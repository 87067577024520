import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[canadianPostalCodeValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CanadianPostalCodeValidator, multi: true }],
})
export class CanadianPostalCodeValidator implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const province = control.parent.controls['province'].value ?? null;

        let value: string = control.value ?? '';
        // Take out all non-alphanumeric characters and convert to lower case for format check
        value = value.replace(/[^a-zA-Z0-9]/, '').toLowerCase();

        const allowedStartLetters = this.startLettersByProvince()[province] ?? null;

        const errors = { custom: { message: 'Postal/Zip Code does not match province/state' } };

        // A case where the province is not a canadian province can occur if the user entered Canada as a country,
        // left the province blank, and entered a postal code
        if (!allowedStartLetters) {
            return errors;
        }

        if (allowedStartLetters.includes(value[0])) {
            return null;
        }

        return errors;
    }

    private startLettersByProvince(): { [province: string]: string[] } {
        return {
            NL: ['a'],
            NS: ['b'],
            PE: ['c'],
            NB: ['e'],
            QC: ['g', 'h', 'j'],
            ON: ['k', 'l', 'm', 'n', 'p'],
            MB: ['r'],
            SK: ['s'],
            AB: ['t'],
            BC: ['v'],
            NU: ['x'],
            NT: ['x'],
            YT: ['y'],
        };
    }
}
