import { ModelMixin } from '@app/models/core/base-generic.model';

export class Topic extends ModelMixin<Topic>() {
    protected static _type = 'topics';
    protected static _version = 'v2';

    get name(): string {
        return this._attributes['name'];
    }
}
