<div>
    <app-file-drop
        (filesSelected)="addInputFiles($event)"
        [inlineView]="true"
        [allowedTypes]="allowedTypes"
        [allowMultiple]="allowMultiple"
        [maxFileSize]="30"
        *ngIf="showInput()"
    >
    </app-file-drop>
    <mat-label class="form-label" *ngFor="let file of files">
        <span>{{ file.name }}</span>
        <img (click)="removeFileModel(file)" appIcon="CrossBlueUiOutline" />
    </mat-label>
    <mat-label class="form-label" *ngFor="let file of fileBlobs">
        <span>{{ file.name }}</span>
        <img (click)="removeInputFile(file)" appIcon="CrossBlueUiOutline" />
    </mat-label>
</div>
