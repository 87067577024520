import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { AdditionalPayItem } from '@app/models/payroll/additional-pay-item.model';
import { NotifyService } from '@app/services';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class InsurableHoursService {
    constructor(
        private http: HttpClient,
        private notify: NotifyService
    ) {}

    formatAdditionalPayItemsForBulkPayload(additionalPayItems: AdditionalPayItem[]): AdditionalPayItem[] {
        const formattedAdditionalPayItems = [];
        additionalPayItems.forEach((additionalPayItem: AdditionalPayItem) => {
            const formattedPayItem = {
                id: additionalPayItem.id,
                compensation_id: additionalPayItem.compensationId,
                name: additionalPayItem.name,
                display_name: additionalPayItem.displayName,
                hours: additionalPayItem.hours,
                amount: additionalPayItem.amount,
            };
            formattedAdditionalPayItems.push(formattedPayItem);
        });
        return formattedAdditionalPayItems;
    }

    async determineIfThereAreEnoughHoursToSubtractFromSalary(
        additionalPayItems: AdditionalPayItem[],
        payrollId: number,
        paystubId: number
    ): Promise<string> {
        const formattedAdditionalPayItems = this.formatAdditionalPayItemsForBulkPayload(additionalPayItems);
        const bulkAdditionalPayItemsCheckSubtractSalaryPayload = {
            data: {
                attributes: {
                    additional_pay_items: formattedAdditionalPayItems,
                },
            },
        };
        try {
            const response = await this.http
                .put(
                    PayrollResources.BulkAdditionalPayItemsCheckSubtractSalary.replace(
                        ':payrollId',
                        payrollId.toString()
                    ).replace(':paystubId', paystubId.toString()),
                    bulkAdditionalPayItemsCheckSubtractSalaryPayload
                )
                .toPromise();
            const message = response['data']['attributes'].message;
            return message;
        } catch (error) {
            this.notify.error(ErrorParser.parse(error));
        }
    }

    async saveBulkAdditionalPayItems(
        additionalPayItems: AdditionalPayItem[],
        payrollId: number,
        paystubId: number
    ): Promise<string> {
        const formattedAdditionalPayItems = this.formatAdditionalPayItemsForBulkPayload(additionalPayItems);
        const bulkAdditionalPayItemsUpsertPayload = {
            data: {
                attributes: {
                    additional_pay_items: formattedAdditionalPayItems,
                },
            },
        };

        try {
            const response = await this.http
                .put(
                    PayrollResources.BulkAdditionalPayItemsUpsert.replace(':payrollId', payrollId.toString()).replace(
                        ':paystubId',
                        paystubId.toString()
                    ),
                    bulkAdditionalPayItemsUpsertPayload
                )
                .toPromise();
            const message = response['data']['attributes'].message;
            return message;
        } catch (error) {
            this.notify.error(ErrorParser.parse(error));
        }
    }
}
