import { TranslatableKey } from '@app/types/translatable.type';

const DefaultCustomFieldsDisplayOptions: { [key: string]: TranslatableKey } = {
    basic_information: 'settings.custom-fields.defaultFieldLabels.basic_information',
    personal_information: 'settings.custom-fields.defaultFieldLabels.personal_information',
    address: 'settings.custom-fields.defaultFieldLabels.address',
    emergency_contacts: 'settings.custom-fields.defaultFieldLabels.emergency_contacts',
    employment_information: 'settings.custom-fields.defaultFieldLabels.employment_information',
    bank_account: 'settings.custom-fields.defaultFieldLabels.bank_account',
};

const CustomFieldGroupsDisplayOptions: { [key: string]: TranslatableKey } = {
    personal: 'settings.custom-fields.defaultFieldGroupLabels.personal',
    job_and_pay: 'settings.custom-fields.defaultFieldGroupLabels.job_and_pay',
};

export { CustomFieldGroupsDisplayOptions, DefaultCustomFieldsDisplayOptions };
