import { Component, ElementRef, Input } from '@angular/core';
import { BulkSelectBaseDialogComponent } from '@app/components';
import { EmploymentTypes } from '@app/constants/employment-types';
import { Office } from '@app/models/company/office.model';
import { LanguageService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { Translatable } from '@app/types/translatable.type';
import { EmploymentTypeDisplayOptions } from '@app/types/translatables/employment-type-display.options';
import { PaginateFilters } from '@interfaces/paginate-filters.interface';
import { Department } from '@models/company/department.model';
import { Job } from '@models/company/job.model';
import { Employee } from '@models/employee/employee.model';
import { differenceBy } from 'lodash-es';

@Component({
    selector: 'app-common-bulk-select-performance-employee-dialog',
    templateUrl: '../bulk-select-base-dialog/bulk-select-base-dialog.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectPerformanceEmployeeDialogComponent extends BulkSelectBaseDialogComponent {
    @Input() label = 'components.bulk-select-employee-dialog.label';
    @Input() headerText = 'components.bulk-select-employee-dialog.header';

    @Input() maxItemsToSelect = null;

    @Input() bannerType = 'info';
    @Input() displayBannerBasedOnMaxItems = false;
    @Input() bannerContent: Translatable;

    filters: any = {
        departmentId: 'applicant-tracker.departments',
        jobId: 'applicant-tracker.positions',
        status: 'applicant-tracker.status',
        officeId: 'applicant-tracker.offices',
        employment: 'applicant-tracker.employmentType',
    };
    filterValues: any = {
        departmentId: {},
        jobId: {},
        status: {
            active: this.languageService.translate('applicant-tracker.active'),
            onboarding: this.languageService.translate('applicant-tracker.onboarding'),
            'on leave': this.languageService.translate('employeeStatus.onLeave'),
        },
        officeId: {},
        employment: {},
    };
    activeFilters: any = {};

    items: Employee[] = [];
    selectItems: Employee[] = [];

    filtersToPaginate: PaginateFilters = {
        jobId: {
            paginateFunction: () => this.paginatePositions(),
            currentPage: 1,
            isLoading: false,
            perPage: 100,
        },
    };

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        private readonly languageService: LanguageService
    ) {
        super(element, auth);

        // Add terminated filter option if administrator
        if (this.auth.isAdmin()) {
            this.filterValues.status['terminated'] = this.languageService.translate('applicant-tracker.terminated');
        }
    }

    async selectAll(): Promise<void> {
        let request = Employee.param('company', this.auth.company.id)
            .with('department')
            .with('job')
            .with('office')
            .limit(0);

        request = this.applyQuery(request);
        request = this.applyFilters(request);
        request = this.applyAdditionalQueryFilters(request);

        this.isLoading = true;

        let promises = this.getRequestsForSelectAll(request);

        try {
            const results = await Promise.all(promises);

            let employees = [];
            for (const collection of results) {
                employees = [...employees, ...collection[0]];
            }

            this.selectItems = [...differenceBy(employees, this.selectItems, 'id'), ...this.selectItems];
            this.updateModelSelection();
        } catch (e) {
        } finally {
            this.isLoading = false;
        }
    }

    private getRequestsForSelectAll(request) {
        const limit = 250;

        if (this.totalResults <= limit) {
            return [request.get()];
        }

        const pages = Math.ceil(this.totalResults / limit);

        let promises = [];
        for (let i = 1; i <= pages; i++) {
            promises.push(request.clone().limit(limit).page(i).get());
        }

        return promises;
    }

    paginatePositions(): void {
        this.filtersToPaginate.jobId.currentPage = this.filtersToPaginate.jobId.currentPage + 1;

        const currentPage = this.filtersToPaginate.jobId.currentPage;
        const numberPerPage = this.filtersToPaginate.jobId.perPage;
        const currentNumberOfJobs = Object.keys(this.filterValues.jobId).length;
        const expectedNumberOfJobs = currentPage * numberPerPage;

        if (currentNumberOfJobs <= expectedNumberOfJobs && !this.filtersToPaginate.jobId.isLoading) {
            this.filtersToPaginate.jobId.isLoading = true;
            Job.param('company', this.auth.company.id)
                .page(currentPage)
                .limit(this.filtersToPaginate.jobId.perPage)
                .get()
                .then(([jobs]) => {
                    for (const job of jobs) {
                        this.filterValues.jobId[job.id] = job.title;
                    }
                    this.filtersToPaginate.jobId.isLoading = false;
                });
        }
    }

    /**
     * Reset the form
     */
    protected loadFilterOptions(): void {
        this.loadDepartments();
        this.loadPositions();
        this.loadOffices();
        this.loadEmploymentTypes();
    }

    protected loadItems(): void {
        let request = Employee.param('company', this.auth.company.id)
            .with('department')
            .with('job')
            .with('office')
            .whereIn('status', ['onboarding', 'active'])
            .page(this.currentPage);

        request = this.applyQuery(request);
        request = this.applyFilters(request);
        request = this.applyAdditionalQueryFilters(request);

        request
            .get()
            .then(([employees, meta]) => {
                this.totalResults = meta.pagination.total;
                this.currentPage = meta.pagination.page;
                this.items = employees;
                this.isLoading = false;
            })
            .catch(() => {});
    }

    private loadDepartments(): void {
        Department.param('company', this.auth.company.id)
            .all()
            .then(([departments]) => {
                for (const department of departments) {
                    this.filterValues.departmentId[department.id] = department.name;
                }
            });
    }

    private loadPositions(): void {
        Job.param('company', this.auth.company.id)
            .page(this.filtersToPaginate.jobId.currentPage)
            .limit(this.filtersToPaginate.jobId.perPage)
            .get()
            .then(([jobs]) => {
                for (const job of jobs) {
                    this.filterValues.jobId[job.id] = job.title;
                }
            });
    }

    private loadOffices(): void {
        Office.param('company', this.auth.company.id)
            .all()
            .then(([offices]) => {
                for (const office of offices) {
                    this.filterValues.officeId[office.id] = office.name;
                }
            });
    }

    private loadEmploymentTypes(): void {
        for (const employmentType of EmploymentTypes) {
            this.filterValues.employment[employmentType] = this.languageService.translate(
                EmploymentTypeDisplayOptions[employmentType] ?? employmentType
            );
        }
    }
}
