import { Model } from '@models/core/base.model';
import { T4Box } from './t4-box.model';
import { DeductionType } from './deduction-type.model';
import { EmployeeDeduction } from './employee-deduction.model';

export class Deduction extends Model {
    protected static _resource = 'payroll/companies/:company/deductions';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['name', 'attach', 'detach', 'deductionTypeId', 'glCodePayable'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    set deductionTypeId(val: number) {
        this._attributes['deductionTypeId'] = val;
    }

    get deductionTypeId(): number {
        return this._attributes['deductionTypeId'];
    }

    get deductionTypeDisplayName(): string {
        return this._attributes['deductionTypeDisplayName'];
    }

    get deductionType(): DeductionType | undefined {
        return this.hasOne(DeductionType, 'deductionType');
    }

    get t4Boxes(): T4Box[] {
        return this.hasMany(T4Box, 't4Boxes');
    }

    get active(): boolean {
        return this._attributes['active'];
    }

    get glCodePayable(): string {
        return this._attributes['glCodePayable'];
    }

    set glCodePayable(val: string) {
        this._attributes['glCodePayable'] = val;
    }

    get employeeDeductions(): EmployeeDeduction[] {
        return this.hasMany(EmployeeDeduction, 'employeeDeductions');
    }
}
