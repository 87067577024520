export enum PayrollStates {
    Open = 'open',
    Processing = 'processing',
    Pending = 'pending',
    Paying = 'paying',
    Paid = 'paid',
    Locked = 'locked',
    ProcessingTimeOff = 'processing_time_off',
    TimeOff = 'time_off',
}
