import { FunnelMessageTemplate } from '@models/recruiting/funnel-message-template.model';
import { ModelMixin } from '../core/base-generic.model';
import { FunnelTemplate } from './funnel-template.model';

export class FunnelTemplateStage extends ModelMixin<FunnelTemplateStage>() {
    protected static _resource = 'applicantTracker/funnelTemplateStages';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _serializeAttributes = ['name', 'atsFunnelTemplateId', 'stageIndex'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get atsFunnelTemplateId(): number {
        return this._attributes['atsFunnelTemplateId'];
    }

    set atsFunnelTemplateId(val: number) {
        this._attributes['atsFunnelTemplateId'] = val;
    }

    get stageIndex(): number {
        return this._attributes['stageIndex'];
    }

    set stageIndex(val: number) {
        this._attributes['stageIndex'] = val;
    }

    get funnelTemplate(): FunnelTemplate {
        return this.hasOne(FunnelTemplate, 'funnelTemplate');
    }

    get funnelMessageTemplate(): FunnelMessageTemplate {
        return this.hasOne(FunnelMessageTemplate, 'funnelMessageTemplate');
    }
}
