/**
 * A translation tag to be passed through a translate pipe, if there's no translation value, the key will be displayed as is.
 */
export type TranslatableKey = string;

export const LOCALES = ['en_CA', 'fr_CA'] as const;
export type Locale = (typeof LOCALES)[number];

export const LOCALIZED_DECIMAL: Record<Locale, string> = { en_CA: '.', fr_CA: ',' };

/** @deprecated use `string` instead. */
export type TranslatableObject = {
    key: TranslatableKey;
    params?: {
        [key: string]: string | number;
    };
};

/** @deprecated use `TranslatableKey` instead. To reduce complexity, only strings should be used for translation.
 * See https://github.com/Humi-HR/interface/pull/8309#discussion_r1318907916
 */
export type Translatable = TranslatableObject | TranslatableKey;

/**
 * Narrowing, the process of refining types to more specific types...
 */
export function isTranslatableObject(obj: unknown): obj is TranslatableObject {
    if (obj === null || typeof obj !== 'object') {
        return false;
    }

    return 'key' in obj;
}
