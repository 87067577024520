import moment from 'moment';

const buildTimes = (): string[] => {
    const hoursInDay = 24;
    const quartersInDay = 4 * hoursInDay;
    const minutesInQuarter = 15;
    const m = moment().startOf('day').subtract(minutesInQuarter, 'minutes');

    return [...Array(quartersInDay)].map(() => {
        m.add(minutesInQuarter, 'minutes');
        return m.format('h:mma');
    });
};

export const timeInQuarterHours = buildTimes();
