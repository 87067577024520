import { Injectable, Injector } from '@angular/core';
import { Platform } from '@app/classes';
import { AnalyticEvents } from '@app/enums/analytic-events.enum';
import { AnalyticEvent } from '@app/interfaces/analytic-event.interface';
import { TimeTrackingSettingsService } from '@app/modules/time-tracking/services/time-tracking-settings.service';
import { ThemeService } from '@app/services/theme.service';
import { TokenService } from '@app/services/token.service';
import { environment } from '@env/environment';
import { Account } from '@models/account/account.model';
import { Role } from '@models/account/role.model';
import { Module } from '@models/common/module.model';
import { Company } from '@models/company/company.model';
import { Employee } from '@models/employee/employee.model';
import { Permission } from '@models/permissions/permission.model';
import { TranslateService } from '@ngx-translate/core';
import { capitalize } from 'lodash-es';
import { PendoUser, UpdatePendoUser } from './types';
import { Settings } from '@time-tracking/interfaces/settings.interface';

declare let pendo: any;
declare let ga: any;
declare let localStorage: Storage;
declare let FS: any;
declare let window: any;

const REP_BENEFITS_TYPE = 'benefits';
const REP_SUCCESS_TYPE = 'success';
const REP_IMPLEMENTATION_TYPE = 'implementation';
const REP_PAYROLL_TYPE = 'payroll';
const REP_SALES_TYPE = 'sales';

@Injectable()
class AnalyticService {
    userToken = '';
    host: string = environment.api;

    constructor(
        private token: TokenService,
        private injector: Injector
    ) {
        this.userToken = this.token.token;
    }

    logLinkOpen(url: string, event: AnalyticEvents = null): void {
        if (event) {
            this.trackEvent(event);
        }
        window.open(url, '_blank');
    }

    trackEvent(event: AnalyticEvents, data: AnalyticEvent = {}): void {
        this.trackPendoEvent(event, data);
        this.trackGoogleAnalyticsEvent(event, data);
    }

    identifyUser(employee: Employee, account: Account, role: Role, company: Company, modules: Module[]): void {
        this.identifyPendo(employee, account, role, company, modules);
        this.identifyFullStory(employee, account, company);
        this.identifyGoogleAnalytics(employee, role, company, modules);
    }

    /**
     * Can update a user's identifiers AFTER the initial identification.
     * This can be used for any attributes that have the ability to be changed by the user.
     * Currently this is only used for Pendo, but can be expanded in the future if necessary
     */
    updateUser(fieldsToUpdate: UpdatePendoUser): void {
        this.updatePendoUser(fieldsToUpdate);
    }

    private identifyFullStory(employee: Employee, account: Account, company: Company): void {
        // try to give Full Story the identity of the user
        // if they are already logged in
        try {
            FS.identify(employee.id, {
                displayName: employee.fullName,
                email: account.email,
                companyName: company.name,
                companyId: company.id,
            });
        } catch (e) {
            console.warn(e);
        }
    }

    private identifyGoogleAnalytics(employee: Employee, role: Role, company: Company, modules: Module[]): void {
        try {
            const data = {
                dimension1: employee.id,
                dimension2: role.name,
                dimension3: company.id,
                dimension4: company.createdAt,
                dimension5: company.reps.find((r) => r.type === REP_SUCCESS_TYPE)?.email,
                dimension6: company.reps.find((r) => r.type === REP_PAYROLL_TYPE)?.email,
                dimension12: company.reps.find((r) => r.type === REP_BENEFITS_TYPE)?.email,
                dimension7: !!modules.find((m) => m.name === 'Time Off'),
                dimension8: !!modules.find((m) => m.name === 'Recruiting'),
                dimension9: !!modules.find((m) => m.name === 'Performance'),
                dimension10: !!modules.find((m) => m.name === 'Payroll'),
                dimension11: !!modules.find((m) => m.name === 'Benefits'),
            };

            ga('set', data);

            // Google Tag Manager uses the window to pull ga variables
            window.gaData = data;
        } catch (e) {
            console.warn(e);
        }
    }

    private async identifyPendo(
        employee: Employee,
        account: Account,
        role: Role,
        company: Company,
        modules: Module[]
    ): Promise<void> {
        let settings: Settings = {};
        if (employee.hasModule(Platform.modules.timeTracking)) {
            settings = await this.injector.get(TimeTrackingSettingsService).get();
        }

        // Call this whenever information about your visitors becomes available
        // Please use Strings, Numbers, or Booleans for value types.
        try {
            if (typeof pendo !== 'undefined' && pendo) {
                // Initial Events / Tracking
                pendo.events.ready((): void => {
                    const authMethod = localStorage.getItem('authMethod');
                    switch (authMethod) {
                        case 'google':
                            this.trackPendoEvent(AnalyticEvents.LoginWithGoogle);
                            break;
                    }
                });

                const pendoUser: PendoUser = {
                    visitor: {
                        id: employee.id, // Required if user is logged in
                        email: account.email, // Optional
                        role: role.name,
                        // eslint-disable-next-line camelcase
                        full_name: employee.fullName,
                        company: company.name,
                        recruiterAccess: role.permissions.some(
                            (perm: Permission) => perm.name === Platform.recruiting.permission.recruiter
                        ),
                        timeTrackingAccess: employee?.hasModule(Platform.modules.timeTracking),
                        country: employee.address?.country,
                        province: employee.address?.province,
                        humilanguagepreference: this.injector.get(TranslateService)?.currentLang, // Injector is used because TranslateService is not instantiated soon enough to be injected in the constructor
                        humidarklightmodepreference: capitalize(
                            this.injector.get(ThemeService)?.getEvaluatedTheme()?.toString()
                        ),
                        // You can add any additional visitor level key-values here,
                        // as long as it's not one of the above reserved names.
                    },

                    account: {
                        id: company.id, // Highly recommended
                        name: company.name, // Optional
                        // planLevel:    // Optional
                        // planPrice:    // Optional
                        creationDate: company.createdAt, // Optional
                        successRep: company.reps.find((r) => r.type === REP_SUCCESS_TYPE)?.email,
                        benefitsRep: company.reps.find((r) => r.type === REP_BENEFITS_TYPE)?.email,
                        payrollRep: company.reps.find((r) => r.type === REP_PAYROLL_TYPE)?.email,
                        implementationRep: company.reps.find((r) => r.type === REP_IMPLEMENTATION_TYPE)?.email,
                        salesRep: company.reps.find((r) => r.type === REP_SALES_TYPE)?.email,
                        modules: modules.map((m) => m.name),
                        moduleTimeOff: !!modules.find((m) => m.name === Platform.modules.timeOff),
                        moduleBenefits: !!modules.find((m) => m.name === Platform.modules.benefits),
                        modulePayroll: company.isPayrollSyncEnabled, // Payroll's module implementation is no standard
                        modulePerformance: !!modules.find((m) => m.name === Platform.modules.performance),
                        moduleRecruiting: !!modules.find((m) => m.name === Platform.modules.recruiting),
                        moduleTimeTracking: !!modules.find((m) => m.name === Platform.modules.timeTracking),
                        billableEmployeeCount: company.billableEmployeeCount,
                        isSelfServeEnabled: company.isSelfServe,
                        isTimeTrackingTimeEnabled:
                            !!modules.find((m) => m.name === Platform.modules.timeTracking) &&
                            settings?.timeTrackingMethod === 'start_end_time',
                        sred: company.isSRED,
                        benefits: company.isBenefitsClient,
                        // You can add any additional account level key-values here,
                        // as long as it's not one of the above reserved names.
                    },
                };

                pendo.initialize(pendoUser);
            }
        } catch (e) {
            console.warn(e);
        }
    }

    private updatePendoUser(fieldsToUpdate: UpdatePendoUser): void {
        try {
            if (typeof pendo !== 'undefined' && pendo) {
                pendo.updateOptions(fieldsToUpdate);
            }
        } catch {
            /* empty - don't want to do anything here, the user will be updated the next time they refresh/login */
        }
    }

    private trackPendoEvent(event: AnalyticEvents, data: AnalyticEvent = {}): void {
        try {
            if (typeof pendo !== 'undefined' && pendo) {
                pendo.track(event, data);
            }
        } catch (e) {
            console.warn(e);
        }
    }

    private trackGoogleAnalyticsEvent(event: AnalyticEvents, data: AnalyticEvent = {}): void {
        try {
            ga(
                'send',
                'event',
                data.category || 'custom',
                data.action || event,
                data.label || event,
                data.value || event
            );
        } catch (e) {
            console.warn(e);
        }
    }
}

export { AnalyticEvent, AnalyticEvents, AnalyticService };
