<div class="ui page dimmer" [class.no-dimmer]="!useDimmer" [class.for-cards]="forCards">
    <div class="content" role="dialog">
        <div
            class="ui segment"
            [class.small]="small"
            [class.narrow]="narrow"
            [class.medium]="medium"
            [class.large]="large"
            [class.tall]="tall"
            [class.loading]="isLoading"
        >
            <div class="content" [class.no-border]="noBorder">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
