import { FeatureFlag } from '@app/enums';
import { CAN_MOCK_FEATURE_FLAG_KEY } from '@app/meta/feature-flag.meta';
import { environment } from '@env/environment';
import { inProduction } from './in-production';

export const getLocalFeatureFlags = (): { [key: string]: boolean | string } => {
    const featureFlags: { [key: string]: boolean | string } = {};

    // Find feature flags that are enabled
    Object.values(FeatureFlag).forEach((featureFlag: string) => {
        const localStorageFlag = window.localStorage.getItem(`flag.${featureFlag}`);
        const hasFeatureFlagEnabled = !!localStorageFlag && localStorageFlag === 'true';

        if (!hasFeatureFlagEnabled) {
            return;
        }

        featureFlags[featureFlag] = true;
    });

    return featureFlags;
};

export const canMockFeatureFlags = (): boolean => {
    // This should always return false on production
    if (inProduction()) {
        return false;
    }

    // This should always return true on e2e
    if (environment.name === 'e2e') {
        return true;
    }

    // All other environments depend on CAN_MOCK_FEATURE_FLAG_KEY
    return window.localStorage.getItem(CAN_MOCK_FEATURE_FLAG_KEY) === 'true';
};

export const setupDataForFeatureFlagExtension = () => {
    // We dump the feature flags in a div element that the extension can access it.
    const element = document.createElement('div');
    element.innerHTML = JSON.stringify(Object.values(FeatureFlag));
    element.style.display = 'none';
    element.id = 'featureFlags';
    document.body.append(element);
};

export const fetchFeatureFlagInterceptor = () => {
    const nativeFetch = window.fetch;

    window['fetch'] = (input: RequestInfo, init?: RequestInit) => {
        if (!canMockFeatureFlags()) {
            return nativeFetch(input, init);
        }

        const featureFlags = getLocalFeatureFlags();

        const url = new URL(input.toString());

        Object.entries(featureFlags).forEach(([key, value]) =>
            url.searchParams.append(`featureFlags[${key}]`, value.toString())
        );

        return nativeFetch(url.toString(), init);
    };
};
