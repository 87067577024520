import { ModelMixin } from '../core/base-generic.model';

export class LeaveOfAbsenceTypes extends ModelMixin<LeaveOfAbsenceTypes>() {
    protected static _resource = 'companyManagement/leaveOfAbsenceTypes';

    protected static _type = 'leaveOfAbsenceTypes';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['name'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get companyId(): number {
        return this._attributes['companyId'];
    }
}
