import { ModelMixin } from '@models/core/base-generic.model';
import { Module } from '@models/common/module.model';
import { SetupGuideStep } from '@models/company/setup-guide-step.model';

/**
 * At the time of creation this is not a resourceful model.
 */
export class SetupGuide extends ModelMixin<SetupGuide>() {
    protected static _type = 'setupGuides';
    protected static _version = 'v2';

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get moduleId(): number {
        return this._attributes['moduleId'];
    }

    get module(): Module {
        return this.hasOne(Module, 'module');
    }

    get initialActionCompletedBy(): number {
        return this._attributes['initialActionCompletedBy'];
    }

    get initialActionCompletedAt(): Date | null {
        return this._attributes['initialActionCompletedAt'];
    }

    get finalActionCompletedBy(): number {
        return this._attributes['finalActionCompletedBy'];
    }

    get finalActionCompletedAt(): Date | null {
        return this._attributes['finalActionCompletedAt'];
    }

    get setupGuideSteps(): SetupGuideStep[] {
        return this.hasMany(SetupGuideStep, 'setupGuideSteps');
    }
}
