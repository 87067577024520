import { Component, Input } from '@angular/core';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-kanban-column-form',
    templateUrl: './kanban-column.form.html',
})
export class KanbanColumnForm extends BaseForm {
    @Input() columnName = '';
}
