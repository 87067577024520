import { SimpleEntityActions } from '@app/classes';
import { Deduction, Earning } from '@app/interfaces';
import { AdditionalPayItem } from '@app/models/payroll/additional-pay-item.model';
import { HourlyPayItem } from '@app/models/payroll/hourly-pay-item.model';
import { PayrollEmployee } from '@app/models/payroll/payroll-employee.model';
import { ReimbursementItem } from '@app/models/payroll/reimbursement-item.model';
import { SalaryPayItem } from '@app/models/payroll/salary-pay-item.model';
import { AdditionalPayItems } from '@app/modules/payroll/enums/additional-pay-items.enums';
import { Model } from '@models/core/base.model';
import { ScheduledVacationPayoutOrRepayment } from './scheduled-vacation-payout-and-repayment.model';

export class Paystub extends Model {
    protected static _resource = 'payroll/employees/:employee/payStubs';
    protected static _type = 'paystubs';
    protected static _version = 'v2';
    protected static _dates = ['chequeDate'];
    protected static _serializeAttributes = ['paymentMethod', 'payrollId', 'employeeId', 'workingVacationPayBalance'];

    static permission = new SimpleEntityActions('employeePayStubs');

    set id(val: number) {
        this._attributes['id'] = val;
    }

    /**
     * its absolutely beyond me why this is needed, but if i dont do this the id up the proto chain is null, if i do this, its correct - fought with this for so long
     */
    get id(): number {
        return +this._attributes['id'];
    }

    get payrollId(): number {
        return this._attributes['payrollId'];
    }
    set payrollId(val: number) {
        this._attributes['payrollId'] = val;
    }

    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get chequeDate(): string {
        return this._attributes['chequeDate'];
    }

    get depositAmount(): string {
        return this._attributes['depositAmount'];
    }

    get paymentMethod(): string {
        return this._attributes['paymentMethod'];
    }

    set paymentMethod(val: string) {
        this._attributes['paymentMethod'] = val;
    }

    get isDirectDeposit(): boolean {
        return this.paymentMethod !== 'cheque';
    }

    set isDirectDeposit(val: boolean) {
        this.paymentMethod = val ? 'deposit' : 'cheque';
    }

    get benefits(): string {
        return this._attributes['benefits'];
    }

    get benefitsAmount(): string {
        return this._attributes['benefitsAmount'];
    }

    get compensationRate(): string {
        return this._attributes['compensationRate'];
    }

    get compensationUnit(): string {
        return this._attributes['compensationUnit'];
    }

    get deductions(): Deduction[] {
        return this._attributes['deductions'];
    }

    get deductionsAmount(): string {
        return this._attributes['deductionsAmount'];
    }

    get defaultPaymentMethod(): string {
        return this._attributes['defaultPaymentMethod'];
    }

    get earnings(): Earning[] {
        return this._attributes['earnings'];
    }

    get employeeName(): string {
        return this._attributes['employeeName'];
    }

    get grossPay(): number {
        return +this._attributes['grossPay'];
    }

    get hasAttachment(): string {
        return this._attributes['hasAttachment'];
    }

    get netPay(): string {
        return this._attributes['net_pay'];
    }

    get preferredPaymentMethod(): string {
        return this._attributes['preferredPaymentMethod'];
    }

    get reimbursementAmount(): string {
        return this._attributes['reimbursementAmount'];
    }

    get state(): string {
        return this._attributes['state'];
    }

    get paidVacationTimeUsed(): number | null {
        return this._attributes['paidVacationTimeUsed'];
    }

    get paidVacationTimeAccrued(): number | null {
        return this._attributes['paidVacationTimeAccrued'];
    }

    get paidVacationTimeYTDBalance(): number | null {
        return this._attributes['paidVacationTimeYtdBalance'];
    }

    get paidVacationMoneyUsed(): number | null {
        return this._attributes['paidVacationMoneyUsed'];
    }

    get paidVacationMoneyAccrued(): number | null {
        return this._attributes['paidVacationMoneyAccrued'];
    }

    get paidVacationMoneyYTDBalance(): number | null {
        return this._attributes['paidVacationMoneyYtdBalance'];
    }

    get included(): {}[] {
        return this._rawAttributes['included'];
    }

    get canDirectDeposit(): boolean {
        return Boolean(this._attributes['canDirectDeposit']);
    }

    get employee(): PayrollEmployee {
        return this.hasOne(PayrollEmployee, 'employee');
    }

    get scheduledVacationPayout(): ScheduledVacationPayoutOrRepayment {
        return this.hasOne(ScheduledVacationPayoutOrRepayment, 'scheduledVacationPayout');
    }

    get additionalPayItems(): AdditionalPayItem[] {
        return this.hasMany(AdditionalPayItem, 'additionalPayItems').filter((item) => item);
    }

    get vacationPayItem(): AdditionalPayItem {
        return this.additionalPayItems.find(({ name }) => name === AdditionalPayItems.VacationPay);
    }

    get additionalPayItemsExcludingVacationPay(): AdditionalPayItem[] {
        return this.additionalPayItems.filter(({ name }) => name !== AdditionalPayItems.VacationPay);
    }

    get hasUserModifiedVacationPay(): boolean {
        return this.vacationPayItem && this.vacationPayItem.userModified;
    }

    get hourlyEmployee(): boolean {
        return this._attributes['hourlyEmployee'];
    }

    set additionalPayItems(val: AdditionalPayItem[]) {
        this.setMany('additionalPayItems', val);
    }

    get hourlyPayItems(): HourlyPayItem[] {
        return this.hasMany(HourlyPayItem, 'hourlyPayItems').filter((item) => item);
    }

    get latestEffectiveHourlyPayItem(): HourlyPayItem {
        return this.hourlyPayItems
            .filter((hourlyPayItem) => hourlyPayItem.multiplier === '1.0')
            .sort((a, b) => (a.compensationEffectiveFrom > b.compensationEffectiveFrom ? -1 : 1))
            .find((_) => true);
    }

    set hourlyPayItems(val: HourlyPayItem[]) {
        this.setMany('hourlyPayItems', val);
    }

    get salaryPayItems(): SalaryPayItem[] {
        return this.hasMany(SalaryPayItem, 'salaryPayItems').filter((item) => item);
    }

    set salaryPayItems(val: SalaryPayItem[]) {
        this.setMany('salaryPayItems', val);
    }

    get reimbursementItem(): ReimbursementItem {
        return this.hasOne(ReimbursementItem, 'reimbursementItem');
    }

    set reimbursementItem(val: ReimbursementItem) {
        this.setOne('reimbursementItem', val);
    }

    get orderedSalaryPayItems(): SalaryPayItem[] {
        return this.salaryPayItems.sort((a: SalaryPayItem, b: SalaryPayItem) => {
            return a.compensationRate > b.compensationRate ? -1 : 1;
        });
    }

    get hasBonusPayItems(): boolean {
        return this.additionalPayItems.some((additionalPayItem) => additionalPayItem.isBonusPayItem);
    }

    get hasNonRequiredAdditionalPayItems(): boolean {
        return this.additionalPayItems.some((additionalPayItem) => additionalPayItem.isNonRequiredItem);
    }

    get nonRequiredAdditionalPayItems(): AdditionalPayItem[] {
        return this.additionalPayItems.filter(
            (additionalPayItem: AdditionalPayItem) => additionalPayItem.isNonRequiredItem
        );
    }

    get bonusPayItems(): AdditionalPayItem[] {
        return this.additionalPayItems.filter(
            (additionalPayItem: AdditionalPayItem) => additionalPayItem.isBonusPayItem
        );
    }

    get nonRequiredAdditionalPayItemSum() {
        return this.nonRequiredAdditionalPayItems.reduce((sum, payItem) => sum + payItem.toAmount(), 0);
    }

    get bonusPayItemSum() {
        return this.bonusPayItems.reduce((sum, payItem) => sum + payItem.toAmount(), 0);
    }

    /**
     * Whether any of the bonus pay items are larger than the Regular Pay amount.
     */
    get isBonusMoreThanRegularPay() {
        const regularPayItem =
            this.salaryPayItems.find((item) => item.name === 'Regular Pay') ??
            this.hourlyPayItems.find((item) => item.name === 'Regular Pay');

        if (regularPayItem && this.bonusPayItems.some((item) => item.amount > regularPayItem.amount)) {
            return true;
        }

        return false;
    }

    get workingVacationPayBalance(): number {
        return this._attributes['workingVacationPayBalance'];
    }

    revertAdditionalPayItems() {
        const originalPayItems = this.additionalPayItems
            .filter((payItem) => payItem.isPersisted)
            .map((payItem) => {
                payItem.revert();
                return payItem;
            });

        this.additionalPayItems = originalPayItems;
    }

    orderedAdditionalPayItems(): AdditionalPayItem[] {
        return this.additionalPayItems.sort((a: AdditionalPayItem, b: AdditionalPayItem) => {
            return this.getAdditionalPayOrder(a) < this.getAdditionalPayOrder(b) ? -1 : 1;
        });
    }

    private getAdditionalPayOrder(additionalPayItem: AdditionalPayItem): number {
        switch (additionalPayItem.name) {
            case AdditionalPayItems.VacationPay:
                return 0;
            case AdditionalPayItems.Bonus:
                return 1;
            case AdditionalPayItems.Commission:
                return 2;
            case AdditionalPayItems.Other:
                return 3;
            default:
                return 99;
        }
    }
}
