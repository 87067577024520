import { Model } from '@models/core/base.model';

export class SelfOnboardingStep extends Model {
    protected static _resource = 'payroll/selfOnboarding/stepTrackers';
    protected static _version = 'v2';

    get flowDisplayName(): string {
        return this._attributes['flowDisplayName'];
    }

    get flowName(): string {
        return this._attributes['flowName'];
    }

    get state(): string {
        return this._attributes['state'];
    }

    get stepDisplayName(): string {
        return this._attributes['stepDisplayName'];
    }

    get stepName(): string {
        return this._attributes['stepName'];
    }
}
