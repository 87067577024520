import { Directive } from '@angular/core';
import { DialogComponent } from '@app/components';

@Directive({
    selector: 'ui-dialog[formDialog]',
    exportAs: 'uiFormDialogDirective',
})
export class FormDialogDirective {
    constructor(private dialog: DialogComponent) {
        this.dialog.primaryButtonType = 'primary';
        this.dialog.primaryButtonLabel = 'save';
        this.dialog.secondaryButtonType = 'ghost';
        this.dialog.secondaryButtonLabel = 'cancel';
    }
}
