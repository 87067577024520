import { Injectable } from '@angular/core';
import { MenuItem } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';

const WorkScheduleMenu: MenuItem[] = [
    {
        key: 'time-off-v3.policyComponents.workSchedules.scheduleDetails',
        link: ['../details'],
    },
    {
        key: 'time-off-v3.policyComponents.workSchedules.enrolledEmployees',
        link: ['../enrolled-employees'],
    },
];

@Injectable()
export class WorkScheduleMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = WorkScheduleMenu;
    }
}
