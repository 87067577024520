/**
 * This directive is taken from the angular2-text-mask library.
 * It has been largely unmodified, aside from fixing some eslint rules.
 * We're also not exporting it as a dedicated ngModule, but using the directive directly.
 *
 * The reason for making this directive ourselves instead of using the package is that the
 * package is no longer being maintained, and the most recent version does not comply with
 * Ivy compiler exports, preventing us from using the latest versions of angular.
 * The package essentially is just this directive, and it relies on a sub-package
 * known as text-mask-core which is in our dev dependencies.
 */
import {
    Directive,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    Optional,
    Provider,
    Renderer2,
    forwardRef,
} from '@angular/core';
import { COMPOSITION_BUFFER_MODE, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ɵgetDOM as getDOM } from '@angular/platform-browser';
import { TextMask } from '@app/interfaces';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';

export class TextMaskConfig {
    mask: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>) | false | TextMask;
    guide?: boolean;
    placeholderChar?: string;
    pipe?: (conformedValue: string, config: TextMaskConfig) => false | string | Record<string, unknown>;
    keepCharPositions?: boolean;
    showMask?: boolean;
}

export const MASKEDINPUT_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextMaskDirective),
    multi: true,
};

/**
 * We must check whether the agent is Android because composition events
 * behave differently between iOS and Android.
 */
function _isAndroid(): boolean {
    const userAgent = getDOM() ? getDOM().getUserAgent() : '';
    return /android (\d+)/.test(userAgent.toLowerCase());
}

@Directive({
    host: {
        '(input)': '_handleInput($event.target.value)',
        '(blur)': 'onTouched()',
        '(compositionstart)': '_compositionStart()',
        '(compositionend)': '_compositionEnd($event.target.value)',
    },
    selector: '[textMask]',
    providers: [MASKEDINPUT_VALUE_ACCESSOR],
})
export class TextMaskDirective implements ControlValueAccessor, OnChanges {
    @Input('textMask') textMaskConfig: TextMaskConfig = {
        mask: [],
        guide: true,
        placeholderChar: '_',
        pipe: undefined,
        keepCharPositions: false,
    };

    // eslint-disable-next-line
    onChange = (_: any) => {};
    // eslint-disable-next-line
    onTouched = () => {};

    private textMaskInputElement: any;
    private inputElement: HTMLInputElement;

    /** Whether the user is creating a composition string (IME events). */
    private _composing = false;

    constructor(
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        @Optional() @Inject(COMPOSITION_BUFFER_MODE) private _compositionMode: boolean
    ) {
        if (this._compositionMode == null) {
            this._compositionMode = !_isAndroid();
        }
    }

    ngOnChanges(): void {
        this._setupMask(true);
        if (this.textMaskInputElement !== undefined) {
            this.textMaskInputElement.update(this.inputElement.value);
        }
    }

    writeValue(value: string): void {
        this._setupMask();

        // set the initial value for cases where the mask is disabled
        const normalizedValue = value == null ? '' : value;
        this._renderer.setProperty(this.inputElement, 'value', normalizedValue);

        if (this.textMaskInputElement !== undefined) {
            this.textMaskInputElement.update(value);
        }
    }

    // eslint-disable-next-line
    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    }

    _handleInput(value: string): void {
        if (!this._compositionMode || (this._compositionMode && !this._composing)) {
            this._setupMask();

            if (this.textMaskInputElement !== undefined) {
                this.textMaskInputElement.update(value);

                // get the updated value
                value = this.inputElement.value;
                this.onChange(value);
            }
        }
    }

    _setupMask(create = false): void {
        if (!this.inputElement) {
            if (this._elementRef.nativeElement.tagName.toUpperCase() === 'INPUT') {
                // `textMask` directive is used directly on an input element
                this.inputElement = this._elementRef.nativeElement;
            } else {
                // `textMask` directive is used on an abstracted input element, `md-input-container`, etc
                this.inputElement = this._elementRef.nativeElement.getElementsByTagName('INPUT')[0];
            }
        }

        if (this.inputElement && create) {
            this.textMaskInputElement = createTextMaskInputElement(
                Object.assign({ inputElement: this.inputElement }, this.textMaskConfig)
            );
        }
    }

    _compositionStart(): void {
        this._composing = true;
    }

    _compositionEnd(value: string): void {
        this._composing = false;
        this._compositionMode && this._handleInput(value);
    }
}
