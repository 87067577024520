export const HiringAccessLevelLabels = {
    recruiter: 'Only Recruiters',
    hiringManager: 'Only Recruiters & Hiring Managers',
    basic: 'Everyone on the Hiring Team',
};

export type HiringAccessLevelLabel = 'Recruiter' | 'Hiring Manager' | 'Basic';

type HiringAccessLevel = {
    id: number;
    label: HiringAccessLevelLabel;
};

export const HiringAccessLevels = {
    recruiter: { id: null, label: HiringAccessLevelLabels.recruiter } as HiringAccessLevel,
    hiringManager: { id: 1, label: HiringAccessLevelLabels.hiringManager } as HiringAccessLevel,
    basic: { id: 2, label: HiringAccessLevelLabels.basic } as HiringAccessLevel,
} as const;
