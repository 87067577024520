import { CheckListItem } from '@app/modules/table/classes/column-filters/check-list/check-list-item.class';
import { ColumnFilterDisplay } from '@app/modules/table/types/column-filter-display.type';
import { FilterValue } from '@app/modules/table/types/filter-value.type';
import { Filter } from '../../filter.abstract';
import { ColumnFilter } from '../column-filter.abstract';

export const isCheckListFilter = (filter: Filter): filter is CheckListFilter =>
    filter.type === 'checklist' || filter.type === 'ratingChecklist';

export class CheckListFilter extends ColumnFilter {
    type: ColumnFilterDisplay = 'checklist';
    options: CheckListItem[] = [];
    currentValues: Set<FilterValue> = new Set<FilterValue>();

    /**
     * Filter field may need to be pulled up into the base filter class, depending on how the other filter display types
     * will operate
     */
    constructor(
        protected filterField: string,
        options: CheckListItem[] | Promise<CheckListItem[]>,
        label?: string
    ) {
        super();

        this.label = label;

        if (Array.isArray(options)) {
            this.options = options;
            this.applyInitialFilters();
            this.isReady = true;

            return;
        }

        options
            .then((o: CheckListItem[]) => {
                this.options = o;
                this.applyInitialFilters();
                this.isReady = true;
            })
            .catch((err) => console.warn('Error retrieving checklist filter options: ', err));
    }

    clear(): void {
        this.currentValues.clear();
        this.emitChanges();
    }

    isActive(option: CheckListItem): boolean {
        return this.currentValues.has(option.value);
    }

    hasValues(): boolean {
        return this.currentValues.size > 0;
    }

    toggle(toggledItem: CheckListItem): void {
        this.currentValues.has(toggledItem.value)
            ? this.currentValues.delete(toggledItem.value)
            : this.currentValues.add(toggledItem.value);

        this.emitChanges();
    }

    private emitChanges(): void {
        const changes = this.currentValues.size > 0 ? Array.from(this.currentValues.values()) : null;

        this.onChanges.next([
            {
                field: this.filterField,
                value: changes,
            },
        ]);
    }

    private applyInitialFilters(): void {
        this.options.filter((o) => o.initiallyActive).forEach((o) => this.toggle(o));
    }
}
