import { ModelMixin } from '@models/core/base-generic.model';
import { Company } from '@models/company/company.model';

export type QuickstartType = 'HR_TIMEOFF' | 'HR_PAYROLL' | 'HR_PAYROLL_TIMEOFF';

export class SelfServeOnboarding extends ModelMixin<SelfServeOnboarding>() {
    protected static _type = 'selfServeOnboardings';
    protected static _resource = 'selfOnboarding/selfServeOnboarding';
    protected static _version = 'v2';
    protected static _dates = ['firstPayDate', 'lastPayDate', 'endDateOfLastPayPeriod'];

    protected static _serializeAttributes = [
        'firstPaydayDate',
        'lastPaydayDate',
        'endDateOfLastPayPeriod',
        'hasRunPayrollThisYear',
        'hasCurrentPayrollProvider',
        'firstPaydayDateUndecided',
        'currentPayrollProvider',
        'paydayFrequency',
    ];

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get company(): Company {
        return this.hasOne(Company, 'company');
    }

    get firstPaydayDate(): Date | null {
        return this._attributes['firstPaydayDate'];
    }

    set firstPaydayDate(value: Date | null) {
        this._attributes['firstPaydayDate'] = value;
    }

    get lastPaydayDate(): Date | null {
        return this._attributes['lastPaydayDate'];
    }

    set lastPaydayDate(value: Date | null) {
        this._attributes['lastPaydayDate'] = value;
    }

    get endDateOfLastPayPeriod(): Date | null {
        return this._attributes['endDateOfLastPayPeriod'];
    }

    set endDateOfLastPayPeriod(value: Date | null) {
        this._attributes['endDateOfLastPayPeriod'] = value;
    }

    get hasRunPayrollThisYear(): boolean {
        return this._attributes['hasRunPayrollThisYear'];
    }

    set hasRunPayrollThisYear(value: boolean) {
        this._attributes['hasRunPayrollThisYear'] = value;
    }

    get hasCurrentPayrollProvider(): boolean {
        return this._attributes['hasCurrentPayrollProvider'];
    }

    set hasCurrentPayrollProvider(value: boolean) {
        this._attributes['hasCurrentPayrollProvider'] = value;
    }

    get firstPaydayDateUndecided(): boolean {
        return this._attributes['firstPaydayDateUndecided'];
    }

    set firstPaydayDateUndecided(value: boolean) {
        this._attributes['firstPaydayDateUndecided'] = value;
    }

    get currentPayrollProvider(): string | null {
        return this._attributes['currentPayrollProvider'];
    }

    set currentPayrollProvider(value: string | null) {
        this._attributes['currentPayrollProvider'] = value;
    }

    get paydayFrequency(): string {
        return this._attributes['paydayFrequency'];
    }

    set paydayFrequency(value: string) {
        this._attributes['paydayFrequency'] = value;
    }

    get selfServePackage(): QuickstartType {
        return this._attributes['selfServePackage'];
    }
}
