import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';

export class EmployeeDeduction extends Model {
    protected static _resource = 'payroll/companies/:company/deductions/:deduction/employeeDeductions';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'amount',
        'calculatorType',
        'benefitDeductionFrequencyId',
        'effectiveDate',
    ];

    get employeeId(): number {
        return Number(this._attributes['employeeId']);
    }

    get prEmployeeId(): number {
        return this._attributes['employeeId'];
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get amount(): number {
        return this._attributes['amount'];
    }
    set amount(val: number) {
        this._attributes['amount'] = val;
    }

    get calculatorType(): string {
        return this._attributes['calculatorType'];
    }
    set calculatorType(val: string) {
        this._attributes['calculatorType'] = val;
    }

    get benefitDeductionFrequencyId(): number {
        return this._attributes['benefitDeductionFrequencyId'];
    }
    set benefitDeductionFrequencyId(val: number) {
        this._attributes['benefitDeductionFrequencyId'] = val;
    }

    get effectiveDate(): string {
        return this._attributes['effectiveDate'];
    }
    set effectiveDate(val: string) {
        this._attributes['effectiveDate'] = val;
    }
}
