import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, Validators } from '@angular/forms';

@Directive({
    selector: '[min]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinDirective, multi: true }],
})
export class MinDirective implements Validator {
    @Input() min: number;
    @Input('min-message') minMessage: string;

    validate(control: AbstractControl): { [key: string]: any } {
        const validator = Validators.min(this.min)(control);

        if (validator && this.minMessage) {
            validator.min.message = this.minMessage;
        }

        return validator;
    }
}
