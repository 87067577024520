import { EffectiveDate } from '@app/enums/effective-date.enum';
import { AssignJSONApiRequest } from '@app/modules/time-off-v3/interfaces/time-off-assign.interface';
import {
    ANNUALLY,
    BIWEEKLY,
    MONTHLY,
    QUARTERLY,
    SEMIMONTHLY,
    TimeOffDateFormatDashes,
    TimeOffDateFormatDayMonthDashes,
    WEEKLY,
} from '@app/modules/time-off-v3/meta/time-off-meta';
import { CapOnOptions, capOnOptions } from '@app/modules/time-off-v3/types/carryover-card.type';
import { Model } from '@models/core/base.model';
import { EmployeeTimeOffPolicies } from '@models/time-off-v3/employee-time-off.policies';
import { TimeOffSeniorityOverride } from '@models/time-off-v3/time-off-seniority-override.model';
import { getTime, setTime } from '@time-off-v3/functions/time-unit-converter';
import { TimeOffResources } from '@time-off-v3/time-off-v3.resource';
import moment from 'moment/moment';
import { Employee } from '../employee/employee.model';
import { TimeOffApprovalFlow } from './time-off-approval-flow.model';
import { TimeOffRequestRules } from './time-off-request-rules.model';
import { TimeOffType } from './time-off-type.model';

export interface TimeOffSeniorityOverridePayload {
    id?: number;
    afterNumberOfYears: number;
    daysAccruedPerPeriod: number;
    delete?: boolean;
}

export class TimeOffPolicy extends Model {
    private _isFuturePolicyOfExistingTimeOffType = false;
    // This is the policy that will be effective immediately after this policy.
    private _nextFuturePolicy: TimeOffPolicy;

    protected static _resource = 'timeOffV3/policies';

    protected static _type = 'timeOffPolicies';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _dates = ['weeklyFrequenciesAnchor', 'carryoverDate'];

    protected static _serializeAttributes = [
        'isUnlimited',
        'timeOffTypeId',
        'timeOffApprovalFlowId',
        'proratesFirstAccrual',
        'name',
        'daysAccruedPerPeriod',
        'balanceCap',
        'carryoverCap',
        'carriesOverOnHireDate',
        'carryoverExpireAfter',
        'accrualTiming',
        'accrualFrequency',
        'accrualFromHireDate',
        'startOfWeek',
        'weeklyFrequenciesAnchor',
        'dayOfMonthOne',
        'dayOfMonthTwo',
        'dayOfYearOne',
        'dayOfYearTwo',
        'dayOfYearThree',
        'dayOfYearFour',
        'carryoverDate',
        'timeOffSeniorityOverridesPayload',
        'effectiveDate',
    ];

    accrualPreviewParams: string[] = [
        'isUnlimited',
        'accrualTiming',
        'accrualFrequency',
        'dayOfMonthOne',
        'dayOfMonthTwo',
        'startOfWeek',
        'weeklyFrequenciesAnchor',
        'dayOfYearOne',
        'dayOfYearTwo',
        'dayOfYearThree',
        'dayOfYearFour',
    ];

    // Must maintain parity with Laravel Model
    private essentialFields: string[] = [
        'isUnlimited',
        'proratesFirstAccrual',
        'accrualFromHireDate',
        'carriesOverOnHireDate',
        'daysAccruedPerPeriod',
        'balanceCap',
        'carryoverCap',
        'carryoverExpireAfter',
        'accrualTiming',
        'accrualFrequency',
        'startOfWeek',
        'weeklyFrequenciesAnchor',
        'dayOfMonthOne',
        'dayOfMonthTwo',
        'dayOfYearOne',
        'dayOfYearTwo',
        'dayOfYearThree',
        'dayOfYearFour',
        'carryoverDate',
    ];

    get id(): number {
        return this._attributes['id'];
    }

    set id(val: number) {
        this._attributes['id'] = val;
    }

    get timeOffTypeId(): number {
        return this._attributes['timeOffTypeId'];
    }

    set timeOffTypeId(val: number) {
        this._attributes['timeOffTypeId'] = val;
    }

    get timeOffApprovalFlowId(): number {
        return this._attributes['timeOffApprovalFlowId'];
    }

    set timeOffApprovalFlowId(val: number) {
        this._attributes['timeOffApprovalFlowId'] = val;
    }

    get timeOffPolicyId(): number {
        return this._attributes['timeOffPolicyId'];
    }

    set timeOffPolicyId(val: number) {
        this._attributes['timeOffPolicyId'] = val;
    }

    get isUnlimited(): boolean {
        return this._attributes['isUnlimited'];
    }

    set isUnlimited(val: boolean) {
        this._attributes['isUnlimited'] = val;
    }

    get proratesFirstAccrual(): boolean {
        return this._attributes['proratesFirstAccrual'];
    }

    set proratesFirstAccrual(val: boolean) {
        this._attributes['proratesFirstAccrual'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get isFuture(): boolean {
        return this._attributes['isFuture'];
    }

    get daysAccruedPerPeriod(): number {
        return this._attributes['daysAccruedPerPeriod'];
    }

    set daysAccruedPerPeriod(val: number) {
        this._attributes['daysAccruedPerPeriod'] = val;
    }

    get balanceCap(): number {
        return getTime(this._attributes['balanceCap'], this.timeOffType);
    }

    set balanceCap(val: number) {
        this._attributes['balanceCap'] = setTime(val, this.timeOffType);
    }

    get carryoverCap(): number {
        return getTime(this._attributes['carryoverCap'], this.timeOffType);
    }

    get carryoverCapOriginal(): number {
        return getTime(this._originalAttributes['carryoverCap'], this.timeOffType);
    }

    set carryoverCap(val: number) {
        this._attributes['carryoverCap'] = setTime(val, this.timeOffType);
    }

    get accrualTiming(): string {
        return this._attributes['accrualTiming'];
    }

    set accrualTiming(val: string) {
        this._attributes['accrualTiming'] = val;
    }

    get accrualFrequency(): string {
        return this._attributes['accrualFrequency'];
    }

    set accrualFrequency(val: string) {
        this._attributes['accrualFrequency'] = val;
    }

    get startOfWeek(): string {
        return this._attributes['startOfWeek'];
    }

    set startOfWeek(val: string) {
        this._attributes['startOfWeek'] = val;
    }

    get weeklyFrequenciesAnchor(): Date {
        return this._attributes['weeklyFrequenciesAnchor'];
    }

    set weeklyFrequenciesAnchor(val: Date) {
        this._attributes['weeklyFrequenciesAnchor'] = val;
    }

    get dayOfMonthOne(): number {
        return this._attributes['dayOfMonthOne'];
    }

    set dayOfMonthOne(val: number) {
        this._attributes['dayOfMonthOne'] = val;
    }

    get dayOfMonthTwo(): number {
        return this._attributes['dayOfMonthTwo'];
    }

    set dayOfMonthTwo(val: number) {
        this._attributes['dayOfMonthTwo'] = val;
    }

    get dayOfYearOne(): string {
        return this._attributes['dayOfYearOne'];
    }

    set dayOfYearOne(val: string) {
        this._attributes['dayOfYearOne'] = val;
    }

    get dayOfYearTwo(): string {
        return this._attributes['dayOfYearTwo'];
    }

    set dayOfYearTwo(val: string) {
        this._attributes['dayOfYearTwo'] = val;
    }

    get dayOfYearThree(): string {
        return this._attributes['dayOfYearThree'];
    }

    set dayOfYearThree(val: string) {
        this._attributes['dayOfYearThree'] = val;
    }

    get dayOfYearFour(): string {
        return this._attributes['dayOfYearFour'];
    }

    set dayOfYearFour(val: string) {
        this._attributes['dayOfYearFour'] = val;
    }

    get carryoverDate(): string {
        return this._attributes['carryoverDate'];
    }

    set carryoverDate(val: string) {
        this._attributes['carryoverDate'] = val;
    }

    get carriesOverOnHireDate(): boolean {
        return this._attributes['carriesOverOnHireDate'];
    }

    set carriesOverOnHireDate(val: boolean) {
        this._attributes['carriesOverOnHireDate'] = val;
    }

    get carryoverExpireAfter(): number {
        return this._attributes['carryoverExpireAfter'];
    }

    set carryoverExpireAfter(val: number) {
        this._attributes['carryoverExpireAfter'] = val;
    }

    get employeeCount(): number {
        return this._attributes['employeeCount'];
    }

    get futureEmployeeCount(): number {
        return this._attributes['futureEmployeeCount'];
    }

    get hasEmployeesAssigned(): boolean {
        return this._attributes['hasEmployeesAssigned'];
    }

    get accrualFromHireDate(): boolean {
        return this._attributes['accrualFromHireDate'];
    }

    set accrualFromHireDate(val: boolean) {
        this._attributes['accrualFromHireDate'] = val;
    }

    get timeOffApprovalFlow(): TimeOffApprovalFlow {
        return this.hasOne(TimeOffApprovalFlow, 'timeOffApprovalFlow');
    }

    set timeOffApprovalFlow(val: TimeOffApprovalFlow) {
        this.setOne('timeOffApprovalFlow', val);
    }

    get timeOffType(): TimeOffType {
        return this.hasOne(TimeOffType, 'timeOffType');
    }

    set timeOffType(val: TimeOffType) {
        this.setOne('timeOffType', val);
    }

    get timeOffRequestRule(): TimeOffRequestRules {
        return this.hasOne(TimeOffRequestRules, 'timeOffRequestRule');
    }

    set timeOffRequestRule(val: TimeOffRequestRules) {
        this.setOne('timeOffRequestRule', val, 'timeOffRequestRulesId');
    }

    get futureAssignedNonTerminatedEmployees(): Employee[] {
        return this.hasMany(Employee, 'futureAssignedNonTerminatedEmployees');
    }

    get currentlyAssignedNonTerminatedEmployees(): Employee[] {
        return this.hasMany(Employee, 'currentlyAssignedNonTerminatedEmployees');
    }

    get currentNonTerminatedEmployeeTimeOffPolicies(): EmployeeTimeOffPolicies[] {
        return this.hasMany(EmployeeTimeOffPolicies, 'currentNonTerminatedEmployeeTimeOffPolicies');
    }

    get futureAssignedNonTerminatedEmployeeTimeOffPolicies(): EmployeeTimeOffPolicies[] {
        return this.hasMany(EmployeeTimeOffPolicies, 'futureAssignedNonTerminatedEmployeeTimeOffPolicies');
    }

    get employees(): Employee[] {
        return this.hasMany(Employee, 'employees');
    }

    get timeOffSeniorityOverrides(): TimeOffSeniorityOverride[] {
        return this.hasMany(TimeOffSeniorityOverride, 'timeOffSeniorityOverrides');
    }

    set timeOffSeniorityOverrides(val: TimeOffSeniorityOverride[]) {
        this.setMany('timeOffSeniorityOverrides', val);
    }

    get timeOffSeniorityOverridesPayload(): TimeOffSeniorityOverridePayload[] {
        return this._attributes['timeOffSeniorityOverridesPayload'];
    }

    set timeOffSeniorityOverridesPayload(val: TimeOffSeniorityOverridePayload[]) {
        this._attributes['timeOffSeniorityOverridesPayload'] = val;
    }

    get effectiveDate(): EffectiveDate {
        return this._attributes['effectiveDate'] || EffectiveDate.today;
    }

    set effectiveDate(val: EffectiveDate) {
        this._attributes['effectiveDate'] = val;
    }

    get effectiveAt(): string {
        return this._attributes['effectiveAt'];
    }
    // This property to help discern if there are multiple policies under the same time off type for an individual policy
    get isAMultiPolicy(): boolean {
        return this._isFuturePolicyOfExistingTimeOffType;
    }

    set isAMultiPolicy(val: boolean) {
        this._isFuturePolicyOfExistingTimeOffType = val;
    }

    get nextFuturePolicy(): TimeOffPolicy {
        return this._nextFuturePolicy;
    }

    set nextFuturePolicy(val: TimeOffPolicy) {
        this._nextFuturePolicy = val;
    }

    resetDateValues(timeOffPolicy: TimeOffPolicy): void {
        // This value always needs to be sent.
        timeOffPolicy.accrualFromHireDate = false;
        timeOffPolicy.accrualTiming = undefined;
        timeOffPolicy.startOfWeek = undefined;
        timeOffPolicy.weeklyFrequenciesAnchor = undefined;
        timeOffPolicy.dayOfMonthOne = undefined;
        timeOffPolicy.dayOfMonthTwo = undefined;
        timeOffPolicy.dayOfYearOne = undefined;
        timeOffPolicy.dayOfYearTwo = undefined;
        timeOffPolicy.dayOfYearThree = undefined;
        timeOffPolicy.dayOfYearFour = undefined;
    }

    serializeForAccrualPreview(): { [key: string]: string } {
        const previewParams = {};
        const attributes = this.getAttributes();

        this.accrualPreviewParams.forEach((param: string) => {
            if (Object.keys(attributes).includes(param) && attributes[param]) {
                previewParams[`params[${param}]`] = attributes[param];
            }
        });
        return previewParams;
    }

    // Used to verify if an accrual was set for any frequency on the model.
    hasAccrualValuesSet(frequency: string): boolean {
        if (!this.accrualTiming) {
            return;
        }

        switch (frequency) {
            case WEEKLY:
                return !!this.startOfWeek;
            case BIWEEKLY:
                return !!(this.startOfWeek && this.weeklyFrequenciesAnchor);
            case SEMIMONTHLY:
                return !!(this.dayOfMonthOne && this.dayOfMonthTwo);
            case MONTHLY:
                return !!this.dayOfMonthOne;
            case QUARTERLY:
                return !!(this.dayOfYearOne && this.dayOfYearTwo && this.dayOfYearThree && this.dayOfYearFour);
            case ANNUALLY:
                return !!this.dayOfYearOne;
        }
    }

    /**
     * Used by new carryover card to determine if a policy qualifies as an unlimited carryover policy
     */
    isUnlimitedCarryover(): boolean {
        return this.carryoverExpireAfter === null && this.carryoverCap === null && this.carryoverDate === null;
    }

    /**
     * Used by new carryover card to determine if a policy qualifies as an annual reset policy
     */
    isAnnualReset(): boolean {
        return !this.carryoverExpireAfter && this.carryoverCap === 0;
    }

    isEssentialFieldDirty(): boolean {
        return (
            this.essentialFields.find((field) => {
                // hack because laravel casts the date to a datetime
                if (field === 'carryoverDate') {
                    return (
                        moment(this._attributes[field], TimeOffDateFormatDashes).format(TimeOffDateFormatDashes) !==
                        moment(this._originalAttributes[field], TimeOffDateFormatDashes).format(TimeOffDateFormatDashes)
                    );
                }

                return this.isFieldDirty(field);
            }) !== undefined
        );
    }

    getCapOnDateOption(): CapOnOptions {
        if (!this.carryoverDate && !this.carriesOverOnHireDate) {
            return capOnOptions.yearEnd;
        }

        if (!this.carryoverDate && this.carriesOverOnHireDate) {
            return capOnOptions.dateHired;
        }

        if (
            moment(this.carryoverDate).format(TimeOffDateFormatDayMonthDashes) ===
            moment().startOf('year').format(TimeOffDateFormatDayMonthDashes)
        ) {
            return capOnOptions.yearEnd;
        }

        return capOnOptions.pickDate;
    }

    /**
     * @deprecated
     */
    static sendAssignment(policyId: number, body: AssignJSONApiRequest): Promise<any> {
        return Model.performPost(TimeOffResources.Assign.replace(':policy_id', policyId.toString()), body);
    }
}
