import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UrlHelperService } from '@app/services/url-helper.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

// Using similarity from AsyncPipe to avoid having to pipe |secure|async in HTML.
@Pipe({
    name: 'secure',
    pure: false,
})
export class SecurePipe implements PipeTransform, OnDestroy {
    private asyncPipe: AsyncPipe;

    private previousUrl: string;
    private _result: BehaviorSubject<any> = new BehaviorSubject<any>('');
    private result: Observable<any> = this._result.asObservable();
    private _internalSubscription: Subscription = null;

    constructor(
        private _ref: ChangeDetectorRef,
        private urlHelperService: UrlHelperService,
        private sanitizer: DomSanitizer
    ) {
        this.asyncPipe = new AsyncPipe(this._ref);
    }

    ngOnDestroy(): void {
        this._internalSubscription?.unsubscribe();
        this.asyncPipe.ngOnDestroy();
    }

    transform(url: string): any {
        const obj = this.internalTransform(url);
        return this.asyncPipe.transform(obj);
    }

    internalTransform(url: string): Observable<any> {
        if (!url) {
            return this.result;
        }

        if (this.previousUrl !== url) {
            this.previousUrl = url;
            this._internalSubscription = this.urlHelperService.get(url).subscribe((m) => {
                const sanitized = this.sanitizer.bypassSecurityTrustUrl(m);
                this._result.next(sanitized);
            });
        }

        return this.result;
    }
}
