<mat-icon svgIcon="clock"></mat-icon>
<input
    type="text"
    [placeholder]="placeholder"
    [required]="required"
    matInput
    [formControl]="inputControl"
    [matAutocomplete]="auto"
    (blur)="onBlur()"
/>
<mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="selected($event)"
>
    <mat-option *ngFor="let time of filteredTimes | async" [value]="time" (mousedown)="blurLock = true">
        {{ time }}
    </mat-option>
</mat-autocomplete>
