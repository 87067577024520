<label #label [class.file-selected]="!!value" [class.read-only]="readOnly"
    >{{ value?.name || placeholder }}
    <input
        [accept]="getAllowedTypes()"
        [attr.id]="id"
        (change)="onFileSelected(fileInput.files)"
        #fileInput
        type="file"
        style="display: none"
/></label>
<button *ngIf="hasFile" (click)="$event.preventDefault(); $event.stopPropagation(); clear()" class="remove-attachment">
    {{ 'components.file-upload.removeAttachment' | appTranslate }}
</button>
