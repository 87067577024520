import { CreateBaseSetting } from '@app/models/settings/base-setting.model';

export const SSO_PROVIDERS = [
    'google',
    'microsoft',
    'okta',
    //,'azure' // ! PDS-1717: Disabling azure and okta since they're not currently available for use within the mobile app
] as const;

export type SSOProvider = (typeof SSO_PROVIDERS)[number];

export class CompanySSO extends CreateBaseSetting<CompanySSO, SSOProvider[]>([]) {
    protected static _resource = 'companyManagement/companies/:company/companySSO';

    protected static _serializeAttributes = [...super._serializeAttributes, 'companyId'];

    protected static _version = 'v2';

    async enableProvider(provider: SSOProvider): Promise<SSOProvider[]> {
        if (this.value.includes(provider)) {
            throw new Error('Provider already enabled');
        }
        this.value = [...this.value, provider];
        return this.saveModel();
    }

    async disableProvider(provider: SSOProvider): Promise<SSOProvider[]> {
        if (!this.value.includes(provider)) {
            throw new Error('Provider already disabled');
        }
        this.value = this.value.filter((p) => p === provider);
        return this.saveModel();
    }

    async setEnabledProviders(providers: SSOProvider[]): Promise<SSOProvider[]> {
        const invalidProviders = providers.find((provider) => !SSO_PROVIDERS.includes(provider));
        if (invalidProviders) {
            throw new Error(`Invalid provider ${invalidProviders} in provider array`);
        }
        this.value = providers;
        return this.saveModel();
    }

    private async saveModel(): Promise<SSOProvider[]> {
        return this.save({ includeId: false }).then((companySSO) => companySSO.value);
    }
}
