import { Model } from '../core/base.model';
import { Employee } from '@models/employee/employee.model';

export abstract class Response extends Model {
    autoSaved = false;

    set id(val: number) {
        this._attributes['id'] = val;
    }
    get id(): number {
        return this._attributes['id'];
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get jsonData(): any {
        return this._attributes['jsonData'];
    }

    set jsonData(value: any) {
        this._attributes['jsonData'] = value;
    }

    get submittedAt(): Date {
        return this._attributes['submittedAt'];
    }
    set submittedAt(val: Date) {
        this._attributes['submittedAt'] = val;
    }

    /* Timestamps */
    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get updatedAt(): Date {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): Date {
        return this._attributes['deletedAt'] || null;
    }

    protected static _dates = ['submittedAt'];

    protected static _serializeAttributes = ['jsonData', 'submittedAt'];
}
