/**
 * These are the possible non-null values for legal sex
 * legal sex can also be null
 */
export enum LegalSexEnum {
    Male = 'M',
    Female = 'F',
    Other = 'X',
    PreferNotToSay = 'P',
}
