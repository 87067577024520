import { Injectable } from '@angular/core';
import { Employee } from '@models/employee/employee.model';
import { ApprovalInstanceStep } from '@models/approvals/approval-instance-step.model';
import { StepStatus } from '@app/enums';
import { ApprovalInstance } from '@models/approvals/approval-instance.model';
import { HttpClient } from '@angular/common/http';

/**
 * Use this service to control the approval flow of an instance and get back certain
 * states like current approver, next approver, etc.
 */
@Injectable()
export class ApprovalInstanceService {
    private url = '/v2/approvals/instances';

    constructor(private http: HttpClient) {}

    isCurrentApprover(approvalInstance: ApprovalInstance, employee: Employee): boolean {
        if (approvalInstance.isResolved()) {
            return false;
        }
        return employee.is(this.getCurrentApprover(approvalInstance));
    }

    getCurrentStep(approvalInstance: ApprovalInstance): ApprovalInstanceStep | null {
        const pendingSteps = approvalInstance.steps.filter((step) => step.status === StepStatus.Pending);

        if (pendingSteps.length) {
            return pendingSteps[0];
        }

        return null;
    }

    getCurrentApprover(approvalInstance: ApprovalInstance): Employee | null {
        return this.getCurrentStep(approvalInstance)?.employee;
    }

    async approve(approvalInstance: ApprovalInstance): Promise<void> {
        const endpoint = `${this.url}/${approvalInstance.id}/steps/${this.getCurrentStep(approvalInstance).id}/approve`;
        await this.http.post(endpoint, {}).toPromise();
    }

    async deny(approvalInstance: ApprovalInstance, closingComments?: string): Promise<void> {
        const endpoint = `${this.url}/${approvalInstance.id}/steps/${this.getCurrentStep(approvalInstance).id}/deny`;
        await this.http
            .post(endpoint, {
                closingComments,
            })
            .toPromise();
    }

    async forceApprove(approvalInstance: ApprovalInstance, closingComments?: string): Promise<void> {
        const endpoint = `${this.url}/${approvalInstance.id}/forceApprove`;
        await this.http
            .post(endpoint, {
                closingComments,
            })
            .toPromise();
    }

    async forceDeny(approvalInstance: ApprovalInstance, closingComments?: string): Promise<void> {
        const endpoint = `${this.url}/${approvalInstance.id}/forceDeny`;
        await this.http
            .post(endpoint, {
                closingComments,
            })
            .toPromise();
    }
}
