import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], key: string, value: any): any {
        if (!items || !items.length) {
            return [];
        }

        if (value === null || value === 'null' || typeof value === 'undefined') {
            return items;
        }

        return items.filter((item) => {
            if (!item) {
                return false;
            }

            if (typeof item[key] === 'undefined' || item[key] === null) {
                return false;
            }

            const isEqualAsNumber = Number(item[key]) === Number(value);
            const isEqualAsString = item[key].toString() === value.toString();

            return isEqualAsNumber || isEqualAsString;
        });
    }
}
