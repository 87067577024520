import { ModelMixin } from '@app/models/core/base-generic.model';
import { Project } from '@app/models/time-tracking/project.model';
import { Employee } from '@models/employee/employee.model';

export class ProjectOwnership extends ModelMixin<ProjectOwnership>() {
    protected static _type = 'timeTracking.projectsOwners';

    get ownerId(): number {
        return this._attributes['ownerId'];
    }

    get projectId(): number {
        return this._attributes['projectId'];
    }

    get owner(): Employee {
        return this.hasOne(Employee, 'owner');
    }

    get project(): Project {
        return this.hasOne(Project, 'project');
    }
}
