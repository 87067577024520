import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankAccount } from '@app/models/company/bank-account.model';
import { AuthService } from '@app/services/auth.service';
import { CompanyInformation } from '@payroll/interfaces';
import { PayrollResources } from '@payroll/payroll.resources';

const DEFAULT_CUSTOM_ORIGINATOR = 'Humi Payroll';

type PayrollCompany = {
    data: {
        id: string;
        type: 'hr_companies';
        attributes: {
            craPayrollAccount: { businessNumber: string; programCode: string; referenceNumber: string };
            doingBusinessAsName: string;
            incorporationDate: string;
            hrCompanyId: number;
            name: string;
            phoneNumber: string;
            remitterType: string;
            website: string;
            bankAccountStatus: string;
            timeOffYtdsUpdatable: boolean;
            customOriginator: string;
            showOnboardingMenu: string;
            quebecEnterpriseNumber: string;
            rqRemittanceFrequency: string;
            rqPayrollAccount: { rqRegistration: string; rqProgramCode: string; rqReferenceNumber: string };
        };
    };
};

@Injectable()
export class CompanyInformationService {
    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    async getCompanyInformation(): Promise<CompanyInformation> {
        const hrCompany = this.auth.company;
        const bankAccount = await BankAccount.param('company', hrCompany.id).find(hrCompany.bankAccountId);

        const payrollCompany = await this.http
            .get<PayrollCompany>(PayrollResources.Companies.replace(':companyId', hrCompany.id.toString()))
            .toPromise();

        const { id, attributes } = payrollCompany.data;

        return {
            companyId: attributes.hrCompanyId,
            prCompanyId: +id,
            doingBusinessAsName: attributes.doingBusinessAsName,
            businessNumber: attributes.craPayrollAccount.businessNumber,
            programCode: attributes.craPayrollAccount.programCode,
            referenceNumber: attributes.craPayrollAccount.referenceNumber,
            remitterType: attributes.remitterType,
            institution: bankAccount.institutionNumber,
            branch: bankAccount.branchNumber,
            account: bankAccount.accountNumber,
            customOriginator: attributes.customOriginator || DEFAULT_CUSTOM_ORIGINATOR,
            quebecEnterpriseNumber: attributes.quebecEnterpriseNumber,
            rqRegistration: attributes.rqPayrollAccount.rqRegistration,
            rqProgramCode: attributes.rqPayrollAccount.rqProgramCode,
            rqReferenceNumber: attributes.rqPayrollAccount.rqReferenceNumber,
            rqRemittanceFrequency: attributes.rqRemittanceFrequency,
        };
    }

    async updateCompanyInformation(companyInformation: CompanyInformation): Promise<unknown> {
        const response = await this.http
            .put(
                PayrollResources.SelfOnboardingCompanies.replace(
                    ':companyId',
                    companyInformation.prCompanyId.toString()
                ),
                {
                    data: { attributes: companyInformation },
                }
            )
            .toPromise();

        await this.syncDataToHR(companyInformation);

        return response;
    }

    private async syncDataToHR(companyInformation: CompanyInformation): Promise<Array<unknown>> {
        const company = this.auth.company;
        const bankAccount = await BankAccount.param('company', company.id).find(company.bankAccountId);

        company.doingBusinessAsName = companyInformation.doingBusinessAsName;
        company.craBusinessNumber = companyInformation.businessNumber;
        company.craProgramCode = companyInformation.programCode;
        company.craReferenceNumber = companyInformation.referenceNumber;
        company.remitterType = companyInformation.remitterType;
        company.quebecEnterpriseNumber = companyInformation.quebecEnterpriseNumber;
        company.rqRemittanceFrequency = companyInformation.rqRemittanceFrequency;
        company.rqRegistration = companyInformation.rqRegistration;
        company.rqProgramCode = companyInformation.rqProgramCode;
        company.rqReferenceNumber = companyInformation.rqReferenceNumber;
        bankAccount.institutionNumber = companyInformation.institution;
        bankAccount.branchNumber = companyInformation.branch;
        bankAccount.accountNumber = companyInformation.account;

        return await Promise.all([company.save(), bankAccount.save()]);
    }
}
