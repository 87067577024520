import { ModelMixin } from '@app/models/core/base-generic.model';
import { Project } from '@app/models/time-tracking/project.model';
import { Employee } from '@models/employee/employee.model';

export class ProjectAssignment extends ModelMixin<ProjectAssignment>() {
    protected static _type = 'timeTracking.projectsAssignments';
    protected static _resource = 'timeTracking/projectAssignments';
    protected static _version = 'v2';

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get projectId(): number {
        return this._attributes['projectId'];
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get project(): Project {
        return this.hasOne(Project, 'project');
    }

    /**
     * timesheetsSumMinutes is the total number of minutes
     * logged against this project for this employee
     */
    get timesheetsSumMinutes(): number {
        return this._attributes['timesheetsSumMinutes'];
    }

    /**
     * timesheetsApprovedSumMinutes is the total number approved of minutes
     * logged against this project for this employee
     */
    get timesheetsApprovedSumMinutes(): number {
        return this._attributes['timesheetsApprovedSumMinutes'];
    }
}
