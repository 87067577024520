import { FunnelMessageTemplate } from '@models/recruiting/funnel-message-template.model';
import { JobFunnel } from '@models/recruiting/job-funnel.model';
import { ModelMixin } from '../core/base-generic.model';

export class JobFunnelMessageTemplate extends ModelMixin<FunnelMessageTemplate>() {
    protected static _resource = 'applicantTracker/jobFunnelMessageTemplates';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _serializeAttributes = ['atsJobFunnelId', 'replyTo', 'subject', 'body'];

    get jobFunnel(): JobFunnel {
        return this.hasOne(JobFunnel, 'jobFunnel');
    }

    set jobFunnel(value: JobFunnel) {
        this.setOne('jobFunnel', value, 'atsJobFunnelId');
    }

    get atsJobFunnelId(): number {
        return this._attributes['atsJobFunnelId'];
    }

    set atsJobFunnelId(value: number) {
        this._attributes['atsJobFunnelId'] = value;
    }

    get replyTo(): string {
        return this._attributes['replyTo'];
    }

    set replyTo(val: string) {
        this._attributes['replyTo'] = val;
    }

    get subject(): string {
        return this._attributes['subject'];
    }

    set subject(val: string) {
        this._attributes['subject'] = val;
    }

    get body(): string {
        return this._attributes['body'];
    }

    set body(val: string) {
        this._attributes['body'] = val;
    }

    toFunnelMessageTemplate(atsFunnelMessageTemplateId?: number): FunnelMessageTemplate {
        return new FunnelMessageTemplate({
            atsFunnelMessageTemplateId: atsFunnelMessageTemplateId,
            replyTo: this.replyTo,
            subject: this.subject,
            body: this.body,
        });
    }
}
