import { DataField } from '@app/models/settings/data-field.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TranslatableKey } from '@app/types/translatable.type';

export class GroupColumn extends Column {
    title = 'settings.custom-fields.field-index-table.group';
    property = 'dataFieldGroup';
    sortable = false;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(object: DataField): string | null | TranslatableKey {
        if (!object.dataFieldGroup) {
            return null;
        }

        return object.dataFieldGroup.translatableName;
    }
}
