import { Roe } from '@app/models/payroll/roe.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class RoeDateCreatedColumn extends Column {
    title = 'table.roes.dateCreated';
    property = 'lastDateForWhichPaid';
    sortField = 'lastDateForWhichPaid';
    sortable = true;
    type: CellDisplay = CellDisplay.date;

    getDisplayPropertyValue(roe: Roe): string {
        return roe.lastDateForWhichPaid ?? '-';
    }
}
