export enum TimeWorkedImportState {
    DONE = 'done',
    IN_PROGRESS = 'processing',
    PENDING = 'pending',
}

export interface TimeWorkedImport {
    payrollId: number;
    state: TimeWorkedImportState;
}

export interface TimeWorkedImportOngoing extends TimeWorkedImport {
    state: TimeWorkedImportState.PENDING | TimeWorkedImportState.IN_PROGRESS;
}

export const isOngoing = (timeWorkedImport: TimeWorkedImport): timeWorkedImport is TimeWorkedImportOngoing =>
    timeWorkedImport.state !== TimeWorkedImportState.DONE;

export interface TimeWorkedImportPayload {
    data: {
        attributes: TimeWorkedImport;
    }[];
}
