import { SimpleEntityActions } from '@app/classes/simple-entity-actions.class';
import { TemplateType } from '@app/modules/performance/enums/template-type.enum';
import { ModelMixin } from '../core/base-generic.model';

export class Template extends ModelMixin<Template>() {
    static performancePermission = new SimpleEntityActions('performanceTemplate');

    static surveyPermission = new SimpleEntityActions('surveyTemplate');

    protected static _resource = 'performanceManagement/templates';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['name', 'description', 'jsonData', 'type'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get type(): TemplateType {
        return this._attributes['type'];
    }

    set type(val: TemplateType) {
        this._attributes['type'] = val;
    }

    get jsonData(): any {
        return this._attributes['jsonData'];
    }

    set jsonData(val: any) {
        this._attributes['jsonData'] = val;
    }

    get isValid(): boolean {
        return this._attributes['isValid'];
    }
}
