export const DEFAULT_PRONOUNS_LIST = [
    {
        tag: 'components.add-pronouns.pronouns.sheHer',
        value: ['she', 'her'],
    },
    {
        tag: 'components.add-pronouns.pronouns.heHim',
        value: ['he', 'him'],
    },
    {
        tag: 'components.add-pronouns.pronouns.theyThem',
        value: ['they', 'them'],
    },
] as const;
