import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogComponent } from '@app/components';
import { BehaviorSubject, Observable } from 'rxjs';

export type DialogConfig = Partial<DialogComponent>;

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private state$ = new BehaviorSubject<DialogConfig>({ open: false, header: '' });
    private portal$ = new BehaviorSubject<TemplatePortal | undefined>(undefined);

    get state(): Observable<DialogConfig> {
        return this.state$.asObservable();
    }

    get portal(): Observable<TemplatePortal | undefined> {
        return this.portal$.asObservable();
    }
    setPortal(templateRef: TemplateRef<unknown>, viewContainerRef: ViewContainerRef, dialogConfig: DialogConfig): void {
        this.portal$.next(new TemplatePortal(templateRef, viewContainerRef));
        this.state$.next(dialogConfig);
    }

    open(): void {
        this.state$.next({ ...this.state$.value, open: true });
    }

    close(): void {
        this.state$.next({ ...this.state$.value, open: false });
    }

    setForm(form: NgForm): void {
        this.state$.next({ ...this.state$.value, form });
    }
}
