import { Model } from '@models/core/base.model';
import { TimeOffRequestApproval } from '@models/time-off-v3/time-off-request-approval.model';
import { TimeOffRequestApprovalFlow } from '@models/time-off-v3/time-off-request-approval-flow.model';

export class TimeOffComment extends Model {
    get type(): number {
        return this._attributes['type'];
    }

    get comment(): number {
        return this._attributes['comment'];
    }

    get timeOffRequestApproval(): TimeOffRequestApproval {
        return this.hasOne(TimeOffRequestApproval, 'timeOffRequestApproval');
    }

    get timeOffRequestApprovalFlow(): TimeOffRequestApprovalFlow {
        return this.hasOne(TimeOffRequestApprovalFlow, 'timeOffRequestApprovalFlow');
    }
}
