import { EnvironmentSettings } from './environment-interface';

export const common: Partial<EnvironmentSettings> = {
    version: '2.0.0',
    build: '2018-04-18T18:13:46.698Z',
    stripeProducts: {
        // ! These values should always match the values provided by the HR application in the [StripePrice Enum](https://github.com/Humi-HR/application/blob/master/app/Modules/SelfServeOnboarding/Enums/StripePrice.php)
        selfServe: {
            hr: 'price_1OWi1qCCh7XUKRwDLi0lcbrT',
            'hr-payroll': 'price_1OWi2XCCh7XUKRwDvBQatgIB',
            'hr-timeOff': 'price_1OWi3bCCh7XUKRwDQxuVQwwv',
            'hr-payroll-timeOff': 'price_1OWi33CCh7XUKRwDqXOUkVcQ',
        },
    },
    openWeatherMap: '8388e7b0196819490ebd52d5acfae446',
    taxRates: {
        AB: 0 + 5,
        BC: 7 + 5,
        MB: 8 + 5,
        NB: 10 + 5,
        NL: 10 + 5,
        NT: 0 + 5,
        NS: 10 + 5,
        NU: 0 + 5,
        ON: 8 + 5,
        PE: 10 + 5,
        QC: 9.975 + 5,
        SK: 6 + 5,
        YT: 0 + 5,
    },
    trackjs: '5e45e7402c9d440f9031bd6c2bf87609',
    beamer: 'QDzlmFMc18751',
    enableBeamer: false,
    handsOnTable: '5e8c0-5064f-f53a3-74944-8445e',
    locale: 'en_CA',
    zenDesk: { apiKey: 'c6c29ccc-2cc3-467b-b07a-fca82d55d062', humiContactFormId: 11771913131287 },
    agGridEnterpriseKey:
        'Using_this_{AG_Grid}_Enterprise_key_{AG-053332}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Humi_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Humi}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Humi}_need_to_be_licensed___{Humi}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_January_2025}____[v3]_[01]_MTczNjU1MzYwMDAwMA==48618473e1efce98d6905055af85bb70', // Yes, this really is the key
};
