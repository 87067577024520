import { css } from 'lit';

export const tipStyles = css`
    :host {
        display: flex;
    }

    .tip {
        display: flex;
        gap: 0.25rem;

        padding: var(--tip-default-padding);
        border-radius: var(--tip-default-border-radius);
        font-size: var(--tip-default-font-size);
        line-height: var(--tip-default-line-height);
        color: var(--tip-default-font-color);
        background-color: var(--tip-default-background-color);

        svg {
            width: var(--tip-default-icon-size);
            height: var(--tip-default-icon-size);

            // Ensures the bulb icon always appears in a floated spot in the top left corner even when text wraps to the next line
            margin-top: calc((var(--tip-default-line-height) - var(--tip-default-icon-size)) / 2);
            flex-shrink: 0;
        }
    }
`;
