import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelfOnboardingStep } from '@app/models/payroll/self-onboarding-step.model';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class PayrollSelfOnboardingService {
    constructor(private http: HttpClient) {}

    async getSteps(): Promise<SelfOnboardingStep[]> {
        const [steps] = await SelfOnboardingStep.get();
        return steps;
    }

    async getSelfOnboardingStatus(): Promise<any> {
        return await this.http.get(PayrollResources.SelfOnboardingStatus, {}).toPromise();
    }

    async getSelfOnboardingIsComplete(): Promise<boolean> {
        const selfOnboardingStatus = await this.getSelfOnboardingStatus();
        const onboardingComplete = selfOnboardingStatus.data.attributes.onboarding_complete;
        return onboardingComplete;
    }
}
