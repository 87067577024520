import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * @deprecated
 */
@Directive({
    selector: '[deprecated-ui-rating]',
})
export class DeprecatedUiRatingDirective implements OnInit {
    @Input() status = 'enable';
    @Input() wait = false;
    @Output() rating: EventEmitter<any> = new EventEmitter<any>();
    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        if (this.wait) {
            return;
        }

        setTimeout(() => {
            $(this.element.nativeElement).rating({
                interactive: this.status === 'enable',
                onRate: (value) => this.rating.emit(value),
            });
        });
    }
}
