import { DateRange } from '@angular/material/datepicker';
import { ColumnFilter } from '@app/modules/table/classes/column-filters/column-filter.abstract';
import { ColumnFilterDisplay } from '@app/modules/table/types/column-filter-display.type';
import { TimeOffDateFormatDashes } from '@time-off-v3/meta/time-off-meta';
import { Moment } from 'moment';
import { Filter } from '../../filter.abstract';

export const isDateRangeFilter = (filter: Filter): filter is DateRangeFilter => filter.type === 'dateRange';
export class DateRangeFilter extends ColumnFilter {
    type: ColumnFilterDisplay = 'dateRange';
    startDate?: Moment;
    endDate?: Moment;

    constructor(
        protected startFieldName: string,
        protected endFieldName?: string,
        label?: string
    ) {
        super();
        this.label = label;
        this.isReady = true;
    }

    clear(): void {
        this.startDate = null;
        this.endDate = null;
        this.emitChanges();
    }

    hasValues(): boolean {
        return !!this.startDate && !!this.endDate;
    }

    setDateRange(dateRange: DateRange<Moment>): void {
        this.startDate = dateRange.start;
        this.endDate = dateRange.end;
        this.emitChanges();
    }

    private emitChanges(): void {
        const to: string | null = this.endDate ? this.endDate.format(TimeOffDateFormatDashes) : null;
        const from: string | null = this.startDate ? this.startDate.format(TimeOffDateFormatDashes) : null;

        if (this.endFieldName) {
            this.onChanges.next([
                { field: `${this.startFieldName}.to`, value: to },
                { field: `${this.endFieldName}.from`, value: from },
            ]);

            return;
        }

        this.onChanges.next([
            { field: `${this.startFieldName}.to`, value: to },
            { field: `${this.startFieldName}.from`, value: from },
        ]);
    }
}
