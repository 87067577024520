import { Model } from '@models/core/base.model';

export class ScheduledVacationPayoutOrRepayment extends Model {
    protected static _resource = 'payroll/payrolls/:payroll/payStubs';
    protected static _version = 'v2';

    get amount(): string {
        return this._attributes['amount'];
    }

    get clearVacationTimeBalance(): boolean {
        return this._attributes['clearVacationTimeBalance'];
    }
}
