import { ErrorStateMatcher } from '@angular/material/core';
import { Filter } from '@app/modules/table/classes/filter.abstract';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';
import { ModelUnion as Model } from '@models/core/base.model';

type DisplayModification = {
    obscure: boolean; // blurs text when not hovered
};

export abstract class Column {
    /**
     * This property can be used to set custom validations to show/hide error messages on interactable cells.
     */
    errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();

    /**
     * This will be used to show/hide save action progress icons next to cells.
     */
    showSaveActionIcons = false;

    /**
     * The property to retrieve from within a model/data object.
     *
     * ex: property: string = 'name';
     *     In the HTML:
     *         <div *ngFor="let value of values">{{ value[column.property] }}</div>
     *
     * NOTE: not required for Avatar cells
     */
    property: string | null = null;

    /**
     * Should the column header be sortable?
     */
    sortable = false;

    /**
     * If the value that this column sorts as for the API is not the same as the property, override this property to
     * sort by the override instead.
     *
     * ex: a model has a calculated property `fullName` which is comprised of firstName + lastName
     * and we want the column to be sortable by last name, we would set this property to 'lastName'
     */
    sortField: string = null;

    /**
     * The various options that a column can be filtered by.
     */
    filter?: Filter;

    /**
     * The width of this column. If it's not set, will be determined automatically by the table (even distribution).
     */
    displayWidth: number;

    displayModification: Partial<DisplayModification> = {};

    hasIcon = false;

    /**
     * The display of the column name on the table. Will be translated if a matching key is found.
     * @see /src/assets/i18n/table/
     */
    abstract title: Translatable;

    /**
     * How the cell displays its data in the table
     */
    abstract type: CellDisplay;

    /**
     * Retriever for figuring out how to sort
     */
    get sortProperty(): string {
        return this.sortField || this.property;
    }

    isFiltered(): boolean {
        return !!this.filter?.hasValues();
    }

    /**
     * If this column has filters, clear em all.
     */
    clear(): void {
        if (this.filter?.hasValues()) {
            this.filter.clear();
        }
    }

    getDisplayPropertyValue(object: Model): string | number | Model | null | Date | Translatable {
        return !this.property
            ? object
            : this.property.split('.').reduce((previous: any, current: any): any => {
                  if (previous) {
                      previous = previous[current];
                  }

                  return previous;
              }, object);
    }

    /**
     * Override this method to apply styling using ngStyle
     */
    getRowStyling(_: Model): null | { [key: string]: string } {
        return;
    }

    /**
     * If a column may have an icon, set hasIcon property to true.
     * Override this method to get the current icon. May return any valid SvgIcon - add custom icons in ShapeIcons.
     */
    getIcon(_: Model): null | string {
        return null;
    }

    getIconStyle(_: Model): null | { [key: string]: string } {
        return null;
    }
}
