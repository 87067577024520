import { IntegrationType } from '@models/common/integration-type.model';
import { Model } from '@models/core/base.model';

export abstract class SettingsIntegration extends Model {
    protected static _type = 'integrations';

    protected static _resource = 'companyManagement/companies/:company/integrations';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['isEnabled'];

    get integrationType(): IntegrationType {
        return this.hasOne(IntegrationType, 'integrationType');
    }

    get integrationTypeId(): number {
        return this._attributes['integrationTypeId'];
    }

    get integrationDetailId(): number {
        return this._attributes['integrationDetailId'];
    }

    get isEnabled(): boolean {
        return this._attributes['isEnabled'];
    }
    set isEnabled(val: boolean) {
        this._attributes['isEnabled'] = val;
    }
}
