import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { tipStyles } from './styles';
import { BULB_ICON } from './svgComponents';

/**
 * Tip component used for displaying helpful tips to users
 */
@customElement('humi-tip')
export class Tip extends LitElement {
    static styles = tipStyles;

    render() {
        return html`<div class="tip">
            ${BULB_ICON}
            <div><slot></slot></div>
        </div>`;
    }
}
