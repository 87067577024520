import { IndeedIntegrationService } from '@applicant-tracker/services/indeed-integration.service';
import { BillingService } from '@settings/services/billing.service';
import { FieldGroupsService } from '@settings/services/field-groups.service';
import { FieldsService } from '@settings/services/fields.service';
import { CustomFieldGroupsIndexTable } from '@settings/services/tables/field-groups-table/custom-field-groups-index.table';
import { DefaultFieldGroupsIndexTable } from '@settings/services/tables/field-groups-table/default-field-groups-index.table';
import { CompanyLogoService } from './company-logo.service';
import { SettingsIntegrationService } from './integration.service';
import { ResthookAPIService } from './resthook-api.service';
import { SettingsMenuService } from './settings-menu.service';
import { SettingsRoleMenuService } from './settings-role-menu.service';
import { CustomRolesIndexTable } from './tables/custom-roles-table/custom-roles-index.table';
import { DefaultRolesIndexTable } from './tables/default-roles-table/default-roles-index.table';
import { FieldsIndexTable } from './tables/fields-table/fields-index.table';
import { XeroIntegrationService } from './xero-integration.service';
import { XeroManageService } from './xero-manage.service';

const Services = [
    BillingService,
    SettingsIntegrationService,
    DefaultRolesIndexTable,
    CustomRolesIndexTable,
    FieldsIndexTable,
    CustomFieldGroupsIndexTable,
    DefaultFieldGroupsIndexTable,
    IndeedIntegrationService,
    FieldGroupsService,
    CompanyLogoService,
    FieldsService,
    XeroIntegrationService,
    ResthookAPIService,
    XeroManageService,
    SettingsMenuService,
    SettingsRoleMenuService,
];

export {
    BillingService,
    CompanyLogoService,
    CustomFieldGroupsIndexTable,
    CustomRolesIndexTable,
    DefaultFieldGroupsIndexTable,
    DefaultRolesIndexTable,
    FieldGroupsService,
    FieldsIndexTable,
    FieldsService,
    IndeedIntegrationService,
    ResthookAPIService,
    Services,
    SettingsIntegrationService,
    SettingsMenuService,
    SettingsRoleMenuService,
    XeroIntegrationService,
    XeroManageService,
};
