import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'dateToConversationalDate' })
export class DateToConversationalDatePipe implements PipeTransform {
    transform(date): string {
        return moment.utc(date).local().calendar(null, {
            sameDay: '[Today at] LT',
            nextDay: '[Tomorrow at] LT',
            nextWeek: 'dddd',
            lastDay: '[Yesterday at] LT',
            lastWeek: '[last] dddd',
            sameElse: 'DD/MM/YYYY',
        });
    }
}
