import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class HolidaysService {
    private apiBaseUrl = '/v1/timeoff';
    constructor(private http: HttpClient) {}

    /*************************
     * Users
     *********************** */
    get(userId, startAt, endAt): Promise<any> {
        return this.http
            .get(`${this.apiBaseUrl}/holidays`, {
                params: { user_id: userId, start_at: startAt, end_at: endAt },
            })
            .toPromise()
            .then(this.returnJson)
            .catch(this.throwError);
    }

    /*************************
     * Callbacks
     *********************** */
    private returnJson(res: HttpResponse<any>) {
        try {
            return Promise.resolve(res);
        } catch (e) {
            console.error('Failed to parse the JSON response from the server', res);
            return Promise.reject(res);
        }
    }

    private throwError(error: any) {
        return Promise.reject(error);
    }
}
