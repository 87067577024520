<mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
    (optionSelected)="selected($event)"
    [displayWith]="displayFn"
>
    <mat-option *ngFor="let option of options" [value]="option.value"> {{ option.label }} </mat-option>
</mat-autocomplete>

<input
    type="text"
    [placeholder]="placeholder"
    aria-label="template select"
    [class.read-only]="readOnly"
    matInput
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    (blur)="onBlur()"
/>

<img *ngIf="loading" class="loading" appIcon="Spinner" />
