import { Component, Input } from '@angular/core';
import { TimeOffType } from '@models/time-off-v3/time-off-type.model';
import { TimeOffBalance } from '@time-off-v3/interfaces/time-off-balance.interface';
import { DECIMAL_PLACES_FOR_PIPE, TimeOffDateFormatDashes } from '@time-off-v3/meta/time-off-meta';
import { TimeOffService } from '@time-off-v3/services/time-off.service';
import moment, { Moment } from 'moment';

@Component({
    selector: 'app-calculate-balance-form',
    templateUrl: 'calculate-balance.template.html',
    styleUrls: ['calculate-balance.style.scss'],
})
export class CalculateBalanceForm {
    @Input() employeeId: number;
    @Input() timeOffTypes: TimeOffType[];
    selectedDate: Moment = moment();
    balance = 0;
    futureRequestsAmountApproved = 0;
    futureRequestsAmountPending = 0;
    timeOffTypeId: number;
    isLoading = false;
    timeOffType: TimeOffType;
    decimalPlacesForPipe = DECIMAL_PLACES_FOR_PIPE;

    constructor(private timeOffService: TimeOffService) {}

    getBalanceOnDateSelect(): void {
        this.isLoading = true;
        this.timeOffType = this.timeOffTypes.find((timeOffType) => timeOffType.id === this.timeOffTypeId);
        this.timeOffService
            .getBalance(this.employeeId, {
                'filters[timeOffTypeId]': this.timeOffTypeId.toString(),
                'filters[processedAt][to]': this.selectedDate.format(TimeOffDateFormatDashes),
            })
            .then(async (res: TimeOffBalance) => {
                const isHourly = this.timeOffType.displayInHours;

                this.balance = isHourly ? res.balanceHours : res.balance;
                this.futureRequestsAmountApproved = isHourly ? res.futureHoursApproved : res.futureDaysApproved;
                this.futureRequestsAmountPending = isHourly ? res.futureHoursPending : res.futureDaysPending;

                this.isLoading = false;
            });
    }
}
