import { JournalEntryReportItemTemplateCategories } from '@app/modules/settings/enums/journal-entry-report-line-item-categories.enum';
import { Model } from '@models/core/base.model';

export class JournalEntryReportItemTemplate extends Model {
    protected static _resource = 'payroll/journalEntryReportItemTemplates';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['glCode', 'separateByDepartment'];

    /*
        GL Codes stand for General Ledger code. A general ledger is a document that
        accountants use to store and organize financial data in order to create the 
        company's financial statements. Companies will have their own unique GL Codes.
    */
    get glCode(): string {
        return this._attributes['glCode'];
    }

    set glCode(val: string) {
        this._attributes['glCode'] = val;
    }

    get displayName(): string {
        return this._attributes['displayName'];
    }

    get category(): string {
        const category = this._attributes['category'];
        if (category === JournalEntryReportItemTemplateCategories.IncomeType) {
            return JournalEntryReportItemTemplateCategories.IncomeType;
        }

        if (category === JournalEntryReportItemTemplateCategories.Deductions) {
            return JournalEntryReportItemTemplateCategories.Deductions;
        }

        if (category === JournalEntryReportItemTemplateCategories.Benefits) {
            return JournalEntryReportItemTemplateCategories.Benefits;
        }
    }

    get separateByDepartment(): boolean {
        return this._attributes['separateByDepartment'];
    }

    set separateByDepartment(val: boolean) {
        this._attributes['separateByDepartment'] = val;
    }
}
