import { Model } from '@models/core/base.model';
import { getTime, setTime } from '@time-off-v3/functions/time-unit-converter';
import { TimeOffPolicy } from '@models/time-off-v3/time-off-policy.model';
import { TimeOffType } from '@models/time-off-v3/time-off-type.model';
import { DECIMAL_PLACES } from '@time-off-v3/meta/time-off-meta';

// *This model only supports updating*

export class TimeOffRequestRules extends Model {
    protected static _resource = 'timeOffV3/requestRules';

    protected static _type = 'timeOffRequestRules';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['negativeBalanceCap', 'waitingPeriod', 'minDayAmount', 'maxLength'];

    get id(): number {
        return this._attributes['id'];
    }

    get negativeBalanceCap(): number | null {
        return (
            this._attributes['negativeBalanceCap'] &&
            +getTime(Math.abs(this._attributes['negativeBalanceCap']), this.timeOffType)?.toFixed(DECIMAL_PLACES)
        );
    }

    get originalNegativeBalanceCap(): number | null {
        return (
            this.getOriginalAttribute('negativeBalanceCap') &&
            +getTime(Math.abs(this.getOriginalAttribute('negativeBalanceCap')), this.timeOffType)?.toFixed(
                DECIMAL_PLACES
            )
        );
    }

    get waitingPeriod(): number {
        return this._attributes['waitingPeriod'];
    }

    get minDayAmount(): number | null {
        return this._attributes['minDayAmount'] && getTime(this._attributes['minDayAmount'], this.timeOffType);
    }

    get maxLength(): number | null {
        return this._attributes['maxLength'] && getTime(this._attributes['maxLength'], this.timeOffType);
    }

    get timeOffPolicy(): TimeOffPolicy {
        return this.hasOne(TimeOffPolicy, 'timeOffPolicy');
    }

    get timeOffType(): TimeOffType {
        return this.timeOffPolicy?.timeOffType ?? this.hasOne(TimeOffType, 'timeOffType');
    }

    set id(val: number) {
        this._attributes['id'] = val;
    }

    set negativeBalanceCap(val: number) {
        // This is so the value can be cleared out on the UI. Math.abs(null) would return 0.
        const valueToSet = val !== undefined && val !== null ? -Math.abs(val) : val;
        this._attributes['negativeBalanceCap'] = setTime(valueToSet, this.timeOffType);
    }

    set waitingPeriod(val: number) {
        this._attributes['waitingPeriod'] = val;
    }

    set minDayAmount(val: number) {
        this._attributes['minDayAmount'] = setTime(val, this.timeOffType);
    }

    set maxLength(val: number) {
        this._attributes['maxLength'] = setTime(val, this.timeOffType);
    }

    set timeOffPolicy(val: TimeOffPolicy) {
        this.setOne('timeOffPolicy', val);
    }

    set timeOffType(val: TimeOffType) {
        this.setOne('timeOffType', val);
    }
}
