import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Video from '@models/videos/video.model';
import { VideoPlayerState } from '@videos/enums/video-player-state';
import VideoPlayerService from '@videos/services/video-player.service';
import VideoErrorInterface from '@videos/errors/video-error.interface';

@Component({
    selector: 'video-player',
    templateUrl: './video-player.template.html',
    styleUrls: ['./video-player.style.scss'],
})
export default class VideoPlayerComponent implements OnInit {
    @Input() video: Video;
    @Input() width?: number;
    @Input() height?: number;
    @Input() responsive: boolean = false;

    @Output() onReady = new EventEmitter<void>();
    @Output() onError = new EventEmitter<VideoErrorInterface>();
    @Output() onStateChange = new EventEmitter<VideoPlayerState>();

    @Output() onPause = new EventEmitter<VideoPlayerState>();
    @Output() onPlay = new EventEmitter<VideoPlayerState>();
    @Output() onEnd = new EventEmitter<VideoPlayerState>();
    @Output() onCue = new EventEmitter<VideoPlayerState>();

    playerId: string;
    playerService: VideoPlayerService;

    constructor(private el: ElementRef) {
        this.playerService = new VideoPlayerService();

        this.playerId = this.generateRandomId();
    }

    async ngOnInit() {
        this.playerService.onReady(() => this.handleReady());
        this.playerService.onError((error) => this.handleError(error));
        this.playerService.onStateChange((state) => this.handleStateChange(state));

        if (this.responsive) {
            this.getResponsiveWidth();
        }

        this.playerService.init(this.playerId, this.video, this.width, this.height);
    }

    generateRandomId(): string {
        return 'video_' + Math.random().toString(36).substring(2);
    }

    handleReady() {
        this.onReady.emit();
    }

    handleStateChange(state: VideoPlayerState) {
        switch (state) {
            case VideoPlayerState.Cued:
                this.onCue.emit(state);
                break;
            case VideoPlayerState.Paused:
                this.onPause.emit(state);
                break;
            case VideoPlayerState.Playing:
                this.onPlay.emit(state);
                break;
            case VideoPlayerState.Ended:
                this.onEnd.emit(state);
                break;
        }

        this.onStateChange.emit(state);
    }

    handleError(error: VideoErrorInterface) {
        this.onError.emit(error);
    }

    /**
     * When loading in a responsive container, calculate the height based on the width of the
     * element. This is an imperfect solution and relies on the video embed to support responsive
     * playback but it will at least make it load ok initially.
     */
    private getResponsiveWidth(): void {
        if (!this.el.nativeElement?.offsetWidth || !this.video?.height || !this.video?.width) {
            return;
        }

        this.width = this.el.nativeElement.offsetWidth;
        this.height = (this.video.height / this.video.width) * this.el.nativeElement.offsetWidth;
    }
}
