import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';

export class EmployeeBenefit extends Model {
    protected static _resource = 'payroll/companies/:company/benefits/:benefit/employeeBenefits';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'employeePremium',
        'premiumAmount',
        'premiumCalculatorType',
        'companyContribution',
        'contributionAmount',
        'contributionCalculatorType',
        'benefitDeductionFrequencyId',
        'effectiveDate',
    ];

    get benefitId(): number {
        return this._attributes['benefitId'];
    }

    get employeeId(): number {
        return Number(this._attributes['employeeId']);
    }

    set benefitId(val: number) {
        this._attributes['benefitId'] = val;
    }

    get employeePremium(): number {
        return this._attributes['employeePremium'];
    }

    set employeePremium(val: number) {
        this._attributes['employeePremium'] = val;
    }

    get companyContribution(): number {
        return this._attributes['companyContribution'];
    }

    set companyContribution(val: number) {
        this._attributes['companyContribution'] = val;
    }

    get prEmployeeId(): number {
        return this._attributes['employeeId'];
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get contributionAmount(): number {
        return this._attributes['contributionAmount'];
    }
    set contributionAmount(val: number) {
        this._attributes['contributionAmount'] = val;
    }

    get contributionCalculatorType(): string {
        return this._attributes['contributionCalculatorType'];
    }
    set contributionCalculatorType(val: string) {
        this._attributes['contributionCalculatorType'] = val;
    }

    get premiumAmount(): number {
        return this._attributes['premiumAmount'];
    }
    set premiumAmount(val: number) {
        this._attributes['premiumAmount'] = val;
    }

    get premiumCalculatorType(): string {
        return this._attributes['premiumCalculatorType'];
    }
    set premiumCalculatorType(val: string) {
        this._attributes['premiumCalculatorType'] = val;
    }

    get benefitDeductionFrequencyId(): number {
        return this._attributes['benefitDeductionFrequencyId'];
    }
    set benefitDeductionFrequencyId(val: number) {
        this._attributes['benefitDeductionFrequencyId'] = val;
    }

    get effectiveDate(): string {
        return this._attributes['effectiveDate'];
    }
    set effectiveDate(val: string) {
        this._attributes['effectiveDate'] = val;
    }
}
