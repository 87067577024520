import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SimplyBenefitsTransferObject } from '@app/modules/benefits/interfaces/simply-benefits-api-response.interface';
import { SimplyBenefitsClass } from '@app/modules/benefits/interfaces/simply-benefits-class.interface';
import { SimplyBenefitsDivision } from '@app/modules/benefits/interfaces/simply-benefits-division.interface';
import { SimplyBenefitsService } from '@app/modules/benefits/services';
import { AnalyticService, NotifyService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { SimplyBenefitsOnboardingData } from '../../../modules/benefits/interfaces/simply-benefits-onboarding-data.interface';

@Component({
    selector: 'app-select-new-employee-benefits-dialog',
    templateUrl: './select-new-employee-benefits-dialog.component.html',
    styleUrls: ['./select-new-employee-benefits-dialog.component.scss'],
})
export class SelectNewEmployeeBenefitsDialogComponent implements OnInit {
    @Input() open = false;
    @Input() clearDataBeforeOpen = false;
    @Output() closing = new EventEmitter<boolean>();
    @Output() benefitData = new EventEmitter<SimplyBenefitsOnboardingData>();

    isLoading = false;
    benefitClasses: SimplyBenefitsClass[] = [];
    divisions: SimplyBenefitsDivision[] = [];

    selectedBenefitClass: SimplyBenefitsClass | null = null;
    selectedBenefitDivision: SimplyBenefitsDivision | null = null;
    isWaitingPeriodWaved = false;

    lastSavedBenefitData: SimplyBenefitsOnboardingData | null = null;
    formWasSavedOk = false;

    constructor(
        private auth: AuthService,
        private notify: NotifyService,
        private simplyBenefitsService: SimplyBenefitsService,
        private analyticsService: AnalyticService
    ) {}

    ngOnInit(): void {
        this.loadBenefitClasses();
        this.loadDivisions();
    }

    onSave(form: NgForm): void {
        if (!this.submitAndValidate(form) || !this.selectedBenefitClass || !this.selectedBenefitDivision) {
            return;
        }

        this.formWasSavedOk = true;
        this.updateSimplyBenefitsOnboardingData();
        this.closing.emit(true);
    }

    private resetFormData(): void {
        if (!this.lastSavedBenefitData) {
            this.selectedBenefitClass = null;
            this.selectedBenefitDivision = null;
            this.isWaitingPeriodWaved = false;
        } else {
            this.selectedBenefitClass = this.lastSavedBenefitData.class;
            this.selectedBenefitDivision = this.lastSavedBenefitData.division;
            this.isWaitingPeriodWaved = this.lastSavedBenefitData.waiveWaitingPeriod;
        }
        this.updateSimplyBenefitsOnboardingData();
    }

    private updateSimplyBenefitsOnboardingData(): void {
        const benefitData: SimplyBenefitsOnboardingData = {
            class: this.selectedBenefitClass,
            division: this.selectedBenefitDivision,
            waiveWaitingPeriod: this.isWaitingPeriodWaved,
        };
        this.lastSavedBenefitData = benefitData;
        this.benefitData.emit(benefitData);
    }

    private submitAndValidate(form: NgForm): boolean | null {
        if (form) {
            form.onSubmit(null);
            return form.valid;
        }

        return true;
    }

    onOpen(): void {
        if (this.clearDataBeforeOpen) {
            this.lastSavedBenefitData = null;
            this.resetFormData();
        }
        this.formWasSavedOk = false;
    }

    onClose(): void {
        if (!this.formWasSavedOk) {
            this.resetFormData();
        }
        this.closing.emit(true);
    }

    private async loadBenefitClasses(): Promise<void> {
        try {
            this.isLoading = true;
            const benefitClasses = await this.simplyBenefitsService.getClasses();

            this.benefitClasses = benefitClasses.map(
                (transferObject: SimplyBenefitsTransferObject) => transferObject.attributes
            );
        } catch (error) {
            this.notify.error('forms.applicant-tracker.onboarding.couldNotLoadBenefitClasses');
        } finally {
            this.isLoading = false;
        }
    }

    private async loadDivisions(): Promise<void> {
        try {
            this.isLoading = true;
            const divisions = await this.simplyBenefitsService.getDivisions();

            this.divisions = divisions.map((transferObject: SimplyBenefitsTransferObject) => transferObject.attributes);
        } catch (error) {
            this.notify.error('forms.applicant-tracker.onboarding.couldNotLoadBenefitDivisions');
        } finally {
            this.isLoading = false;
        }
    }
}
