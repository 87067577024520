<div class="ui secondary menu">
    <ng-container *ngFor="let menuItem of menuClone">
        <ng-container *ngIf="!menuItem.children">
            <a
                class="item"
                *permission="
                    menuItem.permission || null;
                    all: menuItem.permissionAll !== undefined ? menuItem.permissionAll : true
                "
                [routerLink]="menuItem.link"
                [queryParams]="menuItem.queryParams || null"
                [routerLinkActiveOptions]="{ exact: !!menuItem.routerLinkExact }"
                routerLinkActive="active"
                ><h4>{{ menuItem.key | appTranslate }}</h4></a
            >
        </ng-container>

        <ng-container *ngIf="menuItem?.children && menuItem?.children?.length > 0">
            <div class="ui item dropdown" [class.select]="activeTab?.key === menuItem.key">
                <h4 style="margin-bottom: 0">
                    {{ activeTab?.key === menuItem.key ? activeTabDisplayLabel : (menuItem.key | appTranslate) }}

                    <mat-icon svgIcon="chevronDown"></mat-icon>
                </h4>
                <div class="menu">
                    <ng-container *ngFor="let child of menuItem.children">
                        <a
                            class="item"
                            *permission="
                                child.permission || null;
                                all: child.permissionAll !== undefined ? child.permissionAll : true
                            "
                            [routerLink]="child.link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                        >
                            <div>{{ child.key | appTranslate }}</div>
                        </a>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
