import { Component, Injector, Input } from '@angular/core';
import { StepsComponent } from '@app/components';
import { Translatable, TranslatableKey } from '@app/types/translatable.type';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-step',
    templateUrl: './step.template.html',
    styleUrls: ['./step.style.scss'],
})
export class StepComponent {
    @Input() stepForm?: BaseForm;
    @Input() index = 0;
    @Input() title: Translatable = '';
    @Input() step: TranslatableKey = '';
    /*
     * This is an optional key so that we can make comparisons against what
     * step we are one without using the step title
     */
    @Input() stepKey = '';

    @Input() embeddedCard = true;

    parent: StepsComponent;

    constructor(private _injector: Injector) {
        this.parent = this._injector.get<StepsComponent>(StepsComponent);
    }
}
