import { Directive, EventEmitter, Output } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Candidate } from '@app/models/offer-letters/candidate.model';
import { EmailRegex } from '@app/regexes';

@Directive({
    selector: '[candidateEmailValidator]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: CandidateEmailValidator, multi: true }],
})
export class CandidateEmailValidator implements AsyncValidator {
    @Output() candidateId: EventEmitter<number> = new EventEmitter<number>();

    redirectedFromCandidateView = false;

    constructor(private route: ActivatedRoute) {
        this.redirectedFromCandidateView = !!this.route.snapshot.queryParams.candidateId;
    }

    async validate(control: AbstractControl): Promise<ValidationErrors | null> {
        if (this.redirectedFromCandidateView) {
            return null;
        }

        // Remove white spaces (if any) in case user tries to copy the email from some other place.
        const email = control.value.replace(/\s/g, '');
        const isEmailValid = EmailRegex.test(email);

        if (!isEmailValid) {
            const errors = { email: true };
            control.setErrors(errors);
            return errors;
        }

        // Verify if there are any candidates with the same email
        try {
            const [candidates] = await Candidate.where('latestOfferLetterEmail', email).all();

            if (!candidates.length) {
                return null;
            }

            // If there are multiple candidates with same email address
            const hasMultipleCandidates = candidates.length > 1;

            if (hasMultipleCandidates) {
                const errors = { belongsToMultipleExistingCandidates: true };
                control.setErrors(errors);

                return errors;
            }

            // If there is only one candidate with same email address
            const latestCandidate = candidates[0];

            if (!this.isCandidateHireable(latestCandidate)) {
                const errors = { belongsToAnExistingCandidate: true };
                control.setErrors(errors);

                this.candidateId.emit(latestCandidate.id);
                return errors;
            }
        } catch (e) {}

        return null;
    }

    private isCandidateHireable(candidate: Candidate): boolean {
        return !(candidate.latestOfferLetter.isPending || candidate.latestOfferLetter.isSigned());
    }
}
