import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';

@Component({
    selector: 'app-copy-input',
    templateUrl: './copy-input.template.html',
    styleUrls: ['./copy-input.style.scss'],
})
export class CopyInputComponent {
    @Input() value;
    @Input() readOnly = false;
    @Output() onValueCopied = new EventEmitter<string>();
    @ViewChild('copyTooltip', { static: true }) copyTooltip: MatTooltip;
    isCopyTooltipDisabled = true;

    constructor(private clipboard: Clipboard) {}

    onCopyValue(): void {
        this.clipboard.copy(this.value);
        this.isCopyTooltipDisabled = false;
        this.onValueCopied.emit(this.value);
        setTimeout(() => {
            this.copyTooltip.show();

            setTimeout(() => {
                this.isCopyTooltipDisabled = true;
            }, 2000);
        }, 1);
    }
}
