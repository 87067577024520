import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeeFilter } from '@app/models/employee/employee-filter.model';
import { AuthService } from '@app/services';
import { Department } from '@models/company/department.model';
import { Job } from '@models/company/job.model';
import { Office } from '@models/company/office.model';
import { EmploymentType } from '@models/employee/employment-type.model';

export interface EmployeeSelectionCriteriaFields {
    departments: Department[];
    offices: Office[];
    employmentTypes: EmploymentType[];
    jobs: Job[];
}

type CriteriaType = Department | Job | EmploymentType | Office;

@Component({
    selector: 'app-employee-criteria-selection',
    templateUrl: './employee-criteria-selection.template.html',
    styleUrls: ['./employee-criteria-selection.style.scss'],
})
export class EmployeeCriteriaSelectionComponent implements OnInit {
    @Input() employeeFilter: EmployeeFilter;
    @Output() selectionChange = new EventEmitter();

    isLoading = true;

    allDepartments: Department[] = [];
    allJobs: Job[] = [];
    allEmploymentTypes: EmploymentType[] = [];
    allOffices: Office[] = [];

    selectedDepartments: Department[] = [];
    selectedJobs: Job[] = [];
    selectedEmploymentTypes: EmploymentType[] = [];
    selectedOffices: Office[] = [];

    initialDepartments: Department[] = [];
    initialJobs: Job[] = [];
    initialEmploymentTypes: EmploymentType[] = [];
    initialOffices: Office[] = [];

    constructor(private auth: AuthService) {}

    ngOnInit(): void {
        Promise.all([this.getOffices(), this.getDepartments(), this.getJobTitles(), this.getEmploymentTypes()])
            .then(() => {
                this.isLoading = false;
            })
            .catch(() => (this.isLoading = false));

        if (!this.employeeFilter) {
            return;
        }
        this.setInitialEntities();
    }

    isEntitySelected(entity: CriteriaType, selectedList: CriteriaType[]): boolean {
        return selectedList.some((item) => item.id === entity.id);
    }

    onToggle(entity: CriteriaType, selectedList: CriteriaType[]): void {
        const indexOfSelectedItem = selectedList.findIndex((selectedEntity) => selectedEntity.id === entity.id);

        if (indexOfSelectedItem !== -1) {
            selectedList.splice(indexOfSelectedItem, 1);
            return;
        }

        selectedList.push(entity);
    }

    onSelectionChange(): void {
        this.selectionChange.emit();
    }

    getValuesToAttach(): EmployeeSelectionCriteriaFields {
        return {
            departments: this.filterValuesToAttach(this.initialDepartments, this.selectedDepartments),
            offices: this.filterValuesToAttach(this.initialOffices, this.selectedOffices),
            employmentTypes: this.filterValuesToAttach(this.initialEmploymentTypes, this.selectedEmploymentTypes),
            jobs: this.filterValuesToAttach(this.initialJobs, this.selectedJobs),
        };
    }

    getValuesToDetach(): EmployeeSelectionCriteriaFields {
        return {
            departments: this.filterValuesToDetach(this.initialDepartments, this.selectedDepartments),
            offices: this.filterValuesToDetach(this.initialOffices, this.selectedOffices),
            employmentTypes: this.filterValuesToDetach(this.initialEmploymentTypes, this.selectedEmploymentTypes),
            jobs: this.filterValuesToDetach(this.initialJobs, this.selectedJobs),
        };
    }

    clearAttachDetachFilters(): void {
        this.initialDepartments = [...this.selectedDepartments];
        this.initialJobs = [...this.selectedJobs];
        this.initialEmploymentTypes = [...this.selectedEmploymentTypes];
        this.initialOffices = [...this.selectedOffices];
    }

    private async getOffices(): Promise<void> {
        const [offices] = await Office.param('company', this.auth.company.id).all();
        this.allOffices = offices;
    }

    private async getDepartments(): Promise<void> {
        const [departments] = await Department.param('company', this.auth.company.id).orderBy('name', 'ASC').all();
        this.allDepartments = departments;
    }

    private async getJobTitles(): Promise<void> {
        const [jobs] = await Job.param('company', this.auth.company.id).orderBy('title', 'ASC').all();
        this.allJobs = jobs;
    }

    private async getEmploymentTypes(): Promise<void> {
        const [employmentTypes] = await EmploymentType.param('company', this.auth.company.id).all();
        this.allEmploymentTypes = employmentTypes;
    }

    private setInitialEntities(): void {
        this.initialDepartments = this.employeeFilter.getDepartmentCriteria();
        this.initialJobs = this.employeeFilter.getJobCriteria();
        this.initialEmploymentTypes = this.employeeFilter.getEmploymentTypeCriteria();
        this.initialOffices = this.employeeFilter.getOfficeCriteria();

        this.selectedDepartments = [...this.initialDepartments];
        this.selectedJobs = [...this.initialJobs];
        this.selectedEmploymentTypes = [...this.initialEmploymentTypes];
        this.selectedOffices = [...this.initialOffices];
    }

    private filterValuesToAttach<T>(initialList: T[], selectedList: T[]): T[] {
        return selectedList.filter((entity) => !initialList.includes(entity));
    }

    private filterValuesToDetach<T>(initialList: T[], selectedList: T[]): T[] {
        return initialList.filter((entity) => !selectedList.includes(entity));
    }
}
