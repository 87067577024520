import { Paginator, SortDirection } from '@app/classes';
import { WorkersCompensation } from '@app/models/payroll/workers-compensation.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { EmployeeCountColumn, JurisdictionColumn, NameColumn, RateColumn, YearColumn } from './columns';

const pageSize = 25;

export class WorkersCompensationTable extends Table {
    sortDirection = SortDirection.ASC;
    links = true;
    showMenuColumnOptions = true;
    protected sortProperty = 'name';

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [
            new NameColumn(),
            new YearColumn(),
            new JurisdictionColumn(this.auth.company.id),
            new RateColumn(),
            new EmployeeCountColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(WorkersCompensation.param('company', this.auth.company.id).limit(pageSize), {
            pageSize: pageSize,
        });
    }
}
