import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import '@humi-design-library/components/Badge';
import { BadgeType } from '@humi-design-library/components/Badge/types';
@Component({
    selector: 'ui-badge',
    standalone: true,
    templateUrl: './badge.template.html',
    styles: [
        `
            :host {
                display: inline-flex;
                vertical-align: middle;

                &:not(:first-of-type) {
                    margin-left: 0.5rem;
                }
            }
        `,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WebBadgeComponent {
    @Input() type: BadgeType = 'default';
}
