import { Component, ElementRef, Input } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { BenefitDivision } from '@models/benefits/benefit-division.model';
import { BenefitPlan } from '@models/benefits/benefit-plan.model';
import { BenefitPolicy } from '@models/benefits/benefit-policy.model';
import { BaseDialogComponent } from '../base.dialog';

@Component({
    selector: 'app-common-select-benefit-plan-dialog',
    templateUrl: './select-benefit-plan-dialog.template.html',
    styleUrls: ['./select-benefit-plan-dialog.style.scss'],
})
export class SelectBenefitPlanDialogComponent extends BaseDialogComponent {
    @Input() label = 'select';
    @Input() headerText = 'components.select-benefit-plan-dialog.selectBenefitPlan';

    isLoading = false;

    plans: BenefitPlan[] = [];
    divisions: BenefitDivision[] = [];
    selection = { benefitPlan: null, benefitDivision: null, isWaitingPeriodWaved: false };
    selectedBenefitPlan: BenefitPlan | null = null;
    selectedBenefitDivision: BenefitDivision | null = null;
    hasEnhancedManagedBenefits = false;
    existingSelection: BenefitPlan;

    constructor(
        protected element: ElementRef,
        protected auth: AuthService
    ) {
        super(element);
    }

    /**
     * Reset the form
     */
    onBeforeShow(): void {
        if (this.existingSelection) {
            this.selection.benefitPlan = this.existingSelection;
        }
        this.selectedBenefitDivision = null;
        this.loadItems();
    }

    onBenefitPlanChange(): void {
        this.selection.benefitPlan = this.selectedBenefitPlan;
        this.selectedBenefitDivision = null;
        this.setDivisions();
    }

    onBenefitDivisionChange(): void {
        this.selection.benefitDivision = this.selectedBenefitDivision;
    }

    isActionButtonDisabled(): boolean {
        if (!this.selectedBenefitPlan) {
            return true;
        }

        if (this.hasEnhancedManagedBenefits && !this.selectedBenefitDivision) {
            return true;
        }

        return false;
    }

    protected loadItems(): void {
        this.isLoading = true;
        BenefitPlan.param('company', this.auth.company.id)
            .all()
            .then(([benefitPlans]) => {
                this.plans = benefitPlans;
                this.divisions = [];
                this.isLoading = false;
            });
    }

    protected setDivisions(): void {
        if (!this.selectedBenefitPlan.benefitPolicyId) {
            return;
        }
        this.isLoading = true;
        BenefitPolicy.param('company', this.auth.company.id)
            .with(['divisions'])
            .find(this.selectedBenefitPlan.benefitPolicyId)
            .then((benefitPolicy) => {
                this.divisions = benefitPolicy.divisions || null;
                this.hasEnhancedManagedBenefits = benefitPolicy.hasEnhancedManagedBenefits;
                this.isLoading = false;
            });
    }
}
