import { Component } from '@angular/core';
import { AsyncSystemTask } from '@app/models/common/async-system-tasks.model';
import { AsyncSystemTaskTrayService } from '@app/services/async-system-task-tray.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ui-async-system-tasks-tray',
    templateUrl: './async-system-tasks-tray.template.html',
    styleUrls: ['./async-system-tasks-tray.style.scss'],
})
export class AsyncSystemTasksTray {
    collapsed = false;
    dismissed = false;
    tasks$: Observable<AsyncSystemTask[]>;

    private _onDestroy$: Subject<void> = new Subject();

    constructor(public service: AsyncSystemTaskTrayService) {
        this.service.tasks$.pipe(takeUntil(this._onDestroy$)).subscribe((tasks) => {
            this.dismissed = !tasks.length;
        });

        this.tasks$ = this.service.tasks$;
    }

    toggleCollapsed(): void {
        this.collapsed = !this.collapsed;
    }

    dismiss(): void {
        this.dismissed = true;
    }
}
