import { PersonaScope } from '@app/modules/payroll/components/kyc-kyb/types';

export type PersonaInquiry = {
    status: PersonaInquiryStatus;
    inquiryId: string;
};

/**
 * Possible inquiry statuses returned from Persona
 */
export type PersonaInquiryStatus =
    | 'created'
    | 'pending'
    | 'completed'
    | 'expired'
    | 'failed'
    | 'needs_review'
    | 'approved'
    | 'declined';

/**
 * Possible inquiry statuses returned from Humi
 */
export type HumiInquiryStatus = 'not_complete' | 'in_progress' | 'complete';

/**
 * When starting a Persona Session from scratch, the following attributes are required
 */
export type NewPersonaSessionAttributes = {
    templateId: string;
    referenceId: string;
    environmentId: string;
};

/**
 * When resuming an existing Persona Session, the following attributes are required
 */
export type ResumePersonaSessionAttributes = {
    inquiryId: string;
    sessionToken: string;
};

/**
 * These statuses rely on intervention from a 3rd-party. Therefore we want to ignore the cache if an inquiry is in these statuses so we always get a live reflection of whether it's been actioned on
 */
export const INVALIDATE_CACHE_STATUSES: PersonaInquiryStatus[] = ['failed', 'needs_review'];

/**
 * Each KYC/KYB type has its own pre-redirect page under different modules.
 * This mapping both informs the link within the bar as well as informs when to hide the bar
 */
export const KYC_KYB_URLS: Record<PersonaScope, string> = {
    KYC: '/account/verify-your-identity',
    KYB: '/company/verify-your-business',
} as const;
