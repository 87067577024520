import { Model } from '../core/base.model';

export class Address extends Model {
    protected static _type = 'addresses';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'addressLine1',
        'addressLine2',
        'city',
        'province',
        'country',
        'postalCode',
        'name',
        'isPrimary',
    ];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get addressLine1(): string | null {
        return this._attributes['addressLine1'];
    }

    set addressLine1(val: string) {
        this._attributes['addressLine1'] = val;
    }

    get addressLine2(): string | null {
        return this._attributes['addressLine2'];
    }

    set addressLine2(val: string | null) {
        this._attributes['addressLine2'] = val;
    }

    get city(): string | null {
        return this._attributes['city'];
    }

    set city(val: string) {
        this._attributes['city'] = val;
    }

    get province(): string | null {
        return this._attributes['province'];
    }

    set province(val: string) {
        this._attributes['province'] = val;
    }

    get country(): string | null {
        return this._attributes['country'];
    }

    set country(val: string) {
        this._attributes['country'] = val;
    }

    get postalCode(): string | null {
        return this._attributes['postalCode'];
    }

    set postalCode(val: string) {
        this._attributes['postalCode'] = val;
    }

    get isPrimary(): boolean {
        return this._attributes['isPrimary'];
    }

    set isPrimary(val: boolean) {
        this._attributes['isPrimary'] = val;
    }

    get fullAddress(): string {
        return this.addressLine1;
    }

    get provinceDisplayName(): string {
        return this._attributes['provinceDisplayName'];
    }
}
