import { Injectable } from '@angular/core';
import { Country } from '@app/models/common/country.model';
import { ReplaySubject } from 'rxjs';

const reasonableCountryOrdering = (countries: Country[]): Country[] => {
    countries.sort((a, b) => {
        // Canada always first
        if (a.name === 'Canada') {
            return -1;
        }

        if (b.name === 'Canada') {
            return 1;
        }

        // USA always next
        if (a.name === 'United States') {
            return -1;
        }

        if (b.name === 'United States') {
            return 1;
        }

        // Alphabetical
        if (a.name < b.name) {
            return -1;
        }

        return 1;
    });

    return countries;
};

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    allCountries = new ReplaySubject<Country[]>(1);

    constructor() {
        this.getCountries();
    }

    protected getCountries(): void {
        Country.all().then(([countries]) => {
            this.allCountries.next(reasonableCountryOrdering(countries));
        });
    }
}
