import { AnalyticEvents } from '@app/enums';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { InteractableColumn } from '@app/modules/table/interfaces/interactable-column.interface';
import { AnalyticService } from '@app/services';
import { EmployeeDeduction } from '@models/payroll/employee/deduction.model';

export class EffectiveAtColumn extends Column implements InteractableColumn {
    title = 'Effective Date';
    property = 'effectiveDate';
    type: CellDisplay = CellDisplay.datepicker;

    constructor(
        private deductionId: number,
        private companyId: number,
        private analytics: AnalyticService
    ) {
        super();
    }

    getBindProperty(): string {
        return 'effectiveDate';
    }

    async onRowChange(row: EmployeeDeduction): Promise<void> {
        await row.param('deduction', this.deductionId).param('company', this.companyId).save();

        this.analytics.trackEvent(AnalyticEvents.PayrollDeductionsEmployeesChangeEffectiveDateInTableAutosaves);
    }
}
