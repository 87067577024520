import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

export interface ErrorNavigatorBeforeChange {
    activeErrorIndex: number;
    previousNonErrorInput: HTMLInputElement | HTMLSelectElement;
    direction: 'NEXT' | 'PREVIOUS';
}

@Component({
    selector: 'app-error-navigator',
    templateUrl: './error-navigator.template.html',
    styleUrls: ['./error-navigator.styles.scss'],
})
export class ErrorNavigatorComponent implements OnInit {
    activeErrorIndex: number | null = null;
    isLoading = false;
    _errorSelectors: string[] = [];
    /*
     * Track the last input element that had focus so we can use it to find the
     * closest error near it
     * */
    previousNonErrorInput: HTMLInputElement | HTMLSelectElement = null;

    @Output() nextErrorClicked = new EventEmitter();
    @Output() previousErrorClicked = new EventEmitter();
    @Output() beforeChangeInErrorFocus = new EventEmitter<ErrorNavigatorBeforeChange>();
    @Input() set errorSelectors(errors: string[]) {
        this._errorSelectors = errors;
        this.isLoading = false;
        this.shouldSetActiveIndexToNull();
    }

    get errorSelectors(): string[] {
        return this._errorSelectors;
    }
    get hasNoErrors(): boolean {
        return this.errorSelectors.length === 0 && this.activeErrorIndex !== null && !this.isLoading;
    }
    get hasErrorsWithSelection(): boolean {
        return this.errorSelectors.length > 0 && this.activeErrorIndex !== null && !this.isLoading;
    }
    get hasNoActiveSelection(): boolean {
        return this.activeErrorIndex === null && !this.isLoading;
    }

    ngOnInit(): void {
        this.isLoading = true;
    }

    /*
     * Add this method to elements that should have error navigation, it will
     * update the active error to when the user focuses on the element
     *
     * (focusin)="errorNavigator.handleInputFocus($event)"
     * */
    handleInputFocus($event: FocusEvent): void {
        const target = $event.target as HTMLInputElement | HTMLSelectElement;
        let selectorIndexWithError: number = null;

        // Find which error selector is active when user focuses on an element
        this.errorSelectors.map((selector, index) => {
            if (target.classList.contains(selector)) {
                selectorIndexWithError = index;
            }
        });

        // Check if null since 0 is a valid result
        if (selectorIndexWithError !== null) {
            this.activeErrorIndex = selectorIndexWithError;
            this.previousNonErrorInput = null;
        } else {
            this.activeErrorIndex = null;
            this.previousNonErrorInput = target;
        }
    }

    nextError(): void {
        this.nextErrorClicked.emit();
        this.setActiveErrorIndex(this.activeErrorIndex + 1);
        this.beforeChangeInErrorFocus.emit({
            previousNonErrorInput: this.previousNonErrorInput,
            activeErrorIndex: this.activeErrorIndex,
            direction: 'NEXT',
        });
        this.focusOnError();
    }

    previousError(): void {
        this.previousErrorClicked.emit();
        this.setActiveErrorIndex(this.activeErrorIndex - 1);
        this.beforeChangeInErrorFocus.emit({
            previousNonErrorInput: this.previousNonErrorInput,
            activeErrorIndex: this.activeErrorIndex,
            direction: 'PREVIOUS',
        });
        this.focusOnError();
    }

    focusOnError(): void {
        const activeSelector = `.${this.errorSelectors[this.activeErrorIndex]}`;
        const element: HTMLElement = document.querySelector(activeSelector);
        const noScrollTo = element.dataset.noScrollTo === 'true'; // dataset values are strings

        if (!element) {
            return;
        }

        element.focus();

        if (noScrollTo) {
            return;
        }

        element.scrollIntoView({
            behavior: 'smooth', // Defines the transition animation.
            block: 'nearest', // Defines vertical alignment.
            inline: 'center', // Defines horizontal alignment.
        });
    }

    /*
     * Ensures that activeErrorIndex stays within
     * the bounds the errorSelectors array
     */
    private setActiveErrorIndex(errorIndex: number): void {
        const errorCount = this.errorSelectors.length;

        const indexTooHigh = errorIndex > errorCount - 1;
        if (indexTooHigh) {
            this.activeErrorIndex = 0;
            return;
        }

        const indexTooLow = errorIndex < 0;
        if (indexTooLow) {
            this.activeErrorIndex = errorCount - 1;
            return;
        }

        this.activeErrorIndex = errorIndex;
    }

    private shouldSetActiveIndexToNull(): void {
        if (this.activeErrorIndex > this._errorSelectors.length - 1) {
            this.activeErrorIndex = null;
        }
    }
}
