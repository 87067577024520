import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive } from '@angular/core';
import moment from 'moment';

@Directive({
    selector: '[dateOfBirthValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DateOfBirthValidator, multi: true }],
})
export class DateOfBirthValidator implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        const value = moment(control.value).startOf('day');
        const lowerBound = moment('1900-01-01').startOf('day');
        const upperBound = moment().subtract(5, 'years').startOf('day');

        if (value.isBefore(lowerBound) || value.isAfter(upperBound)) {
            return { custom: { message: `Date cannot be later than ${upperBound.format('YYYY-MM-DD')}.` } };
        }

        return null;
    }
}
