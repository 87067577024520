<form class="flex align-items-center" #ngForm>
    <div class="app-grid">
        <mat-form-field class="columns-6">
            <mat-label>From</mat-label>
            <input
                matInput
                required
                name="rangeStartInput"
                #rangeStartInput="ngModel"
                [ngModel]="rangeStart"
                (ngModelChange)="onChange($event, true)"
                [matDatepicker]="rangeStartDatePicker"
            />
            <mat-datepicker-toggle matSuffix [for]="rangeStartDatePicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #rangeStartDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="columns-6">
            <mat-label>To</mat-label>
            <input
                matInput
                required
                name="rangeEndInput"
                #rangeEndInput="ngModel"
                [ngModel]="rangeEnd"
                (ngModelChange)="onChange($event, false)"
                [matDatepicker]="rangeEndDatePicker"
            />
            <mat-datepicker-toggle matSuffix [for]="rangeEndDatePicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #rangeEndDatePicker></mat-datepicker>
        </mat-form-field>
    </div>
    <mat-icon class="clear-button" (click)="clear()" svgIcon="close"></mat-icon>
</form>
