import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SupportedAudioFormats } from '@app/components/app-audio-recorder/enums/audio-formats.enum';
import { FileHelperService, NotifyService } from '@app/services';
import { File as FileModel } from '@models/common/file.model';
import * as Sentry from '@sentry/browser';

const MICROPHONE_DENIED_STATUS = 'denied';

enum RecordingStates {
    canRecord = 'CAN_RECORD',
    canPlay = 'CAN_PLAY',
    recording = 'RECORDING',
}

@Component({
    selector: 'app-audio-recorder',
    templateUrl: './app-audio-recorder.template.html',
    styleUrls: ['./app-audio-recorder.style.scss'],
})
export class AudioRecorderComponent implements OnInit {
    @ViewChild('audio') audio: ElementRef<HTMLAudioElement>;
    @Output() recording: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() audioBlob: Blob | null = null;
    @Output() audioBlobChange = new EventEmitter<Blob | null>();
    @Input() supportedFormat: string | null = null;
    @Output() supportedFormatChange = new EventEmitter<string | null>();
    @Output() audioPlayed: EventEmitter<boolean> = new EventEmitter<boolean>();

    isRecording = false;
    duration = 0;
    microphoneAccessHasBeenDenied = false;
    recorder: MediaRecorder | null = null;
    stream: MediaStream | null = null;
    recordingChunks: Blob[] = [];
    timer: ReturnType<typeof setInterval>;
    shouldDeletePreviousRecording = false;
    hasPersistedAudioFile = false;

    isPlaying = false;
    showRecordButton = true;
    showStopButton = false;
    showPlayButton = false;

    constructor(
        private fileService: FileHelperService,
        private notify: NotifyService
    ) {}

    async ngOnInit(): Promise<void> {
        this.supportedFormat = this.getSupportedAudioFormat();
        this.supportedFormatChange.emit(this.supportedFormat);
        const microphoneStatus = await this.getMicrophoneAccessStatus();
        this.microphoneAccessHasBeenDenied = microphoneStatus === MICROPHONE_DENIED_STATUS;
    }

    onRecord(): void {
        /*
         * Show any error toasts if the user hasn't given us microphone access, etc.
         */
        this.showOnRecordErrors();

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                this.recorder = new MediaRecorder(stream, { mimeType: this.supportedFormat });
                this.stream = stream;

                // Set up event listeners
                this.recorder.ondataavailable = (e): void => this.handleAudioRecordingData(e.data);
                this.recorder.onstop = (): void => this.handleAudioRecordingStop();

                this.isRecording = true;
                this.recording.emit(true);
                this.startTimer();
                this.recorder.start(1000); // Start recording using one-second chunks

                this.setState(RecordingStates.recording);
            })
            .catch((err) => {
                Sentry.captureException(err);
                if (err.name == 'NotFoundError' || err.name == 'DevicesNotFoundError') {
                    // User might not have a microphone or device is manually disabled
                    this.notify.error('components.app-audio-recorder.deviceUnableToRecordError');
                } else if (err.name == 'NotReadableError' || err.name == 'TrackStartError') {
                    // Microphone is already in use
                    this.notify.warn('components.app-audio-recorder.microphoneInUseError');
                } else {
                    this.notify.error('components.app-audio-recorder.unableToRecordError');
                }
            });
    }

    onStop(): void {
        if (this.recorder === null || this.recorder.state === 'inactive') {
            return;
        }

        this.isRecording = false;
        this.recording.emit(false);
        this.stopTimer();
        this.recorder.stop();

        /*
         * Stop listening to the microphone, this will remove the recording icon from the browser tab
         */
        this.stream.getAudioTracks().forEach((track) => {
            track.stop();
        });

        this.setState(RecordingStates.canPlay);
    }

    async onPlay(): Promise<void> {
        if (this.audio === null) {
            return;
        }

        try {
            this.isPlaying = true;
            await this.audio.nativeElement.play();
            this.audioPlayed.emit(true);
        } catch (e) {
            this.isPlaying = false;
            this.notify.error('components.app-audio-recorder.errorPlayingFileError');
            Sentry.captureException({ name: 'Error playing audio file', message: e });
        }
    }

    onRemove(): void {
        this.recordingChunks = [];
        this.audioBlob = null;
        this.audioBlobChange.emit(this.audioBlob);
        this.duration = 0;

        /*
         * If the file is persisted we want to mark it for deletion
         */
        if (this.hasPersistedAudioFile) {
            this.shouldDeletePreviousRecording = true;
        }

        this.setState(RecordingStates.canRecord);
    }

    loadAudio(file: FileModel): void {
        const audioFileUrl = this.fileService.serveWithToken(file.id);
        this.initializeAudio(audioFileUrl);
        this.setState(RecordingStates.canPlay);
    }

    /*
     * Call this method after store to reset component state
     */
    reset(): void {
        this.hasPersistedAudioFile = this.hasAudioToStore();
        this.shouldDeletePreviousRecording = false;
        this.recordingChunks = [];
        this.audioBlob = null;
        this.audioBlobChange.emit(this.audioBlob);
    }

    hasAudioToStore(): boolean {
        return this.audioBlob !== null;
    }

    private showOnRecordErrors(): void {
        if (this.supportedFormat === null || !this.doesSupportAudioRecording()) {
            this.notify.error('components.app-audio-recorder.browserFeatureNotSupportedError');
            return;
        }

        if (this.microphoneAccessHasBeenDenied) {
            this.notify.error('components.app-audio-recorder.microphoneAccessDeniedError');
            return;
        }
    }

    private handleAudioRecordingStop(): void {
        this.audioBlob = new Blob(this.recordingChunks, { type: this.supportedFormat });
        this.audioBlobChange.emit(this.audioBlob);
        const audioFileUrl = URL.createObjectURL(this.audioBlob);
        this.initializeAudio(audioFileUrl);
    }

    private initializeAudio(url: string): void {
        this.audio.nativeElement.src = url;
        this.audio.nativeElement.preload = 'metadata';
        this.audio.nativeElement.load();

        this.audio.nativeElement.addEventListener('ended', () => {
            this.isPlaying = false;
        });
    }

    private handleAudioRecordingData(blob: Blob): void {
        this.recordingChunks.push(blob);
    }

    private async getMicrophoneAccessStatus(): Promise<PermissionState | null> {
        try {
            const status = await navigator?.permissions?.query({ name: 'microphone' as PermissionName });
            return status.state;
        } catch (error) {
            /*
             * The browser doesn't support us querying the permission for 'microphone' and threw an error, this
             * _does not_ mean there's no support for audio capture.
             *
             * We are just unaware if the user has blocked access previously, the browser will either prompt the user for
             * permission again or the user will get a generic error from us on `onRecord()` telling them we are unable to record audio.
             */
            return null;
        }
    }

    private getSupportedAudioFormat(): string | null {
        if (MediaRecorder.isTypeSupported(SupportedAudioFormats.primary)) {
            return SupportedAudioFormats.primary;
        }

        if (MediaRecorder.isTypeSupported(SupportedAudioFormats.secondary)) {
            return SupportedAudioFormats.secondary;
        }

        /*
         * None of the audio formats are supported
         */
        Sentry.captureMessage('Browser cannot record using any of our supported audio formats', 'info');

        return null;
    }

    private doesSupportAudioRecording(): boolean {
        if (navigator.mediaDevices?.getUserMedia) {
            return true;
        }

        Sentry.captureMessage('Browser does not support audio recording', 'info');

        return false;
    }

    private startTimer(): void {
        this.timer = setInterval(() => {
            this.duration = this.duration + 1;

            if (this.duration === 12) {
                this.onStop();
                this.notify.warn('components.app-audio-recorder.recordingTimeLimitReachedError');
            }
        }, 1000);
    }

    private stopTimer(): void {
        clearInterval(this.timer);
    }

    private setState(state: RecordingStates): void {
        if (state === RecordingStates.canPlay) {
            this.showRecordButton = false;
            this.showPlayButton = true;
            this.showStopButton = false;
        }

        if (state === RecordingStates.canRecord) {
            this.showRecordButton = true;
            this.showPlayButton = false;
            this.showStopButton = false;
        }

        if (state === RecordingStates.recording) {
            this.showRecordButton = false;
            this.showPlayButton = false;
            this.showStopButton = true;
        }
    }
}
