export class Platform {
    static modules = {
        timeOff: 'Time Off',
        timeTracking: 'Time Tracking',
        benefits: 'Benefits',
        payroll: 'Payroll',
        performance: 'Performance',
        survey: 'Survey',
        recruiting: 'Recruiting',
        businessInsurance: 'Business Insurance',
        benefitsPlus: 'Benefits Plus',
    } as const;

    static permission = {
        administrate: ['administrate'],
    };

    static manage = {
        all: ['manageAll'],
        directReports: ['manageDirectReports'],
        directAndIndirectReports: ['manageDirectAndIndirectReports'],
        self: ['manageSelf'],
    };

    static benefits = {
        permission: {
            view: ['accessBenefits'],
        },
    };

    static businessInsurance = {
        permission: 'manageBusinessInsurance',
    };

    static employees = {
        permission: {
            view: ['accessEmployees'],
        },
    };

    static people = {
        permission: {
            view: ['viewPeople'],
        },
        directory: {
            permission: {
                view: ['viewPeopleDirectoryTab'],
            },
        },
        myTeam: {
            permission: {
                view: ['viewMyTeamTab'],
            },
        },
        departments: {
            permission: {
                view: ['viewDepartmentsTab'],
            },
        },
        employeeDocuments: {
            permission: {
                view: ['viewEmployeeDocumentsTab'],
            },
        },
    };
    static orgChart = {
        permission: {
            view: ['view.organizationalChart'],
        },
    };
    static payroll = {
        permission: {
            view: ['accessPayroll'],
        },
    };
    static performance = {
        permission: {
            view: ['accessPerformance'],
        },
    };
    static survey = {
        permission: {
            view: ['accessSurvey'],
        },
    };
    static recruiting = {
        permission: {
            view: ['accessRecruiting'],
            access: 'recruiting.access',
            recruiter: 'recruiting.recruiter',
        },
    };
    static reporting = {
        permission: {
            view: ['accessReporting'],
        },
    };
    static timeOff = {
        permission: {
            view: ['accessTimeOff'],
            calendar: ['access.timeOffCalendar'],
        },
    };
    static timeTracking = {
        permission: {
            /**
             * like all view permissions, this is an admin only permission
             * if you see that a user has this permission, they are an admin
             */
            view: ['accessTimeTracking'],
        },
    };
    static settings = {
        permission: {
            view: ['accessSettings'],
        },
    };
}

/**
 * Use this type for enforcing our modules and keeping consistency.
 * Creates a type of:
 *
 * type PlatformModules = "Time Off" | "Time Tracking" | "Benefits" | "Payroll" | "Performance" | "Survey" | "Recruiting" | "Business Insurance" | "Benefits Plus"
 */

export type PlatformModules = (typeof Platform.modules)[keyof typeof Platform.modules];
