import { Model } from '@models/core/base.model';
import { Company } from '@models/company/company.model';
import { ApprovalWorkflowStep } from '@models/approvals/approval-workflow-step.model';

export class ApprovalWorkflow extends Model {
    protected static _resource = 'approvals/workflows';
    protected static _type = 'approvals.approvalWorkflow';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['entityId', 'entityType', 'steps', 'createdAt', 'updatedAt', 'deletedAt'];

    get entityId(): number {
        return this._attributes['entityId'];
    }

    set entityId(entityId: number) {
        this._attributes['entityId'] = entityId;
    }

    get entityType(): string {
        return this._attributes['entityType'];
    }

    set entityType(entityType: string) {
        this._attributes['entityType'] = entityType;
    }

    get company(): Company {
        return this.hasOne(Company, 'company');
    }

    get steps(): ApprovalWorkflowStep[] {
        return this.hasMany(ApprovalWorkflowStep, 'steps');
    }

    set steps(steps: ApprovalWorkflowStep[]) {
        this.setMany('steps', steps);
    }
}
