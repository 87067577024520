import { Model } from '../core/base.model';
import { DataField } from '../settings/data-field.model';

export class SigningTemplateSlotCustomFieldAssignment extends Model {
    get dataFieldId(): number {
        return this._attributes['dataFieldId'];
    }

    get dataField(): DataField {
        return this.hasOne(DataField, 'dataField');
    }
}
