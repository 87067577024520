import { SimpleEntityActions } from '@app/classes';
import { ModelMixin } from '../core/base-generic.model';

export abstract class BankAccount extends ModelMixin<BankAccount>() {
    protected static _type = 'bankAccounts';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['accountNumber', 'branchNumber', 'institutionNumber'];

    static permission = new SimpleEntityActions('bankAccount');

    /**
     * Account Number
     * @return {number} [description]
     */
    get accountNumber(): string {
        return this._attributes['accountNumber'];
    }
    set accountNumber(val: string) {
        this._attributes['accountNumber'] = val;
    }

    /**
     * Branch Number
     * @return {number} [description]
     */
    get branchNumber(): string {
        return this._attributes['branchNumber'];
    }
    set branchNumber(val: string) {
        this._attributes['branchNumber'] = val;
    }

    /**
     * Institution Number
     * @return {number} [description]
     */
    get institutionNumber(): string {
        return this._attributes['institutionNumber'];
    }
    set institutionNumber(val: string) {
        this._attributes['institutionNumber'] = val;
    }
}
