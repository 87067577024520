import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { File } from '@models/common/file.model';
import { FileViewerComponent } from '@app/components';

const dialogBannerOffsetHeight = 64;

@Component({
    selector: 'app-image-acknowledger',
    templateUrl: './image-acknowledger.template.html',
    styleUrls: ['./image-acknowledger.style.scss'],
})
export class ImageAcknowledgerComponent {
    @ViewChild('viewer') viewer: FileViewerComponent;
    @Output() buttonClicked: EventEmitter<void> = new EventEmitter();
    @Input() buttonLabel = 'Acknowledge';
    loading = true;
    isOpened = false;
    file: File | null = null;
    imageOffset = dialogBannerOffsetHeight;

    @HostListener('window:keydown', ['$event'])
    handleKey(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.close();
                break;
        }
    }

    open(file: File): void {
        this.isOpened = true;
        this.viewer.file = file;
        this.viewer.previewFile();
        this.loading = false;
    }

    close(): void {
        this.isOpened = false;
        this.viewer.reset();
        this.loading = true;
    }

    negateClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }
}
