export const EmploymentTypes: string[] = [
    'full-time',
    'part-time',
    'contractor',
    'intern',
    'volunteer',
    'seasonal',
    'casual',
    'consultant',
    'other',
];
