import { Model } from '../core/base.model';
import { AssetCategory } from './asset-category.model';
import { AssetAssignment } from './asset-assignment.model';
import { Employee } from '../employee/employee.model';

export class Asset extends Model {
    static managePermission = 'manageAssets';

    protected static _resource = 'assets';
    protected static _version = 'v2';

    protected static _serializeAttributes = ['name', 'companyId', 'serialNumber', 'cost', 'notes', 'categoryId'];

    get companyId(): number {
        return this._attributes['companyId'];
    }

    set companyId(val: number) {
        this._attributes['companyId'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get categoryId(): number {
        return this._attributes['categoryId'];
    }

    set categoryId(val: number) {
        this._attributes['categoryId'] = val;
    }

    get category(): AssetCategory {
        return this.hasOne(AssetCategory, 'category');
    }

    set category(val: AssetCategory) {
        this.setOne('category', val, 'categoryId');
    }

    get serialNumber(): string {
        return this._attributes['serialNumber'];
    }

    set serialNumber(val: string) {
        this._attributes['serialNumber'] = val;
    }

    get cost(): string {
        return this._attributes['cost'];
    }

    set cost(val: string) {
        this._attributes['cost'] = val;
    }

    get notes(): string {
        return this._attributes['notes'];
    }

    set notes(val: string) {
        this._attributes['notes'] = val;
    }

    get assignment(): AssetAssignment {
        return this.hasOne(AssetAssignment, 'assignment');
    }

    set assignment(val: AssetAssignment) {
        this.setOne('assignment', val);
    }

    getAssignee(): Employee | null {
        return this.assignment?.employee;
    }
}
