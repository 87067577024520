import {
    Component,
    HostBinding,
    Input,
    Optional,
    Self,
    ElementRef,
    ViewChild,
    Output,
    EventEmitter,
} from '@angular/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { ControlValueAccessor, FormControl, NgForm, NgControl } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ErrorStateMatcher } from '@angular/material/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Subscription, Subject } from 'rxjs';
import { arrayWrap } from '@app/functions';

const ALL_FILE_TYPES = '*';
export const IMAGES_ONLY = ['.png', '.gif', '.jpg', '.jpeg', '.svg'];

/**
 * USAGE NOTE
 *
 * If binding a file saving method to this input, make sure to bind to `fileSelected` output and NOT
 * use ngModelChange, as ngModelChange triggers even if a file already exists for the binding and could cause
 * unwanted file saves and API calls.
 *
 * ie:
 *
 * Data::
 * Employee {
 *   avatar: someRandomFile.png
 * }
 *
 * Template::
 * <ui-mat-file-upload [ngModel]="employee.avatar" (ngModelChange)="saveAvatar($event)">
 *
 *
 * TS::
 * saveAvatar(file: File): void {
 *     // this trigger immediately after ngOnInit in the angular lifecycle, since the ngModel above
 *     // is being set to the already selected file
 *     this.employee.avatar = filehelper.store(file);
 * }
 */
@Component({
    selector: 'ui-mat-file-upload',
    templateUrl: './file-upload.template.html',
    styleUrls: ['./file-upload.style.scss'],

    providers: [{ provide: MatFormFieldControl, useExisting: FileUploadComponent }],
    host: {
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
        '[attr.required]': 'required',
    },
})
export class FileUploadComponent implements MatFormFieldControl<File>, ControlValueAccessor {
    @ViewChild('label') label: ElementRef<HTMLLabelElement>;
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

    /**
     * This component can take a single string of allowed mime types or an array of allowed file types.
     *
     * Mime types should be provided as a single string, for example: 'image/*' to allow images.
     * File types should be provided as an array of string, for example: ['.jpg', '.bmp', '.svg'].
     */
    @Input() allowedTypes: string | string[] = ALL_FILE_TYPES;
    static nextId = 0;
    id = `file-upload-${FileUploadComponent.nextId++}`;
    controlType: string;
    stateChanges = new Subject<void>();
    loading = false;
    onChange = (_: any) => {};
    onTouched = () => {};
    describedBy = '';
    value: File | null = null;
    focused = false;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    /**
     * Bind to this instead of ngModelChange when doing files aves since ngModelChange gets
     * triggered if an existing file is bound to this input, causing a resave.
     */
    @Output() fileSelected: EventEmitter<File | null> = new EventEmitter<File | null>();

    @Input()
    get readOnly() {
        return this._readOnly;
    }
    set readOnly(value) {
        this._readOnly = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input() get errorState(): boolean {
        if (this._parentForm?.submitted && this._invalidFileTypeSelected) {
            return true;
        }

        return (
            this.ngControl &&
            this._defaultErrorStateMatcher.isErrorState(this.ngControl.control as FormControl, this._parentForm)
        );
    }

    @Input() get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    get empty() {
        return !this.value;
    }

    get hasFile(): boolean {
        return !!this.value;
    }

    protected _placeholder: string;
    protected _required = false;
    protected _readOnly = false;
    protected _disabled = false;
    protected _subscriptions: Subscription[] = [];
    protected _invalidFileTypeSelected = false;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        protected elementRef: ElementRef<HTMLElement>,
        protected fm: FocusMonitor
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        const fmMonitorSub = this.fm.monitor(this.elementRef.nativeElement, true).subscribe((origin) => {
            if (this._disabled) {
                return;
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });
        this._subscriptions.push(fmMonitorSub);

        // Need to do this to show mat-errors when form is submitted.
        if (this._parentForm) {
            const parentFormSub = this._parentForm.ngSubmit.subscribe(() => {
                this.stateChanges.next();
            });
            this._subscriptions.push(parentFormSub);
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._subscriptions.forEach((s) => s.unsubscribe());
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    writeValue(value: File): void {
        this.value = value;
        this.onChange(value);
        this.stateChanges.next();
    }

    onContainerClick(event: MouseEvent): void {
        if (this._disabled) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        if (event.target === this.label.nativeElement) {
            return;
        }

        this.label.nativeElement.click();
    }

    onFileSelected(files: FileList): void {
        const file = files.item(0);
        this._invalidFileTypeSelected = !!file && !this.isValidFileType(file);
        if (!this._invalidFileTypeSelected) {
            this.fileSelected.emit(file);
        }
        this.writeValue(file);
    }

    getAllowedTypes(): string {
        if (!this.allowedTypes) {
            return '*';
        }

        // If just a mime type string is given (ie: image/*) use just that
        if (typeof this.allowedTypes === 'string') {
            return this.allowedTypes;
        }

        // Return a conjoined list of file ending types allowed
        return this.allowedTypes.join(',');
    }

    clear(): void {
        this.writeValue(null);
        this.fileInput.nativeElement.value = null;
        this.fileSelected.emit(null);
    }

    private isValidFileType(file: File): boolean {
        if (this.allowedTypes === ALL_FILE_TYPES) {
            return true;
        }

        const fileNameChunks = file.name.split('.');
        const fileType = '.' + fileNameChunks[fileNameChunks.length - 1];
        return arrayWrap(this.allowedTypes)
            .map((type) => type.toLowerCase())
            .includes(fileType.toLowerCase());
    }
}
