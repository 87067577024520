import { SimpleEntityActions } from '@app/classes';
import { JobPostingLocationTypeLabelsMap } from '@app/modules/applicant-tracker/constants/job-posting-location-type-labels-map.constant';
import { ModelMixin } from '../core/base-generic.model';
import { Translatable, TranslatableKey } from '@app/types/translatable.type';

export class JobPostingLocationType extends ModelMixin<JobPostingLocationType>() {
    static permission = new SimpleEntityActions('jobPostingLocationType');

    protected static _resource = 'applicantTracker/jobPostingLocationTypes';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['name'];

    get id(): number {
        return this._attributes['id'] || null;
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get label(): TranslatableKey {
        return JobPostingLocationTypeLabelsMap[this._attributes['name']];
    }
}
