import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Model } from '@app/models/core/base.model';
import { isEmpty, omit } from 'lodash-es';

const BACK_END_FORM_ERROR_KEY = 'back-end-error';

@Component({
    template: '',
})
export class BaseForm {
    @ViewChild('form') form: NgForm;

    get valid(): boolean {
        return (
            this.form.valid ||
            // Checks if the only error present on the form is external (from the back-end) in which case the form itself is still valid
            (this.form.errors &&
                Object.keys(this.form.errors).length === 1 &&
                this.form.errors[BACK_END_FORM_ERROR_KEY])
        );
    }

    submit(): void {
        // When submitting we want to clear the back-end error since we don't know if it's relevant any longer until we get another response from the backend
        // If we don't do this, then the form in dialogs will remain unlocked after a back-end error, allowing users to spam click the "submit" button
        if (this.form.form?.errors?.[BACK_END_FORM_ERROR_KEY]) {
            // If the form has other errors as well as the backend error (this should never occur, but is a good safeguard) then we keep the other relevant errors
            const errors = omit(this.form.form.errors, BACK_END_FORM_ERROR_KEY);
            this.form.form.setErrors(isEmpty(errors) ? null : errors);
        }
        this.form.onSubmit(null);
    }

    reset(): void {
        for (const attribute in this) {
            if (this.hasOwnProperty(attribute)) {
                const field: any = Object.getOwnPropertyDescriptor(this, attribute)['value'];

                let isModel = false;

                if (field instanceof Model) {
                    isModel = true;
                }

                if (isModel) {
                    this[attribute] = new field.constructor();
                }
            }
        }

        this.form.resetForm();

        setTimeout(() => {
            $('.page.dimmer.visible.active .ui.dropdown').dropdown('clear');
        });
    }

    /**
     * Look for any dropdowns in the form and set their "active"
     */
    updateDropdowns() {}

    /**
     * Can be called before showing a form
     * Automatically called on forms in modals
     * To be overridden in child form class
     */
    beforeShow() {
        return;
    }

    // Used to indicate that the back end has sent an error response to a form submission
    addExternalError(): void {
        this.form.form.setErrors({ [BACK_END_FORM_ERROR_KEY]: true });
    }
}
