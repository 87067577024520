export enum WcbBackfillStatus {
    DONE = 'done',
    IN_PROGRESS = 'processing',
    PENDING = 'pending',
    EMPTY = 'empty',
}

export interface WcbBackfill {
    payroll_id: number;
    state: WcbBackfillStatus;
}

export interface WcbBackfillPayload {
    data: {
        attributes: WcbBackfill;
    };
}
