import { Model } from '@models/core/base.model';
import { PayrollEmployee } from '@models/payroll/payroll-employee.model';

export class EmployeeDeduction extends Model {
    protected static _resource = 'payroll/employees/:employee/employeeDeductions';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['deductionId', 'employeePremium', 'companyContribution'];

    get deductionId(): number {
        return this._attributes['deductionId'];
    }

    set deductionId(val: number) {
        this._attributes['deductionId'] = val;
    }

    get employeeId(): number {
        return Number(this._attributes['employeeId']);
    }

    get employeePremium(): number {
        return this._attributes['employeePremium'];
    }

    set employeePremium(val: number) {
        this._attributes['employeePremium'] = val;
    }

    get companyContribution(): number {
        return this._attributes['companyContribution'];
    }

    set companyContribution(val: number) {
        this._attributes['companyContribution'] = val;
    }

    get prEmployeeId(): number {
        return this._attributes['employeeId'];
    }

    get employee(): PayrollEmployee {
        return this.hasOne(PayrollEmployee, 'employees');
    }
}
