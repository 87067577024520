import { Pipe, PipeTransform } from '@angular/core';
import { getImageFileTypes } from '@app/functions/files/get-image-file-types';

@Pipe({ name: 'fileIcon' })
export class FileIconPipe implements PipeTransform {
    transform(value): any {
        const fileNameChunks = value.split('.');
        const fileType = '.' + fileNameChunks[fileNameChunks.length - 1];
        const isImageType = getImageFileTypes().includes(fileType.toLowerCase());
        if (isImageType) {
            return 'image';
        }
        return 'file';
    }
}
