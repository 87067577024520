import { InjectionToken } from '@angular/core';

/**
 * This is to help dependency injection and lets angular know that it can provide a default
 * values for the local-storage.service.ts.
 */

export const USE_ENCRYPTION = new InjectionToken<boolean>('UseEncryption', {
    providedIn: 'root',
    factory: (): boolean => false,
});
