<button
    mat-button
    [attr.mat-raised-button]="useRaisedButton"
    class="menu-trigger"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
>
    {{ 'filter' | translate }} <mat-icon svgIcon="{{ activeFilterCount ? 'filterFilled' : 'filter' }}"></mat-icon>
</button>

<mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let filter of groups" [matMenuTriggerFor]="sub_menu">
        {{ filter.label | appTranslate }}
        <mat-menu #sub_menu="matMenu">
            <button mat-menu-item (click)="select($event, option)" *ngFor="let option of filter.options">
                <mat-checkbox [color]="'primary'" [checked]="option.selected">
                    {{ option.label | appTranslate }}
                </mat-checkbox>
            </button>
        </mat-menu>
    </button>
    <button mat-menu-item (click)="clear($event); menuTrigger.closeMenu()">
        <mat-icon class="closing" color="" svgIcon="close"></mat-icon>
        {{ 'clearFilters' | translate }}
    </button>
</mat-menu>
