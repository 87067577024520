import { Injectable } from '@angular/core';
import { Rl1Box } from '@app/models/payroll/rl1-box.model';

@Injectable()
export class Rl1BoxService {
    rl1BoxOptions: Rl1Box[] = [];

    constructor() {
        this.load();
    }

    /**
     * This currently is what we need in order to retrieve rl1Boxes in the benefits view
     * Once we add flow for the rl-1 show view we will change the logic to add or remove
     * filters based on what component is using this
     */
    private load(): void {
        Rl1Box.get().then((rl1BoxOptions) => {
            [this.rl1BoxOptions] = rl1BoxOptions;
            this.rl1BoxOptions.sort((rl1BoxOption1, rl1BoxOption2) => rl1BoxOption1.code - rl1BoxOption2.code);
        });
    }
}
