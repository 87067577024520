<div class="ui page dimmer">
    <div class="content">
        <div class="ui segment" [class.loading]="isLoading">
            <h2 class="ui header">{{ headerText | appTranslate }}</h2>

            <div class="content">
                <form class="ui form" #form="ngForm">
                    <div class="ui stackable grid">
                        <div class="eight wide column">
                            <a (click)="selectPage()" [class.disabled]="!items?.length" class="bulk-action">{{
                                'components.dialog-common.selectPage' | appTranslate
                            }}</a>
                        </div>
                        <div class="eight wide column">
                            <h4 class="ui header">{{ 'components.dialog-common.selected' | appTranslate }}</h4>
                            <a
                                (click)="removeAll()"
                                [class.disabled]="(!selectItems?.length && !allSelected) || disabled"
                                class="bulk-action"
                                >{{ 'components.dialog-common.removeAll' | appTranslate }}</a
                            >
                        </div>
                        <div class="eight wide column results">
                            <div class="ui middle aligned selection list">
                                <ng-container *ngFor="let item of items; let index = index">
                                    <div class="item" [class.selected]="isSelected(item)" (click)="select(item)">
                                        <i class="fa fa-key"></i>&nbsp;&nbsp;&nbsp;&nbsp;{{
                                            item?.name | appTranslateOptions: roleDisplayOptions
                                        }}
                                        ({{
                                            (item.isImmutable
                                                ? 'components.dialog-common.defaultRole'
                                                : 'components.dialog-common.customRole'
                                            ) | appTranslate
                                        }})
                                    </div>
                                </ng-container>
                                <div *ngIf="!items?.length" class="item disabled">
                                    {{ 'components.dialog-common.noResults' | appTranslate }}
                                </div>
                            </div>
                        </div>
                        <div class="eight wide column selection">
                            <div class="ui middle aligned selection list">
                                <div class="item" *ngIf="allSelected" (click)="removeAll()" [class.disabled]="disabled">
                                    <app-avatar
                                        [fullName]="
                                            {
                                                key: 'components.dialog-common.allCountFilteredItems',
                                                params: { count: totalResults },
                                            } | appTranslate
                                        "
                                        [name]="true"
                                    ></app-avatar>
                                    <div class="job-title"></div>
                                </div>
                                <ng-container *ngFor="let item of selectItems; let index = index">
                                    <div class="item" (click)="remove(item)" [class.disabled]="disabled">
                                        <i class="fa fa-key"></i>&nbsp;&nbsp;&nbsp;&nbsp;{{
                                            item?.name | appTranslateOptions: roleDisplayOptions
                                        }}
                                        ({{
                                            (item.isImmutable
                                                ? 'components.dialog-common.defaultRole'
                                                : 'components.dialog-common.customRole'
                                            ) | appTranslate
                                        }})
                                    </div>
                                </ng-container>
                                <div *ngIf="!selectItems?.length && !allSelected" class="item disabled">
                                    {{ 'components.dialog-common.noSelections' | appTranslate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="actions">
                <app-pagination
                    *ngIf="items?.length"
                    [total]="totalResults"
                    [current]="currentPage"
                    [fixed]="false"
                    (pageChange)="onPageChange($event)"
                >
                </app-pagination>

                <ui-button style="float: right" (click)="approve()" [disabled]="!selection || disabled" type="primary">
                    {{ label | appTranslate }}
                </ui-button>
            </div>
        </div>
    </div>
</div>
