import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type MobileSidenavStatus = { readonly isOpen: boolean };

const defaultMobileSidenavStatus: MobileSidenavStatus = { isOpen: false };

/**
 * MobileSidenavService is in charge of controlling the state of the mobile side nav
 *
 * It allows for showing/hiding the sidenav, and also injecting content using portals.
 */
@Injectable({
    providedIn: 'root',
})
export class MobileSidenavService {
    private _portal$ = new BehaviorSubject<TemplatePortal | undefined>(undefined);

    /**
     * portal$ is an observable that produces the currently injected template for the sidebar
     */
    get portal$(): Observable<TemplatePortal | undefined> {
        return this._portal$.asObservable();
    }

    get mobileSidenavStatus$(): Observable<MobileSidenavStatus> {
        return this._mobileSidenavStatus.asObservable();
    }

    private _mobileSidenavStatus: BehaviorSubject<MobileSidenavStatus> = new BehaviorSubject<MobileSidenavStatus>(
        defaultMobileSidenavStatus
    );

    getCurrentStatus(): MobileSidenavStatus {
        return this._mobileSidenavStatus.getValue();
    }

    toggle(): void {
        if (this._mobileSidenavStatus.getValue().isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    open(): void {
        this._mobileSidenavStatus.next({ isOpen: true });
    }

    close(): void {
        this._mobileSidenavStatus.next({ isOpen: false });
    }

    /**
     * setPortal allows for injecting replacing the sidebar's content with custom
     * content.
     */
    setPortal(templateRef: TemplateRef<unknown>, viewContainerRef: ViewContainerRef): void {
        const portal = new TemplatePortal(templateRef, viewContainerRef);
        this._portal$.next(portal);
    }

    /**
     * clearPortal removes custom content from the sidebar and returns the sidebar to it's default state
     */
    clearPortal(): void {
        this._portal$.next(undefined);
    }
}
