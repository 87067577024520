import { Model } from '@models/core/base.model';

export class JobIndeedIntegration extends Model {
    protected static _type = 'indeedIntegrations';

    get jobId(): number {
        return this._attributes['jobId'];
    }

    get indeedJobIdentifier(): string {
        return this._attributes['indeedJobIdentifier'];
    }

    get employerNumber(): number {
        return this._attributes['employerNumber'];
    }

    get isActive(): boolean {
        return this._attributes['isActive'];
    }
}
