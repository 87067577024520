import { ModelMixin } from '@models/core/base-generic.model';
import {
    AuthorizationDocumentType,
    SelfOnboardingDocumentType,
    SupportingDocumentType,
} from '@payroll/types/self-onboarding-document-type.type';

export type DocumentStatus = 'new' | 'ready_for_review' | 'verified' | 'rejected';

export type AuthorizationDocument = SelfOnboardingDocument<AuthorizationDocumentType>;
export type SupportingDocument = SelfOnboardingDocument<SupportingDocumentType>;

export class SelfOnboardingDocument<
    T extends SelfOnboardingDocumentType = SelfOnboardingDocumentType,
> extends ModelMixin<SelfOnboardingDocument>() {
    protected static _version = 'v2';

    protected static _resource = 'payroll/companies/selfOnboarding/documents';

    protected static _serializeAttributes = ['displayName', 'type', 'state', 'rejectionReason'];

    get displayName(): string {
        return this._attributes['displayName'];
    }
    set displayName(val: string) {
        this._attributes['displayName'] = val;
    }

    get type(): T {
        return this._attributes['type'];
    }
    set type(val: T) {
        this._attributes['type'] = val;
    }

    get state(): DocumentStatus {
        return this._attributes['state'];
    }
    set state(val: DocumentStatus) {
        this._attributes['state'] = val;
    }

    get rejectionReason(): string {
        return this._attributes['rejectionReason'];
    }
    set rejectionReason(val: string) {
        this._attributes['rejectionReason'] = val;
    }

    get documentUploaderEmail(): string {
        return this._attributes['documentUploaderEmail'];
    }

    get documentUploaderFirstName(): string {
        return this._attributes['documentUploaderFirstName'];
    }

    get documentUploaderLastName(): string {
        return this._attributes['documentUploaderLastName'];
    }
}
