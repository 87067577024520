import { Model } from '@models/core/base.model';

export class ResthooksAPIKey extends Model {
    protected static _type = 'resthooksApiKeys';

    protected static _version = 'v2';

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get integrationId(): number {
        return this._attributes['integrationId'];
    }

    get value(): string {
        return this._attributes['value'];
    }
}
