export enum AppResources {
    Auth0HumiToken = '/v2/accountManagement/exchangeAccessToken',
    Authenticate = '/v2/accountManagement/login',
    Registration = '/v2/accountManagement/register',
    Activate = '/v2/accountManagement/activate',
    ActivateInvitation = '/v2/accountManagement/activateInvitation',
    StorePassword = '/v2/accountManagement/accounts/:accountId/password',
    ResetPassword = '/v2/accountManagement/forgotPassword',
    ResetPasswordAuth = '/v2/accountManagement/resetPassword',
    KeepAlive = '/v2/accountManagement/keepAlive',
    Reissue = '/v2/accountManagement/reissueTokens',
    LoginFlags = '/v2/accountManagement/loginFlags',
    Logout = '/v2/accountManagement/logout',
    Version = '/v2/commonComponents/version',
    BulkInvite = '/v2/companyManagement/companies/:company/invite',
    CheckCommonPassword = '/v2/accountManagement/checkCommonPassword',
    EmailAvailable = '/v2/accountManagement/checkEmailAvailability',
    Impersonate = '/v2/accountManagement/accounts/:accountId/impersonate',
    DuplicateRole = '/v2/accountManagement/roles/:roleId/duplicate',
    CheckActivationToken = '/v2/accountManagement/checkActivationToken',
    AccessibleEmployeeIds = '/v2/accountManagement/companies/:company/accessibleEmployeeIds',
    PayrollSession = '', // @deprecated
    PayrollSync = '', // @deprecated
    ExchangeSsoToken = '/v2/accountManagement/login/ssoToken',
    ExchangeImpersonateToken = '/v2/accountManagement/login/impersonateToken',
    ExchangeNewAccountToken = '/v2/accountManagement/login/newAccountToken',

    SupportTicket = 'https://humi.zendesk.com/hc/en-us/requests/new',
    HelpCenter = 'https://support.humi.ca',
    SalesPage = 'https://www.humi.ca/contact-sales',
    FeatureAcknowledgement = '/v2/feature-acknowledgement',
    EmployeeModuleAssignment = '/v2/commonComponents/employeeModuleAssignment/assign',
}
