import { Model } from '@models/core/base.model';
import { TimeOffApprovalFlowStep } from '@models/time-off-v3/time-off-approval-flow-step.model';
import { TimeOffRequestApprovalFlow } from '@models/time-off-v3/time-off-request-approval-flow.model';
import { TimeOffRequestApproval } from '@models/time-off-v3/time-off-request-approval.model';

export class TimeOffRequestApprovalFlowStep extends Model {
    protected static _type = 'timeOffRequestApprovalFlows';

    get timeOffRequestApprovalFlowId(): number {
        return this._attributes['timeOffRequestApprovalFlowId'];
    }

    get timeOffRequestApprovalFlow(): TimeOffRequestApprovalFlow {
        return this.hasOne(TimeOffRequestApprovalFlow, 'timeOffRequestApprovalFlow');
    }

    get timeOffApprovalFlowStepId(): number {
        return this._attributes['timeOffApprovalFlowStepId'];
    }

    get timeOffApprovalFlowStep(): TimeOffApprovalFlowStep {
        return this.hasOne(TimeOffApprovalFlowStep, 'timeOffApprovalFlowStep');
    }

    get approved(): boolean | null {
        return this._attributes['approved'];
    }

    get stepIndex(): number {
        return this._attributes['stepIndex'];
    }

    get timeOffRequestApprovals(): TimeOffRequestApproval[] {
        return this.hasMany(TimeOffRequestApproval, 'timeOffRequestApprovals');
    }

    set timeOffRequestApprovals(value: TimeOffRequestApproval[]) {
        this.setMany('timeOffRequestApprovals', value);
    }

    get isTrashed(): boolean {
        return this.deletedAt !== null;
    }
}
