import { Directive, ElementRef, Input } from '@angular/core';
import { Icons } from '@app/enums';

@Directive({
    selector: '[appIcon]',
})
export class IconDirective {
    constructor(protected element: ElementRef) {}

    @Input()
    set appIcon(iconEnum: string) {
        if (Icons[iconEnum]) {
            this.element.nativeElement.src = Icons[iconEnum];
        } else {
            this.element.nativeElement.src = Icons.Missing;
        }
    }
}
