import { Model } from '@models/core/base.model';
import { TimeOffDayCalendarEvent } from '@models/time-off-v3/time-off-day-calendar-event.model';
import { HolidayCalendarEvent } from '@models/time-off/holiday-calendar-event.model';
import moment from 'moment';

export class TimeOffDay extends Model {
    protected static _resource = 'timeOffV3/days';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['workScheduleId', 'calendarDayTypeId', 'name', 'date'];

    protected static _dates = ['date'];

    protected static _type = 'timeOffDays';

    get workScheduleId(): number {
        return this._attributes['workScheduleId'];
    }

    get calendarDayTypeId(): number {
        return this._attributes['calendarDayTypeId'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get date(): Date {
        return this._attributes['date'];
    }

    get calendarType(): any {
        // TODO: This will be implemented as part of CalendarType model task
        return 'not ready yet';
    }

    set workScheduleId(val: number) {
        this._attributes['workScheduleId'] = val;
    }

    set calendarDayTypeId(val: number) {
        this._attributes['calendarDayTypeId'] = val;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    set date(val: Date) {
        this._attributes['date'] = val;
    }

    toCalendarEvent(): TimeOffDayCalendarEvent {
        return new TimeOffDayCalendarEvent({
            description: this.name,
            startAt: moment(this.date),
            endAt: moment(this.date).add(1, 'day'),
        });
    }

    toHolidayCalendarEvent(): HolidayCalendarEvent {
        return new HolidayCalendarEvent({
            summary: this.name,
            startAt: moment(this.date),
            endAt: moment(this.date).add(1, 'day'),
        });
    }
}
