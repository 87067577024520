import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { focusOn, focusOff } from '../focusLock.lib';
import { ButtonTypes } from '../button/button.component';
import { NgForm } from '@angular/forms';
import { overlayAnimationTime } from '../overlay.component';
import { FadeAnimationOpenClosed } from '@app/animations/fade.animation';

// For now, Input is all we want to submit on. Subject to change.
const submittableHtmlTags = ['INPUT'];

@Component({
    selector: 'ui-bulk-action-bar',
    animations: [FadeAnimationOpenClosed(overlayAnimationTime)],
    templateUrl: './bulk-action-bar.template.html',
    styleUrls: ['./bulk-action-bar.style.scss'],
})
export class BulkActionBarComponent {
    constructor(protected elementRef: ElementRef) {}

    /**
     * We want to "submit" the dialog when there is specific element focused
     *   > ie: user opens a confirmation dialog, hits enter
     * We want to "submit" the dialog when the user hits enter within an input
     * element in a form, but *not* a text area.
     */
    @HostListener('window:keydown.enter', ['$event'])
    handleKey(): void {
        const focusedElement = this.elementRef.nativeElement.querySelector(':focus');
        const dialogElement = this.dialog.nativeElement;
        const dialogIsFocused = focusedElement === dialogElement;

        if (dialogIsFocused || submittableHtmlTags.includes(focusedElement?.tagName)) {
            this.onPrimaryButtonPressed();
        }
    }

    @Input() form: NgForm;
    @Input() primaryButtonLabel: string | null = null;
    @Input() primaryButtonType: ButtonTypes = 'primary';

    @Output() primaryButtonPressed: EventEmitter<void> = new EventEmitter<void>();

    @Output() closing = new EventEmitter();

    @Input() set open(isOpen: boolean) {
        this._open = isOpen;

        if (this._open) {
            // Need to unlock actions when the dialog opens.
            this._actionsLocked = false;
            // Perform focus locking as animation ends
            setTimeout(() => {
                this.onOpening();
            }, overlayAnimationTime);
            return;
        }

        this.closeDialog(false);
    }
    get open(): boolean {
        return this._open;
    }

    @ViewChild('dialog') dialog: ElementRef<HTMLDivElement>;

    _actionsLocked = false;
    _open = false;

    closeDialog(emit = true): void {
        this.onClosing();
        this._actionsLocked = true;
        if (emit) {
            this.closing.emit();
        }
    }

    onPrimaryButtonPressed(): void {
        // Prevent successive triggering of submission
        if (this._actionsLocked) {
            return;
        }

        this.primaryButtonPressed.emit();

        // If no form, limit to one possible submission
        if (!this.form) {
            this._actionsLocked = true;
            return;
        }

        // Validate the form
        this.form.onSubmit(null);
        // if the form is valid, lock the submission to prevent multiple saves and such
        this._actionsLocked = this.form.valid;
    }

    private onOpening() {
        focusOn(this.dialog.nativeElement);
        this.dialog.nativeElement?.focus();
        if (this.form) {
            const firstInputElement = this.dialog.nativeElement.querySelector('input');
            firstInputElement?.focus();
        }
    }

    private onClosing(): void {
        if (this.dialog) {
            focusOff(this.dialog.nativeElement);
        }
    }
}
