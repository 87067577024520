import { HttpErrorResponse } from '@angular/common/http';

export class BadRequestError extends Error {
    httpStatus = 400;

    constructor(
        public res: HttpErrorResponse,
        message?: string
    ) {
        super(message ? message : res.statusText);
        Object.setPrototypeOf(this, BadRequestError.prototype);
    }
}
