<!-- Drag and drop options -->
<div
    cdkDropList
    class="drag-and-drop"
    [class.displayBorder]="addBorder"
    (cdkDropListDropped)="onOptionOrderChange($event)"
>
    <div cdkDrag [cdkDragData]="option" *ngFor="let option of options; let i = index" class="options">
        <div class="option">
            <!-- Drag icon -->
            <div class="drag-icon">
                <mat-icon cdkDragHandle class="margin-top-1" svgIcon="dragAndDrop"></mat-icon>
            </div>

            <!-- Input field -->
            <mat-form-field class="input-field">
                <input
                    name="option-{{ i }}"
                    type="text"
                    [(ngModel)]="option[optionAttributeName]"
                    (ngModelChange)="emitOptions()"
                    (keyup)="addOnEnter($event, i)"
                    #optionInputs
                    matInput
                />
            </mat-form-field>

            <!-- Remove Button -->
            <ng-container *ngIf="showRemove()">
                <ui-button type="ghost" class="remove-button" (click)="onRemove(i)">
                    <mat-icon svgIcon="close"></mat-icon> {{ 'remove' | appTranslate }}
                </ui-button>
            </ng-container>
        </div>
    </div>
</div>

<!-- Add Button -->
<ui-button
    [type]="addButtonType"
    class="margin-bottom-2 margin-top-1"
    *ngIf="options.length < maxOptions"
    (click)="onAdd(); $event.preventDefault()"
>
    <mat-icon class="margin-right-1" svgIcon="plus"></mat-icon>{{ addButtonLabel | appTranslate }}
</ui-button>
