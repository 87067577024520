import { SalaryFrequency } from '@app/modules/employees/enums/salary-frequency.enum';
import moment from 'moment';
import { Department } from '../company/department.model';
import { Job } from '../company/job.model';
import { Office } from '../company/office.model';
import { Model } from '../core/base.model';
import { SignatureRequest } from '../documents/signature-request.model';
import { SigningTemplate } from '../documents/signing-template.model';
import { Employee } from '../employee/employee.model';
import { SignerSlot } from './signer-slots';

export class OfferLetter extends Model {
    protected static _resource = 'offer-letters';
    protected static _version = 'v2';

    protected static _type = 'offerLetters';

    protected static _serializeAttributes = [
        'signingTemplateId',
        'reportsToId',
        'candidateId',
        'atsApplicantId',
        'email',
        'legalFirstName',
        'legalLastName',
        'positionId',
        'departmentId',
        'locationId',
        'employmentType',
        'compensationRate',
        'compensationFrequency',
        'stockOptions',
        'probationPeriod',
        'contractTerm',
        'candidateAddress',
        'hoursPerWeek',
        'firstDayOfWorkOn',
        'contractEndsOn',
        'vacationDays',
        'expiresOn',
        'signedAt',
        'declinedAt',
        'withdrawnAt',
        'signerSlots',
    ];

    protected static _dates = ['firstDayOfWork', 'expiresOn', 'contractEndsOn'];

    protected static _datetimes = [
        'createdAt',
        'updatedAt',
        'deletedAt',
        'firstSeenAt',
        'signedAt',
        'sentAt',
        'declinedAt',
        'withdrawnAt',
    ];

    /**
     * Only available after offer letter signed.
     */
    get signatureRequest(): SignatureRequest | null {
        return this.hasOne(SignatureRequest, 'signatureRequest');
    }

    get cosigners(): SignatureRequest[] {
        return this.hasMany(SignatureRequest, 'cosigners');
    }

    get signerSlots(): SignerSlot[] {
        return this._attributes['signerSlots'];
    }

    set signerSlots(val: SignerSlot[]) {
        this._attributes['signerSlots'] = val;
    }

    get signingTemplateId(): number | null {
        return this._attributes['signingTemplateId'] || null;
    }

    set signingTemplateId(val: number) {
        this._attributes['signingTemplateId'] = val;
    }

    get signingTemplate(): SigningTemplate {
        return this.hasOne(SigningTemplate, 'signingTemplate');
    }

    set signingTemplate(signingTemplate: SigningTemplate) {
        this.setOne('signingTemplate', signingTemplate, 'signingTemplateId');
    }

    get reportsToId(): number | null {
        return this._attributes['reportsToId'] || null;
    }

    set reportsToId(val: number) {
        this._attributes['reportsToId'] = val;
    }

    get candidateId(): number {
        return this._attributes['candidateId'];
    }

    set candidateId(val: number) {
        this._attributes['candidateId'] = val;
    }

    get atsApplicantId(): number | null {
        return parseInt(this._attributes['atsApplicantId']) || null;
    }

    set atsApplicantId(val: number | null) {
        this._attributes['atsApplicantId'] = val;
    }

    get reportsTo(): Employee | null {
        return this.hasOne(Employee, 'reportsTo') || null;
    }

    set reportsTo(employee: Employee) {
        this.setOne('reportsTo', employee, 'reportsToId');
    }

    get creator(): Employee {
        return this.hasOne(Employee, 'creator');
    }

    /**
     * Alias for creator
     * Sender is what we show in the UI
     */
    get sender(): Employee {
        return this.creator;
    }

    get email(): string {
        return this._attributes['email'];
    }

    set email(value: string) {
        this._attributes['email'] = value;
    }

    get legalFirstName(): string {
        return this._attributes['legalFirstName'];
    }

    set legalFirstName(value: string) {
        this._attributes['legalFirstName'] = value;
    }

    get legalLastName(): string {
        return this._attributes['legalLastName'];
    }

    set legalLastName(value: string) {
        this._attributes['legalLastName'] = value;
    }

    get positionId(): number | null {
        return this._attributes['positionId'] || null;
    }

    set positionId(val: number | null) {
        this._attributes['positionId'] = val;
    }

    get position(): Job {
        return this.hasOne(Job, 'position');
    }

    set position(position: Job) {
        this.setOne('position', position, 'positionId');
    }

    get departmentId(): number | null {
        return this._attributes['departmentId'] || null;
    }

    set departmentId(val: number | null) {
        this._attributes['departmentId'] = val;
    }

    get department(): Department {
        return this.hasOne(Department, 'department');
    }

    set department(department: Department) {
        this.setOne('department', department, 'departmentId');
    }

    get locationId(): number | null {
        return this._attributes['locationId'] || null;
    }

    set locationId(val: number | null) {
        this._attributes['locationId'] = val;
    }

    get location(): Office {
        return this.hasOne(Office, 'location');
    }

    set location(location: Office) {
        this.setOne('location', location, 'locationId');
    }

    get employmentType(): string | null {
        return this._attributes['employmentType'] || null;
    }

    set employmentType(value: string | null) {
        this._attributes['employmentType'] = value;
    }

    get compensationRate(): number | null {
        return this._attributes['compensationRate'] || null;
    }

    set compensationRate(value: number | null) {
        this._attributes['compensationRate'] = value;
    }

    get compensationFrequencyDisplayValue(): 'Yearly' | 'Hourly' | null {
        if (this._attributes['compensationFrequency'] === 'year') {
            return 'Yearly';
        }

        if (this._attributes['compensationFrequency'] === 'hour') {
            return 'Hourly';
        }

        return null;
    }

    get compensationFrequency(): SalaryFrequency | null {
        return this._attributes['compensationFrequency'] || null;
    }

    set compensationFrequency(value: SalaryFrequency | null) {
        this._attributes['compensationFrequency'] = value;
    }

    get stockOptions(): string | null {
        return this._attributes['stockOptions'] || null;
    }

    set stockOptions(val: string | null) {
        this._attributes['stockOptions'] = val;
    }

    get probationPeriod(): string | null {
        return this._attributes['probationPeriod'] || null;
    }

    set probationPeriod(value: string | null) {
        this._attributes['probationPeriod'] = value;
    }

    get contractTerm(): string | null {
        return this._attributes['contractTerm'] || null;
    }

    set contractTerm(value: string | null) {
        this._attributes['contractTerm'] = value;
    }

    get candidateAddress(): string | null {
        return this._attributes['candidateAddress'] || null;
    }

    set candidateAddress(value: string | null) {
        this._attributes['candidateAddress'] = value;
    }

    get hoursPerWeek(): number | null {
        return this._attributes['hoursPerWeek'] || null;
    }

    set hoursPerWeek(val: number | null) {
        this._attributes['hoursPerWeek'] = val;
    }

    get firstDayOfWorkOn(): Date | null {
        return this._attributes['firstDayOfWorkOn'] || null;
    }

    set firstDayOfWorkOn(val: Date | null) {
        this._attributes['firstDayOfWorkOn'] = val;
    }

    get contractEndsOn(): Date | null {
        return this._attributes['contractEndsOn'] || null;
    }

    set contractEndsOn(val: Date | null) {
        this._attributes['contractEndsOn'] = val;
    }

    get vacationDays(): number | null {
        return this._attributes['vacationDays'] || null;
    }

    set vacationDays(val: number | null) {
        this._attributes['vacationDays'] = val;
    }

    get expiresOn(): Date | null {
        return this._attributes['expiresOn'] || null;
    }

    set expiresOn(val: Date | null) {
        this._attributes['expiresOn'] = val;
    }

    /**
     * sentAt is when we actually send the offer letter to the candidate.
     * If there are cosigners, we don't send it right away.
     */
    get sentAt(): Date | null {
        return this._attributes['sentAt'] || null;
    }

    get signedAt(): Date | null {
        return this._attributes['signedAt'] || null;
    }

    set signedAt(val: Date | null) {
        this._attributes['signedAt'] = val;
    }

    get declinedAt(): Date | null {
        return this._attributes['declinedAt'] || null;
    }

    set declinedAt(val: Date | null) {
        this._attributes['declinedAt'] = val;
    }

    get withdrawnAt(): Date | null {
        return this._attributes['withdrawnAt'] || null;
    }

    set withdrawnAt(val: Date | null) {
        this._attributes['withdrawnAt'] = val;
    }

    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get status(): string | null {
        if (this.isPending()) {
            return 'pending';
        }

        if (this.isAccepted()) {
            return 'accepted';
        }

        if (this.isExpired()) {
            return 'expired';
        }

        return null;
    }

    /**
     * Creates a pretty filename that is likely (but not certainly) unique
     * This avoids the issue where every offer letter is downloaded with the same
     * name.
     *
     * Example: John-Matrix-offer-letter-withdrawn-on-2021-03-04.pdf
     */
    get filename(): string {
        const filenameRoot = `${this.legalFirstName}-${this.legalLastName}-offer-letter`;

        if (this.isWithdrawn()) {
            return `${filenameRoot}-withdrawn-on-${moment(this.withdrawnAt).format('YYYY-MM-DD')}`;
        }

        if (this.isDeclined()) {
            return `${filenameRoot}-declined-on-${moment(this.declinedAt).format('YYYY-MM-DD')}`;
        }

        if (this.isSigned()) {
            return `${filenameRoot}-signed-on-${moment(this.signedAt).format('YYYY-MM-DD')}`;
        }

        return filenameRoot;
    }

    isSigned(): boolean {
        return !!this.signedAt;
    }

    isPending(): boolean {
        return !(this.isWithdrawn() || this.isDeclined() || this.isExpired() || this.isAccepted());
    }

    isWithdrawn(): boolean {
        return !!this.withdrawnAt;
    }

    isDeclined(): boolean {
        return !!this.declinedAt;
    }

    /**
     * Is not expired until the day AFTER the expiry date
     */
    isExpired(): boolean {
        if (!this.expiresOn) {
            return false;
        }

        return moment().startOf('day').isAfter(this.expiresOn);
    }

    isAccepted(): boolean {
        return !!this.signedAt;
    }

    hasCosigners(): boolean {
        return this.cosigners.length > 0;
    }

    // If admin accidentally click on withdraw offer letter or candidate declined,
    // they can revert that action.
    revertChanges(): void {
        if (this.withdrawnAt) {
            this.withdrawnAt = null;
        } else {
            this.declinedAt = null;
        }
    }
}
