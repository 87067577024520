<div class="ui page dimmer">
    <div class="app-grid">
        <div class="ui segment" [class.loading]="isLoading">
            <h2 class="ui header">{{ headerText | appTranslate }}</h2>
            <div class="content">
                <form class="app-grid" #form="ngForm">
                    <mat-form-field class="columns-12">
                        <mat-label appRemoveAriaOwns>{{
                            'components.select-work-schedule-dialog.workSchedule' | appTranslate
                        }}</mat-label>
                        <mat-select
                            name="selection"
                            [(ngModel)]="selection"
                            [compareWith]="workScheduleComparison"
                            required
                        >
                            <mat-option *ngFor="let workSchedule of workSchedules" [value]="workSchedule">{{
                                workSchedule.name | appTranslateOptions: workScheduleDisplayOptions
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>

                <div class="actions">
                    <ui-button
                        [disabled]="isActionButtonDisabled()"
                        type="primary"
                        style="float: right"
                        (click)="approve()"
                    >
                        {{ label | appTranslate }}
                    </ui-button>
                </div>
            </div>
        </div>
    </div>
</div>
