import { Model } from '@models/core/base.model';

export class ContentDirectory extends Model {
    protected static _resource = 'payroll/companies/:company/payrolls/contentDirectories';

    protected static _version = 'v2';

    get baseEntityId(): number {
        return this._attributes['baseEntityId'];
    }

    get baseEntityType(): string {
        return this._attributes['baseEntityType'];
    }

    get subjectId(): number {
        return this._attributes['subjectId'];
    }

    get subjectType(): string {
        return this._attributes['subjectType'];
    }

    get contentCode(): number {
        return this._attributes['contentCode'];
    }

    get contextType(): string {
        return this._attributes['contextType'];
    }

    get subjectContext(): string {
        return this._attributes['subjectContext'];
    }

    get active(): boolean {
        return this._attributes['active'];
    }

    get state(): boolean {
        return this._attributes['state'];
    }
}
