import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslatableKey } from '@app/types/translatable.type';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: './search.template.html',
    styleUrls: ['./search.style.scss'],
})
export class SearchComponent implements OnInit {
    @Output() onSearch: EventEmitter<string | null> = new EventEmitter();

    @Input() placeholder?: TranslatableKey;
    @Input() initialValue?: string;
    @Input() searchTerm: string | null = '';
    @Input() bordered = false;
    @Input() minWidth = 250;

    @ViewChild('input') input?: ElementRef<HTMLInputElement>;

    isMobileExpanded = false;

    searchControl: FormControl<string | null> = new FormControl();

    clear(): void {
        if (this.searchTerm !== '') {
            this.searchTerm = '';
            this.onSearch.emit(this.searchTerm);
            this.isMobileExpanded = false;
        }
    }

    onTouch(): void {
        this.input?.nativeElement.focus();
    }

    onFocus(): void {
        this.isMobileExpanded = true;
    }

    onBlur(): void {
        if (this.searchTerm === '') {
            this.isMobileExpanded = false;
        }
    }

    ngOnInit(): void {
        if (this.initialValue) {
            this.searchTerm = this.initialValue;
            this.searchControl.setValue(this.initialValue);
        }

        this.searchControl.valueChanges.pipe(debounceTime(250)).subscribe((newValue) => {
            this.searchTerm = newValue;
            this.onSearch.emit(this.searchTerm === null || !this.searchTerm.length ? null : this.searchTerm);
        });
    }
}
