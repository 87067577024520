import { Model } from '@models/core/base.model';
import { ApprovalWorkflow } from '@models/approvals/approval-workflow.model';
import { Employee } from '@models/employee/employee.model';

export class ApprovalWorkflowStep extends Model {
    protected static _serializeAttributes = ['actor', 'orderPosition', 'createdAt', 'updatedAt', 'deletedAt'];

    get actor(): string | null {
        return this._attributes['actor'] ?? 'specific_employee';
    }

    set actor(actor: string | null) {
        this._attributes['actor'] = actor === 'specific_employee' ? null : actor;
    }

    get orderPosition(): number {
        return this._attributes['orderPosition'];
    }

    set orderPosition(position: number) {
        this._attributes['orderPosition'] = position;
    }

    get workflow(): ApprovalWorkflow {
        return this.hasOne(ApprovalWorkflow, 'approvalWorkflow');
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    set employee(employee: Employee) {
        this.setOne('employee', employee);
        this._attributes['employeeId'] = employee.id;
    }
}
