import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BulkSelectEmployeeDialogComponent } from '@app/components/dialogs/bulk-select-employee-dialog/bulk-select-employee-dialog.component';
import { Employee } from '@models/employee/employee.model';

@Component({
    selector: 'app-employee-list',
    templateUrl: './employee-list.template.html',
    styleUrls: ['./employee-list.style.scss'],
})
export class EmployeeListComponent {
    @Input() showEditButton = true;
    @Input() showTitle = true;
    @Input() title = 'People list';
    @Input() noEmployeeMessage = 'No one here...';
    @Input() employees: Employee[] = [];
    @Output() selectionUpdated: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('employeeSelect', { static: true }) employeeSelect: BulkSelectEmployeeDialogComponent;

    onEdit(): void {
        this.employeeSelect.show(this.employees).then((employees) => {
            if (employees) {
                this.selectionUpdated.emit(employees);
            }
        });
    }
}
