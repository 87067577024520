import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppResources } from '@app/app.resources';
import * as Sentry from '@sentry/angular';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * The features user can acknowledge in the system.
 */
export enum Features {
    benefitsUpsell = 'benefits-upsell',
    peopleReport2021 = 'people-report-2021',
    inAppUpsell = 'in-app-upsell',
}

@Injectable({
    providedIn: 'root',
})
/**
 * Feature Acknowledgements allow us to know if a user has seen or interacted with something in the system.
 * They are not part of the HR domain.
 *
 * FeatureAcknowledgements should be deleted after they are no longer useful
 *
 * Ex: Has a user ever visited the Benefits Upsell page?
 */
export class FeatureAcknowledgementService {
    /**
     * A subject that notifies that feature acknowledgements have changed.
     * You can subscribe to it. It only tells you that features have changed,
     * but not which ones.
     */
    readonly acknowledgedFeaturesChanged = new Subject<void>();

    private featureAcknowledgements: Map<Features, boolean | undefined> = new Map();

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {
        this.authService.beforeLogout.pipe(takeUntilDestroyed()).subscribe(() => {
            this.featureAcknowledgements.clear();
        });
    }

    /**
     * Has the user acknowledged this feature?
     */
    async hasAcknowledgedFeature(feature: Features): Promise<boolean> {
        if (this.featureAcknowledgements.has(feature)) {
            return this.featureAcknowledgements.get(feature);
        }

        const hasAcknowledgedFeature = await this.fetchHasAcknowledgedFeature(feature);

        this.featureAcknowledgements.set(feature, hasAcknowledgedFeature);

        this.acknowledgedFeaturesChanged.next();

        return hasAcknowledgedFeature;
    }

    /**
     * Acknowledges that this user has seen a feature.
     */
    async acknowledgeFeature(feature: Features): Promise<void> {
        const hasAcknowledgedFeature = await this.hasAcknowledgedFeature(feature);

        if (hasAcknowledgedFeature) {
            return;
        }

        const url = AppResources.FeatureAcknowledgement + '/' + feature;

        try {
            await this.http.post(url, null).toPromise();
            this.featureAcknowledgements.set(feature, true);
            this.acknowledgedFeaturesChanged.next();
        } catch (error) {
            // Leaving this here permanently.
            // In JavaScript we can't throw or it will break the entire app.
            Sentry.captureMessage(error.message || `${feature} feature could not be acknowledged`);
        }
    }

    /**
     * Queries the server to see if the user has acknowledged the feature.
     * If the server does not know about this feature (404), this function will return true
     * by default, and log to sentry letting you know something is wrong.
     */
    private async fetchHasAcknowledgedFeature(feature: Features): Promise<boolean> {
        const url = AppResources.FeatureAcknowledgement + '/' + feature;

        try {
            const response = (await this.http.get(url).toPromise()) as { hasAcknowledgedFeature: boolean };

            return response.hasAcknowledgedFeature;
        } catch (error) {
            const featureNotFound = error?.status === 404;
            if (!featureNotFound) {
                throw error;
            }

            // Leaving this here permanently.
            // In JavaScript we can't throw or it will break the entire app.
            Sentry.captureMessage(error.message || `${feature} feature not found`);
            return true;
        }
    }
}
