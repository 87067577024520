import { Employee } from '@models/employee/employee.model';

export function selectedEmployeesHaveChanged(initialEmployees: Employee[], selectedEmployees: Employee[]): boolean {
    if (initialEmployees.length !== selectedEmployees.length) {
        return true;
    }

    const selectedEmployeeIds = selectedEmployees.map((employee) => employee.id);

    return !initialEmployees.every((employee) => selectedEmployeeIds.includes(employee.id));
}
