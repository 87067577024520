import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Office } from '@app/models/company/office.model';
import { Employee } from '@app/models/employee/employee.model';
import { AuthService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash-es';

@Directive({
    selector: '[employeeOfficeErrors]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: EmployeeOfficeValidator, multi: true }],
})
export class EmployeeOfficeValidator implements AsyncValidator {
    @Input('employeeOfficeErrors') employee?: Employee;

    constructor(
        private auth: AuthService,
        private translateService: TranslateService
    ) {}

    async validate(control: AbstractControl): Promise<ValidationErrors | null> {
        if (!control.value) return this.clearError(control);
        if (!this.employee?.activeOnPayroll()) return this.clearError(control);
        if (!this.isRevenueQuenecInfoEmpty()) return this.clearError(control);

        const selectedOffice = await Office.param('company', this.auth.company.id).find(control.value);

        if (selectedOffice.province !== 'QC') return this.clearError(control);

        const error = this.translateService.instant('forms.employmentInformation.cannotBeAddedToQc');

        return this.setError(control, error);
    }

    private isRevenueQuenecInfoEmpty(): boolean {
        const company = this.auth.company;

        return (
            isEmpty(company.quebecEnterpriseNumber) ||
            isEmpty(company.rqRegistration) ||
            isEmpty(company.rqProgramCode) ||
            isEmpty(company.rqReferenceNumber) ||
            isEmpty(company.rqRemittanceFrequencyDisplay)
        );
    }

    /**
     * Attach the errors to the control, or clear it if they are empty
     */
    private setError(control: AbstractControl, error: string | null): { custom: { message: string } } | null {
        const errors = error !== null ? { custom: { message: error } } : null;
        control.setErrors(errors);
        return errors;
    }

    /**
     * Clear the validation error
     */
    private clearError(control: AbstractControl): null {
        return this.setError(control, null) as null;
    }
}
