<!-- Back Button -->
<div
    class="backContainer"
    [ngStyle]="contentRef.children.length > 0 ? { 'justify-content': 'space-between' } : {}"
    *ngIf="back"
>
    <a
        *ngIf="backRoute"
        class="back"
        [ngStyle]="contentRef.children.length > 0 ? { 'margin-top': '8px' } : {}"
        [routerLink]="backRoute"
        ><mat-icon svgIcon="chevronLeft"></mat-icon>{{ 'components.header.back' | appTranslate }}</a
    >
    <a *ngIf="!backRoute || onBackClick" class="back" (click)="onBack()"
        ><mat-icon svgIcon="chevronLeft"></mat-icon>{{ 'components.header.back' | appTranslate }}</a
    >
    <div #contentRef><ng-content select=".back-container-content"></ng-content></div>
</div>

<div class="title-container">
    <!-- Title -->
    <div class="flex">
        <h1 id="page-title" *ngIf="!noTitle" class="title" [class.ellipsis]="hideOverflow">
            {{ title | appTranslate }}

            <!-- Badge -->
            <ng-container *ngIf="badgeOptions">
                <ui-badge [type]="badgeOptions.type"> {{ badgeOptions.label | appTranslate }} </ui-badge>
            </ng-container>

            <!--Subtitle (always below south)-->
            <div *ngIf="subtitle" class="sub header">{{ subtitle | appTranslate }}</div>
        </h1>
        <ng-content select=".header-content"></ng-content>
    </div>
    <div class="flex middle header-buttons-container">
        <div class="ui buttons-container">
            <ng-content select=".buttons"> </ng-content>
        </div>

        <!-- Semantic Context Menu -->
        <div
            *ngIf="contextMenu && contextMenuType === 'semantic'"
            class="context-menu ui icon dropdown basic button"
            role="button"
            aria-haspopup="true"
        >
            <mat-icon svgIcon="meatballHorizontal"></mat-icon>
            <div class="left menu">
                <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
            </div>
        </div>

        <!-- Material Context Menu -->
        <ng-container *ngIf="contextMenu && contextMenuType === 'material'">
            <ui-button class="context-menu" type="ghost" [matMenu]="contextMatMenu">
                <div class="sr-only">{{ title | appTranslate }} {{ 'screen-reader-only.moreActions' | translate }}</div>
                <mat-icon svgIcon="meatballHorizontal"></mat-icon>
            </ui-button>
            <mat-menu #contextMatMenu>
                <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
            </mat-menu>
        </ng-container>
    </div>
</div>

<!-- Tab Bar -->
<ng-container *ngIf="menuService">
    <div class="tab-bar-container">
        <app-tab-bar aria-labelledby="page-title" [menuService]="menuService"></app-tab-bar>
    </div>
</ng-container>

<!-- Deprecated tab bar -->
<ng-container *ngIf="!menuService && menu">
    <div class="tab-bar-container">
        <app-deprecated-tabbar [menu]="menu"></app-deprecated-tabbar>
    </div>
</ng-container>

<ng-template #dropdownMenu><ng-content></ng-content></ng-template>
