import { Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable, TranslatableKey } from '@app/types/translatable.type';

type ColumnSpan =
    | 'columns-1'
    | 'columns-2'
    | 'columns-3'
    | 'columns-4'
    | 'columns-5'
    | 'columns-6'
    | 'columns-7'
    | 'columns-8'
    | 'columns-9'
    | 'columns-10'
    | 'columns-11'
    | 'columns-12';

export interface Columns {
    columnName: TranslatableKey;
    columnSpan: ColumnSpan;
    /**
     * Optionally add a cell type.
     * Defaults to translatable string, or avatar if the row is an object with `isAvatar: true`
     */
    columnType?: CellDisplay;
}

export interface Row {
    cells: (Translatable | TranslatableKey | string | { employee: Employee; isAvatar: boolean })[];
}

@Component({
    selector: 'app-simple-table',
    templateUrl: './simple-table.component.html',
    styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent {
    @Input() columns: Columns[] = [];
    @Input() rows: Row[] = [];

    @Input() showMeatballs = false;
    @Input() sticky = false;
    @Input() fixedHeight = false;
    @Input() showEditItem = true;
    @Input() showDeleteItem = true;
    @Input() showInlineButton = false;
    @Input() inlineButtonText: TranslatableKey = 'edit';
    @Input() inlineButtonIcon?: string;
    @Input() inlineButtonSpan: ColumnSpan = 'columns-1';

    /**
     * Since there can be any number of cells with the employee model,
     * the parent component should implement this function.
     */
    @Input() trackByRow?: TrackByFunction<Row>;

    /**
     * The translatable text for the delete button
     */
    @Input() deleteText = 'applicant-tracker.components.simple-table.delete';

    @Output() editClicked: EventEmitter<number> = new EventEmitter<number>();
    @Output() inlineButtonClicked: EventEmitter<number> = new EventEmitter<number>();
    @Output() deleteClicked: EventEmitter<number> = new EventEmitter<number>();

    cellDisplay = CellDisplay;

    onEdit(index: number): void {
        this.editClicked.emit(index);
    }

    onDelete(index: number): void {
        this.deleteClicked.emit(index);
    }

    onInlineButtonClicked(index: number): void {
        this.inlineButtonClicked.emit(index);
    }

    trackByCell(index, item) {
        if (item?.employee) {
            return item.employee.id;
        }

        return item;
    }
}
