import { T4 } from '@app/models/payroll/t4.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';

export class EmployeeColumn extends BadgeColumn<T4> {
    title = 'table.t4Index.name';
    property = 'employee';
    sortField = 'lastName';
    sortable = true;
    type: CellDisplay = CellDisplay.avatar;
    displayWidth = 33;

    getBadgeDisplay(t4: T4): BadgeDisplay | undefined {
        if (!t4.terminated) {
            return;
        }

        return {
            type: 'danger',
            label: 'table.t4Index.terminated',
        };
    }

    shouldShowBadge(t4: T4): boolean {
        return t4.terminated;
    }
}
