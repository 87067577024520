import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { DentalBenefitCodeCount } from './types';

/**
 * Service for making all API requests related to box 45 dental benefit codes
 */
@Injectable()
export class DentalBenefitService {
    private readonly DENTAL_BENEFIT_CODE_COUNT_ENDPOINT = `/v2/payroll/companies/${this.authService.company.id}/box45OptionsWithEmployeeCount`;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient
    ) {}

    /**
     * Retrieves dental benefit code employee count from the back-end
     * @returns A map of each code to the number of assigned employees (ie. {1: 4, 2: 0, 3: 0, ...})
     */
    getAssignedDentalBenefitCodeCounts(): Observable<DentalBenefitCodeCount> {
        return this.makeHttpGETRequest<DentalBenefitCodeCount>(this.DENTAL_BENEFIT_CODE_COUNT_ENDPOINT);
    }

    /**
     * Makes a GET request to the back-end
     */
    private makeHttpGETRequest<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(`${environment.api}${url}`, {
            observe: 'body',
            headers: { 'Content-Type': 'application/json' },
        });
    }
}
