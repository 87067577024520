import { DestroyRef, Directive, HostListener, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, NgForm } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { USE_ENCRYPTION } from '@app/services/local-storage/encryption-injection-token';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { isEqual } from 'lodash-es';
import { filter } from 'rxjs/operators';

export interface FormConfig {
    key: string;
    form: NgForm;
    fieldsToExclude?: string[];
}

export const FORM_PERSISTENCE_PREFIX = 'formPersistence_';

/**
 * Example usage of this can be found in the cra-account.view.ts component.
 */

@Directive({
    selector: '[appFormPersistence]',
    // Leverage the local-storage service like this to enable encryption
    providers: [
        { provide: USE_ENCRYPTION, useValue: true },
        LocalStorageService, // This creates a new instance with the provided USE_ENCRYPTION value
    ],
})
export class FormPersistenceDirective {
    @Input() fieldsToExclude: string[] = [];

    private _key = '';
    private destroyRef = inject(DestroyRef);
    private router = inject(Router);
    private localStorageService = inject(LocalStorageService);
    private formSubmitted = false;

    @Input('appFormPersistence')
    set key(key: string) {
        this._key = key;
        if (key) {
            /**
             * This is not ideal and more so a work around.
             *
             * The reasoning on using setTimeout here is that the form will not have it's controls in time.
             * This results in the form not being initializing. By using setTimeout we force the form to be
             * initialized on the next angular change detection cycle.
             */
            this.loadForm({ key, form: this.form, fieldsToExclude: this.fieldsToExclude });
        }
    }

    get key(): string {
        return this._key;
    }

    constructor(private form: NgForm) {
        this.setupNavigationListener();
    }

    // Handle form submission
    @HostListener('ngSubmit')
    onFormSubmit(): void {
        this.formSubmitted = true;
        this.clearAllForms();
    }

    // Save forms before window unload
    @HostListener('window:beforeunload')
    saveFormsBeforeUnload(): void {
        this.saveAllForms();
    }

    // Save all forms
    private saveAllForms(): void {
        this.saveForm();
    }

    // Setup navigation listener to save forms on navigation
    private setupNavigationListener(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(() => {
                if (!this.formSubmitted) {
                    this.saveAllForms();
                }
            });
    }

    // Load form data from localStorage
    private async loadForm(config: FormConfig): Promise<void> {
        try {
            const { form, key } = config;

            if (!form) {
                return;
            }

            const savedForm = await this.localStorageService.retrieve<string>(`${FORM_PERSISTENCE_PREFIX}${key}`);
            if (savedForm) {
                const parsedForm = JSON.parse(savedForm);
                const formControls = form.controls;

                Object.keys(parsedForm).forEach((formKey) => {
                    if (formControls[formKey] && this.shouldUpdateControl(formControls[formKey], parsedForm[formKey])) {
                        formControls[formKey].setValue(parsedForm[formKey], { emitEvent: false });
                    }
                });

                this.triggerValidation(form);
            }
        } catch (error) {
            console.error('Error loading form:', error);
        }
    }

    // Filter form values to be saved
    private filterFormValues(): Record<string, unknown> {
        const formValues = this.form.value;
        const filteredValues: Record<string, unknown> = {};

        for (const key in formValues) {
            if (this.shouldPersistField(key)) {
                filteredValues[key] = formValues[key];
            }
        }

        return filteredValues;
    }

    // Check if control should be updated
    private shouldUpdateControl(control: AbstractControl, savedValue: unknown): boolean {
        return this.isControlEmpty(control) || !this.isValueEqual(control.value, savedValue);
    }

    // Check if field should be persisted
    private shouldPersistField(fieldName: string): boolean {
        return !this.fieldsToExclude || !this.fieldsToExclude.includes(fieldName);
    }

    // Check if control is empty
    private isControlEmpty(control: AbstractControl): boolean {
        return control.value === null || control.value === undefined || control.value === '';
    }

    // Check if two values are equal
    private isValueEqual(value1: unknown, value2: unknown): boolean {
        return isEqual(value1, value2);
    }

    // Save form data to localStorage
    private async saveForm(): Promise<void> {
        if (Object.keys(this.form.value).length === 0 || !this.key) {
            return;
        }

        const formValues = this.filterFormValues();
        if (Object.keys(formValues).length > 0) {
            await this.localStorageService.store(`${FORM_PERSISTENCE_PREFIX}${this.key}`, JSON.stringify(formValues));
        }
    }

    // Clear all forms
    private clearAllForms(): void {
        this.shouldClearForm();
    }

    // Check if form should be cleared
    private shouldClearForm(): void {
        if (this.form.valid) {
            this.localStorageService.remove(`${FORM_PERSISTENCE_PREFIX}${this.key}`);
        }
    }

    // Trigger form validation
    private triggerValidation(form: NgForm): void {
        Object.values(form.controls).forEach((control) => {
            control.updateValueAndValidity({ emitEvent: false });
            control.markAsDirty();
            control.markAsTouched();
        });
    }
}
