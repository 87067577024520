import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { getIconNames } from '@app/constants/icons';
import { TranslatableObject } from '@app/types/translatable.type';

const iconNames = getIconNames();

type SnackBarData = {
    icon?: (typeof iconNames)[number];
} & TranslatableObject;

/*
 * Do not use this component directly - only for use with MatSnackBar
 */
@Component({
    selector: 'app-snack-bar-message',
    templateUrl: './snack-bar-message.template.html',
    styles: ['.snack-bar-message { display: flex; align-items: center; color: inherit }'],
})
export class SnackBarMessageComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}
}
