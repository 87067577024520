import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@table/classes/column.class';

export class CodeColumn extends Column {
    title = 'payroll.dental-benefits.table.code';
    type = CellDisplay.string;
    property = 'code';
    sortable = true;
    displayWidth = 15;
}

export class DescriptionColumn extends Column {
    title = 'payroll.dental-benefits.table.description';
    type = CellDisplay.string;
    property = 'description';
    displayWidth = 60;
}

export class EmployeesAssignedColumn extends Column {
    title = 'payroll.dental-benefits.table.employeesAssigned';
    type = CellDisplay.string;
    property = 'employeesAssignedCount';
    sortable = true;
    displayWidth = 25;
}

export default [CodeColumn, DescriptionColumn, EmployeesAssignedColumn];
