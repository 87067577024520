import { Component, Input } from '@angular/core';
import { Employee } from '@models/employee/employee.model';

@Component({
    selector: 'app-benefits-card',
    templateUrl: './benefits-card.template.html',
    styleUrls: ['./benefits-card.style.scss'],
})
export class BenefitsCardComponent {
    @Input() benefits: any;
    @Input() employee: Employee;
}
