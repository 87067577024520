import { Model } from '@models/core/base.model';
import { Paystub } from '@app/models/payroll/paystub.model';

export class ReimbursementItem extends Model {
    protected static _resource = 'payroll/companies/:company/payrolls/reimbursementItems';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'name',
        'amount',
        'editable',
        'hours',
        'multiplier',
        'prorated',
        'prorationDetails',
        'rate',
        'paystubId',
    ];

    get paystubId(): number {
        return this._attributes['paystubId'];
    }
    set paystubId(val: number) {
        this._attributes['paystubId'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }
    get amount(): number {
        return Number(this._attributes['amount']);
    }
    get editable(): boolean {
        return this._attributes['editable'];
    }
    get hours(): number {
        return this._attributes['hours'];
    }
    get multiplier(): number {
        return this._attributes['multiplier'];
    }
    get prorated(): boolean {
        return this._attributes['prorated'];
    }
    get prorationDetails(): object {
        return this._attributes['prorationDetails'];
    }
    get rate(): number {
        return this._attributes['rate'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }
    set amount(val: number) {
        this._attributes['amount'] = val;
    }
    set editable(val: boolean) {
        this._attributes['editable'] = val;
    }
    set hours(val: number) {
        this._attributes['hours'] = val;
    }
    set multiplier(val: number) {
        this._attributes['multiplier'] = val;
    }
    set prorated(val: boolean) {
        this._attributes['prorated'] = val;
    }
    set prorationDetails(val: object) {
        this._attributes['prorationDetails'] = val;
    }
    set rate(val: number) {
        this._attributes['rate'] = val;
    }

    get paystub(): Paystub {
        return this.hasOne(Paystub, 'paystub');
    }
}
