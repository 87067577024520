import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, FileHelperService } from '@app/services';
import { Company } from '@app/models/company/company.model';
import { T4 } from '@app/models/payroll/t4.model';
import { T4Summary } from '@app/models/payroll/t4-summary.model';
import { PayrollResources } from '../payroll.resources';
import { TaxFormSetResponse, TaxFormSet } from '../interfaces/tax-forms.interface';

@Injectable()
export class T4Service {
    private _taxFormSets: TaxFormSet[];

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private fileHelper: FileHelperService
    ) {}

    async getT4Overview(): Promise<TaxFormSet[]> {
        if (this._taxFormSets) {
            return Promise.resolve(this._taxFormSets);
        }
        const url = `/v2/payroll/companies/${this.auth.company.id}/taxFormSets`;
        return this.http
            .get(url)
            .toPromise()
            .then((res: TaxFormSetResponse) => {
                return (this._taxFormSets = res.data);
            });
    }

    getPierPreview(id: number): Promise<any> {
        const url = PayrollResources.T4Preview.replace(':company', this.auth.company.id.toString()).replace(
            ':t4',
            id.toString()
        );
        return this.http.get(url).toPromise();
    }

    revertToOriginalState(company: Company, t4: T4): Promise<any> {
        const path = PayrollResources.T4Revert.replace(':company', company.id.toString()).replace(
            ':t4',
            t4.id.toString()
        );

        return this.http.put(path, {}).toPromise();
    }

    sendBulkEmails(t4SummaryId: string | number): Promise<any> {
        const url = `/v2/payroll/companies/${this.auth.company.id}/t4Summaries/${t4SummaryId}/bulkEmail`;

        return this.http.put(url, {}).toPromise();
    }

    sendT4Email(t4: T4): Promise<any> {
        const url = PayrollResources.T4Email.replace(':company', this.auth.company.id.toString()).replace(
            ':t4',
            t4.id.toString()
        );
        return this.http.get(url).toPromise();
    }

    issue(t4SummaryId: number, manualSubmit: boolean): Promise<any> {
        const url = `/v2/payroll/companies/${this.auth.company.id}/t4Summaries/${t4SummaryId}/issue`;

        return this.http.put(url, { data: { attributes: { manualSubmit } } }).toPromise();
    }

    async downloadTaxReturnPreview(t4Summary: T4Summary): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.auth.company.id}/t4Summaries/${t4Summary.id}/downloadPdfPreview`;

            this.fileHelper.savePayrollFile(url, `t4_summary_pdf_preview_${t4Summary.year}`, 'pdf', null, (e) =>
                e ? reject(e) : resolve()
            );
        });
    }

    async downloadTaxFormPreview(t4: T4): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `${this.auth.company.id}/t4s/${t4.id}/downloadPdfPreview`;

            this.fileHelper.savePayrollFile(url, `t4_pdf_preview_${t4.year}_${t4.id}`, 'pdf', null, (e) =>
                e ? reject(e) : resolve()
            );
        });
    }

    async downloadTaxPackage(t4Summary: T4Summary): Promise<void> {
        return new Promise((resolve, reject) => {
            const year = t4Summary.year;
            const url = `${this.auth.company.id}/t4Summaries/${t4Summary.id}/download`;

            this.fileHelper.savePayrollFile(url, `T619_${year.toString()}`, 'zip', { year }, (e) =>
                e ? reject(e) : resolve()
            );
        });
    }
}
