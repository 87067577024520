/**
 * @deprecated
 * kill me once additional incomes goes live
 */
export enum AdditionalPayItems {
    Bonus = 'bonus',
    Commission = 'commission',
    VacationPay = 'vacation pay',
    Other = 'other',
}
