import { DataFieldGroup } from '@app/models/settings/data-field-group.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TranslatableKey } from '@app/types/translatable.type';

export class LocationColumn extends Column {
    title = 'settings.custom-fields.field-group-index-table.location';
    property = 'component';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(dataFieldGroup: DataFieldGroup): string | TranslatableKey {
        return dataFieldGroup.translatableComponent;
    }
}
