import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimeOffBackupApprover } from '@app/models/time-off-v3/time-off-backup-approver.model';
import { TimeOffPolicy } from '@app/models/time-off-v3/time-off-policy.model';
import { AuthService, FileHelperService } from '@app/services';
import { JsonApiIndexResponse, JsonApiResponse, JSONApiStoreResponse } from '@interfaces/json-api-resource.interface';
import { TimeOffRequest } from '@models/time-off-v3/time-off-request.model';
import { WorkSchedule } from '@models/time-off-v3/works-schedule.model';
import { TimeOffCommentTypesEnum } from '@time-off-v3/enum/time-off-comment-types.enum';
import { TimeOffValidationResponse } from '@time-off-v3/interfaces/time-off-importer.interface';
import { WarningsErrors } from '@time-off-v3/interfaces/warnings-errors.interface';
import { TimeOffDateFormatDashes } from '@time-off-v3/meta/time-off-meta';
import { TimeOffResources } from '@time-off-v3/time-off-v3.resource';
import { Moment } from 'moment';
import { AssignJSONApiRequest, Attach, Detach, ReassignJSONApiRequest } from '../interfaces/time-off-assign.interface';
import { TimeOffBalance, TimeOffExpiringBalance, TimeOffPreviews } from '../interfaces/time-off-balance.interface';

interface YearRange {
    fromYear: number;
    toYear: number;
}

interface Filter {
    [param: string]: any;
}

interface PayrollHoursAttributes {}

type PayrollHoursResponse = JSONApiStoreResponse<PayrollHoursAttributes>;

@Injectable()
export class TimeOffService {
    constructor(
        private http: HttpClient,
        private fileHelper: FileHelperService,
        private auth: AuthService
    ) {}

    getBalance(employeeId: number, filters: Filter): Promise<TimeOffBalance> {
        const url = TimeOffResources.Balance.replace(':employeeId', employeeId.toString());
        return this.http
            .get(url, { params: { ...filters } })
            .toPromise()
            .then((res: JsonApiResponse<TimeOffBalance>) => {
                res.data.attributes.balance = +res.data.attributes.balance.toFixed(2);
                res.data.attributes.balanceHours = +res.data.attributes.balanceHours.toFixed(2);

                return res.data.attributes;
            });
    }

    getExpiringBalance(employeeId: number, filters: Filter): Promise<TimeOffExpiringBalance> {
        const url = TimeOffResources.ExpiringBalance.replace(':employeeId', employeeId.toString());
        return this.http
            .get(url, { params: { ...filters } })
            .toPromise()
            .then((res: JsonApiResponse<TimeOffExpiringBalance>) => {
                res.data.attributes.expiringBalance = +res.data.attributes.expiringBalance.toFixed(2);
                res.data.attributes.expiringHoursBalance = +res.data.attributes.expiringHoursBalance.toFixed(2);

                return res.data.attributes;
            });
    }

    getAccrualPreview(timeOffPolicy: TimeOffPolicy): Promise<TimeOffPreviews[]> {
        const url = TimeOffResources.AccrualPreview;
        return this.http
            .get(url, { params: timeOffPolicy.serializeForAccrualPreview() })
            .toPromise()
            .then((res: JsonApiIndexResponse<TimeOffPreviews>) => {
                return res.data;
            });
    }

    async prefillHolidays(
        workSchedule: WorkSchedule,
        provinceCode: string,
        countryCode = 'ca',
        year: number
    ): Promise<void> {
        const url = TimeOffResources.PrefillHolidays.replace(':workScheduleId', workSchedule.id.toString());

        await this.http.put(url, { filters: { provinceCode, year, countryCode } }).toPromise();
    }

    createCopy(id: number): Promise<TimeOffPolicy> {
        const url = TimeOffResources.Copy.replace(':policy_id', id.toString());
        return this.http
            .post(url, {})
            .toPromise()
            .then((res: JsonApiResponse<TimeOffPolicy>) => {
                return res.data.attributes;
            });
    }

    async copyCustomHolidays(workSchedule: WorkSchedule, years: YearRange): Promise<void> {
        const url = TimeOffResources.CopyCustomHolidays.replace(':workScheduleId', workSchedule.id.toString());

        await this.http.put(url, { filters: years }).toPromise();
    }

    async assignAndUnassignEmployeesToPolicy(timeOffPolicyId: number, attach?: Attach, detach?: Detach): Promise<void> {
        // Create payload for assignment request.
        const payload: AssignJSONApiRequest = {
            data: {
                attributes: {
                    attach,
                    detach,
                },
            },
        };

        await this.http
            .post(TimeOffResources.Assign.replace(':policy_id', timeOffPolicyId.toString()), payload)
            .toPromise();
    }

    async reAssignEmployeesToPolicy(
        sourceTimeOffPolicyId: number,
        targetTimeOffPolicyId: number,
        dates: { fromHireDate: boolean; effectiveAt: Moment }
    ): Promise<void> {
        const url = TimeOffResources.ReassignPolicy.replace(':policy_id', sourceTimeOffPolicyId.toString());
        // Create payload for assignment request.
        const payload: ReassignJSONApiRequest = {
            data: {
                attributes: {
                    targetTimeOffPolicyId: targetTimeOffPolicyId,
                    fromHireDate: dates.fromHireDate,
                    effectiveAt: dates.effectiveAt.format(TimeOffDateFormatDashes),
                },
            },
        };

        await this.http.post(url, payload).toPromise();
    }

    async resetTimeOffRequest(timeOffRequest: TimeOffRequest): Promise<void> {
        const url = TimeOffResources.ResetTimeOffRequest.replace(':requestId', timeOffRequest.id.toString());

        await this.http.put(url, {}).toPromise();
    }

    async forceApproveTimeOffRequest(timeOffRequest: TimeOffRequest): Promise<void> {
        const url = TimeOffResources.ForceApproveTimeOffRequest.replace(':requestId', timeOffRequest.id.toString());

        await this.http.put(url, {}).toPromise();
    }

    async forceDenyTimeOffRequest(timeOffRequest: TimeOffRequest, comment?: string): Promise<void> {
        const url = TimeOffResources.ForceDenyTimeOffRequest.replace(':requestId', timeOffRequest.id.toString());

        const payload = comment
            ? {
                  data: {
                      type: 'timeOffRequests',
                      attributes: {
                          timeOffCommentsPayload: { type: TimeOffCommentTypesEnum.DENIED, comment: comment },
                      },
                  },
              }
            : {};

        await this.http.put(url, payload).toPromise();
    }

    async verifyBusinessRules(timeOffRequest: TimeOffRequest): Promise<WarningsErrors> {
        return new Promise((resolve) => {
            this.http
                .post(TimeOffResources.VerifyBusinessRules, timeOffRequest.serializeForRulesVerification())
                .toPromise()
                .then(() => resolve({ errors: [], warnings: [] }))
                .catch(({ error }) => {
                    const errors = error?.errors?.map((error) => error.detail);
                    const warnings = error?.warnings?.map((error) => error.detail);
                    resolve({ errors, warnings });
                });
        });
    }

    async getDefaultImportTemplate(timeOffTypeId: number, defaultTemplate: string): Promise<any> {
        const url = TimeOffResources.DefaultImportTemplate.replace(
            ':time_off_type_id',
            timeOffTypeId.toString()
        ).replace(':default_template_name', defaultTemplate);

        this.fileHelper.saveFromURL(url, defaultTemplate, 'csv');
    }

    importFile(file: File, fileName: string, importerName: string): Promise<TimeOffValidationResponse> {
        const data = new FormData();
        const url = TimeOffResources.ValidateTimeOffImporterFile;

        data.append('data[type]', 'timeOffImportFiles');
        data.append('data[attributes][name]', fileName);
        data.append('data[attributes][file]', file);
        data.append('data[attributes][importer_name]', importerName);

        return this.http
            .post(url, data)
            .toPromise()
            .then((res: JsonApiResponse<TimeOffValidationResponse>) => {
                return res.data.attributes;
            });
    }

    import(id: number, timeOffTypeId: number, importerName: string): Promise<unknown> {
        const url = TimeOffResources.TimeOffImporter.replace(':time_off_type_id', timeOffTypeId.toString()).replace(
            ':importerName',
            importerName
        );
        const payload = { time_off_import_file_id: id };

        return this.http.post(url, payload).toPromise();
    }

    eraseAllAccruals(employeeId: number, timeOffTypeId: number): Promise<unknown> {
        const url = TimeOffResources.EraseAccruals.replace(':timeOffTypeId', timeOffTypeId.toString()).replace(
            ':employeeId',
            employeeId.toString()
        );

        return this.http.post(url, null).toPromise();
    }

    async getTimeOffBackupApprover(): Promise<TimeOffBackupApprover> {
        const url = TimeOffResources.TimeOffBackupApprover.replace(':companyId', this.auth.company.id.toString());

        return this.http
            .get<JsonApiResponse<TimeOffBackupApprover>>(url)
            .toPromise()
            .then((res) => {
                return res.data.attributes;
            });
    }

    deletePayrollHours(timeOffTypeId: number, currentPayrollStartDate: Date): Promise<PayrollHoursResponse> {
        const url = TimeOffResources.DeletePayrollHours.replace(':timeOffTypeId', timeOffTypeId.toString());

        const payload = { current_payroll_start_date: currentPayrollStartDate };

        return this.http.post<PayrollHoursResponse>(url, payload).toPromise();
    }

    sendPayrollHours(timeOffTypeId: number, currentPayrollStartDate: Date): Promise<PayrollHoursResponse> {
        const url = TimeOffResources.SendPayrollHours.replace(':timeOffTypeId', timeOffTypeId.toString());

        const payload = { current_payroll_start_date: currentPayrollStartDate };

        return this.http.post<PayrollHoursResponse>(url, payload).toPromise();
    }

    async getPendingHoursInPayroll(filters: Filter): Promise<any> {
        return this.http
            .get<JsonApiResponse<any>>(TimeOffResources.PendingPayrollHours, { params: { ...filters } })
            .toPromise()
            .then((res) => {
                return res.data;
            });
    }
}
