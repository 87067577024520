/**
 * These are the possible non-null values for legal sex
 * that we show to the user
 * legal sex can also be null
 */
export enum LegalSexDisplay {
    Male = 'M',
    Female = 'F',
    Other = 'X',
    PreferNotToSay = 'P',
    Unknown = '',
}
