import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root',
})
/**
 * This is a general service for encrypting and decrypting data. It uses the AES-GCM algorithm for encrypting.
 * The password is
 */
export class CryptoService {
    private readonly algorithm = 'AES-GCM';
    private readonly keyUsages: KeyUsage[] = ['encrypt', 'decrypt'];
    private salt = new Uint8Array();
    private key: CryptoKey | null = null;
    private $destroyRef = inject(DestroyRef);

    constructor(private auth: AuthService) {
        this.auth.onHydrate.pipe(takeUntilDestroyed(this.$destroyRef)).subscribe(async () => {
            await this.initializeService();
        });
    }
    /**
     *
     * @param data
     * @returns A array of bytes which is encrypted into string
     */
    async encrypt(data: string): Promise<string> {
        const encoder = new TextEncoder();
        const encodedData = encoder.encode(data);

        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        const encryptedData = await window.crypto.subtle.encrypt(
            {
                name: this.algorithm,
                iv: iv,
            },
            this.key!,
            encodedData
        );

        const encryptedArray = new Uint8Array(encryptedData);
        const combinedArray = new Uint8Array(iv.length + encryptedArray.length);
        combinedArray.set(iv, 0);
        combinedArray.set(encryptedArray, iv.length);

        return btoa(String.fromCharCode.apply(null, Array.from(combinedArray)));
    }

    async decrypt(encryptedData: string): Promise<string> {
        const combinedArray = new Uint8Array(
            atob(encryptedData)
                .split('')
                .map((char) => char.charCodeAt(0))
        );
        const iv = combinedArray.slice(0, 12);
        const encryptedValue = combinedArray.slice(12);

        try {
            const decryptedData = await window.crypto.subtle.decrypt(
                {
                    name: this.algorithm,
                    iv: iv,
                },
                this.key!,
                encryptedValue
            );

            const decoder = new TextDecoder();
            return decoder.decode(decryptedData);
        } catch (error) {
            throw new Error('Decryption failed');
        }
    }
    // Creates the crypto key to encrypt and decrypt
    private async initializeKey(): Promise<void> {
        const keyMaterial = await this.getKeyMaterial();

        // These settings are best practice
        this.key = await window.crypto.subtle.deriveKey(
            {
                name: 'PBKDF2',
                salt: this.salt,
                iterations: 100000,
                hash: 'SHA-256',
            },
            keyMaterial,
            { name: this.algorithm, length: 256 },
            false,
            this.keyUsages
        );
    }

    private async getKeyMaterial(): Promise<CryptoKey> {
        const password = `Humi${this.auth.employee.id}`; // This key can be changed but is critical to the whole algo working. We use the id of the currently logged in employee.
        const encoder = new TextEncoder();
        const keyMaterial = await window.crypto.subtle.importKey('raw', encoder.encode(password), 'PBKDF2', false, [
            'deriveBits',
            'deriveKey',
        ]);

        return keyMaterial;
    }

    private async initializeService(): Promise<void> {
        if (!this.key) {
            this.salt = new TextEncoder().encode(`GeneralHumiCryptoServiceSalt${this.auth.employee.id}`);
            await this.initializeKey();
        }
    }
}
