import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';
import { OfferLetter } from './offer-letter.model';

export class Candidate extends Model {
    protected static _resource = 'candidates';
    protected static _version = 'v2';

    protected static _serializeAttributes = ['note', 'hiredAt', 'employeeId'];

    protected static _dates = ['firstDayOfWorkOn', 'expiresOn'];

    protected static _datetimes = ['createdAt', 'firstSeenAt', 'withdrawnAt', 'declinedAt', 'signedAt', 'hiredAt'];

    get note(): string {
        return this._attributes['note'];
    }

    set note(val: string) {
        this._attributes['note'] = val;
    }

    get hiredAt(): Date | null {
        return this._attributes['hiredAt'];
    }

    set hiredAt(val: Date | null) {
        this._attributes['hiredAt'] = val;
    }

    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get legalFirstName(): string {
        return this._attributes['legalFirstName'];
    }

    get legalLastName(): string {
        return this._attributes['legalLastName'];
    }

    get legalFullName(): string {
        return this.legalFirstName + ' ' + this.legalLastName;
    }

    get email(): string {
        return this._attributes['email'];
    }

    get reportsTo(): string | null {
        return this._attributes['reportsTo'];
    }

    get expiresOn(): Date | null {
        return this._attributes['expiresOn'];
    }

    get position(): string | null {
        return this._attributes['position'];
    }

    get firstDayOfWorkOn(): Date | null {
        return this._attributes['firstDayOfWorkOn'];
    }

    get firstSeenAt(): Date | null {
        return this._attributes['firstSeenAt'];
    }

    get withdrawnAt(): Date | null {
        return this._attributes['withdrawnAt'];
    }

    get declinedAt(): Date | null {
        return this._attributes['declinedAt'];
    }

    get signedAt(): Date | null {
        return this._attributes['signedAt'];
    }

    get latestOfferLetter(): OfferLetter {
        return this.hasOne(OfferLetter, 'latestOfferLetter');
    }

    get employee(): Employee | null {
        return this.hasOne(Employee, 'employee');
    }

    hasSeen(): boolean {
        return !!this.firstSeenAt;
    }

    isHired(): boolean {
        return !!this.hiredAt;
    }

    isHireable(): boolean {
        return !(
            this.latestOfferLetter.isWithdrawn() ||
            this.latestOfferLetter.isDeclined() ||
            (this.latestOfferLetter.isExpired() && !this.latestOfferLetter.isSigned()) ||
            this.isHired()
        );
    }
}
