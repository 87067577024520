import { Paginator, SortDirection } from '@app/classes';
import { EmployeeHour } from '@app/models/payroll/company/employee-hour.model';
import { Payroll } from '@app/models/payroll/payroll.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { NumericString } from '@app/types/numeric-string.type';
import { ApproverNameColumn } from './columns/approver-name.column';
import { DateUsedColumn } from './columns/date-used.column';
import { EmployeeNameColumn } from './columns/employee-name.column';
import { HoursTypeColumn } from './columns/hours-type.column';
import { HoursColumn } from './columns/hours.column';
import { SourceColumn } from './columns/source.column';
import { StatusColumn } from './columns/status.column';

type EmployeeHoursMetadata = {
    totalEmployees: NumericString;
    totalApprovedHours: NumericString;
    totalPendingHours: NumericString;
    totalNewHours: NumericString;
};

export class EmployeeHourTable extends Table<EmployeeHour, EmployeeHoursMetadata> {
    sortDirection = SortDirection.DESC;
    links = false;
    bulkSelect = false;
    protected sortProperty = 'name';

    constructor(
        protected auth: AuthService,
        bulkSelect: boolean,
        private payroll: Payroll,
        private isLate: boolean = false
    ) {
        super(auth);
        this.boot();

        this.bulkSelect = bulkSelect;
    }

    setColumns(): void {
        const statusColumn = new StatusColumn(this.payroll.startDate, !this.isLate);
        this.sortColumn = statusColumn;
        this.columns = [
            new EmployeeNameColumn(),
            new DateUsedColumn(),
            new HoursTypeColumn(),
            new HoursColumn(),
            new SourceColumn(),
            statusColumn,
            new ApproverNameColumn(),
        ];
    }

    setDataSource(): void {
        const query = EmployeeHour.param('company', this.auth.company.id).param('payroll', this.payroll.id);

        if (this.isLate) {
            query.where('late', this.isLate);
        }

        this.paginator = new Paginator(query);
    }
}
