import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AudioPlaybackComponent } from '@app/components/app-audio-playback/app-audio-playback.component';
import { AnalyticEvents, FeatureFlag } from '@app/enums';
import { Company } from '@app/models/company/company.model';
import { AnalyticService, AuthService, FileHelperService, NotifyService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { EmployeeStatusDisplayOptions } from '@app/types/translatables/employee-status-display.options';
import { Employee } from '@models/employee/employee.model';
import { AvatarUploadEvent } from '../avatar-upload/avatar-upload.component';
import { Avatarable } from '../avatar/avatar.component';

// Currently can be used for display of Employee or Company model types
type CardType = Employee | Company;

@Component({
    selector: 'app-profile-card',
    templateUrl: './profile-card.template.html',
    styleUrls: ['./profile-card.style.scss'],
})
export class ProfileCardComponent implements OnInit {
    @ViewChild('audioPlayer') audioPlayer?: AudioPlaybackComponent;
    @Input() model?: CardType;
    shouldShowEmployeeNamePronunciation = false;
    employeeStatusDisplay = EmployeeStatusDisplayOptions;
    isAvatarDialogOpen = false;

    constructor(
        public auth: AuthService,
        public fileHelperService: FileHelperService,
        protected notifyService: NotifyService,
        private featureService: FeatureService,
        private analyticService: AnalyticService
    ) {}

    async ngOnInit(): Promise<void> {
        this.shouldShowEmployeeNamePronunciation = await this.featureService.has(FeatureFlag.employeeNamePronunciation);
    }

    isEmployee(model?: CardType): model is Employee {
        return model instanceof Employee;
    }

    isCompany(model?: CardType): model is Company {
        return model instanceof Company;
    }

    get avatar(): Avatarable | undefined {
        if (this.isEmployee(this.model)) {
            return this.model;
        }

        if (this.isCompany(this.model)) {
            return {
                fullName: this.model.name,
                firstName: this.model.name,
                lastName: ' ',
                // This appears to be the only place where `logoId` is still in use.
                // If we remove this usage, we can remove logoId
                avatarId: this.model.brandLogoFileId ?? this.model.logoId ?? undefined,
            };
        }

        return undefined;
    }

    addPendoEventForPlayAudioSidebar(): void {
        this.analyticService.trackEvent(AnalyticEvents.EmployeeNamePronunciationSidebarPlayed);
    }

    onClickEdit(event: Event): void {
        this.isAvatarDialogOpen = true;
        // Keeps the expansion panel from expanding
        event.stopPropagation();
    }

    handleAvatarUploadTerminated({ isSuccessful }: AvatarUploadEvent): void {
        this.isAvatarDialogOpen = false;
        if (isSuccessful) {
            this.notifyService.success('account.avatarUpdated');
            return;
        }

        this.notifyService.error('account.avatarUpdatedError');
    }
}
