import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { TimeOffComment } from '@models/time-off-v3/time-off-comment.model';
import { TimeOffCommentTypesEnum } from '@time-off-v3/enum/time-off-comment-types.enum';

interface TimeOffCommentPayload {
    type: TimeOffCommentTypesEnum;
    comment: string;
}

export class TimeOffRequestApproval extends Model {
    protected static _resource = 'timeOffV3/approvals';

    protected static _version = 'v2';

    protected static _type = 'timeOffRequestApprovals';

    protected static _serializeAttributes = ['hasApproved', 'timeOffCommentsPayload'];

    private _type: TimeOffCommentTypesEnum;
    private _comment: string;

    get type(): TimeOffCommentTypesEnum {
        return this._type;
    }

    set type(val: TimeOffCommentTypesEnum) {
        this._type = val;
    }

    get comment(): string {
        return this._comment;
    }

    set comment(val: string) {
        this._comment = val;
    }

    get timeOffRequestApprovalFlowStep(): number {
        return this._attributes['timeOffRequestApprovalFlowStep'];
    }

    get hasApproved(): boolean {
        return this._attributes['hasApproved'];
    }

    set hasApproved(val: boolean) {
        this._attributes['hasApproved'] = val;
    }

    get timeOffCommentsPayload(): TimeOffCommentPayload {
        return this._attributes['timeOffCommentsPayload'];
    }

    set timeOffCommentsPayload(val: TimeOffCommentPayload) {
        this._attributes['timeOffCommentsPayload'] = val;
    }

    get isBackupApprover(): boolean {
        return this._attributes['isBackupApprover'];
    }

    get approver(): Employee {
        return this.hasOne(Employee, 'approver');
    }

    set approver(value: Employee) {
        this.setOne('approver', value);
    }

    get timeOffComment(): TimeOffComment {
        return this.hasOne(TimeOffComment, 'timeOffComment');
    }

    async approve(): Promise<void> {
        this.hasApproved = true;
        await this.save();
    }

    async deny(): Promise<void> {
        this.hasApproved = false;

        if (this.comment) {
            this.timeOffCommentsPayload = { type: this.type, comment: this.comment };
        }
        await this.save();
    }
}
