import { Component, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'lodash-es';

@Component({
    selector: 'ui-star-rating',
    templateUrl: './star-rating.template.html',
    styleUrls: ['./star-rating.styles.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: StarRatingComponent,
            multi: true,
        },
    ],
})
export class StarRatingComponent implements ControlValueAccessor {
    @Input() maxStars = 5;
    @Input() currentValue = 0;
    @Input('aria-label') ariaLabel: string = '';

    onChange = noop;
    onTouched = noop;

    constructor(protected element: ElementRef) {}

    get stars(): number[] {
        return Array.from(Array(this.maxStars).keys());
    }

    onClick(value: number): void {
        this.currentValue = value;
        this.onChange(this.currentValue);
    }

    writeValue(newValue: number): void {
        this.currentValue = newValue;
    }

    registerOnChange(fn: (_: number) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: (_: Event) => void): void {
        this.onTouched = fn;
    }
}
