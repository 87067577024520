import { Address } from '@app/models/employee/address.model';
import { Office } from '@app/models/company/office.model';

interface GeocoderAddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export function populateFromGooglePlace<T extends Address | Office>(address: T, googlePlace: any): T {
    const components: GeocoderAddressComponent[] = googlePlace['address_components'];

    const streetNumberComponent = components.find((component) => component.types.includes('street_number'));
    const streetComponent = components.find((component) => component.types.includes('route'));
    const cityComponent = components.find((component) => component.types.includes('locality'));
    const subLocalityComponent = components.find((component) => component.types.includes('sublocality'));
    const administrateAreaComponent = components.find(
        (component) =>
            component.types.includes('administrative_area_level_1') ||
            component.types.includes('administrative_area_level_2') ||
            component.types.includes('administrative_area_level_3')
    );
    const provinceComponent = components.find((component) => component.types.includes('administrative_area_level_1'));
    const countryComponent = components.find((component) => component.types.includes('country'));
    const postalCodeComponent = components.find((component) => component.types.includes('postal_code'));

    if (streetComponent) {
        address.addressLine1 = streetNumberComponent
            ? `${streetNumberComponent['short_name']} ${streetComponent['long_name']}`
            : streetComponent['long_name'];
    }

    if (cityComponent) {
        address.city = cityComponent['long_name'];
    } else if (subLocalityComponent) {
        address.city = subLocalityComponent['long_name'];
    } else if (administrateAreaComponent) {
        address.city = administrateAreaComponent['long_name'];
    }

    if (provinceComponent) {
        address.province = provinceComponent['short_name'];
    }

    if (countryComponent) {
        address.country = countryComponent['long_name'];
    }

    if (postalCodeComponent) {
        address.postalCode = postalCodeComponent['short_name'];
    }

    return address;
}
