export enum TaskTypes {
    onboarding = 'onboarding',
    offboarding = 'offboarding',
    general = 'general',
}

export enum TaskStatusTypes {
    complete = 'complete',
    incomplete = 'incomplete',
}
