import { LegalSex } from '@app/types/legal-sex.type';
import { LegalSexEnum } from '@app/enums/legal-sex.enum';
import { LegalSexDisplay } from '@app/enums/legal-sex-display.enum';

const map = {
    [LegalSexEnum.Male]: LegalSexDisplay.Male,
    [LegalSexEnum.Female]: LegalSexDisplay.Female,
    [LegalSexEnum.Other]: LegalSexDisplay.Other,
    [LegalSexEnum.PreferNotToSay]: LegalSexDisplay.PreferNotToSay,
};

/**
 * Converts a LegalSex value to a value that can be shown in the UI
 *
 * M => M/H
 * F => F/F
 * X => X/X
 * P => P/P (Prefer not to say/Préfère pas répondre)
 * null => ""
 */
export function displayLegalSex(legalSex: LegalSex): LegalSexDisplay {
    if (legalSex === null) {
        return LegalSexDisplay.Unknown;
    }

    return map[legalSex];
}
