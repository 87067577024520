import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { TransifexToggleService } from '@app/services/language/transifex-toggle.service';
import { Translatable, isTranslatableObject } from '@app/types/translatable.type';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

/*
Unsure how long Transifex will be used.
We are declaring it because it is loaded through a pixel on index.html,
but using a wrapper would be definitely better
*/
declare let Transifex: any;

@Injectable({ providedIn: 'root' })
export class LanguageService implements OnDestroy {
    public onNgxTranslationLoad: Subject<null> = new Subject();

    private _onDestroy$: Subject<void> = new Subject();

    constructor(
        private auth: AuthService,
        private translateService: TranslateService,
        private transifexToggleService: TransifexToggleService
    ) {
        combineLatest([this.auth.onHydrate, this.onNgxTranslationLoad])
            .pipe(take(1))
            .subscribe(() => {
                try {
                    this.applyUserLanguage();
                } catch (e) {
                    console.warn(e);
                }
            });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    /** @deprecated use `translateService.instant` or `translateService.get` instead. */
    public translate(query: Translatable): string {
        let key: string;
        let params: object | undefined;
        if (isTranslatableObject(query)) {
            key = query.key;
            params = query?.params;
        } else {
            key = query;
            params = undefined;
        }
        return this.translateService.instant(key, params);
    }

    public applyUserLanguage(): void {
        if (this.transifexToggleService.isTransifexEnabled()) {
            Transifex.live.onTranslatePage(function (): void {
                Transifex.live.translateNodes($('.ui.static.form label'));
            });
            Transifex.live.onDynamicContent(function (): void {
                Transifex.live.translateNodes($('.ui.static.form label'));
            });
            Transifex.live.onFetchLanguages((): void => {
                Transifex.live.translateTo(this.auth.employee.language, true);
            });
            Transifex.live.onError(function (err) {
                console.error('Transifex error', err);
            });
        }
    }

    public translateTransifex(lang: string): void {
        if (this.transifexToggleService.isTransifexEnabled()) {
            try {
                Transifex.live.translateTo(lang, true);
            } catch (e) {
                console.error('Transifex error while changing language: ', e);
            }
        }
    }
}
