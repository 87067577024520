import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';

import { SimpleEntityActions } from '@app/classes';
import { TIME_FORMAT, DATE_FORMAT } from '@app/constants';
import moment, { Moment } from 'moment';

export class Reminder extends Model {
    protected static _resource = 'companyManagement/companies/:company/employees/:employee/reminders';

    protected static _type = 'reminders';

    protected static _version = 'v2';
    protected static _serializeAttributes = [
        'employeeId',
        'creatorId',
        'subject',
        'body',
        'remindAt',
        'repeatOn',
        'lastSentAt',
    ];
    protected static _datetimes = ['remindAt', 'lastSentAt'];

    static permission = new SimpleEntityActions('employeeReminder');

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    get creatorId(): number {
        return this._attributes['creatorId'];
    }

    set creatorId(val: number) {
        this._attributes['creatorId'] = val;
    }

    get subject(): string {
        return this._attributes['subject'];
    }

    set subject(val: string) {
        this._attributes['subject'] = val;
    }

    get body(): string {
        return this._attributes['body'];
    }

    set body(val: string) {
        this._attributes['body'] = val;
    }

    get remindAt(): Date | Moment {
        return this._attributes['remindAt'];
    }

    set remindAt(val: Date | Moment) {
        this._attributes['remindAt'] = val;
    }

    set remindAtDay(date: Moment) {
        if (!date || !date.format) {
            return;
        }

        const day = date.format(DATE_FORMAT);
        const time = this.remindAtTime ?? '00:00:00';
        this.remindAt = moment(`${day} ${time}`);
    }

    get remindAtTime(): string | null {
        if (!this.remindAt) {
            return null;
        }

        return moment(this.remindAt).format(TIME_FORMAT);
    }

    set remindAtTime(time: string) {
        if (!time) {
            return;
        }

        const remindAtDay = moment(this.remindAt).format(DATE_FORMAT);
        const foo = moment(`${remindAtDay} ${time}`);

        this.remindAt = foo;
    }

    get repeatOn(): string {
        return this._attributes['repeatOn'] || null;
    }

    set repeatOn(val: string) {
        this._attributes['repeatOn'] = val;
    }

    get lastSentAt(): Date {
        return this._attributes['lastSentAt'];
    }

    set lastSentAt(val: Date) {
        this._attributes['lastSentAt'] = val;
    }

    /**
     * Employee
     * @return {Employee} [description]
     */
    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }
}
