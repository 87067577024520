import { WorkScheduleMenuService } from '@time-off-v3/services/menus/work-schedules-menu.service';
import { PolicyManageService } from '@time-off-v3/services/policy-v2/policy-manage.service';
import { PolicyDetailsTabsMenuService } from './menus/policy-details-menu.service';
import { TimeOffCreateFlowService } from './time-off-create-flow.service';
import { TimeOffModuleMenuService, TimeOffProfileMenuService } from './time-off-module-menu.service';
import { TimeOffRequestTable } from './time-off-request-table/time-off-request.table';
import { TimeOffSidebarRedirectService } from './time-off-sidebar-redirect/time-off-sidebar-redirect.service';
import { TimeOffTypesTable } from './time-off-types-table/time-off-types-table';
import { TimeOffService } from './time-off.service';

const Services = [
    TimeOffService,
    TimeOffModuleMenuService,
    TimeOffProfileMenuService,
    PolicyManageService,
    PolicyDetailsTabsMenuService,
    WorkScheduleMenuService,
];

export {
    PolicyDetailsTabsMenuService,
    Services,
    TimeOffCreateFlowService,
    TimeOffRequestTable,
    TimeOffService,
    TimeOffSidebarRedirectService,
    TimeOffTypesTable,
};
