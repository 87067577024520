import { DateRangeFilter } from '@app/modules/table/classes/column-filters/date-range/date-range-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TimeOffRequest } from '@models/time-off-v3/time-off-request.model';

export class RequestDateColumn extends Column {
    title = 'time-off-v3.requestedDates';
    sortField = 'startAt';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    constructor() {
        super();
        this.filter = new DateRangeFilter('startAt', 'endAt', this.title);
    }

    getDisplayPropertyValue(timeOffRequest: TimeOffRequest): string | null {
        return timeOffRequest.dateRangeString;
    }
}
