import { EmployeeDocumentSource } from '@app/types/employee-document-source';
import { TranslatableKey } from '@app/types/translatable.type';
import { TrainingProgramRecordFile } from '@models/documents/training-program-record-file.model';
import { Role } from '../account/role.model';
import { File } from '../common/file.model';
import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';
import { DocumentAcknowledgement } from './document-acknowledgement.model';
import { SignatureRequest } from './signature-request.model';

type SourceTypes = SignatureRequest | DocumentAcknowledgement | File | TrainingProgramRecordFile;

export class EmployeeDocument extends Model {
    protected static _resource = 'employees/:employee/employeeDocuments';
    protected static _version = 'v2';
    protected static _datetimes = ['createdAt', 'updatedAt'];
    protected static _serializeAttributes = ['fileId', 'name', 'attach', 'detach'];

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    /**
     * CAUTION: The status string could include a date that was formatted from a UTC timestamp, this can can
     * discrepancies between signed/acknowledged dates in the UI.
     */
    get status(): string {
        return this._attributes['status'];
    }

    get sourceType(): EmployeeDocumentSource {
        return this._attributes['sourceType'];
    }

    get sourceId(): number {
        return this._attributes['sourceId'];
    }

    set sourceId(val: number) {
        this._attributes['fileId'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get source(): SourceTypes {
        switch (this.sourceType) {
            case 'File':
                return this.hasOne(File, 'source');
            case 'SignatureRequest':
                return this.hasOne(SignatureRequest, 'source');
            case 'DocumentAcknowledgement':
                return this.hasOne(DocumentAcknowledgement, 'source');
            case 'TrainingProgramRecordFile':
                return this.hasOne(TrainingProgramRecordFile, 'source');
        }
    }

    set source(val: SourceTypes) {
        this.setOne('source', val);
    }

    get roles(): Role[] {
        return this.hasMany(Role, 'roles');
    }

    isOwnedBy(employee: number | Employee): boolean {
        return this.employeeId === (employee instanceof Employee ? employee.id : employee);
    }

    isDirectUpload(): boolean {
        return this.sourceType === 'File';
    }

    isTaskUpload(): boolean {
        return this.sourceType === 'Task';
    }

    isSignatureRequest(): boolean {
        return this.sourceType === 'SignatureRequest';
    }

    isAcknowledgement(): boolean {
        return this.sourceType === 'DocumentAcknowledgement';
    }

    isComplete(): boolean {
        if (this.isSignatureRequest()) {
            return !!(this.source as SignatureRequest).completedAt;
        }

        if (this.isAcknowledgement()) {
            return !!(this.source as DocumentAcknowledgement).acknowledgedAt;
        }

        return this.isDirectUpload();
    }

    isProcessing(): boolean {
        return this.isSignatureRequest() && (this.source as SignatureRequest).isProcessing();
    }

    completedAt(): Date | null {
        if (this.isSignatureRequest()) {
            return (this.source as SignatureRequest).completedAt;
        }

        if (this.isAcknowledgement()) {
            return (this.source as DocumentAcknowledgement).acknowledgedAt;
        }

        return null;
    }

    canBeDownloaded(): boolean {
        return this.isSignatureRequest() ? !this.isProcessing() && this.isComplete() : true;
    }

    getVerb(): TranslatableKey {
        if (this.isSignatureRequest()) {
            return 'document.signed';
        }

        if (this.isAcknowledgement()) {
            return 'document.acknowledged';
        }
    }

    isActionable(): boolean {
        return this.isAcknowledgement() || this.isSignatureRequest();
    }

    async notify(): Promise<any> {
        const remindPath = EmployeeDocument.buildUrl([':employeeDocument', 'remind'], {
            employee: this.employeeId,
            employeeDocument: this.id,
        });
        return EmployeeDocument.performGet(remindPath);
    }

    get file(): File {
        return this.hasOne(File, 'file');
    }

    /**
     * Only used for creation of a direct upload employee doc. Otherwise look to SourceId
     */
    set fileId(val: number) {
        this._attributes['fileId'] = val;
    }
}
