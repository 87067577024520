import { Component, Input } from '@angular/core';
import { Icons } from '@app/enums';
import { Translatable } from '@app/types/translatable.type';

@Component({
    selector: 'app-context-item',
    templateUrl: './context-item.template.html',
    styleUrls: ['./context-item.style.scss'],
})
export class ContextItemComponent {
    @Input() label: Translatable = 'components.context-item.actionLabel';
    @Input() icon: Icons = null;
    @Input() disabled = false;
}
