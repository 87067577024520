import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';

export abstract class Assignment extends Model {
    get assignedTo(): number {
        return this._attributes['assignedTo'];
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get updatedAt(): Date {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): Date {
        return this._attributes['deletedAt'] || null;
    }

    get jsonData(): any {
        return this._attributes['jsonData'];
    }

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];
}
