import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { TimeOffDay } from '@models/time-off-v3/time-off-day.model';
import { TimeOffRequestBlackoutPeriod } from '@models/time-off-v3/time-off-request-blackout-period.model';

export class WorkSchedule extends Model {
    protected static _resource = 'timeOffV3/workSchedules';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['isDefault', 'name', 'description', 'weekendDays', 'attach', 'detach'];

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get isDefault(): boolean {
        return this._attributes['isDefault'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get weekendDays(): string[] {
        return this._attributes['weekendDays']
            ? this._attributes['weekendDays'].split(',').map((day) => `${day.charAt(0).toUpperCase()}${day.slice(1)}`)
            : [];
    }

    get employeesCount(): number {
        return this._attributes['employeesCount'];
    }

    get employees(): Employee[] {
        return this.hasMany(Employee, 'employees');
    }

    get timeOffDays(): TimeOffDay[] {
        return this.hasMany(TimeOffDay, 'timeOffDays');
    }

    get timeOffRequestBlackoutPeriods(): TimeOffRequestBlackoutPeriod[] {
        return this.hasMany(TimeOffRequestBlackoutPeriod, 'timeOffRequestBlackoutPeriods');
    }

    set isDefault(val: boolean) {
        this._attributes['isDefault'] = val;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    set weekendDays(val: string[]) {
        this._attributes['weekendDays'] = val.join(',').toLowerCase();
    }
}
