import { Component, ElementRef, Input } from '@angular/core';
import { Applicant } from '@app/models/recruiting/applicant.model';
import { Interview } from '@app/models/recruiting/interview.model';
import { JobFunnel } from '@app/models/recruiting/job-funnel.model';
import { JobPosting } from '@app/models/recruiting/job-posting.model';
import { LanguageService, NotifyService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { Translatable } from '@app/types/translatable.type';
import { JobFunnelNameOptions } from '@app/types/translatables/applicant-tracker/job-funnel-name.options';
import { BulkSelectBaseDialogComponent } from '../bulk-select-base-dialog/bulk-select-base-dialog.component';

const RATINGS = [1, 2, 3, 4, 5];

interface Filters {
    jobFunnelStepName: string;
    isArchived: string;
    rating: string;
}

interface FilterValues {
    jobFunnelStepName: { [key: string]: string | number | Translatable };
    isArchived: { [key: string]: string | number };
    rating: { [key: string]: string | number };
}

@Component({
    selector: 'app-common-bulk-select-interviewers-dialog',
    templateUrl: '../bulk-select-base-dialog/bulk-select-base-dialog.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectInterviewersDialogComponent extends BulkSelectBaseDialogComponent {
    @Input() label = 'applicant-tracker.select';
    @Input() headerText = 'applicant-tracker.selectApplicants';
    @Input() jobPosting: JobPosting;

    filters: Filters = {
        jobFunnelStepName: 'applicant-tracker.jobFunnel',
        isArchived: 'applicant-tracker.status',
        rating: 'applicant-tracker.isArchived',
    };

    filterValues: FilterValues = {
        jobFunnelStepName: {},
        isArchived: {
            0: this.languageService.translate('applicant-tracker.active'),
            1: this.languageService.translate('applicant-tracker.archived'),
        },
        rating: {},
    };
    activeFilters: any = {};

    items: Applicant[] = [];
    selectItems: Applicant[] = [];
    interview: Interview;

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        private notify: NotifyService,
        private readonly languageService: LanguageService
    ) {
        super(element, auth);
    }

    selectAll(): void {
        this.isLoading = true;

        let request = Applicant.with('jobPosting.jobFunnels').where('atsJobId', this.jobPosting.id).limit(0);

        request = this.applyQuery(request);

        request = this.applyFilters(request);

        request
            .all()
            .then(([employees]) => {
                this.selectItems = employees;
                this.updateModelSelection();
            })
            .catch((err) => {
                this.notify.error(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    /**
     * Reset the form
     */
    protected loadFilterOptions(): void {
        this.loadJobFunnels();
        this.loadRatings();
    }

    protected async loadItems(): Promise<void> {
        let request = Applicant.with('jobPosting.jobFunnels')
            .where('atsJobId', this.jobPosting.id)
            .page(this.currentPage);

        request = this.applyQuery(request);
        request = this.applyFilters(request);

        request
            .get()
            .then(([applicants, meta]) => {
                this.items = applicants;
                this.totalResults = meta.pagination.total;
                this.currentPage = meta.pagination.page;
                this.isLoading = false;
            })
            .catch((err) => {
                this.notify.error(err);
            });
    }

    private async loadJobFunnels(): Promise<void> {
        const [jobFunnels] = await JobFunnel.where('jobId', this.jobPosting.id).all();

        for (const jobFunnel of jobFunnels) {
            this.filterValues.jobFunnelStepName[jobFunnel.funnelStep] = JobFunnelNameOptions[jobFunnel.funnelStep]
                ? this.languageService.translate(JobFunnelNameOptions[jobFunnel.funnelStep])
                : jobFunnel.funnelStep;
        }
    }

    private async loadRatings(): Promise<void> {
        for (const rating of RATINGS) {
            this.filterValues.rating[rating] = rating;
        }
    }
}
