<!-- Top Navigation -->
<ui-card class="steps" [withSpacing]="false">
    <ng-container *ngFor="let step of steps; let index = index; let last = last">
        <div
            class="step"
            [class.completed]="activeStep >= index"
            [class.currentStep]="activeStep === index"
            [tabindex]="options.canNavigateViaTopBar ? 0 : -1"
            (click)="options.canNavigateViaTopBar ? setStep(index) : undefined"
        >
            <div class="content" title="{{ step.step | translate }}">
                <div class="number">{{ index + 1 }}</div>
                <div class="title">
                    {{ step.step | translate }}
                    <div *ngIf="options.showCompletedStatus" class="status">
                        <small>{{ stepsStatus[index] | capitalize }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!last" class="divider">•••••</div>
    </ng-container>
</ui-card>

<!-- Steps -->
<ng-content></ng-content>

<div *ngIf="isStepFormsDefined" class="flex button-container">
    <!-- Previous Button -->
    <ui-button (click)="previous()" [disabled]="!canPrevious" *ngIf="hasPrevious">
        <mat-icon svgIcon="chevronLeft" />{{ labels.previous ?? 'components.steps.previous' | translate }}
    </ui-button>

    <div class="right-buttons">
        <!-- Secondary Button -->
        <ui-button (click)="secondaryClick()" [disabled]="!canNext" *ngIf="isSecondaryActionVisible" type="outline">
            {{ labels.secondary ?? '' | translate }}
            <ng-content select=".secondaryButtonIcon"></ng-content>
        </ui-button>

        <!-- Primary Button -->
        <ui-button (click)="finish()" [disabled]="!canFinish" *ngIf="!hasNext" type="primary">
            {{ labels.primary ?? 'components.steps.complete' | translate }}
        </ui-button>

        <!-- Next Button -->
        <ui-button (click)="next()" [disabled]="!canNext" *ngIf="hasNext" type="primary">
            {{ labels.next ?? 'next' | translate }} <mat-icon svgIcon="chevronRight" />
        </ui-button>
    </div>
</div>
