import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SKIP_AUTH0_TOKEN, SKIP_HUMI_TOKEN } from '@app/interceptors/context-tokens';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type OfferLetterSigningUrlApiResponse = {
    data: {
        signingUrl: string;
    };
};

@Injectable()
export class OfferLetterSigningService {
    constructor(private http: HttpClient) {}

    fetchSigningUrl(offerLetterUuid: string): Observable<string> {
        const url = this.getOfferLetterSigningUrlEndpoint(offerLetterUuid);
        const context = new HttpContext().set(SKIP_HUMI_TOKEN, true).set(SKIP_AUTH0_TOKEN, true);

        return this.http
            .get<OfferLetterSigningUrlApiResponse>(url, { context })
            .pipe(map((response) => response.data.signingUrl));
    }

    private getOfferLetterSigningUrlEndpoint(offerLetterUuid: string): string {
        return environment.api + `/guest/offer-letters/${offerLetterUuid}/signing-url`;
    }
}
