<div>
    <div class="flex align-items-center">
        <div class="recorder-buttons">
            <ui-button *ngIf="showRecordButton" type="outline" (click)="onRecord()" [attr.data-cy]="'record-button'">
                <mat-icon class="icon-display" svgIcon="record"></mat-icon>
                {{ 'components.app-audio-recorder.recordButtonLabel' | translate }}
            </ui-button>
            <ui-button *ngIf="showStopButton" type="outline" (click)="onStop()" [attr.data-cy]="'stop-button'">
                <mat-icon class="icon-display" svgIcon="stop"></mat-icon>
                {{ 'components.app-audio-recorder.stopButtonLabel' | translate }}
            </ui-button>
            <ui-button
                *ngIf="showPlayButton"
                [disabled]="isPlaying"
                type="outline"
                (click)="onPlay()"
                [attr.data-cy]="'play-button'"
            >
                <mat-icon class="icon-display" svgIcon="play"></mat-icon>
                {{ 'components.app-audio-recorder.listenButtonLabel' | translate }}
            </ui-button>
            <ui-button
                [disabled]="!showPlayButton"
                [ngClass]="{ warning: !isPlaying && showPlayButton }"
                type="outline"
                (click)="onRemove()"
                [attr.data-cy]="'trash-button'"
            >
                <mat-icon svgIcon="trash"></mat-icon>
            </ui-button>

            <audio #audio></audio>
        </div>
        <div *ngIf="isRecording">
            <mat-icon class="icon-display recording" svgIcon="record"></mat-icon
            ><span class="duration padding-left-1">{{ duration | timer }}</span>
        </div>
    </div>
</div>
