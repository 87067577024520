import { Model } from '@models/core/base.model';
export class DeductionItem extends Model {
    protected static _resource = 'payroll/companies/:company/payrolls/deductionItems';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'name',
        'display_name',
        'amount',
        'pretax',
        'debitable',
        'statutory',
        'paystubId',
        'payee',
    ];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get displayName(): string {
        return this._attributes['displayName'];
    }

    set displayName(val: string) {
        this._attributes['displayName'] = val;
    }

    get paystubId(): number {
        return this._attributes['paystubId'];
    }

    set paystubId(val: number) {
        this._attributes['paystubId'] = val;
    }

    get amount(): number {
        return this._attributes['amount'];
    }

    set amount(val: number) {
        this._attributes['amount'] = val;
    }

    get statutory(): boolean {
        return this._attributes['statutory'];
    }

    set statutory(val: boolean) {
        this._attributes['statutory'] = val;
    }

    get pretax(): boolean {
        return this._attributes['pretax'];
    }

    set pretax(val: boolean) {
        this._attributes['pretax'] = val;
    }

    get debitable(): boolean {
        return this._attributes['debitable'];
    }

    set debitable(val: boolean) {
        this._attributes['debitable'] = val;
    }

    get payee(): string {
        return this._attributes['payee'];
    }

    set payee(val: string) {
        this._attributes['payee'] = val;
    }
}
