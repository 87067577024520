import { ModelMixin } from '../core/base-generic.model';

export class ApplicantOptionalField extends ModelMixin<ApplicantOptionalField>() {
    protected static _version = 'v2';

    get fieldName(): string {
        return this._attributes['fieldName'];
    }

    get atsJobId(): number {
        return this._attributes['atsJobId'];
    }
}
