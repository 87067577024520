const EmploymentTypeDisplayOptions: { [key: string]: string } = {
    'full-time': 'employmentType.fullTime',
    'part-time': 'employmentType.partTime',
    contract: 'employmentType.contract',
    contractor: 'employmentType.contractor',
    intern: 'employmentType.intern',
    internship: 'employmentType.internship',
    volunteer: 'employmentType.volunteer',
    seasonal: 'employmentType.seasonal',
    casual: 'employmentType.casual',
    consultant: 'employmentType.consultant',
    other: 'employmentType.other',
};

export { EmploymentTypeDisplayOptions };
