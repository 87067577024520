import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export const ZENDESK_URLS = {
    SNIPPET: 'https://static.zdassets.com/ekr/snippet.js?key=',
    DOMAIN_CHECK: 'https://ekr.zdassets.com/compose/',
};

@Injectable({ providedIn: 'root' })
export class ZenDeskService {
    public isEnabled!: Observable<boolean | undefined>;

    private isEnabled$ = new BehaviorSubject<boolean | undefined>(undefined);

    constructor(private httpBackend: HttpBackend) {
        this.isEnabled = this.isEnabled$.asObservable();
        new HttpClient(this.httpBackend)
            .get<{ products: unknown[] }>(`${ZENDESK_URLS.DOMAIN_CHECK}${environment.zenDesk?.apiKey}`)
            .pipe(take(1))
            .subscribe(
                (response) => {
                    // If the products array has length then the ZenDesk widget is enabled for the current environment
                    if (response.products.length) {
                        this.isEnabled$.next(true);
                    } else {
                        this.isEnabled$.next(false);
                    }
                },
                () => this.isEnabled$.next(false) // If the API call fails, don't display the widget
            );
    }
}
