export function jsonApiStructure(
    type: string,
    attributes: { [key: string]: any }
): { data: { type: string; attributes: { [key: string]: any } } } {
    return {
        data: {
            type,
            attributes,
        },
    };
}
