import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnChanges, Optional, Self, SimpleChanges } from '@angular/core';
import { NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { State } from '@app/models/common/state.model';
import { StateService } from '@app/services/state.service';
import { debounceTime, tap } from 'rxjs/operators';
import { BaseAutocompleteComponent, Option } from '../base-autocomplete/base-autocomplete.component';

/**
 * This autoselect's behaviour can be challenging
 *
 * The goal is to set the state value (ex: 'ON', 'QC') on an address (bound with ngModel)
 *
 * When a user types (ex: ontar), we subscribe to the value change and show the correct options
 * But when the user selects an option (ex: Ontario), the form control emits another change that we need
 * to handle.
 *
 */
@Component({
    selector: 'ui-state-autocomplete',
    templateUrl: './state-autocomplete.template.html',
    styleUrls: ['./state-autocomplete.styles.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: StateAutocompleteComponent }],
    host: {
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
        '[attr.required]': 'required',
    },
})
export class StateAutocompleteComponent extends BaseAutocompleteComponent<string> implements OnChanges {
    @Input() country: string | null;
    controlType = 'state-autocomplete';
    id = `state-autocomplete-${StateAutocompleteComponent.nextId++}`;
    states: Option<string>[] = [];

    displayFn = (stateCode: string): string => {
        return this.states.find((state) => state.value === stateCode)?.label || '';
    };

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        protected fm: FocusMonitor,
        protected elementRef: ElementRef<HTMLElement>,
        private stateService: StateService
    ) {
        super(ngControl, _parentForm, _defaultErrorStateMatcher, elementRef, fm);

        // Get new results on typing
        const searchControlSub = this.searchControl.valueChanges
            .pipe(
                tap(() => (this.loading = true)),
                debounceTime(500)
            )
            .subscribe((searchTerm: string) => {
                this.states ? this._setDisplayOptions(searchTerm) : {};
            });

        this._subscriptions.push(searchControlSub);
    }

    ngOnInit(): void {
        this._inputElement = this.elementRef.nativeElement.querySelector('input');

        const stateServiceSub = this.stateService.states.subscribe((states: State[]) => {
            this.states = states.map(
                ({ name, code }: State) =>
                    <Option<string>>{
                        label: name,
                        value: code,
                    }
            );
            this._setDisplayOptions();

            // Need to retrigger the displayWith function in the template binding once states have loaded.
            this.writeValue(this.value);
        });

        this._subscriptions.push(stateServiceSub);

        this.loadStates();
    }

    /**
     * Used to reload states from DB when country changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.country && changes.country.currentValue !== changes.country.previousValue) {
            this.value = null;
            this.searchControl.reset();

            // state in stateChanges refers to the state of the dropdown it is inherited from the base component it has nothing to do with provinces/states
            this.stateChanges.next();

            this.loadStates();
        }
    }

    onContainerClick(event: MouseEvent): void {
        // state in stateChanges refers to the state of the dropdown it is inherited from the base component it has nothing to do with provinces/states
        this.stateChanges.next();

        super.onContainerClick(event);
    }

    /**
     * Setting the display values (the options the user sees) is complicated
     * See the comment at the top of this class for reference
     */
    protected _setDisplayOptions(searchTerm = ''): void {
        // if no search term, we show all states
        if (!searchTerm) {
            this.options = [...this.states];
            this.loading = false;

            return;
        }

        // exact match of value
        const exactMatchOnValue = this.states.find((state) => state.value === searchTerm);
        if (exactMatchOnValue) {
            this.options = [exactMatchOnValue];
            this.loading = false;

            return;
        }

        // see if state matches search term
        this.options = this.states.filter((state) => state?.label?.toLowerCase().includes(searchTerm.toLowerCase()));

        this.loading = false;
    }

    private loadStates(): void {
        this.loading = true;
        if (this.country) {
            this.stateService.getStates(this.country);
        }
    }
}
