import { Account } from '@models/account/account.model';
import { PermissibleTypes } from '../../enums/permissible-types.enum';
import { Permissible } from '../permissions/permissible.model';
import { Permission } from '../permissions/permission.model';

import { SimpleEntityActions } from '@app/classes';
import { Chippable } from '@app/interfaces/chippable.interface';
import { ModelMixin } from '../core/base-generic.model';
import { RoleAssignment } from './role-assignment.model';

export class Role extends ModelMixin<Role>() implements Chippable {
    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get isImmutable(): boolean {
        return this._attributes['isImmutable'] || false;
    }
    set isImmutable(val: boolean) {
        this._attributes['isImmutable'] = val;
    }
    /**
     * Permissions
     * @return {Permission[]} [description]
     */
    get permissions(): Permission[] {
        return this.hasMany(Permission, 'permissions');
    }
    set permissions(val: Permission[]) {
        this.setMany('permissions', val);
    }

    get accounts(): Account[] {
        return this.hasMany(Account, 'accounts');
    }

    get roleAssignments(): RoleAssignment[] {
        return this.hasMany(RoleAssignment, 'roleAssignments');
    }

    /**
     * Permissibles
     * @return {Permissible[]} [description]
     */
    get permissibles(): Permissible[] {
        return this.hasMany(Permissible, 'permissibles');
    }

    /**
     * Permissibles
     * @return {Permissible[]} [description]
     */
    set permissibles(permissibles: Permissible[]) {
        this._attributes['permissibles'] = permissibles.map((permissible) => {
            return {
                entityId: permissible.entity.id,
                entityType: PermissibleTypes.Taxonomy,
                name: permissible.entity.tag,
            };
        });
    }

    /**
     * Store permission IDs for sync
     */
    set permissionIds(val: number[]) {
        this._attributes['permissionIds'] = val;
    }

    /**
     * Store assignable roles IDs for sync
     */
    set assignableRolesIds(val: number[]) {
        this._attributes['assignableRolesIds'] = val;
    }

    set toggled(val: boolean) {
        this._attributes['toggled'] = val;
    }

    get chipLabel(): string {
        return this.name;
    }

    protected static _resource = 'accountManagement/roles';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'assignableRolesIds',
        'permissionIds',
        'name',
        'description',
        'isImmutable',
        'permissibles',
    ];

    static permission = new SimpleEntityActions('role');

    cloneRole: string;
}
