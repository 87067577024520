import { Directive, ElementRef } from '@angular/core';

/**
 * @deprecated
 */
@Directive({
    selector: '[deprecated-ui-progress]',
})
export class DeprecatedUiProgressDirective {
    constructor(private element: ElementRef) {}

    ngOnInit() {
        setTimeout(() => {
            $(this.element.nativeElement).progress();
        });
    }
}
