import { Model } from '../core/base.model';

export class CustomReport extends Model {
    static accessPermission = 'customReports';

    protected static _resource = 'reporting/customReports';

    protected static _version = 'v1';

    protected static _serializeAttributes = [
        'name',
        'primaryModel',
        'primaryId',
        'primaryRelation',
        'relations',
        'filters',
    ];

    get id() {
        return this._attributes['id'];
    }

    get name() {
        return this._attributes['name'];
    }
    set name(val) {
        this._attributes['name'] = val;
    }

    get primaryModel() {
        return this._attributes['primary_model'];
    }
    set primaryModel(val) {
        this._attributes['primary_model'] = val;
    }

    get primaryId() {
        return this._attributes['primary_id'];
    }
    set primaryId(val) {
        this._attributes['primary_id'] = val;
    }

    get primaryRelation() {
        return this._attributes['primary_relation'];
    }
    set primaryRelation(val) {
        this._attributes['primary_relation'] = val;
    }

    get relations() {
        return this._attributes['relations'];
    }
    set relations(val) {
        this._attributes['relations'] = val;
    }

    get filters() {
        return this._attributes['filters'];
    }
    set filters(val) {
        this._attributes['filters'] = val;
    }
}
