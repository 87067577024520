<ng-content select="[banner]"></ng-content>
<ng-container *ngIf="parent.steps[parent.activeStep]?.step === step">
    <ui-card *ngIf="embeddedCard; else cardless" [withMargin]="false"
        ><ng-container [ngTemplateOutlet]="stepContent"
    /></ui-card>
    <ng-template #cardless>
        <div><ng-container [ngTemplateOutlet]="stepContent" /></div>
    </ng-template>
</ng-container>

<ng-template #stepContent>
    <ng-container *ngIf="title">
        <app-header [title]="title | appTranslate"></app-header>
        <hr class="lighter margin-top-2" />
    </ng-container>
    <ng-content></ng-content>
</ng-template>
