import { Employee } from '@models/employee/employee.model';

export class ConflictedEmployee extends Employee {
    protected static _resource = 'payroll/conflictedEmployees';

    protected static _version = 'v2';

    get vacationPayConflictMessage(): string {
        return this._attributes['vacationPayConflictMessage'];
    }

    get vacationPayConflictLabel(): string {
        return this._attributes['vacationPayConflictLabel'];
    }

    get vacationPayMethod(): string {
        return this._attributes['vacationPayMethod'];
    }

    set vacationPayMethod(val: string) {
        this._attributes['vacationPayMethod'] = val;
    }
}
