import { Model } from '@models/core/base.model';

/**
 * modelsNotInModels returns an array of models that are in a but not in b.
 *
 * It runs in O(n) time, which is better than most of our O(n^2) implementations.
 */
export function modelsNotInModels<T extends Model>(a: T[], b: T[]): T[] {
    const bSet = new Set(b.map((m) => m.id));
    return a.filter((model) => !bSet.has(model.id));
}
