import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { NameColumn, DescriptionColumn } from '@settings/services/tables/default-roles-table/columns';
import { Role } from '@app/models/account/role.model';

@Injectable()
export class DefaultRolesIndexTable extends Table {
    links = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getLink(role: Role): (string | number)[] {
        return ['/settings', 'roles', role.id];
    }

    setColumns(): void {
        this.columns = [new NameColumn(), new DescriptionColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(Role.where('company_id', this.auth.company.id).where('isImmutable', 1));
    }
}
