import { Translatable, TranslatableKey } from '@app/types/translatable.type';
import { SaveOptions } from '@interfaces/save-options.interface';
import { Model } from '@models/core/base.model';
import { TimeOffPolicy } from '@models/time-off-v3/time-off-policy.model';

export const TIME_WORKED = 'timeWorked';

export class TimeOffType extends Model {
    protected static _resource = 'timeOffV3/types';
    protected static _type = 'timeOffTypes';
    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _serializeAttributes = [
        'id',
        'companyId',
        'isDefault',
        'isPublic',
        'isPaid',
        'displayInHours',
        'hoursPerDay',
        'icon',
        'name',
        'description',
        'color',
        'incomeTypeId',
    ];

    get companyId(): string {
        return this._attributes['companyId'];
    }

    get isDefault(): boolean {
        return this._attributes['isDefault'];
    }

    get isPublic(): boolean {
        return this._attributes['isPublic'];
    }

    get isPaid(): boolean | string {
        return this._attributes['isPaid'] === null ? TIME_WORKED : this._attributes['isPaid'];
    }

    get isLocked(): boolean {
        return this._attributes['isLocked'];
    }

    get displayInHours(): boolean {
        return this._attributes['displayInHours'];
    }

    get hoursPerDay(): number {
        return this._attributes['hoursPerDay'];
    }

    get icon(): string {
        return this._attributes['icon'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get color(): string {
        return this._attributes['color'];
    }

    get incomeTypeId(): number {
        return this._attributes['incomeTypeId'];
    }

    get unitOfTimeSingular(): string {
        return this.displayInHours ? 'hour' : 'day';
    }

    get unitOfTimePlural(): string {
        return this.displayInHours ? 'hours' : 'days';
    }

    get unitOfTimeSingularTranslation(): Translatable {
        return this.displayInHours ? 'time-off-v3.hourSingular' : 'time-off-v3.daySingular';
    }

    get unitOfTimePluralTranslation(): TranslatableKey {
        return this.displayInHours ? 'time-off-v3.hours' : 'time-off-v3.days';
    }

    get timeOffPolicies(): TimeOffPolicy[] {
        return this.hasMany(TimeOffPolicy, 'timeOffPolicies');
    }

    set companyId(val: string) {
        this._attributes['companyId'] = val;
    }

    set isDefault(val: boolean) {
        this._attributes['isDefault'] = val;
    }

    set isPublic(val: boolean) {
        this._attributes['isPublic'] = val;
    }

    set isPaid(val: boolean | string) {
        this._attributes['isPaid'] = val;
    }

    set displayInHours(val: boolean) {
        this._attributes['displayInHours'] = val;
    }

    set hoursPerDay(val: number) {
        this._attributes['hoursPerDay'] = val;
    }

    set icon(val: string) {
        this._attributes['icon'] = val;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    set color(val: string) {
        this._attributes['color'] = val;
    }

    set incomeTypeId(val: number) {
        this._attributes['incomeTypeId'] = val;
    }

    save(options: SaveOptions = { includeId: true, onlyDirty: false }): Promise<any> {
        // Backend expect the value for `isPaid` to be true, false or null. Since null can not ben an `option`
        // we set it to a string ('timeWorked') and change the value upon submission.
        if (this.isPaid === TIME_WORKED) {
            this.isPaid = null;
        }

        return super.save(options);
    }
}
