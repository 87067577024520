import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';

export class AssetAssignment extends Model {
    protected static _resource = 'assets/:asset/assignments';
    protected static _version = 'v2';

    protected static _type = 'assetAssignments';

    protected static _serializeAttributes = ['assetId', 'employeeId', 'assignedOn', 'returnedOn'];

    protected static _dates = ['assignedOn', 'returnedOn'];

    get assetId(): number {
        return this._attributes['assetId'];
    }

    set assetId(val: number) {
        this._attributes['assetId'] = val;
    }

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    set employee(val: Employee) {
        this.setOne('employee', val, 'employeeId');
    }

    get assignedOn(): Date {
        return this._attributes['assignedOn'];
    }

    set assignedOn(val: Date) {
        this._attributes['assignedOn'] = val;
    }

    get returnedOn(): Date {
        return this._attributes['returnedOn'];
    }

    set returnedOn(val: Date) {
        this._attributes['returnedOn'] = val;
    }
}
