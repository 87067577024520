import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * If the value is number, pass it to the decimal pipe
 * Otherwise, send it back
 * Useful for tabular data that is unpredictable
 */
@Pipe({ name: 'maybeNumber' })
export class MaybeNumberPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}

    transform(value: any, digitsInfo?: string, locale?: string): any {
        if (Number.isNaN(Number(value))) {
            return value;
        }

        return this.decimalPipe.transform(value, digitsInfo, locale);
    }
}
