import { ModelMixin } from '@models/core/base-generic.model';
import { Company } from '@models/company/company.model';

export class SelfServeQuickstart extends ModelMixin<SelfServeQuickstart>() {
    protected static _type = 'selfServeQuickstarts';
    protected static _resource = 'selfOnboarding/selfServeQuickstart';
    protected static _version = 'v2';
    protected static _datetimes = [
        'trialExpiresAt',
        'payYourTeamStartedAt',
        'payYourTeamEndedAt',
        'bookACallStartedAt',
        'bookACallEndedAt',
        'payrollDocumentsStartedAt',
        'payrollDocumentsEndedAt',
        'timeOffStartedAt',
        'timeOffEndedAt',
        'companyDetailsStartedAt',
        'companyDetailsEndedAt',
        'addYourTeamStartedAt',
        'addYourTeamEndedAt',
        'cxMeetingAt',
        'invitationsSentAt',
        'firstPayrollRunAt',
        'webinarBannerDismissedAt',
        'completedAt',
    ];

    protected static _serializeAttributes = [
        'payYourTeamStartedAt',
        'payYourTeamEndedAt',
        'bookACallStartedAt',
        'bookACallEndedAt',
        'payrollDocumentsStartedAt',
        'payrollDocumentsEndedAt',
        'timeOffStartedAt',
        'timeOffEndedAt',
        'companyDetailsStartedAt',
        'companyDetailsEndedAt',
        'addYourTeamStartedAt',
        'addYourTeamEndedAt',
        'cxMeetingAt',
        'invitationsSentAt',
        'firstPayrollRunAt',
        'webinarBannerDismissedAt',
        'completedAt',
    ];

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get company(): Company {
        return this.hasOne(Company, 'company');
    }

    get trialExpiresAt(): Date | null {
        return this._attributes['trialExpiresAt'];
    }

    get payYourTeamStartedAt(): Date | null {
        return this._attributes['payYourTeamStartedAt'];
    }

    set payYourTeamStartedAt(value: Date | null) {
        this._attributes['payYourTeamStartedAt'] = value;
    }

    get payYourTeamEndedAt(): Date | null {
        return this._attributes['payYourTeamEndedAt'];
    }

    set payYourTeamEndedAt(value: Date | null) {
        this._attributes['payYourTeamEndedAt'] = value;
    }

    get bookACallStartedAt(): Date | null {
        return this._attributes['bookACallStartedAt'];
    }

    set bookACallStartedAt(value: Date | null) {
        this._attributes['bookACallStartedAt'] = value;
    }

    get bookACallEndedAt(): Date | null {
        return this._attributes['bookACallEndedAt'];
    }

    set bookACallEndedAt(value: Date | null) {
        this._attributes['bookACallEndedAt'] = value;
    }

    get payrollDocumentsStartedAt(): Date | null {
        return this._attributes['payrollDocumentsStartedAt'];
    }

    set payrollDocumentsStartedAt(value: Date | null) {
        this._attributes['payrollDocumentsStartedAt'] = value;
    }

    get payrollDocumentsEndedAt(): Date | null {
        return this._attributes['payrollDocumentsEndedAt'];
    }

    set payrollDocumentsEndedAt(value: Date | null) {
        this._attributes['payrollDocumentsEndedAt'] = value;
    }

    get timeOffStartedAt(): Date | null {
        return this._attributes['timeOffStartedAt'];
    }

    set timeOffStartedAt(value: Date | null) {
        this._attributes['timeOffStartedAt'] = value;
    }

    get timeOffEndedAt(): Date | null {
        return this._attributes['timeOffEndedAt'];
    }

    set timeOffEndedAt(value: Date | null) {
        this._attributes['timeOffEndedAt'] = value;
    }

    get companyDetailsStartedAt(): Date | null {
        return this._attributes['companyDetailsStartedAt'];
    }

    set companyDetailsStartedAt(value: Date | null) {
        this._attributes['companyDetailsStartedAt'] = value;
    }

    get companyDetailsEndedAt(): Date | null {
        return this._attributes['companyDetailsEndedAt'];
    }

    set companyDetailsEndedAt(value: Date | null) {
        this._attributes['companyDetailsEndedAt'] = value;
    }

    get addYourTeamStartedAt(): Date | null {
        return this._attributes['addYourTeamStartedAt'];
    }

    set addYourTeamStartedAt(value: Date | null) {
        this._attributes['addYourTeamStartedAt'] = value;
    }

    get addYourTeamEndedAt(): Date | null {
        return this._attributes['addYourTeamEndedAt'];
    }

    set addYourTeamEndedAt(value: Date | null) {
        this._attributes['addYourTeamEndedAt'] = value;
    }

    get completedAt(): Date | null {
        return this._attributes['completedAt'];
    }

    set completedAt(value: Date | null) {
        this._attributes['completedAt'] = value;
    }

    get cxMeetingAt(): Date | null {
        return this._attributes['cxMeetingAt'];
    }

    set cxMeetingAt(value: Date | null) {
        this._attributes['cxMeetingAt'] = value;
    }

    get invitationsSentAt(): Date | null {
        return this._attributes['invitationsSentAt'];
    }

    set invitationsSentAt(value: Date | null) {
        this._attributes['invitationsSentAt'] = value;
    }

    get firstPayrollRunAt(): Date | null {
        return this._attributes['firstPayrollRunAt'];
    }

    set firstPayrollRunAt(value: Date | null) {
        this._attributes['firstPayrollRunAt'] = value;
    }

    /**
     * webinarBannerDismissedAt shows if the company has dismissed the special webinar banner
     * that we show at the end of the quickstart.
     */
    get webinarBannerDismissedAt(): Date | null {
        return this._attributes['webinarBannerDismissedAt'] ?? false;
    }

    set webinarBannerDismissedAt(value: Date | null) {
        this._attributes['webinarBannerDismissedAt'] = value;
    }
}
