import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';

export class Note extends Model {
    static defaultEntityType = 'App\\Modules\\CompanyManagement\\Models\\Company';
    protected static _resource = 'companyManagement/companies/:company/notes';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['title', 'body', 'authorId', 'entityType', 'entityId'];

    get authorId(): number {
        return this._attributes['authorId'] || this._attributes['author_id'];
    }

    set authorId(val: number) {
        this._attributes['authorId'] = val;
    }

    get title(): string {
        return this._attributes['title'];
    }

    set title(val: string) {
        this._attributes['title'] = val;
    }

    get body(): string {
        return this._attributes['body'];
    }

    set body(val: string) {
        this._attributes['body'] = val;
    }

    set entityType(val: string) {
        this._attributes['entityType'] = val;
    }

    set entityId(val: number) {
        this._attributes['entityId'] = val;
    }

    get author(): Employee {
        return this.hasOne(Employee, 'author');
    }
}
