import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApprovalWorkflow } from '@models/approvals/approval-workflow.model';
import { Employee } from '@models/employee/employee.model';
import { PreviewResponse, PreviewStep } from '@change-requests/interfaces';

@Injectable()
export class ApprovalWorkflowService {
    constructor(private http: HttpClient) {}

    async getPreviewSteps(workflow: ApprovalWorkflow, subject: Employee): Promise<PreviewStep[]> {
        const endpoint = `/v2/approvals/workflows/${workflow.id}/employee/${subject.id}/preview`;
        const res = await this.http.get<PreviewResponse>(endpoint, {}).toPromise();
        return res.data[0].attributes.steps;
    }
}
