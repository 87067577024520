import { Injectable } from '@angular/core';
import { Account, ChartOfAccounts } from '@app/models/payroll/company/chart-of-accounts.model';
import { XeroAccountMapping } from '@app/models/payroll/company/xero-account-mapping.model';
import { JournalEntryReportItemTemplate } from '@app/models/payroll/journal-entry-reports-settings-line-item.model';
import { AuthService } from '@app/services';
import keyBy from 'lodash-es/keyBy';
import sortBy from 'lodash-es/sortBy';

@Injectable()
export class XeroManageService {
    constructor(private auth: AuthService) {}

    async getAccountMappings(): Promise<XeroAccountMapping[]> {
        const [accountMappings, [journalEntryReportItemTemplates]] = await Promise.all([
            XeroAccountMapping.param('company', this.auth.company.id)
                .all()
                .then(([mappings]: [XeroAccountMapping[], any]) => keyBy(mappings, 'journalEntryReportItemTemplateId')),

            JournalEntryReportItemTemplate.param('company', this.auth.company.id).all(),
        ]);

        return journalEntryReportItemTemplates.map((journalEntryReportItemTemplate) => {
            const accountMapping =
                accountMappings[journalEntryReportItemTemplate.id] ??
                new XeroAccountMapping({ journalEntryReportItemTemplateId: journalEntryReportItemTemplate.id });

            accountMapping.journalEntryReportItemTemplate = journalEntryReportItemTemplate;

            return accountMapping;
        });
    }

    async getChartOfAccounts(): Promise<Account[]> {
        const [charts]: [ChartOfAccounts[], any] = await ChartOfAccounts.param('company', this.auth.company.id).all();
        return sortBy(charts[0]?.allAccounts ?? [], 'code');
    }

    async updateAccountMapping(
        xeroAccountMapping: XeroAccountMapping,
        accountCode: string
    ): Promise<XeroAccountMapping> {
        xeroAccountMapping.code = accountCode;
        return await xeroAccountMapping.param('company', this.auth.company.id).save();
    }
}
