const meridianOffset = 2;
const meridianHours = 12;
const timePadding = 2;

export const fromDisplayFormat = (time: string): string => {
    if (typeof time !== 'string') {
        return '';
    }

    const meridian = time.substring(time.length - meridianOffset);

    let [hours, minutes] = time.replace(/[A-z]/gi, '').split(':');

    if (isNaN(parseInt(hours)) || isNaN(parseInt(minutes))) {
        return '';
    }

    hours = '' + (parseInt(hours) % meridianHours);
    if (meridian === 'pm') {
        const hoursVal = parseInt(hours) + meridianHours;
        hours = '' + hoursVal;
    }

    if (!minutes) {
        minutes = '0';
    }

    hours = hours.padStart(timePadding, '0');
    minutes = minutes.padStart(timePadding, '0');

    return `${hours}:${minutes}:00`;
};
