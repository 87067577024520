import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import moment from 'moment';
import { Directive } from '@angular/core';

@Directive({
    selector: '[hiredAtValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: HiredAtValidator, multi: true }],
})
export class HiredAtValidator implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const controlDate = moment(control.value).startOf('day');
        const upperBound = moment().startOf('day').add(1, 'year');
        const lowerBound = moment('1900-01-01').startOf('day');

        if (controlDate.isBefore(lowerBound) || controlDate.isAfter(upperBound)) {
            return { custom: { message: `Date cannot be later than ${upperBound.format('YYYY-MM-DD')}.` } };
        }

        return null;
    }
}
