import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, MenuItemGroup } from '@app/interfaces';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbilityService } from '../ability.service';
import { AuthService } from '../auth.service';
import { FeatureService } from '../feature.service';
import { OnboardingStatusService } from '@app/modules/self-serve/services/onboarding-status.service';

@Injectable()
export abstract class MenuService implements OnDestroy {
    menu$: Observable<MenuItemGroup>;
    protected baseMenu: MenuItemGroup;
    private _menu$: Subject<MenuItemGroup> = new BehaviorSubject([]);
    private _featureFlags: LDFlagSet = {};
    private _destroyed$: Subject<void> = new Subject();
    private _areDependenciesLoaded = false;

    constructor(
        protected auth: AuthService,
        protected abilities: AbilityService,
        protected features: FeatureService,
        private activatedRoute: ActivatedRoute,
        private onboardingStatusService: OnboardingStatusService
    ) {
        this.menu$ = this._menu$.asObservable();
        this.setBaseMenu();
        this.loadDependencies();
        this.subscribeToDependencies();
    }

    get areDependenciesLoaded(): boolean {
        return this._areDependenciesLoaded;
    }

    /**
     * This is where we set the actual menu. We have to do this because of the way JS handles
     * application of derived class's constructors overtop base ones.
     * Setting it as an abstract property is _not_ accessible within the constructor.
     *
     * This should be a simple method that sets `this.baseMenu`
     *
     * ie:
     *
     * const someMenu: MenuItemGroup = [ ... ]
     *
     * class SomeMenuService extends MenuService {
     *     setBaseMenu(): void {
     *         this.baseMenu = someMenu;
     *     }
     * }
     */
    abstract setBaseMenu(): void;

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._menu$.complete();
    }

    updateMenu(): void {
        const menu = this.filterMenu(cloneDeep(this.baseMenu));
        this._menu$.next(menu);
    }

    protected filterMenu(menu: MenuItemGroup): MenuItemGroup {
        return menu
            .filter(this.userCan)
            .filter(this.userCannot)
            .filter(this.userAbleTo)
            .filter(this.userUnableTo)
            .filter(this.hasModule)
            .filter(this.notHasModule)
            .filter(this.hasEmployeeModule)
            .filter(this.hasFeatureFlag)
            .filter(this.doesNotHaveFeatureFlag)
            .filter(this.showWhen)
            .map((m) => {
                if (m.children) {
                    m.children = this.filterMenu(m.children);
                }

                return m;
            });
    }

    private subscribeToDependencies(): void {
        this.abilities.abilitiesChanged$.pipe(takeUntil(this._destroyed$)).subscribe(() => {
            this.updateMenu();
        });
        this.auth.onLogin.subscribe(() => {
            this.updateMenu();
        });
    }

    private loadDependencies(): void {
        Promise.all([this.features.all(), this.abilities.load()])
            .then(([featureFlags]) => {
                this._featureFlags = featureFlags;
                this.updateMenu();
                this._areDependenciesLoaded = true;
            })
            .catch(() => {
                // Log to sentry here maybe?
                this.updateMenu();
            });
    }

    private readonly userCan = (m: MenuItem): boolean => {
        if (!m.can) {
            return true;
        }

        if (m.checkAllPermissions) {
            return this.auth.canAll(m.can);
        }

        return this.auth.canAny(m.can);
    };

    private readonly userCannot = (m: MenuItem): boolean => {
        if (!m.cannot) {
            return true;
        }
        return !this.auth.canAny(m.cannot);
    };

    private readonly userAbleTo = (m: MenuItem): boolean => (m.ableTo ? this.abilities[m.ableTo]() : true);
    private readonly userUnableTo = (m: MenuItem): boolean => (m.unableTo ? !this.abilities[m.unableTo]() : true);
    private readonly hasModule = (m: MenuItem): boolean =>
        m.module ? this.auth.company.modules.findIndex((module) => module.name === m.module) > -1 : true;
    private readonly notHasModule = (m: MenuItem): boolean =>
        m.showWhenModuleDisabled
            ? this.auth.company.modules.findIndex((module) => module.name === m.showWhenModuleDisabled) === -1
            : true;
    private readonly hasEmployeeModule = (m: MenuItem): boolean =>
        m.employeeModule ? this.auth.employee?.hasModule(m.employeeModule) : true;
    private readonly hasFeatureFlag = (m: MenuItem): boolean =>
        m.showIfHasFeatureFlag ? this._featureFlags[m.showIfHasFeatureFlag] : true;
    private readonly doesNotHaveFeatureFlag = (m: MenuItem): boolean =>
        m.hideIfHasFeatureFlag ? !this._featureFlags[m.hideIfHasFeatureFlag] : true;
    private readonly showWhen = (m: MenuItem): boolean =>
        m.showWhen
            ? m.showWhen({
                  authService: this.auth,
                  featureService: this.features,
                  abilityService: this.abilities,
                  activateRoute: this.activatedRoute,
                  onboardingStatusService: this.onboardingStatusService,
              })
            : true;
}
