import { Model } from '../core/base.model';
import { Employee } from '@models/employee/employee.model';
import { SimpleEntityActions } from '@app/classes/simple-entity-actions.class';

export class Feedback extends Model {
    protected static _resource = 'performanceManagement/feedback';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'subjectId',
        'title',
        'body',
        'visibility',
        'positive',
        'constructive',
        'negative',
        'createdBy',
    ];

    static permission = new SimpleEntityActions('feedback');

    get subject(): Employee {
        return this.hasOne(Employee, 'subject');
    }

    set subject(employee: Employee) {
        this.setOne('subject', employee, 'subjectId');
    }

    get creator(): Employee {
        return this.hasOne(Employee, 'creator');
    }

    get title(): string {
        return this._attributes['title'];
    }

    set title(val: string) {
        this._attributes['title'] = val;
    }

    get body(): string {
        return this._attributes['body'];
    }

    set body(val: string) {
        this._attributes['body'] = val;
    }

    get createdBy(): number {
        return this._attributes['createdBy'];
    }

    get subjectId(): number {
        return this._attributes['subjectId'];
    }

    set subjectId(val: number) {
        this._attributes['subjectId'] = val;
    }

    get positive(): boolean {
        return this._attributes['positive'];
    }

    set positive(val: boolean) {
        this._attributes['positive'] = val;
    }

    get constructive(): boolean {
        return this._attributes['constructive'];
    }

    set constructive(val: boolean) {
        this._attributes['constructive'] = val;
    }

    get negative(): boolean {
        return this._attributes['negative'];
    }

    set negative(val: boolean) {
        this._attributes['negative'] = val;
    }

    get visibility(): string {
        return this._attributes['visibility'];
    }

    set visibility(val: string) {
        this._attributes['visibility'] = val;
    }
}
