<div class="flex align-items-center" [class.margin-left-1]="withMargins" [class.margin-bottom-3]="withMargins">
    <div class="flex" style="z-index: 0">
        <div
            *ngFor="let employee of employeesToShow; let i = index"
            class="overlapping-avatar"
            style="z-index: {{ i }}"
        >
            <app-avatar [employee]="employee" [size]="size"></app-avatar>
        </div>
    </div>
    <div *ngIf="showEmployeeName" class="flex margin-left-1">
        <div [matTooltip]="ellipsisEmployeesNames" matTooltipPosition="above">{{ employeesNames }}</div>
    </div>
</div>
