import { SimpleEntityActions } from '@app/classes';
import { Model } from '../core/base.model';

export class Permission extends Model {
    protected static _resource = 'accountManagement/permissions';
    protected static _version = 'v2';
    static permission = new SimpleEntityActions('permission');

    get name(): string {
        return this._attributes['name'];
    }

    get prettyName(): string {
        return this._attributes['name'].replace(/([A-Z])/g, ' $1').replace(/_/g, ' ');
    }

    get resource(): string | null {
        if (this._attributes['name'].indexOf('.') > -1) {
            return this._attributes['name'].split('.')[1];
        }
        return null;
    }

    get action(): string | null {
        if (this._attributes['name'].indexOf('.') > -1) {
            return this._attributes['name'].split('.')[0];
        }
        return null;
    }
}
