import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ChipItem } from '@app/components/chip-filters/chip-filters-list.component';
import { AnalyticEvents } from '@app/enums';
import { NotificationEvents } from '@app/enums/notification-events.enum';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { NotificationRequestFilterOptions } from '@app/modules/dashboard/meta/notification-filter.meta';
import { AnalyticService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { NotifyService } from '@app/services/notify.service';
import { Notification } from '@models/common/notification.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type NotificationType = 'all' | 'unread';
type LocalStorageFilters = {
    id: number;
    filterValues: NotificationRequestFilterOptions;
};
const localStorageDashboardNotificationFiltersKey = 'dashboard-notification-filters';

@Injectable()
export class NotificationService {
    currentPage = 1;
    shouldConcat = false;
    totalNumberOfNotifications = 0;
    totalNumberOfUnreadArchivedNotifications = 0;
    totalNumberOfUnreadNotifications = 0;
    notificationType: NotificationType = 'all';
    filterOptions: NotificationRequestFilterOptions = null;

    notifications$: Observable<Notification[]>;
    isLoading$: Observable<boolean>;
    filterOptions$: Observable<NotificationRequestFilterOptions>;

    private notificationsSource = new BehaviorSubject<Notification[]>([]);
    private isLoadingSource = new BehaviorSubject<boolean>(false);
    private filterOptionSource = new BehaviorSubject<NotificationRequestFilterOptions>({});
    private _subject = new Subject<NotificationEvents>();
    private notifications: Notification[] = [];

    constructor(
        private auth: AuthService,
        private analyticService: AnalyticService,
        private notify: NotifyService,
        private http: HttpClient,
        private localStorage: LocalStorageService
    ) {
        this.notifications$ = this.notificationsSource.asObservable();
        this.filterOptions$ = this.filterOptionSource.asObservable();
        this.isLoading$ = this.isLoadingSource.asObservable();

        this.auth.onLogin.pipe(takeUntilDestroyed()).subscribe(() => {
            this.applyFilters();
        });

        this.auth.onLogout.pipe(takeUntilDestroyed()).subscribe(() => {
            this.filterOptions = null;
            this.filterOptionSource.next(this.filterOptions);
        });

        this.applyFilters();
    }

    get events$(): Observable<NotificationEvents> {
        return this._subject.asObservable();
    }

    async refetchNotifications(showArchived = false): Promise<void> {
        this.currentPage = 1;
        this.shouldConcat = false;
        this.filterOptions = null;

        await this.loadNotifications(showArchived);
    }

    /**
     * Get the filter values saved in local storage and applies it for the current user.
     */
    async applyFilters(): Promise<void> {
        const localFiltersArray = await this.getSavedFilters();

        const currentEmployeeFilter = localFiltersArray?.find((localFilter) => {
            return localFilter.id === this.auth.employee.id;
        });

        if (currentEmployeeFilter) {
            this.filterOptions = currentEmployeeFilter.filterValues;
            this.filterOptionSource.next(this.filterOptions);
        }
    }

    // TODO: Old function remove after getting rid of feature flag for improved homepage
    async incrementPage(showArchived = false): Promise<void> {
        this.currentPage = this.currentPage + 1;
        this.shouldConcat = true;

        if (this.notificationType === 'all') {
            await this.loadNotifications(showArchived);
            return;
        }

        await this.loadUnreadNotifications(showArchived);
    }

    async onPageChange(showArchived = false, currentPage: number): Promise<void> {
        this.currentPage = currentPage;

        if (this.notificationType === 'all') {
            await this.loadNotifications(showArchived);
            return;
        }

        await this.loadUnreadNotifications(showArchived);
    }

    getNotifications(): Notification[] {
        return this.notifications;
    }

    async setFilterOptions(options: NotificationRequestFilterOptions): Promise<void> {
        this.currentPage = 1;

        this.filterOptions = options;

        this.filterOptionSource.next(this.filterOptions);

        // This logic is for storing data to local storage for filters.
        const localFiltersArray = await this.getSavedFilters();

        const updatedFilters = this.updateFilterArray(localFiltersArray, {
            id: this.auth.employee.id,
            filterValues: this.filterOptions,
        });

        this.localStorage.store(localStorageDashboardNotificationFiltersKey, updatedFilters);

        if (this.notificationType === 'all') {
            await this.loadNotifications(false);
            return;
        }

        await this.loadUnreadNotifications(false);
    }

    async clearFilterOptions(): Promise<void> {
        this.filterOptions = null;
        const localFiltersArray = await this.getSavedFilters();
        const updatedFilters = localFiltersArray.filter((filter) => filter.id !== this.auth.employee.id);
        this.localStorage.store(localStorageDashboardNotificationFiltersKey, updatedFilters);

        this.filterOptionSource.next(this.filterOptions);
        this.refetchNotifications();
    }

    getUnreadArchivedNotificationsCount(): Promise<void> {
        return Notification.where('notifiable_id', this.auth.employee.id)
            .orderBy('id', 'DESC')
            .with('category')
            .where('isArchived', true)
            .where('isRead', false)
            .page(1)
            .limit(5)
            .get()
            .then((response) => {
                const meta = response[1];
                this.totalNumberOfUnreadArchivedNotifications = meta.pagination.total;
            });
    }

    getUnreadNotificationsCount(): Promise<number> {
        return new Promise((resolve) => {
            Notification.where('notifiable_id', this.auth.employee.id)
                .orderBy('id', 'DESC')
                .with('category')
                .where('isArchived', false)
                .where('isRead', false)
                .limit(5)
                .get()
                .then((response) => {
                    const meta = response[1];
                    this.totalNumberOfUnreadNotifications = meta.pagination.total;
                    resolve(meta.pagination.total);
                })
                .catch(() => resolve(0));
        });
    }

    toggleRead(notification: Notification): Promise<void> {
        const event = notification.isReadStatus ? NotificationEvents.isUnRead : NotificationEvents.isRead;
        return notification.toggleRead().then(() => this._subject.next(event));
    }

    async archiveAll(): Promise<void> {
        const url = '/v2/notificationManagement/notifications/archiveAll';
        await this.http.post(url, null, { observe: 'response' }).toPromise();
    }

    async unarchiveAll(): Promise<void> {
        const url = '/v2/notificationManagement/notifications/unArchiveAll';
        await this.http.post(url, null, { observe: 'response' }).toPromise();
    }

    async markAllAsRead(isArchived = false): Promise<void> {
        const url = '/v2/notificationManagement/markNotificationsRead';

        const payload = {
            data: {
                type: 'notifications',
                attributes: {
                    isArchived: isArchived,
                },
            },
        };

        await this.http.post(url, payload, { observe: 'response' }).toPromise();
        this._subject.next(NotificationEvents.allAreRead);
    }

    moveAllToInbox(): void {
        // To do once backend SAC is in
        return;
    }

    async filterByStatus($event: ChipItem<NotificationType>): Promise<void> {
        this.notificationType = $event.value;
        // Clear notifications when switching types
        this.notifications = [];
        this.currentPage = 1;

        switch ($event.value) {
            case 'unread':
                await this.loadUnreadNotifications();
                break;

            default:
                await this.loadNotifications();
                break;
        }
    }

    async loadNotifications(showArchived = false): Promise<void> {
        this.isLoadingSource.next(true);

        let request = Notification.where('notifiable_id', this.auth.employee.id)
            .orderBy('id', 'DESC')
            .with('category')
            .where('isArchived', showArchived)
            .page(this.currentPage);

        // If user has selected unread keep that option
        if (this.notificationType === 'unread') {
            request = request.where('isRead', false);
        }

        request = this.addFiltersToRequest(request);

        try {
            const [notifications, meta] = await request.get();

            this.totalNumberOfNotifications = meta.pagination.total;
            this.notifications = this.shouldConcat ? [...this.notifications, ...notifications] : notifications;
            this.notificationsSource.next(this.notifications);
            this.analyticService.trackEvent(AnalyticEvents.InboxAllClicked);
        } catch (error) {
            this.notify.error('Failed retrieving notifications.');
        } finally {
            this.isLoadingSource.next(false);
        }
    }

    private updateFilterArray(filters: LocalStorageFilters[], newFilter: LocalStorageFilters): LocalStorageFilters[] {
        const existingFilterIndex = filters.findIndex((filter) => filter.id === newFilter.id);

        if (existingFilterIndex !== -1) {
            // Update existing filter
            filters[existingFilterIndex].filterValues = newFilter.filterValues;
        } else {
            // Add new filter
            filters.push(newFilter);
        }

        return filters;
    }

    private async loadUnreadNotifications(showArchived = false): Promise<void> {
        this.isLoadingSource.next(true);
        try {
            let request = Notification.where('notifiable_id', this.auth.employee.id)
                .orderBy('id', 'DESC')
                .with('category')
                .where('isArchived', showArchived)
                .where('isRead', false)
                .page(this.currentPage);

            request = this.addFiltersToRequest(request);

            const [notifications, meta] = await request.get();

            this.notifications = this.shouldConcat ? [...this.notifications, ...notifications] : notifications;
            this.totalNumberOfUnreadNotifications = meta.pagination.total;
            this.totalNumberOfNotifications = meta.pagination.total;
            this.notificationsSource.next(this.notifications);
            this.analyticService.trackEvent(AnalyticEvents.InboxUnreadOnlyClicked);
        } catch (error) {
            this.notify.error(error);
        } finally {
            this.isLoadingSource.next(false);
        }
    }
    /*
        Adds the proper where clauses based on filters set by user
    */
    private addFiltersToRequest(request: QueryFetcher): QueryFetcher {
        if (this.filterOptions?.dateRangeData?.from && this.filterOptions?.dateRangeData?.to) {
            request = request.where('createdAt', {
                from: this.filterOptions.dateRangeData.from,
                to: this.filterOptions.dateRangeData.to,
            });
        }

        if (this.filterOptions?.categories?.length) {
            request = request.whereIn(
                'categoryId',
                this.filterOptions?.categories.map((category) => category.id)
            );
        }

        return request;
    }

    private async getSavedFilters(): Promise<LocalStorageFilters[]> {
        return (
            (await this.localStorage.retrieve<LocalStorageFilters[]>(localStorageDashboardNotificationFiltersKey)) ?? []
        );
    }
}
