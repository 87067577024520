import Video from '@models/videos/video.model';
import * as Sentry from '@sentry/angular';
import YouTubeVideoPlayer from '@videos/services/video-players/youtube-video-player';
import VideoErrorCallbackInterface from '@videos/interfaces/video-error-callback.interface';
import VideoReadyCallbackInterface from '@videos/interfaces/video-ready-callback.interface';
import VideoStateChangeCallbackInterface from '@videos/interfaces/video-state-change-callback.interface';
import { Injectable } from '@angular/core';
import VideoPlayerInterface from '@videos/interfaces/video-player.interface';

@Injectable()
export default class VideoPlayerService {
    protected player: VideoPlayerInterface;

    constructor() {
        this.player = new YouTubeVideoPlayer();
    }

    init(playerId: string, video: Video, width?: number, height?: number) {
        this.player.init(playerId, video.externalId, width, height);
    }

    onReady(callback: VideoReadyCallbackInterface) {
        this.player.onReady(callback);
    }

    onStateChange(callback: VideoStateChangeCallbackInterface) {
        this.player.onStateChange(callback);
    }

    onError(callback: VideoErrorCallbackInterface) {
        this.player.onError((error) => {
            callback(error);

            Sentry.captureException(error, {
                tags: {
                    event: 'video-player-error',
                },
            });
        });
    }
}
