<div class="search-contents flex align-center space-between middle margin-y-3" *ngIf="showSearch || showSaveState">
    <app-search
        #searchBar
        placeholder="search"
        (onSearch)="onSearch($event)"
        [initialValue]=""
        *ngIf="showSearch"
    ></app-search>
    <div class="state-indicator" *ngIf="showSaveState">
        <p *ngIf="displayErrorState; else displaySaveState" class="error-indicator">{{ errorString }}</p>
        <ng-template #displaySaveState>
            <p class="saving-indicator">
                {{ (allChangesSaved ? 'components.ag-grid.allChangesSaved' : 'components.ag-grid.saving') | translate }}
            </p></ng-template
        >
    </div>
</div>
<div>
    <ag-grid-angular
        class="ag-theme-material"
        style="width: 100%; height: 55vh"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [undoRedoCellEditing]="undoRedoCellEditing"
        (cellEditingStarted)="onCellEditingStarted($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        (processCellFromClipboard)="onCellPasted($event)"
        (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
</div>
