import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
@Injectable({ providedIn: 'root' })
export class TranslationStorageService {
    set locale(lang: string) {
        localStorage.setItem('locale', lang);
    }

    get locale(): string {
        return localStorage.getItem('locale') ?? environment.locale;
    }

    getLocale(): string {
        // WE WILL HAVE TO UPDATE LOCALES IN ASSETS AND ALL ACROSS WHERE _ IS USED AS A SEPARATOR
        return this.locale === 'fr_CA' ? 'fr-CA' : 'en-CA';
    }
}
