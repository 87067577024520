import { ModelMixin } from '../core/base-generic.model';

/**
 * This class is a base class that all settings can extend. It's useful for unifying different settings we've created in the backend with different value types.
 * For all boolean togglable settings CompanySettings should be used. Other custom setting types can extend this class.
 * @param defaultValue the value to populate for the setting if the setting is undefined/unset in the backend
 * @template TReturn the class being extended. This is only required because of the same requirement on ModelMixin.
 * @template TValue the type of the value you expect returned for the setting in the backend
 */
// TypeScript can infer the return type of this function. Because it's not yet in scope, we can't explicitly define the return type
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function CreateBaseSetting<TReturn, TValue>(defaultValue: TValue) {
    class BaseSetting extends ModelMixin<TReturn>() {
        protected static _serializeAttributes = ['value'];

        get value(): TValue {
            return this._attributes['value'] ?? defaultValue;
        }

        set value(val: TValue) {
            this._attributes['value'] = val;
        }
    }
    return BaseSetting;
}
