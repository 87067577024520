import { FunnelTemplateStage } from '@models/recruiting/funnel-template-stages.model';
import { JobFunnelMessageTemplate } from '@models/recruiting/job-funnel-message-template.model';
import { ModelMixin } from '../core/base-generic.model';

export class FunnelMessageTemplate extends ModelMixin<FunnelMessageTemplate>() {
    protected static _resource = 'applicantTracker/funnelMessageTemplates';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _serializeAttributes = ['atsFunnelTemplateStageId', 'replyTo', 'subject', 'body'];

    get funnelTemplateStage(): FunnelTemplateStage {
        return this.hasOne(FunnelTemplateStage, 'funnelTemplateStage');
    }

    set funnelTemplateStage(value: FunnelTemplateStage) {
        this.setOne('funnelTemplateStage', value, 'atsFunnelTemplateStageId');
    }

    get atsFunnelTemplateStageId(): number {
        return this._attributes['atsFunnelTemplateStageId'];
    }

    set atsFunnelTemplateStageId(value: number) {
        this._attributes['atsFunnelTemplateStageId'] = value;
    }

    get replyTo(): string {
        return this._attributes['replyTo'];
    }

    set replyTo(val: string) {
        this._attributes['replyTo'] = val;
    }

    get subject(): string {
        return this._attributes['subject'];
    }

    set subject(val: string) {
        this._attributes['subject'] = val;
    }

    get body(): string {
        return this._attributes['body'];
    }

    set body(val: string) {
        this._attributes['body'] = val;
    }

    toJobFunnelMessageTemplate(atsJobFunnelId?: number): JobFunnelMessageTemplate {
        return new JobFunnelMessageTemplate({
            atsJobFunnelId: atsJobFunnelId,
            replyTo: this.replyTo,
            subject: this.subject,
            body: this.body,
        });
    }
}
