<div *ngIf="!status?.error" class="ui basic segment flex col align-items-center">
    <div class="flex align-items-center justify-space-between" *ngIf="showingControls">
        <ui-button *ngIf="showAcknowledgementButton" type="link" (click)="onAcknowledge()">Acknowledge</ui-button>
        <app-pagination
            [limit]="1"
            [total]="totalPages"
            [current]="page"
            [hideRange]="true"
            (pageChange)="loadPage($event)"
        ></app-pagination>
    </div>
    <div data-testid="viewer-canvas">
        <canvas></canvas>
    </div>
</div>
