import { Model } from '../core/base.model';
import { File } from '@models/common/file.model';
import { TrainingProgramRecord } from '@models/training/training-program-record.model';
import { HasFileModel } from '@app/components/file-uploader/has-file.inferface';

export class TrainingProgramRecordFile extends Model implements HasFileModel {
    protected static _resource = 'training/trainingProgramRecords/:trainingProgramRecord/TrainingProgramRecordFile';
    protected static _version = 'v2';

    get file(): File {
        return this.hasOne(File, 'file');
    }

    get record(): TrainingProgramRecord {
        return this.hasOne(TrainingProgramRecord, 'trainingProgramRecord');
    }
}
