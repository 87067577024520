import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { InteractableColumn } from '@app/modules/table/interfaces/interactable-column.interface';
import { EmployeeDeduction } from '@models/payroll/employee/deduction.model';
import { DropdownColumn } from '@app/modules/table/interfaces/dropdown-column.interface';
import { debounce } from 'lodash-es';

const dropdownOptions = [
    { label: '$', value: 'dollar' },
    { label: '%', value: 'percent' },
];

const DEBOUNCE_TIME = 1000;

export class EmployeePremiumColumn extends Column implements InteractableColumn, DropdownColumn {
    title = 'Employee Premium';
    property = 'amount';
    type: CellDisplay = CellDisplay.rateSelect;

    onRowChange = debounce(async (row: EmployeeDeduction) => {
        await row.param('deduction', this.deductionId).param('company', this.companyId).save();
    }, DEBOUNCE_TIME);

    constructor(
        private deductionId: number,
        private companyId: number
    ) {
        super();
    }

    getDropdownOptions(_: EmployeeDeduction): { label: string; value: string }[] {
        return dropdownOptions;
    }

    getBindProperty(): string {
        return 'amount';
    }

    getSecondaryBindProperty(): string {
        return 'calculatorType';
    }
}
