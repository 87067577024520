import InvalidVideoUrlError from '@videos/errors/invalid-video-url.error';
import VideoNotFoundError from '@videos/errors/video-not-found.error';
import VideoNotEmbeddableError from '@videos/errors/video-not-embeddable.error';
import VideoPlayerInterface from '@videos/interfaces/video-player.interface';
import VideoErrorCallbackInterface from '@videos/interfaces/video-error-callback.interface';
import VideoStateChangeCallbackInterface from '@videos/interfaces/video-state-change-callback.interface';
import VideoReadyCallbackInterface from '@videos/interfaces/video-ready-callback.interface';
import { Injectable } from '@angular/core';
import { VideoPlayerState } from '@videos/enums/video-player-state';

declare const YT;

@Injectable()
export default class YouTubeVideoPlayer implements VideoPlayerInterface {
    protected player;
    protected playerId: string;
    protected videoId: string;

    protected static apiReady: Promise<void>;

    protected _onReady: VideoReadyCallbackInterface = () => {};
    protected _onStateChange: VideoStateChangeCallbackInterface = () => {};
    protected _onError: VideoErrorCallbackInterface = () => {};

    async init(playerId: string, videoId: string, width?: number, height?: number) {
        this.playerId = playerId;
        this.videoId = videoId;

        await this.loadYouTubeApi();

        this.player = new YT.Player(playerId, {
            height: height,
            width: width,
            videoId: videoId,
            playerVars: {
                playsinline: 1,
                rel: 0,
                origin: window.origin,
            },
            events: {
                onError: (event) => this.handleError(event),
                onReady: () => this.handleReady(),
                onStateChange: (event) => this.handleStateChange(event.data),
            },
        });
    }

    async loadYouTubeApi(): Promise<void> {
        if (YouTubeVideoPlayer.apiReady) {
            return YouTubeVideoPlayer.apiReady;
        }

        const tag = document.createElement('script');
        tag.id = 'youtube-iframe-api';
        tag.src = 'https://www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        YouTubeVideoPlayer.apiReady = new Promise((resolve) => {
            // @ts-ignore
            window.onYouTubeIframeAPIReady = () => {
                resolve(null);
            };
        });

        return YouTubeVideoPlayer.apiReady;
    }

    onReady(callback: VideoReadyCallbackInterface) {
        this._onReady = () => callback();
    }

    onStateChange(callback: VideoStateChangeCallbackInterface) {
        this._onStateChange = (state) => callback(state);
    }

    onError(callback: VideoErrorCallbackInterface) {
        this._onError = (error) => callback(error);
    }

    protected handleReady() {
        this._onReady();
    }

    protected handleStateChange(state: VideoPlayerState) {
        this._onStateChange(state);
    }

    protected handleError(event: any) {
        let error;

        switch (event.data) {
            case 2:
                error = new InvalidVideoUrlError('Invalid video URL.', this.videoId);
                break;
            case 100:
                error = new VideoNotFoundError('A video was not found.', this.videoId);
                break;
            case 101:
                error = new VideoNotEmbeddableError('Video is not embeddable.', this.videoId);
                break;
            default:
                error = new Error(`Unexpected error loading video: ${this.videoId}`);
                break;
        }

        this._onError(error);
    }
}
