import { Model } from '@models/core/base.model';
import moment from 'moment';
import { TimeOffBlackoutPeriodEvent } from '@models/time-off-v3/time-off-blackout-period-event.model';

export class TimeOffRequestBlackoutPeriod extends Model {
    protected static _resource = 'timeOffV3/blackoutPeriods';

    protected static _type = 'timeOffRequestBlackoutPeriods';

    protected static _version = 'v2';

    protected static _dates = ['startDate', 'endDate'];

    protected static _serializeAttributes = ['workScheduleId', 'name', 'startDate', 'endDate'];

    get workScheduleId(): number {
        return this._attributes['workScheduleId'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get startDate(): Date {
        return this._attributes['startDate'];
    }

    get endDate(): Date {
        return this._attributes['endDate'];
    }

    set workScheduleId(val: number) {
        this._attributes['workScheduleId'] = val;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    set startDate(val: Date) {
        this._attributes['startDate'] = val;
    }

    set endDate(val: Date) {
        this._attributes['endDate'] = val;
    }

    toCalendarEvent(): TimeOffBlackoutPeriodEvent {
        return new TimeOffBlackoutPeriodEvent({
            description: this.name,
            startAt: moment(this.startDate),
            endAt: moment(this.endDate),
        });
    }
}
