import { Employee } from '@models/employee/employee.model';
import { ModelMixin } from '@models/core/base-generic.model';

/**
 * This model is not setup to persist. Only for retrieving data.
 */
export class EmployeeTimeOffPolicies extends ModelMixin<EmployeeTimeOffPolicies>() {
    protected static _version = 'v2';

    protected static _type = 'employeeTimeOffPolicies';

    get effectiveAt(): string | null {
        return this._attributes['effectiveAt'];
    }

    get effectiveTo(): string | null {
        return this._attributes['effectiveTo'];
    }

    get simpleEmployee(): Employee {
        return this.hasOne(Employee, 'simpleEmployee');
    }
}
