import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/services';
import { Payroll } from '@models/payroll/payroll.model';
import { WcbBackfill, WcbBackfillPayload, WcbBackfillStatus } from '@payroll/interfaces/wcb-backfill.interface';
import { PayrollResources } from '@payroll/payroll.resources';
import * as Sentry from '@sentry/angular';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This class checks Payroll every five seconds to see if there are any ongoing WCB backfills
 * (these run when a client turns on the WCB feature). If the response is true, it will
 * check more frequently (every second), until a false response is received.
 *
 * At some point, we may want to consider leveraging sockets in this service.
 */
@Injectable()
export class CheckForWcbBackfillService implements OnDestroy {
    private isChecking = false;
    private subject$ = new Subject<boolean>();

    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    /**
     * Stop polling Payroll when this object is destroyed
     */
    ngOnDestroy(): void {
        this.stopCheckingBackfillStatus();
    }

    /**
     * Begin polling Payroll for WCB backfill status
     */
    checkBackfillStatusOf$(payroll: Payroll): Observable<boolean> {
        this.isChecking = true;
        this.checkForBackfill(payroll.id);

        return this.subject$.asObservable();
    }

    /**
     * Stop polling Payroll
     */
    stopCheckingBackfillStatus(): void {
        this.isChecking = false;
    }

    /**
     * Do a backfill check
     *
     * This method clears the current timeout, calls the endpoint, updates the subject, and sets a new timeout if needed.
     * If we return all is done we will call stopCheckingBackfillStatus
     */
    private async checkForBackfill(payrollId: number): Promise<void> {
        if (!this.isChecking) return;

        const wcbBackfill = await this.callPayrollEndpoint(payrollId);

        // We check `isChecking` here a second time, as it's possible for its
        // value to have changed while we were awaiting the backend call
        if (!this.isChecking) return;

        if (!!wcbBackfill && [WcbBackfillStatus.DONE, WcbBackfillStatus.PENDING].includes(wcbBackfill.state)) {
            this.subject$.next(false);
            this.stopCheckingBackfillStatus();
            return;
        }

        const areBackfillsInProgress = !!wcbBackfill && wcbBackfill.state === WcbBackfillStatus.IN_PROGRESS;

        this.subject$.next(areBackfillsInProgress);

        // We can tweak these values if necessary / desired
        const delay = areBackfillsInProgress ? 1000 : 10000;
        setTimeout(() => this.checkForBackfill(payrollId), delay);
    }

    /**
     * Make the call to Payroll to get the WCB backfill status objects
     */
    private callPayrollEndpoint(payrollId: number): Promise<WcbBackfill | void> {
        const endpoint = PayrollResources.WcbBackfillStatus.replace(
            ':company_id',
            this.auth.company!.id.toString()
        ).replace(':payroll_id', payrollId.toString());

        return (
            this.http
                .get<WcbBackfillPayload>(endpoint)
                .pipe(map(({ data }) => data.attributes))
                .toPromise()
                // If the call fails, don't worry about it. Payroll-side
                // validation will take care of the rest.
                .catch((e) => {
                    if (e?.status != HttpStatusCode.NotFound) {
                        Sentry.captureException({
                            name: "Couldn't fetch WCB backfill status from payroll",
                            message: e,
                        });
                    }
                })
        );
    }
}
