<div
    class="ui {{ size }} {{ shape }} image"
    *ngIf="employee"
    [class.faded]="faded"
    [class.text]="!avatarId"
    [class.show-border]="showBorder"
    role="presentation"
>
    <img *ngIf="avatarUrl" alt="" [src]="avatarUrl | secure" (load)="onLoad()" />
    <ng-container *ngIf="!employee?.avatarId">
        <span
            >{{ getInitial(employee.firstName || employee.first_name)
            }}{{ getInitial(employee.lastName || employee.last_name) }}</span
        >
    </ng-container>
</div>

<div *ngIf="name" class="employeeName">
    <ng-container *ngIf="fullName; else firstAndLast"> {{ fullName }} </ng-container>
</div>

<!-- Separating the first and last name into their own elements allows them to be displayed on one line if there's enough room.
But to truncate separately (with ellipses) if they're on separate lines-->
<ng-template #firstAndLast>
    <div>{{ employee?.firstName }}&nbsp;</div>
    <div>{{ employee?.lastName }}</div>
</ng-template>
