import { ModelMixin } from '../core/base-generic.model';

/**
 * Used to resume an in-progress inquiry
 */
export class PersonaInquiryToken extends ModelMixin<PersonaInquiryToken>() {
    get inquiryId(): string {
        return this._attributes['inquiryId'];
    }

    get sessionToken(): string {
        return this._attributes['sessionToken'];
    }

    protected static _serializeAttributes = ['inquiryId', 'sessionToken'];

    protected static _resource = 'commonComponents/inquiryToken' as const;
    protected static _type = 'PersonaInquiryToken';
    protected static _version = 'v2';
}
