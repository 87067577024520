import { HttpErrorResponse } from '@angular/common/http';

export class ForbiddenError extends Error {
    httpStatus = 403;

    constructor(res: HttpErrorResponse, message = 'Forbidden') {
        super(message ? message : res.statusText);
        Object.setPrototypeOf(this, ForbiddenError.prototype);
    }
}
