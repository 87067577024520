import { Model } from '@models/core/base.model';

export class TaskTemplateSubtask extends Model {
    protected static _resource = 'taskTemplates/:taskTemplate/subtasks';
    protected static _version = 'v2';
    protected static _type = 'taskTemplateSubtasks';

    protected static _serializeAttributes = ['description', 'order_by'];

    get taskTemplateId(): string {
        return this._attributes['taskTemplateId'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get orderBy(): number {
        return this._attributes['order_by'];
    }

    set orderBy(val: number) {
        this._attributes['order_by'] = val;
    }
}
