import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { fieldLocationToComponent } from '@import/enums/custom-field-locations.enum';

@Injectable()
export class FieldGroupsService {
    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    async bulkUpdateOrder(groupIds: number[], component: fieldLocationToComponent): Promise<void> {
        const orderingEndpoint = '/v2/companyManagement/companies/:companyId/dataFieldGroups/order';

        await this.http
            .post(orderingEndpoint.replace(':companyId', this.auth.company.id.toString()), {
                data: {
                    fieldGroupIds: groupIds,
                    component,
                },
            })
            .toPromise();
    }

    async bulkUpdateComponent(groupIds: number[], value: fieldLocationToComponent): Promise<void> {
        const locationUpdateEndpoint = '/v2/companyManagement/companies/:companyId/dataFieldGroups/locations';
        await this.http
            .post(locationUpdateEndpoint.replace(':companyId', this.auth.company.id.toString()), {
                data: {
                    fieldGroupIds: groupIds,
                    value: value,
                },
            })
            .toPromise();
    }
}
