import { ModelMixin } from '@models/core/base-generic.model';
import {
    CraPayrollAccount,
    ExpectedRecall,
    InsurableEarning,
    OtherMoney,
    PayPeriod,
    ReasonForIssuing,
    RoeEmployee,
    SpecialPayment,
    StatutoryHoliday,
    VacationPay,
} from '../../modules/payroll/interfaces';
import { PayrollEmployee } from './payroll-employee.model';

export type RoeState = 'open' | 'processing' | 'pending' | 'submitted' | 'failed' | 'nonhumi';

export class Roe extends ModelMixin<Roe>() {
    protected static _resource = 'payroll/companies/:company/roes';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'state',
        'submittedAt',
        'finalPayPeriodEndingDate',
        'comments',
        'firstDateWorked',
        'lastDateForWhichPaid',
        'totalInsurableHours',
        'craPayrollAccount',
        'expectedRecall',
        'insurableEarnings',
        'otherMonies',
        'specialPayments',
        'statutoryHolidays',
        'vacationPay',
        'payPeriodType',
        'payPeriods',
        'reasonForIssuing',
        'roeEmployee',
        'allowAutomaticRoeSubmissions',
    ];

    get state(): RoeState {
        return this._attributes['state'];
    }
    get submittedAt(): string {
        return this._attributes['submittedAt'];
    }
    get finalPayPeriodEndingDate(): string {
        return this._attributes['finalPayPeriodEndingDate'];
    }
    get comments(): string {
        return this._attributes['comments'];
    }
    get firstDateWorked(): string {
        return this._attributes['firstDateWorked'];
    }
    get lastDateForWhichPaid(): string {
        return this._attributes['lastDateForWhichPaid'];
    }
    get totalInsurableHours(): string {
        return this._attributes['totalInsurableHours'];
    }
    get craPayrollAccount(): CraPayrollAccount {
        return this._attributes['craPayrollAccount'];
    }
    get expectedRecall(): ExpectedRecall {
        return this._attributes['expectedRecall'];
    }
    get insurableEarnings(): InsurableEarning[] {
        return this._attributes['insurableEarnings'];
    }
    get otherMonies(): OtherMoney[] {
        return this._attributes['otherMonies'];
    }
    get specialPayments(): SpecialPayment[] {
        return this._attributes['specialPayments'];
    }
    get statutoryHolidays(): StatutoryHoliday[] {
        return this._attributes['statutoryHolidays'];
    }
    get vacationPay(): VacationPay {
        return this._attributes['vacationPay'];
    }
    get payPeriodType(): string {
        return this._attributes['payPeriodType'];
    }
    get payPeriods(): PayPeriod[] {
        return this._attributes['payPeriods'];
    }
    get reasonForIssuing(): ReasonForIssuing {
        return this._attributes['reasonForIssuing'];
    }
    get roeEmployee(): RoeEmployee {
        return this._attributes['roeEmployee'];
    }
    get allowAutomaticRoeSubmissions(): boolean {
        return this._attributes['allowAutomaticRoeSubmissions'];
    }

    set comments(val: string) {
        this._attributes['comments'] = val;
    }
    set totalInsurableHours(val: string) {
        this._attributes['totalInsurableHours'] = val;
    }
    set craPayrollAccount(val: CraPayrollAccount) {
        this._attributes['craPayrollAccount'] = val;
    }
    set expectedRecall(val: ExpectedRecall) {
        this._attributes['expectedRecall'] = val;
    }
    set insurableEarnings(val: InsurableEarning[]) {
        this._attributes['insurableEarnings'] = val;
    }
    set otherMonies(val: OtherMoney[]) {
        this._attributes['otherMonies'] = val;
    }
    set specialPayments(val: SpecialPayment[]) {
        this._attributes['specialPayments'] = val;
    }
    set statutoryHolidays(val: StatutoryHoliday[]) {
        this._attributes['statutoryHolidays'] = val;
    }
    set vacationPay(val: VacationPay) {
        this._attributes['vacationPay'] = val;
    }
    set payPeriodType(val: string) {
        this._attributes['payPeriodType'] = val;
    }
    set payPeriods(val: PayPeriod[]) {
        this._attributes['payPeriods'] = val;
    }
    set reasonForIssuing(val: ReasonForIssuing) {
        this._attributes['reasonForIssuing'] = val;
    }
    set firstDateWorked(val: string) {
        this._attributes['firstDateWorked'] = val;
    }
    set finalPayPeriodEndingDate(val: string) {
        this._attributes['finalPayPeriodEndingDate'] = val;
    }
    set lastDateForWhichPaid(val: string) {
        this._attributes['lastDateForWhichPaid'] = val;
    }
    isSubmitted(): boolean {
        return this._attributes['state'] === 'submitted';
    }
    recallCodeIsY(): boolean {
        return this.expectedRecall['code'] === 'Y';
    }
    employeeFullName(): string {
        return this.roeEmployee.firstName + ' ' + this.roeEmployee.lastName;
    }

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get employee(): PayrollEmployee | null {
        return this.hasOne(PayrollEmployee, 'employee');
    }
}
