import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-radio-toggle',
    templateUrl: './radio-toggle.template.html',
    styleUrls: ['./radio-toggle.style.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RadioToggleComponent,
            multi: true,
        },
    ],
})
export class RadioToggleComponent implements ControlValueAccessor, OnInit {
    @Input() name: string;
    @Input() disabled = false;
    @Input() positiveLabel = 'yes';
    @Input() negativeLabel = 'no';
    toggle = false;

    onChange: (_: any) => void;
    onTouched: (_: any) => void;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        setTimeout(() => {
            $(this.element.nativeElement)
                .find('.checkbox')
                .checkbox({
                    onChange: () => {
                        const val = $(this.element.nativeElement).find('input:checked').val();
                        if (val === 'true') {
                            this.onChange(true);
                        }

                        if (val === 'false') {
                            this.onChange(false);
                        }
                    },
                });
        });
    }

    writeValue(toggle: boolean | number): void {
        this.toggle = Boolean(toggle);
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: (_: any) => void): void {
        this.onTouched = fn;
    }
}
