import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { PayrollResources } from '@payroll/payroll.resources';

export interface T4Notifications {
    employees: Array<{
        name: string;
        hrEmployeeId: number;
        id: number;
        boxes: number[];
    }>;
}

@Injectable()
export class T4NotificationsService {
    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    async getNotifications(t4SummaryId: string | number): Promise<T4Notifications> {
        const { data } = await this.http
            .get<{
                data: { attributes: T4Notifications };
            }>(
                PayrollResources.T4SummaryNotifications.replace(':company', this.auth.company.id.toString()).replace(
                    ':t4_summary',
                    t4SummaryId.toString()
                )
            )
            .toPromise();

        return data?.attributes;
    }
}
