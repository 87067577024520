import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import moment from 'moment';

@Directive({
    selector: '[appDayMonthString]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DayMonthStringValidator, multi: true }],
})
export class DayMonthStringValidator implements Validator {
    validate(control: AbstractControl): { [key: string]: boolean } {
        const dateString = control.value;

        if (!dateString) {
            return null;
        }

        const fullDateString = `${new Date().getFullYear()}-${dateString.substring(3)}-${dateString.substring(0, 2)}`;

        // check that the date reads as valid
        // and will work properly with legacy back end (must be exactly 5 characters)
        if (dateString.length === 5 && moment(fullDateString).isValid()) {
            return null;
        }

        // if invalid
        return { dayMonthString: true };
    }
}
