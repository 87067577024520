import { YtdColumnSettings } from '@app/modules/payroll/interfaces/ytd-column-settings.interface';
import { YTDItem } from '@app/modules/payroll/interfaces/ytditem.interface';
import { Model } from '@models/core/base.model';
import { AdditionalPayItem } from './additional-pay-item.model';
import { DeductionItem } from './deduction-item.model';
import { EmployeeBenefitBenefitItem } from './employee-benefit-benefit-item.model';
import { EmployeeBenefitDeductionItem } from './employee-benefit-deduction-item.model';
import { EmployeeDeductionItem } from './employee-deduction-item.model';
import { PayableItem } from './payable-item.model';
import { VacationPayItem } from './vacation-pay-item.model';

export class Ytd extends Model {
    protected static _version = 'v2';
    protected static _resource = 'payroll/employees/ytds/list';

    /**
     * Cached YTD column settings
     */
    private _columnSettings?: YtdColumnSettings;

    set columnSettings(value: YtdColumnSettings) {
        this._columnSettings = value;
    }

    static deductionItemYtdDefaults(columnSettings: YtdColumnSettings): YTDItem[] {
        return [
            { prop: 'cpp', name: 'CPP', payee: 'cra' },
            { prop: 'cpp2', name: 'CPP2', payee: 'cra' },
            ...(columnSettings.qpp ? [{ prop: 'qpp', name: 'QPP', payee: 'rq' }] : []),
            ...(columnSettings.qpp2 ? [{ prop: 'qpp2', name: 'QPP2', payee: 'rq' }] : []),
            ...(columnSettings.qpip ? [{ prop: 'qpip', name: 'QPIP', payee: 'rq' }] : []),
            { prop: 'ei', name: 'EI', payee: 'cra' },
            ...(columnSettings.income_tax ? [{ prop: 'incomeTax', name: 'Income Tax', payee: 'cra' }] : []),
            ...(columnSettings.income_tax_federal
                ? [{ prop: 'incomeTaxFederal', name: 'Income Tax - federal', payee: 'cra' }]
                : []),
            // provincial income tax's payee is updated as need on the rails side see PAY-2121
            ...(columnSettings.income_tax_provincial
                ? [{ prop: 'incomeTaxProvincial', name: 'Income Tax - provincial', payee: 'cra' }]
                : []),
            { prop: 'otherDeductions', name: 'Other Deductions', payee: null },
        ];
    }

    static payableItemYtdDefaults(columnSettings: YtdColumnSettings): YTDItem[] {
        return [
            { prop: 'employerCpp', name: 'Employer CPP', payee: 'cra' },
            { prop: 'employerCpp2', name: 'Employer CPP2', payee: 'cra' },
            ...(columnSettings.employer_qpp ? [{ prop: 'employerQpp', name: 'Employer QPP', payee: 'rq' }] : []),
            ...(columnSettings.employer_qpp2 ? [{ prop: 'employerQpp2', name: 'Employer QPP2', payee: 'rq' }] : []),
            ...(columnSettings.employer_qpip ? [{ prop: 'employerQpip', name: 'Employer QPIP', payee: 'rq' }] : []),
            { prop: 'employerEi', name: 'Employer EI', payee: 'cra' },
        ];
    }

    // These values are only a subset of the existing values. The full liste is
    // found in payroll under: Paystub::CALCULATED_TAX_VALUE_KEYS
    static calculatedTaxValuesExposed(columnSettings: YtdColumnSettings): YTDItem[] {
        return [
            ...(columnSettings.calculated_tax_values.f5b ? [{ prop: 'f5b', name: 'F5B', payee: null }] : []),
            ...(columnSettings.calculated_tax_values.qc_csb ? [{ prop: 'qc_csb', name: 'CSB', payee: null }] : []),
        ];
    }

    get deductionItemYtdDefaults(): YTDItem[] {
        if (!this._columnSettings) {
            throw new Error('Column settings not provided. Cannot determine deduction item defaults.');
        }
        return Ytd.deductionItemYtdDefaults(this._columnSettings);
    }

    get payableItemYtdDefaults(): YTDItem[] {
        if (!this._columnSettings) {
            throw new Error('Column settings not provided. Cannot determine payable item defaults.');
        }
        return Ytd.payableItemYtdDefaults(this._columnSettings);
    }

    get defaultItemProperties(): { paystubId: number; amount: number } {
        return { paystubId: this.paystubId, amount: 0 };
    }

    get fullName(): string {
        return `${this._attributes['firstName']} ${this._attributes['lastName']}`;
    }

    get fullNameInReverseOrder(): string {
        return `${this._attributes['lastName']}, ${this._attributes['firstName']}`;
    }

    get paystubId(): number {
        return this._attributes['paystubId'];
    }

    get regularPay(): AdditionalPayItem {
        return this.getAdditionalPayItem('regular_pay', 'Regular Pay');
    }

    get vacationPay(): VacationPayItem {
        return this.getVacationPayItem('vacation pay', 'Vacation Pay');
    }

    get timeOffOnboardingYtdBalance(): number {
        return this._attributes['timeOffOnboardingYtdBalance'];
    }

    get jurisdiction(): string {
        return this._attributes['jurisdiction'] ?? '';
    }

    get cpp(): DeductionItem {
        return this.getDeductionItem('cpp');
    }

    get cpp2(): DeductionItem {
        return this.getDeductionItem('cpp2');
    }

    get qpp(): DeductionItem {
        return this.getDeductionItem('qpp');
    }

    get qpp2(): DeductionItem {
        return this.getDeductionItem('qpp2');
    }

    get qpip(): DeductionItem {
        return this.getDeductionItem('qpip');
    }

    get ei(): DeductionItem {
        return this.getDeductionItem('ei');
    }

    get employerCpp(): PayableItem {
        return this.getPayableItem('employerCpp');
    }

    get employerCpp2(): PayableItem {
        return this.getPayableItem('employerCpp2');
    }

    get employerQpp(): PayableItem {
        return this.getPayableItem('employerQpp');
    }

    get employerQpp2(): PayableItem {
        return this.getPayableItem('employerQpp2');
    }

    get employerQpip(): PayableItem {
        return this.getPayableItem('employerQpip');
    }

    get employerEi(): PayableItem {
        return this.getPayableItem('employerEi');
    }

    get incomeTax(): DeductionItem {
        return this.getDeductionItem('incomeTax');
    }

    get incomeTaxFederal(): DeductionItem {
        return this.getDeductionItem('incomeTaxFederal');
    }

    get incomeTaxProvincial(): DeductionItem {
        return this.getDeductionItem('incomeTaxProvincial');
    }

    get otherDeductions(): DeductionItem {
        return this.getDeductionItem('otherDeductions');
    }

    get benefitDeductionItems(): EmployeeBenefitDeductionItem[] {
        return this.hasMany(EmployeeBenefitDeductionItem, 'benefitsDeductionItems');
    }

    set benefitDeductionItems(val: EmployeeBenefitDeductionItem[]) {
        this.setMany('benefitsDeductionItems', val);
    }

    get benefitBenefitItems(): EmployeeBenefitBenefitItem[] {
        return this.hasMany(EmployeeBenefitBenefitItem, 'benefitsBenefitItems');
    }

    set benefitBenefitItems(val: EmployeeBenefitBenefitItem[]) {
        this.setMany('benefitsBenefitItems', val);
    }

    get employeeDeductions(): EmployeeDeductionItem[] {
        return this.hasMany(EmployeeDeductionItem, 'employeeDeductions');
    }

    set employeeDeductions(val: EmployeeDeductionItem[]) {
        this.setMany('employeeDeductions', val);
    }

    get additionalPayItems(): AdditionalPayItem[] {
        return this.hasMany(AdditionalPayItem, 'additionalPayItems');
    }

    set additionalPayItems(val: AdditionalPayItem[]) {
        this.setMany('additionalPayItems', val);
    }

    get vacationPayItems(): VacationPayItem[] {
        return this.hasMany(VacationPayItem, 'additionalPayItems');
    }

    set vacationPayItems(val: VacationPayItem[]) {
        this.setMany('additionalPayItems', val);
    }

    get f5b(): { amount: string | number } {
        return this.getCalculatedTaxValue('f5b');
    }

    get qc_csb(): { amount: string | number } {
        return this.getCalculatedTaxValue('qc_csb');
    }

    get hrEmployeeId(): string {
        return this._attributes['hrEmployeeId'] ?? '';
    }

    /**
     * Return a single calculated tax value
     *
     * The return value is an object, to conform with YTD table's expectations
     */
    getCalculatedTaxValue(name: string): { amount: string | number } {
        const amount = this.calculatedTaxValues[name] ?? 0;
        return { amount };
    }

    /**
     * Set a single calculated tax value
     */
    setCalculatedTaxValue(name: string, value: string | number): void {
        this._attributes['calculatedTaxValues'] = this._attributes['calculatedTaxValues'] || {};
        this._attributes['calculatedTaxValues'][name] = value;
    }

    get calculatedTaxValues(): Record<string, string | number> {
        let calculatedTaxValues = this._attributes['calculatedTaxValues'];
        if (calculatedTaxValues != null) {
            calculatedTaxValues = Object.fromEntries(
                Object.entries(calculatedTaxValues).map(([key, value]) => {
                    // transform only alphabets in key
                    const result = key.replace(/([A-Z])/g, ' $1');
                    const newKey = result.split(' ').join('_').toLowerCase();
                    return [newKey, value];
                })
            );
        }
        return calculatedTaxValues || {};
    }

    /**
     * Gets an additional pay item, by name, from the additional pay item list
     * If such an item doesn't exist, it creates one but doesn't persist it
     */
    getAdditionalPayItem(name: string, displayName: string): AdditionalPayItem {
        const additionalPayItem = this.additionalPayItems.find((item: AdditionalPayItem) => item.name === name);

        if (additionalPayItem) {
            additionalPayItem.paystubId = this.paystubId;
            return additionalPayItem;
        }

        const newAdditionalPayItem = new AdditionalPayItem({ name, displayName, ...this.defaultItemProperties });
        this.additionalPayItems = [...this.additionalPayItems, newAdditionalPayItem];

        return newAdditionalPayItem;
    }

    getVacationPayItem(name: string, displayName: string): VacationPayItem {
        const vacationPayItem = this.vacationPayItems.find((item: VacationPayItem) => item.name === name);

        if (vacationPayItem) {
            vacationPayItem.paystubId = this.paystubId;
            return Object.assign(new VacationPayItem(), vacationPayItem);
        }

        const newVacationPayItem = new VacationPayItem({ name, displayName, ...this.defaultItemProperties });
        this.vacationPayItems = [...this.vacationPayItems, newVacationPayItem];

        return newVacationPayItem;
    }

    getDeductionItem(prop: string): DeductionItem {
        if (!this.hasOne(DeductionItem, prop)) {
            const name = this.deductionItemYtdDefaults.find((item) => prop === item.prop).name;
            // All standard deductions are statutory, except for 'other deductions' which is a catch all
            const statutory = name !== 'Other Deductions';
            const payee = this.deductionItemYtdDefaults.find((item) => prop === item.prop).payee;
            this.setOne(prop, new DeductionItem({ name, statutory, payee, ...this.defaultItemProperties }));
        }

        return this.hasOne(DeductionItem, prop);
    }

    getPayableItem(prop: string): PayableItem {
        if (!this.hasOne(PayableItem, prop)) {
            const name = this.payableItemYtdDefaults.find((item) => prop === item.prop).name;
            const payee = this.payableItemYtdDefaults.find((item) => prop === item.prop).payee;
            this.setOne(prop, new PayableItem({ name, statutory: true, payee, ...this.defaultItemProperties }));
        }

        return this.hasOne(PayableItem, prop);
    }
}
