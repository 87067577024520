import { Model } from '../core/base.model';
import { Account } from './account.model';
import { SimpleEntityActions } from '@app/classes';

export class RoleAssignment extends Model {
    static permission = new SimpleEntityActions('accountRoles');
    protected static _resource = 'accountManagement/accounts/:account/roleAssignments';
    protected static _type = 'accountRoles';
    protected static _serializeAttributes = ['roleId'];

    get roleId(): number {
        return this._attributes['roleId'];
    }

    set roleId(val) {
        this._attributes['roleId'] = val;
    }

    get accountId(): number {
        return this._attributes['accountId'];
    }

    get account(): Account {
        return this.hasOne(Account, 'account');
    }

    get isOnlyRoleAssignmentInCompany(): boolean {
        return this._attributes['isOnlyRoleAssignmentInCompany'];
    }
}
