import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { FeatureFlag, FileUploadDirectories } from '@app/enums';
import { File as FileModel } from '@app/models/common/file.model';
import { TokenService } from '@app/services/token.service';
import { UrlHelperService } from '@app/services/url-helper.service';
import { environment } from '@env/environment';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { FeatureService } from './feature.service';

type DIRECTORY_CONTEXT = 'avatar' | 'import' | 'recruiting' | 'documents' | 'general' | 'audio';

const FILE_STORE_ENDPOINT = '/v2/commonComponents/files';

@Injectable()
export class FileHelperService {
    private isUsingAuthService = false;
    constructor(
        private token: TokenService,
        private http: HttpClient,
        private urlHelper: UrlHelperService,
        private featureService: FeatureService
    ) {
        const usingAuthServicePromise = this.featureService.has(FeatureFlag.experimentalAuthService);
        usingAuthServicePromise.then((result) => (this.isUsingAuthService = result));
    }

    save(id: number, fileName: string, fileType: string): void {
        const path = this.path(id);
        this.urlHelper.getBlob(path).subscribe((data) => {
            saveAs(data, fileName + '.' + fileType);
        });
    }

    saveFile(file: FileModel): void {
        this.urlHelper.getBlob(this.path(file.id)).subscribe((data) => {
            saveAs(data, file.filename);
        });
    }

    saveFromURL(path: string, fileName: string, fileType: string): void {
        this.urlHelper.getBlob(path).subscribe((data) => {
            saveAs(data, fileName + '.' + fileType);
        });
    }

    saveFileFromResponse(response: HttpResponse<Blob>, fallbackName: string): boolean {
        const filename = this.getFilenameFromResponse(response, fallbackName);
        const blob = response.body;
        if (blob) {
            saveAs(blob, filename ?? undefined);
            return true;
        }
        return false;
    }

    savePayrollFile(
        downloadPath: string,
        fileName: string,
        fileType: string,
        params?: any,
        callback?: (err?: any) => void
    ): void {
        const path = this.pathForPayrollFiles(downloadPath);
        this.urlHelper.getBlob(path, params).subscribe(
            (data) => {
                saveAs(data, fileName + '.' + fileType);
                callback();
            },
            (err) => {
                callback(err);
            }
        );
    }

    /*
     * Directory determines which directory the file will be stored
     * Optimize determines if the file should be resized when uploaded, applies only to images
     * */
    storeFileAndGetResponseStream(
        file: File,
        directory: DIRECTORY_CONTEXT = FileUploadDirectories.avatars,
        optimize = false
    ): Observable<any> {
        const data = new FormData();
        data.append('data[type]', 'files');
        data.append('data[attributes][file]', file);
        data.append('data[attributes][context]', directory);
        data.append('data[attributes][optimize]', optimize.toString());

        return this.http.request(
            new HttpRequest('POST', environment.api + FILE_STORE_ENDPOINT, data, {
                reportProgress: true,
                responseType: 'json',
            })
        );
    }

    /*
     * Directory determines which directory the file will be stored - eg. avatar will be stored in /avatars
     * Optimize determines if the file should be resized when uploaded, applies only to images
     * */
    store(
        file: File,
        directory: DIRECTORY_CONTEXT = FileUploadDirectories.avatars,
        optimize = false
    ): Promise<FileModel> {
        return this.storeFileAndGetResponseStream(file, directory, optimize)
            .pipe(
                map((event) => {
                    if (event instanceof HttpResponse && event.ok) {
                        return new FileModel(event.body);
                    }
                }),
                filter((value) => !!value),
                catchError((error) => {
                    throw ErrorParser.parse(error);
                })
            )
            .toPromise();
    }

    path(fileId?: number): string {
        if (!fileId) {
            return '';
        }
        return environment.api + '/v2/commonComponents/files/' + fileId + '/download';
    }

    pathForPayrollFiles(path: string): string {
        if (this.isDirectlyAccessingPayroll(path)) {
            return path;
        }
        return `${environment.api}/v2/payroll/companies/${path}`;
    }

    serveWithToken(fileId: number): string {
        return (
            environment.api +
            '/v2/commonComponents/files/' +
            fileId +
            '/serve?token=' +
            encodeURIComponent(this.token.token)
        );
    }

    serve(fileId: number): string {
        return environment.api + '/v2/commonComponents/files/' + fileId + '/serve';
    }

    protected getFilenameFromResponse(response: HttpResponse<Blob>, fallbackName: string): string | null {
        if (
            response.headers.get('content-disposition') &&
            response.headers.get('content-disposition').indexOf('filename') >= 0
        ) {
            // fetch filename from headers, if set
            return response.headers
                .get('content-disposition')
                .split('filename=')[1]
                .split(';')[0]
                .replace(/['"]+/g, '');
        }
        return fallbackName;
    }

    private isDirectlyAccessingPayroll(path: string): boolean {
        if (this.isUsingAuthService) {
            return path.includes(environment.payrollUrl);
        }

        return false;
    }
}
