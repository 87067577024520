import { Model } from '../core/base.model';

import { SimpleEntityActions } from '@app/classes';
import { NotificationCategoryType } from '@app/modules/common/constants/notification-icons';
import { TranslatableKey } from '@app/types/translatable.type';

const DEFAULT = 'general';

export class NotificationCategory extends Model {
    static permission = new SimpleEntityActions('notification');

    protected static _resource = 'notificationManagement/notificationCategories';
    protected static _version = 'v2';

    private static display: { [key: string]: { name: TranslatableKey } } = {
        general: { name: 'notification-category.general' },
        timeOff: { name: 'notification-category.timeOff' },
        recruiting: { name: 'notification-category.recruiting' },
        documents: { name: 'notification-category.documents' },
        performance: { name: 'notification-category.performance' },
        feedback: { name: 'notification-category.feedback' },
        review: { name: 'notification-category.review' },
        survey: { name: 'notification-category.survey' },
        goal: { name: 'notification-category.goal' },
        announcement: { name: 'notification-category.announcement' },
        benefits: { name: 'notification-category.benefits' },
        timeTracking: { name: 'notification-category.timeTracking' },
        changeRequest: { name: 'notification-category.changeRequest' },
        offerLetter: { name: 'notification-category.offerLetter' },
        training: { name: 'notification-category.training' },
        reminder: { name: 'notification-category.reminder' },
    };

    static getGeneralDisplayName(): TranslatableKey {
        return this.display[DEFAULT].name;
    }

    get name(): NotificationCategoryType {
        return this._attributes['name'];
    }

    get displayName(): TranslatableKey {
        return NotificationCategory.display[this.name]
            ? NotificationCategory.display[this.name].name
            : NotificationCategory.display[DEFAULT].name;
    }
}
