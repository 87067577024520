@if (url) {
    @if (externalLink) {
        <a
            [target]="openInNewTab ? '_blank' : '_self'"
            class="button"
            [attr.tabindex]="disabled ? -1 : tabIndexValue"
            [attr.disabled]="disabled || loading ? true : null"
            [attr.href]="url"
            [ngClass]="buttonClass"
            [attr.aria-label]="ariaLabel"
        >
            <ng-container [ngTemplateOutlet]="label"></ng-container>
        </a>
    } @else {
        <a
            class="button"
            [attr.tabindex]="disabled ? -1 : tabIndexValue"
            [attr.disabled]="disabled || loading ? true : null"
            [routerLink]="[url]"
            [queryParams]="queryParams"
            [ngClass]="buttonClass"
            [attr.aria-label]="ariaLabel"
        >
            <ng-container [ngTemplateOutlet]="label"></ng-container>
        </a>
    }
} @else {
    @if (matMenu) {
        <button
            [attr.type]="submit ? 'submit' : 'button'"
            class="button"
            [attr.tabindex]="disabled ? -1 : tabIndexValue"
            [attr.disabled]="disabled || loading ? true : null"
            [ngClass]="buttonClass"
            [matMenuTriggerFor]="matMenu"
            [attr.aria-label]="ariaLabel"
        >
            <ng-container [ngTemplateOutlet]="label"></ng-container>
        </button>
    } @else {
        <button
            [attr.type]="submit ? 'submit' : 'button'"
            class="button"
            [attr.tabindex]="disabled ? -1 : tabIndexValue"
            [attr.disabled]="disabled || loading ? true : null"
            [ngClass]="buttonClass"
            [attr.aria-label]="ariaLabel"
        >
            <ng-container [ngTemplateOutlet]="label"></ng-container>
        </button>
    }
}

<ng-template #label>
    <mat-spinner class="margin-right-2" *ngIf="loading" diameter="20"></mat-spinner>
    <ng-content></ng-content>
</ng-template>
