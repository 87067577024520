import { StatusColors } from '@app/modules/performance/enums/status-colors';
import { Model } from '@models/core/base.model';
import { SurveyAssignment } from '@models/performance/survey-assignment.model';
import { Template } from '@models/performance/template.model';

import { SimpleEntityActions } from '@app/classes/simple-entity-actions.class';
import { BadgeType } from '@humi-design-library/components/Badge/types';
import { SurveyResponse } from '@models/performance/survey-response.model';
import { StatusBadgeTypes } from '../../modules/performance/enums/status-badge-types';
import { Status } from '../../modules/performance/enums/status.enum';
import { Employee } from '../employee/employee.model';

export class Survey extends Model {
    static permission = new SimpleEntityActions('survey');

    protected static _serializeAttributes = [
        'attach',
        'detach',
        'dueAt',
        'title',
        'description',
        'templateId',
        'isAnonymous',
        'reoccuring',
    ];

    protected static _resource = 'performanceManagement/surveys';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt', 'startAt', 'dueAt', 'completedAt'];

    /**
     *
     * @returns {SurveyAssignment[]}
     */
    get assignments(): SurveyAssignment[] {
        return this.hasMany(SurveyAssignment, 'assignments');
    }
    set assignments(val: SurveyAssignment[]) {
        this.setMany('assignments', val);
    }
    get participants(): Employee[] {
        return this.hasMany(Employee, 'participants');
    }

    set participants(val: Employee[] | null) {
        this.setMany('participants', val);
    }
    /**
     * Creator
     * @return {Job} [description]
     */
    get creator(): Employee {
        return this.hasOne(Employee, 'creator');
    }

    /* Timestamps */
    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get updatedAt(): Date {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): Date {
        return this._attributes['deletedAt'] || null;
    }

    /**
     * Ids
     */
    get companyId(): number {
        return this._attributes['companyId'];
    }
    get templateId(): number {
        return this._attributes['templateId'];
    }
    set templateId(val: number) {
        this._attributes['templateId'] = val;
    }
    get createdBy(): number {
        return this._attributes['createdBy'];
    }

    /**
     * Dates
     */
    get startAt(): Date {
        return this._attributes['startAt'];
    }

    set startAt(value: Date) {
        this._attributes['startAt'] = value;
    }

    get dueAt(): Date {
        return this._attributes['dueAt'];
    }

    set dueAt(value: Date) {
        this._attributes['dueAt'] = value;
    }

    get completedAt(): Date {
        return this._attributes['completedAt'];
    }

    set completedAt(value: Date) {
        this._attributes['completedAt'] = value;
    }

    get isComplete(): boolean {
        return this._attributes['isComplete'];
    }

    get title(): string {
        return this._attributes['title'];
    }

    set title(value: string) {
        this._attributes['title'] = value;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(value: string) {
        this._attributes['description'] = value;
    }

    get dueIn(): number {
        return this._attributes['dueIn'];
    }

    get currentValue(): number {
        return this._attributes['currentValue'];
    }

    get userResponse(): SurveyResponse | null {
        return this.hasOne(SurveyResponse, 'userResponse');
    }
    set userResponse(val: SurveyResponse | null) {
        this.setOne('userResponse', val);
    }
    get responses(): SurveyResponse[] {
        return this.hasMany(SurveyResponse, 'responses');
    }

    get userAssignment(): any {
        return this._attributes['userAssignment'];
    }
    set userAssignment(val: any) {
        this._attributes['userAssignment'] = val;
    }

    get isAnonymous(): boolean {
        return this._attributes['isAnonymous'];
    }
    set isAnonymous(val: boolean) {
        this._attributes['isAnonymous'] = val;
    }

    get statusId(): number {
        return this._attributes['statusId'];
    }

    get status(): string {
        return Status[this.statusId].replace(/([A-Z])/g, ' $1');
    }

    get statusBadgeType(): BadgeType {
        return StatusBadgeTypes[Status[this.statusId]];
    }

    get statusColor(): string {
        return StatusColors[Status[this.statusId]];
    }

    get template(): Template {
        return this.hasOne(Template, 'template');
    }

    get reoccuring(): string {
        return this._attributes['reoccuring'];
    }
    set reoccuring(val: string) {
        this._attributes['reoccuring'] = val;
    }

    get reoccuringId(): number {
        return this._attributes['reoccuringId'];
    }

    get assignmentsCount(): number {
        return this._attributes['assignmentsCount'];
    }

    get submissionsCount(): number {
        return this._attributes['submissionsCount'];
    }

    force(): Promise<any> {
        return Model.performGet(Survey._version + '/' + Survey._resource + '/' + this.id + '/force');
    }

    remind(): Promise<any> {
        return Model.performGet(Survey._version + '/' + Survey._resource + '/' + this.id + '/remind');
    }
}
