<!-- We need to sub here, nested async pipes within ngIfs do not work -->
<div *ngIf="tasks$ | async as tasks">
    <div class="wrapper" *ngIf="tasks.length && !dismissed">
        <div class="topbar flex justify-space-between align-items-center padding-y-1 padding-x-2">
            <h4 class="title">
                Active Jobs
                <span *ngIf="collapsed" class="counter margin-left-2">{{ tasks.length }}</span>
            </h4>
            <div class="actions flex align-items-center">
                <mat-icon
                    class="margin-right-2"
                    (click)="toggleCollapsed()"
                    [svgIcon]="collapsed ? 'chevronUp' : 'chevronDown'"
                ></mat-icon>
                <mat-icon class="margin-right-1" (click)="dismiss()" svgIcon="close"></mat-icon>
            </div>
        </div>

        <!-- Tasks -->
        <ng-container *ngIf="!collapsed">
            <div
                class="task flex align-items-center justify-space-between padding-x-2 padding-y-1"
                *ngFor="let task of tasks"
            >
                <div class="left">
                    <span>
                        <mat-icon *ngIf="task.completedAt" class="margin-right-1 completed" svgIcon="check"></mat-icon>
                        <mat-icon *ngIf="task.failedAt" class="margin-right-1 failed" svgIcon="error"></mat-icon>
                        <mat-icon
                            *ngIf="!task.completedAt && !task.failedAt"
                            class="margin-right-1 running"
                            svgIcon="refresh"
                        ></mat-icon>
                        {{ task.name }}</span
                    >
                </div>
                <div class="right">
                    <ui-button type="link" [url]="task.link">View</ui-button>
                    <ui-button type="link" (click)="service.markAsRead(task)">Clear</ui-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
