export enum CellDisplay {
    avatar,
    avatarOnly,
    bubbleWithText,
    button,
    colorCircle,
    date,
    dateTime,
    datepicker,
    dollar,
    dropdown,
    externalLink,
    inputNumber,
    inputText,
    internalLink,
    multiAvatar,
    badge,
    rateSelect,
    rating,
    sentiment,
    singleAndMultiAvatar,
    string,
    textArea,
    tooltip,
    multiBadge,
}
