<!-- Genders -->
<div class="app-grid">
    <mat-form-field class="columns-11">
        <mat-label appRemoveAriaOwns>{{ 'components.add-genders.genderLabel' | translate }}</mat-label>
        <mat-select
            #matSelect
            multiple
            type="text"
            placeholder="{{ 'components.add-genders.genderPlaceholder' | translate }}"
            name="defaultGendersField"
            [compareWith]="compareWith"
            (selectionChange)="onGendersSelect($event)"
            [formControl]="gendersSelectControl"
        >
            <mat-option *ngFor="let gender of defaultGendersList" [value]="gender">
                {{ gender.tag | appTranslate }}
            </mat-option>
            <mat-option (click)="onAddUserDefinedGender()">{{
                'components.add-genders.selfIdentifyLabel' | translate
            }}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Genders tooltip -->
    <ui-info class="columns-1" text="{{ 'components.add-genders.genderTooltip' | translate }}"></ui-info>
</div>

<!-- User defined genders -->
<ng-container *ngFor="let userDefinedGender of userDefinedGenders; let i = index; let first = first">
    <div class="app-grid user-defined-gender">
        <mat-form-field class="columns-10">
            <mat-label *ngIf="first">{{ 'components.add-genders.myGenderLabel' | translate }}</mat-label>
            <mat-label *ngIf="!first">{{ 'components.add-genders.and' | translate }}</mat-label>
            <input
                class="padding-top-1"
                name="userDefinedGender{{ i }}"
                type="text"
                [(ngModel)]="userDefinedGender.value"
                required
                matInput
            />
        </mat-form-field>

        <ui-button type="ghost" class="columns-2 margin-top-3 remove-gender" (click)="removeUserDefinedGender(i)">
            <mat-icon svgIcon="close"></mat-icon>{{ 'components.add-genders.removeButtonLabel' | translate }}
        </ui-button>
    </div>
</ng-container>

<!-- Hide add button if there are already 3 additional genders -->
<div class="app-grid">
    <ui-button
        class="columns-1 buttons margin-bottom-1 add-gender"
        *ngIf="userDefinedGenders.length && userDefinedGenders.length < maxUserDefinedGenders"
        type="link"
        (click)="onAddUserDefinedGender(); $event.preventDefault()"
        ><mat-icon svgIcon="plus"></mat-icon>
        {{ 'components.add-genders.addButtonLabel' | translate }}
    </ui-button>
</div>
