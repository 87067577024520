import { AvailablePeriod } from '@app/interfaces/available-period.interface';
import { PayPeriod } from '@app/interfaces/pay-period.interface';
import { ModelMixin } from '@models/core/base-generic.model';
import { addDays, compareDesc, isFuture, isPast, isSameDay } from 'date-fns';

export class Schedule extends ModelMixin<Schedule>() {
    protected static _resource = 'payroll/companies/:company/schedule';
    protected static _version = 'v2';

    get startDate(): string {
        return this._attributes['startDate'];
    }

    get frequency(): 'weekly' | 'biweekly' | 'semimonthly' | 'monthly' {
        return this._attributes['frequency'];
    }

    get dayOfTheWeek(): 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | null {
        return this._attributes['dayOfTheWeek'];
    }

    get daysOfTheMonth(): number[] {
        return this._attributes['daysOfTheMonth'];
    }

    get daysInArrears(): number {
        return this._attributes['daysInArrears'];
    }

    get periodsAvailable(): AvailablePeriod[] {
        return this._attributes['periodsAvailable'];
    }

    get allPeriods(): any[] {
        return this._attributes['allPeriods'];
    }

    get allJournalEntryReportPeriods(): any[] {
        return this._attributes['allJournalEntryReportPeriods'];
    }

    get currentPeriod(): PayPeriod | null {
        const currentYear = new Date().getFullYear();
        const currentYearPeriod = this.allPeriods.find(({ year }) => year === currentYear);

        if (!currentYearPeriod) {
            return null;
        }

        return currentYearPeriod.payPeriods.find(
            (payPeriod: PayPeriod) => isPast(payPeriod.startDate) && isFuture(payPeriod.endDate)
        );
    }

    get lastPendingPeriod(): PayPeriod | null {
        return this.allPeriods
            .reduce((acc, { payPeriods }) => [...acc, ...payPeriods], [])
            .filter(({ payrollState }) => payrollState === 'pending')
            .sort((a: PayPeriod, b: PayPeriod) => compareDesc(a.startDate, b.startDate))
            .shift();
    }

    get lastPaidPeriod(): PayPeriod | null {
        return this.allPeriods
            .reduce((acc, { payPeriods }) => [...acc, ...payPeriods], [])
            .filter(({ payrollState }) => payrollState === 'paid')
            .sort((a: PayPeriod, b: PayPeriod) => compareDesc(a.startDate, b.startDate))
            .shift();
    }

    getPeriodMatchingDates(startAt: Date, endAt: Date, allPeriodProperty: string): PayPeriod {
        return this[allPeriodProperty]
            .reduce((acc, { payPeriods }) => [...acc, ...payPeriods], [])
            .find(
                (payPeriod) =>
                    isSameDay(startAt, payPeriod.chequeDate) && isSameDay(endAt, addDays(payPeriod.chequeDate, 1))
            );
    }
    /**
     * Reverses the order of the years, quarters, months and periods
     * for display purposes
     */
    reverse(allPeriodProperty: string): void {
        this[allPeriodProperty].reverse().forEach((allPeriod) => {
            allPeriod.quarters.reverse();
            allPeriod.months.reverse();
            allPeriod.payPeriods.reverse();
        });
    }
}
