import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes/error-parser.class';
import { FeatureFlag } from '@app/enums';
import { AuthService, FileHelperService, NotifyService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { environment } from '@env/environment';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class HoursImporterService {
    constructor(
        private auth: AuthService,
        private fileHelper: FileHelperService,
        private http: HttpClient,
        private notify: NotifyService,
        private featureService: FeatureService
    ) {}

    async downloadTemplate(payrollId: number): Promise<void> {
        const path = await this.getDownloadPath(payrollId);
        return new Promise((resolve, reject) => {
            this.fileHelper.savePayrollFile(path, `hours-template`, 'csv', null, (e?: Record<string, unknown>) => {
                if (e) {
                    this.notify.error(ErrorParser.parse(e));
                    reject(e);
                } else {
                    resolve();
                }
            });
        });
    }

    async uploadHours(payrollId: number, file: File): Promise<void> {
        const uploadHoursData = new FormData();
        uploadHoursData.append('file', file);
        const url = PayrollResources.UploadHoursImporter.replace(':companyId', this.auth.company.id.toString()).replace(
            ':payrollId',
            payrollId.toString()
        );
        const uploadEmployeeHoursResultPromise = await this.http.post(url, uploadHoursData).toPromise();
        const uploadEmployeeHoursResult = uploadEmployeeHoursResultPromise['data']['attributes'];
        this.notify.success(uploadEmployeeHoursResult?.message || 'Successfully uploaded employee hours');
    }

    private async getDownloadPath(payrollId: number): Promise<string> {
        if (await this.featureService.has(FeatureFlag.experimentalAuthService)) {
            return `${environment.payrollUrl}/v2/companies/${this.auth.company.id}/payrolls/${payrollId}/hours_inout`;
        }

        return `${this.auth.company.id}/payrolls/${payrollId}/downloadHours`;
    }
}
