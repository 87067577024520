<mat-form-field [class.has-chips]="selectedItems.length > 0">
    <mat-label *ngIf="allowHtmlLabel" [innerHtml]="label | appTranslate"></mat-label>
    <mat-label *ngIf="!allowHtmlLabel">{{ label | appTranslate }}</mat-label>
    <mat-chip-list
        [class.read-only]="readOnly"
        [disabled]="disabled"
        #chipList
        aria-label="Item Selection"
        [selectable]="false"
    >
        <mat-chip *ngFor="let item of selectedItems" [selectable]="false" [removable]="true" (removed)="remove(item)">
            <mat-icon svgIcon="close" matChipRemove></mat-icon>
            {{ item.chipLabel }}
        </mat-chip>
        <input
            type="text"
            [placeholder]="placeholder | appTranslate"
            #textInput
            matInput
            (focus)="onFocus()"
            [formControl]="textControl"
            [matAutocomplete]="auto"
            [matAutocompleteDisabled]="disabled"
            [matChipInputFor]="chipList"
            [matChipInputAddOnBlur]="false"
            (matChipInputTokenEnd)="add($event)"
        />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true" (optionSelected)="selected($event)">
        <mat-option
            *ngIf="textInput.value && allowAdditions && !currentInputHasMatch"
            [value]="textInput.value"
            class="chip-preview"
            [innerHTML]="'components.chips-autocomplete.add' | translate: { item: textInput.value }"
        >
        </mat-option>
        <mat-option *ngFor="let item of filteredItems | async" [value]="item.chipLabel">
            {{ item.chipLabel }}
        </mat-option>
    </mat-autocomplete>
    <mat-icon
        class="clear-all"
        [class.hidden]="disabled || selectedItems.length < 2"
        (click)="onClear($event)"
        svgIcon="cancel"
    ></mat-icon>
</mat-form-field>
