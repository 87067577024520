import { Model } from '@models/core/base.model';
import { TimeOffApprovalFlow } from '@models/time-off-v3/time-off-approval-flow.model';
import { TimeOffApproverType } from '@models/time-off-v3/time-off-approver-type.model';
import { TimeOffApprover } from '@models/time-off-v3/time-off-approver.model';

export class TimeOffApprovalFlowStep extends Model {
    protected static _resource = 'timeOffV3/approvalFlowSteps';

    protected static _version = 'v2';

    protected static _type = 'timeOffApprovalFlowSteps';

    protected static _serializeAttributes = ['timeOffApprovalFlowId', 'timeOffApproverTypeId', 'stepIndex'];

    approverId: number;

    get timeOffApprovalFlowId(): number {
        return this._attributes['timeOffApprovalFlowId'];
    }

    get timeOffApproverTypeId(): number {
        return this._attributes['timeOffApproverTypeId'];
    }

    get stepIndex(): number {
        return this._attributes['stepIndex'];
    }

    get timeOffApprovalFlow(): TimeOffApprovalFlow {
        return this.hasOne(TimeOffApprovalFlow, 'timeOffApprovalFlow');
    }

    get timeOffApproverType(): TimeOffApproverType {
        return this.hasOne(TimeOffApproverType, 'timeOffApproverType');
    }

    get timeOffApprover(): TimeOffApprover {
        return this.hasOne(TimeOffApprover, 'timeOffApprover');
    }

    set timeOffApprovalFlowId(val: number) {
        this._attributes['timeOffApprovalFlowId'] = val;
    }

    set timeOffApproverTypeId(val: number) {
        this._attributes['timeOffApproverTypeId'] = val;
    }

    set stepIndex(val: number) {
        this._attributes['stepIndex'] = val;
    }

    hasApprover(): boolean {
        return !!this.timeOffApprover && !!this.timeOffApprover.approver;
    }
}
