import { HttpErrorResponse } from '@angular/common/http';

export class InternalServerError extends Error {
    httpStatus = 500;

    constructor(res: HttpErrorResponse, message?: string) {
        super(message ? message : res.statusText);
        Object.setPrototypeOf(this, InternalServerError.prototype);
    }
}
