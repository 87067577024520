import { Model } from '../core/base.model';

export class EmploymentType extends Model {
    protected static _resource = 'companyManagement/companies/:company/employmentTypes';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['name'];

    get name(): string | null {
        return this._attributes['name'] || null;
    }
}
