import { Injectable } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { Company } from '@app/models/company/company.model';
import { Template } from '@app/models/performance/template.model';
import { TemplateType } from '@app/modules/performance/enums/template-type.enum';
import { FeatureService } from '@app/services/feature.service';

@Injectable()
export class FetchTemplates {
    constructor(private featureService: FeatureService) {}

    async forCompany(company: Company): Promise<[Template[], any]> {
        const hasPerformanceModuleSplit = await this.featureService.has(FeatureFlag.performanceModuleSplit);

        let query = Template.param('company', company.id).where('hasQuestions', true).orderBy('name', 'ASC');

        query = hasPerformanceModuleSplit ? query.where('type', TemplateType.SURVEY) : query;

        return query.all();
    }
}
