import { SimpleEntityActions } from '@app/classes/simple-entity-actions.class';
import { Translatable } from '@app/types/translatable.type';
import { BenefitPolicy } from '@models/benefits/benefit-policy.model';
import { BenefitProvider } from '@models/benefits/benefit-provider.model';
import { Benefit } from '@models/benefits/benefit.model';
import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { startCase } from 'lodash-es';
import { File } from '../common/file.model';

export class BenefitPlan extends Model {
    static permission = new SimpleEntityActions('benefitPlans');

    protected static _resource = 'benefitManagement/companies/:company/benefitPlans';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'companyId',
        'providerId',
        'fileId',
        'policyNumber',
        'name',
        'planUrl',
        'probationPeriodDays',
        'premium',
        'startAt',
        'expireAt',
        'attach',
        'detach',
        'classIdentifier',
        'benefitPolicyId',
        'probationPeriodType',
        'probationPeriodValue',
    ];

    protected static _dates = ['startAt', 'expireAt'];

    get name(): string {
        return this._attributes['name'];
    }
    set name(val: string) {
        this._attributes['name'] = val;
    }

    get policyNumber(): string | null {
        return this._attributes['policyNumber'];
    }
    set policyNumber(val: string | null) {
        this._attributes['policyNumber'] = val;
    }

    get planUrl(): string {
        return this._attributes['planUrl'];
    }
    set planUrl(val: string) {
        this._attributes['planUrl'] = val;
    }

    get probationPeriodDays(): number {
        return this._attributes['probationPeriodDays'];
    }
    set probationPeriodDays(val: number) {
        this._attributes['probationPeriodDays'] = val;
    }
    get probationPeriodDescription(): Translatable {
        const translationKeyBasePath = 'employees.benefits.';
        let periodTranslationKey = 'waitingPeriodAsNull';

        if (this.probationPeriodValue && this.probationPeriodType) {
            // it is coming as singular from the enum colum benefit_plans.probation_period_type = enum('day','week','month')
            let probationPeriodTypeForKey = startCase(this.probationPeriodType);
            if (this.probationPeriodValue > 1) {
                probationPeriodTypeForKey = probationPeriodTypeForKey + 's';
            }
            periodTranslationKey = 'waitingPeriodIn' + probationPeriodTypeForKey;

            return {
                key: translationKeyBasePath + periodTranslationKey,
                params: {
                    amount: this.probationPeriodValue,
                },
            };
        }

        //we need this additional check because there is another beautiful column: benefit_plans.probation_period_days (int)
        if (this.probationPeriodDays) {
            periodTranslationKey = 'waitingPeriodInDay';
            if (this.probationPeriodDays > 1) {
                periodTranslationKey = 'waitingPeriodInDays';
            }
            return {
                key: translationKeyBasePath + periodTranslationKey,
                params: {
                    amount: this.probationPeriodDays,
                },
            };
        }

        return {
            key: translationKeyBasePath + periodTranslationKey,
        };
    }

    get probationPeriodType(): string {
        return this._attributes['probationPeriodType'];
    }

    set probationPeriodType(val: string) {
        this._attributes['probationPeriodType'] = val;
    }

    get probationPeriodValue(): number {
        return this._attributes['probationPeriodValue'];
    }

    set probationPeriodValue(val: number) {
        this._attributes['probationPeriodValue'] = val;
    }

    get startAt(): Date {
        return this._attributes['startAt'];
    }
    set startAt(val: Date) {
        this._attributes['startAt'] = val;
    }

    get expireAt(): Date {
        return this._attributes['expireAt'];
    }
    set expireAt(val: Date) {
        this._attributes['expireAt'] = val;
    }

    get fileId(): number {
        return this._attributes['fileId'];
    }
    set fileId(val: number) {
        this._attributes['fileId'] = val;
    }

    get providerId(): number {
        return this._attributes['providerId'];
    }
    set providerId(val: number) {
        this._attributes['providerId'] = val;
    }

    get benefitPolicyId(): number {
        return this._attributes['benefitPolicyId'];
    }

    set benefitPolicyId(val: number) {
        this._attributes['benefitPolicyId'] = val;
    }

    get benefitProvider(): BenefitProvider {
        return this.hasOne(BenefitProvider, 'benefitProvider');
    }

    get benefitPolicy(): BenefitPolicy | null {
        return this.hasOne(BenefitPolicy, 'benefitPolicy');
    }

    get file(): File {
        return this.hasOne(File, 'file');
    }

    set file(val: File) {
        this.setOne('file', val, 'fileId');
    }

    get benefits(): Benefit[] {
        return this.hasMany(Benefit, 'benefits');
    }

    get employees(): Employee[] {
        return this.hasMany(Employee, 'employees');
    }

    get classIdentifier(): string | null {
        return this._attributes['classIdentifier'];
    }

    set classIdentifier(val: string) {
        this._attributes['classIdentifier'] = val;
    }

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get hasEnhancedManagedBenefits(): boolean | null {
        return this._attributes['hasEnhancedManagedBenefits'];
    }
}
