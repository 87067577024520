import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive()
export abstract class BaseDialogComponent {
    @Input() closable = true;
    @Input() centered = true;

    protected selection: any;
    protected existingSelection: any;
    protected resolve: Function;
    protected reject: Function;

    private denied = false;
    private approved = false;

    constructor(protected element: ElementRef) {}

    show<T>(previousSelection?: T): Promise<T> {
        if (previousSelection) {
            this.existingSelection = previousSelection;
        }
        this.onBeforeShow();
        this.approved = false; // reset approval status
        this.denied = false; // reset approval status
        return new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            this.onShow();
        });
    }

    @HostListener('document:keydown.escape')
    deny(): void {
        this.denied = true;
        $(this.element.nativeElement).find('.ui.dimmer').dimmer('hide');
        this.onDeny();
    }

    approve(): void {
        this.approved = true;
        $(this.element.nativeElement).find('.ui.dimmer').dimmer('hide');
        this.onApprove();
    }

    protected onBeforeShow(): void {
        this.selection = null;
    }

    protected onApprove(): void {
        this.resolve?.(this.selection);
    }

    protected onDeny(): void {
        this.resolve?.(null);
    }

    protected onShow(): void {
        $(this.element.nativeElement)
            .find('.ui.dimmer')
            .dimmer({
                closable: this.closable,
                onHide: () => {
                    if (!this.approved && !this.denied) {
                        this.deny();
                    }
                },
            });
        $(this.element.nativeElement).find('.ui.dimmer').dimmer('show');
        $(this.element.nativeElement).find('.ui.segment').fadeIn();
    }
}
