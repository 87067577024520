import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { DropdownColumn } from '@app/modules/table/interfaces/dropdown-column.interface';
import { InteractableColumn } from '@app/modules/table/interfaces/interactable-column.interface';
import { EmployeeBenefit } from '@models/payroll/employee/benefit.model';
import { debounce } from 'lodash-es';

const dropdownOptions = [
    { label: '$', value: 'dollar' },
    { label: '%', value: 'percent' },
];

const DEBOUNCE_TIME = 1000;

export class CompanyContributionColumn extends Column implements InteractableColumn, DropdownColumn {
    title = 'table.benefits.companyContribution';
    property = 'contributionAmount';
    type: CellDisplay = CellDisplay.rateSelect;
    showSaveActionIcons = true;

    onRowChange = debounce(async (row: EmployeeBenefit) => {
        if (row.contributionAmount.toString() === '') {
            row.contributionAmount = 0;
        }
        await row.param('benefit', this.benefitId).param('company', this.companyId).save();
    }, DEBOUNCE_TIME);

    constructor(
        private benefitId: number,
        private companyId: number
    ) {
        super();
    }

    getDropdownOptions(_: EmployeeBenefit): { label: string; value: string }[] {
        return dropdownOptions;
    }

    getBindProperty(): string {
        return 'contributionAmount';
    }

    getSecondaryBindProperty(): string {
        return 'contributionCalculatorType';
    }
}
