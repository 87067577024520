import { SimpleEntityActions } from '@app/classes';
import { TranslatableKey } from '@app/types/translatable.type';
import {
    CustomFieldGroupsDisplayOptions,
    DefaultCustomFieldsDisplayOptions,
} from '@app/types/translatables/default-custom-fields-display.options';
import { Model } from '@models/core/base.model';
import { DataValue } from '@models/employee/data-value.model';
import { DataField } from '@models/settings/data-field.model';
import { DefaultGroupFieldNamesEnum } from '@settings/enums/default-group-field-names.enum';

export class DataFieldGroup extends Model {
    static permission = new SimpleEntityActions('companyDataFieldGroup');
    protected static _resource = 'companyManagement/companies/:company/dataFieldGroups';

    protected static _version = 'v2';
    protected static _serializeAttributes = ['name', 'component', 'companyId'];

    // Company Id
    get companyId(): number {
        return this._attributes['companyId'];
    }

    set companyId(val: number) {
        this._attributes['companyId'] = val;
    }

    // Name
    get name(): string {
        if (this.defaultGroupType) {
            return DefaultGroupFieldNamesEnum[this.defaultGroupType];
        }

        return this._attributes['name'];
    }

    get translatableName(): string | TranslatableKey {
        if (this.defaultGroupType) {
            return DefaultCustomFieldsDisplayOptions[this.defaultGroupType] ?? this._attributes['name'];
        }

        return this._attributes['name'];
    }

    set name(val: string) {
        if (this.defaultGroupType) {
            return;
        }

        this._attributes['name'] = val;
    }

    /**
     * NOTE: The frontend refers to the 'component' as 'location' as well
     */
    get component(): string {
        return this._attributes['component'];
    }

    get translatableComponent(): string | TranslatableKey {
        const component = this._attributes['component'];
        return CustomFieldGroupsDisplayOptions[component] ?? component;
    }

    set component(val: string) {
        this._attributes['component'] = val;
    }

    get defaultGroupType(): string | null {
        return this._attributes['defaultGroupType'] ?? null;
    }

    set defaultGroupType(val: string) {
        this._attributes['defaultGroupType'] = val;
    }

    get orderBy(): number | null {
        return this._attributes['orderBy'] ?? null;
    }

    set orderBy(val: number) {
        this._attributes['orderBy'] = val;
    }

    get isDefault(): boolean {
        return this.defaultGroupType !== null;
    }

    get isCustom(): boolean {
        return this.defaultGroupType === null;
    }

    get dataFields(): DataField[] {
        return this.hasMany(DataField, 'dataFields');
    }

    set dataFields(val: DataField[]) {
        this._relationships['dataFields'] = val;
    }

    get dataFieldCount(): number {
        return this._attributes['dataFieldCount'];
    }

    /**
     * Set directly on the relationship as its used internally only
     */
    get dataValues(): DataValue[] {
        return this._relationships['dataValues'];
    }

    set dataValues(val: DataValue[]) {
        this._relationships['dataValues'] = val;
    }

    /**
     * Used for form validation
     */
    get isValid(): boolean {
        return this._attributes['isValid'];
    }

    set isValid(val: boolean) {
        this._attributes['isValid'] = val;
    }

    /**
     * The frontend refers to the dataFieldGroup 'component' as 'location'
     */
    isLocation(location: string): boolean {
        return this.component === location;
    }
}
