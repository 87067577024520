import { Table } from '@app/modules/table/classes/table.class';
import { Paginator, SortDirection } from '@app/classes';
import { EmployeeDeduction } from '@app/models/payroll/employee/deduction.model';
import { AnalyticService, AuthService } from '@app/services';
import { EmployeeNameColumn } from './columns/employee-name.column';
import { FrequencyColumn } from './columns/frequency.column';
import { EffectiveAtColumn } from './columns/effective-at.column';
import { EmployeePremiumColumn } from './columns/employee-premium.column';

export class EmployeeDeductionTable extends Table {
    sortDirection = SortDirection.ASC;
    links = false;
    bulkSelect = true;
    protected sortProperty = 'name';

    constructor(
        protected auth: AuthService,
        private analytics: AnalyticService,
        private deductionId: number
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        const nameColumn = new EmployeeNameColumn();
        this.sortColumn = nameColumn;
        this.columns = [
            nameColumn,
            new EffectiveAtColumn(this.deductionId, this.auth.company.id, this.analytics),
            new EmployeePremiumColumn(this.deductionId, this.auth.company.id),
            new FrequencyColumn(this.deductionId, this.auth.company.id),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            EmployeeDeduction.param('company', this.auth.company.id)
                .param('deduction', this.deductionId)
                .with('employee')
        );
    }
}
