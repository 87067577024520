import { Paginator, SortDirection } from '@app/classes';
import { Roe } from '@app/models/payroll/roe.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { EmployeeNameColumn, RoeDateCreatedColumn, RoeDateSubmittedColumn, RoeStatusColumn } from './columns';

export class RoeV2Table extends Table {
    sortDirection = SortDirection.ASC;
    links = true;
    protected sortProperty = 'name';

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [
            new EmployeeNameColumn(),
            new RoeStatusColumn(),
            new RoeDateSubmittedColumn(),
            new RoeDateCreatedColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(Roe.param('company', this.auth.company.id));
    }
}
