import { Injectable } from '@angular/core';
import { State } from '@app/models/common/state.model';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    states = new ReplaySubject<State[]>(1);

    private prevCountry = '';
    private prevStates: State[] = [];

    /**
     * @deprecated because it doesn't get you states...
     * if you want the states, please use getStatesForCountry
     */
    public getStates(country: string): void {
        State.param('country', country)
            .all()
            .then(([states]) => {
                this.states.next(states);
            });
    }

    public async getStatesForCountry(country: string): Promise<State[]> {
        // we use a local cache of the last country's states
        // odds are, it'll be Canada again
        if (country === this.prevCountry) {
            return this.prevStates;
        }

        const [states] = await State.param('country', country).all();
        this.prevCountry = country;
        this.prevStates = states;
        return states;
    }

    /**
     * getProvinceShortCode returns the short code for a province or state
     *
     * Eg. Canada, Ontario         => ON
     *     United States, New York => NY
     */
    public async getProvinceShortCode(country: string, provinceName: string): Promise<string | null> {
        const states = await this.getStatesForCountry(country);
        return states.find((s) => s.name === provinceName)?.code ?? null;
    }
}
