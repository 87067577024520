import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getIconNames } from '@app/constants/icons';

export type BannerVariant = 'default' | 'info' | 'warning' | 'danger' | 'success' | 'bulb';

const iconNames = getIconNames();

type IconMap = Record<BannerVariant, (typeof iconNames)[number] | null>;

const iconMap: IconMap = {
    default: null,
    info: 'boldInfo',
    danger: 'cancel',
    warning: 'warning',
    success: 'success',
    bulb: 'bulb',
};

@Component({
    selector: 'ui-banner',
    templateUrl: './banner.template.html',
    styleUrls: ['./banner.style.scss'],
})
export class BannerComponent {
    @Input() showIcon = true;
    @Input() iconOverride: BannerVariant | null = null; // if you want the icon of a different variant
    @Output() close = new EventEmitter();

    private _variant: BannerVariant = 'default';
    private _icon: string | null = null;
    private _closable = false;

    /** Determines style variant of banner */
    @Input() set variant(v: BannerVariant) {
        const _variant = v || 'default';
        this._icon = iconMap[_variant];
        this._variant = _variant;
    }

    get variant(): BannerVariant {
        return this._variant;
    }

    @Input() set closable(closable: boolean) {
        if (typeof closable === 'boolean') {
            this._closable = closable;
        } else {
            this._closable = false;
        }
    }

    get closable(): boolean {
        return this._closable;
    }

    get bannerClassName(): string {
        return `banner ${this.variant}`;
    }

    get bannerIcon(): string {
        if (this.iconOverride) {
            return iconMap[this.iconOverride] || '';
        }

        return this._icon || '';
    }

    onClose(): void {
        this.close.emit();
    }
}
