import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { TimeOffType } from '@app/models/time-off-v3/time-off-type.model';
import { FeatureService } from '@app/services/feature.service';
import { environment } from '@env/environment';
import { Schedule } from '@models/payroll/schedule.model';
import { PayrollResources } from '@payroll/payroll.resources';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

type XeroReportType = 'Invoice' | 'JournalEntry';

export type Settings = {
    integrationWithTimeOff?: boolean;
    timeOffPaidVacationTypeId?: number | null;
    sendPaystubNotificationEmails?: boolean;
    allowCovidWageSubsidy?: boolean;
    displayTimeOffTimeBalanceInPaystub?: boolean;
    displayTimeOffMoneyBalanceInPaystub?: boolean;
    schedule?: Schedule | null;
    vacationPayAccrualMethod?: null | string;
    displayTimeOffTimeBalanceInTimeOffView?: boolean;
    displayTimeOffMoneyBalanceInTimeOffView?: boolean;
    displayTimeOffNegativeBalanceInPaystub?: boolean;
    allowAutomaticRoeSubmissions?: boolean;
    informPreviousPayrollProviderToSubmitRoe?: boolean;
    shouldDelayPaystubToChequeDate?: boolean;
    roeContactFirstName?: null | string;
    roeContactLastName?: null | string;
    roeContactAreaCode?: null | string;
    roeContactPhoneNumber?: null | string;
    roeContactPhoneExtensionNumber?: null | string;
    showOnboardingMenu?: boolean;
    wcbActivated?: boolean;
    showHoursPage?: boolean;
    reportJournalEntryReportsToXeroAs?: XeroReportType;
    qhsfRate?: null | string;
};

@Injectable()
export class PayrollSettingsService {
    loading$ = new BehaviorSubject(false);

    settings$: BehaviorSubject<Settings> = new BehaviorSubject<Settings>({});
    schedule: Schedule;
    timeOffTypes: TimeOffType[] = [];
    settings: Settings;
    timeOffPayrollIntegrationPhaseFourFeatureFlag = false;
    vacationAccrualAndPayoutFeatureFlag = false;
    hasLoaded = false;

    defaultSettings: Settings = {
        integrationWithTimeOff: false,
        timeOffPaidVacationTypeId: null,
        sendPaystubNotificationEmails: false,
        allowCovidWageSubsidy: false,
        displayTimeOffTimeBalanceInPaystub: false,
        displayTimeOffMoneyBalanceInPaystub: false,
        schedule: null,
        vacationPayAccrualMethod: null,
        displayTimeOffTimeBalanceInTimeOffView: false,
        displayTimeOffMoneyBalanceInTimeOffView: false,
        displayTimeOffNegativeBalanceInPaystub: false,
        allowAutomaticRoeSubmissions: false,
        informPreviousPayrollProviderToSubmitRoe: false,
        shouldDelayPaystubToChequeDate: false,
        roeContactFirstName: null,
        roeContactLastName: null,
        roeContactAreaCode: null,
        roeContactPhoneNumber: null,
        roeContactPhoneExtensionNumber: null,
        showOnboardingMenu: true,
        wcbActivated: false,
        showHoursPage: false,
    };

    private isUsingAuthService = false;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private featureService: FeatureService
    ) {
        this.setDefaultSettings();
        this.featureService.has(FeatureFlag.experimentalAuthService).then((isUsingAuthService) => {
            this.isUsingAuthService = isUsingAuthService;
        });
    }

    async reload(): Promise<void> {
        this.hasLoaded = false;
        return this.load();
    }

    async load(): Promise<void> {
        if (this.hasLoaded) {
            return;
        }

        if (!this.needsPayrollSettings()) {
            this.setDefaultSettings();
            return;
        }

        this.loading$.next(true);

        const [settings, schedule] = await Promise.all([this.loadSettings(), this.loadSchedule()]);
        settings.schedule = schedule;
        this.settings = settings;
        this.settings$.next(settings);

        if (this.settings.qhsfRate && this.settings.qhsfRate.toString().includes('%')) {
            this.settings.qhsfRate = this.settings.qhsfRate.replace('%', '');
        }

        await this.loadFeatureFlags();

        if (this.timeOffPayrollIntegrationPhaseFourFeatureFlag) {
            this.timeOffTypes = await this.loadTimeOffTypes();
        }

        this.hasLoaded = true;
        this.loading$.next(false);
    }

    isPayrollEnabled(): any {
        return this.auth.company.modules.find((module) => module.name === 'Payroll');
    }

    hasPayrollTenantId(): any {
        return this.auth.company.prTenantId;
    }
    needsPayrollSettings(): boolean {
        return this.isPayrollEnabled() && this.hasPayrollTenantId() && this.auth.can('accessPayroll');
    }

    setDefaultSettings(): void {
        this.settings = this.defaultSettings;
    }

    async save(): Promise<void> {
        this.loading$.next(true);

        const settingsClone = Object.assign({}, this.settings$.value);

        if (settingsClone.qhsfRate) {
            settingsClone.qhsfRate += '%';
        }

        const payload = {
            data: {
                attributes: settingsClone,
            },
        };

        await this.http.put(this.getSettingsPath(), payload).toPromise();

        this.loading$.next(false);
    }

    hasPaidTimeOffType(): boolean {
        return this.timeOffTypes.length > 0;
    }

    isVacationYTDNavigable(): boolean {
        return this.settings.integrationWithTimeOff
            ? this.settings.integrationWithTimeOff
            : this.vacationAccrualAndPayoutFeatureFlag;
    }

    isPayrollSetupNavigable(): boolean {
        return this.settings.showOnboardingMenu;
    }

    private async loadSettings(): Promise<Settings> {
        const response = await this.http
            .get(this.getSettingsPath())
            .toPromise()
            .catch((error) => {
                this.setDefaultSettings();
                // We are capturing this error twice as we want to be able to log errors on Sentry
                // to determine issues as well as throw an error to return the execution of this promise
                Sentry.captureException(error);
                throw error;
            });
        return response['data']['attributes'];
    }

    private async loadSchedule(): Promise<Schedule> {
        const response = await this.http
            .get(this.getSchedulePath())
            .toPromise()
            .catch((error) => {
                this.setDefaultSettings();
                // We are capturing this error twice as we want to be able to log errors on Sentry
                // to determine issues as well as throw an error to return the execution of this promise
                Sentry.captureException(error);
                throw error;
            });

        return new Schedule(response['data']['attributes']);
    }

    private getSchedulePath(): string {
        if (this.isUsingAuthService) {
            return `${environment.payrollUrl}/v2/companies/${this.auth.company.id}/schedule`;
        }

        return PayrollResources.Schedule.replace(':company', this.auth.company.id.toString());
    }

    private getSettingsPath(): string {
        if (this.isUsingAuthService) {
            return `${environment.payrollUrl}/v2/companies/${this.auth.company.id}/settings`;
        }

        return PayrollResources.Setting.replace(':company', this.auth.company.id.toString());
    }

    private async loadFeatureFlags(): Promise<void> {
        const timeOffPayrollIntegrationPhaseFourPromise = this.featureService.has(
            FeatureFlag.timeOffPayrollIntegrationPhaseFour
        );

        const vacationAccrualAndPayoutPromise = this.featureService.has(FeatureFlag.vacationAccrualAndPayout);
        const [timeOffPayrollIntegrationPhaseFour, vacationAccrualAndPayout] = await Promise.all([
            timeOffPayrollIntegrationPhaseFourPromise,
            vacationAccrualAndPayoutPromise,
        ]);

        this.timeOffPayrollIntegrationPhaseFourFeatureFlag = timeOffPayrollIntegrationPhaseFour;
        this.vacationAccrualAndPayoutFeatureFlag = vacationAccrualAndPayout;
    }

    private async loadTimeOffTypes(): Promise<TimeOffType[]> {
        const [response] = await TimeOffType.where('isPaid', 1).get();

        return response;
    }
}
