import { Pipe, PipeTransform } from '@angular/core';
import { AppTranslatePipe } from '@app/pipes/app-translate.pipe';

@Pipe({ name: 'dataFieldType' })
export class FieldTypePipe extends AppTranslatePipe implements PipeTransform {
    transform(str: string): string {
        if (str === 'boolean') {
            return super.transform('forms.fields.yesNo');
        }

        /*
         * Convert kebab case to title case
         * */
        return str.replace(/-/g, ' ').replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
}
