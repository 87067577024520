import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BenefitPlan } from '@app/models/benefits/benefit-plan.model';
import { environment } from '@env/environment';

@Injectable()
export class BenefitPlanEmployeeAssignmentService {
    constructor(private http: HttpClient) {}

    onSave(
        benefitPlan: BenefitPlan,
        enroll: number[],
        unenroll: number[],
        selectedBenefitDivisionId = null,
        waiveWaitingPeriod = false
    ): Promise<any> {
        const url = this.getBenefitPlanEmployeeAssignmentEndpoint(benefitPlan);
        const payload = {
            data: {
                type: 'benefitPlanEmployeeAssignment',
                enroll: enroll,
                unenroll: unenroll,
                benefitPlanId: benefitPlan.id,
                division: selectedBenefitDivisionId,
                waiveWaitingPeriod: waiveWaitingPeriod,
            },
        };

        return this.http.post(url, payload).toPromise();
    }

    private getBenefitPlanEmployeeAssignmentEndpoint(benefitPlan: BenefitPlan): string {
        return `${environment.api}/v2/benefitManagement/companies/${benefitPlan.companyId}/benefitPlans/${benefitPlan.id}/enroll`;
    }
}
