import { AfterContentInit, Component, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbilityService, AuthService, LanguageService } from '@app/services';
import { MenuItem, MenuItemGroup } from '@app/interfaces';
import { FeatureService } from '@app/services/feature.service';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

@Component({
    selector: 'app-deprecated-tabbar',
    templateUrl: './deprecated-tabbar.template.html',
    styleUrls: ['./deprecated-tabbar.style.scss'],
})
export class DeprecatedTabbarComponent implements AfterContentInit {
    @Input() menu: MenuItem[];

    menuClone: MenuItem[];
    activeTab: MenuItem = null;
    activeTabDisplayLabel: string;

    featureFlags: LDFlagSet;

    constructor(
        protected auth: AuthService,
        protected abilities: AbilityService,
        private element: ElementRef,
        private route: ActivatedRoute,
        private router: Router,
        private featureService: FeatureService,
        private languageService: LanguageService
    ) {
        this.loadDependencies();
    }

    private async loadDependencies(): Promise<void> {
        const [featureFlags] = await Promise.all([this.featureService.all(), this.abilities.load()]);
        this.featureFlags = featureFlags;

        const menuClone = JSON.parse(JSON.stringify(this.menu));
        this.menuClone = this.filterNavigation(menuClone);
        this.replaceRouteParameters();
    }

    ngAfterContentInit(): void {
        setTimeout(() => {
            $(this.element.nativeElement).find('.ui.dropdown').dropdown({
                direction: 'downward',
            });
        });
    }

    setActiveTab(url: string): void {
        let child: MenuItem;

        this.activeTab = this.menuClone.find((m: MenuItem) => {
            return (
                m.children &&
                !!m.children.find((c: MenuItem) => {
                    c = this.replaceRouteParametersInMenuItem(c);
                    if (c.link.join('/') === url) {
                        child = c;
                        return true;
                    }

                    return false;
                })
            );
        });

        if (this.activeTab && child) {
            const parentKey = this.languageService.translate(this.activeTab.key);
            const childKey = this.languageService.translate(child.key);

            this.activeTabDisplayLabel = parentKey + ' - ' + childKey;

            return;
        }

        this.activeTabDisplayLabel = null;
    }

    /**
     * Find all occurrences of :route parameters
     * and replace with current route parameters
     * if existing
     */
    private replaceRouteParameters(): void {
        if (this.menu) {
            this.menuClone = this.replaceRouteParametersInMenu(this.menuClone);

            this.menuClone.map((menu) => {
                if (!menu.children) {
                    return menu;
                }

                return this.replaceRouteParametersInMenu(menu.children);
            });
        }
        this.setActiveTab(this.router.url);

        this.ngAfterContentInit();
    }

    private replaceRouteParametersInMenu(menu: MenuItemGroup): MenuItemGroup {
        return menu.map((menuItem) => {
            if (!menuItem.link) {
                return menuItem;
            }

            return this.replaceRouteParametersInMenuItem(menuItem);
        });
    }

    private replaceRouteParametersInMenuItem(menuItem: MenuItem): MenuItem {
        menuItem.link = menuItem.link.map((linkSegment) => {
            if (linkSegment.includes(':')) {
                const key = linkSegment.replace(':', '');
                if (this.route.snapshot.params[key] !== null) {
                    linkSegment = linkSegment.replace(':' + key, this.route.snapshot.params[key]);
                }
            }
            return linkSegment;
        });
        return menuItem;
    }

    private filterNavigation(navigation: MenuItemGroup): MenuItemGroup {
        return (
            navigation
                .filter((navItem) => {
                    if (!navItem.checkAccessToEmployee) {
                        return true;
                    }

                    return this.auth.canAccessEmployee(parseInt(this.route.snapshot.params['employee']));
                })
                .filter((navItem) => {
                    if (!navItem.can) {
                        return true;
                    }

                    if (navItem.checkAllPermissions) {
                        return this.auth.canAll(navItem.can);
                    }

                    return this.auth.canAny(navItem.can);
                })
                // Legacy permissions used in navigation meta
                .filter((navItem) => {
                    if (!navItem.permission) {
                        return true;
                    }

                    if (navItem.permissionAll) {
                        return this.auth.canAll(navItem.permission);
                    }

                    return this.auth.canAny(navItem.permission);
                })
                .filter((navItem) => {
                    if (!navItem.cannot) {
                        return true;
                    }
                    return !this.auth.canAny(navItem.cannot);
                })
                .filter((navItem) => (navItem.ableTo ? this.abilities[navItem.ableTo]() : true))
                .filter((navItem) => (navItem.unableTo ? !this.abilities[navItem.unableTo]() : true))
                .filter((navItem) =>
                    navItem.module ? this.auth.company.modules.findIndex((m) => m.name === navItem.module) > -1 : true
                )
                .filter((navItem) =>
                    navItem.employeeModule ? this.auth.employee?.hasModule(navItem.employeeModule) : true
                )
                .filter((navItem) =>
                    navItem.showIfHasFeatureFlag ? this.featureFlags[navItem.showIfHasFeatureFlag] : true
                )
                .filter((navItem) =>
                    navItem.hideIfHasFeatureFlag ? !this.featureFlags[navItem.hideIfHasFeatureFlag] : true
                )
                .map((navItem) => {
                    if (navItem.children) {
                        navItem.children = this.filterNavigation(navItem.children);
                    }

                    return navItem;
                })
        );
    }
}
