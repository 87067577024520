import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ShapeIcons } from '@app/constants';
// import { environment } from '@env/environment';

@Injectable()
export class IconsService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.registerIcons();
    }

    registerIcons(): void {
        ShapeIcons.forEach((icon: { name: string; path: string }) => {
            this.matIconRegistry.addSvgIcon(
                icon.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(/*environment.url +*/ icon.path)
            );
        });
    }
}
