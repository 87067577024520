import { TimeOffType } from '@app/models/time-off-v3/time-off-type.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class TimeOffPoliciesCount extends Column {
    title = 'time-off-v3.timeOffTypesTable.policiesUnderThisType';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(timeOffType: TimeOffType): number {
        return timeOffType.timeOffPolicies.length;
    }
}
