import { EmployeeHour } from '@app/models/payroll/company/employee-hour.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

const options = [
    {
        label: 'payroll.run.timeTracking',
        value: 'timesheetEntry',
    },
    {
        label: 'payroll.run.timeOff',
        value: 'timeOffRequest',
    },
];

export class SourceColumn extends Column {
    title = 'table.employeeHour.source';
    property = 'source';
    sortField = 'source';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    constructor() {
        super();

        this.filter = new CheckListFilter('source', options);
    }

    getDisplayPropertyValue(employeeHour: EmployeeHour): string {
        return employeeHour.source === 'timesheetEntry' ? 'payroll.run.timeTracking' : 'payroll.run.timeOff';
    }
}
