import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class BillingService {
    constructor(private http: HttpClient) {}

    cancelSubscription(cancellationReason): Promise<object> {
        return this.http.post(`${environment.api}/v2/billingCancellation`, cancellationReason).toPromise();
    }

    cancelPlan(plan: string): Promise<object> {
        return this.http.post(`${environment.api}/v2/billingCancellation?plan=${plan}`, {}).toPromise();
    }
}
