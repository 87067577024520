<div class="ui left icon input" [class.collapsed]="!isMobileExpanded">
    <div class="search-icon" (click)="onTouch()">
        <mat-icon svgIcon="search"></mat-icon>
    </div>
    <input
        #input
        (focus)="onFocus()"
        (blur)="onBlur()"
        type="search"
        [class.bordered]="bordered"
        name="search"
        [value]="searchTerm"
        attr.placeholder="{{ placeholder | appTranslate }}"
        [formControl]="searchControl"
        [style.min-width.px]="minWidth"
    />
</div>
