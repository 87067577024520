import { DataFieldGroup } from '@app/models/settings/data-field-group.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class NumberOfFieldsColumn extends Column {
    title = 'settings.custom-fields.field-group-index-table.numberOfFields';
    sortable = false;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(fieldGroup: DataFieldGroup): number {
        return fieldGroup.dataFieldCount ?? 0;
    }
}
