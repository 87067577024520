import { Paystub } from '@app/models/payroll/paystub.model';
import { Model } from '@models/core/base.model';

export class SalaryPayItem extends Model {
    protected static _resource = 'payroll/companies/:company/payrolls/salaryPayItems';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'name',
        'amount',
        'editable',
        'userModified',
        'hours',
        'multiplier',
        'prorated',
        'prorationDetails',
        'rate',
        'paystubId',
        'compensationId',
    ];

    get paystubId(): number {
        return this._attributes['paystubId'];
    }
    set paystubId(val: number) {
        this._attributes['paystubId'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }
    get amount(): number {
        return Number(this._attributes['amount']);
    }
    get editable(): boolean {
        return this._attributes['editable'];
    }
    get hours(): number {
        return this._attributes['hours'];
    }
    get multiplier(): number {
        return this._attributes['multiplier'];
    }
    get prorated(): boolean {
        return this._attributes['prorated'];
    }
    get prorationDetails(): object {
        return this._attributes['prorationDetails'];
    }
    get rate(): number {
        return this._attributes['rate'];
    }
    get periodic(): boolean {
        return this._attributes['periodic'];
    }
    get compensationRate(): number {
        return this._attributes['compensationRate'];
    }
    get compensationUnit(): string {
        return this._attributes['compensationUnit'] || 'Hour';
    }
    get compensationVacationPayPercentage(): number {
        return this._attributes['compensationVacationPayPercentage'];
    }
    get compensationEffectiveFrom(): number {
        return this._attributes['compensationEffectiveFrom'];
    }
    get userModified(): boolean {
        return this._attributes['userModified'];
    }

    get hourlyRate(): number {
        return this._attributes['hourlyRate'];
    }

    get paystub(): Paystub {
        return this.hasOne(Paystub, 'paystub');
    }

    /**
     * The back end returns an object when prorated
     * and an array when not prorated
     */
    get isProrated(): boolean {
        return !Array.isArray(this.prorationDetails);
    }

    get compensationId(): number {
        return this._attributes['compensationId'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }
    set amount(val: number) {
        this._attributes['amount'] = Number(val);
    }
    set editable(val: boolean) {
        this._attributes['editable'] = val;
    }
    set hours(val: number) {
        this._attributes['hours'] = val;
    }
    set multiplier(val: number) {
        this._attributes['multiplier'] = val;
    }
    set prorated(val: boolean) {
        this._attributes['prorated'] = val;
    }
    set prorationDetails(val: object) {
        this._attributes['prorationDetails'] = val;
    }
    set rate(val: number) {
        this._attributes['rate'] = val;
    }
    set userModified(val: boolean) {
        this._attributes['userModified'] = val;
    }

    set compensationId(val: number) {
        this._attributes['compensationId'] = val;
    }

    toAmount(): number {
        return Number(this.amount);
    }
}
