import { SimpleEntityActions } from '@app/classes';
import { KanbanCard } from '@app/interfaces/kanban-card.interface';
import { KanbanColumn } from '@app/interfaces/kanban-column.interface';
import { JobPostingCompensationTypesAsNoun } from '@app/modules/applicant-tracker/enums/job-posting-compensation-types-as-noun.enum';
import { convertBaseSalaryGivenCompensationType } from '@app/modules/applicant-tracker/functions/convert-base-salary-given-compensation-type.function';
import { Address } from '@app/modules/applicant-tracker/interfaces/address.interface';
import { KanbanApplicant } from '@app/modules/applicant-tracker/interfaces/kanban-applicant.interface';
import { Translatable } from '@app/types/translatable.type';
import { ValidationPayloadInterface } from '@applicant-tracker/interfaces/validation-payload.interface';
import { environment } from '@env/environment';
import { ATSJobIntegration } from '@models/company/ats-job-integration.model';
import { JobIndeedIntegration } from '@models/company/job-indeed-integration.model';
import { Employee } from '@models/employee/employee.model';
import { JobFunnel } from '@models/recruiting/job-funnel.model';
import { JobPostingLocationType } from '@models/recruiting/job-posting-location-type.model';
import { ManagerAssignment } from '@models/recruiting/manager-assignment.model';
import { Question } from '@models/recruiting/question.model';
import { Topic } from '@models/recruiting/subscriptions/topic.model';
import { ModelMixin } from '../core/base-generic.model';
import { ApplicantOptionalField } from './applicant-optional-field.model';
import { JobAttachment } from './job-attachments.model';

export class JobPosting extends ModelMixin<JobPosting>() {
    static permission = new SimpleEntityActions('jobPosting');

    protected static _resource = 'applicantTracker/jobPostings';
    protected static _version = 'v2';
    protected static _serializeAttributes = [
        'title',
        'level',
        'description',
        'optionalSkills',
        'previousExperience',
        'addressLine1',
        'addressLine2',
        'city',
        'region',
        'country',
        'postalCode',
        'latitude',
        'longitude',
        'payLow',
        'payHigh',
        'benefitsPerks',
        'additionalFields',
        'jobViews',
        'filledAt',
        'archivedAt',
        'category',
        'autoScoreKeywords',
        'hiringTeamIds',
        'successMsgId',
        'closedMsgId',
        'isVisible',
        'managerAssignments',
        'draftAt',
        'attach',
        'detach',
        'jobPostingLocationTypeId',
        'compensationType',
        'applicantConsent',
    ];

    protected static _datetimes = ['pausedAt', 'draftAt'];

    public allLocationTypes = [];

    /**
     * Used in creation of job posting to store questions temporarily
     */
    temporaryAutoArchivingQuestions: Question[] = [];

    hiringTeamAssign: Employee[] = [];

    /* Timestamps */
    get createdAt(): any {
        return this._attributes['createdAt'];
    }

    get createdBy(): any {
        return this._attributes['createdBy'];
    }

    get draftAt(): Date {
        return this._attributes['draftAt'];
    }

    set draftAt(value: Date) {
        this._attributes['draftAt'] = value;
    }

    get updatedAt(): any {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): any {
        return this._attributes['deletedAt'] || null;
    }

    get archivedAt(): any {
        return this._attributes['archivedAt'] || null;
    }

    set archivedAt(value: any) {
        this._attributes['archivedAt'] = value;
    }

    get pausedAt(): Date {
        return this._attributes['pausedAt'];
    }

    get id(): number {
        return this._attributes['id'] || null;
    }

    get title(): string {
        return this._attributes['title'];
    }

    set title(value: string) {
        this._attributes['title'] = value;
    }

    get category(): string {
        return this._attributes['category'];
    }

    set category(value: string) {
        this._attributes['category'] = value;
    }

    get level(): string {
        return this._attributes['level'];
    }

    set level(value: string) {
        this._attributes['level'] = value;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(value: string) {
        this._attributes['description'] = value;
    }

    get addressLine1(): string {
        return this._attributes['addressLine1'];
    }

    set addressLine1(value: string) {
        this._attributes['addressLine1'] = value;
    }

    get addressLine2(): string {
        return this._attributes['addressLine2'];
    }

    set addressLine2(value: string) {
        this._attributes['addressLine2'] = value;
    }

    get city(): string {
        return this._attributes['city'];
    }

    set city(value: string) {
        this._attributes['city'] = value;
    }

    get region(): string {
        return this._attributes['region'];
    }

    set region(value: string) {
        this._attributes['region'] = value;
    }

    get country(): string {
        return this._attributes['country'];
    }

    set country(value: string) {
        this._attributes['country'] = value;
    }

    get postalCode(): string {
        return this._attributes['postalCode'];
    }

    set postalCode(value: string) {
        this._attributes['postalCode'] = value;
    }

    get latitude(): number {
        return this._attributes['latitude'];
    }

    set latitude(value: number) {
        this._attributes['latitude'] = value;
    }

    get longitude(): number {
        return this._attributes['longitude'];
    }

    set longitude(value: number) {
        this._attributes['longitude'] = value;
    }

    get address(): Address {
        return {
            line1: this.addressLine1,
            line2: this.addressLine2,
            city: this.city,
            region: this.region,
            country: this.country,
            postalCode: this.postalCode,
            latitude: this.latitude,
            longitude: this.longitude,
        };
    }

    set address(address: Address) {
        this._attributes['addressLine1'] = address.line1;
        this._attributes['addressLine2'] = address.line2;
        this._attributes['city'] = address.city;
        this._attributes['region'] = address.region;
        this._attributes['country'] = address.country;
        this._attributes['postalCode'] = address.postalCode ? address.postalCode : null;
        this._attributes['latitude'] = address.latitude;
        this._attributes['longitude'] = address.longitude;
    }

    get streetAddress(): string {
        let streetAddress = '';
        if (this.addressLine1) {
            streetAddress = this.addressLine1;
        }
        if (this.addressLine2) {
            streetAddress += ' ' + this.addressLine2;
        }
        return streetAddress.trim();
    }

    get compensationType(): string {
        return this._attributes['compensationType'];
    }

    get compensationTypeAsNoun(): string {
        return JobPostingCompensationTypesAsNoun[this._attributes['compensationType']];
    }

    set compensationType(value: string) {
        this._attributes['compensationType'] = value;
    }

    get payLow(): string {
        return this._attributes['payLow'];
    }

    get payLowPlaceholder(): Translatable {
        const hourlyBaseSalary = 30;
        const salaryAsString = convertBaseSalaryGivenCompensationType(
            hourlyBaseSalary,
            this._attributes['compensationType']
        );
        return { key: 'applicant-tracker.compensationAmountPlaceholder', params: { amount: salaryAsString } };
    }

    set payLow(value: string) {
        this._attributes['payLow'] = value;
    }

    get payHigh(): string {
        return this._attributes['payHigh'];
    }

    get payHighPlaceholder(): Translatable {
        const hourlyBaseSalary = 40;
        const salaryAsString = convertBaseSalaryGivenCompensationType(
            hourlyBaseSalary,
            this._attributes['compensationType'],
            true
        );

        return { key: 'applicant-tracker.compensationAmountPlaceholder', params: { amount: salaryAsString } };
    }

    set payHigh(value: string) {
        this._attributes['payHigh'] = value;
    }

    get isVisible(): boolean {
        return this._attributes['isVisible'];
    }

    set isVisible(val: boolean) {
        this._attributes['isVisible'] = val;
    }

    get hasSubscription(): boolean {
        return this._attributes['hasSubscription'];
    }

    get hiringTeam(): Employee[] {
        return this.hasMany(Employee, 'hiringTeam');
    }

    set hiringTeam(value: Employee[]) {
        this._relationships['hiringTeam'] = value;
        this._attributes['hiringTeam'] = value;
    }

    set hiringTeamIds(value: number[]) {
        this._attributes['hiringTeamIds'] = value;
    }

    get applicants(): any[] {
        return this._attributes['applicants'] || [];
    }

    get applicantsCount(): number {
        return this._attributes['applicantsCount'];
    }

    get totalApplicants(): number {
        return this._attributes['totalApplicants'];
    }

    get isArchived(): boolean {
        return Boolean(this.archivedAt);
    }

    get isPaused(): boolean {
        return Boolean(this.pausedAt);
    }

    get isDraft(): boolean {
        return Boolean(this.draftAt);
    }

    get isActive(): boolean {
        return !this.isArchived && !this.isPaused;
    }

    get autoScoreKeywords(): string[] {
        return this._attributes['autoScoreKeywords'] || [];
    }

    set autoScoreKeywords(value: string[]) {
        this._attributes['autoScoreKeywords'] = value;
    }

    get jobFunnels(): JobFunnel[] {
        return this.hasMany(JobFunnel, 'jobFunnels');
    }

    get jobFunnelsWithActiveApplicantsCount(): JobFunnel[] {
        return this.hasMany(JobFunnel, 'jobFunnelsWithActiveApplicantsCount');
    }

    set jobFunnels(jobFunnels: JobFunnel[]) {
        this.setMany('jobFunnels', jobFunnels);
    }
    // TODO: Remove this getter to make atsAdditionalQuestions the main getter.
    get additionalQuestions(): {
        label: string;
        type: 'input' | 'number' | 'textarea' | 'date';
        required: boolean;
    }[] {
        return this._attributes['additionalFields'];
    }

    set additionalQuestions(
        questions: { label: string; type: 'input' | 'number' | 'textarea' | 'date'; required: boolean }[]
    ) {
        this._attributes['additionalFields'] = questions;
    }

    get autoArchivingQuestions(): Question[] {
        return this.hasMany(Question, 'autoArchiveQuestions');
    }

    set autoArchivingQuestions(value: Question[]) {
        this._attributes['autoArchiveQuestions'] = value;
    }

    get atsAdditionalQuestions(): Question[] {
        return this.hasMany(Question, 'additionalQuestions');
    }

    set atsAdditionalQuestions(value: Question[]) {
        this._attributes['additionalQuestions'] = value;
    }

    get applicantConsent(): string {
        return this._attributes['applicantConsent'];
    }

    set applicantConsent(value: string) {
        this._attributes['applicantConsent'] = value;
    }

    get jobAttachments(): JobAttachment[] {
        return this.hasMany(JobAttachment, 'jobAttachments');
    }

    set jobAttachments(jobAttachments: JobAttachment[]) {
        this.setMany('jobAttachments', jobAttachments);
    }

    get applicantOptionalFields(): ApplicantOptionalField[] {
        return this.hasMany(ApplicantOptionalField, 'applicantOptionalFields');
    }

    set applicantOptionalFields(val: ApplicantOptionalField[]) {
        this.setMany('applicantOptionalFields', val);
    }

    get jobIntegrations(): ATSJobIntegration[] {
        return this.hasMany(ATSJobIntegration, 'jobIntegrations');
    }

    get jobPostingLocationType(): JobPostingLocationType {
        return this.hasOne(JobPostingLocationType, 'jobPostingLocationType');
    }

    /**
     * Because of the need to refresh only the JobPostingLocationType relationship
     * after saving the model.
     * (without having to refresh the whole model and affecting more parts
     * of the app flow in job posting creation and edit sections)
     */
    set jobPostingLocationType(newJobPostingLocationType: JobPostingLocationType) {
        this.setOne('jobPostingLocationType', newJobPostingLocationType);
        this.jobPostingLocationTypeId = newJobPostingLocationType.id;
    }

    get indeedIntegration(): JobIndeedIntegration {
        return this.hasOne(JobIndeedIntegration, 'indeedIntegration');
    }

    get topic(): Topic {
        return this.hasOne(Topic, 'topic');
    }

    get managerAssignments(): ManagerAssignment[] {
        return this.hasMany(ManagerAssignment, 'managerAssignments');
    }

    get jobPostingLocationTypeId(): number {
        return this._attributes['jobPostingLocationTypeId'];
    }

    set jobPostingLocationTypeId(value: number) {
        this._attributes['jobPostingLocationTypeId'] = value;
    }

    clone(): JobPosting {
        const attributesToClone = this.getAttributes();
        delete attributesToClone['id'];

        return new JobPosting(attributesToClone);
    }

    setApplicantsToOnlyActive(): this {
        this.jobFunnels.map((jobFunnel: JobFunnel) => {
            jobFunnel.applicants = jobFunnel.activeApplicants;

            return jobFunnel;
        });

        return this;
    }

    toKanBan(kanbanEntities: KanbanApplicant[], jobFunnels: JobFunnel[]): KanbanColumn[] {
        return jobFunnels
            .map((jobFunnel: JobFunnel) => {
                const cards = kanbanEntities
                    .filter((kanbanEntity: KanbanApplicant) => jobFunnel.id === kanbanEntity.atsJobFunnelId)
                    .map((kanbanEntity: KanbanApplicant) => {
                        return {
                            index: kanbanEntity.funnelStatus,
                            contentCount: kanbanEntity.documentCount,
                            data: kanbanEntity,
                            id: kanbanEntity.id,
                        };
                    });

                this.orderCards(cards);
                this.reIndexCards(cards);

                return {
                    cards,
                    title: jobFunnel.funnelStep,
                    data: jobFunnel,
                    index: jobFunnel.funnelIndex,
                };
            })
            .sort((a: KanbanColumn, b: KanbanColumn) => {
                if (a.index < b.index) {
                    return -1;
                }
                return 1;
            });
    }

    jobBoardLink(jobBoardSettings: any = null): string {
        if (!jobBoardSettings || !jobBoardSettings.company_slug) {
            return 'none - job board not configured';
        }

        if (!this.category) {
            return 'none - category missing';
        }

        const link = `https://${jobBoardSettings.company_slug}.${environment.jobBoardDomain}/${this.category}/${this.id}`;

        return link.toLowerCase();
    }

    toValidatePayload(): ValidationPayloadInterface {
        const attributes = {};

        JobPosting._serializeAttributes.forEach((item) => (attributes[item] = this._attributes[item]));

        return {
            data: {
                type: 'jobPostings',
                attributes: { ...attributes, id: this.id },
            },
        };
    }

    private orderCards(cards: KanbanCard[]): void {
        cards.sort((a: KanbanCard, b: KanbanCard) => {
            if (a.index < b.index) {
                return -1;
            }
            return 1;
        });
    }
    /**
     * Self-healing for job funnels
     * Fixes issue where a funnel status is null, or where there are missing indices
     */
    private reIndexCards(cards: KanbanCard[]): void {
        cards.forEach((card: KanbanCard, index: number) => (card.index = index));
    }
}
