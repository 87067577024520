import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, FileHelperService } from '@app/services';
import { environment } from '@env/environment';
import { PayrollResources } from '@payroll/payroll.resources';
import { ReportFilter } from '@reporting/interfaces/report-filter.interface';
import { stringify } from 'qs/dist/qs';
import { map } from 'rxjs/operators';

@Injectable()
export class WorkersCompensationPerLineItemReportService {
    constructor(
        private auth: AuthService,
        private files: FileHelperService,
        private httpClient: HttpClient
    ) {}

    fetch(filters: ReportFilter[]): Promise<boolean> {
        if (!this.auth.company) {
            throw new Error('Not logged in');
        }

        const url =
            environment.api +
            PayrollResources.WorkersCompensationPerLineItemReport.replace(':company', this.auth.company.id.toString());

        // Convert filters to url-encoded keys nested under "filters"
        // e.g. "filters[from]=2023-01-01&filters[to]=2023-12-31"
        const query = stringify({
            filters: Object.fromEntries(filters.map(({ name, value }) => [name, value])),
        });

        return this.httpClient
            .get(url + '?' + query, { observe: 'response', responseType: 'blob' })
            .pipe(
                map((response) =>
                    this.files.saveFileFromResponse(response, 'workers-compensation-per-line-item-report.csv')
                )
            )
            .toPromise();
    }
}
