import { Translatable } from '@app/types/translatable.type';

const EmployeeStatusDisplayOptions: { [key: string]: Translatable } = {
    active: 'employeeStatus.active',
    onboarding: 'employeeStatus.onboarding',
    terminated: 'employeeStatus.terminated',
    onLeave: 'employeeStatus.onLeave',
};

export { EmployeeStatusDisplayOptions };
