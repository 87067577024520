import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class EmployeeNameColumn extends Column {
    title = 'table.roes.employeeName';
    property = 'roeEmployee';
    sortField = 'employeeName';
    sortable = true;
    type: CellDisplay = CellDisplay.avatar;
}
