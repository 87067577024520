import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class DateUsedColumn extends Column {
    title = 'table.employeeHour.dateUsed';
    property = 'dateUsed';
    sortField = 'dateUsed';
    sortable = true;
    type: CellDisplay = CellDisplay.date;
}
