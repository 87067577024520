import { SimpleEntityActions } from '@app/classes';
import { Role } from '@models/account/role.model';
import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { DataFieldGroup } from '@models/settings/data-field-group.model';
import { DataFieldOption } from '@models/settings/data-field-option.model';
import { Company } from '../company/company.model';

export class DataField extends Model {
    static permission = new SimpleEntityActions('companyDataField');

    protected static _resource = 'companyManagement/companies/:company/dataFields';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'name',
        'description',
        'type',
        'rules',
        'isRequired',
        'min',
        'max',
        'dataFieldGroupId',
        'isEncrypted',
        'orderBy',
        'isEnabledForDocuments',
        'roleIds',
        'viewOnlyRoleIds',
        'employeeIds',
        'attach',
        'detach',
        'assignedEmployeesCount',
        'employeesWithDataValuesCount',
    ];

    get name(): string {
        return this._attributes['name'];
    }
    set name(val: string) {
        this._attributes['name'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }
    set description(val: string) {
        this._attributes['description'] = val;
    }

    get rules(): string {
        return this._attributes['rules'];
    }
    set rules(val: string) {
        this._attributes['rules'] = val;
    }

    get isRequired(): boolean | null {
        return this._attributes['isRequired'];
    }
    set isRequired(val: boolean) {
        this._attributes['isRequired'] = val;
    }

    get min(): number | null {
        return this._attributes['min'];
    }
    set min(val: number) {
        this._attributes['min'] = val;
    }

    get max(): number | null {
        return this._attributes['max'];
    }
    set max(val: number) {
        this._attributes['max'] = val;
    }

    get orderBy(): number | null {
        return this._attributes['orderBy'] ?? null;
    }
    set orderBy(val: number) {
        this._attributes['orderBy'] = val;
    }

    get type(): string {
        return this._attributes['type'];
    }
    set type(val: string) {
        this._attributes['type'] = val;
    }

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get company(): Company {
        return this.hasOne(Company, 'company');
    }

    get dataFieldOptions(): DataFieldOption[] {
        return this.hasMany(DataFieldOption, 'dataFieldOptions');
    }

    set dataFieldOptions(val: DataFieldOption[]) {
        this.setMany('dataFieldOptions', val);
    }

    get dataFieldGroupId(): number | null {
        return this._attributes['dataFieldGroupId'] || null;
    }

    set dataFieldGroupId(val: number) {
        this._attributes['dataFieldGroupId'] = val;
    }

    get isEncrypted(): boolean {
        return this._attributes['isEncrypted'];
    }
    set isEncrypted(val: boolean) {
        this._attributes['isEncrypted'] = val;
    }

    get isEnabledForDocuments(): boolean {
        return this._attributes['isEnabledForDocuments'];
    }
    set isEnabledForDocuments(val: boolean) {
        this._attributes['isEnabledForDocuments'] = val;
    }

    get dataFieldGroup(): DataFieldGroup {
        return this.hasOne(DataFieldGroup, 'dataFieldGroup');
    }

    set dataFieldGroup(val: DataFieldGroup) {
        this.setOne('dataFieldGroup', val, 'dataFieldGroupId');
    }

    get roles(): Role[] {
        return this.hasMany(Role, 'roles');
    }

    set roles(val: Role[]) {
        this._relationships['roles'] = val;
    }

    get roleIds(): number[] {
        return this._attributes['roleIds'];
    }

    set roleIds(val: number[]) {
        this._attributes['roleIds'] = val;
    }

    get viewOnlyRoles(): Role[] {
        return this.hasMany(Role, 'viewOnlyRoles');
    }

    set viewOnlyRoles(val: Role[]) {
        this._relationships['viewOnlyRoles'] = val;
    }

    get viewOnlyRoleIds(): number[] {
        return this._attributes['viewOnlyRoleIds'];
    }

    set viewOnlyRoleIds(val: number[]) {
        this._attributes['viewOnlyRoleIds'] = val;
    }

    // Get if field is editable by logged in user
    get editable(): boolean {
        return this._attributes['editable'];
    }

    get employees(): Employee[] {
        return this.hasMany(Employee, 'employees');
    }

    get employeeIds(): number[] {
        return this._attributes['employeeIds'];
    }

    set employeeIds(val: number[]) {
        this._attributes['employeeIds'] = val;
    }

    get assignedEmployeesCount(): number {
        return this._attributes['assignedEmployeesCount'] ?? 0;
    }

    get employeesWithDataValuesCount(): number {
        return this._attributes['employeesWithDataValuesCount'] ?? 0;
    }

    getFormattedDropdownOption(formatOptions: { allowNull?: boolean } = {}): { label: string; value: number }[] {
        const { allowNull } = formatOptions;

        const options = this.dataFieldOptions.map((option) => {
            return {
                label: option.value,
                value: option.id,
            };
        });

        if (allowNull) {
            options.unshift({
                label: this.description,
                value: null,
            });
        }

        return options;
    }
}
