import { Injectable } from '@angular/core';
import { MenuItem } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';

export const PolicyDetailsTabsMenu: MenuItem[] = [
    {
        key: 'time-off-v3.editPolicy.policyDetails',
        link: ['../details'],
    },
    {
        key: 'time-off-v3.enrolledEmployees',
        link: ['../enrolled-employees'],
    },
];

@Injectable()
export class PolicyDetailsTabsMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = PolicyDetailsTabsMenu;
    }
}
