import { Component, Input, OnInit } from '@angular/core';
import { Warning, WarningsToDisplayProvider } from '@app/interfaces/warnings-to-display-provider.interface';

@Component({
    selector: 'app-warning-banner',
    templateUrl: './model-warning-banner.template.html',
    // Don't display the banner unless if it has contents
    styles: [
        `
            :host {
                display: contents;
            }
        `,
    ],
})
export class ModelWarningBanner implements OnInit {
    private static defaultHeader = 'The following issues have been found:';

    @Input()
    header?: string;
    @Input()
    warningsToDisplayProvider?: WarningsToDisplayProvider;
    @Input()
    withMargin? = true;

    ngOnInit(): void {
        if (!this.header) {
            this.header = ModelWarningBanner.defaultHeader;
        }
    }

    get warningsToDisplay(): Warning[] {
        return this.warningsToDisplayProvider?.warningsToDisplay() ?? [];
    }

    hasWarningsToDisplay(): boolean {
        return this.warningsToDisplay.length > 0;
    }
}
