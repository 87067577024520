import { Paginator, SortDirection } from '@app/classes';
import { CompanyDeduction } from '@app/models/payroll/company/deduction.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { GlCodePayableColumn } from './columns/gl-code-payable.column';
import { AuthService } from '@app/services';
import { NameColumn } from './columns/name.column';
import { TypeColumn } from './columns/type.column';

const editOption = {
    label: 'Edit',
    event: 'edit',
};

const deleteOption = {
    label: 'Delete',
    event: 'delete',
};

export class DeductionTable extends Table {
    sortDirection = SortDirection.ASC;
    links = true;
    showMenuColumnOptions = true;
    protected sortProperty = 'name';

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        const nameColumn = new NameColumn();
        this.sortColumn = nameColumn;
        this.columns = [nameColumn, new TypeColumn(), new GlCodePayableColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(CompanyDeduction.param('company', this.auth.company.id).with('deduction_type'));
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [editOption, deleteOption];
    }
}
