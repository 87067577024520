import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, Validators } from '@angular/forms';

@Directive({
    selector: '[max]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxDirective, multi: true }],
})
export class MaxDirective implements Validator {
    @Input() max: number;
    @Input('max-message') maxMessage: string;

    validate(control: AbstractControl): { [key: string]: any } {
        const validator = Validators.max(this.max)(control);

        if (validator && this.maxMessage) {
            validator.max.message = this.maxMessage;
        }

        return validator;
    }
}
