import { Model } from '@models/core/base.model';
import { TimeOffApprovalFlowStep } from '@models/time-off-v3/time-off-approval-flow-step.model';
import { TimeOffApprovalObserver } from '@models/time-off-v3/time-off-approval-observer.model';
import { Company } from '../company/company.model';

export class TimeOffApprovalFlow extends Model {
    protected static _resource = 'timeOffV3/approvalFlows';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['id', 'companyId', 'isAutomatic', 'name'];

    protected static _type = 'timeOffApprovalFlows';

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get isAutomatic(): boolean {
        return this._attributes['isAutomatic'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get timeOffPoliciesCount(): number {
        return this._attributes['timeOffPoliciesCount'];
    }

    get company(): Company {
        return this.hasOne(Company, 'company');
    }

    get timeOffApprovalFlowSteps(): TimeOffApprovalFlowStep[] {
        return this.hasMany(TimeOffApprovalFlowStep, 'timeOffApprovalFlowSteps');
    }

    get timeOffApprovalObservers(): TimeOffApprovalObserver[] {
        return this.hasMany(TimeOffApprovalObserver, 'timeOffApprovalObservers');
    }

    set companyId(val: number) {
        this._attributes['companyId'] = val;
    }

    set isAutomatic(val: boolean) {
        this._attributes['isAutomatic'] = val;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }
}
