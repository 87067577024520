import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { stringToDate } from '@app/functions/string-to-date';
import { TranslationStorageService } from '@app/services';
import { isMoment, Moment } from 'moment';

@Pipe({ name: 'appDate' })
export class AppDatePipe implements PipeTransform {
    datePipe: DatePipe;

    constructor(private translateStorage?: TranslationStorageService) {
        if (!this.translateStorage) {
            this.translateStorage = new TranslationStorageService();
        }
        this.datePipe = new DatePipe(this.translateStorage.getLocale());
    }

    transform(
        value: Moment | Date | string | null,
        format?: string,
        timezone?: string,
        locale?: string
    ): string | null {
        // allow a placeholder - without messing with date parsing.
        if (value === '-') {
            return '-';
        }

        if (isMoment(value)) {
            value = value.toDate();
        }

        return this.datePipe.transform(stringToDate(value), format, timezone, locale);
    }
}
