import { Model } from '@app/models/core/base.model';

export interface Account {
    accountId: string;
    code: string;
    name: string;
    type: string;
}

export class ChartOfAccounts extends Model {
    protected static _type = 'chartOfAccount';

    protected static _resource = 'payroll/companies/:company/chartOfAccounts';

    get allAccounts(): Account[] {
        return this._attributes['allAccounts'];
    }
}
