import { T4 } from '@app/models/payroll/t4.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';

export class DisplayPendingStateColumn extends BadgeColumn<T4> {
    title = '';
    type = CellDisplay.badge;
    displayWidth = 28;

    constructor() {
        super();
    }

    getBadgeDisplay(row: T4): BadgeDisplay | undefined {
        if (row.displayPendingState) {
            return { type: 'warning', label: 'table.t4Index.pending' };
        }
    }
}
