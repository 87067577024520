import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepComponent } from '@app/components';
import { BaseForm } from '@app/modules/forms/base.form';
import { TranslatableKey } from '@app/types/translatable.type';
import { noop } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, takeWhile, timeout } from 'rxjs/operators';

@Component({
    selector: 'app-steps',
    templateUrl: './steps.template.html',
    styleUrls: ['./steps.style.scss'],
})
export class StepsComponent {
    @Input() steps: StepComponent[] = [];
    @Input() stepsStatus: TranslatableKey[] = [];
    @Input() activeStep = 0;

    @Input() labels: Partial<{
        /** Label for the primary action button. Defaults to "Complete" if not provided*/
        primary: TranslatableKey;
        /** Label for the secondary action button. Defaults to "" if not provided*/
        secondary: TranslatableKey;
        /** Label for the button that takes the user to the previous step. Defaults to "Previous" if not provided*/
        previous: TranslatableKey;
        /** Label for the button that takes the user to the next step. Defaults to "Next" if not provided*/
        next: TranslatableKey;
    }> = {};

    @Input() options: Partial<{
        /** Whether the secondary action button should be displayed.*/
        showSecondaryAction: 'ALWAYS' | 'LAST_STEP_ONLY';
        /** Whether the user can navigate through the steps using the top bar.*/
        canNavigateViaTopBar: boolean;
        /** Disable's user's ability to move between steps.*/
        disableNavigation: boolean;
        /** Allows navigation to next step despite the current step's form having validation errors*/
        allowNavigationIfFormIsInvalid: boolean;
        /** Allows completion of steps despite the current step's form having validation errors*/
        allowCompletionIfFormIsInvalid: boolean;
        /** Shows a status label under each step. Status labels provided by stepsStatus array input*/
        showCompletedStatus: boolean;
        /** Can be used to disable ability to move to next step */
        useDisableNextFunctionality: boolean;
        /** Disable's user's ability to complete last step of form.*/
        disableFinish: boolean;
    }> = {};

    @Input() beforeNext?: (step: StepComponent) => Promise<void>;
    @Input() beforePrevious?: (step: StepComponent) => Promise<void>;

    @Output() onPrimary: EventEmitter<null> = new EventEmitter();
    @Output() onSecondary: EventEmitter<null> = new EventEmitter();
    @Output() onNext: EventEmitter<[StepComponent, number]> = new EventEmitter<[StepComponent, number]>();
    @Output() onPrevious: EventEmitter<[StepComponent, number]> = new EventEmitter<[StepComponent, number]>();

    get step(): StepComponent {
        return this.steps[this.activeStep];
    }

    get stepForm(): BaseForm | undefined {
        return this.step.stepForm;
    }

    get isStepFormsDefined(): boolean {
        return this.steps.some((step) => Boolean(step?.stepForm?.form));
    }

    get canPrevious(): boolean {
        if (this.options.disableNavigation) {
            return false;
        }

        return this.hasPrevious;
    }

    get canNext(): boolean {
        if (this.options.disableNavigation) {
            return false;
        }

        if (this.steps[this.activeStep]?.stepForm?.form && this.nextIsEnabled) {
            return true;
        }

        return false;
    }

    get canFinish(): boolean {
        return this.canNext && !this.options.disableFinish;
    }

    get hasPrevious(): boolean {
        return this.activeStep > 0;
    }

    get hasNext(): boolean {
        return this.activeStep < this.steps.length - 1;
    }

    // This will control wether to show the secondary button on all steps or the last one only.
    get isSecondaryActionVisible(): boolean {
        if (!this.options.showSecondaryAction) {
            return false;
        }

        if (this.options.showSecondaryAction === 'LAST_STEP_ONLY') {
            return !this.hasNext;
        }

        return true;
    }

    setStep(index: number): void {
        if (this.options.disableNavigation) {
            return;
        }

        this.activeStep = index;
        this.scrollTop();
    }

    isCurrentStep(step: StepComponent): boolean {
        return this.steps[this.activeStep] === step;
    }

    beforeNextCallback(step: StepComponent): Promise<void> {
        if (this.beforeNext) {
            return this.beforeNext(step);
        }

        return Promise.resolve();
    }

    beforePreviousCallback(step: StepComponent): Promise<void> {
        if (this.beforePrevious) {
            return this.beforePrevious(step);
        }

        return Promise.resolve();
    }

    previous(): void {
        if (this.options.disableNavigation) {
            return;
        }

        this.scrollTop();
        this.beforePreviousCallback(this.steps[this.activeStep])
            .then(() => {
                this.activeStep -= 1;
                this.onPrevious.emit([this.steps[this.activeStep], this.activeStep]);
                this.scrollTop();
            })
            .catch(noop); // Do not throw an error if promise is rejected in the callback
    }

    async next(): Promise<void> {
        if (this.options.disableNavigation) {
            return;
        }

        // Submit the form
        this.stepForm?.form.onSubmit(new Event('submit'));

        // Wait for form to submit before validating
        if (this.stepForm?.form.status === 'PENDING') {
            await this.stepForm?.form.statusChanges
                ?.pipe(
                    takeWhile((status) => status === 'PENDING', true),
                    // Failsafe in case the form gets stuck in a pending state don't block the UI thread for more than 4 seconds
                    timeout(4000),
                    catchError(() => of())
                )
                .toPromise();
        }

        const stepFormIsValid = this.stepForm ? this.stepForm.form.valid : true;
        // As per business logic of the self-onboarding flow, we should be able to step through even if the form
        // is invalid, therefore, added this new input logic
        if (stepFormIsValid || this.options.allowNavigationIfFormIsInvalid) {
            this.scrollTop();
            this.beforeNextCallback(this.steps[this.activeStep])
                .then(() => {
                    this.activeStep += 1;
                    this.onNext.emit([this.steps[this.activeStep], this.activeStep]);
                    this.scrollTop();
                })
                .catch(noop); // Do not throw an error if the promise is rejected in the callback
        }
    }

    /*
     * This is to be used for secondary actions such as previewing
     * before the the user decides to click on "save"
     */
    secondaryClick(): void {
        this.onSecondary.emit();
    }

    finish(): void {
        this.stepForm?.form.onSubmit(new Event('submit'));

        const stepFormIsValid = this.stepForm ? this.stepForm.form.valid : true;
        if (stepFormIsValid || this.options.allowCompletionIfFormIsInvalid) {
            this.onPrimary.emit();
        }
    }

    private scrollTop(): void {
        document.querySelector('.ui.outlet')?.scrollTo({ top: 0, behavior: 'smooth' });
    }

    private get nextIsEnabled(): boolean {
        if (!this.options.useDisableNextFunctionality) {
            return true;
        }

        return this.stepForm ? Boolean(this.stepForm.valid) : true;
    }
}
