import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TranslateDebuggerStoreService {
    readonly LOCAL_STORAGE_KEY = 'language-debug';

    toggleDebugEnabled(): void {
        if (this.isDebugEnabled()) {
            window.localStorage.removeItem(this.LOCAL_STORAGE_KEY);
        } else {
            window.localStorage.setItem(this.LOCAL_STORAGE_KEY, 'true');
        }
    }

    isDebugEnabled(): boolean {
        return window.localStorage.getItem(this.LOCAL_STORAGE_KEY) === 'true';
    }
}
