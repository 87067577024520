// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style, AnimationTriggerMetadata } from '@angular/animations';

export const FadeAnimationOpenClosed = (time: number): AnimationTriggerMetadata => {
    return trigger('fading', [
        state(
            'open',
            style({
                opacity: 1,
            })
        ),
        state(
            'closed',
            style({
                opacity: 0,
                pointerEvents: 'none',
            })
        ),
        transition('open => closed', [animate(time)]),
        transition('closed => open', [animate(time)]),
    ]);
};
