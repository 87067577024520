import { EptSummaryDetails } from '@app/interfaces';
import { Province } from '@app/types/province';
import { Model } from '@models/core/base.model';

export class EptSummary extends Model {
    get periodBalanceDue(): string {
        return this._attributes['periodBalanceDue'];
    }

    get ytdBalanceDue(): number {
        return this._attributes['ytdBalanceDue'];
    }

    get creditsAvailable(): number {
        return this._attributes['creditsAvailable'];
    }

    get exemption(): number {
        return this._attributes['exemption'];
    }

    get ytdTaxPayable(): number {
        return this._attributes['ytdTaxPayable'];
    }

    get periodTaxPayable(): number {
        return this._attributes['periodTaxPayable'];
    }

    get taxRate(): string {
        return this._attributes['taxRate'];
    }

    get totalEptPaid(): number {
        return this._attributes['totalEptPaid'];
    }

    get ytdRemuneration(): number {
        return this._attributes['ytdRemuneration'];
    }

    get periodRemuneration(): number {
        return this._attributes['periodRemuneration'];
    }

    get ytdTaxablePayroll(): number {
        return this._attributes['ytdTaxablePayroll'];
    }

    get periodTaxablePayroll(): number {
        return this._attributes['periodTaxablePayroll'];
    }

    get upcomingFilingDate(): Date {
        return this._attributes['upcomingFilingDate'];
    }

    get upcomingPaymentDate(): Date {
        return this._attributes['upcomingPaymentDate'];
    }

    get upcomingRegistrationDate(): Date {
        return this._attributes['upcomingRegistrationDate'];
    }

    get details(): EptSummaryDetails {
        return this._attributes['details'];
    }

    get jurisdictionArea(): Province {
        return this._attributes['jurisdictionArea'];
    }
}
