import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/services';
import { Payroll } from '@models/payroll/payroll.model';
import {
    TimeWorkedImport,
    TimeWorkedImportPayload,
    TimeWorkedImportState,
} from '@payroll/interfaces/time-worked-import.interface';
import { PayrollResources } from '@payroll/payroll.resources';
import * as Sentry from '@sentry/angular';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This class checks Payroll every five seconds to see if there are any ongoing
 * imports from Scheduling. If the response is true, it will check more
 * frequently (every second), until a false response is received.
 */
@Injectable()
export class CheckForSchedulingImportService implements OnDestroy {
    private isChecking = false;
    private subject$ = new Subject<TimeWorkedImport[]>();

    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    ngOnDestroy(): void {
        this.stopCheckingImportStatus();
    }

    /**
     * Begin polling Payroll for import status of the given payroll object
     */
    checkImportStatusOf$(payroll: Payroll): Observable<TimeWorkedImport[]> {
        this.isChecking = true;
        this.checkForImport(payroll.id);

        return this.subject$.asObservable();
    }

    /**
     * Stop polling Payroll
     */
    stopCheckingImportStatus(): void {
        this.isChecking = false;
    }

    private async checkForImport(payrollId: number): Promise<void> {
        if (!this.isChecking) return;

        const timeWorkedImports = await this.callPayrollEndpoint(payrollId);

        // We check `isChecking` here a second time, as it's possible for its
        // value to have changed while we were awaiting the backend call
        if (!this.isChecking) return;

        // We can tweak these values if necessary / desired, or
        // alternatively implement long-polling on the backend
        const delay = timeWorkedImports.some(({ state }) => state != TimeWorkedImportState.DONE) ? 1000 : 5000;

        setTimeout(() => this.checkForImport(payrollId), delay);
    }

    private async callPayrollEndpoint(payrollId: number): Promise<TimeWorkedImport[]> {
        const endpoint = PayrollResources.TimeWorkedImportStatus.replace(
            ':company_id',
            this.auth.company.id.toString()
        ).replace(':payroll_id', payrollId.toString());

        const timeWorkedImports = await this.http
            .get<TimeWorkedImportPayload>(endpoint)
            .pipe(
                map(({ data }) =>
                    data
                        .filter(({ attributes }) => attributes.payrollId == payrollId)
                        .map(({ attributes }) => attributes)
                )
            )
            .toPromise()
            // If the call fails, don't worry about it. Payroll-side
            // validation will take care of the rest.
            .catch((e) => {
                if (e?.status != HttpStatusCode.NotFound) {
                    Sentry.captureException({
                        name: "Couldn't fetch TimeWorked import status from payroll",
                        message: e,
                    });
                }

                return [];
            });

        this.subject$.next(timeWorkedImports);

        return timeWorkedImports;
    }
}
