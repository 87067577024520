import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titlecase' })
export class TitlecasePipe implements PipeTransform {
    transform(str: string): string {
        if (!str) {
            return '';
        }

        return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
        });
    }
}
