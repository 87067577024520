import { TimeOffType } from '@models/time-off-v3/time-off-type.model';

export interface TimeConversionOptions {
    hoursPerDay: number;
}

export const getTime = (
    value: number,
    timeOffType: TimeOffType,
    options?: TimeConversionOptions
): number | null | undefined => {
    // Prevent NaN
    if (isNaN(value) || value === null) {
        return value;
    }

    const hoursPerDay = options?.hoursPerDay ? options?.hoursPerDay : timeOffType?.hoursPerDay;

    const multiplier = timeOffType?.displayInHours ? hoursPerDay : 1;

    const time = value * multiplier;

    // Reduce decimal precision to hide floating point glitches from UX
    // https://stackoverflow.com/a/588014
    return parseFloat((time ?? 0).toFixed(10));
};

export const setTime = (value: number, timeOffType: TimeOffType): number | null | undefined => {
    // Prevent NaN
    if (isNaN(value) || value === null) {
        return value;
    }

    const divisor = timeOffType?.displayInHours ? timeOffType.hoursPerDay : 1;

    return value / divisor;
};
