import { JobAttachmentTypes } from '@app/modules/applicant-tracker/enums/job-attachment-types.enum';
import { ModelMixin } from '../core/base-generic.model';

export class JobAttachment extends ModelMixin<JobAttachment>() {
    protected static _resource = 'applicantTracker/jobAttachments';
    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _serializeAttributes = ['name', 'applicantId', 'isRequired', 'jobAttachmentTypeId', 'atsJobId'];

    linkAttached = false;
    link: string;
    fileId: number;

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get atsJobId(): number {
        return this._attributes['atsJobId'];
    }

    set atsJobId(val: number) {
        this._attributes['atsJobId'] = val;
    }

    get applicantId(): number {
        return this._attributes['applicantId'];
    }

    set applicantId(val: number) {
        this._attributes['applicantId'] = val;
    }

    get jobAttachmentTypeId(): number {
        return this._attributes['jobAttachmentTypeId'];
    }

    set jobAttachmentTypeId(val: number) {
        this._attributes['jobAttachmentTypeId'] = val;
    }

    get isRequired(): boolean {
        return this._attributes['isRequired'];
    }

    set isRequired(val: boolean) {
        this._attributes['isRequired'] = val;
    }

    get jobAttachmentType(): JobAttachmentTypes {
        return JobAttachmentTypes[JobAttachmentTypes[this.jobAttachmentTypeId]];
    }

    get jobAttachmentTypeName(): any {
        return JobAttachmentTypes[this.jobAttachmentTypeId];
    }

    clone(): JobAttachment {
        const attributesToClone = this.getAttributes();
        delete attributesToClone['id'];
        delete attributesToClone['atsJobId'];

        return new JobAttachment(attributesToClone);
    }
}
