import { Component, ElementRef, Input } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { CompanyDocument } from '@app/models/documents/company-document.model';
import { DocumentTag } from '@app/models/documents/document-tag.model';
import { AuthService } from '@app/services/auth.service';
import { FeatureService } from '@app/services/feature.service';
import { BulkSelectBaseDialogComponent } from '../bulk-select-base-dialog/bulk-select-base-dialog.component';

@Component({
    selector: 'app-bulk-select-company-documents-dialog',
    templateUrl: './bulk-select-company-documents-dialog.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectCompanyDocumentsDialogComponent extends BulkSelectBaseDialogComponent {
    @Input() label = 'select';
    @Input() headerText = 'components.bulk-select-company-documents-dialog.selectDocuments';
    @Input() allowShowOfferletter: boolean;

    filters: any = {
        tags: 'components.bulk-select-company-documents-dialog.tags',
    };
    filterValues: any = {
        tags: {},
    };
    activeFilters: any = {};

    items: CompanyDocument[] = [];
    selectItems: CompanyDocument[] = [];

    private allowMultiSignDocuments = false;

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        private featureService: FeatureService
    ) {
        super(element, auth);
        this.setFeatureFlag();
    }

    selectAll(): void {
        let request = CompanyDocument.when(!this.allowMultiSignDocuments, (query: QueryFetcher) =>
            query.where('onlyBulkAssignable', true)
        )
            .where('excludeOfferLetters', !this.allowShowOfferletter)
            .with([
                'documentTags',
                'signingTemplate',
                'signingTemplate.slots',
                'signingTemplate.slots.assignee',
                'signingTemplate.slots.customFieldAssignments',
            ])
            .limit(0);

        request = this.applyQuery(request);
        request = this.applyFilters(request);

        request
            .get()
            .then(([documents]) => {
                this.selectItems = documents;
                this.updateModelSelection();
            })
            .catch(() => {});
    }

    /**
     * Reset the form
     */
    protected loadFilterOptions(): void {
        this.loadTags();
    }

    protected loadItems(): void {
        let request = CompanyDocument.when(!this.allowMultiSignDocuments, (query: QueryFetcher) =>
            query.where('onlyBulkAssignable', true)
        )
            .where('excludeOfferLetters', !this.allowShowOfferletter)
            .with([
                'documentTags',
                'signingTemplate',
                'signingTemplate.slots',
                'signingTemplate.slots.assignee',
                'signingTemplate.slots.customFieldAssignments',
            ])
            .page(this.currentPage);

        request = this.applyQuery(request);
        request = this.applyFilters(request);

        request
            .get()
            .then(([documents, meta]) => {
                this.totalResults = meta.pagination.total;
                this.currentPage = meta.pagination.page;
                this.items = documents;
                this.isLoading = false;
            })
            .catch(() => {});
    }

    private async loadTags(): Promise<void> {
        const [documentTags] = await DocumentTag.all();

        for (const tag of documentTags) {
            this.filterValues.tags[tag.id] = tag.name;
        }
    }

    private async setFeatureFlag() {
        this.allowMultiSignDocuments = await this.featureService.has(FeatureFlag.onboardingMultiSignDocuments);
    }
}
