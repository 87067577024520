<div class="wrapper" [class.closeable]="closeable" [class.light]="light">
    <img *ngIf="closeable" class="close" (click)="onClose()" appIcon="CrossGreyUiOutline" />
    <div *ngIf="header" class="flag-header">
        <img *ngIf="icon" class="icon" [ngClass]="iconType" [appIcon]="icon" />
        <h3>{{ header }}</h3>
    </div>
    <div class="flag-body">
        <div class="content">
            <ng-content></ng-content>
            <ui-button *ngIf="buttonText" type="link">{{ buttonText }}</ui-button>
        </div>
    </div>
</div>
