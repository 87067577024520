import { Component, Input } from '@angular/core';
import { TimeOffPolicy } from '@app/models/time-off-v3/time-off-policy.model';
import { TimeOffBalance } from '@app/modules/time-off-v3/interfaces/time-off-balance.interface';
import { DECIMAL_PLACES_FOR_PIPE } from '@app/modules/time-off-v3/meta/time-off-meta';
import { Translatable } from '@app/types/translatable.type';
import { TimeOffv3DateDisplayOptions } from '@app/types/translatables/time-off-v3-display.options';
import { DisplayDateSetting, ProfileBalanceDateSetting } from './time-off-profile-balance-display.service';

@Component({
    selector: 'app-time-off-policy-card',
    templateUrl: './time-off-policy-card.template.html',
    styleUrls: ['./time-off-policy-card.style.scss'],
})
export class TimeOffPolicyCard {
    @Input() timeOffTypeName: Translatable = 'components.time-off-policy-card.vacation';
    @Input() timeOffPolicy: TimeOffPolicy;
    @Input() amount = 0;
    @Input() unitOfTime: Translatable = 'time-off-v3.dayPlural';
    @Input() color = 'grey';
    @Input() icon = '';
    @Input() isUnlimited = false;
    @Input() balanceDateSetting: ProfileBalanceDateSetting = DisplayDateSetting.TODAY;
    @Input() balanceDateLabel: TimeOffBalance['balanceDateLabel'];
    @Input() isAdmin = false;

    decimalPlacesForPipe = DECIMAL_PLACES_FOR_PIPE;

    dateDisplayOptions = TimeOffv3DateDisplayOptions;
}
