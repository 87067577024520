<div class="container padding-3 flex col" tabindex="0" #dialog [@fading]="open ? 'open' : 'closed'">
    <div class="flex row justify-space-between">
        <div class="form-container">
            <ng-content></ng-content>
        </div>

        <ui-button
            [submit]="true"
            *ngIf="primaryButtonLabel"
            (click)="onPrimaryButtonPressed()"
            [disabled]="_actionsLocked"
            [type]="primaryButtonType"
            >{{ primaryButtonLabel }}</ui-button
        >
    </div>
</div>
