import { Model } from '../core/base.model';

import { SimpleEntityActions } from '@app/classes';

export class Permissible extends Model {
    protected static _resource = 'accountManagement/permissibles';

    protected static _version = 'v2';

    static permission = new SimpleEntityActions('permissible');

    get name(): string {
        return this._attributes['name'];
    }

    get entityType(): string {
        return this._attributes['entityType'];
    }

    get entityId(): number {
        return this._attributes['entityId'];
    }

    get entity(): any {
        return this._attributes['entity'];
    }
}
