import { Directive, ElementRef, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { Address } from '@app/modules/applicant-tracker/interfaces/address.interface';
import { environment } from '@env/environment';
import { isEmpty } from 'lodash-es';

@Directive({
    selector: '[appAddressAutoComplete]',
})
export class AddressAutoCompleteDirective implements OnInit {
    @Output() onAddressChange = new EventEmitter<Address>();

    private placesApi: google.maps.places.Autocomplete;
    private addressNode: HTMLInputElement | null = null;

    constructor(
        private elRef: ElementRef,
        private ngZone: NgZone
    ) {
        this.addressNode = this.elRef.nativeElement as HTMLInputElement;
    }

    async ngOnInit(): Promise<void> {
        if (!this.placesApiIsLoaded()) {
            this.loadPlacesApi();
            return;
        }

        this.initAutocomplete();
    }

    private initAutocomplete(): void {
        if (isEmpty(this.addressNode)) {
            return;
        }

        setTimeout(() => {
            this.placesApi = new google.maps.places.Autocomplete(this.addressNode, {
                types: ['address'], // add 'establishment' if we also want to allow search malls, restaurants and the like
                //if adding establishment then somehow the plugin fails to load..
            });

            if (!this.placesApi) {
                console.error('The Google Autocomplete library was not properly loaded/initialized');
                return;
            }

            if (!this.placesApi.addListener != null) {
                // Check to bypass https://github.com/angular-ui/angular-google-maps/issues/270
                this.placesApi.addListener('place_changed', () => {
                    this.handlePlaceChangedEvent();
                });
            }
        });
    }

    private handlePlaceChangedEvent() {
        this.ngZone.run(() => {
            // Get the place details from the autocomplete object.
            const place = this.placesApi.getPlace();
            if (!place) {
                return;
            }
            if (place.name === this.addressNode.value) {
                //only triggered if user clicks enter key wihout making any selection
                return;
            }
            this.onAddressChange.emit(this.mapGooglePlaceToAddress(place));
        });
    }

    private placesApiIsLoaded(): boolean {
        return document.body.contains(document.getElementById('googlePlacesApiScript'));
    }

    private loadPlacesApi(): void {
        window.addEventListener('location-script-loaded', () => {
            this.initAutocomplete();
        });
        const script = document.createElement('script');
        script.id = 'googlePlacesApiScript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`;
        script.async = true;
        script.onload = function () {
            window.dispatchEvent(new Event('location-script-loaded'));
        };
        document.body.append(script);
    }

    private mapGooglePlaceToAddress(place: google.maps.places.PlaceResult): Address {
        let addressFound: Address = {};

        let streetNumber = '';
        let streetName = '';
        let subLocality = '';
        let subLocalityLevelOne = '';
        let subLocalityLevelTwo = '';
        let subLocalityLevelThree = '';
        //address_components types are documented at https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
        for (let index = place.address_components.length - 1; index > -1; index--) {
            const element = place.address_components[index];
            if (element.types.includes('postal_code')) {
                addressFound.postalCode = element.long_name;
                continue;
            }
            if (element.types.includes('country')) {
                addressFound.country = element.long_name;
                continue;
            }
            if (element.types.includes('administrative_area_level_1')) {
                addressFound.region = element.long_name;
                continue;
            }
            if (element.types.includes('locality')) {
                addressFound.city = element.long_name;
                continue;
            }
            if (element.types.includes('sublocality')) {
                subLocality = element.long_name;
                continue;
            }
            if (element.types.includes('sublocality_level_1')) {
                subLocalityLevelOne = element.long_name;
                continue;
            }
            if (element.types.includes('sublocality_level_2')) {
                subLocalityLevelTwo = element.long_name;
                continue;
            }
            if (element.types.includes('sublocality_level_3')) {
                subLocalityLevelThree = element.long_name;
                continue;
            }
            if (element.types.includes('street_address')) {
                addressFound.line1 = element.long_name;
                continue;
            }
            if (element.types.includes('street_number')) {
                streetNumber = element.long_name;
                continue;
            }
            if (element.types.includes('subpremise')) {
                addressFound.line2 = element.long_name;
                continue;
            }
            if (element.types.includes('route')) {
                streetName = element.long_name;
                continue;
            }
        }

        if (isEmpty(addressFound.city)) {
            addressFound.city = this.getFinalLocality([
                subLocality,
                subLocalityLevelOne,
                subLocalityLevelTwo,
                subLocalityLevelThree,
            ]);
        }

        if (isEmpty(addressFound.line1) && !isEmpty(streetName)) {
            addressFound.line1 = streetNumber + ' ' + streetName;
        }

        if (addressFound.city && addressFound.region && addressFound.country && addressFound.line1) {
            addressFound.latitude = place.geometry.location.lat();
            addressFound.longitude = place.geometry.location.lng();
        }

        return addressFound;
    }

    private getFinalLocality(localities: string[]): string {
        for (let locality of localities) {
            if (!isEmpty(locality)) {
                return locality;
            }
        }

        return '';
    }
}
