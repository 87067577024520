import { Table } from '@app/modules/table/classes/table.class';
import { Paginator, SortDirection } from '@app/classes';
import { AuthService } from '@app/services';
import { NameColumn } from './columns/name.column';
import { CreatedAtColumn } from './columns/created-at.column';
import { T4Summary } from '@models/payroll/t4-summary.model';
import { StatusColumn } from './columns/status.column';

const pageSize = 10;

export class EmployerPackageTable extends Table {
    sortDirection = SortDirection.ASC;
    links = true;

    protected sortProperty = 'name';

    constructor(
        protected auth: AuthService,
        public year: number
    ) {
        super(auth);

        this.boot();
    }

    setColumns(): void {
        const nameColumn = new NameColumn();
        this.sortColumn = nameColumn;
        this.columns = [nameColumn, new CreatedAtColumn(), new StatusColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            T4Summary.param('company', this.auth.company?.id).where('year', this.year.toString()).limit(pageSize),
            {
                pageSize: pageSize,
            }
        );
    }

    getLink(t4Summary: T4Summary): string[] {
        return ['/payroll/records/tax-forms/t4-summary', t4Summary.id.toString()];
    }
}
