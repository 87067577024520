import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { badgeStyles } from './styles';
import { BadgeType } from './types';

@customElement('humi-badge')
export class Badge extends LitElement {
    static styles = badgeStyles;

    /**
     * The variations of the pill will effect background and font color to indicate purpose
     */
    @property()
    variant: BadgeType = 'default';

    render() {
        return html`<div class="badge ${this.variant}">
            <slot></slot>
        </div>`;
    }
}
