import { Injectable } from '@angular/core';
import { ConflictedEmployee } from '@app/models/payroll/conflicted-employee.model';
import { AuthService, NotifyService } from '@app/services';
import { ErrorParser } from '@app/classes';
import { Employee } from '@app/models/employee/employee.model';

@Injectable()
export class TimeOffConflictService {
    constructor(
        private notify: NotifyService,
        private auth: AuthService
    ) {}

    async updateConflictedEmployee(conflictedEmployee: ConflictedEmployee): Promise<void> {
        const employee = new Employee(conflictedEmployee.getAttributes());
        try {
            await employee.param('company', this.auth.company.id).save({ onlyDirty: false });
        } catch (error) {
            this.notify.error(error);
        }
    }

    async loadConflictedEmployees(
        timeOffPaidVacationId: number,
        payrollChequeDate?: string
    ): Promise<ConflictedEmployee[]> {
        let conflictedEmployeesQuery = ConflictedEmployee.where('timeOffTypeId', timeOffPaidVacationId);
        if (payrollChequeDate) {
            conflictedEmployeesQuery = ConflictedEmployee.where('timeOffTypeId', timeOffPaidVacationId).where(
                'asOfDate',
                payrollChequeDate
            );
        }
        try {
            const [conflictedEmployees] = await conflictedEmployeesQuery.get();
            return conflictedEmployees;
        } catch (error) {
            this.notify.error(ErrorParser.parse(error));
        }
    }
}
