import { HttpErrorResponse } from '@angular/common/http';

export class GatewayTimeoutError extends Error {
    httpStatus = 504;

    constructor(res: HttpErrorResponse, message?: string) {
        super(message ? message : res.statusText);
        Object.setPrototypeOf(this, GatewayTimeoutError.prototype);
    }
}
