import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icons } from '@app/enums';

/**
	@deprecated Please use mat-icon instead
*/
@Component({
    selector: 'ui-flag-deprecated',
    templateUrl: './flag.template.html',
    styleUrls: ['./flag.style.scss'],
})
export class FlagComponent implements OnInit {
    @Input() icon: Icons;
    @Input() light = false;
    @Input() header = '';
    @Input() buttonText = '';
    @Input() iconType: 'primary' | 'warning' | 'danger' = 'primary';
    @Input() closeable = false;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit(): void {
        // eslint-disable-next-line no-console
        console.warn('ui-flag-deprecated has been deprecated.', 'Please use mat-icon instead');
    }

    onClose(): void {
        this.close.emit();
    }
}
