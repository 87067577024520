import { T4 } from '@app/models/payroll/t4.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';

export class StatusColumn extends BadgeColumn<T4> {
    title = 'table.t4Index.status';
    property = 'state';
    sortField = 'state';
    sortable = true;
    type: CellDisplay = CellDisplay.badge;
    // Making this one narrow so we can fit the DisplayPendingState column on its right
    displayWidth = 5;

    getBadgeDisplay(t4: T4): BadgeDisplay {
        return t4.stateBadge();
    }
}
