import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-common-label-item',
    templateUrl: './label-item.template.html',
    styleUrls: ['./label-item.style.scss'],
})
export class LabelItemComponent {
    @Input() label: string;
    @Input() removable = true;
    @Output() remove: EventEmitter<any> = new EventEmitter<any>();

    onRemove(): void {
        this.remove.emit();
    }
}
