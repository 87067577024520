import { Injectable, OnDestroy } from '@angular/core';
import { ASYNC_TASK_EVENT_TYPES } from '@app/enums';
import { AsyncSystemTask } from '@app/models/common/async-system-tasks.model';
import { Observable, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { SocketService } from './socket.service';

const DEBOUNCE = 500;
const TASKS_TO_LOAD = 5;

/**
 * Used by the AsyncSystemTray component to provide
 * updates on the current user's asyncSystemTasks
 */
@Injectable({ providedIn: 'root' })
export class AsyncSystemTaskTrayService implements OnDestroy {
    tasks$: Observable<AsyncSystemTask[]>;

    private _tasks$: Subject<AsyncSystemTask[]> = new Subject();
    private _shouldStopListeningOnSocket$ = new Subject<void>();
    private _onDestroy$ = new Subject<void>();

    constructor(
        private auth: AuthService,
        private socket: SocketService
    ) {
        this.tasks$ = this._tasks$.asObservable();
        this._subscribeToAuthEvents();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    async markAsRead(task: AsyncSystemTask): Promise<void> {
        task.readAt = new Date();
        await task.save();
        this._loadAsyncTasks();
    }

    /**
     * Begin listening on the socket when the user has authenticated
     * While listening to the socket, on any event load more async tasks
     * Stop listening on the socket after the suer has unauthenticated
     */
    private _subscribeToAuthEvents(): void {
        this.auth.onHydrate.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
            this.socket
                .getEmployeeChannel(this.auth.employee, ASYNC_TASK_EVENT_TYPES)
                .pipe(takeUntil(this._shouldStopListeningOnSocket$), startWith(null), debounceTime(DEBOUNCE))
                .subscribe(async () => await this._loadAsyncTasks());
        });

        this.auth.onLogout.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
            this._tasks$.next([]);
            this._shouldStopListeningOnSocket$.next();
        });
    }

    private async _loadAsyncTasks(): Promise<void> {
        const [tasks] = await AsyncSystemTask.limit(TASKS_TO_LOAD).where('onlyUnread', true).get();
        this._tasks$.next(tasks);
    }
}
