import { Model } from '../core/base.model';

import { SimpleEntityActions } from '@app/classes';

export class Email extends Model {
    get id(): number {
        return +this._attributes['id'];
    }

    get address(): string {
        return this._attributes['address'] || undefined;
    }

    set address(val: string) {
        this._attributes['address'] = val;
    }

    get isVerified(): boolean {
        return this._attributes['isVerified'];
    }

    get isPrimary(): boolean {
        return this._attributes['isPrimary'];
    }

    get createdAt(): string {
        return this._attributes['createdAt'];
    }
    protected static _resource = 'accountManagement/accounts/:account/emails';
    protected static _type = 'accountEmails';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['address'];

    static permission = new SimpleEntityActions('accountEmails');

    /** Additional HTTP Methods */
    /**
     * Resend the verificaiton email
     * @param {string} token
     * @returns {Promise<any>}
     */
    resendVerification(): Promise<any> {
        return Model.performGet(this.urlGetRoute('sendVerificationEmail'));
    }

    /**
     * Make email primary
     * @param {string} token
     * @returns {Promise<any>}
     */
    makePrimary(): Promise<any> {
        this._attributes['isPrimary'] = true;
        return Model.performGet(this.urlGetRoute('makePrimary'));
    }

    private urlGetRoute(newSlug = ''): string {
        return (
            Email._version +
            '/' +
            Email._resource.replace(':account', this._attributes['accountId']) +
            '/' +
            this.id +
            '/' +
            newSlug
        );
    }
}
