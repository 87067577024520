import { Model } from '@models/core/base.model';
export class PayableItem extends Model {
    protected static _resource = 'payroll/payableItems';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['paystubId', 'name', 'amount', 'statutory', 'payee'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get payee(): string {
        return this._attributes['payee'];
    }

    set payee(val: string) {
        this._attributes['payee'] = val;
    }

    get paystubId(): number {
        return this._attributes['paystubId'];
    }

    set paystubId(val: number) {
        this._attributes['paystubId'] = val;
    }

    get amount(): number {
        return this._attributes['amount'];
    }

    set amount(val: number) {
        this._attributes['amount'] = val;
    }

    get statutory(): boolean {
        return this._attributes['statutory'];
    }

    set statutory(val: boolean) {
        this._attributes['statutory'] = val;
    }
}
