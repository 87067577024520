import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

/**
 * This validator to allows for hooking backend T4 errors into an Angular form, so they show up in the error field
 */
@Directive({
    selector: '[appBackendT4Errors]',
    providers: [{ provide: NG_VALIDATORS, useExisting: BackendT4ErrorsValidator, multi: true }],
})
export class BackendT4ErrorsValidator implements Validator {
    @Input('appBackendT4Errors') error?: string;

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.error) return null;

        // Once user has changed, remove error state
        if (control.dirty) return null;

        return { custom: { message: this.error } };
    }
}
