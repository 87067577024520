import { Model } from '@models/core/base.model';

export abstract class SettingsIntegrationBase extends Model {
    protected static _type = 'integrationDetails';

    protected static _resource = 'companyManagement/companies/:company/integrations/generic';

    protected static _version = 'v2';

    get integrationId(): number {
        return this._attributes['integrationId'];
    }
}
