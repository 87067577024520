import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class VacationYtdAdjusterService {
    constructor(private http: HttpClient) {}

    async fetchLatestEmployeeVacationYtdAdjustment(employeeId: string): Promise<string | null> {
        const url = PayrollResources.LatestVacationYtdAdjustment.replace(':employee', employeeId.toString());
        const latestEmployeeVacationYtdAdjustmentPromise = await this.http.get(url).toPromise();
        const latestEmployeeVacationYtdAdjustment =
            latestEmployeeVacationYtdAdjustmentPromise['data']['attributes'].latestYtdBalance;
        return latestEmployeeVacationYtdAdjustment;
    }

    async saveEmployeeVacationYtdAdjustment(
        employeeId: number,
        adjustmentAmount: string | null,
        adjustmentMessage: string | null
    ) {
        const vacationYtdAdjustmentPayload = {
            data: {
                type: 'adjustments',
                attributes: {
                    amount: adjustmentAmount,
                    message: adjustmentMessage,
                },
            },
        };
        await this.http
            .post(
                PayrollResources.MakeVacationYtdAdjustment.replace(':employee', employeeId.toString()),
                vacationYtdAdjustmentPayload
            )
            .toPromise();
    }
}
