<div class="date-container">
    <ui-button size="mobile-shrink" (click)="onClickToday()" type="outline">{{ 'today' | translate }}</ui-button>

    <div class="arrows">
        <mat-icon (click)="onMovePreviousMonth()" svgIcon="chevronLeft" />
        <mat-icon (click)="onMoveNextMonth()" svgIcon="chevronRight" />
    </div>

    <div class="date-title" (click)="onShowDatePicker()">
        <h2 class="no-margin">
            <!-- Opting to use ngx-translate via lowercase month keys, rather than translations provided by built-in Angular Date Pipe via appDate.
            This allows for more control of the strings provided by all locales since they can be updated within the crowdin JSON files-->
            {{ date | appDate: 'MMMM' : undefined : 'en_CA' | lowercase | appTranslate }}&nbsp;
            <span class="date-year">{{ date | appDate: 'yyyy' }}</span>
        </h2>
        <input type="date" />
    </div>
</div>
