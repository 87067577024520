import { Model } from '@models/core/base.model';
import { PayrollEmployee } from '@models/payroll/payroll-employee.model';

/**
 * EmployeeBenefit is an Employee's instance of a Benefit
 *
 * Each employee assigned to a Benefit can have different amounts
 * for the Deduction (Employee Premium) and Benefit (Company Contribution)
 *
 * Ex: Sarah has to pay a $200 premium and the company contributes 50%
 * but Alvin has to pay a $100 premium and the company contributes 25%
 */
export class EmployeeBenefit extends Model {
    protected static _resource = 'payroll/employees/:employee/employeeBenefits';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['benefitId', 'employeePremium', 'companyContribution'];

    get benefitId(): number {
        return this._attributes['benefitId'];
    }

    set benefitId(val: number) {
        this._attributes['benefitId'] = val;
    }

    get employeeId(): number {
        return Number(this._attributes['employeeId']);
    }

    get employeePremium(): number {
        return this._attributes['employeePremium'];
    }

    set employeePremium(val: number) {
        this._attributes['employeePremium'] = val;
    }

    get companyContribution(): number {
        return this._attributes['companyContribution'];
    }

    set companyContribution(val: number) {
        this._attributes['companyContribution'] = val;
    }

    get prEmployeeId(): number {
        return this._attributes['employeeId'];
    }

    get employee(): PayrollEmployee {
        return this.hasOne(PayrollEmployee, 'employees');
    }
}
