import { Component, ElementRef, Input } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { Role } from '@models/account/role.model';

import { BulkSelectBaseDialogComponent } from '../bulk-select-base-dialog/bulk-select-base-dialog.component';
import { RoleNameDisplayOptions } from '@app/types/translatables/role-display.options';

@Component({
    selector: 'app-common-bulk-select-role-dialog',
    templateUrl: './bulk-select-roles.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectRolesComponent extends BulkSelectBaseDialogComponent {
    @Input() label = 'select';
    @Input() headerText = 'components.bulk-select-role-dialog.selectRoles';
    @Input() disabled = false;

    readonly restrictedRoles: string[] = ['Super Admin', 'No Access', 'Basic Access'];

    items: Role[] = [];
    selectItems: Role[] = [];

    roleDisplayOptions = RoleNameDisplayOptions;

    protected _assignableBy: Role = null;

    constructor(
        protected element: ElementRef,
        protected auth: AuthService
    ) {
        super(element, auth);
    }

    assignableBy(role: Role = null): BulkSelectRolesComponent {
        this._assignableBy = role;
        return this;
    }

    selectAll(): void {
        let request = Role.where('company_id', this.auth.company.id)
            .where('assignableBy', this._assignableBy?.id)
            .limit(0);

        request = this.applyQuery(request);

        request = this.applyFilters(request);

        request
            .get()
            .then(([roles]) => {
                roles = roles.filter((role) => !this.restrictedRoles.includes(role.name));
                this.selectItems = roles;
                this.updateModelSelection();
            })
            .catch(() => {});
    }

    protected loadItems(): void {
        let request = Role.where('company_id', this.auth.company.id)
            .where('assignableBy', this._assignableBy?.id)
            .page(this.currentPage);

        request = this.applyQuery(request);
        request = this.applyFilters(request);

        request
            .get()
            .then(([roles, meta]) => {
                let filteredOut = 0;
                roles = roles.filter((role) => {
                    if (this.restrictedRoles.includes(role.name)) {
                        filteredOut++;
                        return false;
                    }

                    return true;
                });

                // Alphabetically sort roles by name
                roles.sort((a, b) => a.name.localeCompare(b.name));

                this.totalResults = meta.pagination.total - filteredOut;
                this.currentPage = meta.pagination.page;
                this.items = roles;
                this.isLoading = false;
            })
            .catch(() => {});
    }
}
