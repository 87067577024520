import { Component, Input, OnInit } from '@angular/core';
import { Employee } from '@models/employee/employee.model';
import { DefaultIconSize, Size } from '@app/components/avatar/avatar.component';

@Component({
    selector: 'app-inline-avatars',
    templateUrl: 'inline-avatars.template.html',
    styleUrls: ['inline-avatars.style.scss'],
})
export class InlineAvatarsComponent implements OnInit {
    @Input() numberOfEmployeesToShow = 2;

    @Input() showEmployeeName = true;

    /**
     * If true, will only show the first employees name, then "+n more" - but will show all icons.
     */
    @Input() showShortNameCopy = false;

    @Input() employees: Employee[] = [];
    @Input() size: Size = DefaultIconSize;
    @Input() withMargins = true;

    employeesToShow: Employee[] = [];

    employeesNames = '';
    ellipsisEmployeesNames = '';

    ngOnInit(): void {
        this.updateEmployeeData();
    }

    ngOnChanges(): void {
        this.updateEmployeeData();
    }

    private updateEmployeeData(): void {
        this.employeesToShow = this.employees.slice(0, this.numberOfEmployeesToShow);
        this.employeesNames = this.getEmployeesName();
        this.ellipsisEmployeesNames = this.getEllipsisEmployeesName();
    }

    private getEmployeesName(): string {
        const employeesName = this.employeesToShow.map((employee: Employee) => employee.fullName).join(', ');

        if (this.showShortNameCopy && this.employees?.length > 1) {
            return `${this.employees[0]?.fullName} + ${this.employees.length - 1} more`;
        }

        if (this.employees?.length <= this.numberOfEmployeesToShow) {
            return employeesName;
        }

        return `${employeesName} + ${this.employees.length - this.numberOfEmployeesToShow} more`;
    }

    private getEllipsisEmployeesName(): string {
        return this.employees
            .slice(this.numberOfEmployeesToShow)
            .map((employee: Employee) => employee.fullName)
            .join(', ');
    }
}
