import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class LastModifiedColumn extends Column {
    title = 'table.t4Index.lastModified';
    property = 'modifiedAt';
    sortField = 'modifiedAt';
    sortable = true;
    type: CellDisplay = CellDisplay.date;
    displayWidth = 33;
}
