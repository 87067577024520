import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { UploadedImage } from '@app/classes';
import { FileUploadDirectories } from '@app/enums';
import { AuthService, FileHelperService } from '@app/services';
import { Employee } from '@models/employee/employee.model';

export type AvatarUploadEvent = { isSuccessful: boolean };

// We save all images as png
// the format does not matter to the user
const pngMimeType = 'image/png';

@Component({
    selector: 'app-avatar-upload',
    templateUrl: './avatar-upload.template.html',
    styleUrls: ['./avatar-upload.style.scss'],
})
export class AvatarUpload {
    @Input() employee: Employee = new Employee();
    @Output() onUpload: EventEmitter<AvatarUploadEvent> = new EventEmitter<AvatarUploadEvent>();
    image: UploadedImage | null = null;
    uploadProgress = 0;

    constructor(
        public auth: AuthService,
        protected fileHelperService: FileHelperService,
        protected ngZone: NgZone
    ) {}

    setUploadedImage(image: UploadedImage): void {
        this.image = image;
    }

    upload(canvas: HTMLCanvasElement, originalFile: UploadedImage): void {
        this.uploadProgress = 0;
        canvas.toBlob((blob: Blob | null) => {
            // why ngZone?
            // https://stackoverflow.com/questions/63712127/why-is-angulars-changedetection-not-triggered-when-using-canvas-toblob-with-a
            this.ngZone.run(() => {
                if (!blob) {
                    this.onUpload.emit({ isSuccessful: false });
                    return;
                }

                const newFile = new File([blob], originalFile.file.name, { type: pngMimeType });

                this.fileHelperService
                    .storeFileAndGetResponseStream(newFile, FileUploadDirectories.avatars, true)
                    .subscribe(async (event) => {
                        if (event.status && event.status >= 400) {
                            this.onUpload.emit({ isSuccessful: false });
                            return;
                        }

                        if (event.type === HttpEventType.UploadProgress) {
                            this.uploadProgress = Math.round((100 * event.loaded) / event.total);
                            return;
                        }

                        if (event instanceof HttpResponse) {
                            const saveEmployee = new Employee(this.employee['_attributes']);
                            saveEmployee.avatarId = event.body['data']['id'];
                            try {
                                await saveEmployee.param('company', this.auth.company.id).save();
                                this.employee.avatarId = saveEmployee.avatarId;

                                if (this.auth.employee.is(this.employee)) {
                                    this.auth.employee.avatarId = this.employee.avatarId;
                                }

                                this.uploadProgress = 0;
                                this.onUpload.emit({ isSuccessful: true });
                            } catch (err) {
                                this.onUpload.emit({ isSuccessful: false });
                            }
                        }
                    });
            }, pngMimeType);
        });
    }
}
