import { AppDatePipe } from '@app/pipes';
import { Model } from '@models/core/base.model';
import { TimeOffDay } from '@models/time-off-v3/time-off-day.model';
import { TimeOffRequest } from '@models/time-off-v3/time-off-request.model';
import { TimeOffType } from '@models/time-off-v3/time-off-type.model';
import { getTime, setTime } from '@time-off-v3/functions/time-unit-converter';
import { TimeOffDateFormatDashes, TimeOffDateMonthDayDateShortCommaPipeFormat } from '@time-off-v3/meta/time-off-meta';
import moment from 'moment';

export interface TimeOffRequestDayPayload {
    amount: number;
    timeOffRequestId?: number;
    date: string;
}

export class TimeOffRequestDay extends Model {
    protected static _type = 'timeOffRequestDays';
    protected static _dates = ['date'];
    protected static _version = 'v2';
    protected static _serializeAttributes = ['timeOffRequestId', 'defaultAmount', 'amount', 'date'];
    protected static _resource = 'timeOffV3/requests/preview';
    private appDatePipe = new AppDatePipe();

    get timeOffRequestId(): number {
        return this._attributes['timeOffRequestId'];
    }

    get defaultAmount(): number {
        return getTime(this._attributes['defaultAmount'], this.timeOffType);
    }

    get amount(): number {
        return getTime(this._attributes['amount'], this.timeOffType);
    }

    get date(): Date {
        return this._attributes['date'];
    }

    get week(): string {
        const date = moment(this.date);
        const isoWeekMax = 53;
        const isFirstWeek = date.week() === 1;
        const isFirstMonth = date.month() === 0;

        const weekNumber = isFirstWeek && !isFirstMonth ? isoWeekMax : date.week();

        return `${date.year()}-${weekNumber}`;
    }

    get weekDay(): number {
        return moment(this.date).weekday();
    }

    get shortDateString(): string {
        return this.appDatePipe.transform(moment(this.date), TimeOffDateMonthDayDateShortCommaPipeFormat);
    }

    get timeOffRequest(): TimeOffRequest {
        return this.hasOne(TimeOffRequest, 'timeOffRequest');
    }

    get timeOffDay(): TimeOffDay {
        return this.hasOne(TimeOffDay, 'timeOffDay');
    }

    get isWeekend(): boolean {
        return this._attributes['isWeekend'];
    }

    get isHoliday(): boolean {
        return !!this.timeOffDay;
    }

    get timeOffType(): TimeOffType {
        return this.hasOne(TimeOffType, 'timeOffType');
    }

    set date(val: Date) {
        this._attributes['date'] = val;
    }

    set timeOffRequest(val: TimeOffRequest) {
        this.setOne('timeOffRequest', val);
    }

    set timeOffType(val: TimeOffType) {
        this.setOne('timeOffType', val);
    }

    set amount(val: number) {
        this._attributes['amount'] = setTime(val, this.timeOffType);
    }

    toPayload(): TimeOffRequestDayPayload {
        return {
            timeOffRequestId: this.timeOffRequestId,
            date: moment(this.date).format(TimeOffDateFormatDashes),
            amount: this._attributes['amount'],
        };
    }
}
