import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import moment from 'moment';

@Directive({
    selector: '[dataFieldTypeRules]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DataFieldTypeRulesValidator, multi: true }],
})
export class DataFieldTypeRulesValidator implements Validator {
    @Input('dataFieldTypeRules') dataFieldTypeRules: string;

    validate(control: AbstractControl): { [key: string]: any } {
        let valid = true;
        let error = 'Value is invalid';
        let isInteger = false;
        const rules = this.dataFieldTypeRules.split('|');

        for (const rule of rules) {
            if (!valid) {
                continue;
            }
            // Required
            switch (rule) {
                case 'required':
                    valid = control.value !== null && control.value !== undefined && control.value !== '';
                    error = 'Value is required';
                    break;
                case 'integer':
                    valid = !Number.isNaN(+control.value);
                    error = 'Value must be a number';
                    isInteger = true;
                    break;
                case 'numeric':
                    valid = !Number.isNaN(+control.value);
                    error = 'Value must be a number';
                    break;
                case 'date':
                    valid = moment(control.value, 'YYYY-MM-DD').isValid() || this.validEmptyDateString(control.value);
                    error = 'Value must be a valid date';
                    break;
                case 'boolean':
                    // The boolean is stored as a 0 or 1 because of the backend
                    valid = +control.value === 1 || +control.value === 0;
                    error = "Must pick 'yes' or 'no'";
                    break;
            }

            /*
             * Determine if the input is text or number then validate the MIN length or value
             */
            if (valid && rule.indexOf('min') > -1) {
                const minLengthOrValue = Number(rule.split(':')[1]);
                if (control.value && isInteger) {
                    valid = control.value >= minLengthOrValue;
                    error = 'Value does not meet minimum value of ' + minLengthOrValue;
                } else if (control.value) {
                    valid = control.value.length >= minLengthOrValue;
                    error = 'Value does not meet minimum length of ' + minLengthOrValue + ' characters';
                }
            }

            /*
             * Determine if the input is text or number then validate the MAX length or value
             */
            if (valid && rule.indexOf('max') > -1) {
                const maxLengthOrValue = Number(rule.split(':')[1]);
                if (control.value && isInteger) {
                    valid = control.value <= maxLengthOrValue;
                    error = 'Value exceeds maximum value of ' + maxLengthOrValue;
                } else if (control.value) {
                    valid = control.value.length <= maxLengthOrValue;
                    error = 'Value exceeds maximum length of ' + maxLengthOrValue + ' characters';
                }
            }
        }
        return valid ? null : { dataFieldTypeRules: error };
    }

    private validEmptyDateString(val: string): boolean {
        return val === '' || val === ' ' || val === null;
    }
}
