import { WorkingPeriodsMap } from '../constants/working-periods-map.constant';
import { JobPostingCompensationTypes } from '../enums/job-posting-compensation-types.enum';

export const convertBaseSalaryGivenCompensationType = (
    baseHourlySalary: number,
    compensationType: string,
    roundUp = false
): string => {
    let finalSalary = baseHourlySalary;
    switch (compensationType) {
        case JobPostingCompensationTypes.daily:
            finalSalary = finalSalary * WorkingPeriodsMap.hoursPerDay;
            break;
        case JobPostingCompensationTypes.weekly:
            finalSalary = finalSalary * WorkingPeriodsMap.hoursPerDay * WorkingPeriodsMap.daysPerWeek;
            break;
        case JobPostingCompensationTypes.monthly:
            finalSalary =
                finalSalary *
                WorkingPeriodsMap.hoursPerDay *
                WorkingPeriodsMap.daysPerWeek *
                WorkingPeriodsMap.weeksPerMonth;
            break;
        case JobPostingCompensationTypes.yearly:
            finalSalary =
                finalSalary *
                WorkingPeriodsMap.hoursPerDay *
                WorkingPeriodsMap.daysPerWeek *
                WorkingPeriodsMap.weeksPerMonth *
                WorkingPeriodsMap.monthsPerYear;
            break;
        default:
            break;
    }
    const salaryAsString = finalSalary + '';
    let stringOfZeros = '';
    for (let i = 1; i < salaryAsString.length; i++) {
        stringOfZeros += '0';
    }

    let firstNumber = salaryAsString.charAt(0);
    if (roundUp) {
        if (+salaryAsString.charAt(1) >= 5) {
            firstNumber = +firstNumber + 1 + '';
        }
    }

    return firstNumber + stringOfZeros;
};
