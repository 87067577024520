import { Model } from '@models/core/base.model';
import { TimeOffPolicy, TimeOffSeniorityOverridePayload } from '@models/time-off-v3/time-off-policy.model';

interface PayloadOptions {
    delete?: boolean;
}

export class TimeOffSeniorityOverride extends Model {
    private _active = false;

    protected static _resource = 'timeOffV3/seniorityOverrides';

    protected static _type = 'timeOffSeniorityOverrides';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['timeOffPolicyId', 'afterNumberOfYears', 'daysAccruedPerPeriod'];

    get timeOffPolicyId(): number {
        return this._attributes['timeOffPolicyId'];
    }

    set timeOffPolicyId(val: number) {
        this._attributes['timeOffPolicyId'] = val;
    }

    get afterNumberOfYears(): number {
        return this._attributes['afterNumberOfYears'];
    }

    set afterNumberOfYears(val: number) {
        this._attributes['afterNumberOfYears'] = val;
    }

    get daysAccruedPerPeriod(): number {
        return this._attributes['daysAccruedPerPeriod'];
    }

    set daysAccruedPerPeriod(val: number) {
        this._attributes['daysAccruedPerPeriod'] = val;
    }

    get timeOffPolicy(): TimeOffPolicy {
        return this.hasOne(TimeOffPolicy, 'timeOffPolicy');
    }

    get active(): boolean {
        return this._active;
    }

    set active(val: boolean) {
        this._active = val;
    }

    toPayload(options?: PayloadOptions): TimeOffSeniorityOverridePayload {
        return {
            id: this.id,
            afterNumberOfYears: this.afterNumberOfYears,
            daysAccruedPerPeriod: this.daysAccruedPerPeriod,
            ...options,
        };
    }
}
