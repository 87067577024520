export const PAYROLL_AUTHORIZATION_DOCUMENTS = ['PadAgreement', 'ROEAuthorization'] as const;

export type AuthorizationDocumentType = (typeof PAYROLL_AUTHORIZATION_DOCUMENTS)[number];

export const PAYROLL_SUPPORTING_DOCUMENTS = [
    'PayVerification',
    'PlaceAndHistoryOfBusiness',
    'PayorVerification',
    'PayorRelationship',
] as const;

export type SupportingDocumentType = (typeof PAYROLL_SUPPORTING_DOCUMENTS)[number];

export type SelfOnboardingDocumentType = AuthorizationDocumentType | SupportingDocumentType;
