import { Injectable } from '@angular/core';
import { T4Box } from '@app/models/payroll/t4-box.model';

@Injectable()
export class T4BoxService {
    t4BoxOptions: T4Box[] = [];

    constructor() {
        this.load();
    }

    /**
     * This currently is what we need in order to retrieve t4Boxes in the benefits view
     * Once we add flow for the t4 show view we will change the logic to add or remove
     * filters based on what component is using this
     */
    private load(): void {
        T4Box.where('secondary', true)
            .where('without2020', true)
            .limit(100)
            .get()
            .then((t4BoxOptions) => {
                [this.t4BoxOptions] = t4BoxOptions;
                this.t4BoxOptions.sort((t4BoxOption1, t4BoxOption2) => t4BoxOption1.code - t4BoxOption2.code);
            });
    }
}
