import { Model } from '../core/base.model';

export class AssetCategory extends Model {
    protected static _resource = 'assetCategories';
    protected static _version = 'v2';

    protected static _serializeAttributes = ['name'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }
}
