import { Component, NgZone, OnInit } from '@angular/core';
import { TranslationStorageService } from '@app/services';
import { ZENDESK_URLS, ZenDeskService } from '@app/services/zendesk.service';
import { environment } from '@env/environment';
import { takeWhile } from 'rxjs/operators';

const WEB_WIDGET_NAME = 'messenger' as const;

type WebWidgetName = typeof WEB_WIDGET_NAME;

/**
 * zE is the script loaded onto the window to control the ZenDesk widget.
 * Unfortunately, there's no \@types library so we have to define the API ourselves here.
 */
declare let zE: { activate: () => void } & ((webWidget: WebWidgetName, ...args: string[]) => void) &
    ((webWidget: `${WebWidgetName}:on`, event: string, callback: () => void) => void) &
    ((webWidget: `${WebWidgetName}:set`, event: string, value: string) => void);

// A custom ZenDesk launcher widget
@Component({
    selector: 'app-zen-desk',
    templateUrl: './zen-desk.template.html',
    styleUrls: ['./zen-desk.style.scss'],
})
export class ZenDeskComponent implements OnInit {
    // Controls whether the launcher widget (? symbol) is visible
    isLauncherVisible = false;

    get isWindowOpen(): boolean {
        return this._isWindowOpen;
    }
    set isWindowOpen(isWindowOpen: boolean) {
        this._isWindowOpen = isWindowOpen;
        zE(WEB_WIDGET_NAME, isWindowOpen ? 'open' : 'close');
    }

    private _isWindowOpen = false;

    constructor(
        private translationStorage: TranslationStorageService,
        private zenDeskService: ZenDeskService,
        private ngZone: NgZone
    ) {}

    async ngOnInit(): Promise<void> {
        // isWidgetEnabled checks whether the widget is enabled via the domain allow list in the ZenDesk control panel.
        // Since we use a custom launcher, we need to check manually before displaying it.
        const isWidgetEnabled = await this.zenDeskService.isEnabled
            .pipe(takeWhile((isEnabled) => isEnabled === undefined, true))
            .toPromise();
        if (isWidgetEnabled) {
            this.createSnippet();
        }
    }

    onClick = (): void => {
        this.isWindowOpen = !this.isWindowOpen;
    };

    // Creates the ZenDesk snippet script tag and appends it to the document head
    private createSnippet = (): void => {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        // When the script is loaded, we can apply the settings to the widget
        script.addEventListener('load', this.onLoad.bind(this));
        script.src = `${ZENDESK_URLS.SNIPPET}${environment.zenDesk?.apiKey}`;
        document.getElementsByTagName('head')[0].appendChild(script);
    };

    /**
     * Called after the script has been loaded into the HTML
     */
    private onLoad = (): void => {
        // Set language
        // Need to use BCP 47 by replacing "_" with "-" (ie. en_CA vs en-CA)
        zE(`${WEB_WIDGET_NAME}:set`, 'locale', this.translationStorage.locale.replace('_', '-'));
        // Bind to the minimize event from the chat window to keep track of the window state in order to update the mat-icon in the custom launcher
        zE(`${WEB_WIDGET_NAME}:on`, 'close', () => this.ngZone.run(() => (this._isWindowOpen = false)));
        // Ensure the widget starts in the minimized state
        zE(WEB_WIDGET_NAME, 'close');
        // Show the custom launcher
        this.isLauncherVisible = true;
    };
}
