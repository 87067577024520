import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { JsonApiResponse } from '@app/interfaces';
import { OAuthUrl } from '@applicant-tracker/interfaces/oauth.interface';

@Injectable()
export class IndeedIntegrationService {
    constructor(private http: HttpClient) {}

    getOAuthUrl(): Promise<string> {
        return this.http
            .get(environment.api + '/v2/accountManagement/login/indeed')
            .toPromise()
            .then((res: JsonApiResponse<OAuthUrl>) => res.data.attributes.url);
    }
}
