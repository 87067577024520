import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppResources } from '@app/app.resources';
import { FeatureRequest } from '@app/models/feature-request/feature-request.model';
import { DialogService, NotifyService } from '@app/services';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-feature-request-form',
    templateUrl: './feature-request-form.template.html',
})
export class FeatureRequestForm extends BaseForm {
    // A map of translation keys to the values sent to the backend
    PRODUCT_AREA_TAGS = {
        assetTracker: 'asset tracker',
        benefits: 'benefits',
        coreHr: 'core hr',
        documents: 'documents',
        integrations: 'integrations',
        mobileApp: 'mobile app',
        payroll: 'payroll',
        people: 'people',
        performance: 'performance',
        recruiting: 'recruiting',
        reporting: 'reporting',
        settings: 'settings',
        surveys: 'surveys',
        tasks: 'tasks',
        timeOff: 'time off',
        training: 'training',
        timeTracking: 'time tracking',
    };

    title = '';
    content = '';
    tags = '';

    isWaitingForResponse = false;
    helpCenterUrl = AppResources.HelpCenter;

    constructor(
        private dialogService: DialogService,
        private notifyService: NotifyService
    ) {
        super();
    }

    onSubmit(form: NgForm): void {
        if (!form.valid) {
            return;
        }

        const featureRequest = new FeatureRequest({ ...form.value, tags: [form.value.tags] });
        this.isWaitingForResponse = true;
        featureRequest
            .submitRequest()
            .then(() => {
                this.notifyService.success('sidebar.featureRequest.successToast');
                this.dialogService.close();
            })
            .catch(() => {
                this.notifyService.error('sidebar.featureRequest.failureToast');
                this.isWaitingForResponse = false;
            });
    }
}
