import { Model } from '@models/core/base.model';
import { TimeOffApprovalFlow } from '@models/time-off-v3/time-off-approval-flow.model';
import { TimeOffApprovalObserverType } from '@models/time-off-v3/time-off-approval-observer-type.model';
import { Employee } from '@models/employee/employee.model';

export class TimeOffApprovalObserver extends Model {
    protected static _resource = 'timeOffV3/approvalObservers';

    protected static _version = 'v2';

    protected static _type = 'timeOffApprovalObservers';

    protected static _serializeAttributes = ['timeOffApprovalFlowId', 'timeOffApprovalObserverTypeId', 'observerId'];

    get timeOffApprovalFlowId(): number {
        return this._attributes['timeOffApprovalFlowId'];
    }

    get timeOffApprovalObserverTypeId(): number {
        return this._attributes['timeOffApprovalObserverTypeId'];
    }

    get observerId(): number {
        return this._attributes['observerId'];
    }

    get timeOffApprovalFlow(): TimeOffApprovalFlow {
        return this.hasOne(TimeOffApprovalFlow, 'timeOffApprovalFlow');
    }

    get timeOffApprovalObserverType(): TimeOffApprovalObserverType {
        return this.hasOne(TimeOffApprovalObserverType, 'timeOffApprovalObserverType');
    }

    get observer(): Employee {
        return this.hasOne(Employee, 'observer');
    }

    set timeOffApprovalFlowId(val: number) {
        this._attributes['timeOffApprovalFlowId'] = val;
    }

    set timeOffApprovalObserverTypeId(val: number) {
        this._attributes['timeOffApprovalObserverTypeId'] = val;
    }

    set observerId(val: number) {
        this._attributes['observerId'] = val;
    }
}
