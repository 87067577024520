import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
    transform(str: string): string {
        if (!str) {
            return null;
        }

        return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    }
}
