export enum TimeTrackingResources {
    AssignProjectEmployee = '/v2/timeTracking/projectAssignments/batch',
    EmployeeHourTypeCheck = '/v2/timeTracking/employeeHourTypes',
    AssignEmployeePayrollIntegration = '/v2/timeTracking/timeTrackingPayrollEmployeeIntegration/assign',
    TimesheetEntryBatch = '/v2/timeTracking/timesheets/batch',
    TimesheetEntryApprove = '/v2/timeTracking/timesheets/:timesheetEntryId/approve',
    TimesheetEntryApproveAll = '/v2/timeTracking/timesheets/approveAll',
    MinutesWorkedOnDay = '/v2/timeTracking/minutesWorkedOnDay',
    ProjectOwnerBatch = '/v2/timeTracking/projects/:projectId/ownerships',
    Settings = '/v2/timeTracking/settings',
    EmployeeReport = '/v2/timeTracking/reports/employees',
    EmployeeProfileReport = '/v2/timeTracking/reports/employeeProfile',
    LatestActiveEntry = '/v2/timeTracking/time-clock/latest-active',
}
