<div class="ui page dimmer">
    <div class="app-grid">
        <div class="ui segment" [class.loading]="isLoading">
            <h2 class="ui header">{{ headerText | appTranslate }}</h2>
            <div class="content">
                <form class="app-grid" #form="ngForm">
                    <mat-form-field class="columns-12">
                        <mat-label appRemoveAriaOwns>{{
                            'components.select-benefit-plan-dialog.benefitPlan' | appTranslate
                        }}</mat-label>
                        <mat-select
                            name="selection"
                            (selectionChange)="onBenefitPlanChange()"
                            [(ngModel)]="selectedBenefitPlan"
                            required
                        >
                            <mat-option *ngFor="let plan of plans" [value]="plan">{{ plan.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="hasEnhancedManagedBenefits">
                        <mat-form-field class="columns-12">
                            <mat-label appRemoveAriaOwns>{{
                                'components.select-benefit-plan-dialog.division' | appTranslate
                            }}</mat-label>
                            <mat-select
                                (selectionChange)="onBenefitDivisionChange()"
                                name="division"
                                [(ngModel)]="selectedBenefitDivision"
                                required
                            >
                                <mat-option *ngFor="let division of divisions" [value]="division">{{
                                    division.name
                                }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-slide-toggle
                            name="isWaitingPeriodWaved"
                            color="primary"
                            [(ngModel)]="selection.isWaitingPeriodWaved"
                        >
                            {{ 'components.select-benefit-plan-dialog.waiveWaitingPeriod' | appTranslate }}
                        </mat-slide-toggle>
                    </ng-container>
                </form>

                <div class="actions">
                    <ui-button
                        [disabled]="isActionButtonDisabled()"
                        type="primary"
                        style="float: right"
                        (click)="approve()"
                    >
                        {{ label | appTranslate }}
                    </ui-button>
                </div>
            </div>
        </div>
    </div>
</div>
