import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@app/modules/table/classes/column.class';
import { Role } from '@app/models/account/role.model';
import { RoleNameDisplayOptions } from '@app/types/translatables/role-display.options';
import { Translatable } from '@app/types/translatable.type';

export class NameColumn extends Column {
    title = 'settings.default-roles-table.nameColumn';
    property = 'name';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(role: Role): Translatable {
        return RoleNameDisplayOptions[role?.name ?? ''] ?? role.name;
    }
}
