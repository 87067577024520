import { Pipe, PipeTransform } from '@angular/core';
import { AppTranslatePipe } from '@app/pipes/app-translate.pipe';

@Pipe({ name: 'fieldGroupLocation' })
export class FieldGroupLocationPipe extends AppTranslatePipe implements PipeTransform {
    transform(str: string): string {
        if (str === 'personal') {
            return super.transform('forms.fields.personal');
        }

        if (str === 'job_and_pay') {
            return super.transform('forms.fields.jobAndPay');
        }

        return '';
    }
}
