import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';

export class GoalUpdate extends Model {
    protected static _resource = 'performanceManagement/goals/:goal/goalUpdates';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _serializeAttributes = [
        'onTrack',
        'offTrack',
        'progressing',
        'metricIncrement',
        'metricTotal',
        'metricValue',
        'metricMilestones',
        'comment',
    ];

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get onTrack(): boolean {
        return this._attributes['onTrack'];
    }
    set onTrack(val: boolean) {
        this._attributes['onTrack'] = val;
    }

    get offTrack(): boolean {
        return this._attributes['offTrack'];
    }
    set offTrack(val: boolean) {
        this._attributes['offTrack'] = val;
    }

    get progressing(): boolean {
        return this._attributes['progressing'];
    }
    set progressing(val: boolean) {
        this._attributes['progressing'] = val;
    }

    get metricIncrement(): boolean {
        return this._attributes['metricIncrement'];
    }
    set metricIncrement(val: boolean) {
        this._attributes['metricIncrement'] = val;
    }

    get metricTotal(): boolean {
        return this._attributes['metricTotal'];
    }
    set metricTotal(val: boolean) {
        this._attributes['metricTotal'] = val;
    }

    get metricValue(): number {
        return this._attributes['metricValue'];
    }
    set metricValue(val: number) {
        this._attributes['metricValue'] = val;
    }

    get metricMilestones(): string {
        return this._attributes['metricMilestones'] || [];
    }
    set metricMilestones(val: string) {
        this._attributes['metricMilestones'] = val;
    }

    get comment(): string {
        return this._attributes['comment'];
    }
    set comment(val: string) {
        this._attributes['comment'] = val;
    }

    /* Timestamps */
    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get updatedAt(): Date {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): Date {
        return this._attributes['deletedAt'] || null;
    }
}
