import { ModelMixin } from '@models/core/base-generic.model';

/**
 * At the time of creation this is not a resourceful model.
 */
export class SetupGuideStep extends ModelMixin<SetupGuideStep>() {
    protected static _type = 'setupGuideSteps';
    protected static _version = 'v2';

    get stepKey(): string {
        return this._attributes['stepKey'];
    }

    get completedAt(): Date | null {
        return this._attributes['completedAt'];
    }
}
