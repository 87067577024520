import { Inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { CryptoService } from '../crypto/crypto.service';
import { USE_ENCRYPTION } from './encryption-injection-token';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor(
        private cryptoService: CryptoService,
        // Used to make it optional and resolve it when injected with the token.
        @Inject(USE_ENCRYPTION) private useEncryption: boolean
    ) {
        this.useEncryption = useEncryption ?? false;
    }

    // Store data
    public async store(key: string, value: any): Promise<void> {
        if (this.useEncryption) {
            const encryptedValue = await this.cryptoService.encrypt(JSON.stringify(value));
            localStorage.setItem(key, encryptedValue);
            return;
        }

        localStorage.setItem(key, JSON.stringify(value));
    }

    // Retrieve data
    public async retrieve<T>(key: string): Promise<T | null> {
        const item = localStorage.getItem(key);

        if (!item || isEmpty(item)) return null;

        if (this.useEncryption) {
            try {
                const decryptedValue = await this.cryptoService.decrypt(item);
                return JSON.parse(decryptedValue) as T;
            } catch (error) {
                console.error('Failed to decrypt item:', error);
                return null;
            }
        } else {
            return JSON.parse(item) as T;
        }
    }

    // Remove data
    public remove(key: string): void {
        localStorage.removeItem(key);
    }
}
