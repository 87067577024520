import { Component, ElementRef, Input } from '@angular/core';
import { EmploymentTypes } from '@app/constants/employment-types';
import { Office } from '@app/models/company/office.model';
import { NotifyService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { Department } from '@models/company/department.model';
import { Job } from '@models/company/job.model';
import { Employee } from '@models/employee/employee.model';
import { BulkSelectBaseDialogComponent } from '../bulk-select-base-dialog/bulk-select-base-dialog.component';

@Component({
    selector: 'app-common-bulk-select-survey-participants-dialog',
    templateUrl: '../bulk-select-base-dialog/bulk-select-base-dialog.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectSurveyParticipantsDialogComponent extends BulkSelectBaseDialogComponent {
    @Input() label = 'Select';
    @Input() headerText = 'Select Participants';

    @Input() maxItemsToSelect = null;

    @Input() bannerType = 'info';
    @Input() displayBannerBasedOnMaxItems = false;
    @Input() bannerContent: string;

    filters: any = {
        departmentId: 'Departments',
        jobId: 'Positions',
        status: 'Status',
        officeId: 'Offices',
        employment: 'Employment Type',
        manager: 'Reports To',
    };
    filterValues: any = {
        departmentId: {},
        jobId: {},
        status: {
            active: 'Active',
            onboarding: 'Onboarding',
        },
        officeId: {},
        employment: {},
        manager: {},
    };
    activeFilters: any = {};

    items: Employee[] = [];
    selectItems: Employee[] = [];

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        protected notify: NotifyService
    ) {
        super(element, auth);

        // Add terminated filter option if administrator
        if (this.auth.isAdmin()) {
            this.filterValues.status['terminated'] = 'Terminated';
        }
    }

    selectAll(): void {
        this.isLoading = true;

        let request = Employee.param('company', this.auth.company.id)
            .with('account')
            .with('department')
            .with('job')
            .with('office')
            .with('manager')
            .limit(0);

        request = this.applyQuery(request);

        request = this.applyFilters(request);

        request
            .all()
            .then(([employees]) => {
                this.selectItems = employees;
                this.updateModelSelection();
            })
            .catch((e) => {
                this.notify.error(e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    /**
     * Reset the form
     */
    protected loadFilterOptions(): void {
        this.loadDepartments();
        this.loadPositions();
        this.loadOffices();
        this.loadEmploymentTypes();
        this.loadManagers();
    }

    protected loadItems(): void {
        let request = Employee.param('company', this.auth.company.id)
            .with('account')
            .with('department')
            .with('job')
            .with('office')
            .with('manager')
            .whereIn('status', ['onboarding', 'active'])
            .page(this.currentPage);

        request = this.applyQuery(request);
        request = this.applyFilters(request);

        request
            .get()
            .then(([employees, meta]) => {
                this.totalResults = meta.pagination.total;
                this.currentPage = meta.pagination.page;
                this.items = employees;
                this.isLoading = false;
            })
            .catch(() => {});
    }

    private loadDepartments(): void {
        Department.param('company', this.auth.company.id)
            .all()
            .then(([departments]) => {
                for (const department of departments) {
                    this.filterValues.departmentId[department.id] = department.name;
                }
            });
    }

    private loadPositions(): void {
        Job.param('company', this.auth.company.id)
            .all()
            .then(([jobs]) => {
                for (const job of jobs) {
                    this.filterValues.jobId[job.id] = job.title;
                }
            });
    }

    private loadOffices(): void {
        Office.param('company', this.auth.company.id)
            .all()
            .then(([offices]) => {
                for (const office of offices) {
                    this.filterValues.officeId[office.id] = office.name;
                }
            });
    }

    private loadEmploymentTypes(): void {
        for (const employmentType of EmploymentTypes) {
            this.filterValues.employment[employmentType] = employmentType;
        }
    }

    /**
     * This actually just loads all employees but is used to filter for employees who
     * report to the selected "manager".
     *
     * Eventually we would like to expose a way to get only employees who are managers.
     */
    private loadManagers(): void {
        Employee.param('company', this.auth.company.id)
            .orderBy('lastName', 'ASC')
            .where('managersOnly', true)
            .all()
            .then(([managers]) => {
                managers.forEach((manager) => {
                    this.filterValues.manager[manager.id] = manager.fullName;
                });
            });
    }
}
