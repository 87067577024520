import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Template } from '@app/models/performance/template.model';

@Directive({
    selector: '[templateValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: TemplateValidator, multi: true }],
})
export class TemplateValidator implements Validator {
    @Input('templateValidator') templates: Template[] = [];

    validate(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return { required: true };
        }

        const value = control.value;
        const selectedTemplate = this.templates.find((template) => template.name === value);
        return selectedTemplate && selectedTemplate.isValid ? null : { invalidTemplate: true };
    }
}
