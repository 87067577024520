import { Model } from '@models/core/base.model';
import { JobPosting } from '../recruiting/job-posting.model';
import { SettingsIntegration } from './settings/settings-integration.model';
import { ActiveIntegrations } from '@settings/enums/integration.enum';

export class ATSJobIntegration extends Model {
    protected static _type = 'jobIntegrations';

    protected static _resource = 'applicantTracker/jobIntegrations';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['isEnabled', 'atsJobId', 'integrationId'];

    get integrationType(): SettingsIntegration {
        return this.hasOne(SettingsIntegration, 'integrationType');
    }

    set integrationType(settingsIntegration: SettingsIntegration) {
        this.setOne('integrationType', settingsIntegration);
    }

    get jobPosting(): JobPosting {
        return this.hasOne(JobPosting, 'jobPosting');
    }

    get atsJobId(): number {
        return this._attributes['atsJobId'];
    }

    set atsJobId(val: number) {
        this._attributes['atsJobId'] = val;
    }

    get integrationId(): number {
        return this._attributes['integrationId'];
    }

    set integrationId(val: number) {
        this._attributes['integrationId'] = val;
    }

    get isEnabled(): boolean {
        return this._attributes['isEnabled'];
    }

    set isEnabled(val: boolean) {
        this._attributes['isEnabled'] = val;
    }

    isIntegrationOfType(integrationName: ActiveIntegrations): boolean {
        return this.integrationType?.integrationType?.name === integrationName;
    }
}
