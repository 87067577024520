<ui-dialog
    [size]="size"
    (closing)="resolver(false); confirm.open = false"
    #confirm
    [header]="header | appTranslate"
    [primaryButtonLabel]="primaryButtonLabel | appTranslate"
    [primaryButtonType]="primaryButtonType"
    [secondaryButtonLabel]="secondaryButtonLabel | appTranslate"
    [secondaryButtonType]="secondaryButtonType"
    (primaryButtonPressed)="resolver(true); confirm.open = false"
    (secondaryButtonPressed)="resolver(false); confirm.open = false"
>
    <ng-content></ng-content>
</ui-dialog>
