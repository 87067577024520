import { Model } from '@app/interfaces';
import { CellDisplay } from '../enums/cell-display.enum';
import { BadgeDisplay } from '../interfaces/badge-display.interface';
import { Column } from './column.class';

export abstract class MultiBadgeColumn<T extends Model> extends Column {
    type = CellDisplay.multiBadge;

    /**
     * Contains cache of badges, keyed by Employee ID
     */
    badges: Record<number, BadgeDisplay[]> = {};

    /**
     * Return an array of BadgeDisplay types with the text that should be within each badge,
     * and the badge type to use.
     */
    abstract getMultiBadgeDisplay(row: T): BadgeDisplay[];
}
