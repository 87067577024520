import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { Subject, Subscription } from 'rxjs';

export interface Option<T> {
    label: string;
    value: T;
    [additionalProperty: string]: any;
}

@Directive()
export abstract class BaseAutocompleteComponent<T>
    implements MatFormFieldControl<T>, ControlValueAccessor, OnDestroy, OnInit
{
    @ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;
    @Output() blur: EventEmitter<void> = new EventEmitter<void>();
    abstract id: string;
    abstract controlType: string;
    static nextId = 0;
    stateChanges = new Subject<void>();
    focused = false;
    loading = false;
    searchControl: FormControl<T | null> = new FormControl();
    options: Option<T>[] = [];
    onChange = (_: any) => {};
    onTouched = () => {};
    describedBy = '';
    delayOpen = 0;

    abstract displayFn: (selection: T) => string;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    get readOnly() {
        return this._readOnly;
    }
    set readOnly(value) {
        this._readOnly = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input() get errorState(): boolean {
        return (
            this.ngControl &&
            this._defaultErrorStateMatcher.isErrorState(this.ngControl.control as FormControl, this._parentForm)
        );
    }

    @Input() get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        value ? this.searchControl.disable() : this.searchControl.enable();
        this.stateChanges.next();
    }

    get value(): T {
        return this._selection;
    }
    set value(value: T) {
        if (this._selection === value) {
            return;
        }

        this._selection = value;
    }

    get empty(): boolean {
        return !this.value;
    }

    protected _selection: T;
    protected _placeholder: string;
    protected _required = false;
    protected _readOnly = false;
    protected _disabled = false;
    protected _subscriptions: Subscription[] = [];
    protected _inputElement: HTMLInputElement = null;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        protected elementRef: ElementRef<HTMLElement>,
        protected fm: FocusMonitor
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        const fmMonitorSub = this.fm.monitor(this.elementRef.nativeElement, true).subscribe((origin) => {
            this._inputElement.style.maxWidth = 'none';
            this.focused = !!origin;
            this.stateChanges.next();
        });

        this._subscriptions.push(fmMonitorSub);

        // Need to do this to show mat-errors when form is submitted.
        if (this._parentForm) {
            this._subscriptions.push(
                this._parentForm.ngSubmit.subscribe(() => {
                    this.stateChanges.next();
                })
            );
        }
    }

    ngOnInit(): void {
        this._inputElement = this.elementRef.nativeElement.querySelector('input');

        this.loading = true;
        this._setDisplayOptions();
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._subscriptions.forEach((s) => s.unsubscribe());
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    focus(): void {
        if (this.delayOpen > 0) {
            setTimeout(() => this._inputElement.focus(), this.delayOpen);
        } else {
            this._inputElement.focus();
        }
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if (this.disabled) {
            return;
        }

        if (!this.searchControl.value) {
            this._clearInput();
        }

        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this._focusInput();
            setTimeout(() => this.trigger.openPanel());
        }
    }

    onBlur(): void {
        this.writeValue(this.value);
        this.blur.emit();
    }

    closePanel(): void {
        this.trigger.closePanel();
    }

    writeValue(value: T): void {
        this.value = value;
        this.searchControl.setValue(this.value);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.writeValue(event.option.value);
        this.onChange(event.option.value);
        this.stateChanges.next();
        this._blurInput();
    }

    private _clearInput(): void {
        setTimeout(() => (this._inputElement.value = ''));
    }

    private _focusInput(): void {
        this._inputElement.focus();
    }

    protected abstract _setDisplayOptions(): void;

    private _blurInput(): void {
        this._inputElement.blur();
    }
}
