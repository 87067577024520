import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@app/modules/table/classes/column.class';
import { Role } from '@app/models/account/role.model';
import { RoleDescriptionDisplayOptions } from '@app/types/translatables/role-display.options';
import { Translatable } from '@app/types/translatable.type';

export class DescriptionColumn extends Column {
    title = 'settings.default-roles-table.descriptionColumn';
    property = 'description';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(role: Role): Translatable {
        return RoleDescriptionDisplayOptions[role?.description ?? ''] ?? role.description;
    }
}
