import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'limitTo' })
export class LimitToPipe implements PipeTransform {
    private readonly ELLIPSIS = '...';

    transform(str: string | unknown, limit: number): string {
        if (typeof str !== 'string') {
            return '';
        }

        if (str.length > limit) {
            return str.substring(0, limit) + this.ELLIPSIS;
        }

        return str;
    }
}
