<!--Modals-->
<app-common-bulk-select-employee-dialog #employeeSelect [label]="'Assign'"> </app-common-bulk-select-employee-dialog>

<!--Body-->
<img *ngIf="showEditButton" class="right floated pen-icon" appIcon="PenSolid" (click)="onEdit()" />
<h2 *ngIf="showTitle" class="ui left floated header">{{ title }}</h2>
<div class="clear-both"></div>
<app-avatar
    class="employee-list-avatar"
    *ngFor="let employee of employees"
    [employee]="employee"
    [name]="true"
></app-avatar>
<p *ngIf="!employees?.length">{{ noEmployeeMessage }}</p>
