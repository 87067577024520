<div>
    <div role="list">
        <div
            *ngFor="let step of workflow.steps; index as index"
            class="approval-flow-row app-grid align-items-center margin-top-1"
            role="listitem"
        >
            <span [id]="'ordinal-number-' + index" class="columns-1">{{ index + 1 | ordinalNumber }}</span>
            <mat-form-field
                class="no-labels no-helpers"
                [class.columns-5]="step.actor === 'specific_employee'"
                [class.columns-10]="step.actor !== 'specific_employee'"
            >
                <mat-select [(ngModel)]="step.actor" [aria-labelledby]="ariaLabelledBy + ' ordinal-number-' + index">
                    <mat-option value="direct_manager">{{
                        'components.change-requests.approver.employeeDirectManager' | translate
                    }}</mat-option>
                    <mat-option value="indirect_manager">{{
                        'components.change-requests.approver.employeeManagerManager' | translate
                    }}</mat-option>
                    <mat-option value="specific_employee">{{
                        'components.change-requests.approver.specificEmployee' | translate
                    }}</mat-option>
                    <mat-option value="subject">{{
                        'components.change-requests.approver.requestSubject' | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="step.actor === 'specific_employee'" class="columns-5 no-labels no-helpers">
                <ui-employee-autocomplete
                    [(ngModel)]="step.employee"
                    name="reportsTo"
                    aria-label="{{ index + 1 | ordinalNumber }} {{
                        'components.change-requests.approver.specificEmployee' | translate
                    }}"
                ></ui-employee-autocomplete>
            </mat-form-field>

            <span class="columns-1">
                <button *ngIf="workflow.steps.length > 1" mat-icon-button (click)="removeStep(index)">
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
            </span>
        </div>
    </div>
    <div *ngIf="workflow.steps.length < 5" class="margin-top-2">
        <ui-button type="outline" (click)="addStep()">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'components.change-requests.addStep' | translate }}
        </ui-button>
    </div>
</div>
