import { ModelMixin } from '@models/core/base-generic.model';

export class EmployeeHour extends ModelMixin<EmployeeHour>() {
    protected static _resource = 'payroll/payrolls/:payroll/payrollHours';
    protected static _version = 'v2';
    protected static _dates = ['dateUsed'];

    get employeeName(): string {
        return this._attributes['employeeName'];
    }

    get dateUsed(): Date {
        return this._attributes['dateUsed'];
    }

    get hoursType(): string {
        return this._attributes['hoursType'];
    }

    get hoursDisplayName(): string {
        return this._attributes['hoursDisplayName'];
    }

    get hours(): number {
        return this._attributes['hours'];
    }

    get source(): string {
        return this._attributes['source'];
    }

    get status(): string {
        return this._attributes['status'];
    }

    get approverName(): string | null {
        return this._attributes['approverName'];
    }

    get new(): boolean {
        return this._attributes['new'];
    }
}
