import { Injectable } from '@angular/core';
import { TimeOffApprovalFlow } from '@app/models/time-off-v3/time-off-approval-flow.model';
import { TimeOffPolicy } from '@app/models/time-off-v3/time-off-policy.model';
import { TimeOffRequestRules } from '@app/models/time-off-v3/time-off-request-rules.model';
import { TimeOffSeniorityOverride } from '@app/models/time-off-v3/time-off-seniority-override.model';
import { TimeOffType } from '@app/models/time-off-v3/time-off-type.model';
import { NotifyService } from '@app/services';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimeOffCreateFlowService {
    isLoading = false;

    timeOffType = new TimeOffType();
    timeOffPolicy = new TimeOffPolicy();
    timeOffRequestRules = new TimeOffRequestRules();

    timeOffPolicy$: Observable<TimeOffPolicy>;
    timeOffType$: Observable<TimeOffType>;
    timeOffTypes$: Observable<TimeOffType[]>;
    timeOffApprovalFlows$: Observable<TimeOffApprovalFlow[]>;
    timeOffRequestRules$: Observable<TimeOffRequestRules>;
    isLoading$: Observable<boolean>;

    /**
     *  Time off policies should exist with these default states
     */
    timeOffPolicyDefaultValues = {
        isUnlimited: false,
        proratesFirstAccrual: true,
        accrualFrequency: 'weekly',
        carriesOverOnHireDate: false,
        accrualFromHireDate: false,

        // these put the policy into a default state of unlimited carryover
        carryoverExpireAfter: null,
        carryoverCap: null,
        carryoverDate: null,
    };

    private timeOffTypesSource = new BehaviorSubject<TimeOffType[]>([]);
    private timeOffApprovalFlowsSource = new BehaviorSubject<TimeOffApprovalFlow[]>([]);
    private timeOffPolicySource = new BehaviorSubject<TimeOffPolicy>(this.timeOffPolicy);
    private timeOffTypeSource = new BehaviorSubject<TimeOffType>(this.timeOffType);
    private timeOffRequestRulesSource = new BehaviorSubject<TimeOffRequestRules>(this.timeOffRequestRules);
    private isLoadingSource = new BehaviorSubject<boolean>(false);

    constructor(private notify: NotifyService) {
        this.timeOffPolicy$ = this.timeOffPolicySource.asObservable();
        this.timeOffType$ = this.timeOffTypeSource.asObservable();
        this.timeOffTypes$ = this.timeOffTypesSource.asObservable();
        this.timeOffApprovalFlows$ = this.timeOffApprovalFlowsSource.asObservable();
        this.timeOffRequestRules$ = this.timeOffRequestRulesSource.asObservable();
        this.isLoading$ = this.isLoadingSource.asObservable();
    }

    async loadAllTimeOffTypes(): Promise<void> {
        this.isLoadingSource.next(true);

        try {
            const [timeOffTypes] = await TimeOffType.all();
            this.timeOffTypesSource.next(timeOffTypes);
        } catch (err) {
            this.notify.error(err);
        } finally {
            this.isLoadingSource.next(false);
        }
    }

    async loadAllTimeOffApprovals(): Promise<void> {
        this.isLoadingSource.next(true);

        try {
            const [timeOffApprovalFlows] = await TimeOffApprovalFlow.all();
            this.timeOffApprovalFlowsSource.next(timeOffApprovalFlows);
        } catch (err) {
            this.notify.error(err);
        } finally {
            this.isLoadingSource.next(false);
        }
    }

    updateTimeOffPolicy(updatedPolicy: TimeOffPolicy): void {
        this.timeOffPolicy = updatedPolicy;
        this.timeOffPolicySource.next(this.timeOffPolicy);
    }

    updateTimeOffType(updatedTimeOffType: TimeOffType): void {
        this.timeOffType = updatedTimeOffType;
        this.timeOffPolicy.timeOffType = updatedTimeOffType;
        this.timeOffTypeSource.next(this.timeOffType);
    }

    updateTimeOffSeniorityOverrides(timeOffSeniorityOverrides: TimeOffSeniorityOverride[]): void {
        this.timeOffPolicy.timeOffSeniorityOverrides = timeOffSeniorityOverrides;
        this.timeOffPolicySource.next(this.timeOffPolicy);
    }

    updateTimeOffRequestRules(timeOffRequestRules: TimeOffRequestRules): void {
        this.timeOffRequestRules = timeOffRequestRules;
        this.timeOffRequestRulesSource.next(this.timeOffRequestRules);
    }
}
