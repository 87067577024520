<ui-overlay [open]="isOpened" [scrollable]="true" [topOffset]="imageOffset" [centerContent]="false">
    <div class="flex align-items-center padding-x-4 padding-y-1 toolbar">
        <div class="flex-1 margin-right-auto flex align-items-center">
            <div class="margin-left-auto">
                <ui-button class="margin-right-4" *ngIf="buttonLabel" type="primary" (click)="buttonClicked.emit()">
                    {{ buttonLabel }}</ui-button
                >
            </div>
        </div>
        <span class="close-button" role="button" (click)="close()">
            <mat-icon color="white" svgIcon="close"></mat-icon>
        </span>
    </div>
    <div tabindex="0" class="padding-bottom-8 viewer-container" (click)="close()">
        <mat-spinner *ngIf="loading" class="align-self-center margin-auto"></mat-spinner>

        <div [class.visible]="!loading" class="flex justify-center padding-top-5" (click)="negateClick($event)">
            <app-file-viewer #viewer [file]="file" [maxHeight]="800"></app-file-viewer>
        </div>
    </div>
</ui-overlay>
