import { Directive } from '@angular/core';
import { DialogComponent } from '@app/components';

@Directive({
    selector: 'ui-dialog[confirmationDialog]',
    exportAs: 'uiConfirmationDialogDirective',
})
export class ConfirmationDialogDirective {
    constructor(private dialog: DialogComponent) {
        this.dialog.primaryButtonType = 'primary';
        this.dialog.primaryButtonLabel = 'confirm';
        this.dialog.secondaryButtonType = 'ghost';
        this.dialog.secondaryButtonLabel = 'cancel';
    }
}
