import { Directive } from '@angular/core';
import { DialogComponent } from '@app/components';

@Directive({
    selector: 'ui-dialog[deleteDialog]',
    exportAs: 'uiDeleteDialogDirective',
})
export class DeleteDialogDirective {
    constructor(private dialog: DialogComponent) {
        this.dialog.primaryButtonType = 'danger';
        this.dialog.primaryButtonLabel = 'Delete';
        this.dialog.secondaryButtonType = 'ghost';
        this.dialog.secondaryButtonLabel = 'Cancel';
    }
}
