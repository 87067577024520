import { Model } from '@models/core/base.model';

export class TimeOffApprovalObserverType extends Model {
    protected static _resource = 'timeOffV3/approvalObserverTypes';

    protected static _version = 'v2';

    protected static _type = 'timeOffApprovalObserverTypes';

    get name(): string {
        return this._attributes['name'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    isSpecificPerson(): boolean {
        return this.name === 'Specific Person';
    }
}
