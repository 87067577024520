import { Model } from '../core/base.model';

export class Currency extends Model {
    protected static _resource = 'core/data/currencies';

    protected static _version = 'v1';

    get name(): string {
        return this._attributes['name'] || null;
    }

    get code(): string {
        return this._attributes['code'] || null;
    }
}
