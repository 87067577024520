import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[usaZipCodeValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: UsaZipCodeValidator, multi: true }],
})
export class UsaZipCodeValidator implements Validator {
    @Input() country: string;

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.country !== 'United States') {
            return null;
        }

        /**
         * A valid USA zip code must either be 5 digits `48059`
         * or five digits, dash 4 digits `85965-1058`
         */
        const value = control.value ?? '';
        if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
            return null;
        }

        return { custom: { message: 'The zip code must be a valid American zip code.' } };
    }
}
