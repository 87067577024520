import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { TimeOffApprovalFlowStep } from '@models/time-off-v3/time-off-approval-flow-step.model';

export class TimeOffApprover extends Model {
    protected static _resource = 'timeOffV3/approvers';

    protected static _version = 'v2';

    protected static _type = 'timeOffApprovers';

    protected static _serializeAttributes = ['timeOffApprovalFlowStepId', 'approverId'];

    get timeOffApprovalFlowStepId(): number {
        return this._attributes['timeOffApprovalFlowStepId'];
    }

    get approverId(): number {
        return this._attributes['approverId'];
    }

    get approver(): Employee {
        return this.hasOne(Employee, 'approver');
    }

    get timeOffApprovalFlowStep(): TimeOffApprovalFlowStep {
        return this.hasOne(TimeOffApprovalFlowStep, 'timeOffApprovalFlowStep');
    }

    set timeOffApprovalFlowStepId(val: number) {
        this._attributes['timeOffApprovalFlowStepId'] = val;
    }

    set approverId(val: number) {
        this._attributes['approverId'] = val;
    }
}
