import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@app/modules/table/classes/column.class';
import { TimeOffRequest } from '@models/time-off-v3/time-off-request.model';
import { Translatable } from '@app/types/translatable.type';

export class CountAsColumn extends Column {
    title = 'time-off-v3.countAs';
    property = 'totalAmount';
    sortField = 'totalAmount';
    sortable = true;
    type: CellDisplay = CellDisplay.string;
    displayWidth = 20;

    getDisplayPropertyValue(request: TimeOffRequest): Translatable {
        return request.totalAmountWithUnit;
    }
}
