import { Injectable } from '@angular/core';
import { Currency } from '@app/models/common/currency.model';

const CAD = 'CAD';
const USD = 'USD';

const reasonableCurrencyOrdering = (a: Currency, b: Currency): number => {
    // Canada always first
    if (a.code === CAD) {
        return -1;
    }

    if (b.code === CAD) {
        return 1;
    }

    // USA always next
    if (a.code === USD) {
        return -1;
    }

    if (b.code === USD) {
        return 1;
    }

    return a.code.localeCompare(b.code);
};

@Injectable()
export class CurrencyService {
    private _currencies: Currency[] = [];

    async getAllCurrencies(): Promise<Currency[]> {
        if (!this._currencies.length) {
            const [currencies] = await Currency.all();
            this._currencies = currencies.sort(reasonableCurrencyOrdering);
        }

        return Promise.resolve(this._currencies);
    }
}
