import { SimpleEntityActions } from '@app/classes';
import { Applicant } from '@models/recruiting/applicant.model';
import { FunnelTemplateStage } from '@models/recruiting/funnel-template-stages.model';
import { JobFunnelMessageTemplate } from '@models/recruiting/job-funnel-message-template.model';
import { ModelMixin } from '../core/base-generic.model';

export class JobFunnel extends ModelMixin<JobFunnel>() {
    static permission = new SimpleEntityActions('jobFunnel');

    protected static _resource = 'applicantTracker/jobFunnels';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['jobId', 'funnelStep', 'funnelIndex'];

    get jobPostingId(): number {
        return this._attributes['jobId'] || null;
    }

    set jobPostingId(val: number) {
        this._attributes['jobId'] = val;
    }

    get funnelStep(): string {
        return this._attributes['funnelStep'];
    }

    set funnelStep(value: string) {
        this._attributes['funnelStep'] = value;
    }

    get funnelIndex(): number {
        if (this._attributes['funnelIndex'] === 0) {
            return 0;
        }

        return this._attributes['funnelIndex'] || null;
    }

    set funnelIndex(value: number) {
        this._attributes['funnelIndex'] = value;
    }

    get activeApplicantsCount(): number {
        return this._attributes['activeApplicantsCount'];
    }

    get applicants(): Applicant[] {
        return this.hasMany(Applicant, 'applicants');
    }

    set applicants(applicants: Applicant[]) {
        this.setMany('applicants', applicants);
    }

    get activeApplicants(): Applicant[] {
        return this.hasMany(Applicant, 'activeApplicants');
    }

    get jobFunnelMessageTemplate(): JobFunnelMessageTemplate {
        return this.hasOne(JobFunnelMessageTemplate, 'jobFunnelMessageTemplate');
    }

    get hasEmailAutomation(): boolean {
        return this._attributes['hasEmailAutomation'];
    }

    toFunnelTemplateStage(atsFunnelTemplateId?: number): FunnelTemplateStage {
        return new FunnelTemplateStage({
            atsFunnelTemplateId: atsFunnelTemplateId,
            name: this.funnelStep,
            stageIndex: this.funnelIndex,
        });
    }
}
