import { Model } from '../core/base.model';

export class Rep extends Model {
    get id(): number {
        return +this._attributes['id'];
    }

    get email(): string {
        return this._attributes['email'];
    }

    get type(): string {
        return this._attributes['type'];
    }

    get legalFirstName(): string {
        return this._attributes['legalFirstName'];
    }

    get legalLastName(): string {
        return this._attributes['legalLastName'];
    }
}
