import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { CacheService } from '@app/services/cache.service';

@Injectable()
export class UrlHelperService {
    constructor(
        private http: HttpClient,
        private cache: CacheService
    ) {}

    get(url: string): Observable<any> {
        return new Observable((observer: Subscriber<any>) => {
            let objectUrl: string = null;

            const cacheValue = this.cache.get(url);

            if (cacheValue) {
                return observer.next(cacheValue);
            }

            this.http
                .get(url, {
                    responseType: 'blob',
                })
                .subscribe((m) => {
                    objectUrl = URL.createObjectURL(m);
                    this.cache.put(url, objectUrl);
                    observer.next(objectUrl);
                });

            return () => {
                if (objectUrl) {
                    // We are caching these values so revoking the objectUrl wont work
                    // URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }

    getBlob(url: string, params?: HttpParams): Observable<any> {
        return new Observable((observer: Subscriber<any>) => {
            this.http
                .get(url, {
                    responseType: 'blob',
                    params: params,
                })
                .subscribe(
                    (res) => {
                        observer.next(res);
                    },
                    (err) => {
                        observer.error(err);
                    }
                );

            return () => {};
        });
    }
}
