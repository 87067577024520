import { ModelMixin } from '../core/base-generic.model';
import { Employee } from '../employee/employee.model';
import { Applicant } from './applicant.model';

export class ConnectEmployeeLink extends ModelMixin<ConnectEmployeeLink>() {
    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt', 'offerAcceptedDate'];

    get employee(): Employee {
        return this.hasOne(Employee, 'employee_id');
    }

    get applicant(): Applicant {
        return this.hasOne(Applicant, 'applicant_id');
    }

    get offerAcceptedDate(): Date {
        return this._attributes['offerAcceptedDate'];
    }

    get employeeFullName(): string {
        return this._attributes['employeeFullName'];
    }

    get timeToHire(): string {
        return this._attributes['timeToHire'];
    }

    get timeToFill(): string {
        return this._attributes['timeToFill'];
    }

    get candidateId(): number | null {
        return this._attributes['candidateId'];
    }
}
