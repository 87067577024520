/*
 * We chose these formats since this would get us support for all major browsers without transcoding audio.
 *
 * Webm recording is supported by Firefox, Edge, Chrome, Android Browsers
 * Mp4 is only supported by Safari and iOS
 *
 * Both of these formats will play on all browsers.
 */
export enum SupportedAudioFormats {
    primary = 'audio/webm;codecs:vp8',
    secondary = 'audio/mp4',
}
