import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BadRequestError } from '@app/errors/http/400/bad-request.error';

@Injectable()
export class ApiValidationService {
    constructor() {}

    /**
     * Display api errors on valid form elements. Ensure form element uses camelCase version of
     * api property.
     */
    errors(form: NgForm, error: Error): Error {
        if (!(error instanceof BadRequestError)) {
            throw error;
        }

        const json: { errors: { detail: string; source?: { pointer?: string } }[] } = error.res.error;

        const inputs: Map<string, string[]> = new Map<string, string[]>();
        json.errors.map((err) => {
            if (err.source?.pointer?.includes('data.attributes.')) {
                const key = err.source.pointer;
                if (!inputs.has(key)) {
                    inputs.set(key, []);
                }
                inputs.get(key)?.push(this.clean(err.detail));
            }
        });
        for (const [key, messages] of inputs) {
            this.setErrors(form, this.cleanAndCamelCase(key), messages);
        }
        return error;
    }

    protected clean(text: string): string {
        return text.replace('data.attributes.', '');
    }

    protected cleanAndCamelCase(text: string): string {
        return this.clean(text).replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
    }

    protected setErrors(form: NgForm, key: string, messages: string[]): boolean {
        if (key in form.controls) {
            form.controls[key].setErrors({ custom: { message: messages.join(' ') }, invalid: true });
            return true;
        }
        return false;
    }
}
