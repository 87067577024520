import { Translatable } from '@app/types/translatable.type';

const TrainingProgramRecordsStatusDisplayOptions: { [key: string]: Translatable } = {
    pending: 'training.pending',
    overdue: 'training.overdue',
    complete: 'training.complete',
};

const TrainingProgramRecurrenceSingleDisplayOptions: { [key: string]: Translatable } = {
    day: 'training.recurrenceLabels.everySingle.day',
    month: 'training.recurrenceLabels.everySingle.month',
    year: 'training.recurrenceLabels.everySingle.year',
};

const TrainingProgramRecurrenceManyDisplayOptions: { [key: string]: string } = {
    day: 'training.recurrenceLabels.everyNumberOf.day',
    month: 'training.recurrenceLabels.everyNumberOf.month',
    year: 'training.recurrenceLabels.everyNumberOf.year',
};

const TrainingProgramDueDateSingleDisplayOptions: { [key: string]: Translatable } = {
    day: 'training.dueDateLabels.single.day',
    month: 'training.dueDateLabels.single.month',
    year: 'training.dueDateLabels.single.year',
};

const TrainingProgramDueDateManyDisplayOptions: { [key: string]: string } = {
    day: 'training.dueDateLabels.many.day',
    month: 'training.dueDateLabels.many.month',
    year: 'training.dueDateLabels.many.year',
};

export {
    TrainingProgramDueDateManyDisplayOptions,
    TrainingProgramDueDateSingleDisplayOptions,
    TrainingProgramRecordsStatusDisplayOptions,
    TrainingProgramRecurrenceManyDisplayOptions,
    TrainingProgramRecurrenceSingleDisplayOptions,
};
