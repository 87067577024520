import { XeroIntegrationJob } from '@app/interfaces';
import { PayrollItem } from '@app/interfaces/payroll-item.interface';
import { CovidWageSubsidy } from '@app/models/payroll/covid-wage-subsidy.model';
import { PayrollStates } from '@payroll/enums/payroll-state.enum';
import { ModelMixin } from '../core/base-generic.model';
import { ContentDirectory } from './content-directories.model';
import { EptSummary } from './ept-summary.model';

/**
 * Map of provinces to WCB Board names
 *
 * Source: https://www.ccohs.ca/oshanswers/information/wcb_canada.html
 *
 * QC / CNESST is omitted as we handle it separately
 */
const WCB_TYPES_MAP = {
    AB: "Workers' Compensation Board of Alberta",
    BC: 'WorkSafeBC',
    MB: 'Workers Compensation Board of Manitoba',
    NB: 'WorkSafeNB',
    NL: 'WorkplaceNL',
    NT: "Workers' Compensation Board of the Northwest Territories and Nunavut",
    NS: "Workers' Compensation Board of Nova Scotia",
    ON: 'Workplace Safety and Insurance Board (Ontario)',
    PE: "Workers' Compensation Board of Prince Edward Island",
    SK: "Saskatchewan Workers' Compensation Board",
    YK: "Yukon Workers' Compensation, Health and Safety Board",
};

export class Payroll extends ModelMixin<Payroll>() {
    protected static _resource = 'payroll/companies/:company/payrolls';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['payDate', 'addAllEmployees'];
    protected static _dates = ['payDate', 'hoursLockedAt'];

    get chequeDate(): string {
        return this._attributes['chequeDate'];
    }

    get cnesstAmount(): string {
        return this._attributes['cnesstAmount'];
    }

    get qhsfAmount(): string {
        return this._attributes['qhsfAmount'];
    }

    get directDeposit(): boolean {
        return this._attributes['directDeposit'];
    }

    get endDate(): string {
        return this._attributes['endDate'];
    }

    get hasAttachment(): boolean {
        return this._attributes['hasAttachment'];
    }

    get late(): boolean {
        return this._attributes['late'];
    }

    get paidAt(): string {
        return this._attributes['paidAt'];
    }

    get payDate(): string {
        return this._attributes['payDate'];
    }
    set payDate(val: string) {
        this._attributes['payDate'] = val;
    }

    get paystubsTotal(): string {
        return this._attributes['paystubsTotal'];
    }

    get qcPaystubCount(): number {
        return this._attributes['qcPaystubCount'];
    }

    get periodNo(): number {
        return this._attributes['periodNo'];
    }

    get recurring(): boolean {
        return this._attributes['recurring'];
    }

    get startDate(): string {
        return this._attributes['startDate'];
    }

    get state(): PayrollStates {
        return this._attributes['state'];
    }

    get totalPeriods(): number {
        return this._attributes['totalPeriods'];
    }

    get deadline(): string {
        return this._attributes['deadline'];
    }

    get remittanceLate(): boolean {
        return this._attributes['remittanceLate'];
    }

    get netAmount(): number {
        return this._attributes['netAmount'];
    }

    get grossAmount(): number {
        return this._attributes['grossAmount'];
    }

    get liability(): number {
        return this._attributes['liability'];
    }

    get debitAmount(): number {
        return this._attributes['debitAmount'];
    }

    get deductionsAmount(): number {
        return this._attributes['deductionsAmount'];
    }

    get netAmountDebitable(): number {
        return this._attributes['netAmountDebitable'];
    }

    get paystubsByCheque(): string {
        return this._attributes['paystubsByCheque'];
    }

    get reimbursementAmount(): number {
        return this._attributes['reimbursementAmount'];
    }

    get statutoryAmount(): number {
        return this._attributes['statutoryAmount'];
    }

    get statutoryDeductions(): PayrollItem[] {
        return this._attributes['statutoryDeductions'];
    }

    get obligationAmounts(): string {
        return this._attributes['obligationAmounts'];
    }

    get payablesAmount(): string {
        return this._attributes['payablesAmount'];
    }

    get wcbPremiumAmount(): string {
        return this._attributes['wcbPremiumAmount'];
    }

    get wcbPremiumAmounts(): string {
        return this._attributes['wcbPremiumAmounts'];
    }

    get multiprovince(): boolean {
        return this._attributes['multiprovince'];
    }

    get timeOffFromDate(): string {
        return this._attributes['timeOffFromDate'];
    }

    get timeOffToDate(): string {
        return this._attributes['timeOffToDate'];
    }

    get runAt(): string {
        return this._attributes['runAt'];
    }

    get integratedPayrollAndTimeOffAt(): string {
        return this._attributes['integratedPayrollAndTimeOffAt'];
    }

    get lastRecurringPayrollAt(): string {
        return this._attributes['lastRecurringPayrollAt'];
    }

    get latestXeroIntegrationJob(): XeroIntegrationJob {
        return this._attributes['latestXeroIntegrationJob'];
    }

    get hoursLockedAt(): string {
        return this._attributes['hoursLockedAt'];
    }

    get hoursLocked(): boolean {
        return this._attributes['hoursLocked'];
    }

    get netAmountChequeable(): number {
        return this.netAmount - this.netAmountDebitable;
    }

    get addAllEmployees(): boolean {
        return this._attributes['addAllEmployees'];
    }
    set addAllEmployees(val: boolean) {
        this._attributes['addAllEmployees'] = val;
    }

    get wcbPremiums(): { displayName: string; amount: string }[] {
        const wcbPremiumAmounts = this._attributes['wcbPremiumAmounts'] ?? {};

        return Object.entries(wcbPremiumAmounts)
            .filter(([province]) => province !== 'qc')
            .map(([province, amount]) => {
                province = province.toUpperCase();
                return {
                    displayName: WCB_TYPES_MAP[province as keyof typeof WCB_TYPES_MAP] ?? province,
                    amount: `${amount}`,
                };
            });
    }

    get totalWcbPremiums(): string {
        return this.wcbPremiums.reduce(
            (acc, premium) => (parseFloat(acc) + parseFloat(premium.amount)).toFixed(2),
            '0'
        );
    }

    get totalOtherRemittances(): number {
        return parseFloat(this.totalEptSummariesNonQc) + parseFloat(this.totalWcbPremiums);
    }

    get otherDeductions(): number {
        return this.liability - this.netAmount - this.statutoryAmount - parseFloat(this.totalRqDeductions);
    }

    get contentDirectories(): ContentDirectory[] {
        return this.hasMany(ContentDirectory, 'contentDirectories');
    }

    get eptSummaries(): EptSummary[] {
        return this.hasMany(EptSummary, 'eptSummaries');
    }

    get eptSummariesQc(): EptSummary[] {
        return this.eptSummaries.filter(({ jurisdictionArea }) => jurisdictionArea === 'QC');
    }

    get eptSummariesNonQc(): EptSummary[] {
        return this.eptSummaries.filter(({ jurisdictionArea }) => jurisdictionArea !== 'QC');
    }

    get totalEptSummariesNonQc(): string {
        return this.eptSummariesNonQc.reduce(
            (acc, summary) => (parseFloat(acc) + parseFloat(summary.periodBalanceDue)).toFixed(2),
            '0'
        );
    }

    get covidWageSubsidy(): CovidWageSubsidy | null {
        return this.hasOne(CovidWageSubsidy, 'covidWageSubsidy');
    }

    get cppDeductions(): PayrollItem | undefined {
        return this.statutoryDeductions.find(({ name, category }) => name === 'CPP' && category === 'deductions');
    }

    get cpp2Deductions(): PayrollItem | undefined {
        return this.statutoryDeductions.find(({ name, category }) => name === 'CPP2' && category === 'deductions');
    }

    get eiDeductions(): PayrollItem | undefined {
        return this.statutoryDeductions.find(({ name, category }) => name === 'EI' && category === 'deductions');
    }

    get employerCppDeductions(): PayrollItem | undefined {
        return this.statutoryDeductions.find(
            ({ name, category }) => name === 'Employer CPP' && category === 'payables'
        );
    }

    get employerCpp2Deductions(): PayrollItem | undefined {
        return this.statutoryDeductions.find(
            ({ name, category }) => name === 'Employer CPP2' && category === 'payables'
        );
    }

    get employerEiDeductions(): PayrollItem | undefined {
        return this.statutoryDeductions.find(({ name, category }) => name === 'Employer EI' && category === 'payables');
    }

    get incomeTaxDeductions(): PayrollItem | undefined {
        const deductions = this.statutoryDeductions.filter(
            ({ name, category, payee }) =>
                ['Income Tax', 'Income Tax - federal', 'Income Tax - provincial', 'Additional Tax'].includes(name) &&
                category === 'deductions' &&
                payee === 'cra'
        );

        if (deductions.length === 0) return undefined;

        return deductions.reduce((final, item) => {
            return {
                ...final,
                amount: (parseFloat(final.amount) + parseFloat(item.amount)).toFixed(2),
            };
        });
    }

    get totalCraDeductions(): string {
        return [
            this.cppDeductions,
            this.cpp2Deductions,
            this.eiDeductions,
            this.employerCppDeductions,
            this.employerCpp2Deductions,
            this.employerEiDeductions,
            this.incomeTaxDeductions,
        ].reduce((acc, deductionItem) => {
            if (!deductionItem) {
                return acc;
            }
            return (parseFloat(acc) + parseFloat(deductionItem.amount)).toFixed(2);
        }, '0');
    }

    get hasProrations(): boolean {
        return this.contentDirectories?.some(
            (contentDirectories: ContentDirectory) => contentDirectories.contextType === 'proration'
        );
    }

    get qppDeduction(): PayrollItem | undefined {
        return this.statutoryDeductions.find(({ name, category }) => name === 'QPP' && category === 'deductions');
    }

    get qpp2Deduction(): PayrollItem | undefined {
        return this.statutoryDeductions.find(({ name, category }) => name === 'QPP2' && category === 'deductions');
    }

    get qpipDeduction(): PayrollItem | undefined {
        return this.statutoryDeductions.find(({ name, category }) => name === 'QPIP' && category === 'deductions');
    }

    get employerQppDeduction(): PayrollItem | undefined {
        return this.statutoryDeductions.find(
            ({ name, category }) => name === 'Employer QPP' && category === 'payables'
        );
    }

    get employerQpp2Deduction(): PayrollItem | undefined {
        return this.statutoryDeductions.find(
            ({ name, category }) => name === 'Employer QPP2' && category === 'payables'
        );
    }

    get employerQpipDeduction(): PayrollItem | undefined {
        return this.statutoryDeductions.find(
            ({ name, category }) => name === 'Employer QPIP' && category === 'payables'
        );
    }

    get incomeTaxRqDeduction(): PayrollItem | undefined {
        return this.statutoryDeductions.find(
            ({ name, category, payee }) =>
                name === 'Income Tax - provincial' && category === 'deductions' && payee === 'rq'
        );
    }

    get totalRqDeductions(): string {
        const deductions = [
            this.qppDeduction,
            this.qpp2Deduction,
            this.qpipDeduction,
            this.employerQppDeduction,
            this.employerQpp2Deduction,
            this.employerQpipDeduction,
            this.incomeTaxRqDeduction,
        ]
            .filter((deduction): deduction is PayrollItem => deduction !== undefined)
            .map(({ amount }) => amount);

        const eptAmountsQc = this.eptSummariesQc.map(({ periodBalanceDue }) => periodBalanceDue);

        return [...deductions, this.cnesstAmount, ...eptAmountsQc].reduce((acc, item) => {
            if (!item) {
                return acc;
            }
            return (parseFloat(acc) + parseFloat(item)).toFixed(2);
        }, '0');
    }

    get hasQcRemittances(): boolean {
        return this.statutoryDeductions.some(({ payee }) => payee == 'rq');
    }
}
