import { Model } from '../core/base.model';
import { EmploymentType } from './employment-type.model';
import { Department } from '../company/department.model';
import { Job } from '../company/job.model';
import { EmployeeFilter } from './employee-filter.model';
import { Address } from './address.model';
import { Office } from '../company/office.model';

type CriteriaTypes = Department | Job | EmploymentType | Address;

export class FilterCriteria extends Model {
    get filterId(): string {
        return this._attributes['filterId'];
    }

    get criteriableId(): string {
        return this._attributes['criteriableId'];
    }

    get criteriableType(): string {
        return this._attributes['criteriableType'];
    }

    get criteriable(): CriteriaTypes {
        switch (this.criteriableType) {
            case 'Department':
                return this.hasOne(Department, 'criteriable');
            case 'Job':
                return this.hasOne(Job, 'criteriable');
            case 'EmploymentType':
                return this.hasOne(EmploymentType, 'criteriable');
            case 'Address':
                return this.hasOne(Office, 'criteriable');
        }
    }

    get filter(): EmployeeFilter {
        return this.hasOne(EmployeeFilter, 'employeeFilter');
    }
}
