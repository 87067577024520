import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Office } from '@models/company/office.model';

export class JurisdictionColumn extends Column {
    title = 'table.workers-compensations.jurisdiction';
    property = 'jurisdiction';
    sortField = 'jurisdiction';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    constructor(protected companyId: number) {
        super();

        const options = Office.param('company', this.companyId)
            .orderBy('province', 'ASC')
            .all()
            .then(([offices]) =>
                offices
                    .filter(
                        // We do not support QC yet, and NT and NU do not have workers compensation
                        (office) => office.province !== 'QC' && office.province !== 'NT' && office.province !== 'NU'
                    )
                    .map((office: Office) => ({
                        label: office.provinceDisplayName,
                        value: office.province,
                    }))
            );

        this.filter = new CheckListFilter('jurisdiction', options);
    }
}
