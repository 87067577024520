import { Model } from '../core/base.model';

import { Employee } from '../employee/employee.model';
import { TrainingProgram } from './training-program.model';
import { TrainingProgramRecordFile } from '@models/documents/training-program-record-file.model';

export class TrainingProgramRecord extends Model {
    protected static _resource = 'training/trainingProgramRecords';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'notes',
        'dueAt',
        'completedAt',
        'credits',
        'hours',
        'cost',
        'instructor',
        'isEmployeeSubmittable',
        'status',
        'preemptedCreatedAt',
        'createdAt',
        'expirationDate',
        'actionableEmployeeIds',
        'attach',
        'detach',
        'result',
    ];

    protected static _dates = ['dueAt', 'completedAt', 'expirationDate', 'preemptedCreatedAt'];

    static createOnceOff(program: TrainingProgram): TrainingProgramRecord {
        const model = new TrainingProgramRecord();
        model.setOne('trainingProgram', program);
        return model;
    }

    get trainingProgramRecordFiles(): TrainingProgramRecordFile[] {
        return this.hasMany(TrainingProgramRecordFile, 'trainingProgramRecordFiles');
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get trainingProgram(): TrainingProgram {
        return this.hasOne(TrainingProgram, 'trainingProgram');
    }

    get dueAt(): Date {
        return this._attributes['dueAt'];
    }

    /**
     * sessionNumber represents the number of recurrence
     * for this record. Ex: If this is the 2nd time the record has
     * recurred, session number would be 2.
     * Often this is undefined.
     */
    get sessionNumber(): number | undefined {
        return this._attributes['sessionNumber'];
    }

    set dueAt(val: Date) {
        this._attributes['dueAt'] = val;
    }

    get completedAt(): Date | null {
        return this._attributes['completedAt'];
    }

    set completedAt(val: Date) {
        this._attributes['completedAt'] = val;
    }

    get notes(): string {
        return this._attributes['notes'];
    }
    set notes(val: string) {
        this._attributes['notes'] = val;
    }

    get credits(): number {
        return this._attributes['credits'];
    }
    set credits(val: number) {
        this._attributes['credits'] = val;
    }

    get cost(): number {
        return this._attributes['cost'];
    }
    set cost(val: number) {
        this._attributes['cost'] = val;
    }

    get hours(): number {
        return this._attributes['hours'];
    }
    set hours(val: number) {
        this._attributes['hours'] = val;
    }

    get result(): string | null {
        return this._attributes['result'];
    }
    set result(val: string) {
        this._attributes['result'] = val;
    }

    get instructor(): string {
        return this._attributes['instructor'];
    }
    set instructor(val: string) {
        this._attributes['instructor'] = val;
    }

    get isEmployeeSubmittable(): boolean {
        return this._attributes['isEmployeeSubmittable'];
    }

    get status(): string {
        return this._attributes['status'];
    }
    set status(val: string) {
        this._attributes['status'] = val;
    }

    get preemptedCreatedAt(): Date {
        return this._attributes['preemptedCreatedAt'];
    }

    get createdAt(): Date {
        return this._attributes['createdAt'];
    }

    get expirationDate(): Date | null {
        return this._attributes['expirationDate'];
    }
    set expirationDate(val: Date) {
        this._attributes['expirationDate'] = val;
    }

    get isComplete(): boolean {
        return this.completedAt != null;
    }

    get actionableEmployeeIds(): number[] {
        return this._attributes['actionableEmployeeIds'];
    }

    getLinkPath(): (string | number)[] {
        return ['/training', 'log', this.id];
    }
}
