export enum TimeOffResources {
    AccrualPreview = '/v2/timeOffV3/policies/preview',
    Assign = '/v2/timeOffV3/policies/:policy_id/assign',
    Balance = '/v2/timeOffV3/employees/:employeeId/balance',
    ExpiringBalance = '/v2/timeOffV3/employees/:employeeId/expiringBalance',
    Copy = '/v2/timeOffV3/policies/:policy_id/duplicate',
    CopyCustomHolidays = '/v2/timeOffV3/workSchedules/:workScheduleId/copy-custom-time-off-days',
    ForceApproveTimeOffRequest = '/v2/timeOffV3/requests/:requestId/forceApprove',
    ForceDenyTimeOffRequest = '/v2/timeOffV3/requests/:requestId/forceDeny',
    DefaultImportTemplate = '/v2/timeOffV3/types/:time_off_type_id/download-:default_template_name-template',
    TimeOffImporter = '/v2/timeOffV3/types/:time_off_type_id/import-:importerName',
    ValidateTimeOffImporterFile = '/v2/timeOffV3/importFiles',
    PrefillHolidays = '/v2/timeOffV3/workSchedules/:workScheduleId/prefill',
    ResetTimeOffRequest = '/v2/timeOffV3/requests/:requestId/reset',
    VerifyBusinessRules = '/v2/timeOffV3/requests/verify',
    ReassignPolicy = '/v2/timeOffV3/policies/:policy_id/reassign',
    EraseAccruals = '/v2/timeOffV3/employees/:employeeId/purgeTimeOffType/:timeOffTypeId',
    TimeOffBackupApprover = '/v2/companyManagement/companies/:companyId/timeOffBackupApprover',
    DeletePayrollHours = '/v2/timeOffV3/types/:timeOffTypeId/delete-payroll-hours',
    SendPayrollHours = '/v2/timeOffV3/types/:timeOffTypeId/send-payroll-hours',
    PendingPayrollHours = '/v2/payroll/employeeHours',
}
