<div [attr.data-cy]="'simple-table-body'" class="table">
    <div [className]="sticky ? 'sticky' : null">
        <div class="app-grid margin-bottom-2 header">
            <div
                [attr.data-cy]="'simple-table-header-' + i"
                [className]="column.columnSpan"
                *ngFor="let column of columns; let i = index"
            >
                <span class="title-header">{{ column.columnName | translate }}</span>
            </div>

            <div *ngIf="showInlineButton" [ngClass]="[inlineButtonSpan, 'hidden-column']">
                <button class="fake-inline-button">{{ inlineButtonText | translate }}</button>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div [className]="fixedHeight ? 'fixed-height' : null">
            <ng-container *ngFor="let row of rows; let i = index; trackBy: trackByRow">
                <div [attr.data-cy]="'simple-table-row'" class="app-grid grid-centered margin-bottom-2 margin-top-2">
                    <div
                        [attr.data-cy]="'simple-table-cell'"
                        *ngFor="let cell of row.cells; let j = index; trackBy: trackByCell"
                        [className]="columns[j].columnSpan"
                    >
                        <ng-container *ngIf="columns[j].columnType === undefined">
                            <span *ngIf="!cell?.isAvatar" class="cell">{{ cell | translate }}</span>
                            <app-avatar
                                *ngIf="cell?.isAvatar"
                                [employee]="cell?.employee"
                                [name]="cell?.employee.fullName"
                            ></app-avatar>
                        </ng-container>

                        <ng-container *ngIf="columns[j].columnType !== undefined">
                            <ng-container [ngSwitch]="columns[j].columnType">
                                <ng-container *ngSwitchCase="cellDisplay.date">
                                    <span class="drop-hover-highlight">{{ cell | appDate: 'mediumDate' }}</span>
                                </ng-container>

                                <ng-container *ngSwitchCase="cellDisplay.dollar">{{ cell | currency }}</ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <ui-button
                        *ngIf="showInlineButton"
                        type="link"
                        [class]="inlineButtonSpan"
                        (click)="onInlineButtonClicked(i)"
                    >
                        {{ inlineButtonText | translate }}
                        <mat-icon *ngIf="inlineButtonIcon" [svgIcon]="inlineButtonIcon" />
                    </ui-button>

                    <ng-container *ngIf="showMeatballs">
                        <ui-button [matMenu]="meatball" class="columns-1 meatball" size="no-padding">
                            <mat-icon svgIcon="meatballHorizontal"></mat-icon>
                        </ui-button>
                        <mat-menu #meatball xPosition="before">
                            <button *ngIf="showEditItem" mat-menu-item (click)="onEdit(i)">
                                {{ 'applicant-tracker.components.simple-table.edit' | translate }}
                            </button>
                            <button *ngIf="showDeleteItem" mat-menu-item (click)="onDelete(i)">
                                {{ deleteText | translate }}
                            </button>
                        </mat-menu>
                    </ng-container>
                </div>
                <mat-divider></mat-divider>
            </ng-container>
        </div>
    </div>
</div>
