import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

/**
 * @deprecated
 * Just used for benefits digest
 */
@Injectable()
export class BenefitsService {
    constructor(private http: HttpClient) {}

    actionCompanyEvent(eventId: number): Promise<any> {
        const endpoint = '/v1/benefits/events/' + eventId;
        return this.http.post(endpoint, {}).toPromise().then(this.returnJson).catch(this.throwError);
    }

    /*************************
     * Callbacks
     *********************** */
    private returnJson(res: HttpResponse<any>) {
        try {
            return Promise.resolve(res);
        } catch (e) {
            console.error('Failed to parse the JSON response from the server', res);
            return Promise.reject(res);
        }
    }

    private throwError(error: any) {
        return Promise.reject(error.message || error);
    }
}
