import { HttpErrorResponse } from '@angular/common/http';

export class UnprocessableEntityError extends Error {
    httpStatus = 422;

    constructor(res: HttpErrorResponse, message?: string) {
        super(message ? message : res.statusText);
        Object.setPrototypeOf(this, UnprocessableEntityError.prototype);
    }
}
