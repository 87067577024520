import { BadRequestError } from '@app/errors/http/400/bad-request.error';
import { ConflictError } from '@app/errors/http/400/conflict.error';
import { ForbiddenError } from '@app/errors/http/400/forbidden.error';
import { ImATeapotError } from '@app/errors/http/400/im-a-teapot.error';
import { LockedError } from '@app/errors/http/400/locked.error';
import { MethodNotAllowedError } from '@app/errors/http/400/method-not-allowed.error';
import { NotAcceptableError } from '@app/errors/http/400/not-acceptable.error';
import { NotFoundError } from '@app/errors/http/400/not-found.error';
import { PaymentRequiredError } from '@app/errors/http/400/payment-required.error';
import { RequestTimeoutError } from '@app/errors/http/400/request-timeout.error';
import { UnauthorizedError } from '@app/errors/http/400/unauthorized.error';
import { UnprocessableEntityError } from '@app/errors/http/400/unprocessable-entity.error';
import { UnsupportedMediaTypeError } from '@app/errors/http/400/unsupported-media-type.error';

import { HttpErrorResponse } from '@angular/common/http';
import { BadGatewayError } from '@app/errors/http/500/bad-gateway.error';
import { EnhanceYourCalmError } from '@app/errors/http/500/enhance-your-calm.error';
import { GatewayTimeoutError } from '@app/errors/http/500/gateway-timeout.error';
import { InternalServerError } from '@app/errors/http/500/internal-server-error.error';
import { LoginTimeoutError } from '@app/errors/http/500/login-timeout.error';
import { NotImplementedError } from '@app/errors/http/500/not-implemented.error';
import { ServiceUnavailableError } from '@app/errors/http/500/service-unavailable.error';

export class HttpError extends Error {
    constructor(
        public res?: HttpErrorResponse,
        message?: string
    ) {
        super(typeof res === 'undefined' ? undefined : res.statusText);
        Object.setPrototypeOf(this, HttpError.prototype);
        if (res && res.status) {
            message = message ? message.replace(/data\.attributes\./g, '') : undefined;
            switch (res.status) {
                case 400:
                    return new BadRequestError(res, message);
                case 401:
                    return new UnauthorizedError(res, message);
                case 402:
                    return new PaymentRequiredError(res, message);
                case 403:
                    return new ForbiddenError(res, message);
                case 404:
                    return new NotFoundError(res, message);
                case 405:
                    return new MethodNotAllowedError(res, message);
                case 406:
                    return new NotAcceptableError(res, message);
                case 408:
                    return new RequestTimeoutError(res, message);
                case 409:
                    return new ConflictError(res, message);
                case 415:
                    return new UnsupportedMediaTypeError(res, message);
                case 418:
                    return new ImATeapotError(res, message);
                case 422:
                    return new UnprocessableEntityError(res, message);
                case 423:
                    return new LockedError(res, message);

                case 500:
                    return new InternalServerError(res, message);
                case 501:
                    return new NotImplementedError(res, message);
                case 502:
                    return new BadGatewayError(res, message);
                case 503:
                    return new ServiceUnavailableError(res, message);
                case 504:
                    return new GatewayTimeoutError(res, message);
                case 420:
                    return new EnhanceYourCalmError(res, message);
                case 440:
                    return new LoginTimeoutError(res, message);
            }
        }
    }
}
