import { HttpClientModule } from '@angular/common/http';
import { HttpClientTestingModule as AngularHttpClientTestingModule } from '@angular/common/http/testing';
import { Injector, NgModule } from '@angular/core';

/**
 * A singleton instance injector that can be imported anywhere throughout the app in order to re-use the HttpClient used elsewhere in the app.
 * Normally we would use Angular's built-in dependency injection to pull in the HttpClient but this is sometimes necessary in static contexts (see QueryFetcher)
 */
export let InjectorInstance: Injector;

/**
 * Contains Angular's HttpClientModule as well as definitions for our HttpInterceptors which are hit on each request to the httpClient
 */
@NgModule({
    imports: [HttpClientModule],
})
export class HttpModule {
    constructor(private injector: Injector) {
        InjectorInstance = this.injector;
    }
}

/**
 * To be used in unit tests, this module re-exports the existing functionality from Angular's Http Client Testing Module.
 * This is necessary to ensure the InjectorInstance is populated in unit tests, otherwise we will get a null reference error.
 */
@NgModule({
    imports: [AngularHttpClientTestingModule],
})
export class HumiHttpClientTestingModule {
    constructor(private injector: Injector) {
        InjectorInstance = this.injector;
    }
}
