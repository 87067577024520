import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { DropdownColumn } from '@app/modules/table/interfaces/dropdown-column.interface';
import { InteractableColumn } from '@app/modules/table/interfaces/interactable-column.interface';
import { EmployeeDeduction } from '@models/payroll/employee/deduction.model';
import { BenefitDeductionFrequency } from '@models/payroll/company/benefit-deduction-frequency.model';

const { SORT_ORDER } = BenefitDeductionFrequency;

export class FrequencyColumn extends Column implements InteractableColumn, DropdownColumn {
    title = 'Frequency';
    property = 'benefitDeductionFrequencyId';
    type: CellDisplay = CellDisplay.dropdown;
    dropdownOptions: { label: string; value: number }[] = [];

    constructor(
        private deductionId: number,
        private companyId: number
    ) {
        super();

        this.getBenefitDeductionFrequencies();
    }

    private async getBenefitDeductionFrequencies(): Promise<void> {
        const [results] = await BenefitDeductionFrequency.get();

        const options = results.map((result) => {
            return {
                label: result.displayName,
                name: result.name,
                value: result.id,
            };
        });

        this.dropdownOptions = SORT_ORDER.map((order) => options.find(({ name }) => name == order));
    }

    getDropdownOptions(_: EmployeeDeduction): { label: string; value: number }[] {
        return this.dropdownOptions;
    }

    getBindProperty(): string {
        return 'benefitDeductionFrequencyId';
    }

    async onRowChange(row: EmployeeDeduction): Promise<void> {
        await row.param('deduction', this.deductionId).param('company', this.companyId).save();
    }
}
