import { AppDatePipe } from '@app/pipes';
import { Model } from '../core/base.model';

import { Translatable } from '@app/types/translatable.type';
import {
    TrainingProgramRecurrenceManyDisplayOptions,
    TrainingProgramRecurrenceSingleDisplayOptions,
} from '@app/types/translatables/training-display.options';
import { File } from '@models/common/file.model';
import moment from 'moment';

export class TrainingProgram extends Model {
    static managePermission = 'manageTrainingPrograms';
    static accessTrainingModulePermission = 'accessTrainingModule';

    protected static _resource = 'training/trainingPrograms';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'name',
        'description',
        'dueOffset',
        'dueType',
        'frequencyOffset',
        'frequencyType',
        'autoEnrollType',
        'maxOccurrences',
        'isEmployeeSubmittable',
        'hasExpirationDate',
        'hasCost',
        'hasCredits',
        'hasInstructor',
        'hasResult',
        'hasHours',
        'recurrenceType',
        'recurrenceAnnualMonth',
        'recurrenceAnnualDay',
        'attach',
        'detach',
        'enrolledCount',
    ];

    appDatePipe = new AppDatePipe();

    static createOnceOff(): TrainingProgram {
        return new TrainingProgram({
            isEmployeeSubmittable: false,
            hasExpirationDate: true,
            hasCost: true,
            hasCredits: true,
            hasInstructor: true,
            hasResult: false,
            hasHours: true,
        });
    }

    get name(): string {
        return this._attributes['name'];
    }
    set name(val: string) {
        this._attributes['name'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }
    set description(val: string) {
        this._attributes['description'] = val;
    }

    get dueOffset(): number | null {
        return this._attributes['dueOffset'] || null;
    }
    set dueOffset(val: number) {
        this._attributes['dueOffset'] = val;
    }

    get dueType(): string {
        return this._attributes['dueType'] || null;
    }
    set dueType(val: string) {
        this._attributes['dueType'] = val;
    }

    get frequencyOffset(): number {
        return this._attributes['frequencyOffset'] || null;
    }
    set frequencyOffset(val: number) {
        this._attributes['frequencyOffset'] = val;
    }

    get frequencyType(): string {
        return this._attributes['frequencyType'] || null;
    }
    set frequencyType(val: string) {
        this._attributes['frequencyType'] = val;
    }

    get autoEnrollType(): string | null {
        return this._attributes['autoEnrollType'] || null;
    }
    set autoEnrollType(val: string) {
        this._attributes['autoEnrollType'] = val;
    }

    get maxOccurrences(): number | null {
        return this._attributes['maxOccurrences'] || null;
    }
    set maxOccurrences(val: number | null) {
        this._attributes['maxOccurrences'] = val;
    }

    get isEmployeeSubmittable(): boolean {
        return this._attributes['isEmployeeSubmittable'] || false;
    }
    set isEmployeeSubmittable(val: boolean) {
        this._attributes['isEmployeeSubmittable'] = val;
    }

    get hasExpirationDate(): boolean {
        return this._attributes['hasExpirationDate'] || false;
    }
    set hasExpirationDate(val: boolean) {
        this._attributes['hasExpirationDate'] = val;
    }

    get hasCost(): boolean | null {
        return this._attributes['hasCost'] || null;
    }
    set hasCost(val: boolean) {
        this._attributes['hasCost'] = val;
    }

    get hasCredits(): boolean | null {
        return this._attributes['hasCredits'] || null;
    }
    set hasCredits(val: boolean) {
        this._attributes['hasCredits'] = val;
    }

    get hasInstructor(): boolean | null {
        return this._attributes['hasInstructor'] || null;
    }
    set hasInstructor(val: boolean) {
        this._attributes['hasInstructor'] = val;
    }

    get hasResult(): boolean | null {
        return this._attributes['hasResult'] || null;
    }
    set hasResult(val: boolean) {
        this._attributes['hasResult'] = val;
    }

    get hasHours(): boolean | null {
        return this._attributes['hasHours'] || null;
    }
    set hasHours(val: boolean) {
        this._attributes['hasHours'] = val;
    }
    get recurrenceType(): string | null {
        return this._attributes['recurrenceType'] || null;
    }

    set recurrenceType(val: string) {
        this._attributes['recurrenceType'] = val;
    }

    get recurrenceAnnualMonth(): number | null {
        return this._attributes['recurrenceAnnualMonth'] || null;
    }

    set recurrenceAnnualMonth(val: number | null) {
        this._attributes['recurrenceAnnualMonth'] = val;
    }

    get recurrenceAnnualDay(): number | null {
        return this._attributes['recurrenceAnnualDay'] || null;
    }

    set recurrenceAnnualDay(val: number | null) {
        this._attributes['recurrenceAnnualDay'] = val;
    }

    get isRecurring(): boolean {
        return this._attributes['isRecurring'] || false;
    }

    get recurrenceLabel(): Translatable {
        if (this.recurrenceType === 'frequency') {
            return this.getFrequencyLabel();
        }

        if (this.recurrenceType === 'expiration') {
            return 'training.recurrenceLabels.onExpiration';
        }

        if (this.recurrenceType === 'annual') {
            return this.getAnnualRecurrenceLabel();
        }

        return '—';
    }

    private getAnnualRecurrenceLabel(): Translatable {
        const date = moment(this.recurrenceAnnualMonth + '-' + this.recurrenceAnnualDay, 'M-D');
        if (!date.isValid()) {
            return 'training.recurrenceLabels.annually';
        }

        const monthDay = this.appDatePipe.transform(date, 'MMMM d') ?? null;
        if (!monthDay) {
            return '-';
        }

        return {
            key: 'training.recurrenceLabels.everyDayOfMonth',
            params: {
                monthDay: monthDay,
            },
        };
    }

    private getFrequencyLabel(): Translatable {
        if (this.frequencyOffset === 1) {
            return TrainingProgramRecurrenceSingleDisplayOptions[this.frequencyType] ?? `Every ${this.frequencyType}`;
        }

        const translationKey = TrainingProgramRecurrenceManyDisplayOptions[this.frequencyType] ?? null;
        if (!translationKey) {
            return `Every ${this.frequencyOffset} ${this.frequencyType}s`;
        }

        return {
            key: translationKey,
            params: {
                frequency: this.frequencyOffset,
            },
        };
    }

    get hasDueDate(): boolean {
        return this.dueType !== null && this.dueOffset !== null;
    }

    get enrolledCount(): number {
        return this._attributes['enrolledCount'];
    }

    get files(): File[] {
        return this.hasMany(File, 'files');
    }
}
