import { Model } from '../core/base.model';

export class CustomField extends Model {
    protected static _resource = 'employees/:employee/customFields';

    protected static _version = 'v1';

    protected static _serializeAttributes = ['customFieldId', 'employeeId', 'value'];

    get customFieldId(): number | null {
        return this._attributes['customFieldId'] || null;
    }

    get employeeId(): number | null {
        return this._attributes['employeeId'] || null;
    }

    get value(): string | null {
        return this._attributes['value'] || null;
    }

    set value(val: string) {
        this._attributes['value'] = val;
    }
}
