<div class="ui page dimmer">
    <div class="content">
        <div class="ui segment" [class.loading]="isLoading">
            <h2 class="ui header">{{ headerText | appTranslate }}</h2>
            <div class="content">
                <form class="ui form" #form="ngForm">
                    <div class="ui stackable grid">
                        <!-- Items Top -->
                        <div class="eight wide column items top-section">
                            <!--<app-search placeholder="Search..."-->
                            <!--(onSearch)="onSearch($event)"></app-search>-->

                            <!-- This search needs to be replaced once onboarding reminders has an updated endpoint -->
                            <div class="ui left icon input" style="width: 100%">
                                <i class="search icon"></i>
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="{{
                                        'components.dialog-common.searchNameOrDescription' | appTranslate
                                    }}"
                                    [formControl]="searchControl"
                                />
                            </div>
                        </div>

                        <!-- Selected Top -->
                        <div class="eight wide column top-section">
                            <h4 class="ui header">{{ 'components.dialog-common.selected' | appTranslate }}</h4>
                            <a
                                (click)="removeAll()"
                                [class.disabled]="!selectItems?.length && !allSelected"
                                class="bulk-action"
                                >{{ 'components.dialog-common.removeAll' | appTranslate }}</a
                            >
                        </div>

                        <!-- Items Body -->
                        <div class="eight wide column results">
                            <div class="ui middle aligned selection list">
                                <div
                                    class="item"
                                    *ngIf="showItems?.length && showItems?.length > 1"
                                    [class.selected]="allSelected"
                                    (click)="selectAll()"
                                >
                                    <app-avatar
                                        [fullName]="
                                            {
                                                key: 'components.dialog-common.totalResultsItems',
                                                params: { count: totalResults },
                                            } | appTranslate
                                        "
                                        [name]="true"
                                    ></app-avatar>
                                </div>
                                <ng-container *ngFor="let item of showItems; let index = index">
                                    <div class="item" [class.selected]="isSelected(item)" (click)="select(item)">
                                        <i class="fa fa-database"></i> {{ item?.name }}
                                    </div>
                                </ng-container>
                                <div *ngIf="!showItems?.length" class="item disabled">
                                    {{ 'components.dialog-common.noResults' | appTranslate }}
                                </div>
                            </div>
                        </div>

                        <!-- Selected Body -->
                        <div class="eight wide column selection">
                            <div class="ui middle aligned selection list">
                                <div class="item" *ngIf="allSelected" (click)="removeAll()">
                                    <app-avatar
                                        [fullName]="
                                            {
                                                key: 'components.dialog-common.allCountFilteredItems',
                                                params: { count: totalResults },
                                            } | appTranslate
                                        "
                                        [name]="true"
                                    ></app-avatar>
                                </div>
                                <ng-container *ngFor="let item of selectItems; let index = index">
                                    <div class="item" (click)="remove(item)">
                                        <div class="item" [class.selected]="isSelected(item)" (click)="select(item)">
                                            <i class="fa fa-database"></i> {{ item?.name }}
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="!selectItems?.length && !allSelected" class="item disabled">
                                    {{ 'components.dialog-common.noSelections' | appTranslate }}
                                </div>
                            </div>
                        </div>

                        <!-- Items bottom -->
                        <div class="eight wide column bottom-section">
                            <app-pagination
                                *ngIf="items?.length"
                                [total]="totalResults"
                                [current]="currentPage"
                                [fixed]="false"
                                (pageChange)="onPageChange($event)"
                            >
                            </app-pagination>
                            <a (click)="selectPage()" [class.disabled]="!items?.length" class="bulk-action">{{
                                'components.dialog-common.selectPage' | appTranslate
                            }}</a>
                        </div>

                        <!-- Selected Bottom -->
                        <div class="eight wide column bottom-section selected-items">
                            <ui-button style="float: right" (click)="approve()" [disabled]="!selection" type="primary">
                                {{ label | appTranslate }}
                            </ui-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
