import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonApiResponse } from '@app/interfaces';
import { IntegrationType } from '@app/models/common/integration-type.model';
import { IntegrationXero } from '@app/models/company/settings/integrations/xero.model';
import { SettingsIntegration } from '@app/models/company/settings/settings-integration.model';
import { XeroOAuthUrl } from '@app/modules/payroll/interfaces/xero-oauth.interface';
import { ActiveIntegrations } from '@app/modules/settings/enums/integration.enum';
import { SettingsIntegrationService } from '@app/modules/settings/services';
import { AuthService } from '@app/services';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class XeroIntegrationService {
    private payrollCallbackUrl = `${environment.api}/v2/accountManagement/login/xero/callback`;
    constructor(
        private auth: AuthService,
        private http: HttpClient,
        private integrationService: SettingsIntegrationService
    ) {}

    getOAuthUrl(): Promise<string> {
        return this.http
            .get(environment.api + '/v2/accountManagement/login/xero')
            .toPromise()
            .then((res: JsonApiResponse<XeroOAuthUrl>) => res.data.attributes.authorizationUrl);
    }

    performKeyExchange(searchParams: URLSearchParams): Promise<boolean> {
        const query = new HttpParams().appendAll({
            code: searchParams.get('code'),
            state: searchParams.get('state'),
        });
        return this.http
            .get(this.payrollCallbackUrl, { params: query })
            .toPromise()
            .then(() => true)
            .catch(() => false);
    }

    revokeAccess(): Promise<Object> {
        const revokeXeroPath = `${environment.api}/v2/accountManagement/login/xero/disconnect`;
        return this.http.delete(revokeXeroPath).toPromise();
    }

    /**
     * Load the details object for the company's Xero integration.
     *
     * If the integrations and integrationTypes parameters are provided, they will
     * be used to determine the id of the Xero integration detail.
     * Otherwise they will be loaded from the backend.
     */
    async getXeroIntegrationDetails(
        integrationTypes?: IntegrationType[],
        integrations?: SettingsIntegration[]
    ): Promise<IntegrationXero | undefined> {
        // Load integrations and types if they weren't provided
        [integrationTypes, integrations] = await Promise.all([
            integrationTypes ? Promise.resolve(integrationTypes) : this.integrationService.getIntegrationTypes(),
            integrations ? Promise.resolve(integrations) : this.integrationService.getIntegrations(),
        ]);

        // Find the Xero integration type
        const xeroIntegrationType = integrationTypes.find(({ name }) => name === ActiveIntegrations.xero);
        if (!xeroIntegrationType) return;

        // Find the Xero integration
        const xeroIntegration = integrations.find(
            ({ integrationTypeId }) => integrationTypeId === xeroIntegrationType.id
        );
        if (!xeroIntegration) return;

        // Finally, get the integration details
        return IntegrationXero.param('company', this.auth.company?.id).find(xeroIntegration.integrationDetailId);
    }
}
