import { Paystub } from '@app/models/payroll/paystub.model';
import { Model } from '@models/core/base.model';

const BonusPayItemNames = ['bonus_work_related', 'bonus_discretionary'];
const RequiredPayItemNames = ['commission', 'vacation pay', ...BonusPayItemNames];
export class AdditionalPayItem extends Model {
    static BonusPayItemNames = BonusPayItemNames;
    static RequiredPayItemNames = RequiredPayItemNames;

    protected static _resource = 'payroll/companies/:company/payrolls/additionalPayItems';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'name',
        'displayName',
        'amount',
        'editable',
        'userModified',
        'hours',
        'multiplier',
        'prorated',
        'prorationDetails',
        'rate',
        'paystubId',
        'compensationId',
        'reduceVacationPayBalance',
    ];

    get paystubId(): number {
        return this._attributes['paystubId'];
    }

    set paystubId(val: number) {
        this._attributes['paystubId'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }
    get amount(): number {
        return Number(this._attributes['amount']);
    }
    get editable(): boolean {
        return this._attributes['editable'];
    }
    get hours(): number {
        return Number(this._attributes['hours']);
    }
    get multiplier(): number {
        return Number(this._attributes['multiplier']);
    }
    get prorated(): boolean {
        return this._attributes['prorated'];
    }
    get periodic(): boolean {
        return this._attributes['periodic'];
    }
    get prorationDetails(): object {
        return this._attributes['prorationDetails'];
    }
    get rate(): number {
        return this._attributes['rate'];
    }
    get userModified(): boolean {
        return this._attributes['userModified'];
    }

    get compensationId(): number {
        return this._attributes['compensationId'];
    }

    get displayName(): string {
        return this._attributes['displayName'];
    }

    get subtractFromSalary(): boolean {
        return this._attributes['subtractFromSalary'];
    }

    get reduceVacationPayBalance(): boolean {
        return this._attributes['reduceVacationPayBalance'];
    }

    set compensationId(val: number) {
        this._attributes['compensationId'] = val;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }
    set displayName(val: string) {
        this._attributes['displayName'] = val;
    }
    set amount(val: number) {
        this._attributes['amount'] = Number(val);
    }
    set editable(val: boolean) {
        this._attributes['editable'] = val;
    }
    set hours(val: number) {
        this._attributes['hours'] = Number(val);
    }
    set multiplier(val: number) {
        this._attributes['multiplier'] = val;
    }
    set prorated(val: boolean) {
        this._attributes['prorated'] = val;
    }
    set prorationDetails(val: object) {
        this._attributes['prorationDetails'] = val;
    }
    set rate(val: number) {
        this._attributes['rate'] = val;
    }
    set userModified(val: boolean) {
        this._attributes['userModified'] = val;
    }

    get paystub(): Paystub {
        return this.hasOne(Paystub, 'paystub');
    }

    get isBonusPayItem(): boolean {
        return BonusPayItemNames.includes(this.name);
    }

    get isNonRequiredItem(): boolean {
        return !this.isRequiredPayItem;
    }

    get isRequiredPayItem(): boolean {
        return RequiredPayItemNames.includes(this.name);
    }

    get isZero(): boolean {
        return !Number(this.amount);
    }

    get employeeHoursExists(): boolean {
        return this._attributes['employeeHoursExists'];
    }

    toAmount(): number {
        return Number(this.amount);
    }

    get rateMultiplied(): number {
        return this.rate * this.multiplier;
    }

    get amountWithoutMultiplier(): number {
        return this.rate * this.hours;
    }
}
