import { Component, Input, OnInit } from '@angular/core';
import { ApprovalWorkflowStep } from '@models/approvals/approval-workflow-step.model';
import { ApprovalWorkflow } from '@models/approvals/approval-workflow.model';

@Component({
    selector: 'approval-workflow-config-panel',
    templateUrl: './approval-workflow-config-panel.template.html',
    styleUrls: ['./approval-workflow-config-panel.style.scss'],
})
export class ApprovalWorkflowConfigPanel implements OnInit {
    @Input() workflow: ApprovalWorkflow;
    @Input('aria-labelledby') ariaLabelledBy?: string;

    constructor() {}

    ngOnInit(): void {
        if (this.workflow.steps.length === 0) {
            this.addStep();
        }
    }

    addStep() {
        const step = new ApprovalWorkflowStep({
            actor: 'direct_manager',
        });

        this.workflow.steps.push(step);

        this.indexSteps();
    }

    removeStep(index: number) {
        this.workflow.steps.splice(index, 1);

        this.indexSteps();
    }

    indexSteps() {
        for (const [index, step] of this.workflow.steps.entries()) {
            step.orderPosition = index + 1;
        }
    }
}
