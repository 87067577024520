import { ModelMixin } from '@models/core/base-generic.model';

export class Rl1Box extends ModelMixin<Rl1Box>() {
    protected static _resource = 'payroll/rl1Boxes';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt'];

    get code(): number {
        return this._attributes['code'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get other(): boolean {
        return this._attributes['other'];
    }

    get inEmploymentIncome(): boolean {
        return this._attributes['inEmploymentIncome'];
    }

    get taxYear(): number {
        return this._attributes['taxYear'];
    }

    get xmlName(): string {
        return this._attributes['xmlName'];
    }

    get xmlNameCamel(): string {
        return this.camelize(this._attributes['xmlName']);
    }

    private camelize(str: string): string {
        return str.replace(/(\_\w)/g, function (k: string): string {
            return k[1].toUpperCase();
        });
    }
}
