<div class="wrapper">
    <div class="steps" role="list" [attr.aria-labelledby]="ariaLabelledBy">
        <div
            class="step"
            [class.hideIndicator]="step.hideIndicator"
            role="listitem"
            [attr.aria-labelledby]="id + '-' + i"
            *ngFor="let step of approvalFlowSteps; let i = index; let last = last"
        >
            <div class="indicator">
                <div
                    *ngIf="!step.hideIndicator"
                    class="circle"
                    [class.approved]="step.status === approvalFlowStatus.PositiveStatus"
                    [class.denied]="step.status === approvalFlowStatus.NegativeStatus"
                >
                    <mat-icon svgIcon="check" *ngIf="step.status === approvalFlowStatus.PositiveStatus"></mat-icon>
                    <mat-icon svgIcon="close" *ngIf="step.status === approvalFlowStatus.NegativeStatus"></mat-icon>
                </div>

                <div class="line" *ngIf="!last"></div>
            </div>

            <div
                class="text"
                [class.approved]="step.status === approvalFlowStatus.PositiveStatus"
                [class.hideIndicator]="step.hideIndicator"
            >
                <div class="primary">
                    <span [id]="id + '-' + i">{{ step.title | appTranslate }}</span>
                </div>

                <div class="secondary">
                    <span>{{ step.description | appTranslate }}</span>
                </div>
            </div>

            <ui-button
                attr.name="{{ 'approvalStepButton' + i }}"
                (click)="onButtonEmit(i - 1)"
                *ngIf="step.buttonText"
                class="button"
                style="margin-left: auto"
                type="link"
                >{{ step.buttonText | translate }}</ui-button
            >
        </div>
    </div>
</div>
