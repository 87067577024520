import VideoErrorInterface from '@videos/errors/video-error.interface';

export abstract class VideoError extends Error implements VideoErrorInterface {
    abstract readonly code: string;
    readonly videoUrl: string;

    constructor(message: string, videoUrl: string) {
        super(message);

        this.videoUrl = videoUrl;
    }
}
