import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimplyBenefitsOnboardingEmployee } from '@app/models/benefits/simply-benefits-onboarding-employee.model';
import { BenefitsResource } from '@benefits/benefits.resource';
import {
    ApiMultipleEntriesResponse,
    ApiResponse,
    SimplyBenefitsTransferObject,
} from '@benefits/interfaces/simply-benefits-api-response.interface';
import { SimplyBenefitsClass } from '@benefits/interfaces/simply-benefits-class.interface';
import { SimplyBenefitsDivision } from '@benefits/interfaces/simply-benefits-division.interface';
import { SimplyBenefitsMember } from '@benefits/interfaces/simply-benefits-member.interface';
import { SimplyBenefitsOnboardingData } from '@benefits/interfaces/simply-benefits-onboarding-data.interface';

@Injectable()
export class SimplyBenefitsService {
    constructor(private http: HttpClient) {}

    async getMember(employeeId: number): Promise<SimplyBenefitsMember> {
        return this.http
            .get(BenefitsResource.getMember.replace(':employeeId', employeeId.toString()))
            .toPromise()
            .then((response: ApiResponse) => {
                return <SimplyBenefitsMember>response.data.attributes;
            });
    }

    async getNewMemberToken(employeeId: number): Promise<string> {
        return this.http
            .get(BenefitsResource.getNewMemberToken.replace(':employeeId', employeeId.toString()))
            .toPromise()
            .then((response: ApiResponse) => {
                return <string>response.data.attributes.token ?? '';
            });
    }

    async getClass(classId: string): Promise<SimplyBenefitsClass> {
        return this.http
            .get(BenefitsResource.getClass.replace(':classId', classId))
            .toPromise()
            .then((response: ApiResponse) => {
                return <SimplyBenefitsClass>response.data.attributes;
            });
    }

    async getClasses(): Promise<SimplyBenefitsTransferObject[]> {
        return this.http
            .get(BenefitsResource.getClasses)
            .toPromise()
            .then((response: ApiMultipleEntriesResponse) => {
                return <SimplyBenefitsTransferObject[]>response.data;
            });
    }

    async getDivision(divisionId: string): Promise<SimplyBenefitsDivision> {
        return this.http
            .get(BenefitsResource.getDivision.replace(':divisionId', divisionId))
            .toPromise()
            .then((response: ApiResponse) => {
                return <SimplyBenefitsDivision>response.data.attributes;
            });
    }

    async getDivisions(): Promise<SimplyBenefitsTransferObject[]> {
        return this.http
            .get(BenefitsResource.getDivisions)
            .toPromise()
            .then((response: ApiMultipleEntriesResponse) => {
                return <SimplyBenefitsTransferObject[]>response.data;
            });
    }

    async setOnboardingDataOfEmployee(
        employeeId: number,
        simplyBenefitsData: SimplyBenefitsOnboardingData
    ): Promise<SimplyBenefitsOnboardingEmployee | null> {
        if (!simplyBenefitsData.class?.classID || !simplyBenefitsData.division?.divisionID) {
            return null;
        }

        let newOnboardingData = new SimplyBenefitsOnboardingEmployee();
        newOnboardingData.classId = simplyBenefitsData.class.classID;
        newOnboardingData.divisionId = simplyBenefitsData.division.divisionID;
        newOnboardingData.waiveEligibilityPeriod = simplyBenefitsData.waiveWaitingPeriod;
        return newOnboardingData.param('employeeId', employeeId).save();
    }
}
