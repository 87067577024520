import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'app-deprecated-modal',
    templateUrl: './deprecated-modal.template.html',
    styleUrls: ['./deprecated-modal.style.scss'],
})
export class DeprecatedModalComponent {
    @Input() isLoading = false;
    @Input() closable = true;
    @Input() tall = false;
    @Input() narrow = false;
    @Input() small = false;
    @Input() medium = false;
    @Input() large = false;
    @Input() centered = false;
    @Input() forCards = false;
    @Input() useDimmer = true;
    @Input() hideCloseButton = false;
    @Input() noBorder = false;
    @Output() cancel: EventEmitter<null> = new EventEmitter<null>();

    constructor(protected element: ElementRef) {}

    show($event?: any): void {
        if ($event) {
            $event.stopPropagation();
        }
        $(this.element.nativeElement)
            .find('.ui.dimmer')
            .dimmer({
                closable: this.closable,
                onHide: () => {
                    this.cancel.emit();
                },
            });
        this.onShow();
    }

    @HostListener('document:keydown.escape')
    onClose(): void {
        this.cancel.emit();
        $(this.element.nativeElement).find('.ui.dimmer').dimmer('hide');
    }

    protected onShow(): void {
        $(this.element.nativeElement).find('.ui.dimmer').dimmer('show');

        $(this.element.nativeElement).find('.ui.segment').fadeIn();
    }
}
