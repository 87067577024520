import { Injectable } from '@angular/core';
import { AudioFormatExtensions } from '@app/components/app-audio-recorder/enums/audio-format-extensions.enum';
import { SupportedAudioFormats } from '@app/components/app-audio-recorder/enums/audio-formats.enum';
import { FileUploadDirectories } from '@app/enums';
import { FileHelperService } from '@app/services/file-helper.service';
import { File as FileModel } from '@models/common/file.model';

@Injectable()
export class AudioFileService {
    constructor(private fileService: FileHelperService) {}

    async store(audioBlob: Blob, audioFormat: SupportedAudioFormats): Promise<FileModel> {
        const format = this.removeCodecFromFormat(audioFormat);
        const name = this.generateFileName(format);
        const file = new File([audioBlob], name, { type: format });

        /*
         * This being true will ensure the backend normalizes audio files to .mp3
         */
        const shouldOptimize = true;

        return this.fileService.store(file, FileUploadDirectories.audio, shouldOptimize);
    }

    /*
     * Generate a simple file name with extension
     */
    generateFileName(format: string): string {
        // ex. 1652883130578.weba
        return `${Date.now()}${AudioFormatExtensions[format]}`;
    }

    /*
     * Get the file type without codec
     */
    removeCodecFromFormat(audioFormat: SupportedAudioFormats): string {
        return audioFormat.split(';')[0];
    }
}
