import { AfterContentInit, Component, ElementRef, Input } from '@angular/core';

interface DropdownElement {
    dropdown: CallableFunction;
}
@Component({
    selector: 'app-context-menu',
    templateUrl: './context-menu.template.html',
    styleUrls: ['./context-menu.style.scss'],
})
export class ContextMenuComponent implements AfterContentInit {
    @Input() stopPropagation = false;
    @Input() withBorder = true;

    dropdownElement: DropdownElement;

    constructor(private element: ElementRef) {}

    ngAfterContentInit(): void {
        setTimeout(() => {
            this.dropdownElement = $(this.element.nativeElement).find('.ui.dropdown').dropdown();

            $(this.element.nativeElement).click((event) => {
                event.preventDefault();
                event.stopPropagation();
            });
        });
    }

    hide(): void {
        this.dropdownElement.dropdown('hide');
    }
}
