import { Model } from '../core/base.model';

export class T4Box extends Model {
    protected static _version = 'v2';
    protected static _resource = 'payroll/t4Boxes';

    box45Options = [
        {
            value: 1,
            label: 'Code 1',
            description: 'No access to any dental care insurance, or coverage of dental services of any kind.',
        },
        {
            value: 2,
            label: 'Code 2',
            description:
                'Access to any dental care insurance, or coverage of dental services of any kind for only the payee.',
        },
        {
            value: 3,
            label: 'Code 3',
            description:
                'Access to any dental care insurance, or coverage of dental services of any kind for payee, spouse and dependants.',
        },
        {
            value: 4,
            label: 'Code 4',
            description:
                'Access to any dental care insurance, or coverage of dental services of any kind for only the payee and their spouse.',
        },
        {
            value: 5,
            label: 'Code 5',
            description:
                'Access to any dental care insurance, or coverage of dental services of any kind for only the payee and dependants.',
        },
    ];

    /*
        Using parseInt here as we are comparing this id with selectedIds
        in many places within our logic and this id is coming in as
        a string
    */
    get id(): number {
        let t4BoxId = this._attributes['id'];
        if (typeof t4BoxId === 'string') {
            t4BoxId = parseInt(t4BoxId);
        }
        return t4BoxId;
    }

    get code(): number {
        return this._attributes['code'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get other(): boolean {
        return this._attributes['other'];
    }

    get inEmploymentIncome(): boolean {
        return this._attributes['inEmploymentIncome'];
    }

    get taxYear(): number {
        return this._attributes['taxYear'];
    }

    get xmlName(): string {
        return this._attributes['xmlName'];
    }

    get xmlNameCamel(): string {
        return this.camelize(this._attributes['xmlName']);
    }

    private camelize(str: string): string {
        return str.replace(/(\_\w)/g, function (k: string): string {
            return k[1].toUpperCase();
        });
    }
}
