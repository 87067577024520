import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Model } from '@app/models/core/base.model';

@Component({
    selector: 'app-group-checkboxes',
    templateUrl: './group-checkboxes.template.html',
    styleUrls: ['./group-checkboxes.style.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GroupCheckboxesComponent),
            multi: true,
        },
    ],
})
export class GroupCheckboxesComponent<T extends Model> implements ControlValueAccessor {
    @Input() entities: T[] = [];
    @Input() labelProperty = 'name';

    @Input() previousEntities: T[] = [];
    @Input() disablePreviousSelection = false;
    @Output() selectionChange = new EventEmitter();

    private onChange;
    private selected: T[] = [];

    constructor() {}

    writeValue(val: T[] | null): void {
        this.selected = val;
    }

    set value(val: T[] | null) {
        if (val !== undefined && this.selected !== val) {
            this.selected = val;
            this.onChange(val);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(): void {}

    onToggle(entity: T, event: MatCheckboxChange): void {
        const indexOfSelectedItem = this.selected.findIndex((selectedEntity) => selectedEntity.id === entity.id);

        if (!event.checked && indexOfSelectedItem !== -1) {
            this.selected.splice(indexOfSelectedItem, 1);
            this.selectionChange.emit();
            return;
        }

        this.selected.push(entity);
        this.selectionChange.emit();
    }

    isChecked(entity: T): boolean {
        if (this.previousEntities.length) {
            return this.previousEntities.some((item) => item.id === entity.id);
        }

        return this.selected?.some((item) => item.id === entity.id) ?? false;
    }

    isDisabled(entity: T): boolean {
        if (!this.disablePreviousSelection) {
            return false;
        }
        return this.previousEntities.some((item) => item.id === entity.id);
    }
}
