import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    MatLegacySnackBar as MatSnackBar,
    MatLegacySnackBarConfig as MatSnackBarConfig,
    MatLegacySnackBarRef as MatSnackBarRef,
    LegacyTextOnlySnackBar as TextOnlySnackBar,
} from '@angular/material/legacy-snack-bar';
import { ErrorParser } from '@app/classes';
import { SnackBarMessageComponent } from '@app/components/snack-bar-message/snack-bar-message.component';
import { getIconNames } from '@app/constants/icons';
import { JsonApiError } from '@app/errors/json-api.error';
import { Translatable, TranslatableObject, isTranslatableObject } from '@app/types/translatable.type';
import * as Sentry from '@sentry/angular';

const iconNames = getIconNames();

const DEFAULT_DURATION = 4000;

type NotificationClass = 'error' | 'info' | 'success' | 'warn';

const ICON_MAP: Record<NotificationClass, (typeof iconNames)[number]> = {
    error: 'cancel',
    warn: 'warning',
    success: 'success',
    info: 'boldInfo',
};

/**
 * This service is used to notify users in the UI using a snack bar (toast).
 * Only one message can be displayed at a time.
 */
@Injectable()
export class NotifyService {
    constructor(private snackBar: MatSnackBar) {}

    /**
     * A notification that something good has happened.
     */
    success(message: Translatable): void {
        const content = isTranslatableObject(message) ? message : { key: message?.trim() };

        if (!content.key) {
            this.reportMissingMessage();
            content.key = 'success';
        }

        this.customHtml(content, 'success');
    }

    /**
     * A notification of information that is neutral.
     */
    info(message: Translatable): void {
        const content = isTranslatableObject(message) ? message : { key: message?.trim() };

        if (!content.key) {
            this.reportMissingMessage();

            return;
        }

        this.customHtml(content, 'info');
    }

    /**
     * A notification that something bad could happen.
     */
    warn(message: Translatable): void {
        const content = isTranslatableObject(message) ? message : { key: message?.trim() };

        if (!content.key) {
            this.reportMissingMessage();

            return;
        }

        this.customHtml(content, 'warn');
    }

    /**
     * A notification that something bad has happened.
     * Also allows errors, but read below for more information
     * Handles `unknown`, because `any` is sometimes passed to this method
     */
    error(error: Translatable | Error | unknown): void {
        let content: TranslatableObject;
        if (error && typeof error === 'string') {
            content = { key: error.trim() };
        } else if (isTranslatableObject(error)) {
            content = error;
        } else {
            content = { key: this.getErrorMessage(error) };
        }

        if (!content.key) {
            this.reportMissingMessage();
            content.key = 'error';
        }

        /**
         * We need to open from a component since MatSnackBar does not support HTML by default
         * so we pass the html content into a component
         */
        this.customHtml(content, 'error');
    }

    /**
     * Interact with the material snack bar directly.
     * If you are using this, you need a good reason. Please make sure to clear it with your team lead.
     * Most of the time, the other methods (success, error) should be used.
     */
    custom(message: string, action: string, config: MatSnackBarConfig): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(message, action, config);
    }

    /**
     * @see app/components/snack-bar-message/snack-bar-message.template.html
     */
    customHtml(message: Translatable, panelClass: NotificationClass): void {
        const content = isTranslatableObject(message) ? message : { key: message?.trim() };
        this.snackBar.openFromComponent(SnackBarMessageComponent, {
            duration: DEFAULT_DURATION,
            panelClass: panelClass,
            data: {
                icon: ICON_MAP[panelClass],
                ...content,
            },
        });
    }

    /**
     * In the case that a message was an empty string, or worse, undefined,
     * we want to report it so we can track it down.
     */
    private reportMissingMessage(): void {
        const error = new Error('No message to notification.');
        Sentry.captureException(error);
    }

    /**
     * The error method in this service needs to be able to retrieve the message
     * from the error object since we want to support legacy usage of this service.
     *
     * TODO: We need to improve our error message formatting/parsing - this is a stop gap
     *       to fix our error notifications but ErrorParser should be improved or our
     *       API should return good error messages.
     *
     *       See https://gethumi.atlassian.net/browse/PDS-931
     */
    private getErrorMessage(error: Error | HttpErrorResponse | string | unknown): string {
        let message = '';
        /**
         * Support error responses from HttpClient
         */
        if (error instanceof HttpErrorResponse) {
            message = ErrorParser.parse(error);
        }

        if (error instanceof JsonApiError) {
            message = error.detail;
        }

        /**
         * Support error responses from our models, which go through the ErrorParser earlier in the chain
         */
        if (error instanceof Error) {
            message = error.message;
        }

        /**
         * clean up the error message
         */

        // remove double periods that sometimes occur
        if (message.endsWith('..')) {
            message = message.slice(0, -1); // remove the last character
        }

        return message;
    }
}
