export const DEFAULT_GENDERS_LIST = [
    {
        tag: 'components.add-genders.genders.woman',
        value: 'woman',
    },
    {
        tag: 'components.add-genders.genders.man',
        value: 'man',
    },
    {
        tag: 'components.add-genders.genders.transgender',
        value: 'transgender',
    },
    {
        tag: 'components.add-genders.genders.cisgender',
        value: 'cisgender',
    },
    {
        tag: 'components.add-genders.genders.nonbinary',
        value: 'nonbinary',
    },
    {
        tag: 'components.add-genders.genders.genderqueer',
        value: 'genderqueer',
    },
    {
        tag: 'components.add-genders.genders.genderfluid',
        value: 'genderfluid',
    },
    {
        tag: 'components.add-genders.genders.transfeminine',
        value: 'transfeminine',
    },
    {
        tag: 'components.add-genders.genders.transmasculine',
        value: 'transmasculine',
    },
    {
        tag: 'components.add-genders.genders.intersex',
        value: 'intersex',
    },
];
