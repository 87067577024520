import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResthooksAPIKey } from '@app/models/resthooks/resthooks-apikey.model';

@Injectable()
export class ResthookAPIService {
    constructor(private http: HttpClient) {}

    async generateApiKey(integrationId: number): Promise<ResthooksAPIKey> {
        const url = '/v2/restHooks/key/' + integrationId.toString();

        const response = await this.http.post(url, null).toPromise();

        return new ResthooksAPIKey(response);
    }
}
