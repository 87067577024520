import { Injectable } from '@angular/core';
import { TaskTemplateSubtask } from '@app/models/tasks/task-template-subtask.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';

@Injectable({
    providedIn: 'root',
})
export class TaskTemplateSubtasksService {
    async onSaveSubTasks(taskTemplate: TaskTemplate, subtasks: TaskTemplateSubtask[]): Promise<void> {
        subtasks = this.removeEmptySubTasks(subtasks);
        subtasks = this.setSubTasksOrderBy(subtasks);

        // create new sub tasks
        const newSubtasks = this.getNewSubTasks(subtasks);
        newSubtasks.forEach(async (newSubtask) => await newSubtask.param('taskTemplate', taskTemplate.id).save());

        // update sub tasks
        const updatedSubtasks = this.getUpdatedSubTasks(subtasks);
        updatedSubtasks.forEach(
            async (updatedSubtask) => await updatedSubtask.param('taskTemplate', taskTemplate.id).save()
        );

        // delete sub tasks
        const deletedSubtasks = this.getDeletedSubTasks(taskTemplate, subtasks);
        deletedSubtasks.forEach(
            async (deletedSubtask) => await deletedSubtask.param('taskTemplate', taskTemplate.id).delete()
        );
    }

    private getNewSubTasks(subtasks: TaskTemplateSubtask[]): TaskTemplateSubtask[] {
        return subtasks.filter((subtask) => !subtask.isPersisted);
    }

    private getUpdatedSubTasks(subtasks: TaskTemplateSubtask[]): TaskTemplateSubtask[] {
        return subtasks.filter((subtask) => subtask.isPersisted && subtask.isDirty());
    }

    private getDeletedSubTasks(
        taskTemplate: TaskTemplate,
        currentSubtasks: TaskTemplateSubtask[]
    ): TaskTemplateSubtask[] {
        const oldSubtasks = taskTemplate.subtasks;

        return oldSubtasks.filter((oldSubtask: TaskTemplateSubtask) => {
            return !currentSubtasks.includes(oldSubtask);
        });
    }

    // set order_by property of subtasks
    private setSubTasksOrderBy(subtasks: TaskTemplateSubtask[]): TaskTemplateSubtask[] {
        subtasks.forEach((subtask, index) => {
            subtask.orderBy = index + 1;
        });

        return subtasks;
    }

    // Remove empty sub tasks
    private removeEmptySubTasks(subtasks: TaskTemplateSubtask[]): TaskTemplateSubtask[] {
        return subtasks.filter((subtask) => subtask.description !== undefined && subtask.description !== null);
    }
}
