import { AuthService } from '@app/services';
import { Paginator, SortDirection } from '@app/classes';
import { TimeOffRequest } from '@models/time-off-v3/time-off-request.model';
import { RequestDateColumn } from './columns/requested-date.columns';
import { DescriptionColumn } from './columns/description.column';
import { StatusColumn } from './columns/status.column';
import { CountAsColumn } from './columns/count-as.column';
import { Table } from '@app/modules/table/classes/table.class';
import { TimeOffTypeColumn } from './columns/time-off-type.column';
import { Model } from '@models/core/base.model';
import { TimeOffType } from '@models/time-off-v3/time-off-type.model';

interface TimeOffRequestTableOptions {
    includeTerminated?: boolean;
}

export class TimeOffRequestTable extends Table {
    sortDirection = SortDirection.DESC;
    links = true;
    protected defaultSortProperty = 'startAt';

    constructor(
        protected auth: AuthService,
        private timeOffTypes: TimeOffType[],
        public employeeId: number,
        private options?: TimeOffRequestTableOptions
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        const requestDateColumn = new RequestDateColumn();

        this.sortColumn = requestDateColumn;
        this.columns = [
            requestDateColumn,
            new TimeOffTypeColumn(this.timeOffTypes),
            new CountAsColumn(),
            new DescriptionColumn(),
            new StatusColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TimeOffRequest.where('employeeId', this.employeeId)
                .with(['timeOffPolicy.timeOffType'])
                .when(this.options?.includeTerminated, (query) => query.where('includeTerminated', true))
        );
    }

    getLink(row: Model): (string | number)[] | null {
        return ['/', 'time-off', 'v3', 'requests', row.id];
    }
}
