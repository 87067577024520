import { HttpErrorResponse } from '@angular/common/http';

export class RequestTimeoutError extends Error {
    httpStatus = 408;

    constructor(res: HttpErrorResponse, message?: string) {
        super(message ? message : res.statusText);
        Object.setPrototypeOf(this, RequestTimeoutError.prototype);
    }
}
