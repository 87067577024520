import { SimpleEntityActions } from '@app/classes';
import {
    HiringAccessLevelLabel,
    HiringAccessLevels,
} from '@app/modules/applicant-tracker/constants/hiring-access-levels.constant';
import { HiringAccessLevelIdsEnum } from '@app/modules/applicant-tracker/enums/hiring-access-levels.enum';
import { ModelMixin } from '../core/base-generic.model';

export class Question extends ModelMixin<Question>() {
    static permission = new SimpleEntityActions('jobPostingQuestion');

    protected static _resource = 'applicantTracker/questions';

    protected static _type = 'jobPostingQuestion';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'jobId',
        'question',
        'isAutoArchive',
        'isRequired',
        'accessLevelId',
        'atsJobPostingQuestionTypeId',
        'fieldType',
    ];

    private _isSelected = false;

    get id(): number {
        return this._attributes['id'];
    }

    set id(val: number) {
        this._attributes['id'] = val;
    }

    get isSelected(): boolean {
        return this._isSelected;
    }

    set isSelected(val: boolean) {
        this._isSelected = val;
    }

    get jobId(): number {
        return this._attributes['jobId'];
    }

    set jobId(val: number) {
        this._attributes['jobId'] = val;
    }

    get question(): string {
        return this._attributes['question'];
    }

    set question(val: string) {
        this._attributes['question'] = val;
    }

    get atsJobPostingQuestionTypeId(): number {
        return this._attributes['atsJobPostingQuestionTypeId'];
    }

    set atsJobPostingQuestionTypeId(val: number) {
        this._attributes['atsJobPostingQuestionTypeId'] = val;
    }

    get isAutoArchive(): boolean {
        return this._attributes['isAutoArchive'];
    }

    set isAutoArchive(val: boolean) {
        this._attributes['isAutoArchive'] = val;
    }

    get isRequired(): boolean {
        return this._attributes['isRequired'];
    }

    set isRequired(val: boolean) {
        this._attributes['isRequired'] = val;
    }

    get accessLevelId(): HiringAccessLevelIdsEnum {
        return this._attributes['accessLevelId'];
    }

    set accessLevelId(val: HiringAccessLevelIdsEnum) {
        this._attributes['accessLevelId'] = val;
    }

    get accessLevelLabel(): HiringAccessLevelLabel {
        return Object.values(HiringAccessLevels).find((x) => x.id === this.accessLevelId)?.label;
    }

    set accessLevelLabel(val: HiringAccessLevelLabel) {
        this._attributes['accessLevelId'] = Object.values(HiringAccessLevels).find((hiringAccessLevel) => {
            return hiringAccessLevel.label === val;
        })?.id;
    }

    get fieldType(): string {
        return this._attributes['fieldType'];
    }

    set fieldType(val: string) {
        this._attributes['fieldType'] = val;
    }

    clone(): Question {
        const attributesToClone = this.getAttributes();
        delete attributesToClone['id'];
        delete attributesToClone['jobId'];

        return new Question(attributesToClone);
    }
}
