import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE } from '@angular/cdk/keycodes';
import { Component, ElementRef, HostBinding, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { TranslatableKey } from '@app/types/translatable.type';
import { Subject, Subscription } from 'rxjs';

const HEX_LENGTH = 7;
const SHORT_HEX_LENGTH = 3;

interface Color {
    hex: string;
    label: string | TranslatableKey;
}

const isHexCode = (value: string): boolean => {
    return /#([a-fA-F0-9]{3}$|[0-9a-fA-F]{6}$)/.test(value);
};

const Colors: Color[] = [
    { hex: '#d32f2f', label: 'components.color-picker.red' },
    { hex: '#c2185b', label: 'components.color-picker.fuchsia' },
    { hex: '#7b1fa2', label: 'components.color-picker.violet' },
    { hex: '#512da8', label: 'components.color-picker.purple' },
    { hex: '#303f9f', label: 'components.color-picker.navy' },
    { hex: '#1976d2', label: 'components.color-picker.blue' },
    { hex: '#0288d1', label: 'components.color-picker.lightBlue' },
    { hex: '#0097a7', label: 'components.color-picker.teal' },
    { hex: '#00796b', label: 'components.color-picker.darkGreen' },
    { hex: '#388e3c', label: 'components.color-picker.green' },
    { hex: '#689f3b', label: 'components.color-picker.lightGreen' },
    { hex: '#afb42b', label: 'components.color-picker.lime' },
    { hex: '#fbc02d', label: 'components.color-picker.yellow' },
    { hex: '#ffa000', label: 'components.color-picker.darkYellow' },
    { hex: '#f57c00', label: 'components.color-picker.orange' },
    { hex: '#e64a19', label: 'components.color-picker.darkOrange' },
    { hex: '#5d4037', label: 'components.color-picker.brown' },
    { hex: '#000000', label: 'components.color-picker.black' },
    { hex: '#b2b5bb', label: 'components.color-picker.gray' },
    { hex: '#f1f1f1', label: 'components.color-picker.lightGray' },
];

@Component({
    selector: 'ui-color-picker',
    templateUrl: './color-picker.template.html',
    styleUrls: ['./color-picker.styles.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: ColorPickerComponent }],
    host: {
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
        '[attr.required]': 'required',
    },
})
export class ColorPickerComponent implements MatFormFieldControl<string>, ControlValueAccessor {
    static nextId = 0;
    id = `colorpicker-${ColorPickerComponent.nextId++}`;
    controlType: string;
    stateChanges = new Subject<void>();
    loading = false;
    hexInputControl: FormControl<string | null> = new FormControl();
    onChange = (_: any) => {};
    onTouched = () => {};
    describedBy = '';
    value: string;
    selectedColor: Color | null;
    colors = Colors;
    isOverlayOpen = false;
    _focused = false;

    get focused(): boolean {
        return this._focused || this.isOverlayOpen;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    get readOnly() {
        return this._readOnly;
    }
    set readOnly(value) {
        this._readOnly = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input() get errorState(): boolean {
        return (
            this.ngControl &&
            this._defaultErrorStateMatcher.isErrorState(this.ngControl.control as FormControl, this._parentForm)
        );
    }

    @Input() get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    get empty() {
        return !this.value;
    }

    protected _placeholder: string;
    protected _required = false;
    protected _readOnly = false;
    protected _disabled = false;
    protected _subscriptions: Subscription[] = [];
    protected _inputElement: HTMLInputElement = null;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        protected elementRef: ElementRef<HTMLElement>,
        protected fm: FocusMonitor
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        const fmMonitorSub = this.fm.monitor(this.elementRef.nativeElement, true).subscribe((origin) => {
            this._focused = !!origin;
            this.stateChanges.next();
        });
        this._subscriptions.push(fmMonitorSub);

        const hexValueSubscription = this.hexInputControl.valueChanges.subscribe((value: string) => {
            if (isHexCode(value)) {
                this.writeValue(value);
            }
        });
        this._subscriptions.push(hexValueSubscription);

        // Need to do this to show mat-errors when form is submitted.
        if (this._parentForm) {
            const fmMonitorSub = this._parentForm.ngSubmit.subscribe(() => {
                this.stateChanges.next();
            });
            this._subscriptions.push(fmMonitorSub);
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._subscriptions.forEach((s) => s.unsubscribe());
    }

    selectColor(color: Color): void {
        this.writeValue(color.hex);
        this.toggleOverlay();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    writeValue(value: string): void {
        this.value = value;
        this.setSelectedColor(value);
        this.onChange(value);
        this.stateChanges.next();
    }

    toggleOverlay(parseColor = false): void {
        if (this.disabled) {
            return;
        }

        if (parseColor) {
            this.parseColor();
        }

        this.isOverlayOpen = !this.isOverlayOpen;
    }

    onOverlayKeydown(keyEvent: KeyboardEvent): void {
        if (keyEvent.keyCode === ESCAPE) {
            this.toggleOverlay(true);
        }
    }

    private parseColor() {
        const userInput = this.hexInputControl.value;

        if (!userInput || isHexCode(userInput)) {
            return;
        }

        const hexValue = userInput.replace('#', '');
        const coercedColor = '#'.padEnd(HEX_LENGTH, hexValue.substr(0, SHORT_HEX_LENGTH));
        this.hexInputControl.setValue(coercedColor);
        this.writeValue(coercedColor);
    }

    onContainerClick(): void {
        if (this.disabled) {
            return;
        }
    }

    private setSelectedColor(hex: string): void {
        if (!hex) {
            this.selectedColor = null;
            return;
        }

        const presetColor = Colors.find((color: Color) => color.hex === hex.toLowerCase());
        this.selectedColor = presetColor ?? { hex, label: hex };
        this.stateChanges.next();
    }
}
