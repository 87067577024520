import { common } from './common';
import { EnvironmentSettings } from './environment-interface';

export const environment: EnvironmentSettings = {
    ...common,
    envModules: [],
    production: true,
    name: 'production',
    url: 'https://hr.humi.ca',
    api: 'https://api.humi.ca',
    payrollUrl: 'https://payroll.humi.ca',
    stripeKey: 'pk_live_EjqvBW5oOmQ4omGyi5Y1KLjH',
    stripeProducts: {
        // ! These values should always match the values provided by the HR application in the [StripePrice Enum](https://github.com/Humi-HR/application/blob/master/app/Modules/SelfServeOnboarding/Enums/StripePrice.php)
        selfServe: {
            hr: 'price_1ONd3fCCh7XUKRwDOqYhUv1f',
            'hr-payroll': 'price_1ONd5HCCh7XUKRwD18T99j9m',
            'hr-timeOff': 'price_1OWPuICCh7XUKRwDEZyxpCuI',
            'hr-payroll-timeOff': 'price_1ONd6YCCh7XUKRwDikpjEWZj',
        },
    },
    sentry: 'https://f160c28cf8b14d3b8fe72424f852e601@sentry.io/302995',
    launchDarkly: '5b4b88c59dc3df2d8d0d047c',
    helloSignClientId: '642152bb7d719bc533c514ca4cdaeb32',
    pusher: {
        id: 493727,
        key: 'dd2bd59058ce90c3c773',
        cluster: 'us2',
    },
    enableBeamer: true,
    jobBoardDomain: 'applytojobs.ca',
    googleMapsApiKey: 'AIzaSyCAp3GjlzoLRngAFXCkELIPLgYwTuykapU',
    simplyBenefitsAdminPanelUrl: 'https://app.simplybenefits.ca/admin/login',
    simplyBenefitsEmployeePortalUrl: 'https://member.simplybenefits.ca/login',
    auth0: {
        domain: 'account.humi.ca',
        clientId: 'OrA18AwEMUBNcXJ5gtaZQXxXUh6MD213',
        authorizationParams: {
            audience: 'https://api.humi.ca',
            redirect_uri: 'https://hr.humi.ca/auth0callback',
        },
    },
    chiliPiper: {
        subdomain: 'humi-ca',
        router: 'implementation_router',
    },
};
