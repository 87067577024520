import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { CalendarEvent } from '@models/common/calendar-event.model';

@Directive({
    selector: '[appCalendarEventColor]',
})
export class CalendarEventColorDirective implements OnInit {
    @Input('appCalendarEventColor') private calendarEvent: CalendarEvent;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        this.setStandardColors();
    }

    @HostListener('mouseenter')
    onMouseEnter(): void {
        if (this.calendarEvent.hasHover) {
            this.setHoverColors();
        }
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        if (this.calendarEvent.hasHover) {
            this.setStandardColors();
        }
    }

    private setHoverColors(): void {
        this.element.nativeElement.style.backgroundColor = this.calendarEvent.color.hoverBackground;
        this.element.nativeElement.children[0].style.color = this.calendarEvent.color.hoverText;
    }

    private setStandardColors(): void {
        this.element.nativeElement.style.backgroundColor = this.calendarEvent.color.background;
        this.element.nativeElement.children[0].style.color = this.calendarEvent.color.text;
    }
}
