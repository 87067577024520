<div class="error-navigator" [ngClass]="{ 'has-errors': errorSelectors.length }">
    <div class="status" *ngIf="hasErrorsWithSelection">
        <mat-icon svgIcon="warning"></mat-icon>
        {{
            'import.errorNavigator.errorIndexPosition'
                | translate: { index: activeErrorIndex + 1, total: errorSelectors.length }
        }}
    </div>
    <div class="status" *ngIf="hasNoErrors">
        <mat-icon svgIcon="success"></mat-icon>
        {{ 'import.errorNavigator.noErrors' | translate }}
    </div>
    <div class="status" *ngIf="hasNoActiveSelection">
        <mat-icon svgIcon="success"></mat-icon>
        {{ 'import.errorNavigator.errorCount' | translate: { total: errorSelectors.length } }}
    </div>
    <div class="status" *ngIf="isLoading">
        <svg
            class="loading-icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99996 2.66667C8.18403 2.66667 8.33329 2.51743 8.33329 2.33333C8.33329 2.14924 8.18403 2 7.99996 2C7.81589 2 7.66663 2.14924 7.66663 2.33333C7.66663 2.51743 7.81589 2.66667 7.99996 2.66667Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.99996 14C8.18403 14 8.33329 13.8507 8.33329 13.6667C8.33329 13.4826 8.18403 13.3333 7.99996 13.3333C7.81589 13.3333 7.66663 13.4826 7.66663 13.6667C7.66663 13.8507 7.81589 14 7.99996 14Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.16671 3.42668C5.3508 3.42668 5.50004 3.27744 5.50004 3.09334C5.50004 2.90925 5.3508 2.76001 5.16671 2.76001C4.98261 2.76001 4.83337 2.90925 4.83337 3.09334C4.83337 3.27744 4.98261 3.42668 5.16671 3.42668Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.8333 13.24C11.0174 13.24 11.1667 13.0908 11.1667 12.9067C11.1667 12.7226 11.0174 12.5733 10.8333 12.5733C10.6493 12.5733 10.5 12.7226 10.5 12.9067C10.5 13.0908 10.6493 13.24 10.8333 13.24Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.09334 5.49999C3.27744 5.49999 3.42668 5.35075 3.42668 5.16666C3.42668 4.98257 3.27744 4.83333 3.09334 4.83333C2.90925 4.83333 2.76001 4.98257 2.76001 5.16666C2.76001 5.35075 2.90925 5.49999 3.09334 5.49999Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.33333 8.33334C2.51743 8.33334 2.66667 8.18407 2.66667 8.00001C2.66667 7.81594 2.51743 7.66667 2.33333 7.66667C2.14924 7.66667 2 7.81594 2 8.00001C2 8.18407 2.14924 8.33334 2.33333 8.33334Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.09334 11.1667C3.27744 11.1667 3.42668 11.0174 3.42668 10.8333C3.42668 10.6493 3.27744 10.5 3.09334 10.5C2.90925 10.5 2.76001 10.6493 2.76001 10.8333C2.76001 11.0174 2.90925 11.1667 3.09334 11.1667Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.9067 5.49999C13.0908 5.49999 13.24 5.35075 13.24 5.16666C13.24 4.98257 13.0908 4.83333 12.9067 4.83333C12.7226 4.83333 12.5734 4.98257 12.5734 5.16666C12.5734 5.35075 12.7226 5.49999 12.9067 5.49999Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.16671 13.24C5.3508 13.24 5.50004 13.0908 5.50004 12.9067C5.50004 12.7226 5.3508 12.5733 5.16671 12.5733C4.98261 12.5733 4.83337 12.7226 4.83337 12.9067C4.83337 13.0908 4.98261 13.24 5.16671 13.24Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.8333 3.42668C11.0174 3.42668 11.1667 3.27744 11.1667 3.09334C11.1667 2.90925 11.0174 2.76001 10.8333 2.76001C10.6493 2.76001 10.5 2.90925 10.5 3.09334C10.5 3.27744 10.6493 3.42668 10.8333 3.42668Z"
                stroke="#167D8A"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        {{ 'import.errorNavigator.loading' | translate }}
    </div>
    <div class="buttons">
        <button type="button" (click)="previousError()" [disabled]="errorSelectors.length === 0">
            <mat-icon svgIcon="chevronLeft"></mat-icon>
        </button>
        <button type="button" (click)="nextError()" [disabled]="errorSelectors.length === 0">
            <mat-icon svgIcon="chevronRight"></mat-icon>
        </button>
    </div>
</div>
