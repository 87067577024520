import { SettingsIntegrationBase } from '@app/models/company/settings/integrations/integration-base.model';
import { SettingsIntegration } from '@app/models/company/settings/settings-integration.model';
import { IndeedIntegrationStatus } from '@applicant-tracker/enums/indeed-integration-status.enum';

export class IntegrationIndeed extends SettingsIntegrationBase {
    protected static _type = 'indeeds';

    protected static _resource = 'companyManagement/companies/:company/integrations/indeed';

    protected static _serializeAttributes = [
        'email',
        'phone',
        'employerNumber',
        'syncJobs',
        'isEnabled',
        'status',
        'isQuickApplyEnabled',
    ];

    get email(): string {
        return this._attributes['email'];
    }
    set email(val: string) {
        this._attributes['email'] = val;
    }

    get phone(): string {
        return this._attributes['phone'];
    }
    set phone(val: string) {
        this._attributes['phone'] = val;
    }

    get employerNumber(): string {
        return this._attributes['employerNumber'];
    }

    set employerNumber(val: string) {
        this._attributes['employerNumber'] = val;
    }

    get syncJobs(): boolean {
        return this._attributes['syncJobs'];
    }

    set syncJobs(val: boolean) {
        this._attributes['syncJobs'] = val;
    }

    get isEnabled(): boolean {
        return this._attributes['isEnabled'];
    }

    set isEnabled(val: boolean) {
        this._attributes['isEnabled'] = val;
    }

    get integration(): SettingsIntegration {
        return this.hasOne(SettingsIntegration, 'integration');
    }

    get status(): IndeedIntegrationStatus {
        return this._attributes['status'];
    }

    set status(val: IndeedIntegrationStatus) {
        this._attributes['status'] = val;
    }

    get isQuickApplyEnabled(): boolean {
        return this._attributes['isQuickApplyEnabled'];
    }

    set isQuickApplyEnabled(val: boolean) {
        this._attributes['isQuickApplyEnabled'] = val;
    }
}
