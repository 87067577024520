import { DataField } from '@app/models/settings/data-field.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TranslatableObject } from '@app/types/translatable.type';

export class CompletionRateColumn extends Column {
    title = 'settings.custom-fields.field-index-table.completeRate';
    sortable = false;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(dataField: DataField): TranslatableObject {
        return {
            key: 'settings.custom-fields.field-index-table.completeRateValue',
            params: {
                employeesWithData: dataField.employeesWithDataValuesCount,
                assignedEmployeesCount: dataField.assignedEmployeesCount,
            },
        };
    }
}
