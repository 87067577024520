import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { DataField } from '@models/settings/data-field.model';
import {
    CompletionRateColumn,
    DescriptionColumn,
    GroupColumn,
    NameColumn,
} from '@settings/services/tables/fields-table/columns';

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

const assignOption: MenuColumnItem = {
    label: 'assign',
    event: 'assign',
};

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

@Injectable()
export class FieldsIndexTable extends Table {
    links = true;
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getLink(dataField: DataField): (string | number)[] {
        return ['/settings', 'custom-fields', 'fields', dataField.id];
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [editOption, assignOption, deleteOption];
    }

    setColumns(): void {
        this.addColumns(new NameColumn(), new DescriptionColumn(), new GroupColumn(), new CompletionRateColumn());
    }

    setDataSource(): void {
        this.paginator = new Paginator(DataField.param('company', this.auth.company.id).with(['dataFieldGroup']));
    }
}
