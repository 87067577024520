import { TextMask } from '@app/interfaces';
import { Locale } from '@app/types/translatable.type';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const SIN_DIGITS = 9;
const BANK_INSTITUTION_DIGITS = 3;
const BANK_TRANSIT_DIGITS = 5;
const BANK_ACCOUNT_DIGITS = 12;

const digit = /\d/;
const placeholderChar = '\u2000';

const postalCodeMask: Array<RegExp | string> = [/[A-Z]/i, digit, /[A-Z]/i, ' ', digit, /[A-Z]/i, digit];
const phoneNumberMask: Array<RegExp | string> = [
    '(',
    /[1-9]/,
    digit,
    digit,
    ')',
    ' ',
    digit,
    digit,
    digit,
    '-',
    digit,
    digit,
    digit,
    digit,
];

const upperCasePipe = (conformedValue: string): string => conformedValue.toUpperCase();

export const makePhoneNumberMask = (): TextMask => ({
    mask: phoneNumberMask,
    placeholderChar,
    guide: true,
});

export const makePostalCodeMask = (): TextMask => ({
    mask: postalCodeMask,
    placeholderChar,
    guide: true,
    pipe: upperCasePipe,
});

export const makePercentageMask = (): TextMask =>
    createNumberMask({
        prefix: '',
        suffix: '%',
        includeThousandsSeparator: true,
        allowDecimal: true,
        guide: true,
        keepCharPositions: false,
        integerLimit: 2,
    });

export const makeTwoDecimalPointsNumberMask = (): TextMask =>
    createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: 2,
    });

export const makeCurrencyMask = (
    {
        keepCharPositions,
        allowDecimal,
    }: Partial<{
        keepCharPositions: boolean;
        allowDecimal: boolean;
    }> = {},
    locale: Locale = 'en_CA'
): TextMask =>
    createNumberMask({
        prefix: locale === 'fr_CA' ? '' : '$',
        suffix: locale === 'fr_CA' ? '$' : '',
        thousandsSeparatorSymbol: locale === 'fr_CA' ? ' ' : ',',
        decimalSymbol: locale === 'fr_CA' ? ',' : '.',
        includeThousandsSeparator: true,
        allowDecimal: allowDecimal ?? true,
        guide: true,
        keepCharPositions: keepCharPositions ?? false,
    });

export const sinNumberMask: TextMask = {
    mask: Array(SIN_DIGITS).fill(digit),
    placeholderChar,
    guide: false,
};

export const bankInstitutionMask: TextMask = {
    mask: Array(BANK_INSTITUTION_DIGITS).fill(digit),
    placeholderChar,
    guide: false,
};

export const bankTransitMask: TextMask = {
    mask: Array(BANK_TRANSIT_DIGITS).fill(digit),
    placeholderChar,
    guide: false,
};

export const bankAccountMask: TextMask = {
    mask: Array(BANK_ACCOUNT_DIGITS).fill(digit),
    placeholderChar,
    guide: false,
};
