import { Injectable, Injector } from '@angular/core';
import {
    applyUserTheme,
    getEvaluatedTheme,
    getThemePreference,
    HUMI_THEME_KEY,
    ThemePreference,
} from '@app/scripts/theme/theme';
import { capitalize } from 'lodash-es';
import { AnalyticService } from './analytic/analytic.service';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    /**
     * Used to cache the themePreference in memory so that we are not repeatedly accessing LocalStorage
     */
    private _themePreference: ThemePreference = 'LIGHT';

    constructor(private injector: Injector) {
        this.fetchThemePreference();
    }

    get themePreference(): ThemePreference {
        return this._themePreference;
    }

    getEvaluatedTheme(): Omit<ThemePreference, 'MATCH_BROWSER'> {
        return getEvaluatedTheme();
    }

    setThemePreference(themePreference: ThemePreference): void {
        localStorage.setItem(HUMI_THEME_KEY, themePreference);
        const updatedTheme = applyUserTheme();
        // Updates the User Segment in Pendo to reflect theme preference
        this.injector
            .get(AnalyticService)
            .updateUser({ visitor: { humidarklightmodepreference: capitalize(updatedTheme.toString()) } });
        this.fetchThemePreference();
    }

    /**
     * Caches current theme preference from LocalStorage into provide variables
     */
    private fetchThemePreference(): void {
        this._themePreference = getThemePreference();
    }
}
