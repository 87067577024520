import { SimpleEntityActions } from '@app/classes';
import { Platform } from '@app/classes/platform.class';
import { FeatureFlag } from '@app/enums';
import { BenefitPlan } from '@app/models/benefits/benefit-plan.model';
import { CompanyDocument } from '@app/models/documents/company-document.model';
import { CustomReport } from '@app/models/reporting/custom-report.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { Task } from '@app/models/tasks/task.model';
import { Account } from '@models/account/account.model';
import { Role } from '@models/account/role.model';
import { Asset } from '@models/company/asset.model';
import { Company } from '@models/company/company.model';
import { Department } from '@models/company/department.model';
import { Job } from '@models/company/job.model';
import { Office } from '@models/company/office.model';
import { Employee } from '@models/employee/employee.model';
import { Reminder } from '@models/employee/reminder.model';
import { Feedback } from '@models/performance/feedback.model';
import { Goal } from '@models/performance/goal.model';
import { Review } from '@models/performance/review.model';
import { Survey } from '@models/performance/survey.model';
import { Template } from '@models/performance/template.model';
import { CompanySetting } from '@models/settings/company-setting.model';
import { DataFieldGroup } from '@models/settings/data-field-group.model';
import { DataField } from '@models/settings/data-field.model';
import { TrainingProgram } from '@models/training/training-program.model';
import { Module } from '@settings/interfaces/module-permissions.interface';

export const ManageCustomFields = {
    label: 'Manage Custom Fields',
    toggled: false,
    showRoleModal: false,
    permissions: [
        'search.companyDataField',
        'view.companyDataField',
        'create.companyDataField',
        'update.companyDataField',
        'delete.companyDataField',
        'search.companyDataFieldGroup',
        'view.companyDataFieldGroup',
        'create.companyDataFieldGroup',
        'update.companyDataFieldGroup',
        'delete.companyDataFieldGroup',
    ],
};

export const HireEmployee = {
    label: 'settings.moduleFeaturePermissions.hireNewEmployees',
    toggled: false,
    showRoleModal: true,
    permissions: [
        ...Account.permission.all,
        'invite.account',
        ...Employee.permission.all,
        'import.employee',
        'create.employeeSalary',
        ...Office.permission.all,
        ...Department.permission.all,
        ...Job.permission.all,
        'assign.accountRole',
        'search.accountRole',
        ...Role.permission.view,

        // Assigning on boarding stuff
        ...CompanyDocument.permission.all,
        ...Reminder.permission.some('update', 'search'),
        'search.companyDataField',
        'update.companyDataField',
        'create.benefitPlans',
    ],
};

export const ManageRolesAndPermissions = {
    label: 'settings.moduleFeaturePermissions.manageRolesAndPermissions',
    toggled: false,
    permissions: [
        ...Role.permission.all,
        ...Account.permission.view,
        ...Employee.permission.view,
        'assign.accountRole',
        'search.accountRole',
        ...Account.permission.some('update'),
    ],
};

export const ModuleFeaturePermissions: Module[] = [
    {
        title: 'settings.moduleFeaturePermissions.navigation',
        features: [
            {
                label: 'settings.moduleFeaturePermissions.administratorView',
                toggled: false,
                permissions: ['administrate'],
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.company',
        features: [
            {
                label: 'settings.moduleFeaturePermissions.manageCompanyDetails',
                toggled: false,
                permissions: [
                    ...Company.permission.some('view', 'update'),
                    'update.companyDetail',
                    'view.companyDetail',
                ],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageDepartments',
                toggled: false,
                permissions: [...Department.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.managePositions',
                toggled: false,
                permissions: [...Job.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageOffices',
                toggled: false,
                permissions: [...Office.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageDocuments',
                toggled: false,
                permissions: [...CompanyDocument.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageAssets',
                toggled: false,
                permissions: [Asset.managePermission],
            },
            {
                label: 'settings.moduleFeaturePermissions.sendAnnouncements',
                toggled: false,
                permissions: ['create.companyNotificationAnnouncement'],
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.people',
        toggled: true,
        permissions: [...Platform.people.permission.view],
        features: [
            // Defined above
            HireEmployee,
            {
                label: 'settings.moduleFeaturePermissions.placeEmployeesOnLeaveOfAbsence',
                toggled: false,
                permissions: ['manageLeaveOfAbsence'],
            },
            {
                label: 'settings.moduleFeaturePermissions.viewOrganizationalChart',
                toggled: true,
                permissions: [...Platform.orgChart.permission.view],
            },
            {
                label: 'settings.moduleFeaturePermissions.viewDirectory',
                toggled: true,
                permissions: [...Platform.people.directory.permission.view],
            },
            {
                label: 'settings.moduleFeaturePermissions.viewMyTeam',
                toggled: true,
                permissions: [...Platform.people.myTeam.permission.view],
            },
            {
                label: 'settings.moduleFeaturePermissions.viewDepartments',
                toggled: true,
                permissions: [...Platform.people.departments.permission.view],
            },
            {
                label: 'settings.moduleFeaturePermissions.viewBirthdays',
                toggled: true,
                permissions: [...Employee.birthdayPermission.some('view')],
            },
            {
                label: 'settings.moduleFeaturePermissions.viewWorkplaceAnniversaries',
                toggled: true,
                permissions: [Employee.eventFeedAnniversariesPermission],
            },
            {
                label: 'settings.moduleFeaturePermissions.terminateEmployees',
                toggled: false,
                permissions: [...Employee.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.viewEmployeeDocuments',
                toggled: true,
                permissions: [...Platform.people.employeeDocuments.permission.view],
            },
            {
                label: 'settings.moduleFeaturePermissions.createAndApplyTasks',
                toggled: true,
                disableDependentOnToggleOff: 'settings.moduleFeaturePermissions.createAndEditTaskTemplates',
                permissions: [Task.managePermission],
            },
            {
                label: 'settings.moduleFeaturePermissions.createAndEditTaskTemplates',
                toggled: false,
                permissions: [TaskTemplate.managePermission],
                tooltipDescription: 'settings.moduleFeaturePermissions.createAndEditTaskTemplatesTooltip',
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.payroll',
        toggled: false,
        permissions: ['accessPayroll'],
        features: [],
        hideIfHasFeatureFlag: FeatureFlag.KYC_KYB,
    },
    {
        title: 'settings.moduleFeaturePermissions.payroll',
        toggled: false,
        permissions: ['accessPayroll'],
        features: [],
        warning: 'payroll.kyc-kyb.roles-and-permissions.update-role',
        showIfHasFeatureFlag: FeatureFlag.KYC_KYB,
    },
    {
        title: 'settings.moduleFeaturePermissions.timeOff',
        toggled: false,
        permissions: ['accessTimeOff'],
        cascadeDisableTab: 'time-off',
        features: [
            {
                label: 'settings.moduleFeaturePermissions.employeeCalendar',
                toggled: false,
                permissions: [...Platform.timeOff.permission.calendar],
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.timeTracking',
        toggled: false,
        permissions: ['accessTimeTracking'],
        dependency: [
            'settings.moduleFeaturePermissions.navigation',
            'settings.moduleFeaturePermissions.administratorView',
        ],
        showIfModuleEnabled: Platform.modules.timeTracking,
        features: [],
    },
    {
        title: 'settings.moduleFeaturePermissions.performance',
        toggled: false,
        permissions: ['accessPerformance'],
        features: [
            {
                label: 'settings.moduleFeaturePermissions.manageGoals',
                toggled: false,
                permissions: [...Goal.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageReviews',
                toggled: false,
                permissions: [...Review.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageFeedback',
                toggled: false,
                permissions: [...Feedback.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageTemplates',
                toggled: false,
                permissions: [...Template.performancePermission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageSurveys',
                toggled: false,
                permissions: [...Survey.permission.all],
                hideIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.survey',
        toggled: false,
        permissions: ['accessSurvey'],
        showIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
        features: [
            {
                label: 'settings.moduleFeaturePermissions.manageSurveys',
                toggled: false,
                permissions: [...Survey.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageTemplates',
                toggled: false,
                permissions: [...Template.surveyPermission.all],
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.recruiting',
        toggled: false,
        permissions: [Platform.recruiting.permission.access],
        features: [
            {
                label: 'settings.moduleFeaturePermissions.recruiterAccess',
                toggled: false,
                permissions: [Platform.recruiting.permission.recruiter],
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.benefits',
        toggled: false,
        permissions: ['accessBenefits'],
        features: [
            {
                label: 'settings.moduleFeaturePermissions.manageBenefitsPlans',
                toggled: false,
                permissions: [...BenefitPlan.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageChangeRequests',
                toggled: false,
                permissions: ['manageChangeRequest'],
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.businessInsurance',
        toggled: false,
        permissions: [Platform.businessInsurance.permission],
        showIfModuleEnabled: Platform.modules.businessInsurance,
        features: [
            {
                label: 'settings.moduleFeaturePermissions.manageBusinessInsurance',
                toggled: false,
                permissions: [Platform.businessInsurance.permission],
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.training',
        toggled: false,
        permissions: [TrainingProgram.accessTrainingModulePermission],
        features: [
            {
                label: 'settings.moduleFeaturePermissions.manageTraining',
                toggled: false,
                permissions: [TrainingProgram.accessTrainingModulePermission, TrainingProgram.managePermission],
            },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.reporting',
        toggled: false,
        permissions: ['accessReporting'],
        dependency: [
            'settings.moduleFeaturePermissions.navigation',
            'settings.moduleFeaturePermissions.administratorView',
        ],
        features: [
            {
                label: 'settings.moduleFeaturePermissions.exportEmployeeData',
                toggled: false,
                permissions: ['export.employee', 'create.file'],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageCustomReports',
                toggled: false,
                permissions: [CustomReport.accessPermission],
            },

            // These haven't been implemented yet, but will be later
            // {
            //     label: 'View Standard Reports',
            //     toggled: false,
            //     permissions: [
            //         /** @todo add required permissions */
            //     ],
            // },
            // {
            //     label: 'View Payroll Reports',
            //     toggled: false,
            //     permissions: [
            //         /** @todo add required permissions */
            //     ],
            // },
            // {
            //     label: 'View Benefits Reports',
            //     toggled: false,
            //     permissions: [
            //         /** @todo add required permissions */
            //     ],
            // },
        ],
    },
    {
        title: 'settings.moduleFeaturePermissions.settings',
        toggled: false,
        permissions: ['accessSettings', ...CompanySetting.permission.some('search', 'update')],
        features: [
            ManageRolesAndPermissions,
            {
                label: 'settings.moduleFeaturePermissions.manageFields',
                toggled: false,
                permissions: [...DataField.permission.all, ...DataFieldGroup.permission.all],
            },
            {
                label: 'settings.moduleFeaturePermissions.manageBilling',
                toggled: false,
                permissions: [
                    ...new SimpleEntityActions('companySubscription').all,
                    ...new SimpleEntityActions('companySubscriptionCard').some('create'),
                ],
            },
            // {
            //    label: 'Manage Integrations',
            //    toggled: false,
            //    permissions: [
            //        /** @todo add required permissions */
            //    ],
            // },
        ],
    },
];
