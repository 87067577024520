import { EmployeeSelectionCriteriaFields } from '@app/components/employee-criteria-selection/employee-criteria-selection.component';
import { Department } from '../company/department.model';
import { Job } from '../company/job.model';
import { Office } from '../company/office.model';
import { Model } from '../core/base.model';
import { Employee } from './employee.model';
import { EmploymentType } from './employment-type.model';
import { FilterCriteria } from './filter-criteria.model';

type CriteriableType = 'Department' | 'Job' | 'EmploymentType' | 'Address';

export class EmployeeFilter extends Model {
    protected static _resource = 'companyManagement/companies/:company/employeeFilters';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['name', 'attach', 'detach'];

    get name(): string | null {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get filterCriteria(): FilterCriteria[] {
        return this.hasMany(FilterCriteria, 'filterCriteria');
    }

    set filterCriteria(val: FilterCriteria[]) {
        this._relationships['filterCriteria'] = val;
    }

    get employees(): Employee[] {
        return this.hasMany(Employee, 'employees');
    }

    getDepartmentCriteria(): Department[] {
        return this.getCriteriaByType('Department');
    }

    getJobCriteria(): Job[] {
        return this.getCriteriaByType('Job');
    }

    getOfficeCriteria(): Office[] {
        return this.getCriteriaByType('Address');
    }

    getEmploymentTypeCriteria(): EmploymentType[] {
        return this.getCriteriaByType('EmploymentType');
    }

    getCriteriaByType(criteriableType: 'Job'): Job[];
    getCriteriaByType(criteriableType: 'Address'): Office[];
    getCriteriaByType(criteriableType: 'EmploymentType'): EmploymentType[];
    getCriteriaByType(criteriableType: 'Department'): Department[];
    getCriteriaByType(criteriableType: CriteriableType) {
        return this.filterCriteria
            .filter((criteria) => {
                return criteria.criteriableType === criteriableType;
            })
            .map((filter) => filter.criteriable);
    }

    attachEmployeeFilters(employeeFilter: EmployeeFilter, toAttach: EmployeeSelectionCriteriaFields): void {
        employeeFilter
            .attach(
                'departments',
                toAttach.departments.map((department) => department.id)
            )
            .attach(
                'offices',
                toAttach.offices.map((office) => office.id)
            )
            .attach(
                'employmentTypes',
                toAttach.employmentTypes.map((employmentType) => employmentType.id)
            )
            .attach(
                'jobs',
                toAttach.jobs.map((job) => job.id)
            );
    }

    detachEmployeeFilters(employeeFilter: EmployeeFilter, toDetach: EmployeeSelectionCriteriaFields): void {
        employeeFilter
            .detach(
                'departments',
                toDetach.departments.map((department) => department.id)
            )
            .detach(
                'offices',
                toDetach.offices.map((office) => office.id)
            )
            .detach(
                'employmentTypes',
                toDetach.employmentTypes.map((employmentType) => employmentType.id)
            )
            .detach(
                'jobs',
                toDetach.jobs.map((job) => job.id)
            );
    }
}
