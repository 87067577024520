import { TranslatableKey } from '@app/types/translatable.type';
import { DataFieldGroup } from '@models/settings/data-field-group.model';
import { Column } from '@table/classes/column.class';
import { CellDisplay } from '@table/enums/cell-display.enum';

export class DefaultGroupTypeColumn extends Column {
    title = 'settings.custom-fields.field-group-index-table.name';
    property = 'name';
    sortable = false;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(dataFieldGroup: DataFieldGroup): string | TranslatableKey {
        return dataFieldGroup.translatableName;
    }
}
