import { Model } from '@models/core/base.model';

/**
 * EmployeeBenefitDeductionItem represents the DEDUCTION (Employee Premium)
 * of an Employee's benefit.
 *
 * Ex: Sarah is given the Company Car benefit. She must pay for the DEDUCTION on this Employee Benefit.
 */
export class EmployeeBenefitDeductionItem extends Model {
    protected static _resource = 'payroll/employees/:employee/employeeBenefits/:employeeBenefit/deductionItems';
    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'name',
        'displayName',
        'deductableId',
        'deductableType',
        'amount',
        'pretax',
        'debitable',
        'statutory',
        'paystubId',
    ];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get displayName(): string {
        return this._attributes['displayName'];
    }

    set displayName(val: string) {
        this._attributes['displayName'] = val;
    }

    get paystubId(): number {
        return this._attributes['paystubId'];
    }

    set paystubId(val: number) {
        this._attributes['paystubId'] = val;
    }

    get deductableId(): number {
        return this._attributes['deductableId'];
    }

    set deductableId(val: number) {
        this._attributes['deductableId'] = val;
    }

    get deductableType(): string {
        return this._attributes['deductableType'];
    }

    set deductableType(val: string) {
        this._attributes['deductableType'] = val;
    }

    get amount(): number {
        return this._attributes['amount'] ?? 0;
    }

    set amount(val: number) {
        this._attributes['amount'] = val;
    }

    get statutory(): boolean {
        return this._attributes['statutory'];
    }

    set statutory(val: boolean) {
        this._attributes['statutory'] = val;
    }

    get pretax(): boolean {
        return this._attributes['pretax'];
    }

    set pretax(val: boolean) {
        this._attributes['pretax'] = val;
    }

    get debitable(): boolean {
        return this._attributes['debitable'];
    }

    set debitable(val: boolean) {
        this._attributes['debitable'] = val;
    }
}
