import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Chippable } from '@app/interfaces/chippable.interface';
import { Translatable } from '@app/types/translatable.type';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'ui-chips-autocomplete',
    templateUrl: './chips-autocomplete.template.html',
    styleUrls: ['./chips-autocomplete.style.scss'],
})
export class ChipsAutoCompleteComponent {
    @ViewChild('textInput') textInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

    @Output() changes: EventEmitter<Chippable[]> = new EventEmitter<Chippable[]>();

    @Input() label: Translatable = 'components.chips-autocomplete.label';
    @Input() allowHtmlLabel = false;
    @Input() placeholder: Translatable = 'components.chips-autocomplete.newItem';
    @Input() autocompleteItems: Chippable[] = [];
    @Input() selectedItems: Chippable[] = [];
    @Input() allowAdditions = false;

    textControl: FormControl<string | null> = new FormControl();
    filteredItems: Observable<Chippable[]>;
    currentInputHasMatch = false;

    private _readOnly = false;
    private _disabled = false;

    constructor() {
        this.filteredItems = this.textControl.valueChanges.pipe(
            startWith(null),
            map((name: string | null) =>
                (name ? this.filter(name) : [...this.autocompleteItems])
                    .filter((item) => this.selectedItems.indexOf(item) < 0)
                    .sort(this.sort)
            )
        );

        this.textControl.valueChanges.subscribe((input) => {
            if (!input) {
                this.currentInputHasMatch = false;

                return;
            }

            this.currentInputHasMatch = this.doesInputMatchAnySelectedOrAutocompleteItems(input);
        });
    }

    @Input() set disabled(val: boolean) {
        const disabled = coerceBooleanProperty(val);
        this._disabled = disabled;

        if (disabled) {
            this.textControl.disable();
            return;
        }

        this.textControl.enable();
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input() set readOnly(val: boolean) {
        this._readOnly = coerceBooleanProperty(val);
    }

    get readOnly(): boolean {
        return this._readOnly;
    }

    onFocus(): void {
        if (this._disabled) {
            return;
        }

        this.trigger._onChange('');
    }

    getSelected(): Chippable[] {
        return this.selectedItems;
    }

    add(event: MatChipInputEvent): void {
        if (!this.allowAdditions) {
            return;
        }

        const chipLabel = event.value;
        const isValid = (chipLabel || '').trim();

        if (isValid) {
            this.addChippable({ chipLabel });
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const { value } = event.option;
        this.addChippable({ chipLabel: value });
    }

    remove(item: Chippable): void {
        this.removeChippable(item);
        this.refreshAutocomplete();
    }

    onClear(event: Event): void {
        // Stop event propagation to prevent autocomplete from opening
        event.preventDefault();
        event.stopPropagation();
        this.clearAll();
    }

    clearAll(): void {
        this.selectedItems = [];
        this.onChanges();
        this.refreshAutocomplete();
    }

    clearInput(): void {
        this.textInput.nativeElement.value = '';
    }

    private filter(chipLabel: string): Chippable[] {
        const filterValue = chipLabel.toLowerCase();

        return this.autocompleteItems.filter(
            (item: Chippable) => item.chipLabel.toLowerCase().indexOf(filterValue) > -1
        );
    }

    private sort(a: Chippable, b: Chippable): number {
        return a.chipLabel.localeCompare(b.chipLabel);
    }

    private findAutocompleteItemByLabel(chipLabel: string): Chippable {
        return this.autocompleteItems.find(
            (item: Chippable) => item.chipLabel.toLocaleLowerCase() === chipLabel.toLocaleLowerCase()
        );
    }

    private refreshAutocomplete(): void {
        this.clearInput();
        this.textControl.setValue('');
    }

    private addChippable(chippable: Chippable): void {
        const alreadySelected = this.selectedItems.some(
            (item) => item.chipLabel.toLocaleLowerCase() === chippable.chipLabel.toLocaleLowerCase()
        );

        if (alreadySelected) {
            this.clearInput();
            return;
        }

        const existingOption = this.findAutocompleteItemByLabel(chippable.chipLabel);
        this.selectedItems.push(existingOption || chippable);
        this.refreshAutocomplete();
        this.onChanges();
    }

    private removeChippable(chippable: Chippable): void {
        const index = this.selectedItems.indexOf(chippable);

        if (index >= 0) {
            this.selectedItems.splice(index, 1);
        }
        this.onChanges();
    }

    private onChanges(): void {
        this.changes.emit(this.selectedItems);
    }

    private doesInputMatchAnySelectedOrAutocompleteItems(input: string): boolean {
        return (
            this.autocompleteItems.some((item) => item.chipLabel.toLocaleLowerCase() === input.toLocaleLowerCase()) ||
            this.selectedItems.some((item) => item.chipLabel.toLocaleLowerCase() === input.toLocaleLowerCase())
        );
    }
}
