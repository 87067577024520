import { Component, Input } from '@angular/core';
import { Translatable } from '@app/types/translatable.type';

@Component({
    selector: 'app-empty-state',
    templateUrl: './empty-state.template.html',
    styleUrls: ['./empty-state.style.scss'],
})
export class EmptyStateComponent {
    @Input() src: string;
    @Input() label: Translatable = 'components.empty-state.noDataAvailable';
}
