import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntegrationType } from '@app/models/common/integration-type.model';
import { ATSJobIntegration } from '@app/models/company/ats-job-integration.model';
import { SettingsIntegrationBase } from '@app/models/company/settings/integrations/integration-base.model';
import { SettingsIntegration } from '@app/models/company/settings/settings-integration.model';
import { JobPosting } from '@app/models/recruiting/job-posting.model';
import { AuthService } from '@app/services';
import { JobIndeedIntegration } from '@models/company/job-indeed-integration.model';
import { Subject } from 'rxjs';
import { ActiveIntegrations } from '../enums/integration.enum';
import { SettingsResources } from '../settings.resource';

@Injectable({ providedIn: 'root' })
export class SettingsIntegrationService {
    jobPostingStateChange$ = new Subject<'active' | 'archived' | 'paused'>();
    jobSyncedWithIndeed$ = new Subject<JobIndeedIntegration>();

    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    async getIntegrations(): Promise<SettingsIntegration[]> {
        const [integrations] = await SettingsIntegration.param('company', this.auth.company.id)
            .with('integrationType')
            .get();
        return integrations;
    }

    async updateIntegration(integration: SettingsIntegration): Promise<void> {
        await integration.param('company', this.auth.company.id).save();
    }

    async onDeleteIntegration(integration: SettingsIntegration): Promise<void> {
        await integration.param('company', this.auth.company.id).delete();
    }

    async onManage(integrationDetail: SettingsIntegrationBase): Promise<SettingsIntegrationBase> {
        return await integrationDetail.param('company', this.auth.company.id).save();
    }

    async getIntegrationTypes(): Promise<IntegrationType[]> {
        let [integrationTypes] = await IntegrationType.get();

        integrationTypes = integrationTypes.filter((integrationType) =>
            Object.values(ActiveIntegrations).includes(integrationType.name as ActiveIntegrations)
        );

        return integrationTypes;
    }

    async getCompanyActiveIntegrations(): Promise<SettingsIntegration[]> {
        const [integrations] = await SettingsIntegration.param('company', this.auth.company.id)
            .where('jobBoardRelated', true)
            .where('isEnabled', true)
            .orderBy('integrationType.name', 'asc')
            .get();

        return integrations;
    }

    /**
     * Retrieves all top level integrations records that are related to the job board.
     */
    async getCompanyJobBoardRelatedIntegrations(): Promise<SettingsIntegration[]> {
        const [integrations] = await SettingsIntegration.param('company', this.auth.company.id)
            .where('jobBoardRelated', true)
            .orderBy('integrationType.name', 'asc')
            .get();

        return integrations;
    }

    async getJobIntegrations(atsJobId: number): Promise<ATSJobIntegration[]> {
        const [jobIntegrations] = await ATSJobIntegration.where('atsJobId', atsJobId).with('jobPosting').get();
        return jobIntegrations;
    }

    async updateJobIntegration(jobIntegration: ATSJobIntegration): Promise<void> {
        await jobIntegration.save();
    }

    async pauseJobIntegration(atsJobId: number, shouldPause: boolean): Promise<JobPosting> {
        const url = SettingsResources.JobIntegrationPause.replace(':atsJobId', atsJobId.toString());
        const request = {
            data: {
                type: 'jobPostings',
                attributes: {
                    hasPaused: shouldPause,
                },
            },
        };

        await this.http.put(url, request).toPromise();

        this.jobPostingStateChange$.next('paused');

        return JobPosting.param('company', this.auth.company.id)
            .with([
                'autoArchiveQuestions',
                'additionalQuestions',
                'applicantOptionalFields',
                'indeedIntegration',
                'jobAttachments',
                'jobIntegrations.integration.integrationType',
                'topic',
                'managerAssignments.employee',
                'hiringTeam',
                'hiringTeam.account',
                'jobPostingLocationType',
            ])
            .find(atsJobId.toString());
    }
}
