<div [ngClass]="{ 'is-hidden': isPrinting }" [id]="'graph-container' + '-' + chartId">
    <canvas
        #chart
        [id]="chartId"
        class="reportingChart"
        width="600"
        [height]="height"
        style="font-family: FontAwesome"
    ></canvas>
</div>
<img [ngClass]="{ 'is-hidden': !isPrinting }" [height]="height" class="print-img" [id]="'img-' + chartId" src="" />
