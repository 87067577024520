import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { CompanyLogo } from '@app/models/company/company-logo.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CompanyLogoService {
    constructor(private http: HttpClient) {}

    store(file: File): Promise<CompanyLogo> {
        return this.storeStream(file)
            .pipe(
                map((event) => {
                    if (event instanceof HttpResponse && event.ok && event.body) {
                        return new CompanyLogo(event.body);
                    }
                }),
                filter((value) => !!value),
                catchError((error) => {
                    throw ErrorParser.parse(error);
                })
            )
            .toPromise();
    }

    private storeStream(file: File): Observable<HttpEvent<object>> {
        const url = environment.api + '/v2/companyBrand/companyLogo';

        const data = new FormData();
        data.append('data[type]', 'files');
        data.append('data[attributes][file]', file);

        return this.http.request(
            new HttpRequest('POST', url, data, {
                reportProgress: true,
                responseType: 'json',
            })
        );
    }
}
