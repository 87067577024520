import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DeprecatedConfirmationDialogComponent } from '@app/components';
import { AnalyticEvents } from '@app/enums';
import { CompanyLogoService } from '@app/modules/settings/services';
import { AnalyticService, AuthService, FileHelperService, NotifyService } from '@app/services';
import { File as FileModel } from 'app/models/common/file.model';

@Component({
    selector: 'app-company-logo-uploader',
    templateUrl: './company-logo-uploader.template.html',
    styleUrls: ['./company-logo-uploader.style.scss'],
})
export class CompanyLogoUploaderComponent {
    @ViewChild('confirmDeleteLogo', { static: true }) confirmDeleteLogo!: DeprecatedConfirmationDialogComponent;

    /**
     * maxSize is the maximum logo size in bytes.
     */
    maxSize = 5_000_000;
    allowedImageTypes = ['.png', '.jpeg', '.jpg'];
    fileToPublish: File | null = null;
    fileName = '';
    filePreviewUrl = '';
    fileIsPersisted = false;
    isTooLarge = false;

    constructor(
        private companyLogoService: CompanyLogoService,
        private auth: AuthService,
        private fileHelper: FileHelperService,
        private http: HttpClient,
        private notify: NotifyService,
        private analyticService: AnalyticService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loadLogo();
    }

    /**
     * processFile handles the uploading of a file into the web browser
     */
    processFile($event: FileList): void {
        this.isTooLarge = false;
        this.fileToPublish = $event[0];
        this.fileName = this.fileToPublish?.name ?? '';
        this.filePreviewUrl = URL.createObjectURL(this.fileToPublish);
        if (this.fileToPublish?.size > this.maxSize) {
            this.isTooLarge = true;
        }
        this.analyticService.trackEvent(AnalyticEvents.SettingsCompanyLogoUpload);
    }

    async removeFile(): Promise<void> {
        if (!this.fileIsPersisted) {
            this.clearFileUploader();
        } else {
            this.confirmDeleteLogo.show().then((yes: boolean) => {
                if (!yes) {
                    return;
                }

                this.clearFileUploader();

                this.http
                    .delete('/v2/companyBrand/companyLogo', { observe: 'response' })
                    .toPromise()
                    .then(() => {
                        this.notify.success('settings.company-logo.logoRemovedSuccessMessage');
                        this.auth.company.brandLogoFileId = null;
                        this.analyticService.trackEvent(AnalyticEvents.SettingsCompanyLogoDelete);
                    })
                    .catch((err) => {
                        this.notify.error(err);
                    });
            });
        }
    }

    /**
     * publishFile sends the uploaded file to the backend for storage
     */
    async publishFile(): Promise<void> {
        if (!this.fileToPublish) {
            return;
        }

        await this.companyLogoService
            .store(this.fileToPublish)
            .then((companyLogo) => {
                this.notify.success('settings.company-logo.logoPublishedSuccessMessage');
                this.fileToPublish = null;
                this.fileIsPersisted = true;
                this.auth.company.brandLogoFileId = companyLogo.getAttributes()?.file?.id;
                this.analyticService.trackEvent(AnalyticEvents.SettingsCompanyLogoPublish);
                this.loadLogo();
            })
            .catch((err) => {
                this.notify.error(err);
            });
    }

    private async loadLogo(): Promise<void> {
        const logoFileId = this.auth.company.brandLogoFileId;

        if (logoFileId) {
            this.filePreviewUrl = this.fileHelper.serve(logoFileId);

            const response = await FileModel.find(logoFileId);
            this.fileName = response.name ?? '';

            this.fileIsPersisted = true;
        }
    }

    private clearFileUploader(): void {
        this.fileToPublish = null;
        this.filePreviewUrl = '';
    }
}
