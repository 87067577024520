<ui-banner *ngIf="hasWarningsToDisplay()" variant="warning" [class.margin-bottom-3]="withMargin">
    <div class="flex col">
        <strong>{{ header }}</strong>
        <ul>
            <li
                *ngFor="let warningToDisplay of warningsToDisplay"
                [innerHtml]="warningToDisplay.message | translate: warningToDisplay.params"
            ></li>
        </ul>
    </div>
</ui-banner>
