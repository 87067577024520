import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { EmailRegex } from '@app/regexes';
import { AuthService } from '@app/services';

@Directive({
    selector: '[emailValidator]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: EmailValidator, multi: true }],
})
export class EmailValidator implements AsyncValidator {
    @Input('errorMessage') errorMessage?: string;

    constructor(private auth: AuthService) {}

    async validate(control: AbstractControl): Promise<ValidationErrors | null> {
        // Remove white spaces (if any) in case user tries to copy the email from some other place.
        const email = control.value.replace(/\s/g, '');
        // We don't allow single letter TLDs
        const singleLetterTLDRegex = new RegExp(/\.[A-z]$/);
        const isEmailValid = EmailRegex.test(email) && !singleLetterTLDRegex.test(email);

        if (!isEmailValid) {
            const errors = { email: true };
            control.setErrors(errors);
            return errors;
        }

        // This will return 400 level error if it's not available.
        try {
            await this.auth.checkEmailAvailable(email);
        } catch (e) {
            let errors = {};
            if (!this.errorMessage) {
                errors = { unavailable: true };
            } else {
                errors = { unavailable: true, message: this.errorMessage };
            }

            control.setErrors(errors);
            return errors;
        }

        control.setErrors(null);
        return null;
    }
}
