import { ModelMixin } from '../core/base-generic.model';

export class SimplyBenefitsOnboardingEmployee extends ModelMixin<SimplyBenefitsOnboardingEmployee>() {
    protected static _type = 'simplyBenefitsOnboardingEmployee';
    protected static _resource = 'benefitManagement/employees/:employeeId/simplyBenefitsOnboardingEmployee';
    protected static _version = 'v2';

    protected static _serializeAttributes = ['classId', 'divisionId', 'waiveEligibilityPeriod'];

    get classId(): string {
        return this._attributes['classId'];
    }

    set classId(val: string) {
        this._attributes['classId'] = val;
    }

    get divisionId(): string {
        return this._attributes['divisionId'];
    }

    set divisionId(val: string) {
        this._attributes['divisionId'] = val;
    }

    get waiveEligibilityPeriod(): boolean {
        return this._attributes['waiveEligibilityPeriod'];
    }

    set waiveEligibilityPeriod(val: boolean) {
        this._attributes['waiveEligibilityPeriod'] = val;
    }
}
