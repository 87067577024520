<app-deprecated-confirmation-dialog
    #confirmDeleteLogo
    [label]="'settings.company-logo.confirmRemoveButton' | translate"
    [description]="'settings.company-logo.confirmRemoveMessage' | translate"
    [header]="'settings.company-logo.confirmRemoveHeader' | translate"
    [negative]="true"
>
</app-deprecated-confirmation-dialog>

<!-- If we have no file then show the user the file uploader -->
<app-file-drop
    #fileUploader
    *ngIf="(!filePreviewUrl && !fileToPublish) || isTooLarge"
    (filesSelected)="processFile($event)"
    [allowedTypes]="allowedImageTypes"
></app-file-drop>

<!-- If we have a file then we can show a file preview -->
<div *ngIf="(filePreviewUrl || fileToPublish) && !isTooLarge" class="file-preview margin-bottom-3">
    <div class="image-wrapper">
        <!-- If the user has supplied a new file, don't put it through the secure pipe -->
        <!-- The secure pipe will strip out the blob -->
        <img *ngIf="fileToPublish" [src]="filePreviewUrl" [alt]="'settings.company-logo.logoAltText' | appTranslate" />
        <!-- If the file already exists, use the secure pipe -->
        <!-- The odds that a company is trying to use their own logo maliciously must be vanishingly small -->
        <!-- But safety first! -->
        <img
            *ngIf="!fileToPublish"
            [src]="filePreviewUrl | secure"
            [alt]="'settings.company-logo.logoAltText' | appTranslate"
        />
    </div>

    <div class="file-preview-tools margin-top-1">
        <span>{{ fileName | capitalize }}</span>
        <div>
            <ui-button type="caution-link" (click)="removeFile()">
                {{ 'settings.company-logo.removeButton' | appTranslate }}
            </ui-button>
            <ui-button type="primary" *ngIf="fileToPublish" (click)="publishFile()">
                {{ 'settings.company-logo.publishButton' | appTranslate }}
            </ui-button>
        </div>
    </div>
</div>
<div *ngIf="isTooLarge" class="message">
    <mat-icon svgIcon="warning"></mat-icon>
    {{ 'settings.company-logo.fileExceedsLimitWarningMessage' | appTranslate }}
</div>
