import { Component } from '@angular/core';

@Component({
    selector: 'ui-dialog-footer',
    template: `
        <footer #footer class="dialog-footer">
            <ng-content></ng-content>
        </footer>
    `,
    styleUrls: ['./dialog-footer.style.scss'],
})
export class DialogFooter {}
