import { Injectable } from '@angular/core';

@Injectable()
export class DragService {
    public static DEFAULT_ZONE = 'humiZone';

    private draggedFromZone: string;

    private data: { [key: string]: unknown } = {};

    public storeData(data: unknown): string {
        const key = '_' + Math.random().toString(36).substr(2, 9);
        this.data[key] = data;
        return key;
    }

    public getData(key: string): unknown {
        return this.data[key];
    }

    public registerDraggedZone(group: string): void {
        this.draggedFromZone = group;
    }

    public removeDraggedZone(): void {
        this.draggedFromZone = null;
    }

    public accepts(zone: string): boolean {
        return this.draggedFromZone && this.draggedFromZone === zone;
    }
}
