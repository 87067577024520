import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorParser } from '@app/classes/error-parser.class';
import { HttpError } from '@app/errors/http/http.error';
import { InjectorInstance } from '@app/http.module';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import camelcaseKeys from 'camelcase-keys';
import { clone } from 'lodash-es';
import { stringify } from 'qs/dist/qs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JSONApiIndexResponse, JSONApiShowResponse, Model } from '../../interfaces';

const qsOptions = {
    indices: true,
};

const REQUEST_OPTIONS = {
    observe: 'body',
    headers: { 'Content-Type': 'application/json' },
} as const;

export type GetParam = string | number | boolean | { [key: string]: GetParam };

export class QueryFetcher<T = any> {
    _model: any;
    _filters: { [key: string]: any } = {};
    _customParams: { [prop: string]: GetParam } = {};
    _include: string[];
    _fields: string[];
    _sort: string;
    _sortDirection: string;
    _page = 1;
    _limit = 25;
    _params: any = {};
    private _withTrashed = false;
    private _onlyTrashed = false;
    private httpClient: HttpClient;

    constructor(model: unknown) {
        this.httpClient = InjectorInstance.get<HttpClient>(HttpClient);
        this._model = model;
    }

    select(fields: string[]): QueryFetcher<T> {
        this._fields = fields;
        return this;
    }

    get(): Promise<[T[], any]> {
        return new Promise((resolve, reject) => {
            this.httpGet<JSONApiIndexResponse<T> | Array<Record<string, unknown>>>(
                this._model.version +
                    '/' +
                    this.replaceUrlParams(this._model.resource) +
                    '?' +
                    stringify(this.serializeSearchPayload(), qsOptions)
            )
                .then((res) => {
                    let meta;
                    let mappedRes: Array<Record<string, unknown>>;

                    if (!Array.isArray(res)) {
                        meta = res.meta;
                        const data = res.data;

                        mappedRes = data.map((singleData) => {
                            return {
                                data: { _params: this._params, ...singleData },
                                included: res.included,
                            };
                        });
                    } else {
                        mappedRes = res;
                    }

                    const models: T[] = mappedRes.map((obj) => new this._model(obj));
                    resolve([models, meta]);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    raw(): Promise<[any, any]> {
        const url =
            this._model.version +
            '/' +
            this.replaceUrlParams(this._model.resource) +
            '?' +
            stringify(this.serializeSearchPayload(), qsOptions);

        return new Promise((resolve, reject) => {
            this.httpGet<JSONApiIndexResponse<T>>(url)
                .then((res) => {
                    let meta;
                    let mappedRes: any;

                    if (!Array.isArray(res)) {
                        meta = res.meta;
                        const data = res.data;

                        mappedRes = data.map((singleData) => {
                            return {
                                data: { _params: this._params, ...singleData },
                                included: res.included,
                            };
                        });
                    }

                    resolve([mappedRes, meta]);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    /**
     * Execute query only when condition is truthy
     */
    when(condition: any, callback: any): QueryFetcher<T> {
        if (condition) {
            return callback(this);
        }

        return this;
    }

    where(field: string, value: any): QueryFetcher<T> {
        this._filters[field] = value;
        return this;
    }

    queryParams(field: string, value: GetParam): QueryFetcher<T> {
        this._customParams[field] = value;
        return this;
    }

    whereIn(field: string, value: any[]): QueryFetcher<T> {
        //Set empty arrays to null as 'stringify' strips these out preventing the filter to be applied
        if (!value || !value.length) {
            value = null;
        }
        this._filters[field] = value;
        return this;
    }

    param(field: string, value: any): QueryFetcher<T> {
        this._params[field] = value;
        return this;
    }

    first(): Promise<T> {
        return new Promise((resolve, reject) => {
            this.httpGet<JSONApiIndexResponse<T>>(
                this._model.version +
                    '/' +
                    this.replaceUrlParams(this._model.resource) +
                    '?' +
                    stringify(this.serializeSearchPayload(), qsOptions)
            )
                .then((res) => {
                    res = this.persistParams(res);
                    const model: T = new this._model(res);
                    resolve(model);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    find(id: number | string): Promise<T> {
        const url =
            this._model.version +
            '/' +
            this.replaceUrlParams(this._model.resource) +
            '/' +
            id +
            '?' +
            stringify(this.serializeSearchPayload(), qsOptions);

        return new Promise((resolve, reject) => {
            this.httpGet<JSONApiShowResponse<T>>(url)
                .then((res) => {
                    res = this.persistParams(res);
                    const model: T = new this._model(res);
                    resolve(model);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    /**
     * Get the search payload as a URL query string
     * No pagination
     * No ? prefix
     */
    searchPayload(): string {
        const payload = this.serializeSearchPayload();
        delete payload.page;
        return stringify(payload, qsOptions);
    }

    /**
     * Perform a show/get on an index route
     * Useful for cases where the model only has 1 possible entity
     */
    show(): Promise<T> {
        return new Promise((resolve, reject) => {
            this.httpGet(
                this._model.version +
                    '/' +
                    this.replaceUrlParams(this._model.resource) +
                    '?' +
                    stringify(this.serializeSearchPayload(), qsOptions)
            )
                .then((res) => {
                    res = this.persistParams(res);
                    const model: T = new this._model(res);
                    resolve(model);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    all(): Promise<[T[], any]> {
        this._limit = 0;
        return this.get();
    }

    page(page: number): QueryFetcher<T> {
        this._page = page;
        return this;
    }

    with(include: string | string[], hasRelationPermission?: boolean): QueryFetcher<T> {
        if (hasRelationPermission !== undefined && hasRelationPermission === false) {
            console.warn('Attempt to include', include, 'but permission check did not pass');
            return this;
        }

        if (!this._include) {
            this._include = [];
        }

        if (typeof include === 'string') {
            this._include.push(include);
            return this;
        }
        this._include = this._include.concat(include);
        return this;
    }

    getRelated(_relationship: string): Promise<Model[]> {
        return new Promise((resolve, reject) => {
            this.httpGet(this._model.version + '/' + this._model.resource)
                .then((res) => {
                    const models: Model[] = (res as Array<object>).map((obj) => new this._model(obj));
                    resolve(models);
                })
                .catch((err) => reject(err));
        });
    }

    persistParams(res: any): any {
        if (!this._params) {
            return res;
        }

        if (!res.data) {
            return { _params: this._params, ...res };
        }

        if (res.data) {
            res._params = this._params;
            return res;
        }

        return res;
    }

    orderBy(field: string, direction: string): QueryFetcher<T> {
        this._sort = field;
        this._sortDirection = direction;
        return this;
    }

    limit(limit: number): QueryFetcher<T> {
        this._limit = limit;
        return this;
    }

    withTrashed(): QueryFetcher<T> {
        this._withTrashed = true;
        return this;
    }

    onlyTrashed(): QueryFetcher<T> {
        this._onlyTrashed = true;
        return this;
    }

    /**
     * Clone the current queryBuilder
     * @todo rebuild this function, maybe ext lib ? shared import?
     */
    clone(): QueryFetcher<T> {
        const cloneObj = new QueryFetcher<T>(this._model);
        if (this._filters) {
            cloneObj._filters = JSON.parse(JSON.stringify(this._filters));
        }
        if (this._include) {
            cloneObj._include = JSON.parse(JSON.stringify(this._include));
        }
        if (this._fields) {
            cloneObj._fields = JSON.parse(JSON.stringify(this._fields));
        }
        cloneObj._sort = this._sort;
        cloneObj._sortDirection = this._sortDirection;
        cloneObj._page = this._page;
        cloneObj._limit = this._limit;
        if (this._params) {
            cloneObj._params = JSON.parse(JSON.stringify(this._params));
        }
        if (this._customParams) {
            cloneObj._customParams = JSON.parse(JSON.stringify(this._customParams));
        }
        return cloneObj;
    }

    getVersion(): string {
        return this._model._version;
    }

    httpGet<U>(url: string): Promise<U | null> {
        return this.httpClient
            .get<U>(environment.api + '/' + url, REQUEST_OPTIONS)
            .pipe(this.processResponse)
            .toPromise();
    }

    httpPost<U>(url: string, payload: object): Promise<U | null> {
        return this.httpClient
            .post<U>(environment.api + '/' + url, JSON.stringify(payload), REQUEST_OPTIONS)
            .pipe(this.processResponse)
            .toPromise();
    }

    httpPut<U>(url: string, payload: object): Promise<U | null> {
        return this.httpClient
            .put<U>(environment.api + '/' + url, JSON.stringify(payload), REQUEST_OPTIONS)
            .pipe(this.processResponse)
            .toPromise();
    }

    httpDelete<U>(url: string): Promise<U | null> {
        return this.httpClient
            .delete<U>(environment.api + '/' + url, REQUEST_OPTIONS)
            .pipe(this.processResponse)
            .toPromise();
    }

    /**
     * Processes HTTP Responses from all request types (GET, POST, PUT, etc.)
     * Add any functionality common to all request types here, ie. logging or error handling
     * @param sourceObservable The Observable returned from the HTTP request
     * @returns The observable after processing. Further RxJS operators can be chained afterwards
     */
    private processResponse<U>(sourceObservable: Observable<U>): Observable<U> {
        return sourceObservable.pipe(
            // Error Handling - Parse and log to Sentry
            catchError((error: unknown) => {
                if (error instanceof HttpErrorResponse) {
                    const HTTPError: HttpError = new HttpError(error, ErrorParser.parse(error));
                    Sentry.captureException(HTTPError);
                    throw HTTPError;
                }
                Sentry.captureException(error);
                throw error;
            })
        );
    }

    /**
     * Prepare the search payload to be converted to query string
     */
    private serializeSearchPayload(): Record<string, unknown> {
        let payload: Record<string, unknown> = {};

        if (Object.keys(this._customParams).length) {
            payload = clone(this._customParams);
        }

        if (this._sort) {
            payload.sort = {
                column: this.camelize(this._sort),
                direction: this._sortDirection,
            };
        }

        if (this._fields) {
            payload.fields = this._fields.map((field) => this.camelize(field)).join(',');
        }

        if (this._filters) {
            payload.filters = camelcaseKeys(this._filters, { exclude: [/\./] });
        }
        if (this._include) {
            payload.include = this._include.join(',');
        }
        if (this._page !== null) {
            payload.page = { number: this._page, size: this._limit };
        }
        if (this._withTrashed) {
            payload.withTrashed = true;
        }
        if (this._onlyTrashed) {
            payload.onlyTrashed = true;
        }

        return payload;
    }

    private replaceUrlParams(resource: string): string {
        for (const param in this._params) {
            resource = resource.replace(':' + param, this._params[param]);
        }

        if (resource.indexOf('/:') > -1) {
            throw new Error('All URL params must be replaced in ' + resource);
        }

        return resource;
    }

    private camelize(str: string): string {
        return str.replace(/(\_\w)/g, function (k: string): string {
            return k[1].toUpperCase();
        });
    }
}
