import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[additionalCompensationNameValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: AdditionalCompensationNameValidator, multi: true }],
})
export class AdditionalCompensationNameValidator implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const name = control.value;
        const nameIsRegularPayOrOvertime = name
            ? name.toLowerCase().trim() === 'regular pay' || name.toLowerCase().trim() === 'overtime'
            : null;

        const errors = {
            custom: {
                message: `Additonal Compensations cannot be named "Regular Pay" or "Overtime"`,
            },
        };
        return nameIsRegularPayOrOvertime ? errors : null;
    }
}
