import { Component, ElementRef, Input } from '@angular/core';
import { NotifyService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { WorkSchedule } from '@models/time-off-v3/works-schedule.model';
import { BaseDialogComponent } from '../base.dialog';
import { TimeOffv3WorkScheduleDisplayOptions } from '@app/types/translatables/time-off-v3-display.options';

@Component({
    selector: 'app-common-select-work-schedule-dialog',
    templateUrl: './select-work-schedule-dialog.template.html',
    styleUrls: ['./select-work-schedule-dialog.style.scss'],
})
export class SelectWorkScheduleDialogComponent extends BaseDialogComponent {
    @Input() label = 'select';
    @Input() headerText = 'components.select-work-schedule-dialog.selectWorkSchedule';

    isLoading = false;

    workSchedules: WorkSchedule[] = [];
    selection: WorkSchedule | null = null;
    existingSelection: WorkSchedule;

    workScheduleDisplayOptions = TimeOffv3WorkScheduleDisplayOptions;

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        protected notify: NotifyService
    ) {
        super(element);
    }

    /**
     * Reset the form
     */
    onBeforeShow(): void {
        this.selection = this.existingSelection;

        this.loadItems();
    }

    isActionButtonDisabled(): boolean {
        if (!this.selection) {
            return true;
        }

        return false;
    }

    workScheduleComparison(selected: WorkSchedule, value: WorkSchedule): boolean {
        return selected.id === value.id;
    }

    protected loadItems(): void {
        this.isLoading = true;

        try {
            WorkSchedule.param('company', this.auth.company.id)
                .all()
                .then(([workSchedules]) => {
                    this.workSchedules = workSchedules;

                    if (!this.selection) {
                        this.selection = workSchedules.filter((workSchedule: WorkSchedule) => {
                            return workSchedule.isDefault;
                        })[0];
                    }

                    this.isLoading = false;
                });
        } catch (err) {
            this.notify.error(err);
        } finally {
            this.isLoading = false;
        }
    }
}
