import { Model } from '@app/interfaces';

import { Translatable } from '@app/types/translatable.type';
import { CellDisplay } from '../enums/cell-display.enum';
import { BadgeDisplay } from '../interfaces/badge-display.interface';
import { Column } from './column.class';

export abstract class BadgeColumn<T extends Model> extends Column {
    type = CellDisplay.badge;

    /**
     * Return a BadgeDisplay type with the text that should be within the badge,
     * and the badge type to use.
     *
     * If undefined is returned, no badge will show.
     */
    abstract getBadgeDisplay(row: T): BadgeDisplay | undefined;

    /**
     * Cell text can appear beside the badge if necessary. If so, the badge will float a bit
     * to the right of the cell text.
     */
    getCellText(_: T): Translatable | null {
        return null;
    }
}
