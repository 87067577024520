import { Pipe, PipeTransform } from '@angular/core';
import { LegalSexDisplay } from '@app/enums/legal-sex-display.enum';
import { displayLegalSex } from '@app/functions/legal-sex';
import { LegalSex } from '@app/types/legal-sex.type';

@Pipe({ name: 'legalSex' })
export class LegalSexPipe implements PipeTransform {
    transform(legalSex: LegalSex): LegalSexDisplay {
        return displayLegalSex(legalSex);
    }
}
