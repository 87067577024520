import { Moment } from 'moment';
import { ModelMixin } from '../core/base-generic.model';
import { LeaveOfAbsenceTypes } from './leave-of-absence-types.model';

export class LeaveOfAbsence extends ModelMixin<LeaveOfAbsence>() {
    protected static _resource = 'companyManagement/employees/:employeeId/leaveOfAbsences';

    protected static _type = 'leaveOfAbsences';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['employeeId', 'leaveOfAbsenceTypeId', 'start', 'end', 'description'];

    protected static _dates = ['start', 'end'];

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    get leaveOfAbsenceTypeId(): number {
        return this._attributes['leaveOfAbsenceTypeId'];
    }

    set leaveOfAbsenceTypeId(val: number) {
        this._attributes['leaveOfAbsenceTypeId'] = val;
    }

    get start(): Moment {
        return this._attributes['start'];
    }

    set start(val: Moment) {
        this._attributes['start'] = val;
    }

    get end(): Moment {
        return this._attributes['end'];
    }

    set end(val: Moment) {
        this._attributes['end'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get LeaveOfAbsenceTypes(): LeaveOfAbsenceTypes[] {
        return this.hasMany(LeaveOfAbsenceTypes, 'leaveOfAbsenceTypes');
    }
}
