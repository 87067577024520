import { ModelMixin } from '../core/base-generic.model';
import { Question } from './question.model';

export class Answer extends ModelMixin<Answer>() {
    protected static _type = 'jobPostingQuestionAnswers';
    protected static _resource = 'applicantTracker/jobPostingQuestionAnswers';
    protected static _version = 'v2';

    get jobPostingQuestionId(): number {
        return this._attributes['jobPostingQuestionId'];
    }

    get question(): Question {
        return this.hasOne(Question, 'question');
    }

    get questionV2(): Question {
        return this.hasOne(Question, 'questionV2');
    }

    set questionV2(val: Question) {
        this.setOne('questionV2', val);
    }

    get questionWithTrashed(): Question {
        return this.hasOne(Question, 'questionWithTrashed');
    }

    get applicantId(): number {
        return this._attributes['applicantId'];
    }

    get answer(): string {
        return this._attributes['answer'];
    }
}
