import { PlatformModules } from '@app/classes';
import { Model } from '../core/base.model';

export class Module extends Model {
    protected static _resource = 'commonComponents/modules';

    protected static _version = 'v2';

    get name(): PlatformModules {
        return this._attributes['name'];
    }
}
