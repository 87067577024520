<ng-container *ngIf="model">
    <!-- Desktop shows the card as a ui-card with all info available-->
    <ui-card class="only-large">
        <ng-container [ngTemplateOutlet]="nameAndTitle" />
        <ng-container [ngTemplateOutlet]="isEmployee(model) ? employeeFields : companyFields" />
    </ui-card>
    <!-- Mobile shows just the header info as a button that can be pressed to show the additional details.
    This keeps the card from taking up too much screen space since it appears on every tab-->
    <mat-expansion-panel class="hide-large">
        <mat-expansion-panel-header collapsedHeight="88px" expandedHeight="88px">
            <ng-container [ngTemplateOutlet]="nameAndTitle"
        /></mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <ng-container [ngTemplateOutlet]="isEmployee(model) ? employeeFields : companyFields" />
        </ng-template>
    </mat-expansion-panel>
</ng-container>

<ng-template #nameAndTitle>
    <div class="image">
        <img
            *ngIf="isCompany(model) && avatar?.avatarId; else avatarView"
            [src]="fileHelperService.path(avatar?.avatarId) | secure"
        />
        <ng-template #avatarView>
            <app-avatar [employee]="avatar" size="profile" [withMargin]="false"></app-avatar>
        </ng-template>
        <div
            *ngIf="isEmployee(model) && (auth.user?.id === model.id || auth.canEdit(model, 'id'))"
            class="edit-button"
            (click)="onClickEdit($event)"
        >
            <mat-icon class="auto-size" svgIcon="edit"></mat-icon>
        </div>
    </div>
    <div class="name-and-title">
        <div class="employee-name">
            <h2 *ngIf="isEmployee(model)" title="{{ model.fullName }}">
                <div>{{ model.firstName }}&nbsp;</div>
                <div>{{ model.lastName }}</div>
            </h2>
            <h2 *ngIf="isCompany(model)">{{ model.name }}</h2>

            <app-audio-playback
                *ngIf="isEmployee(model) && model?.employeeNamePronunciation"
                #audioPlayer
                [file]="model.employeeNamePronunciation.file"
                (audioPlayed)="addPendoEventForPlayAudioSidebar()"
            ></app-audio-playback>
        </div>
        <h5 *ngIf="isEmployee(model) && model.job" aria-level="3" class="employee-role" title="{{ model.job.title }}">
            {{ model.job.title }}
        </h5>
        <h5
            *ngIf="isCompany(model) && model.industry"
            aria-level="3"
            class="employee-role"
            title="{{ model.industry }}"
        >
            {{ model.industry }}
        </h5>
    </div>
</ng-template>

<ng-template #employeeFields>
    <div *ngIf="isEmployee(model) && model as employee" class="vertical-labels">
        <div class="vertical-label capitalize">
            <label>{{ 'account.status' | appTranslate }}</label>
            <div class="flex middle allow-overflow">
                {{
                    employee.onLeave
                        ? ('employeeStatus.onLeave' | appTranslate)
                        : (employee.status | appTranslateOptions: employeeStatusDisplay)
                }}
                <ui-badge
                    class="margin-left-auto"
                    *ngIf="employee?.account?.isLocked && auth.can('view.account')"
                    type="warning"
                >
                    {{ 'account.locked' | appTranslate }}
                </ui-badge>
            </div>
        </div>
        <div class="vertical-label" *ngIf="employee?.account?.email">
            <label>{{ 'account.email' | appTranslate }}</label>
            <a href="mailto:{{ employee.account.email }}">{{ employee.account.email }}</a>
        </div>
        <div class="vertical-label" *ngIf="employee?.phoneWork">
            <label>{{ 'account.phoneNumber' | appTranslate }}</label>
            {{ employee.phoneWork }}
        </div>
        <div class="vertical-label" *ngIf="employee?.office?.name">
            <label>{{ 'account.office' | appTranslate }}</label>
            <a [routerLink]="['/company', 'offices', employee.office.id]">{{ employee.office.name }}</a>
        </div>
        <div class="vertical-label" *ngIf="employee?.manager?.fullName">
            <label>{{ 'account.reportsTo' | appTranslate }}</label>
            <a [routerLink]="['/employees', 'directory', employee.manager?.id]">{{ employee.manager?.fullName }}</a>
        </div>
    </div>
</ng-template>

<ng-template #companyFields>
    <div *ngIf="isCompany(model) && model as company" class="vertical-labels">
        <div class="vertical-label">
            <label>{{ 'company.overview.employeesLabel' | translate }}</label>
            {{ company.activeEmployeeCount || 0 }}
        </div>
        <div class="vertical-label">
            <label>{{ 'company.overview.foundedLabel' | translate }}</label>
            {{ company.incorporationDate | appDate: 'longDate' }}
        </div>
        <div class="vertical-label">
            <label>{{ 'company.overview.websiteLabel' | translate }}</label>
            <a href="{{ company.websiteAbsoluteUrl }}"> {{ company.websiteAbsoluteUrl }} </a>
        </div>
    </div>
</ng-template>

<ui-dialog
    *ngIf="isEmployee(model) && isAvatarDialogOpen"
    [open]="isAvatarDialogOpen"
    (closing)="isAvatarDialogOpen = false"
    header=""
>
    <app-avatar-upload
        (onUpload)="handleAvatarUploadTerminated($event)"
        *ngIf="isAvatarDialogOpen"
        [employee]="model"
    ></app-avatar-upload>
</ui-dialog>
