import { SimpleEntityActions } from '@app/classes';
import { SaveOptions } from '@app/interfaces/save-options.interface';
import { ModelMixin } from '../core/base-generic.model';
import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';
import { Email } from './email.model';
import { Role } from './role.model';

export class Account extends ModelMixin<Account>() {
    get id(): number {
        return +this._attributes['id'];
    }

    get email(): string {
        return this._attributes['email'];
    }

    set email(val: string) {
        this._attributes['email'] = val;
    }

    get isMFAEnabled(): boolean {
        return this._attributes['mfaEnabled'];
    }

    set isMFAEnabled(val: boolean) {
        this._attributes['mfaEnabled'] = val;
    }

    get isRoleBasedMfaEnabled(): boolean {
        return this._attributes['roleBasedMfaEnabled'];
    }

    /**
     * @deprecated
     */
    get firstName(): string {
        return this._attributes['firstName'] || this._attributes['first_name'];
    }

    get legalFirstName(): string {
        return this._attributes['legalFirstName'];
    }

    /**
     * @deprecated
     */
    set firstName(val: string) {
        this._attributes['firstName'] = val;
    }

    set legalFirstName(val: string) {
        this._attributes['firstName'] = val;
        this._attributes['legalFirstName'] = val;
    }

    /**
     * @deprecated
     */
    get middleName(): string {
        return this._attributes['middleName'] || this._attributes['middle_name'];
    }

    get legalMiddleName(): string {
        return this._attributes['legalMiddleName'];
    }

    /**
     * @deprecated
     */
    set middleName(val: string) {
        this._attributes['middleName'] = val;
    }

    set legalMiddleName(val: string) {
        this._attributes['middleName'] = val;
        this._attributes['legalMiddleName'] = val;
    }

    /**
     * @deprecated
     */
    get lastName(): string {
        return this._attributes['lastName'] || this._attributes['last_name'];
    }

    get legalLastName(): string {
        return this._attributes['legalLastName'];
    }

    /**
     * @deprecated
     */
    set lastName(val: string) {
        this._attributes['lastName'] = val;
    }

    set legalLastName(val: string) {
        this._attributes['lastName'] = val;
        this._attributes['legalLastName'] = val;
    }

    get createdAt(): string {
        return this._attributes['createdAt'] || null;
    }

    get activatedAt(): string {
        return this._attributes['activatedAt'] || null;
    }

    get isActive(): boolean {
        return this._attributes['isActive'];
    }

    set isActive(val: boolean) {
        this._attributes['isActive'] = val;
    }

    get isLocked(): boolean {
        return this._attributes['isLocked'];
    }

    set isLocked(val: boolean) {
        this._attributes['isLocked'] = val;
    }

    get deactivatedAt(): string {
        return this._attributes['deactivatedAt'];
    }

    set deactivatedAt(val: string) {
        this._attributes['deactivatedAt'] = val;
    }

    get fullName(): string | null {
        const firstName = this._attributes.legalFirstName;
        const lastName = this._attributes.legalLastName;
        if (firstName && lastName) {
            return `${firstName} ${lastName}`;
        }

        if (firstName) {
            return firstName;
        }

        if (lastName) {
            return lastName;
        }

        return null;
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get emails(): Email[] {
        return this.hasMany(Email, 'accountEmails');
    }

    set emails(emails: Email[]) {
        this._attributes['emails'] = emails;
    }

    get isMultiCompany(): boolean {
        return this._attributes['isMultiCompany'];
    }

    get personaCompletedAt(): Date | undefined {
        return this._attributes['personaCompletedAt'];
    }

    get roles(): Role[] | undefined {
        return this._attributes['roles'];
    }

    static loginAsPermission = 'loginAs.account';
    protected static _resource = 'accountManagement/accounts';
    protected static _version = 'v2';
    protected static _serializeAttributes = [
        'email',
        'mfaEnabled',
        'firstName',
        'legalFirstName',
        'lastName',
        'legalLastName',
        'middleName',
        'legalMiddleName',
        'isActive',
        'isLocked',
    ];

    static permission = new SimpleEntityActions('account');

    static legalNamePermission = new SimpleEntityActions('account.legalName', ['view', 'update']);

    // accounts only save dirty attributes (PATCH)
    save(options: SaveOptions = { onlyDirty: true, includeId: true }) {
        return super.save(options);
    }

    getActivationLink(): Promise<any> {
        return Model.performGet(this.getActivationLinkUrl());
    }

    private getActivationLinkUrl(): string {
        return Account._version + '/' + Account._resource + '/' + this.id + '/activationLink';
    }
}
