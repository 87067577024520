import { Model } from '@models/core/base.model';

export class TimeOffTransaction extends Model {
    protected static _resource = 'timeOffV3/transactions';
    protected static _type = 'timeOffTransactions';
    protected static _version = 'v2';
    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];
    protected static _dates = ['periodEndDate', 'processIndex', 'processedAt'];

    protected static _serializeAttributes = [
        'timeOffTypeId',
        'employeeId',
        'isBalanceReset',
        'description',
        'amount',
        'processedAt',
    ];

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get creatorId(): number {
        return this._attributes['creatorId'];
    }

    get timeOffTypeId(): number {
        return this._attributes['timeOffTypeId'];
    }

    get timeOffPolicyId(): number {
        return this._attributes['timeOffPolicyId'];
    }

    get timeOffRequestDayId(): number {
        return this._attributes['timeOffRequestDayId'];
    }

    get title(): string {
        return this._attributes['title'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get periodStartDate(): Date {
        return this._attributes['periodStartDate'];
    }

    get periodEndDate(): Date {
        return this._attributes['periodEndDate'];
    }

    get processIndex(): number {
        return this._attributes['processIndex'];
    }

    get amount(): number {
        return this._attributes['amount'];
    }

    get balance(): number {
        return this._attributes['balance'];
    }

    get balanceHours(): number {
        return this._attributes['balanceHours'];
    }

    get isPending(): boolean {
        return this._attributes['isPending'];
    }

    get processedAt(): Date {
        return this._attributes['processedAt'];
    }

    get isBalanceReset(): boolean {
        return this._attributes['isBalanceReset'];
    }

    get isAdminAdjustment(): boolean {
        return this.processIndex === 1;
    }

    get hoursPerDay(): number {
        return this._attributes['hoursPerDay'];
    }

    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    set creatorId(val: number) {
        this._attributes['creatorId'] = val;
    }

    set timeOffTypeId(val: number) {
        this._attributes['timeOffTypeId'] = val;
    }

    set timeOffPolicyId(val: number) {
        this._attributes['timeOffPolicyId'] = val;
    }

    set timeOffRequestDayId(val: number) {
        this._attributes['timeOffRequestDayId'] = val;
    }

    set title(val: string) {
        this._attributes['title'] = val;
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    set periodStartDate(val: Date) {
        this._attributes['periodStartDate'] = val;
    }

    set periodEndDate(val: Date) {
        this._attributes['periodEndDate'] = val;
    }

    set processIndex(val: number) {
        this._attributes['processIndex'] = val;
    }

    set amount(val: number) {
        this._attributes['amount'] = val;
    }

    set balance(val: number) {
        this._attributes['balance'] = val;
    }

    set balanceHours(val: number) {
        this._attributes['balanceHours'] = val;
    }

    set isPending(val: boolean) {
        this._attributes['isPending'] = val;
    }

    set processedAt(val: Date) {
        this._attributes['processedAt'] = val;
    }

    set isBalanceReset(val: boolean) {
        this._attributes['isBalanceReset'] = val;
    }

    set hoursPerDay(val: number) {
        this._attributes['hoursPerDay'] = val;
    }
}
