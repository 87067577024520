import { Roe } from '@app/models/payroll/roe.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';

export class RoeStatusColumn extends BadgeColumn<Roe> {
    title = 'table.roes.status';
    property = 'state';
    sortField = 'state';
    sortable = true;
    filters = [
        new CheckListFilter('state', [
            { label: 'table.roes.statusOptions.open', value: 'open', initiallyActive: false },
            { label: 'table.roes.statusOptions.pending', value: 'pending', initiallyActive: false },
            { label: 'table.roes.statusOptions.submitted', value: 'submitted', initiallyActive: false },
            { label: 'table.roes.statusOptions.failed', value: 'failed', initiallyActive: false },
        ]),
    ];
    type: CellDisplay = CellDisplay.badge;

    getBadgeDisplay(roe: Roe): BadgeDisplay | undefined {
        const BadgeTypeAndLabel = this.getRoeBadgeTypeAndLabel(roe?.state);
        return BadgeTypeAndLabel;
    }

    getRoeBadgeTypeAndLabel(roeState: string): BadgeDisplay {
        switch (roeState) {
            case 'open':
                return {
                    type: 'default',
                    label: 'table.roes.statusOptions.open',
                };
            case 'pending':
                return {
                    type: 'warning',
                    label: 'table.roes.statusOptions.pending',
                };
            case 'processing':
                return {
                    type: 'warning',
                    label: 'table.roes.statusOptions.pending',
                };
            case 'submitted':
                return {
                    type: 'success',
                    label: 'table.roes.statusOptions.submitted',
                };
            case 'failed':
                return {
                    type: 'danger',
                    label: 'table.roes.statusOptions.failed',
                };
        }
    }
}
