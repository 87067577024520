import { HttpErrorResponse } from '@angular/common/http';

export class NotImplementedError extends Error {
    httpStatus = 501;

    constructor(res: HttpErrorResponse, message?: string) {
        super(message ? message : res.statusText);
        Object.setPrototypeOf(this, NotImplementedError.prototype);
    }
}
