import { Platform } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { MenuItemGroup } from '@app/interfaces';
import { TrainingProgram } from '@app/models/training/training-program.model';

export const EmployeeNavMenu: MenuItemGroup[] = [
    [
        {
            key: 'sidebar.home',
            link: ['/dashboard'],
        },
        {
            key: 'sidebar.profile',
            link: ['/account'],
        },
    ],

    [
        {
            key: 'sidebar.people',
            link: ['/employees'],
            can: Platform.people.permission.view,
        },
        {
            key: 'sidebar.payroll',
            link: ['/payroll', 'self-onboarding'],
            can: Platform.payroll.permission.view,
            module: Platform.modules.payroll,
            unableTo: 'payroll',
            hideIfNoTenantId: true,
        },
        {
            key: 'sidebar.payroll',
            link: ['/payroll'],
            can: Platform.payroll.permission.view,
            module: Platform.modules.payroll,
            ableTo: 'payroll',
        },
        {
            key: 'sidebar.paystubs',
            link: ['/profile/paystubs'],
            cannot: Platform.payroll.permission.view,
            module: Platform.modules.payroll,
            ableTo: 'paystubs',
        },
        {
            key: 'sidebar.timeTracking',
            link: ['/time-tracking/'],
            module: Platform.modules.timeTracking,
            employeeModule: Platform.modules.timeTracking,
        },
        {
            key: 'sidebar.timeOff',
            link: ['/profile/time-off'],
            can: Platform.timeOff.permission.view,
            module: Platform.modules.timeOff,
        },
    ],
    [
        {
            key: 'sidebar.recruiting',
            link: ['/recruiting'],
            can: [Platform.recruiting.permission.access],
            module: Platform.modules.recruiting,
        },
        {
            key: 'sidebar.documents',
            link: ['/profile/documents/folders'],
            hideIfHasFeatureFlag: FeatureFlag.documentsV2,
        },
        {
            key: 'sidebar.documents',
            link: ['/profile/documents-v2/folders'],
            showIfHasFeatureFlag: FeatureFlag.documentsV2,
        },
        {
            key: 'sidebar.training',
            link: ['/training'],
            can: [TrainingProgram.accessTrainingModulePermission],
        },
    ],

    // Group 4
    [
        {
            key: 'sidebar.surveys',
            link: ['/profile/surveys'],
            can: Platform.performance.permission.view,
            module: Platform.modules.performance,
            hideIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
        },
        {
            key: 'sidebar.surveys',
            link: ['/profile/surveys'],
            can: Platform.survey.permission.view,
            module: Platform.modules.survey,
            showIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
        },
        {
            key: 'sidebar.performance',
            link: ['/profile/performance'],
            can: Platform.performance.permission.view,
            module: Platform.modules.performance,
        },
    ],
    /**
     * The following permissions for benefits works as follows now for an employee:
     * - Any employee which has a benefits plan will be able to see the nav item.
     * - Simply benefits will remain the same and will be controlled via the `accessBenefits` permission toggle.
     */
    [
        {
            key: 'sidebar.benefits',
            link: ['/profile/benefits'],
            showWhen: ({ authService }): boolean => {
                return authService.employee.hasBenefitPlan();
            },
            module: Platform.modules.benefits,
            showIfCompanyAndEmployeeHaveSimplyBenefits: false,
        },
        {
            key: 'sidebar.benefits',
            link: ['/benefits/portal'],
            openInNewTab: true,
            can: Platform.benefits.permission.view,
            module: Platform.modules.benefits,
            showIfCompanyAndEmployeeHaveSimplyBenefits: true,
            // once simplyBenefits flag is deleted you can safely delete the following line and this comment. You're welcome
            showIfHasFeatureFlag: FeatureFlag.simplyBenefits,
        },
        {
            key: 'sidebar.company',
            link: ['/company'],
        },
    ],
];
