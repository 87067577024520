import { Component, ElementRef, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import 'cropperjs';

const cropperOptions = {
    aspectRatio: 1,
    viewMode: 2,
};

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.template.html',
    styleUrls: ['./image-cropper.style.scss'],
})
export class ImageCropperComponent implements OnChanges {
    @ViewChild('image', { static: true }) image: ElementRef;
    @Input() source: SafeUrl | string;
    display = false;
    protected cropper: Cropper;

    constructor(protected zone: NgZone) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('source')) {
            this.resetCropper();
        }
    }

    getCroppedCanvas(): HTMLCanvasElement {
        if (this.cropper) {
            return this.cropper.getCroppedCanvas();
        }
    }

    protected resetCropper(): void {
        this.display = false;
        setTimeout(() => {
            if (this.cropper) {
                this.cropper.destroy();
            }
            this.cropper = new Cropper(this.image.nativeElement, <any>cropperOptions);
            this.display = true;
        });
    }
}
