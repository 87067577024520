export const capOnOptions = {
    yearEnd: 'year-end',
    dateHired: 'date-hired',
    pickDate: 'pick-date',
} as const;

export const carryoverTypes = {
    annualReset: 'annualReset',
    carryOver: 'carryOver',
} as const;

export const carryoverSubTypes = {
    limitedCarryover: 'limitedCarryover',
    unlimitedCarryover: 'unlimitedCarryover',
} as const;

export type CapOnOptions = (typeof capOnOptions)[keyof typeof capOnOptions];
export type CarryoverTypes = (typeof carryoverTypes)[keyof typeof carryoverTypes];
export type CarryoverSubTypes = (typeof carryoverSubTypes)[keyof typeof carryoverSubTypes];
