import { Model } from '@models/core/base.model';

export class TaskSubtask extends Model {
    protected static _resource = 'tasks/:task/subtasks';
    protected static _version = 'v2';
    protected static _type = 'taskSubtasks';

    protected static _serializeAttributes = ['description', 'order_by', 'completedAt'];

    get taskId(): string {
        return this._attributes['taskId'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get orderBy(): number {
        return this._attributes['order_by'];
    }

    set orderBy(val: number) {
        this._attributes['order_by'] = val;
    }

    get completedAt(): number {
        return this._attributes['completedAt'];
    }

    set completedAt(val: number) {
        this._attributes['completedAt'] = val;
    }

    isComplete(): boolean {
        return !!this.completedAt;
    }
}
