import { BenefitType } from '@models/benefits/benefit-type.model';
import { Model } from '../core/base.model';

export class BenefitDetail extends Model {
    protected static _resource =
        'benefitManagement/companies/:company/benefitPlans/:benefitPlan/benefits/:benefit/benefitDetails';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'benefitId',
        'benefitTypeId',
        'coverage',
        'reimbursement',
        'deductible',
        'max',
        'nem',
        'premium',
        'rate',
        'description',
    ];

    get description(): string | null {
        return this._attributes['description'];
    }
    set description(val: string | null) {
        this._attributes['description'] = val;
    }

    get coverage(): number | null {
        return this._attributes['coverage'];
    }
    set coverage(val: number | null) {
        this._attributes['coverage'] = val;
    }

    get reimbursement(): number | null {
        return this._attributes['reimbursement'];
    }
    set reimbursement(val: number | null) {
        this._attributes['reimbursement'] = val;
    }

    get deductible(): number | null {
        return this._attributes['deductible'];
    }
    set deductible(val: number | null) {
        this._attributes['deductible'] = val;
    }

    get max(): number | null {
        return this._attributes['max'];
    }
    set max(val: number | null) {
        this._attributes['max'] = val;
    }

    get nem(): number | null {
        return this._attributes['nem'];
    }
    set nem(val: number | null) {
        this._attributes['nem'] = val;
    }

    get benefitTypeId(): number {
        return this._attributes['benefitTypeId'];
    }
    set benefitTypeId(val: number) {
        this._attributes['benefitTypeId'] = val;
    }

    get benefitId(): number {
        return this._attributes['benefitId'];
    }
    set benefitId(val: number) {
        this._attributes['benefitId'] = val;
    }

    get benefitType(): BenefitType {
        return this.hasOne(BenefitType, 'benefitType');
    }
    set benefitType(val: BenefitType) {
        this.setOne('benefitType', val, 'benefitTypeId');
    }
}
