import { Component, Input, ViewChild } from '@angular/core';
import { ButtonTypes } from '@app/components/platform/button/button.component';
import { DialogComponent } from '../dialog/dialog.component';
import { Sizes } from '@app/components/platform/dialog/dialog.types';
import { Translatable } from '@app/types/translatable.type';

@Component({
    selector: 'ui-confirmation-dialog',
    templateUrl: './confirmation-dialog.template.html',
})
export class ConfirmationDialogComponent {
    @ViewChild('confirm', { static: true }) confirm: DialogComponent;

    resolver: (shouldReturn: boolean) => void;

    @Input() primaryButtonLabel: Translatable | null = null;
    @Input() primaryButtonType: ButtonTypes = 'primary';
    @Input() secondaryButtonLabel: Translatable | null = null;
    @Input() secondaryButtonType: ButtonTypes = 'ghost';
    @Input() header: Translatable = '';
    @Input() size: Sizes = 'small';

    open(): Promise<boolean> {
        return new Promise((resolve) => {
            this.confirm.open = true;
            this.resolver = resolve;
        });
    }
}
