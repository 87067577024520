import moment from 'moment';
import { File } from '../common/file.model';
import { ModelMixin } from '../core/base-generic.model';
import { EmployeeDocument } from '../documents/employee-document.model';
import { Employee } from '../employee/employee.model';
import { TaskSubtask } from './task-subtask.model';
export class Task extends ModelMixin<Task>() {
    static managePermission = 'createAndApplyTasks';

    static assigneeTypeSubject = 'subject';
    static assigneeTypeManager = 'manager';
    static assigneeTypeSpecificEmployee = 'specific employee';

    protected static _resource = 'tasks';
    protected static _version = 'v2';
    protected static _serializeAttributes = [
        'assigneeId',
        'assigneeType',
        'subjectId',
        'fileId',
        'name',
        'description',
        'isAttachmentRequired',
        'dueOffset',
        'dueType',
        'dueRelation',
        'dueAt',
        'completedAt',
        'taskType',
        'attach',
        'detach',
    ];
    protected static _datetimes = ['completedAt'];
    protected static _dates = ['dueAt'];

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get taskTemplateId(): number {
        return this._attributes['taskTemplateId'];
    }

    get subjectId(): number {
        return this._attributes['subjectId'];
    }

    set subjectId(val: number) {
        this._attributes['subjectId'] = val;
    }

    get subject(): Employee {
        return this.hasOne(Employee, 'subject');
    }

    get assigneeId(): number {
        return this._attributes['assigneeId'];
    }

    set assigneeId(val: number) {
        this._attributes['assigneeId'] = val;
    }

    get assignee(): Employee {
        return this.hasOne(Employee, 'assignee');
    }

    set assignee(employee: Employee) {
        this.setOne('assignee', employee, 'assigneeId');
    }

    get assignees(): Employee[] {
        return this.hasMany(Employee, 'assignees');
    }

    get fileId(): number | null {
        return this._attributes['fileId'];
    }

    set fileId(val: number | null) {
        this._attributes['fileId'] = val;
    }

    get file(): File {
        return this.hasOne(File, 'file');
    }

    get filename(): string | null {
        return this.file ? this.file.name : this._attributes['filename'];
    }

    set filename(val: string | null) {
        this._attributes['filename'] = val;
    }
    get employeeDocument(): EmployeeDocument {
        return this.hasOne(EmployeeDocument, 'employeeDocument');
    }

    set employeeDocument(employeeDocument: EmployeeDocument) {
        this.setOne('employeeDocument', employeeDocument, 'employeeDocumentId');
    }

    get completedBy(): EmployeeDocument {
        return this.hasOne(Employee, 'completedBy');
    }

    get taskType(): string {
        return this._attributes['taskType'];
    }

    set taskType(val: string) {
        this._attributes['taskType'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get isAttachmentRequired(): boolean {
        return this._attributes['isAttachmentRequired'];
    }

    set isAttachmentRequired(val: boolean) {
        this._attributes['isAttachmentRequired'] = val;
    }

    get dueOffset(): number {
        return this._attributes['dueOffset'];
    }

    set dueOffset(val: number) {
        this._attributes['dueOffset'] = val;
    }

    get dueType(): string {
        return this._attributes['dueType'];
    }

    set dueType(val: string) {
        this._attributes['dueType'] = val;
    }

    get dueRelation(): string {
        return this._attributes['dueRelation'];
    }

    set dueRelation(val: string) {
        this._attributes['dueRelation'] = val;
    }

    get dueAt(): Date {
        return this._attributes['dueAt'];
    }

    set dueAt(val: Date) {
        this._attributes['dueAt'] = val;
    }

    get completedAt(): Date | null {
        return this._attributes['completedAt'];
    }

    set completedAt(val: Date | null) {
        this._attributes['completedAt'] = val;
    }

    get assigneeType(): string {
        return this._attributes['assigneeType'];
    }

    set assigneeType(val: string) {
        this._attributes['assigneeType'] = val;
    }

    get subtasks(): TaskSubtask[] {
        return this.hasMany(TaskSubtask, 'subtasks');
    }

    get totalSubtasksCount(): number {
        return this._attributes['totalSubtasksCount'] ?? 0;
    }

    get completedSubtasksCount(): number {
        return this._attributes['completedSubtasksCount'] ?? 0;
    }

    /**
     * @deprecated This method considers date and time, but due_at field is date only. Use isPastDueDay() instead.
     **/
    isPastDue(): boolean {
        return moment().isAfter(this.dueAt);
    }

    isPastDueDay(): boolean {
        return moment().isAfter(this.dueAt, 'day');
    }

    isComplete(): boolean {
        return !!this.completedAt;
    }

    toggleCompletion(): Promise<Date> {
        return Task.performPost(this.urlGetRoute('toggleCompletion')).then(
            (response) => response.data?.attributes?.completedAt
        );
    }

    updateFile(): Promise<void> {
        return Task.performPost(this.urlGetRoute('updateFile'), Task.serializer().serialize(this._attributes));
    }

    sendReminder(): Promise<void> {
        return Task.performPost(this.urlGetRoute('remind'));
    }

    private urlGetRoute(newSlug = ''): string {
        return Task._version + '/' + Task._resource + '/' + this.id + '/' + newSlug;
    }
}
