import { Pipe, PipeTransform } from '@angular/core';

/**
 * If the value is a number and has a decimal, ensure that it's 2 decimal places.
 * Otherwise, send it back as is.
 */
@Pipe({ name: 'maybeCurrency' })
export class MaybeCurrencyPipe implements PipeTransform {
    transform(value: any, _digitsInfo?: string, _locale?: string): any {
        const x = Number(value);

        if (Number.isNaN(x) || x % 1 === 0) {
            // No decimal places
            return value;
        }

        return x.toFixed(2);
    }
}
