import {
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { DialogConfig, DialogService } from '@app/services/dialog.service';
import { FeatureService } from '@app/services/feature.service';
import { Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { FeatureRequestForm } from './feature-request-form/feature-request-form';

declare let pendo: { feedback: { loginAndRedirect: ({ anchor }: { anchor: HTMLAnchorElement }) => void } } | undefined;

const PRODUCT_BOARD_DIALOG_CONFIG: DialogConfig = {
    header: 'sidebar.featureRequest.title',
    primaryButtonLabel: 'submit',
    primaryButtonDisabledTimeout: 5000,
    secondaryButtonLabel: 'cancel',
    secondaryButtonType: 'ghost',
};

@Component({
    selector: 'app-feature-request',
    styleUrls: ['./feature-request.style.scss'],
    templateUrl: './feature-request.template.html',
})
export class FeatureRequestComponent implements OnDestroy {
    @ViewChild('pendoAnchor', { static: true }) pendoAnchor!: ElementRef<HTMLAnchorElement>;
    @ViewChild('featureRequestDialogContent', { static: true }) featureRequestDialogContent!: TemplateRef<unknown>;
    @ViewChild(FeatureRequestForm, { static: false }) featureRequestForm!: FeatureRequestForm;

    shouldOpenProductBoardDialog = false;
    closing = new EventEmitter();
    opening = new EventEmitter();

    private _destroyed: Subject<null> = new Subject();

    constructor(
        private featureService: FeatureService,
        private viewContainerRef: ViewContainerRef,
        private dialogService: DialogService
    ) {
        this.featureService
            .has(FeatureFlag.productBoardFeatureRequestForm)
            .then((flagValue) => (this.shouldOpenProductBoardDialog = flagValue));
        this.closing.pipe(takeUntil(this._destroyed)).subscribe(this.onClose.bind(this));
        this.opening.pipe(takeUntil(this._destroyed)).subscribe(this.onOpen.bind(this));
    }

    ngOnDestroy(): void {
        this._destroyed.next();
    }

    onClose = (): void => {
        this.dialogService.close();
    };

    onOpen = (): void => {
        // The form has to be added to state AFTER the dialog opens, otherwise it will not be rendered yet and return undefined
        this.dialogService.state
            .pipe(takeWhile((state) => !state.form))
            .subscribe(() => this.dialogService.setForm(this.featureRequestForm.form));
    };

    requestFeature(): void {
        if (this.shouldOpenProductBoardDialog) {
            this.dialogService.setPortal(this.featureRequestDialogContent, this.viewContainerRef, {
                ...PRODUCT_BOARD_DIALOG_CONFIG,
                closing: this.closing,
                opening: this.opening,
            });
            this.dialogService.open();
        } else if (typeof pendo !== 'undefined') {
            pendo.feedback.loginAndRedirect({ anchor: this.pendoAnchor.nativeElement });
        }
    }
}
