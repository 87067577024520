import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, NgForm } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { DEFAULT_PRONOUNS_LIST } from '@app/constants/default-pronouns-list';
import { Pronoun, PronounsCollection } from '@app/interfaces/pronouns.interface';

const MAX_USER_DEFINED_PRONOUNS = 3;

const createEmptyNonUserDefinedPronoun = (): Pronoun => {
    return {
        userDefined: false,
        value: [],
    };
};

const createEmptyUserDefinedPronoun = (): Pronoun => {
    return {
        userDefined: true,
        value: [],
    };
};

const isSelectedPronounValueEqualToOriginalPronounValue = (
    selectedPronounValue: string[],
    originalPronounValue: string[]
): boolean => {
    return selectedPronounValue.every((value) => originalPronounValue.includes(value));
};

// If the pronoun does not exist in systemPronouns but it exists in selectedPronouns anymore, then add it
const filterPronounsToAdd = (
    systemPronouns: PronounsCollection,
    selectedPronouns: [{ [key: string]: string[] }]
): string[][] => {
    const pronounsToAdd = selectedPronouns.filter(
        (selectedPronoun) => !systemPronouns.some((systemPronoun) => systemPronoun.value === selectedPronoun?.value)
    );

    return pronounsToAdd.map((pronounToAdd) => pronounToAdd?.value);
};

// If the pronoun exists in systemPronouns but it does not exist in selectedPronouns anymore, then remove it
const filterPronounsToRemove = (
    systemPronouns: PronounsCollection,
    selectedPronouns: [{ [key: string]: string[] }]
): string[][] => {
    const pronounsToRemove = systemPronouns.filter(
        (systemPronoun) => !selectedPronouns.some((selectedPronoun) => selectedPronoun?.value === systemPronoun.value)
    );

    return pronounsToRemove.map((pronounToRemove) => pronounToRemove?.value);
};

@Component({
    selector: 'add-pronouns',
    templateUrl: './add-pronouns.template.html',
    styleUrls: ['./add-pronouns.style.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AddPronounsComponent implements OnInit {
    @Input() initialPronouns: PronounsCollection = [];

    @ViewChild('matSelect') matSelect;

    @Output() emitAllPronouns: EventEmitter<PronounsCollection> = new EventEmitter<PronounsCollection>();

    defaultPronounsList = DEFAULT_PRONOUNS_LIST;
    maxUserDefinedPronouns = MAX_USER_DEFINED_PRONOUNS;

    pronounsCollection: PronounsCollection;

    /**
     * System pronouns are the pronouns that are pre-defined pronouns
     * that can be selected via multi-select dropdown.
     */
    systemPronouns: PronounsCollection = [];
    userDefinedPronouns: PronounsCollection = [];

    pronounsSelectControl: FormControl<PronounsCollection | null> = new FormControl();

    ngOnInit(): void {
        this.populatePronouns();
    }

    compareWith(selectedPronoun: Pronoun | null, originalPronoun: Pronoun | null): boolean {
        if (!selectedPronoun || !originalPronoun) {
            return false;
        }
        return isSelectedPronounValueEqualToOriginalPronounValue(selectedPronoun.value, originalPronoun.value);
    }

    onPronounsSelect(event: MatSelectChange): void {
        const selectedPronouns: [{ [key: string]: string[] }] = event.value;

        const pronounsToAdd = filterPronounsToAdd(this.systemPronouns, selectedPronouns);
        const pronounsToRemove = filterPronounsToRemove(this.systemPronouns, selectedPronouns);

        // Add Pronouns
        pronounsToAdd.forEach((pronounToAdd) => {
            if (pronounToAdd) {
                const defaultPronounObject = createEmptyNonUserDefinedPronoun();

                defaultPronounObject.value = pronounToAdd;
                this.systemPronouns.push(defaultPronounObject);
            }
        });

        // Remove Pronouns
        pronounsToRemove.forEach((pronounToRemove) => {
            const index = this.systemPronouns.findIndex((systemPronoun) => systemPronoun.value === pronounToRemove);

            if (index > -1) {
                this.systemPronouns.splice(index, 1);
            }
        });

        this.mergeAndEmitPronouns();
    }

    setUserDefinedPronoun(value: string, userDefinedPronoun: Pronoun, index: number): void {
        userDefinedPronoun.value[index] = value;
        this.mergeAndEmitPronouns();
    }

    onAddUserDefinedPronoun(): void {
        // Close Mat-Select if the user selected ''My pronouns are...'
        this.matSelect.close();
        this.pronounsSelectControl.setValue(this.systemPronouns);

        if (this.userDefinedPronouns.length < MAX_USER_DEFINED_PRONOUNS) {
            this.userDefinedPronouns.push(createEmptyUserDefinedPronoun());
            this.mergeAndEmitPronouns();
        }
    }

    removeUserDefinedPronoun(index: number): void {
        this.userDefinedPronouns.splice(index, 1);
        this.mergeAndEmitPronouns();
    }

    private mergeAndEmitPronouns(): void {
        this.pronounsCollection = [...this.systemPronouns, ...this.userDefinedPronouns];

        this.emitAllPronouns.emit(this.pronounsCollection);
    }

    private populatePronouns(): void {
        if (!this.initialPronouns?.length) {
            return;
        }

        this.systemPronouns = this.initialPronouns.filter((pronoun: Pronoun) => !pronoun.userDefined);
        this.userDefinedPronouns = this.initialPronouns.filter((pronoun: Pronoun) => pronoun.userDefined);

        this.pronounsSelectControl.setValue(this.systemPronouns);
    }
}
