import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ordinalNumber' })
export class OrdinalNumberPipe implements PipeTransform {
    private readonly SUFFIXES = {
        'en-CA': new Map([
            ['one', 'st'],
            ['two', 'nd'],
            ['few', 'rd'],
            ['other', 'th'],
        ]),
        'fr-CA': new Map([
            ['one', 'er'],
            ['other', 'e'],
        ]),
    };

    transform(number: number, localeCode: 'en-CA' | 'fr-CA' = 'en-CA'): string {
        const pr = new Intl.PluralRules(localeCode, { type: 'ordinal' });

        const rule = pr.select(number);
        const suffix = this.SUFFIXES[localeCode]?.get(rule);

        return `${number}${suffix}`;
    }
}
