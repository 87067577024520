import { HolidayCalendarEvent } from '@models/time-off/holiday-calendar-event.model';
import { CalendarEvent } from '@models/common/calendar-event.model';
import moment, { Moment } from 'moment';
import { TimeOffRequestCalendarEvent } from '@app/models/time-off-v3/time-off-request-calendar-event.model';

export class Day {
    isToday: boolean;
    isWeekend: boolean;
    isOutOfYear: boolean;
    holiday: HolidayCalendarEvent;
    isHoliday = false;
    event: CalendarEvent | TimeOffRequestCalendarEvent;
    hasEvent = false;

    constructor(
        public date: Date,
        private referenceYear: number = null
    ) {
        const momentDate: Moment = moment(date);
        this.isToday = momentDate.isSame(moment(), 'd');

        const weekday = momentDate.format('dddd');
        const weekendDay = weekday === 'Sunday' || weekday === 'Saturday';
        this.isWeekend = weekendDay;
        this.isOutOfYear = Boolean(this.referenceYear && this.referenceYear !== moment(date).year());
    }

    makeHoliday(holiday: HolidayCalendarEvent): void {
        this.holiday = holiday;
        this.isHoliday = true;
    }

    injectEvent(event: CalendarEvent): void {
        this.event = event;
        this.hasEvent = true;
    }
}
