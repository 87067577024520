import { Observable, ReplaySubject, Subject } from 'rxjs';

export abstract class ReactiveService {
    protected changes = new Subject<null>();
    protected loading = new ReplaySubject<boolean>();

    onChanges(): Observable<null> {
        return this.changes.asObservable();
    }

    onLoading(): Observable<boolean> {
        return this.loading.asObservable();
    }
}
