import { Model } from '../core/base.model';

export class AsyncSystemTask extends Model {
    protected static _type = 'asyncSystemTasks';
    protected static _resource = 'asyncSystemTasks';
    protected static _serializeAttributes = ['readAt'];
    protected static _datetimes = ['createdAt', 'readAt', 'completedAt', 'failedAt'];

    get name(): string {
        return this._attributes['name'];
    }

    get link(): string {
        return this._attributes['link'];
    }

    get readAt(): string | Date {
        return this._attributes['readAt'];
    }

    get completedAt(): Date {
        return this._attributes['completedAt'];
    }

    get failedAt(): Date {
        return this._attributes['failedAt'];
    }

    set readAt(val: string | Date) {
        this._attributes['readAt'] = val;
    }

    isFinished(): boolean {
        return this.isComplete() || this.isFailed();
    }

    isComplete(): boolean {
        return !!this.completedAt;
    }

    isFailed(): boolean {
        return !!this.failedAt;
    }
}
