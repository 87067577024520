import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { DragService } from '@app/services';
import { DroppableEventObject } from '@app/interfaces';

/**
 * Accept data being dragged from an AppDraggable component.
 *
 * Use `onDroppableComplete` to handle submitted data from the drag/drop action.
 */
@Directive({
    selector: '[appDroppable]',
})
export class DroppableDirective implements OnInit, OnDestroy, OnChanges {
    @Output() public onDroppableComplete: EventEmitter<DroppableEventObject> = new EventEmitter();

    @Input() dropZone = DragService.DEFAULT_ZONE;
    @Input() dropEnabled = true;
    @Input() dragTargetClass = 'app-drop-target';

    private onDragEnter;
    private onDragLeave;
    private onDragOver;
    private onDrop;

    /**
     * Cache last element we dragged into, we ignore all "leave" events until this element
     * triggers the leave - this avoid child elements triggering leave.
     */
    private lastHoveredElement: EventTarget;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private service: DragService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dropEnabled && changes.dropEnabled.currentValue !== changes.dropEnabled.previousValue) {
            // update draggable functionality based on isEnabled or not
            changes.dropEnabled.currentValue ? this.setupDroppable() : this.teardownDroppable();
        }
    }

    ngOnInit(): void {
        this.setupDroppable();
    }

    ngOnDestroy(): void {
        this.teardownDroppable();
    }

    private teardownDroppable(): void {
        this.renderer.removeClass(this.elementRef.nativeElement, 'app-droppable');
        this.onDragEnter ? this.onDragEnter() : null;
        this.onDragLeave ? this.onDragLeave() : null;
        this.onDragOver ? this.onDragOver() : null;
        this.onDrop ? this.onDrop() : null;
    }

    private setupDroppable(): void {
        if (!this.dropEnabled) {
            return;
        }
        this.onDragEnter = this.renderer.listen(
            this.elementRef.nativeElement,
            'dragenter',
            (event: DragEvent): void => {
                this.handleDragEnter(event);
            }
        );
        this.onDragLeave = this.renderer.listen(
            this.elementRef.nativeElement,
            'dragleave',
            (event: DragEvent): void => {
                this.handleDragLeave(event);
            }
        );

        this.onDragOver = this.renderer.listen(this.elementRef.nativeElement, 'dragover', (event: DragEvent): void => {
            this.handleDragOver(event);
        });
        this.onDrop = this.renderer.listen(this.elementRef.nativeElement, 'drop', (event: DragEvent): void => {
            this.handleDrop(event);
        });
    }

    private handleDragEnter(event: DragEvent): void {
        this.lastHoveredElement = event.target;

        if (this.service.accepts(this.dropZone)) {
            event.dataTransfer.dropEffect = 'move';
            this.renderer.addClass(this.elementRef.nativeElement, this.dragTargetClass);
            event.preventDefault();
        }
    }

    private handleDragLeave(event: DragEvent): void {
        // ignore leave events which have an unknown target, probably child elements
        if (this.lastHoveredElement !== event.target) {
            return;
        }
        if (this.service.accepts(this.dropZone)) {
            this.renderer.removeClass(this.elementRef.nativeElement, this.dragTargetClass);
        }
    }

    private handleDragOver(event: DragEvent): void {
        if (this.service.accepts(this.dropZone)) {
            event.preventDefault();
        }
    }

    private handleDrop(event: DragEvent): void {
        if (this.service.accepts(this.dropZone)) {
            this.renderer.removeClass(this.elementRef.nativeElement, this.dragTargetClass);

            const key = event.dataTransfer.getData('Text');
            const data = this.service.getData(key);
            this.onDroppableComplete.emit({
                data: data,
            });
            this.service.removeDraggedZone();
            event.preventDefault();
        }
    }
}
