import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Translatable } from '@app/types/translatable.type';

export interface DragAndDropOption {
    value: string;
    orderBy: number;
    shouldConfirmBeforeRemove: boolean;
}

@Component({
    selector: 'app-drag-and-drop-with-deletable-options',
    templateUrl: './drag-and-drop-with-deletable-options.template.html',
    styleUrls: ['./drag-and-drop-with-deletable-options.style.scss'],
})
export class DragAndDropWithDeletableOptionsComponent<T extends DragAndDropOption> implements OnInit {
    /**
     * This is the attribute which can be used to store the value in the input field.
     * By default, its value but it can be passed on from the parent component as well.
     */
    @Input() optionAttributeName = 'value';

    /**
     * Each option needs to contain the following properties:
     *  - order_by
     *
     *  We need an empty option in order to easily add a new model on click of add button.
     */
    @Input() emptyOption: T;
    @Input() options: T[] = [];
    @Input() maxOptions = 0;
    @Input() addButtonLabel?: Translatable = 'add';
    @Input() addButtonType?: string = 'link';
    @Input() hideRemoveOnOneItem = false;
    @Input() addBorder = false;
    @Output() optionsChange: EventEmitter<T[]> = new EventEmitter<T[]>();
    @Output() optionRemoved: EventEmitter<number> = new EventEmitter<number>();
    @ViewChildren('optionInputs') optionInputs: QueryList<ElementRef>;

    ngOnInit(): void {
        if (!this.options.length) {
            this.onAdd();
        }
    }

    /*
     * Add a new option if the user hits enter on the last input field
     */
    addOnEnter($event: KeyboardEvent, index: number): void {
        if (this.options.length >= this.maxOptions) {
            return;
        }

        const { key } = $event;
        const isLastField = this.options.length - 1 === index;
        const hasValue = this.optionHasValue(this.options[index]);
        const canAdd = key === 'Enter' && isLastField && hasValue;

        if (!canAdd) {
            return;
        }

        this.onAdd();
    }

    showRemove(): boolean {
        if (this.hideRemoveOnOneItem && this.options.length === 1) {
            return false;
        }

        return true;
    }

    onOptionOrderChange(event: CdkDragDrop<string[]>): void {
        const updatedOptions = [...this.options];
        moveItemInArray(updatedOptions, event.previousIndex, event.currentIndex);

        this.options = [...updatedOptions];

        this.resetOrderOfOptions();
        this.emitOptions();
    }

    onAdd(): void {
        if (this.options.length > this.maxOptions) {
            return;
        }

        const emptyOption = this.emptyOption;
        emptyOption.orderBy = this.options.length;

        this.options = [...this.options, emptyOption];

        this.emitOptions();
        this.focusOnNewInput();
    }

    onRemove(index: number): void {
        this.optionRemoved.emit(index);
    }

    emitOptions(): void {
        this.optionsChange.emit(this.options);
    }

    focusOnNewInput(): void {
        setTimeout(() => {
            this.optionInputs.last.nativeElement.focus();
        });
    }

    private resetOrderOfOptions(): void {
        this.options.forEach((option, index) => {
            option.orderBy = index;
        });
    }

    private optionHasValue(option: T): boolean {
        return (
            option[this.optionAttributeName] !== '' &&
            option[this.optionAttributeName] !== null &&
            option[this.optionAttributeName] !== undefined
        );
    }
}
