import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@app/classes';
import { Employee } from '@app/models/employee/employee.model';
import { JobPosting } from '@app/models/recruiting/job-posting.model';
import { HiringAccessLevelIdsEnum } from '@app/modules/applicant-tracker/enums/hiring-access-levels.enum';
import { HiringTeamMember } from '@app/modules/applicant-tracker/interfaces/hiring-team-member.interface';
import { AuthService } from '@app/services';
import { BulkSelectEmployeeDialogComponent } from '../dialogs/bulk-select-employee-dialog/bulk-select-employee-dialog.component';

@Component({
    selector: 'app-manage-hiring-team',
    templateUrl: './manage-hiring-team.template.html',
    styleUrls: ['./manage-hiring-team.style.scss'],
})
export class ManageHiringTeamComponent {
    @Input() employees: Employee[] = [];
    @Input() jobPostingCreationMode = false;
    @Output() selectionUpdated: EventEmitter<HiringTeamMember[]> = new EventEmitter<HiringTeamMember[]>();
    @Output() editAction: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('employeeSelect', { static: true }) employeeSelect: BulkSelectEmployeeDialogComponent;

    jobPosting: JobPosting | null;
    hiringAccessLevelIdsEnum = HiringAccessLevelIdsEnum;
    hiringTeamMembers: HiringTeamMember[] = [];

    // This list will be used for a diff
    recruitingRecruiters: number[] = [];
    jobCreator = new Employee();

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute
    ) {
        this.jobPosting = this.route.snapshot.data.models?.get('jobPosting');
    }

    async ngOnInit(): Promise<void> {
        this.jobCreator = this.employees[0];

        this.employeeSelect.allowRemoval = false;

        this.sortByAccessLevel(this.employees);

        const [employees] = await Employee.param('company', this.auth.company.id)
            .whereIn('permissions', [Platform.recruiting.permission.recruiter])
            .whereIn('status', ['onboarding', 'active'])
            .all();

        this.recruitingRecruiters = employees.map((employee: Employee) => {
            return employee.id;
        });

        this.hiringTeamMembers = this.employees.map((employee: Employee) => {
            return { employee, onSelection: false };
        });
    }

    onManageHiringTeam(): void {
        this.employeeSelect.show(this.employees).then((employees: Employee[]) => {
            if (!employees?.length) {
                return;
            }

            const creatorIndex = employees.findIndex((employee) => employee.id === this.jobCreator?.id);

            // If it exists
            if (creatorIndex !== -1) {
                employees.unshift(employees.splice(creatorIndex, 1)[0]);
            }

            employees
                .filter((employee: Employee) => employee.id !== this.auth?.employee?.id)
                .forEach((employee: Employee) => {
                    if (this.recruitingRecruiters.includes(employee.id)) {
                        employee.accessLevelId = this.hiringAccessLevelIdsEnum.recruiter;
                        return;
                    }

                    if (this.employees.some((selectedEmployee: Employee) => employee.id === selectedEmployee.id)) {
                        return;
                    }

                    employee.accessLevelId = this.hiringAccessLevelIdsEnum.basic;
                });

            this.sortByAccessLevel(employees);

            this.employees = employees;

            this.hiringTeamMembers = this.employees.map((employee: Employee) => {
                return { employee, onSelection: false };
            });

            this.editAction.emit(true);
            this.selectionUpdated.emit(this.hiringTeamMembers);
        });
    }

    onSelectionChange(hiringTeamMember: HiringTeamMember): void {
        hiringTeamMember.onSelection = true;
        this.editAction.emit(true);
        this.selectionUpdated.emit(this.hiringTeamMembers);
    }

    onRemove(index: number): void {
        this.editAction.emit(true);
        this.hiringTeamMembers.splice(index, 1);
        this.selectionUpdated.emit(this.hiringTeamMembers);
    }

    canDeleteJobPostingCreator(hiringTeamMember: HiringTeamMember, index: number): boolean {
        if (this.jobPostingCreationMode) {
            //when creating a job posting by default the creator is on the top of the list
            //and that entry should not be deleted. Others can be freely deleted
            return index !== 0;
        }

        return hiringTeamMember.employee.id !== this.jobPosting?.createdBy;
    }

    // Custom sort to ensure recruiter access users are on top.
    private sortByAccessLevel(employees: Employee[]): void {
        employees.sort((a: Employee, b: Employee) => {
            if (a.accessLevelId === null && b.accessLevelId !== null) {
                return -1;
            }

            if (a.accessLevelId !== null && b.accessLevelId === null) {
                return 1;
            }

            if (a.accessLevelId === null && b.accessLevelId === null) {
                return 0;
            }
        });
    }
}
