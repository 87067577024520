import { Model } from '../core/base.model';
import { SimpleEntityActions } from '@app/classes';
import { Employee } from '../employee/employee.model';

export const SUPPORTED_DOCUMENT_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'gif'];

export class File extends Model {
    static permission = new SimpleEntityActions('file');
    protected static _resource = 'commonComponents/files';

    protected static _version = 'v2';

    get fileType(): string | null {
        return this._attributes['fileType'] || null;
    }

    get type(): string | null {
        return this._attributes['type'] || null;
    }

    get url(): string | null {
        return this._attributes['url'] || null;
    }

    get name(): string {
        return this._attributes['name'];
    }

    get filename(): string {
        return this._attributes['filename'];
    }

    get uploadedBy(): Employee {
        return this.hasOne(Employee, 'uploadedBy');
    }

    /**
     * isSupportedImageType checks the extension of the file
     * AS GIVEN BY THE CLIENT, and determines if this file
     * is ok to show.
     *
     * While this isn't dangerous, it's also not great.
     */
    get isSupportedImageType(): boolean {
        if (!this.type) {
            return false;
        }

        // We allow users to store files with extensions that are uppercase.
        const lowerCaseType = this.type.toLowerCase();

        return SUPPORTED_DOCUMENT_IMAGE_TYPES.includes(lowerCaseType);
    }
}
