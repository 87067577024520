import { ModelMixin } from '../core/base-generic.model';

export class FunnelTemplate extends ModelMixin<FunnelTemplate>() {
    protected static _resource = 'applicantTracker/funnelTemplates';
    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _serializeAttributes = ['name'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get hasEmailAutomation(): boolean {
        return this._attributes['hasEmailAutomation'];
    }

    get funnelTemplateStagesCount(): number {
        return this._attributes['funnelTemplateStagesCount'];
    }
}
