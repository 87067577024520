import {
    Component,
    Input,
    SimpleChanges,
    EventEmitter,
    Output,
    ViewChild,
    OnChanges,
    AfterViewInit,
} from '@angular/core';
import {
    DateRange,
    MatRangeDateSelectionModel,
    DefaultMatCalendarRangeStrategy,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
    MatCalendar,
} from '@angular/material/datepicker';
import { Moment } from 'moment';
import moment from 'moment';
import { ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ui-inline-date-picker',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
    template: `<mat-calendar
        #calendar
        [dateFilter]="dateFilter"
        [selected]="dateRange"
        [headerComponent]="headerComponent"
        (selectedChange)="rangeChanged($event)"
    ></mat-calendar> `,
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
        DefaultMatCalendarRangeStrategy,
        MatRangeDateSelectionModel,
    ],
})
export class InlineDatePickerComponent implements OnChanges, AfterViewInit {
    @Input() startDate: Moment | Date;
    @Input() endDate: Moment | Date;
    @Input() startAt: Moment | Date;
    @Input() headerComponent: string | null = null;
    @Input() canMakeSelection = true;
    @Input() dateFilter: (date: Moment) => boolean;
    @Output() dateRangesChange = new EventEmitter<DateRange<Moment>>();
    @Output() startDateSelectedChange = new EventEmitter<Moment>();
    @ViewChild('calendar') _calendar: MatCalendar<Moment>;

    dateRange: DateRange<Moment>;

    constructor(
        private readonly selectionModel: MatRangeDateSelectionModel<Moment>,
        private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Moment>
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if ('startDate' in changes) {
            this.dateRange = new DateRange<Moment>(moment(changes.startDate.currentValue), this.dateRange?.end);
        }

        if ('endDate' in changes) {
            this.dateRange = new DateRange<Moment>(this.dateRange?.start, moment(changes.endDate.currentValue));
        }
    }

    ngAfterViewInit(): void {
        if (this.startAt) {
            this.goToDate(moment(this.startAt), 'month');
        }
    }

    goToDate(date: Moment, view: 'month' | 'year' | 'multi-year'): void {
        this._calendar._goToDateInView(date, view);
    }

    rangeChanged(selectedDate: Moment): void {
        if (!this.canMakeSelection) {
            return;
        }

        const selection = this.selectionModel.selection;
        const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);

        this.selectionModel.updateSelection(newSelection, this);
        this.dateRange = new DateRange<Moment>(newSelection.start, newSelection.end);

        if (this.selectionModel.isComplete()) {
            this.dateRangesChange.emit(this.dateRange);
            return;
        }

        // If the range isn't completed, a new start date was selected.
        this.startDateSelectedChange.emit(this.dateRange.start);
    }
}

/** Custom header component for datepicker. */
@Component({
    selector: 'calendar-empty-header',
    styles: [],
    template: ` <div></div> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarEmptyHeader<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    constructor(
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef
    ) {
        _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get periodLabel() {
        return this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    previousClicked(mode: 'month' | 'year') {
        this._calendar.activeDate =
            mode === 'month'
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }

    nextClicked(mode: 'month' | 'year') {
        this._calendar.activeDate =
            mode === 'month'
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
}
