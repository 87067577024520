import { SimpleEntityActions } from '@app/classes';
import { File } from '../common/file.model';
import { ModelMixin } from '../core/base-generic.model';
import { OfferLetter } from '../offer-letters/offer-letter.model';
import { Answer } from './answer.model';
import { ConnectEmployeeLink } from './connect-employee-link.model';
import { JobFunnel } from './job-funnel.model';
import { JobPosting } from './job-posting.model';

export class Applicant extends ModelMixin<Applicant>() {
    get id(): number {
        const id = +this._attributes['id'] || null;
        return id;
    }

    set id(val: number) {
        this._attributes['id'] = val;
    }

    /* Timestamps */
    get createdAt(): any {
        return this._attributes['createdAt'];
    }

    get updatedAt(): any {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): any {
        return this._attributes['deletedAt'] || null;
    }

    get archivedAt(): any {
        return this._attributes['archivedAt'] || null;
    }

    set archivedAt(value: any) {
        this._attributes['archivedAt'] = value;
    }

    get archivedBy(): number {
        return this._attributes['archivedBy'] || null;
    }

    set archivedBy(value: number) {
        this._attributes['archivedBy'] = value;
    }

    get isArchived(): boolean {
        return this._attributes['isArchived'] || null;
    }

    set isArchived(value: boolean) {
        this._attributes['isArchived'] = value;
    }

    get isHired(): boolean {
        return this._attributes['isHired'] || null;
    }

    get showMessages(): boolean {
        return this._attributes['showMessages'];
    }

    set showMessages(value: boolean) {
        this._attributes['showMessages'] = value;
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get firstName(): string {
        return this._attributes['firstName'] || '';
    }

    set firstName(value: string) {
        this._attributes['firstName'] = value;
    }

    get lastName(): string {
        return this._attributes['lastName'] || '';
    }

    set lastName(value: string) {
        this._attributes['lastName'] = value;
    }

    get rating(): number {
        return this._attributes['rating'] || 0;
    }

    set rating(value: number) {
        this._attributes['rating'] = value;
    }

    get referrer(): string {
        return this._attributes['referer'];
    }

    set referrer(value: string) {
        this._attributes['referer'] = value;
    }

    get email(): string {
        return this._attributes['email'];
    }

    set email(value: string) {
        this._attributes['email'] = value;
    }

    get phone(): string {
        return this._attributes['phone'];
    }

    set phone(value: string) {
        this._attributes['phone'] = value;
    }

    get country(): string {
        return this._attributes['country'];
    }

    set country(value: string) {
        this._attributes['country'] = value;
    }

    get postalCode(): string {
        return this._attributes['postalCode'];
    }

    set postalCode(value: string) {
        this._attributes['postalCode'] = value;
    }

    get job(): JobPosting {
        return this.jobPosting;
    }

    get jobPosting(): JobPosting {
        return this.hasOne(JobPosting, 'jobPosting');
    }

    set jobPosting(value: JobPosting) {
        this.setOne('jobPosting', value, 'jobPostingId');
    }

    get applicantId(): number {
        return this._attributes['atsApplicantId'];
    }

    get jobPostingId(): number {
        if (this.jobPosting) {
            return this.jobPosting.id;
        }

        return this._attributes['atsJobId'];
    }

    set jobPostingId(value: number) {
        this._attributes['atsJobId'] = value;
    }

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get answers(): Answer[] {
        return this.hasMany(Answer, 'answers');
    }

    get jobFunnel(): JobFunnel {
        return this.hasOne(JobFunnel, 'jobFunnel');
    }

    get jobFunnelId(): number {
        return this._attributes['atsJobFunnelId'];
    }

    set jobFunnelId(value: number) {
        this._attributes['atsJobFunnelId'] = value;
    }

    get about(): string {
        return this._attributes['about'];
    }

    set about(value: string) {
        this._attributes['about'] = value;
    }

    get funnelStatus(): number {
        return this._attributes['funnelStatus'];
    }

    set funnelStatus(value: number) {
        this._attributes['funnelStatus'] = value;
    }

    get funnelId(): number {
        return this._attributes['atsJobFunnelId'];
    }

    set funnelId(value: number) {
        this._attributes['atsJobFunnelId'] = value;
    }

    get resume(): File {
        return this.hasOne(File, 'resume');
    }

    set resume(value: File) {
        this.setOne('resume', value, 'resumeId');
    }

    get offerLetter(): OfferLetter {
        return this.hasOne(OfferLetter, 'offerLetter');
    }

    get connectEmployeeLink(): ConnectEmployeeLink {
        return this.hasOne(ConnectEmployeeLink, 'connectEmployeeLink');
    }

    set connectEmployeeLink(value: ConnectEmployeeLink) {
        this.setOne('connectEmployeeLink', value);
    }

    get applicationSource(): string {
        return this._attributes['applicationSource'];
    }

    get autoScoreMatches(): string[] | null {
        return this._attributes['autoScoreMatches'];
    }

    get nextApplicantId(): number {
        return this._attributes['nextApplicantId'];
    }

    get previousApplicantId(): number {
        return this._attributes['previousApplicantId'];
    }

    protected static _resource = 'applicantTracker/applicants';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'atsJobId',
        'letterId',
        'atsJobFunnelId',
        'rating',
        'firstName',
        'lastName',
        'role',
        'email',
        'phone',
        'country',
        'postalCode',
        'about',
        'source',
        'funnelStatus',
        'isArchived',
        'archivedBy',
        'archivedAt',
        'autoScoreMatches',
        'referer',
        'showMessages',
    ];

    static permission = new SimpleEntityActions('applicant');
}
