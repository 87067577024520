import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { Subject } from 'rxjs';
import { Settings, TimeTrackingSettingsResponse } from '../interfaces/settings.interface';
import { TimeTrackingResources } from '../time-tracking.resource';

@Injectable({
    providedIn: 'root',
})
export class TimeTrackingSettingsService {
    public subject: Subject<null> = new Subject();
    private cachedSettings: Settings | null = null;
    private getRequestPromise: Promise<TimeTrackingSettingsResponse> | null = null;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {
        this.authService.onLogout.subscribe(() => {
            this.getRequestPromise = null;
            this.cachedSettings = null;
        });
    }

    /**
     * Set company settings
     */
    async set(settings: Omit<Settings, 'counts'>): Promise<void> {
        const payload = {
            data: {
                type: 'timeTracking.settings',
                attributes: {
                    settings,
                },
            },
        };

        const url = TimeTrackingResources.Settings;
        await this.http.put(url, payload).toPromise();

        // invalidate cached settings
        this.cachedSettings = null;
    }

    /**
     * Get company settings
     */
    async get(): Promise<Settings> {
        if (this.cachedSettings) {
            // deep clone to avoid changes to reference
            return JSON.parse(JSON.stringify(this.cachedSettings));
        }

        let response: TimeTrackingSettingsResponse;
        try {
            if (!this.getRequestPromise) {
                this.getRequestPromise = this.http
                    .get<TimeTrackingSettingsResponse>(TimeTrackingResources.Settings)
                    .toPromise();
            }
            response = await this.getRequestPromise;
        } catch (error: unknown) {
            // Employee does not have access to the time tracking module
            // Instead of throwing a system error for the 403 Forbidden response,
            // return an empty settings object to gracefully handle this case
            const httpError = error as { status?: number };
            if (httpError?.status === 403) {
                return {};
            }
            throw error; // Re-throw any other errors
        }

        if (this.cachedSettings) {
            // deep clone to avoid changes to reference
            // identical cache code to above which might look strange, but it's to handle simultaneous requests, JS tick hack
            return JSON.parse(JSON.stringify(this.cachedSettings));
        }

        const settings = this.convertResponseToSettings(response);
        this.cachedSettings = settings;
        this.getRequestPromise = null;

        return settings;
    }

    /**
     * invalidate provides a way of telling the service that an external change
     * has made the settings no longer valid.
     *
     * Ex: something changed a count
     */
    invalidate(): void {
        this.cachedSettings = null;
        this.subject.next();
    }

    private convertResponseToSettings(response: TimeTrackingSettingsResponse): Settings {
        const settingsData = response.data.attributes.settings;
        const settings: Settings = { ...settingsData };

        // Some default values
        // Also, we coerce the string "" to false on boolean columns
        settings.noProjectApprovalFlow = settingsData.noProjectApprovalFlow ?? 'manager';
        settings.remindersWeeklyDeadline =
            settingsData.remindersWeeklyDeadline && Number(settingsData.remindersWeeklyDeadline) !== 0 ? true : false;
        settings.payrollIntegration =
            settingsData.payrollIntegration && Number(settingsData.payrollIntegration) !== 0 ? true : false;
        settings.allowFutureEntries =
            settingsData.allowFutureEntries && Number(settingsData.allowFutureEntries) !== 0 ? true : false;
        settings.timeTrackingMethod = settingsData.timeTrackingMethod ?? 'hours';
        settings.allowTimeClockRounding =
            settingsData.allowTimeClockRounding && Number(settingsData.allowTimeClockRounding) !== 0 ? true : false;
        settings.timeClockRoundingMode = settings.allowTimeClockRounding
            ? (settingsData.timeClockRoundingMode ?? 'nearest')
            : null;
        settings.timeClockRoundingMinutes = settings.allowTimeClockRounding
            ? Number(settingsData.timeClockRoundingMinutes ?? 15)
            : null;
        return settings;
    }
}
