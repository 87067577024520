import { FilterValueChange } from '@app/modules/table/interfaces/filter-value-change.interface';
import { ReplaySubject } from 'rxjs';
import { ColumnFilterDisplay } from '../types/column-filter-display.type';
import { TableFilterDisplay } from '../types/table-filter-display.type';

/**
 * Base class for filters on tables.
 *
 * This class has two immediate children which should be extended if implementing new filters:
 *  1. TableFilter - lives in the table header
 *  2. ColumnFilter - lives in the column's header
 *
 * While both are mostly similar, their respective display components do have some different
 * behaviour.
 *
 * Ultimately, the goal is to stop using column filters altogether and move to just separate
 * filter lists that live outside the table, and at that point the entire inheritance hierarchy
 * will be unified.
 */
export abstract class Filter {
    /**
     * Indicates whether or not the filter class is ready to be interacted with
     * For example, all the potential filter values are loaded and formatted and good to go.
     */
    isReady = false;

    // The string key for the type of filter. Can be used later by TypeScript for proper type casting
    type: ColumnFilterDisplay | TableFilterDisplay = 'select';

    // Optional label, populated differently per filter, used in the multi-filter component
    label?: string;

    /**
     * Push all value changes to this subject. Subscribers can then update their values.
     * For example, a dropdown can push when a new item is selected and a table will append it to a query search.
     */
    onChanges: ReplaySubject<FilterValueChange[]> = new ReplaySubject<FilterValueChange[]>();

    /**
     * Whether or not this filter is clearable
     */
    isClearable(): boolean {
        return this.hasValues();
    }

    /**
     * If the filter has any values applied.
     */
    abstract hasValues(): boolean;

    /**
     * A method to clear the filter's selection
     */
    abstract clear(): void;
}
