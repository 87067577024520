import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { format, parse } from 'date-fns';
import moment from 'moment';

@Component({
    selector: 'app-filter-date',
    templateUrl: './app-filter-date.template.html',
    styleUrls: ['./app-filter-date.style.scss'],
})
export class AppFilterDateComponent implements OnInit {
    @Input() startAt: string | Date;
    @Input() endAt: string | Date;
    @Output() filter: EventEmitter<any> = new EventEmitter<any>();

    rangeStart: any;
    rangeEnd: any;

    constructor() {}

    ngOnInit(): void {
        this.setInitialRange();
    }

    onChange(date: string, rangeStart: boolean): void {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        rangeStart ? (this.rangeStart = formattedDate) : (this.rangeEnd = formattedDate);
        this.filter.emit([this.rangeStart, this.rangeEnd]);
    }

    clear(): void {
        this.rangeStart = null;
        this.rangeEnd = null;
        this.filter.emit([this.rangeStart, this.rangeEnd]);
    }

    /**
     * Sets the initial start and end dates if they are provided
     *
     * Dates and strings are accepted
     */
    private setInitialRange(): void {
        if (this.startAt) {
            this.rangeStart = format(parse(this.startAt), 'YYYY-MM-DD');
        }

        if (this.endAt) {
            this.rangeEnd = format(parse(this.endAt), 'YYYY-MM-DD');
        }
    }
}
