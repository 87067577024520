import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Translatable } from '@app/types/translatable.type';

@Component({
    selector: 'app-deprecated-confirmation-dialog',
    templateUrl: './deprecated-confirmation-dialog.template.html',
    styleUrls: ['./deprecated-confirmation-dialog.style.scss'],
})
export class DeprecatedConfirmationDialogComponent {
    @ViewChild('modal', { static: true }) modal: ElementRef;
    @Input() closable = true;
    @Input() description: Translatable = 'components.deprecated-confirmation-dialog.description';
    @Input() useNgContent = false;
    @Input() header: Translatable = 'components.deprecated-confirmation-dialog.header';
    @Input() label: Translatable = 'components.deprecated-confirmation-dialog.label';
    @Input() cancelLabel: Translatable = 'components.deprecated-confirmation-dialog.cancelLabel';
    @Input() negative = false;
    @Input() disableAction = false;

    show(): Promise<boolean> {
        return new Promise((resolve) => {
            setTimeout(() => {
                $(this.modal.nativeElement)
                    .modal({
                        detachable: false,
                        autofocus: false,
                        duration: 0,
                        closable: this.closable,
                        selector: {
                            approve: '.approve-button',
                            deny: '.deny-button',
                        },
                        onVisible: () => {
                            $(this.modal.nativeElement).animate({ opacity: 1 }, 250);
                        },
                        onHide: () => {
                            $(this.modal.nativeElement).find('.ui.modal').animate({ opacity: 0 }, 250);
                        },
                        onApprove: () => {
                            resolve(true);
                        },
                        onDeny: () => {
                            resolve(false);
                        },
                    })
                    .modal('show');
            });
        });
    }
}
