<mat-accordion>
    <!--Department-->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'components.employee-criteria-selection.department' | appTranslate }}
                <!--count-->
                <ng-container>
                    <span *ngIf="selectedDepartments.length > 0" class="counter">
                        {{ selectedDepartments.length }}
                    </span>
                </ng-container>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="flex-container content">
            <app-group-checkboxes
                name="selectedDepartments"
                [entities]="allDepartments"
                [(ngModel)]="selectedDepartments"
                (selectionChange)="onSelectionChange()"
            ></app-group-checkboxes>
        </div>
    </mat-expansion-panel>

    <!--Office-->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'components.employee-criteria-selection.office' | appTranslate }}
                <!--count-->
                <ng-container>
                    <span *ngIf="selectedOffices.length > 0" class="counter"> {{ selectedOffices.length }} </span>
                </ng-container>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="flex-container content">
            <app-group-checkboxes
                name="selectedOffices"
                [entities]="allOffices"
                [(ngModel)]="selectedOffices"
                (selectionChange)="onSelectionChange()"
            ></app-group-checkboxes>
        </div>
    </mat-expansion-panel>

    <!--Employment status-->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'components.employee-criteria-selection.employmentType' | appTranslate }}
                <!--count-->
                <ng-container>
                    <span *ngIf="selectedEmploymentTypes.length > 0" class="counter">
                        {{ selectedEmploymentTypes.length }}
                    </span>
                </ng-container>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="flex-container content">
            <app-group-checkboxes
                name="selectedEmploymentTypes"
                [entities]="allEmploymentTypes"
                [(ngModel)]="selectedEmploymentTypes"
                (selectionChange)="onSelectionChange()"
            ></app-group-checkboxes>
        </div>
    </mat-expansion-panel>

    <!--Job Title--->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'components.employee-criteria-selection.position' | appTranslate }}
                <!--count-->
                <ng-container>
                    <span *ngIf="selectedJobs.length > 0" class="counter"> {{ selectedJobs.length }} </span>
                </ng-container>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="flex-container content">
            <app-group-checkboxes
                name="selectedJobs"
                [entities]="allJobs"
                [labelProperty]="'title'"
                [(ngModel)]="selectedJobs"
                (selectionChange)="onSelectionChange()"
            ></app-group-checkboxes>
        </div>
    </mat-expansion-panel>
</mat-accordion>
