<app-common-bulk-select-employee-dialog
    #employeeSelect
    [label]="'components.manage-hiring-team.assign'"
></app-common-bulk-select-employee-dialog>

<!-- Manage Hiring Team -->
<ui-card>
    <div class="flex justify-space-between align-items-center">
        <h2 class="margin-bottom-2">{{ 'components.manage-hiring-team.title' | appTranslate }}</h2>
        <ui-button (click)="onManageHiringTeam()" class="margin-bottom-2" type="link">{{
            'components.manage-hiring-team.addMember' | appTranslate
        }}</ui-button>
    </div>

    <p
        class="margin-bottom-6"
        [innerHTML]="'components.manage-hiring-team.addAndRemoveEmployeesHiringTeam' | appTranslate"
    ></p>
    <div class="app-grid">
        <p class="columns-6 access-text">{{ 'components.manage-hiring-team.employeeName' | appTranslate }}</p>
        <p class="columns-5 access-text">{{ 'components.manage-hiring-team.accessLevel' | appTranslate }}</p>
    </div>
    <mat-divider></mat-divider>
    <ng-container *ngIf="employees.length; else empty">
        <ng-container *ngFor="let hiringTeamMember of hiringTeamMembers; let i = index">
            <div class="app-grid centered margin-bottom-2 margin-top-2" style="text-align: left">
                <p class="columns-2 full-name">{{ hiringTeamMember.employee.fullName }}</p>
                <p class="access-text columns-4 margin-top-2">
                    <ng-container
                        *ngIf="hiringTeamMember.employee.accessLevelId === hiringAccessLevelIdsEnum.recruiter"
                    >
                        {{
                            'components.manage-hiring-team.employeeHasAlreadyReceivedFullAdminPermissions'
                                | translate
                                    : {
                                          employeeName: hiringTeamMember.employee.fullName,
                                      }
                        }}
                    </ng-container>
                </p>
                <mat-form-field
                    *ngIf="hiringTeamMember.employee.accessLevelId !== hiringAccessLevelIdsEnum.recruiter"
                    class="columns-2 no-labels no-helpers"
                >
                    <mat-select
                        [(ngModel)]="hiringTeamMember.employee.accessLevelId"
                        (selectionChange)="onSelectionChange(hiringTeamMember)"
                        name="accessLevel"
                        type="text"
                        required
                        #accessLevel="ngModel"
                    >
                        <mat-option [value]="hiringAccessLevelIdsEnum.hiringManager">{{
                            'components.manage-hiring-team.hiringManagerAccess' | appTranslate
                        }}</mat-option>
                        <mat-option [value]="hiringAccessLevelIdsEnum.basic">{{
                            'components.manage-hiring-team.basicAccess' | appTranslate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p
                    *ngIf="hiringTeamMember.employee.accessLevelId === hiringAccessLevelIdsEnum.recruiter"
                    class="columns-2 margin-top-2"
                >
                    {{ 'components.manage-hiring-team.recruiterAccess' | appTranslate }}
                </p>
                <div class="columns-3"></div>

                <ui-button
                    *ngIf="canDeleteJobPostingCreator(hiringTeamMember, i)"
                    (click)="onRemove(i)"
                    type="link"
                    class="columns-1"
                    >{{ 'components.manage-hiring-team.remove' | appTranslate }}</ui-button
                >
            </div>
            <mat-divider></mat-divider>
        </ng-container>
    </ng-container>
</ui-card>

<ng-template #empty>
    <app-empty-state
        class="margin-top-4"
        label="applicant-tracker.messages.jobPosting.addEmployeesFromYourOrgToTheHiringTeam"
    ></app-empty-state>
</ng-template>
