import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TransifexToggleService {
    readonly TRANSIFEX_DISABLED = 'transifex-off';

    toggleTransifex(): void {
        this.isTransifexEnabled() ? this.disableTransifex() : this.enableTransifex();
    }

    enableTransifex(): void {
        window.localStorage.removeItem(this.TRANSIFEX_DISABLED);
    }

    disableTransifex(): void {
        window.localStorage.setItem(this.TRANSIFEX_DISABLED, '1');
        window.localStorage.removeItem('txlive');
        window.localStorage.removeItem('txlive:selectedlang');
    }

    isTransifexEnabled(): boolean {
        // we look for the absence of the disabled flag
        return window.localStorage.getItem(this.TRANSIFEX_DISABLED) ? false : true;
    }
}
