import { Model } from '@models/core/base.model';

/**
 * This Model is never updated on the front end
 * It is used for display only
 */
export class CovidWageSubsidy extends Model {
    protected static _dates = ['appliedStartDate', 'appliedEndDate'];

    get employeesOnPayroll(): number {
        return this._attributes['employeesOnPayroll'];
    }

    get grossPayroll(): number {
        return this._attributes['grossPayroll'];
    }

    get incomeTax(): number {
        return this._attributes['incomeTax'];
    }

    get claim(): number {
        return this._attributes['claim'];
    }

    get preClaimRemittance(): number {
        return this._attributes['preClaimRemittance'];
    }

    get postClaimRemittance(): number {
        return this._attributes['postClaimRemittance'];
    }

    get appliedStartDate(): string {
        return this._attributes['appliedStartDate'];
    }

    get appliedEndDate(): string {
        return this._attributes['appliedEndDate'];
    }

    get appliedDaysInPeriod(): number {
        return this._attributes['appliedDaysInPeriod'];
    }

    get prorated(): boolean {
        return this._attributes['prorated'];
    }

    get claimMaximized(): boolean {
        return this._attributes['claimMaximized'];
    }
}
