import { SimpleEntityActions } from '@app/classes';
import { populateFromGooglePlace } from '@app/functions';
import { Address } from '../common/address.model';

export class Office extends Address {
    protected static _resource = 'companyManagement/companies/:company/addresses';

    static createFromGooglePlace(googlePlace: any): Office {
        return populateFromGooglePlace(new Office(), googlePlace);
    }

    static permission = new SimpleEntityActions('companyAddress');

    get employeeCount(): number {
        return this._attributes['employeeCount'];
    }

    get isOfficeUnsupportedForPayroll(): boolean {
        return this._attributes['province'] === 'QC';
    }
}
