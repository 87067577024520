import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nl2br' })
export class NewLineToBreakPipe implements PipeTransform {
    transform(str: string): string | null {
        if (!str) {
            return null;
        } else {
            return str.replace(/\n/g, '<br />');
        }
    }
}
