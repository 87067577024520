import { ModelMixin } from '../core/base-generic.model';
import { Employee } from '../employee/employee.model';

export class ManagerAssignment extends ModelMixin<ManagerAssignment>() {
    protected static _serializeAttributes = ['atsJobId', 'employeeId', 'accessLevelId'];

    /* Timestamps */
    get createdAt(): any {
        return this._attributes['createdAt'];
    }

    get updatedAt(): any {
        return this._attributes['updatedAt'];
    }

    get deletedAt(): any {
        return this._attributes['deletedAt'] || null;
    }

    get id(): number {
        return this._attributes['id'] || null;
    }

    get jobPostingId(): number {
        return this._attributes['atsJobId'];
    }

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get accessLevelId(): number | null {
        return this._attributes['accessLevelId'];
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }
}
