import { inLocal } from '@app/functions/in-local';

const WARNING = `

░░██╗  ██╗██╗██╗  ██╗░░
░██╔╝  ██║██║██║  ╚██╗░
██╔╝░  ██║██║██║  ░╚██╗
╚██╗░  ╚═╝╚═╝╚═╝  ░██╔╝
░╚██╗  ██╗██╗██╗  ██╔╝░
░░╚═╝  ╚═╝╚═╝╚═╝  ╚═╝░░`;

/** Use to give helpful warnings */
export function warnDeveloper(messages: string[]): void {
    if (!inLocal()) {
        return;
    }

    console.group(`%c` + WARNING, `color: orange; font-family: 'Impact'`);
    messages.forEach((message) => console.log(message));
    console.groupEnd();
}
