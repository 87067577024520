import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[sinValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SinValidator, multi: true }],
})
export class SinValidator implements Validator {
    @Input() luhnRequired: boolean;
    @Input() isValidationRequired: boolean;

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.isValidationRequired) {
            return null;
        }

        if (!/^\d{9}$/.test(control.value)) {
            return { custom: { message: 'employees.personal.invalidSin' } };
        }

        if (!this.validateLuhn(control.value)) {
            return { custom: { message: 'employees.personal.invalidSin' } };
        }

        return null;
    }

    /**
     * Luhn algorithm
     * https://en.wikipedia.org/wiki/Luhn_algorithm
     * its implementation for canadian SINs
     * https://en.wikipedia.org/wiki/Social_insurance_number#Validation
     */
    private validateLuhn(value: string | number): boolean {
        if (!this.luhnRequired) return true;

        const checksum = `${value}`
            .split('')
            .reduce((carry, digit, index) => `${carry}${index % 2 !== 0 ? parseInt(digit) * 2 : digit}`, '');

        const sum = checksum.split('').reduce((calc: number, digit: string) => calc + parseInt(digit), 0);

        return sum % 10 === 0;
    }
}
