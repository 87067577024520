import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { DataFieldGroup } from '@models/settings/data-field-group.model';
import { LocationColumn, NameColumn, NumberOfFieldsColumn } from '@settings/services/tables/field-groups-table/columns';

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

@Injectable()
export class CustomFieldGroupsIndexTable extends Table {
    showMenuColumnOptions = true;
    links = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getLink(dataFieldGroup: DataFieldGroup): (string | number)[] {
        return ['/settings', 'custom-fields', 'field-groups', dataFieldGroup.id, 'edit'];
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [editOption, deleteOption];
    }

    setColumns(): void {
        this.addColumns(new NameColumn(), new LocationColumn(), new NumberOfFieldsColumn());
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            DataFieldGroup.param('company', this.auth.company.id).where('onlyCustomGroups', true).limit(10),
            {
                pageSize: 10,
            }
        );
    }
}
