import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkersCompensation } from '@app/models/payroll/workers-compensation.model';
import { AuthService } from '@app/services';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class WorkersCompensationService {
    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {}

    async attachDetachEmployees(
        workersCompensation: WorkersCompensation,
        attachEmployees: number[],
        detachEmployees: number[]
    ): Promise<void> {
        const payload = {
            data: {
                attributes: {
                    attach: {
                        employees: attachEmployees,
                    },
                    detach: {
                        employees: detachEmployees,
                    },
                },
            },
        };

        try {
            await this.http
                .put(
                    PayrollResources.AttachDetachWorkersCompensationEmployees.replace(
                        ':company',
                        this.auth.company.id.toString()
                    ).replace(':workersCompensation', workersCompensation.id.toString()),
                    payload
                )
                .toPromise();
        } catch (e) {
            if (e instanceof HttpErrorResponse && e.status === 422) {
                throw e.error.errors[0];
            }

            throw e;
        }
    }
}
