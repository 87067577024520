import { SigningTemplateSlotNames } from '@app/modules/document/enums/signing-template-slot-names.enum';
import { SlotAssignmentOrder } from '@app/modules/document/types/slot-assignment-order.type';
import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';
import { SigningTemplateSlotCustomFieldAssignment } from './signing-template-slot-custom-field-assignment.model';
import { SigningTemplate } from './signing-template.model';

export class SigningTemplateSlot extends Model {
    protected static _resource = 'signingTemplates/:signingTemplate/slots';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['assigneeId', 'order'];
    protected static _type = 'signingTemplateSlots';

    get customFieldAssignments(): SigningTemplateSlotCustomFieldAssignment[] {
        return this.hasMany(SigningTemplateSlotCustomFieldAssignment, 'customFieldAssignments');
    }

    get name(): SigningTemplateSlotNames {
        return this._attributes['name'];
    }

    get assignee(): Employee {
        return this.hasOne(Employee, 'assignee');
    }

    set assignee(employee: Employee) {
        this.setOne('assignee', employee, 'assigneeId');
    }

    get signingTemplate(): SigningTemplate {
        return this.hasOne(SigningTemplate, 'signingTemplate');
    }

    get signingTemplateId(): number {
        return this._attributes['signingTemplateId'];
    }

    get assigneeId(): number {
        return this._attributes['assigneeId'];
    }

    set assigneeId(val: number | null) {
        this._attributes['assigneeId'] = val;
    }

    get order(): SlotAssignmentOrder {
        return this._attributes['order'];
    }

    set order(val: SlotAssignmentOrder) {
        this._attributes['order'] = val;
    }

    hasPermanentAssignee(): boolean {
        return !!this._attributes['assigneeId'];
    }

    customFieldCount(): number {
        return this.customFieldAssignments.length;
    }
}
