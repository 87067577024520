import { Component, ElementRef, Input } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { DataFieldGroup } from '@models/settings/data-field-group.model';
import { DataField } from '@models/settings/data-field.model';
import { BulkSelectBaseDialogComponent } from '../bulk-select-base-dialog/bulk-select-base-dialog.component';

@Component({
    selector: 'app-common-bulk-select-custom-field-dialog',
    templateUrl: './bulk-select-custom-field-dialog.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectCustomFieldDialogComponent extends BulkSelectBaseDialogComponent {
    @Input() label = 'select';
    @Input() headerText = 'components.bulk-select-custom-field-dialog.selectCustomFields';

    isLoading = false;

    filters: any = {
        dataFieldGroupIds: 'components.bulk-select-custom-field-dialog.groups',
    };
    filterValues: any = {
        dataFieldGroupIds: {},
    };
    activeFilters: any = {};

    items: DataField[] = [];
    selectItems: DataField[] = [];

    constructor(
        protected element: ElementRef,
        protected auth: AuthService
    ) {
        super(element, auth);
    }

    selectAll(): void {
        let request = DataField.param('company', this.auth.company.id).with('dataFieldGroup').limit(0);

        request = this.applyQuery(request);

        request = this.applyFilters(request);

        request
            .get()
            .then(([customFields]) => {
                this.selectItems = customFields;
                this.updateModelSelection();
            })
            .catch(() => {});
    }

    /**
     * Reset the form
     */
    protected loadFilterOptions(): void {
        this.loadCustomFieldGroups();
    }

    protected loadItems(): void {
        let request = DataField.param('company', this.auth.company.id).with('dataFieldGroup').page(this.currentPage);

        request = this.applyQuery(request);
        request = this.applyFilters(request);

        request
            .get()
            .then(([fields, meta]) => {
                this.totalResults = meta.pagination.total;
                this.currentPage = meta.pagination.page;
                this.items = fields;
                this.isLoading = false;
            })
            .catch(() => {});
    }

    private loadCustomFieldGroups(): void {
        DataFieldGroup.param('company', this.auth.company.id)
            .all()
            .then(([fields]) => {
                fields.forEach((dataFieldGroup) => {
                    this.filterValues.dataFieldGroupIds[dataFieldGroup.id] = dataFieldGroup.name;
                });

                this.isLoading = false;
            });
    }
}
