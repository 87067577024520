export enum DocumentFileTypes {
    pdf = '.pdf',
    doc = '.doc',
    docx = '.docx',
    xls = '.xls',
    xlsx = '.xlsx',
    csv = '.csv',
    txt = '.txt',
    ppt = '.ppt',
    pptx = '.pptx',
    pages = '.pages',
    numbers = '.numbers',
    key = '.key',
}
