export const TerminationCodes = {
    A00: 'Shortage of work / End of Contract or Season',
    A01: 'Employer bankruptcy or receivership',
    B00: 'Strike or lockout',
    D00: 'Illness or injury',
    E00: 'Quit',
    E02: 'Quit / Follow spouse',
    E03: 'Quit / Return to school',
    E04: 'Quit / Health reasons',
    E05: 'Quit / Voluntary retirement',
    E06: 'Quit / Take another job',
    E09: 'Quit / Employer relocation',
    E10: 'Quit / Care for a dependant',
    E11: 'Quit / To become self-employed',
    F00: 'Maternity',
    G00: 'Mandatory retirement',
    G07: 'Retirement / Approved workforce reduction',
    H00: 'Work sharing',
    J00: 'Apprentice training',
    K00: 'Other',
    K12: 'Other / Change of payroll frequency',
    K13: 'Other / Change of ownership',
    K14: 'Other / Requested by Employment Insurance',
    K15: "Other / Canadian Forces - Queen's Regulations/Orders",
    K16: 'Other / At the employee’s request',
    K17: 'Other / Change of Service Provider',
    M00: 'Dismissal',
    M08: 'Dismissal / Terminated within probationary period',
    N00: 'Leave of absence',
    P00: 'Parental',
    Z00: 'Compassionate care/Family caregiver',
} as const;

export type TerminationCode = keyof typeof TerminationCodes;
