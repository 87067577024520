<form class="container" attr.aria-label="{{ ariaLabel + ' ' + ('components.iconLabels.starRating' | translate) }}">
    @for (starValue of stars; track starValue) {
        <label class="star" [class.active]="starValue < currentValue">
            <span class="sr-only">{{ starValue + 1 + ' ' + ('applicant-tracker.stars' | translate) }}</span>
            <input
                class="sr-only"
                type="radio"
                [value]="starValue"
                [checked]="starValue === currentValue - 1"
                (change)="onClick(starValue + 1)"
            />
        </label>
    }
</form>
