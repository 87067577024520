import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FieldsService {
    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {}

    async bulkUpdateOrder(fieldGroupId: number, fieldIds: number[]): Promise<void> {
        const orderingEndpoint = '/v2/companyManagement/companies/:companyId/dataFieldGroups/:fieldGroupId/order'
            .replace(':companyId', this.auth.company.id.toString())
            .replace(':fieldGroupId', fieldGroupId.toString());

        await this.http
            .post(orderingEndpoint, {
                data: {
                    dataFieldIds: fieldIds,
                },
            })
            .toPromise();
    }
}
