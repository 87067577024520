import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: '[qhsfRateValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: QhsfRateValidator, multi: true }],
})
export class QhsfRateValidator implements Validator {
    constructor(private readonly translateService: TranslateService) {}

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.value === undefined || control.value === null) {
            return null;
        }

        const stringValue = control.value.toString();

        if (stringValue.includes('.') && stringValue.split('.')[1].length > 2) {
            return {
                custom: {
                    message: this.translateService.instant('payroll.settings.qhsf.validation.maxTwoDecimals'),
                },
            };
        }

        const value = parseFloat(control.value);

        if (value <= 0 || value >= 5) {
            return {
                custom: {
                    message: this.translateService.instant('payroll.settings.qhsf.validation.betweenZeroAndFive'),
                },
            };
        }

        return null;
    }
}
