import { css } from 'lit';

export const badgeStyles = css`
    :host {
        display: flex;
    }

    .badge {
        padding: var(--badge-default-padding);
        border-radius: var(--badge-default-border-radius);
        font-size: var(--badge-default-font-size);
        line-height: var(--badge-default-font-size);
        font-weight: var(--badge-default-font-weight);
        text-transform: uppercase;

        // Default
        color: var(--badge-default-font-color);
        background-color: var(--badge-default-background-color);
    }

    .informative {
        color: var(--badge-informative-font-color);
        background-color: var(--badge-informative-background-color);
    }

    .danger {
        color: var(--badge-danger-font-color);
        background-color: var(--badge-danger-background-color);
    }

    .warning {
        color: var(--badge-warning-font-color);
        background-color: var(--badge-warning-background-color);
    }

    .success {
        color: var(--badge-success-font-color);
        background-color: var(--badge-success-background-color);
    }
`;
