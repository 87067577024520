<div class="pagination" *ngIf="isDefined(total) && isDefined(current) && isDefined(limit)">
    <div class="ui right floated secondary menu">
        <div *ngIf="getShowing() && !itemType" class="item desktop">
            {{
                { key: 'components.pagination.showingOfTotal', params: { showing: getShowing(), total: getTotal() } }
                    | appTranslate
            }}
        </div>

        <a
            class="item"
            (click)="goToPage(pager().currentPage - 1)"
            [class.disabled]="pager().currentPage === 1 || disableLoad"
        >
            <mat-icon svgIcon="chevronLeft"></mat-icon>
        </a>
        <a
            class="item"
            (click)="goToPage(pager().currentPage + 1)"
            [class.disabled]="pager().currentPage >= pager().totalPages || disableLoad"
        >
            <mat-icon svgIcon="chevronRight"></mat-icon>
        </a>
    </div>
    <div class="clear"></div>
</div>
