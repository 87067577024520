import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Model } from '@app/interfaces/model.interface';
import { BaseForm } from '@forms/base.form';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { Translatable } from '@app/types/translatable.type';

/*
 ! THIS COMPONENT IS DEPRECATED: Please use <ui-dialog> DialogComponent instead.
*/
const validationPending = 'PENDING';
const formStatusInvalid = 'INVALID';

/**
 * Removes superfluous whitespace from form inputs
 */
function trimFormControls(formGroup: FormGroup): void {
    if (!formGroup) {
        return;
    }
    Object.keys(formGroup.controls).forEach((key) => {
        const formControl = formGroup.get([key]);
        if (typeof formControl.value === 'string') {
            formControl.setValue(formControl.value.trim());
        }
    });
}

@Component({
    selector: 'app-deprecated-modal-form',
    templateUrl: './deprecated-modal-form.template.html',
    styleUrls: ['./deprecated-modal-form.style.scss'],
})
export class DeprecatedModalFormComponent {
    @Input() isLoading = false;
    @Input() centered = true;
    @Input() closeable = true;
    @Input() label: Translatable = 'save';
    @Input() headerText: Translatable = 'edit';
    @Input() form: BaseForm;
    @Input() customHeight: string;
    @Input() tall = false;
    @Input() stubby = false;
    @Input() thin = false;
    @Input() medium = false;
    @Input() wide = false;
    @Input() disabled = false;
    @Input() hideOnSave = true;
    @Input() secondaryActionText: Translatable | null = null;
    @Input() showButtons = true;
    @Output() save: EventEmitter<null> = new EventEmitter<null>();
    @Output() cancel: EventEmitter<null> = new EventEmitter<null>();
    @Output() secondaryAction: EventEmitter<null> = new EventEmitter<null>();

    isOpen = false;

    protected formData: any = {};

    private approved = false;
    private clickTarget: HTMLElement;
    private formSubject$: Subject<null> = new Subject();

    constructor(protected element: ElementRef) {}

    setForm(form: BaseForm): void {
        this.form = form;
    }

    show($event?: any, _model?: Model): void {
        this.beforeShow();

        this.approved = false; // reset approval status

        if ($event) {
            $event.stopPropagation();
        }

        $(this.element.nativeElement)
            .find('.ui.dimmer')
            .dimmer({
                closable: false, // we hanbdle closing ourselves due to the mousedown inside/mouseup outside issue
                onHide: () => {
                    if (!this.approved) {
                        this.cancel.emit();
                    }
                },
            });
        this.onShow();
    }

    @HostListener('document:keydown.escape')
    deny(): void {
        $(this.element.nativeElement).find('.ui.dimmer').dimmer('hide');
        this.cancel.emit();
    }

    @HostListener('document:mousedown', ['$event'])
    mousedown(e: any): void {
        this.clickTarget = e.target;
    }

    @HostListener('document:mouseup', ['$event'])
    mouseup(e: any): void {
        if (e.target.classList.contains('dimmer') && this.clickTarget === e.target && this.closeable) {
            $(this.element.nativeElement).find('.ui.dimmer').dimmer('hide');
        }
    }

    async approve(): Promise<void> {
        // If we have no inner form, just close on approve
        if (!this.form?.form) {
            this.approved = true;
            if (this.hideOnSave) {
                this.hide();
            }
            this.save.emit();
            return;
        }

        trimFormControls(this.form.form.form);

        const ngForm = this.form.form;
        this.formSubject$
            .pipe(
                tap(() => this.form.submit()),
                switchMap(() =>
                    this.form.form.statusChanges.pipe(
                        startWith(ngForm.status),
                        filter((status) => status !== validationPending),
                        take(1)
                    )
                ),
                take(1)
            )
            .subscribe((formStatus) => {
                if (formStatus === formStatusInvalid) {
                    return;
                }

                // This flag is used to indicate whether hiding the modal is a cancel
                // or save. It must be set before calling `this.hide()`
                this.approved = true;

                if (this.hideOnSave) {
                    this.hide();
                }

                this.save.emit();
                return;
            });
        this.formSubject$.next();
    }

    hide(): void {
        $(this.element.nativeElement).find('.ui.dimmer').dimmer('hide');
        this.isOpen = false;
    }

    protected onShow(): void {
        $(this.element.nativeElement).find('.ui.dimmer').dimmer('show');
        $(this.element.nativeElement).find('.ui.segment').fadeIn();
        this.isOpen = true;
    }

    protected beforeShow(): void {
        if (!(this.form && this.form.beforeShow)) {
            return;
        }

        this.form.beforeShow();
    }
}
