import { Setting } from '../common/setting.model';

import { SimpleEntityActions } from '@app/classes';
import { CreateBaseSetting } from './base-setting.model';

export class CompanySetting extends CreateBaseSetting<CompanySetting, boolean>(false) {
    get updatedAt(): Date {
        return this._attributes['updated_at'];
    }

    get setting(): Setting {
        return this.hasOne(Setting, 'setting');
    }

    static permission = new SimpleEntityActions('companySetting');

    protected static _resource = 'companyManagement/companies/:company/companySettings';

    protected static _version = 'v2';

    /**
     * Change the value of the setting and save it.
     *
     * @returns {Promise<any>}
     */
    toggle(): Promise<CompanySetting> {
        this.value = !this.value;
        return this.save();
    }
}
