import { EmployeeHour } from '@app/models/payroll/company/employee-hour.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { MultiBadgeColumn } from '@app/modules/table/classes/multi-badge-column.class';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { isBefore } from 'date-fns';

const options = [
    {
        label: 'pending',
        value: 'pending',
    },
    {
        label: 'approved',
        value: 'approved',
    },
];

export class StatusColumn extends MultiBadgeColumn<EmployeeHour> {
    title = 'table.employeeHour.status';
    property = 'status';
    sortField = 'status';
    sortable = true;

    constructor(
        private payrollStartDate: string,
        private showLateBadge = true
    ) {
        super();

        this.filter = new CheckListFilter('status', options);
    }

    getMultiBadgeDisplay(employeeHour: EmployeeHour): BadgeDisplay[] {
        // Return the list of badges if already retrieved for EmployeeHour
        if (this.badges[employeeHour.id]) {
            return this.badges[employeeHour.id];
        }

        const badges: BadgeDisplay[] = [
            employeeHour.status.toLowerCase() === 'approved'
                ? {
                      type: 'success',
                      label: 'approved',
                  }
                : {
                      type: 'informative',
                      label: 'pending',
                  },
        ];

        if (this.showLateBadge && isBefore(employeeHour.dateUsed, this.payrollStartDate)) {
            badges.push({ type: 'warning', label: 'table.employeeHour.late' });
        }

        if (employeeHour.new) {
            badges.push({ type: 'default', label: 'table.employeeHour.new' });
        }

        // Cache retrieved badge list
        this.badges[employeeHour.id] = badges;

        return this.badges[employeeHour.id];
    }
}
