import { HttpClient } from '@angular/common/http';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import * as PDFJS from 'pdfjs-dist';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import { from, fromEvent, of, Subscription } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

const PDF_WORKER = '/pdf.worker.min.js';
const DEBOUNCE_TIME = 100;

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.template.html',
    styleUrls: ['./pdf-viewer.style.scss'],
})
export class PdfViewerComponent implements OnChanges, OnInit, OnDestroy {
    @Input() src: string;
    @Input() scale = 1;
    @Input() page = 1;
    @Input() static = true;
    @Input() showingControls = true;
    @Input() showAcknowledgementButton = false;
    @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() load: EventEmitter<any> = new EventEmitter<any>();
    @Output() acknowledged: EventEmitter<void> = new EventEmitter<any>();
    @Output() pdfRendered: EventEmitter<void> = new EventEmitter();

    totalPages = 0;
    error: any = null;

    status: any = {
        loading: true,
        error: null,
    };

    private pdfPage: PDFPageProxy | undefined;
    private pdfDocument: PDFDocumentProxy | undefined;
    private windowResizeSubscription: Subscription;

    constructor(
        private element: ElementRef,
        private httpClient: HttpClient
    ) {}

    ngOnInit(): void {
        if (this.src && this.static) {
            this.initialize();
        }

        this.windowResizeSubscription = fromEvent(window, 'resize')
            .pipe(debounceTime(DEBOUNCE_TIME))
            .subscribe(() => {
                if (this.pdfPage) {
                    this.renderPage();
                }
            });
    }

    ngOnDestroy(): void {
        if (this.windowResizeSubscription) {
            this.windowResizeSubscription.unsubscribe();
        }
    }

    onAcknowledge(): void {
        this.acknowledged.emit(null);
    }

    setSource(src: string): Promise<void> {
        this.src = src;
        return this.initialize();
    }

    initialize(): Promise<void> {
        if (!this.src) {
            return;
        }

        this.status.error = null;
        this.statusChange.emit(this.status);
        PDFJS.GlobalWorkerOptions.workerSrc = PDF_WORKER;

        return this.renderPdf();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['page'] && changes['page'].currentValue !== changes['page'].previousValue) {
            setTimeout(() => {
                if (this.pdfDocument && this.pdfPage) {
                    this.loadPage(this.page);
                }
            });
        }
    }

    loadPage(pageNumber: number): void {
        this.pdfDocument
            .getPage(pageNumber)
            .then((page) => {
                this.page = pageNumber;
                this.pdfPage = page;
                if (this.pdfPage) {
                    this.renderPage();
                }
            })
            .catch((err) => {
                this.status.loading = false;
                this.status.error = err;
                this.statusChange.emit(this.status);
            });
    }

    private renderPage(): void {
        const desiredWidth = $(this.element.nativeElement).width();
        const viewport = this.pdfPage.getViewport({ scale: this.scale });
        const scale = desiredWidth / viewport.width;
        const scaledViewport = this.pdfPage.getViewport({ scale });

        const canvas = $(this.element.nativeElement).find('canvas').get(0);

        const context = canvas.getContext('2d');
        canvas.height = scaledViewport.height;
        canvas.width = scaledViewport.width;

        const renderContext = {
            canvasContext: context,
            viewport: scaledViewport,
        };

        this.pdfPage
            .render(renderContext)
            .promise.then(() => {
                this.status.loading = false;
                this.statusChange.emit(this.status);
                this.pdfRendered.emit();
            })
            .catch((err) => {
                this.status.loading = false;
                this.status.error = err;
                this.statusChange.emit(this.status);
            });
    }

    private renderPdf(): Promise<void> {
        this.error = null;
        return this.httpClient
            .get(this.src, { observe: 'body', responseType: 'arraybuffer' })
            .pipe(
                switchMap((arrayBuffer) =>
                    from(PDFJS.getDocument({ data: arrayBuffer, isEvalSupported: false }).promise)
                ),
                tap((pdf: PDFDocumentProxy) => {
                    this.pdfDocument = pdf;
                    this.page = 1;
                    this.totalPages = pdf.numPages;
                    this.load.emit(pdf);
                    this.loadPage(this.page);
                }),
                map(() => void 0), // Don't need to return the PDF since it is set on the class property
                catchError((error) => {
                    this.status.loading = false;
                    this.status.error = error;
                    this.statusChange.emit(this.status);
                    return of(void 0);
                })
            )
            .toPromise();
    }
}
