import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { Employee } from '@app/models/employee/employee.model';
import { AbilityService, NotifyService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { Settings } from '@app/services/payroll-settings.service';
import { PayrollResources } from '../payroll.resources';

export interface TimeOffIntegratedCompensationVacationPay {
    type: string;
    percentage: number;
}

export interface TimeOffIntegrationCompensationDetails {
    isOnVacationPayPolicy: boolean;
    isPayrollTimeOffIntegrationOn: boolean;
    timeOffTypeId: number | null;
    timeOffIntegratedVacationPay: TimeOffIntegratedCompensationVacationPay | null;
}

@Injectable()
export class TimeOffIntegratedCompensationVacationPayService {
    payrollSettings: Settings;

    constructor(
        private http: HttpClient,
        private notify: NotifyService,
        private abilities: AbilityService,
        private featureService: FeatureService
    ) {}

    async isCompensationAbleToHaveTimeOffIntegratedCompensations(employee: Employee): Promise<boolean> {
        const payrollTimeOffFeatureFlags = await this.loadAndReturnFeatureFlags();
        const payrollEnabled = this.abilities.payroll();
        const couldCompensationsBeIntegratedWithTimeOff =
            payrollTimeOffFeatureFlags && payrollEnabled && employee?.activeOnPayroll();
        return couldCompensationsBeIntegratedWithTimeOff;
    }

    async loadAndReturnFeatureFlags(): Promise<boolean> {
        const timeOffPayrollIntegrationPhaseFourPromise = this.featureService.has(
            FeatureFlag.timeOffPayrollIntegrationPhaseFour
        );

        const timeOffPayrollIntegrationPhaseFour = await timeOffPayrollIntegrationPhaseFourPromise;

        return timeOffPayrollIntegrationPhaseFour;
    }

    async getTimeOffIntegratedCompensationVacationPay(
        employeeId: number
    ): Promise<TimeOffIntegrationCompensationDetails> {
        const url = PayrollResources.TimeOffIntegratedCompensationVacationPay.replace(
            ':employeeId',
            employeeId.toString()
        );
        const timeOffIntegratedCompensationVacationDetailsPromise = await this.http.get(url).toPromise();
        const timeOffIntegratedCompensationVacationDetails =
            timeOffIntegratedCompensationVacationDetailsPromise['data']['attributes'];
        let timeOffIntegratedVacationPay = {
            type: null,
            percentage: null,
        };
        Object.entries(timeOffIntegratedCompensationVacationDetails).some(([key, value]) => {
            if (key !== 'customEsaMinimum' && key !== 'standardEsaMinimum' && key !== 'timeOffAccrualPercentage') {
                return;
            }
            if (value !== null) {
                timeOffIntegratedVacationPay.type = key;
                timeOffIntegratedVacationPay.percentage = value;
                return true;
            }
        });
        if (!timeOffIntegratedVacationPay.type || !timeOffIntegratedVacationPay.percentage) {
            timeOffIntegratedVacationPay = null;
        }
        const timeOffIntegrationCompensationDetails: TimeOffIntegrationCompensationDetails = {
            isOnVacationPayPolicy: timeOffIntegratedCompensationVacationDetails.isOnVacationPayPolicy,
            isPayrollTimeOffIntegrationOn: timeOffIntegratedCompensationVacationDetails.isPayrollTimeOffIntegrationOn,
            timeOffTypeId: timeOffIntegratedCompensationVacationDetails.timeOffTypeId,
            timeOffIntegratedVacationPay: timeOffIntegratedVacationPay,
        };
        return timeOffIntegrationCompensationDetails;
    }

    async updateCustomESAMinimumVacationPayPercentage(
        employeeId: number,
        vacationPayPercentage: number
    ): Promise<void> {
        const payload = {
            data: {
                attributes: {
                    custom_esa_minimum: vacationPayPercentage,
                },
            },
        };
        try {
            await this.http
                .put(
                    PayrollResources.TimeOffIntegratedCompensationVacationPaySettings.replace(
                        ':employeeId',
                        employeeId.toString()
                    ),
                    payload
                )
                .toPromise();
        } catch (error) {
            this.notify.error(ErrorParser.parse(error));
        }
    }
}
