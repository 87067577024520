import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TimeOffRequest } from '@models/time-off-v3/time-off-request.model';
import { TimeOffType } from '@models/time-off-v3/time-off-type.model';

export class TimeOffTypeColumn extends Column {
    title = 'time-off-v3.type';
    property = 'timeOffPolicy.timeOffType.name';
    sortable = true;
    sortField = 'timeOffTypeName';
    type: CellDisplay = CellDisplay.bubbleWithText;

    constructor(timeOffTypes: TimeOffType[]) {
        super();

        const options = timeOffTypes.map((timeOffType) => {
            return {
                label: timeOffType.name,
                value: timeOffType.id,
            };
        });

        this.filter = new CheckListFilter('timeOffTypeId', options, this.title);
    }

    getBubbleDisplayColor(row: TimeOffRequest): string {
        return row.timeOffPolicy.timeOffType.color;
    }
}
