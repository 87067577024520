import { SimpleEntityActions } from '@app/classes';
import { SalaryFrequency } from '@app/modules/employees/enums/salary-frequency.enum';
import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';

export class Salary extends Model {
    protected static _resource = 'companyManagement/companies/:company/employees/:employee/salaries';

    protected static _type = 'salaries';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'employeeId',
        'rate',
        'frequency',
        'hoursPerWeek',
        'effectiveAt',
        'vacationPayPercentage',
        'name',
        'endsAt',
        'isPrimary',
    ];

    protected static _dates = ['effectiveAt', 'endsAt'];

    static permission = new SimpleEntityActions('employeeSalary');

    get frequencyLabel(): string {
        return this.frequency === 'hour' ? 'Rate' : 'Salary';
    }

    get employeeId(): number {
        return this._attributes['employeeId'];
    }
    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    get isPaid(): boolean {
        return this._attributes['isPaid'];
    }

    get rate(): number {
        return this._attributes['rate'];
    }
    set rate(val: number) {
        this._attributes['rate'] = val;
    }

    get frequency(): SalaryFrequency | 'year' | 'hour' {
        return this._attributes['frequency'];
    }

    set frequency(val: SalaryFrequency | 'year' | 'hour') {
        this._attributes['frequency'] = val;
    }

    get status(): string {
        return this._attributes['status'];
    }

    get isPrimary(): boolean {
        return this._attributes['isPrimary'];
    }

    set isPrimary(val: boolean) {
        this._attributes['isPrimary'] = val;
    }

    get prCompensationId(): number {
        return this._attributes['prCompensationId'];
    }

    get effectiveAt(): Date | null {
        return this._attributes['effectiveAt'];
    }

    set effectiveAt(val: Date) {
        this._attributes['effectiveAt'] = val;
    }

    get endsAt(): Date | null {
        return this._attributes['endsAt'];
    }

    set endsAt(val: Date) {
        this._attributes['endsAt'] = val;
    }

    get vacationPayPercentage(): number {
        return this._attributes['vacationPayPercentage'];
    }

    set vacationPayPercentage(value: number) {
        this._attributes['vacationPayPercentage'] = value > 100 ? 100 : value;
    }

    get hasVacationPayPercentage(): boolean {
        const percentage = this.vacationPayPercentage;
        return Boolean(percentage > 0 || percentage === 0);
    }

    get hoursPerWeek(): number {
        return this._attributes['hoursPerWeek'];
    }

    set hoursPerWeek(val: number) {
        this._attributes['hoursPerWeek'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get current(): boolean {
        return this._attributes['current'];
    }
}
