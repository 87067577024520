import { Model } from '@models/core/base.model';
import { T4Box } from '@models/payroll/t4-box.model';
import { Rl1Box } from '@models/payroll/rl1-box.model';

export class DeductionType extends Model {
    protected static _resource = 'payroll/deductionTypes';

    protected static _version = 'v2';

    get name(): string {
        return this._attributes['name'];
    }

    get displayName(): string {
        return this._attributes['displayName'];
    }

    get pretax(): boolean {
        return this._attributes['pretax'];
    }

    get custom(): boolean {
        return this._attributes['custom'];
    }

    get legacy(): boolean {
        return this._attributes['legacy'];
    }

    get t4BoxesDisplayList(): null | string {
        return this.t4Boxes?.map((b) => b.code).join(',');
    }

    get t4Boxes(): T4Box[] {
        return this.hasMany(T4Box, 't4Boxes');
    }

    get rl1BoxesDisplayList(): null | string {
        return this.rl1Boxes?.map((b) => b.code).join(',');
    }

    get rl1Boxes(): Rl1Box[] {
        return this.hasMany(Rl1Box, 'rl1Boxes');
    }
}
