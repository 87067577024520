export const HUMI_THEME_KEY = 'user_theme_preference';

export const THEME_PREFERENCES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    MATCH_BROWSER: 'MATCH_BROWSER',
};

export function getThemePreference() {
    let preference = localStorage.getItem(HUMI_THEME_KEY);

    if (!preference) {
        return THEME_PREFERENCES.LIGHT;
    }

    if (!Object.values(THEME_PREFERENCES).includes(preference)) {
        localStorage.removeItem(HUMI_THEME_KEY);
        return THEME_PREFERENCES.LIGHT;
    }

    return preference;
}

/**
 * Gets the actual theme value of the user, evaluating the browser preference if MATCH_BROWSER is chosen
 */
export function getEvaluatedTheme() {
    const preference = getThemePreference();

    if (preference === THEME_PREFERENCES.MATCH_BROWSER) {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
            ? THEME_PREFERENCES.DARK
            : THEME_PREFERENCES.LIGHT;
    }

    return preference;
}

export function applyUserTheme() {
    let preference = getEvaluatedTheme();
    const ROOT_ELEMENT = document.querySelector('html');
    const THEME_COLOR_DEFINITION_ELEMENT = document.querySelector('meta[name="theme-color"]');

    if (preference === THEME_PREFERENCES.LIGHT) {
        ROOT_ELEMENT?.classList.remove('dark');

        THEME_COLOR_DEFINITION_ELEMENT?.setAttribute(
            'content',
            getComputedStyle(document.body).getPropertyValue('--card-background-color')
        );
        return THEME_PREFERENCES.LIGHT;
    }

    ROOT_ELEMENT?.classList.add('dark');

    THEME_COLOR_DEFINITION_ELEMENT?.setAttribute(
        'content',
        getComputedStyle(document.body).getPropertyValue('--card-background-color')
    );
    return THEME_PREFERENCES.DARK;
}
