/**
 * Key is a string we get from the HR API
 */
const RoleNameDisplayOptions: { [key: string]: string } = {
    Admin: 'role.admin',
    Employee: 'role.employee',
    Manager: 'role.manager',
};

/**
 * Key is a string we get from the HR API
 */
const RoleDescriptionDisplayOptions: { [key: string]: string } = {
    'This role allows unrestricted access to all areas of the application': 'role.adminDesc',
    'This role allows for employee type access to the system.': 'role.employeeDesc',
    'This role allows for managerial type access to the system.': 'role.managerDesc',
};

export { RoleDescriptionDisplayOptions, RoleNameDisplayOptions };
