import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { TimeOffApprovalFlow } from '@models/time-off-v3/time-off-approval-flow.model';
import { TimeOffComment } from '@models/time-off-v3/time-off-comment.model';
import { TimeOffRequestApprovalFlowStep } from '@models/time-off-v3/time-off-request-approval-flow-step.model';
import { TimeOffRequest } from '@models/time-off-v3/time-off-request.model';

const APPROVED = 'approved';
const DENIED = 'denied';

export class TimeOffRequestApprovalFlow extends Model {
    protected static _type = 'timeOffRequestApprovalFlows';

    get timeOffApprovalFlowId(): number {
        return this._attributes['timeOffApprovalFlowId'];
    }

    get timeOffApprovalFlow(): TimeOffApprovalFlow {
        return this.hasOne(TimeOffApprovalFlow, 'timeOffApprovalFlow');
    }

    get timeOffRequestId(): number {
        return this._attributes['timeOffRequestId'];
    }

    get timeOffRequest(): TimeOffRequest {
        return this.hasOne(TimeOffRequest, 'timeOffRequest');
    }

    get timeOffComment(): TimeOffComment {
        return this.hasOne(TimeOffComment, 'timeOffComment');
    }

    get timeOffRequestApprovalFlowSteps(): TimeOffRequestApprovalFlowStep[] {
        return this.hasMany(TimeOffRequestApprovalFlowStep, 'timeOffRequestApprovalFlowSteps');
    }

    set timeOffRequestApprovalFlowSteps(value: TimeOffRequestApprovalFlowStep[]) {
        this.setMany('timeOffRequestApprovalFlowSteps', value);
    }

    get timeOffRequestApprovalFlowStepsWithTrashed(): TimeOffRequestApprovalFlowStep[] {
        return this.hasMany(TimeOffRequestApprovalFlowStep, 'timeOffRequestApprovalFlowStepsWithTrashed');
    }

    get forcedBy(): Employee {
        return this.hasOne(Employee, 'forcedBy');
    }

    get isAutomatic(): boolean {
        return this._attributes['isAutomatic'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get forcedStatus(): string {
        return this._attributes['forcedStatus'];
    }

    get isForcedApproved(): boolean {
        return this.forcedStatus === APPROVED;
    }

    get isForcedDenied(): boolean {
        return this.forcedStatus === DENIED;
    }
}
