<div class="ui outlet small modal segment" #modal role="dialog" aria-labelledby="dialog-header">
    <h2 id="dialog-header" class="dialog-header">
        {{ (disableAction ? 'components.deprecated-confirmation-dialog.cannotPerformAction' : header) | appTranslate }}
    </h2>

    <div class="content">
        <p *ngIf="!useNgContent">{{ description | appTranslate }}</p>
        <ng-content *ngIf="useNgContent"></ng-content>
    </div>

    <div class="actions">
        <ui-button class="deny-button" type="ghost"> {{ cancelLabel | appTranslate }} </ui-button>

        <!-- Approve, confirm, other 'positive' actions -->
        <ui-button type="primary" class="approve-button" *ngIf="!negative" [disabled]="disableAction">
            {{ label | appTranslate }}
        </ui-button>

        <!-- Delete, deny, other 'negative' actions -->
        <ui-button type="danger" class="approve-button" *ngIf="negative" [disabled]="disableAction">
            {{ label | appTranslate }}
        </ui-button>
    </div>
</div>
