import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TimeOffPolicy } from '@app/models/time-off-v3/time-off-policy.model';
import { WorkSchedule } from '@app/models/time-off-v3/works-schedule.model';
import { UrlMapKeys } from './../../meta/time-off-setup-guides-step-meta';

@Injectable({ providedIn: 'root' })
export class TimeOffSidebarRedirectService {
    urlMap: { [K in UrlMapKeys]: (() => Promise<string[]>) | string[] } = {
        RedirectToLatestPolicyEnrolledEmployees: (): Promise<string[]> => this.getLatestPolicyUrl(),
        RedirectToLatestWorkSchedule: (): Promise<string[]> => this.getDefaultWorkScheduleUrl(),
        RedirectToSetupTimeOffTypes: ['/time-off/v3/types-and-policies/types/create'],
        RedirectToSetupTimeOffPolicies: ['/time-off/v3/policy/create'],
        RedirectToImportTimeOffData: ['/time-off/v3/settings'],
    };

    constructor(private router: Router) {}

    async redirectTo(buttonTarget: UrlMapKeys): Promise<void> {
        const url = await this.getRedirectionTarget(buttonTarget);

        this.router.navigate(url);
    }

    async getRedirectionTarget(mapKey: UrlMapKeys): Promise<string[]> {
        const redirectionTarget = this.urlMap[mapKey] || ['/time-off/v3/requests'];
        let url: string[];

        if (typeof redirectionTarget === 'function') {
            url = await redirectionTarget();
        } else {
            url = redirectionTarget;
        }

        return url;
    }

    private async getLatestPolicyUrl(): Promise<Array<string>> {
        const [[timeOffPolicy]] = await TimeOffPolicy.limit(1).get();

        if (!timeOffPolicy) {
            // This enrolled employee step in side bar won't be markable as complete until they have a policy
            return ['/', 'time-off', 'v3', 'policy', 'create'];
        }

        return ['/', 'time-off', 'v3', 'policy', timeOffPolicy.id, 'enrolled-employees'];
    }

    private async getDefaultWorkScheduleUrl(): Promise<Array<string>> {
        const [[workSchedule]] = await WorkSchedule.limit(1).get();

        if (!workSchedule) {
            // if for some reason default work schedule doesn't exist, redirect them to settings
            return ['/', 'time-off', 'v3', 'settings'];
        }

        return ['/', 'time-off', 'v3', 'work-schedule', workSchedule.id, 'details'];
    }
}
