import { Model } from '@models/core/base.model';

export class CompanyLogo extends Model {
    protected static _type = 'companyLogos';

    protected static _version = 'v2';

    protected static _resource = 'companyBrand/companyLogo';

    get companyBrandId(): number {
        return this._attributes['companyBrandId'];
    }
}
