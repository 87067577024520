import { Model } from '../core/base.model';
import { BenefitDivision } from './benefit-division.model';

export class BenefitPolicy extends Model {
    protected static _resource = 'benefitManagement/companies/:company/benefitPolicies';

    protected static _version = 'v2';

    get name(): string {
        return this._attributes['name'];
    }

    get policyNumber(): string {
        return this._attributes['policyNumber'];
    }

    get partnerIdentifier(): string {
        return this._attributes['partnerIdentifier'];
    }

    get insuranceCarrier(): string {
        return this._attributes['insuranceCarrier'];
    }

    get benefitRepId(): string {
        return this._attributes['benefitRepId'];
    }

    get companyId(): string {
        return this._attributes['companyId'];
    }

    get notificationsEnabled(): number | null {
        return this._attributes['notificationsEnabled'];
    }

    get conversionEmail(): boolean | null {
        return this._attributes['conversionEmail'];
    }

    get syncedAt(): number | null {
        return this._attributes['syncedAt'];
    }

    get initialDueDate(): number | null {
        return this._attributes['initialDueDate'];
    }

    get createdAt(): string {
        return this._attributes['createdAt'] || null;
    }

    get divisions(): BenefitDivision[] {
        return this._attributes['divisions'] || null;
    }

    get hasEnhancedManagedBenefits(): boolean | null {
        return this._attributes['hasEnhancedManagedBenefits'];
    }
}
