import { ModelMixin } from '@app/models/core/base-generic.model';
import { ProjectAssignment } from './project-assignment.model';
import { ProjectOwnership } from '@models/time-tracking/project-ownership.model';

export class Project extends ModelMixin<Project>() {
    protected static _type = 'timeTracking.projects';
    protected static _resource = 'timeTracking/projects';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['name', 'description', 'isArchived', 'approvalFlow'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(value: string) {
        this._attributes['name'] = value;
    }

    get description(): string | null {
        return this._attributes['description'];
    }

    set description(value: string | null) {
        this._attributes['description'] = value;
    }

    get assignmentsCount(): number {
        return this._attributes['assignmentsCount'];
    }

    get projectAssignments(): ProjectAssignment[] {
        return this.hasMany(ProjectAssignment, 'assignments');
    }

    get approvalFlow(): 'automatic' | 'manager' | 'owner' {
        return this._attributes['approvalFlow'];
    }

    set approvalFlow(value: 'automatic' | 'manager' | 'owner') {
        this._attributes['approvalFlow'] = value;
    }

    /**
     * If a project is archived, it cannot currently be used for tracking time.
     */
    get isArchived(): boolean {
        return this._attributes['isArchived'];
    }

    set isArchived(value: boolean) {
        this._attributes['isArchived'] = value;
    }

    get projectOwnerships(): ProjectOwnership[] {
        return this.hasMany(ProjectOwnership, 'ownerships');
    }

    /**
     * timesheetsSumMinutes is only available to admins
     * and project owners
     */
    get timesheetsSumMinutes(): number {
        return this._attributes['timesheetsSumMinutes'];
    }

    /**
     * timesheetsApprovedSumMinutes is only available to admins
     * and project owners
     */
    get timesheetsApprovedSumMinutes(): number {
        return this._attributes['timesheetsApprovedSumMinutes'];
    }

    /**
     * hasTimesheetEntries is only available to admins
     * and project owners
     */
    get hasTimesheetEntries(): boolean {
        return Boolean(this._attributes['hasTimesheetEntries']);
    }

    /**
     * hasPendingTimesheetEntries is only available to admins
     * and project owners
     */
    get hasPendingTimesheetEntries(): boolean {
        return Boolean(this._attributes['hasPendingTimesheetEntries']);
    }
}
