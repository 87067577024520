import { Pipe, PipeTransform } from '@angular/core';
import { Translatable, isTranslatableObject } from '@app/types/translatable.type';
import { TranslatePipe } from '@ngx-translate/core';

/** @deprecated use `translate` instead. */
@Pipe({ name: 'appTranslate' })
export class AppTranslatePipe extends TranslatePipe implements PipeTransform {
    /** @deprecated use `translate` instead. */
    transform(query: unknown): string {
        let key: string;
        let params: unknown;

        if (query === undefined || query === null) {
            // if the query is null or undefined return a blank string
            return '';
        }

        if (isTranslatableObject(query)) {
            // Compatible with TranslatableObject
            key = query.key;
            params = query?.params;
        } else if (typeof query === 'string') {
            // Compatible with TranslatableKey
            key = query;
            params = undefined;
        } else {
            // We don't know what to do with this. Assume it was a string and return that for backwards compatibility.
            return String(query);
        }

        const translation = super.transform(key, params);
        if (this.isPrimitive(translation) === false) {
            // Fix: User input can collide with folder names under src/assets/i18n/*, return unchanged.
            return String(query);
        }

        return translation;
    }

    private isPrimitive(test: unknown): boolean {
        return test !== Object(test);
    }
}

/** @deprecated use `translate` instead. */
@Pipe({ name: 'appTranslateOptions' })
export class AppTranslateOptionsPipe implements PipeTransform {
    constructor(private appTranslate: AppTranslatePipe) {}

    /**
     * @deprecated use `translate` instead.
     * @param key
     * @param options
     * @param fallbackToKey (optional) When set to false the fallback is empty string. The default fallback is key.
     */
    transform(key: string | null | undefined, options: { [key: string]: Translatable }, fallbackToKey = true): string {
        const translation = options[key ?? ''];
        if (translation) {
            // Translatable was found
            return this.appTranslate.transform(translation);
        }

        if (fallbackToKey && typeof key === 'string') {
            // Translatable was not found return key
            return key;
        }

        // Translatable was not found return empty string
        return '';
    }
}
