<!-- ! THIS COMPONENT IS DEPRECATED: Please use <ui-dialog> DialogComponent instead. -->
<div class="ui page dimmer">
    <div class="content" role="dialog" aria-labelledby="dialog-header">
        <div
            [ngStyle]="{ height: customHeight }"
            class="ui segment"
            [class.tall]="tall"
            [class.wide]="wide"
            [class.thin]="thin"
            [class.medium]="medium"
            [class.stubby]="stubby"
            [class.loading]="isLoading"
        >
            <div class="header-container">
                <h2 id="dialog-header">{{ headerText | appTranslate }}</h2>
                <ui-button *ngIf="secondaryActionText" type="link" class="edit" (click)="secondaryAction.emit()">{{
                    secondaryActionText | appTranslate
                }}</ui-button>
            </div>

            <div class="content">
                <ng-content></ng-content>
            </div>
            <div *ngIf="showButtons" class="actions">
                <ui-button
                    style="float: right; margin-left: 10px"
                    [disabled]="disabled"
                    type="primary"
                    (click)="approve()"
                >
                    {{ label | appTranslate }}
                </ui-button>
                <ui-button style="float: right" type="ghost" (click)="deny()">{{ 'cancel' | appTranslate }}</ui-button>
            </div>
        </div>
    </div>
</div>
