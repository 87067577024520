import { ModelMixin } from '../core/base-generic.model';

export class FeatureRequest extends ModelMixin<FeatureRequest>() {
    protected static _type = 'CreateNoteRequest';

    protected static _resource = 'commonComponents/featureRequest';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['title', 'content', 'tags'];

    async submitRequest(): Promise<void> {
        await ModelMixin().performPost(
            FeatureRequest.buildUrl(),
            FeatureRequest.serializer().serialize(this._attributes)
        );
    }
}
