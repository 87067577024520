import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService, NotifyService } from '@app/services';
import { BulkSelectBaseDialogComponent } from '@app/components';
import { OnboardingReminder } from '@models/employee/onboarding-reminder.model';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-common-bulk-select-onboarding-reminder-dialog',
    templateUrl: './bulk-select-onboarding-reminders.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectOnboardingRemindersComponent extends BulkSelectBaseDialogComponent implements OnInit {
    @Input() label = 'select';
    @Input() headerText = 'components.bulk-select-onboarding-reminders.selectOnboardingReminders';

    searchControl: FormControl<string | null> = new FormControl();

    items: OnboardingReminder[] = [];
    showItems: OnboardingReminder[] = [];
    selectItems: OnboardingReminder[] = [];

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        private notify: NotifyService
    ) {
        super(element, auth);
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(debounceTime(250)).subscribe((filterBy: string) => {
            if (!filterBy) {
                this.showItems = this.items;
                return;
            }

            this.showItems = this.items.filter((r: OnboardingReminder) => {
                const filter = filterBy.toLowerCase();
                const inName = r.name.toLowerCase().indexOf(filter) > -1;
                const inBody = r.body.toLowerCase().indexOf(filter) > -1;

                return inName || inBody;
            });
        });
    }

    selectAll(): void {
        OnboardingReminder.param('company', this.auth.company.id)
            .all()
            .then(([reminders]) => {
                this.selectItems = reminders;
                this.updateModelSelection();
            })
            .catch(() => {
                this.isLoading = false;
                this.notify.error('components.dialog-common.unableToContactServer');
            });
    }

    protected loadItems(): void {
        OnboardingReminder.param('company', this.auth.company.id)
            .page(this.currentPage)
            .get()
            .then(([reminders, meta]) => {
                this.totalResults = meta.pagination.total;
                this.items = reminders;
                this.showItems = reminders;
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
                this.notify.error('components.dialog-common.unableToContactServer');
            });
    }
}
