import { Model } from '@app/interfaces';
import { Serializer } from 'jsonapi-serializer';
import { QueryFetcher } from './query-fetcher.model';

export function ModelStatic<T = any>() {
    class ModelStatic {
        static get version(): string {
            return this._version;
        }

        static get resource(): string {
            return this._resource;
        }

        static get type(): string | null {
            return this._type;
        }

        static get method(): string | null {
            return this._method;
        }

        static get serializeAttributes(): string[] {
            return this._serializeAttributes;
        }

        static buildUrl(paths: string[] = [], urlParams?: { [key: string]: string | number }): string {
            const url = [this._version, this._resource, ...paths].join('/');
            const finalUrl = urlParams
                ? Object.keys(urlParams).reduce((url, paramKey) => {
                      return url.replace(':' + paramKey, urlParams[paramKey].toString());
                  }, url)
                : url;

            return finalUrl;
        }

        protected static _version = 'v2';
        protected static _resource = 'base';
        protected static _type: string | null = null;
        protected static _method: string | null = null;
        protected static _serializeAttributes: string[] = [];
        protected static _dates: string[] = [];
        protected static _datetimes: string[] = [];

        static select(fields: string[]): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.select(fields);
        }

        static all(): Promise<[T[], any]> {
            const builder = new QueryFetcher<T>(this);
            return builder.limit(0).all();
        }

        static where(field: string, value: any): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.where(field, value);
        }

        static when(condition: any, callback: any): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);

            if (condition) {
                return callback(builder);
            }

            return builder;
        }

        static queryParams(field: string, value: any): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.queryParams(field, value);
        }

        static whereIn(field: string, value: any[]): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.whereIn(field, value);
        }

        static param(field: string, value: any): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.param(field, value);
        }

        static first(): Promise<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.first();
        }

        static find(id: number | string): Promise<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.find(id);
        }

        static show(): Promise<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.show();
        }

        static get(): Promise<[T[], any]> {
            const builder = new QueryFetcher<T>(this);
            return builder.get();
        }

        static raw(): Promise<[any, any]> {
            const builder = new QueryFetcher<T>(this);
            return builder.raw();
        }

        static page(page: number): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.page(page);
        }

        static with(relationships: string | string[]): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.with(relationships);
        }
        static getRelated(relationship: string): Promise<Model[]> {
            const builder = new QueryFetcher<T>(this);
            return builder.getRelated(relationship);
        }

        /**
         * The sort order
         */
        static orderBy(field: string, direction: string): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.orderBy(field, direction);
        }

        /**
         * The maximum number of items to return
         */
        static limit(limit: number): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.limit(limit);
        }

        /**
         * Include deleted entities
         */
        static withTrashed(): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.withTrashed();
        }

        /**
         * Only include deleted entities
         */
        static onlyTrashed(): QueryFetcher<T> {
            const builder = new QueryFetcher<T>(this);
            return builder.onlyTrashed();
        }

        /**
         * Set up a new, unmodified query
         */
        static query(): QueryFetcher<T> {
            return new QueryFetcher<T>(this);
        }

        public static serializer(): Serializer {
            return new Serializer(this.type ? this.type : this._resource.split('/').pop(), {
                keyForAttribute: 'camelCase',
                pluralizeType: false,
                attributes: this.serializeAttributes,
            });
        }
    }

    return ModelStatic;
}
