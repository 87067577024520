import { XeroConnectionStatus } from '@app/modules/settings/enums/xero-connection-status.enum';
import { SettingsIntegrationBase } from './integration-base.model';

export class IntegrationXero extends SettingsIntegrationBase {
    protected static _type = 'xeros';

    protected static _resource = 'companyManagement/companies/:company/integrations/xero';

    protected static _serializeAttributes = ['xeroCompanyName', 'xeroConnectionStatus'];

    get xeroCompanyName(): string {
        return this._attributes['xeroCompanyName'];
    }

    get xeroConnectionStatus(): XeroConnectionStatus {
        return this._attributes['xeroConnectionStatus'];
    }
}
