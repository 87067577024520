import { EmployeeHour } from '@app/models/payroll/company/employee-hour.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class HoursColumn extends Column {
    title = 'table.employeeHour.hours';
    property = 'hours';
    sortField = 'hours';
    sortable = true;
    type = CellDisplay.string;

    getDisplayPropertyValue(employeeHour: EmployeeHour): string {
        return Number(employeeHour.hours).toFixed(2);
    }
}
