import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ucfirst' })
export class UcFirstPipe implements PipeTransform {
    transform(str: string): string {
        if (!str) {
            return '';
        }

        return str.charAt(0).toUpperCase() + str.substr(1);
    }
}
