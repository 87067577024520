import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class YearColumn extends Column {
    title = 'table.workers-compensations.year';
    property = 'year';
    sortField = 'year';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    constructor() {
        super();

        const start = 2023;
        const end = new Date().getFullYear() + 1;
        const diffInYears = end - start;

        const rangeOfYears = Array(diffInYears + 1)
            .fill(start)
            .map((year, index) => ({ label: year + index, value: year + index, initiallyActive: false }));

        this.filter = new CheckListFilter('year', [...rangeOfYears]);
    }
}
