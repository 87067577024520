import { Model } from '../core/base.model';
import { BenefitPolicy } from './benefit-policy.model';

export class BenefitDivision extends Model {
    protected static _resource = 'benefitManagement/companies/:company/benefitDivisions';

    protected static _version = 'v2';

    get name(): string {
        return this._attributes['name'];
    }

    get divisionNumber(): string {
        return this._attributes['divisionNumber'];
    }

    get benefitPolicyId(): number | null {
        return this._attributes['benefitPolicyId'];
    }

    get benefitPolicy(): BenefitPolicy {
        return this.hasOne(BenefitPolicy, 'benefitPolicy');
    }
}
