import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { NotifyService } from '@app/services/notify.service';
import { TokenService } from '@app/services/token.service';

@Injectable({ providedIn: 'root' })
export class UserAccessService {
    constructor(
        private auth: AuthService,
        private token: TokenService,
        private notify: NotifyService,
        private router: Router
    ) {}

    logout(): void {
        this.router.navigate(['/logout']);
        this.notify.success('You have been logged out');
    }

    changeRole(roleId: number): void {
        const appLoader: HTMLElement = document.querySelector('.app-loader');
        if (this.token.changeRole(roleId)) {
            this.auth
                .checkPersistentTokens()
                .then(() => {
                    this.reloadPage(appLoader);
                })
                .catch((e) => {
                    this.notify.error(e);
                    setTimeout(() => {
                        this.reloadPage(appLoader);
                    }, 4000);
                });
            return;
        }

        this.notify.error('Could not change roles');
    }

    private reloadPage(appLoader: HTMLElement): void {
        appLoader.style.display = 'block';
        window.location.reload();
    }
}
