<mat-form-field>
    <mat-label>{{ 'settings.integration.apiKey' | translate }}</mat-label>
    <input
        type="text"
        matInput
        matTooltip="Copied to clipboard!"
        matTooltipPosition="above"
        [matTooltipDisabled]="isCopyTooltipDisabled"
        [matTooltipHideDelay]="2000"
        #copyTooltip="matTooltip"
        [(ngModel)]="value"
        [readonly]="readOnly"
        class="copy-input-field"
    />
    <ui-button type="primary" size="compact" class="copy-input-button" (click)="onCopyValue()">{{
        'copy' | translate
    }}</ui-button>
</mat-form-field>
