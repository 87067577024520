import { HttpErrorResponse } from '@angular/common/http';

export class BadGatewayError extends Error {
    httpStatus = 502;

    constructor(res: HttpErrorResponse, message?: string) {
        super(message ? message : res.statusText);
        Object.setPrototypeOf(this, BadGatewayError.prototype);
    }
}
