import { Model } from '@models/core/base.model';
import { DataField } from '@models/settings/data-field.model';
import { DragAndDropOption } from '@app/components/drag-and-drop-with-deletable-options/drag-and-drop-with-deletable-options.component';

export class DataFieldOption extends Model implements DragAndDropOption {
    protected static _resource = 'companyManagement/companies/:company/dataFields/:dataField/dataFieldOptions';

    protected static _version = 'v2';
    protected static _serializeAttributes = ['value', 'orderBy', 'dataFieldId', 'dataValuesCount'];

    get dataFieldId(): number {
        return this._attributes['dataFieldId'];
    }
    set dataFieldId(val: number) {
        this._attributes['dataFieldId'] = val;
    }

    get value(): string {
        return this._attributes['value'];
    }
    set value(val: string) {
        this._attributes['value'] = val;
    }

    get orderBy(): number {
        return this._attributes['orderBy'];
    }
    set orderBy(val: number) {
        this._attributes['orderBy'] = val;
    }

    get dataField(): DataField {
        return this.hasOne(DataField, 'dataField');
    }
    set dataField(val: DataField) {
        this._relationships['dataField'] = val;
    }

    get dataValuesCount(): number {
        return this._attributes['dataValuesCount'] ?? 0;
    }

    get shouldConfirmBeforeRemove(): boolean {
        return this.dataValuesCount > 0;
    }
}
