import { EmployeeHour } from '@app/models/payroll/company/employee-hour.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class ApproverNameColumn extends Column {
    title = 'table.employeeHour.approverName';
    property = 'approverName';
    sortField = 'approverName';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(employeeHour: EmployeeHour): string {
        return employeeHour.approverName ?? 'na';
    }
}
