<ui-card
    [withMargin]="false"
    class="bulk-upload-container height-full"
    [class.drag-over]="isDraggedOver && allowMoreFiles"
    (drop)="isDraggedOver = false; onDrop($event)"
    (dragenter)="isDraggedOver = true"
    (dragleave)="isDraggedOver = false"
    (dragover)="onDrag($event)"
>
    <!-- drop container is only shown until the upload starts -->
    <div class="drop-container" *ngIf="!hasStartedUpload()" [class.condensed]="uploadables.length > 0">
        <!-- We show the big drop area if there are no uploadables yet -->
        <div *ngIf="allowMoreFiles && uploadables.length === 0" class="drop-area">
            <input
                name="fileSelector"
                #fileInput
                type="file"
                [multiple]="true"
                [accept]="allowedTypes"
                (change)="addFiles(fileInput.files)"
            />

            <div class="placeholder">
                <p class="only-large">{{ 'components.bulk-file-uploader.dragAndDropYourFiles' | translate }}</p>
                <p class="only-large">{{ 'components.bulk-file-uploader.or' | translate }}</p>
                <ui-button type="outline" (click)="fileInput.click()">{{
                    'components.bulk-file-uploader.browseFiles' | translate
                }}</ui-button>
                <p class="hint">
                    {{ 'components.bulk-file-uploader.maxSizePerFile' | translate: { maxFileSize: maxFileSizeLabel } }}
                </p>
            </div>
        </div>

        <!-- we show the small drop area if there are already uploadables, but the limit has not been reached -->
        <div *ngIf="allowMoreFiles && uploadables.length > 0" class="drop-area">
            <input
                name="fileSelector"
                #fileInput
                type="file"
                [multiple]="true"
                [accept]="allowedTypes"
                (change)="addFiles(fileInput.files)"
            />

            <div>
                <span class="only-large">{{ 'components.bulk-file-uploader.dropFilesOr' | translate }}&nbsp;</span>
                <ui-button type="link" (click)="fileInput.click()">{{
                    'components.bulk-file-uploader.browse' | translate
                }}</ui-button>
            </div>
        </div>

        <!-- we show the max files warning when they reach the limit-->
        <div *ngIf="!allowMoreFiles && !hasStartedUpload()" class="drop-area">
            <div class="placeholder">
                <p>{{ 'components.bulk-file-uploader.youHaveReachedTheMaximumNumberOfFilesPerUpload' | translate }}</p>
            </div>
        </div>
    </div>

    <!-- Each uploadable is sent to the uploadableTemplate if valid, or invalidFileTemplate if not valid -->
    <div class="uploadable-list" [class.drop-area-hidden]="!allowMoreFiles">
        <div *ngFor="let uploadable of uploadables; index as idx; let first = first; let last = last">
            <div class="uploadable-container" [class.last]="last">
                <ng-container
                    [ngTemplateOutlet]="(uploadable.hasValidFile() && uploadableTemplate) || invalidFileTemplate"
                    [ngTemplateOutletContext]="{ $implicit: uploadable, idx: idx }"
                >
                </ng-container>
            </div>
        </div>
    </div>
</ui-card>

<!-- UPLOAD button and UPLOAD MORE button -->
<div class="flex justify-end align-items-center margin-top-3">
    <ui-button class="margin-right-1" *ngIf="status === 'complete'" type="link" (click)="reset.emit()">{{
        'components.bulk-file-uploader.uploadMore' | translate: { descriptor: uploadDescriptor }
    }}</ui-button>
    <ui-button
        [aria-label]="'components.bulk-file-uploader.upload' | translate"
        type="primary"
        [disabled]="status !== 'ready'"
        (click)="upload.emit()"
        >{{
            (status === 'complete' ? 'components.bulk-file-uploader.uploaded' : 'components.bulk-file-uploader.upload')
                | appTranslate
        }}</ui-button
    >
</div>

<!-- Shown when file is invalid -->
<!-- The uploadable must be removed -->
<ng-template #invalidFileTemplate let-uploadable>
    <div class="invalid-file">
        <div class="file-name">
            <div>
                <mat-icon svgIcon="attach"></mat-icon>
                <span>{{ uploadable.file.name }}</span>
            </div>
            <div *ngIf="uploadable.hasWrongFileType" class="message">
                <mat-icon svgIcon="warning"></mat-icon
                >{{ 'components.bulk-file-uploader.fileIsNotAnAllowedFileType' | translate }}
            </div>
            <div *ngIf="uploadable.isTooLarge" class="message">
                <mat-icon svgIcon="warning"></mat-icon
                >{{
                    'components.bulk-file-uploader.fileExceedsTheMaxSize'
                        | translate: { maxFileSize: uploadable.maxFileSize }
                }}
            </div>
        </div>
        <ui-button type="ghost" size="no-padding" (click)="remove.emit(uploadable)">
            <mat-icon svgIcon="close"></mat-icon>{{ 'components.bulk-file-uploader.remove' | translate }}
        </ui-button>
    </div>
</ng-template>
