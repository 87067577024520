import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppResources } from '@app/app.resources';
import { EmployeeModuleAssignmentData } from '@app/types/employee-module-assignment.type';

@Injectable()
export class EmployeeModuleAssignmentService {
    constructor(private http: HttpClient) {}

    /**
     * Attach/detach many employees to many modules with a single API call.
     * Plays nice with app-common-bulk-select-employee-dialog UI component
     */
    async assign(
        attach: EmployeeModuleAssignmentData[] = [],
        detach: EmployeeModuleAssignmentData[] = []
    ): Promise<void> {
        const payload = {
            data: {
                type: 'employeeModuleAssignments',
                attributes: {
                    attach,
                    detach,
                },
            },
        };

        await this.http.post(AppResources.EmployeeModuleAssignment, payload).toPromise();
    }
}
