import { Component, EventEmitter, Input, Output } from '@angular/core';
import { arrayWrap } from '@app/functions';

const BYTES_IN_MB = 1024000;

@Component({
    selector: 'app-file-drop',
    templateUrl: './file-drop.template.html',
    styleUrls: ['./file-drop.style.scss'],
})
export class FileDropComponent {
    /**
     * This component can take a single string of allowed mime types or an array of allowed file types.
     *
     * Mime types should be provided as a single string, for example: 'image/*' to allow images.
     * File types should be provided as an array of string, for example: ['.jpg', '.bmp', '.svg'].
     *
     * Drag and drop file validation will only occur if a file type list is provided
     */
    @Input() allowedTypes: string | string[] = '*';

    @Input() showAllowableTypes = false;
    @Input() allowMultiple = false;
    @Input() externalErrors: string[] = [];

    /**
     * Max file size in MB
     */
    @Input() maxFileSize: number;

    @Input() inlineView = false;
    @Input() required = false;

    @Output() filesSelected: EventEmitter<FileList> = new EventEmitter<FileList>();

    dragOver = false;
    invalidFileTypesSelected = false;
    invalidAmountOfFiles = false;
    invalidFileSize = false;

    getAllowedTypes(): string {
        // If just a mime type string is given (ie: image/*) use just that
        if (typeof this.allowedTypes === 'string') {
            return this.allowedTypes;
        }

        // Return a conjoined list of file ending types allowed
        return this.allowedTypes.join(',');
    }

    onDrag(event: DragEvent): void {
        event.preventDefault();
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();

        const files = event.dataTransfer.files;
        if (event.dataTransfer.files.length > 0) {
            this.onFilesSelected(files);
        }
    }

    resetValidation(): void {
        this.invalidAmountOfFiles = false;
        this.invalidFileTypesSelected = false;
        this.invalidFileSize = false;
        this.externalErrors = [];
    }

    onFilesSelected(files: FileList): void {
        this.validateFiles(files);
        if (this.isValid()) {
            this.filesSelected.emit(files);
        }
    }

    private isValid(): boolean {
        return (
            !this.invalidFileTypesSelected &&
            !this.invalidAmountOfFiles &&
            !this.invalidFileSize &&
            this.externalErrors.length === 0
        );
    }

    private validateFiles(files: FileList): void {
        const filesArray = Array.from(files);

        // Check multiple
        if (!this.allowMultiple && filesArray.length > 1) {
            this.invalidAmountOfFiles = true;
        }

        // Check valid file endings. If a mime type is provided, skip.
        if (Array.isArray(this.allowedTypes)) {
            this.invalidFileTypesSelected = filesArray.some((file: File) => {
                const fileNameChunks = file.name.split('.');
                const fileType = '.' + fileNameChunks[fileNameChunks.length - 1];
                return !arrayWrap(this.allowedTypes)
                    .map((type) => type.toLocaleLowerCase())
                    .includes(fileType.toLocaleLowerCase());
            });
        }

        // Validate File Size
        if (this.maxFileSize) {
            this.invalidFileSize = filesArray.some((file: File) => file.size > this.maxFileSize * BYTES_IN_MB);
        }
    }
}
