import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApprovalFlowStatus } from '@app/enums';
import { Translatable } from '@app/types/translatable.type';

/**
 * ApprovalFlowStep represents a step in an approval flow process
 *
 * Title and description will be translated if a matching key is found.
 */
export interface ApprovalFlowStep {
    title: Translatable;
    description: Translatable;
    status?: ApprovalFlowStatus;
    hideIndicator: boolean;

    /**
     * Adds an optional button
     */
    buttonText?: string;
}

@Component({
    selector: 'ui-approval-flow',
    templateUrl: './approval-status-flow.template.html',
    styleUrls: ['./approval-status-flow.style.scss'],
})
export class ApprovalStatusFlowComponent {
    private static nextId = 0;

    @Input() id = `approvalFlow-${(ApprovalStatusFlowComponent.nextId += 1)}`;
    @Input() approvalFlowSteps: ApprovalFlowStep[] = [];
    @Input('aria-labelledby') ariaLabelledBy?: string;

    @Output() onButtonClick = new EventEmitter<number>();

    approvalFlowStatus = ApprovalFlowStatus;

    onButtonEmit(index: number): void {
        this.onButtonClick.emit(index);
    }
}
