import { BadgeType } from '@humi-design-library/components/Badge/types';
import { Status } from './status.enum';

export const StatusBadgeTypes: Record<keyof typeof Status, BadgeType> = {
    Completed: 'success',
    Pending: 'warning',
    InProgress: 'warning',
    Scheduled: 'informative',
    Overdue: 'danger',
    Missed: 'danger',
};
