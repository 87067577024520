import { HttpErrorResponse } from '@angular/common/http';

const sampleError = {
    errors: [
        {
            status: '422',
            source: { pointer: '/data/attributes/first-name' },
            title: 'Invalid Attribute',
            detail: 'First name must contain at least three characters.',
        },
    ],
};

/**
 * The proposed standard error format should be the following, based on the current JSON API specs
 *
 *
 *  {
 *      "statusCode": 401, // http status code
 *      "errors": [
 *          {
 *              "detail": "Your company has enforced single sign-on for this account. Please log in with a single sign-on provider, or contact your administrator.", // text description of the error
 *              "status": "401", // string http status code
 *              "title": "This account is locked", // short, human-readable summary of the problem.
 *              "code": "12345", // application-specific string error code
 *              "source": {
 *                  "pointer": "", // specific field or path in a file that caused this error, if present
 *                  "parameter": "email", // URI query parameter caused the error, if present
 *                  "header": "application/content-type", // name of single request header that caused the error, if responsible
 *                  "line": "42", // specific line where the exception is originating from. It is optional to throw.
 *              },
 *              "meta": { // a meta object containing non-standard meta-information about the error.
 *                  "foo": "bar",
 *                  "baz": [
 *                      "some": "value",
 *                  ]
 *              }
 *          },
 *      ]
 *  }
 *
 */

type JsonApiErrorSource = {
    pointer?: string;
    line?: string;
    parameter?: string;
    header?: string;
};

export class JsonApiError {
    private _status: number;
    private _code: string;
    private _title: string;
    private _detail: string;
    private _meta: any;
    private _source: JsonApiErrorSource | null;
    private _raw: HttpErrorResponse;

    constructor(errorResponse?: HttpErrorResponse) {
        this._raw = errorResponse;

        let response: any = errorResponse;
        if (typeof response === 'undefined') {
            response = { error: sampleError };
        }
        if (response.error && response.error.length) {
            if (response.error.message) {
                this.parseStandard(response.error, response.status);
            }
            if (response.error.errors && !response.error.message) {
                this.parseJsonApi(response.error);
            }
        } else {
            this.parseEmpty(response.status);
        }
    }

    get raw(): HttpErrorResponse {
        return this._raw;
    }

    get status(): number {
        return this._status;
    }
    get code(): string {
        return this._code;
    }
    get title(): string {
        return this._title;
    }
    get detail(): string {
        return this._detail;
    }
    get meta(): any {
        return this._meta;
    }
    get source(): JsonApiErrorSource | null {
        return this._source;
    }

    /**
     * Parse the first error occurence from the response
     * @param {[type]} jsonApiObject [description]
     */
    private parseJsonApi(jsonApiObject: any): void {
        const errors = jsonApiObject.errors;
        if (errors[0]) {
            const firstError = errors[0];
            this._status = firstError.status;
            this._code = firstError.code;
            this._title = firstError.title;
            this._detail = firstError.detail;
            this._meta = firstError.meta;
            this._source = firstError.source;
        }
    }

    /**
     * Parse the first error occurence from the response
     * @param {[type]} jsonStandardObject [description]
     */
    private parseStandard(jsonStandardObject: any, status: number): void {
        this._status = status;
        this._title = jsonStandardObject.message;
        this._detail = jsonStandardObject.message;
    }

    /**
     * Parse jsut the status code from the response error
     * @param {[type]} jsonStandardObject [description]
     */
    private parseEmpty(status: number): void {
        this._status = status;
    }
}
