import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FileHelperService } from '@app/services';
import { File as FileModel } from '@models/common/file.model';
import { HasFileModel } from './has-file.inferface';
import { RelationsUpdate } from './relations-update.inferface';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.template.html',
    styleUrls: ['./file-uploader.style.scss'],
})
export class FileUploaderComponent implements OnChanges {
    @Input() files: FileModel[] = [];
    @Input() hasFiles: HasFileModel[] = [];
    @Input() allowedTypes: string[] = ['*'];
    @Input() allowMultiple = true;

    fileBlobs: File[] = [];
    protected detachableFiles: FileModel[] = [];

    constructor(protected fileHelper: FileHelperService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasFiles) {
            this.fileBlobs = [];
            this.files = this.hasFiles.map((file: HasFileModel) => file.file);
        }
    }

    async saveFiles(): Promise<RelationsUpdate> {
        const files = await this.getFileModels();

        return {
            attach: files.map((file: FileModel) => file.id),
            detach: this.detachableFiles.map((file: FileModel) => file.id),
        };
    }

    showInput(): boolean {
        return this.allowMultiple || this.files.length + this.fileBlobs.length < 1;
    }

    addInputFiles(files: FileList): void {
        this.fileBlobs = this.fileBlobs.concat(Array.from(files));
    }

    removeInputFile(file: File): void {
        const index = this.fileBlobs.indexOf(file);
        if (index !== -1) {
            this.fileBlobs.splice(index, 1);
        }
    }

    removeFileModel(file: FileModel): void {
        const index = this.files.indexOf(file);
        if (index !== -1) {
            this.files.splice(index, 1);
        }
        this.detachableFiles.push(file);
    }

    protected async getFileModels(): Promise<FileModel[]> {
        const promises = this.fileBlobs.map((file) => {
            return this.fileHelper.store(file);
        });
        return Promise.all(promises);
    }
}
