import { Injectable } from '@angular/core';
import { MenuItem } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';

const SettingsRoleMenu: MenuItem[] = [
    {
        key: 'settings.roleMenu.role',
        link: ['/settings', 'roles', ':role'],
        routerLinkExact: true,
    },
    {
        key: 'settings.roleMenu.employees',
        link: ['/settings', 'roles', ':role', 'employees'],
        routerLinkExact: true,
    },
];

@Injectable()
export class SettingsRoleMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = SettingsRoleMenu;
    }
}
