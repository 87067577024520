import { Model } from '@models/core/base.model';

export class TimeOffApproverType extends Model {
    protected static _resource = 'timeOffV3/approverTypes';

    protected static _version = 'v2';

    protected static _type = 'timeOffApproverTypes';

    get name(): string {
        return this._attributes['name'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    isSpecificPerson(): boolean {
        return this.name === 'Specific Person';
    }
}
