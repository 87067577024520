import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface DeprecatedFilterValues {
    [key: string]: string | number | boolean | DeprecatedFilterValues;
}

@Component({
    selector: 'app-deprecated-filter',
    templateUrl: './deprecated-filter.template.html',
    styleUrls: ['./deprecated-filter.style.scss'],
})
export class DeprecatedFilterComponent implements OnInit {
    /**
     * An object whose keys are the strings of the filterable resources appends with 'Id' (eg. departmentId)
     * The value associate with those keys are the string name of the filter (eg. Department)
     * Ex:
     * {
     *      departmentId: 'Departments',
     *      officeId: 'Offices',
     * }
     */
    @Input() filters: any;

    /**
     * An object whose keys are the strings of the filterable resources appends with 'Id' (eg. departmentId)
     * The value associated with those keys are also objects.
     * These objects have a property for each department where
     * the key is the department id and the value is the string name of the department
     *
     * Ex:
     * {
     *      departmentId: {
     *          1: 'Sales'
     *          2: 'Marketing'
     *          },
     *      officeId: {
     *          1: 'New York'
     *          2: 'Seattle'
     *          }
     * }
     */
    @Input() filterValues: DeprecatedFilterValues;
    @Input() icon = 'filter';
    @Input() capitalize = false;
    @Input() activeFilters: any = {};

    @Output() filter: EventEmitter<any> = new EventEmitter<any>();
    @Output() scrolledToBottom: EventEmitter<string> = new EventEmitter<string>();

    activeFilterCount = 0;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        this.activeFilterCount = Object.keys(this.activeFilters).length;
        this.renderDropdown();

        // If active filters are passed in, filter by those filters
        if (this.activeFilterCount) {
            this.filter.emit(this.activeFilters);
        }
    }

    getFilterKeys(): string[] | any[] {
        return this.filters ? Object.keys(this.filters) : [];
    }

    getFilterValueKeys(key: string): string[] | any[] {
        return this.filterValues[key] ? Object.keys(this.filterValues[key]) : [];
    }

    isArray(item: any): boolean {
        return Array.isArray(item);
    }

    isObject(item: any): boolean {
        return typeof item === 'object';
    }

    toggle($event: Event, name: string, value?: string): void {
        $event.stopPropagation();
        if (!value) {
            if (!this.activeFilters[name]) {
                this.activeFilters[name] = true;
                this.activeFilterCount++;
                this.filter.emit(this.activeFilters);
                return;
            }
            this.activeFilters[name] = false;
            this.activeFilterCount--;
            this.filter.emit(this.activeFilters);
            return;
        }
        if (!this.activeFilters[name]) {
            this.activeFilters[name] = [];
        }
        if (this.activeFilters[name].indexOf(value) >= 0) {
            this.activeFilterCount--;
            this.activeFilters[name].splice(this.activeFilters[name].indexOf(value), 1);
        } else {
            this.activeFilterCount++;
            this.activeFilters[name].push(value);
        }

        this.filter.emit(this.activeFilters);
    }

    toggled(name: string, value?: string): any {
        if (!value) {
            if (!this.activeFilters[name]) {
                return false;
            }
            return this.activeFilters[name];
        }

        if (!this.activeFilters[name]) {
            return false;
        }

        return this.activeFilters[name].indexOf(value) >= 0;
    }

    clear(): void {
        this.activeFilterCount = 0;
        this.activeFilters = {};
        this.filter.emit(this.activeFilters);
    }

    detectScrollEnd($event, key: string): void {
        if ($event.target.offsetHeight + $event.target.scrollTop >= $event.target.scrollHeight) {
            this.scrolledToBottom.emit(key); // Emit the filter key we want to action on when the user scrolls to the bottom
        }
    }

    private renderDropdown(): void {
        setTimeout(() => {
            $(this.element.nativeElement).find('.ui.dropdown:not(.item)').dropdown({
                action: 'hide',
            });
            $(this.element.nativeElement).find('.ui.dropdown.item').dropdown({
                action: 'nothing',
            });
        });
    }
}
