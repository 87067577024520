<!-- Pronouns -->
<div class="app-grid">
    <mat-form-field class="columns-11">
        <mat-label appRemoveAriaOwns>{{ 'components.add-pronouns.pronounsLabel' | translate }}</mat-label>
        <mat-select
            #matSelect
            multiple
            type="text"
            placeholder="{{ 'components.add-pronouns.pronounsPlaceholder' | translate }}"
            name="defaultPronounsField"
            [compareWith]="compareWith"
            (selectionChange)="onPronounsSelect($event)"
            [formControl]="pronounsSelectControl"
        >
            <mat-option *ngFor="let pronoun of defaultPronounsList" [value]="pronoun">
                {{ pronoun.tag | appTranslate }}
            </mat-option>
            <mat-option (click)="onAddUserDefinedPronoun()">
                {{ 'components.add-pronouns.selfIdentifyLabel' | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Pronouns tooltip -->
    <ui-info class="columns-1" text="{{ 'components.add-pronouns.pronounsTooltip' | translate }}"></ui-info>
</div>

<!-- User defined pronouns -->
<ng-container *ngFor="let userDefinedPronoun of userDefinedPronouns; let i = index; let first = first">
    <!-- Label -->
    <div class="app-grid label">
        <span class="columns-12" *ngIf="first">{{ 'components.add-pronouns.myPronounsLabel' | translate }}</span>
        <span class="columns-12" *ngIf="!first">{{ 'components.add-pronouns.and' | translate }} </span>
    </div>

    <!-- User defined pronoun input -->
    <div class="app-grid user-defined-pronoun">
        <mat-form-field class="columns-4">
            <input
                name="userDefinedPronounFirstValue{{ i }}"
                type="text"
                [ngModel]="userDefinedPronoun.value[0]"
                (ngModelChange)="setUserDefinedPronoun($event, userDefinedPronoun, 0)"
                required
                matInput
            />
        </mat-form-field>
        <span class="columns-1 margin-top-4">/</span>
        <mat-form-field class="columns-4">
            <input
                name="userDefinedPronounSecondValue{{ i }}"
                type="text"
                [ngModel]="userDefinedPronoun.value[1]"
                (ngModelChange)="setUserDefinedPronoun($event, userDefinedPronoun, 1)"
                required
                matInput
            />
        </mat-form-field>

        <ui-button type="ghost" class="columns-1 margin-top-3 remove-pronoun" (click)="removeUserDefinedPronoun(i)">
            <mat-icon svgIcon="close"></mat-icon> {{ 'components.add-pronouns.removeButtonLabel' | translate }}
        </ui-button>
    </div>
</ng-container>

<!-- Hide add button if there are already 3 additional pronouns -->
<div class="app-grid">
    <ui-button
        class="columns-1 buttons margin-bottom-1 add-pronoun"
        *ngIf="userDefinedPronouns?.length && userDefinedPronouns?.length < maxUserDefinedPronouns"
        type="link"
        (click)="onAddUserDefinedPronoun(); $event.preventDefault()"
        ><mat-icon svgIcon="plus"></mat-icon>
        {{ 'components.add-pronouns.addButtonLabel' | translate }}
    </ui-button>
</div>
