<!-- Form -->
<div *ngIf="!uploadProgress && !image" class="h-100">
    <app-image-upload #imageUpload (imageUploaded)="setUploadedImage($event)"></app-image-upload>
</div>

<div *ngIf="!uploadProgress && image">
    <app-image-cropper class="margin-bottom-4" #imageCropper [source]="image.src"></app-image-cropper>

    <ui-dialog-footer>
        <ui-button type="outline" (click)="image = null">{{ 'account.profileImage.new' | appTranslate }}</ui-button>
        <ui-button type="primary" (click)="upload(imageCropper.getCroppedCanvas(), image)">
            {{ 'save' | translate }}
        </ui-button>
    </ui-dialog-footer>
</div>

<div *ngIf="uploadProgress">
    <p>{{ 'uploading' | translate }}...</p>
    <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
</div>
