export const getElementOffsetTop: (element: HTMLElement) => number = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return rect.top + scrollTop;
};

export const getElementOffsetLeft: (element: HTMLElement) => number = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    return rect.left + scrollLeft;
};
