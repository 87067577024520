import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@app/models/company/company.model';
import { EmployeeFilter } from '@app/models/employee/employee-filter.model';
import { Employee } from '@app/models/employee/employee.model';
import { stringify } from 'qs/dist/qs';

interface PreviewData {
    employeesToPreview: Employee[];
    totalEmployees: number;
}

@Injectable()
export class EmployeeFilterPreviewService {
    constructor(private http: HttpClient) {}

    async preview(employeeFilter: EmployeeFilter, company: Company, pageNumber = 1): Promise<PreviewData> {
        const parameters = stringify({
            page: { number: pageNumber },
        });

        const url = `/v2/companyManagement/companies/${company.id}/employeeFilters/${employeeFilter.id}/preview?${parameters}`;

        const response: any = await this.http.get(url).toPromise();

        const employeesToPreview: Employee[] = response.data.map((employee) => {
            return <Employee>employee.attributes;
        });

        const totalEmployees = response.meta.pagination.total;

        return { employeesToPreview, totalEmployees };
    }
}
