import { Directive } from '@angular/core';
import { DialogComponent } from '@app/components';

@Directive({
    selector: 'ui-dialog[promptDialog]',
    exportAs: 'uiPromptDialogDirective',
})
export class PromptDialogDirective {
    constructor(private dialog: DialogComponent) {
        this.dialog.primaryButtonType = 'primary';
        this.dialog.primaryButtonLabel = 'Accept';
    }
}
