import { Filter } from '@app/modules/table/classes/filter.abstract';
import { ColumnFilterDisplay } from '@app/modules/table/types/column-filter-display.type';

/**
 * Filters that live in the column headers. Extend this class if your filter belongs there.
 */
export abstract class ColumnFilter extends Filter {
    /**
     * The type of inputs that this filter will display.
     * For example, checklist
     */
    abstract type: ColumnFilterDisplay;
}
