import { Injectable } from '@angular/core';
import { ReactiveService } from '@app/classes';
import { Employee } from '@app/models/employee/employee.model';
import { Task } from '@app/models/tasks/task.model';
import { AuthService, FileHelperService, NotifyService } from '@app/services';

@Injectable({
    providedIn: 'root',
})
export class TasksService extends ReactiveService {
    private _totalIncompleteUserTasks = 0;

    constructor(
        private notify: NotifyService,
        protected auth: AuthService,
        protected fileHelper: FileHelperService
    ) {
        super();
        this.loadIncompleteUserTasks();
    }

    get totalIncompleteUserTasks(): number {
        return this._totalIncompleteUserTasks;
    }

    async attachFile(files: FileList, task: Task): Promise<void> {
        this.loading.next(true);
        const file = files[0];

        const savedfile = await this.fileHelper.store(file);
        task.fileId = savedfile.id;
        task.filename = savedfile.name;

        await task
            .updateFile()
            .catch((err) => this.notify.error(err.message))
            .finally(() => this.loading.next(false));
    }

    async removeFile(task: Task): Promise<void> {
        this.loading.next(true);
        task.fileId = null;
        task.filename = null;

        await task
            .updateFile()
            .catch((err) => this.notify.error(err.message))
            .finally(() => this.loading.next(false));
    }

    async toggleCompletion(task: Task): Promise<void> {
        this.loading.next(true);
        return task
            .toggleCompletion()
            .then((completedAt) => {
                task.completedAt = completedAt;
                this.loadIncompleteUserTasks();
            })
            .catch((err) => this.notify.error(err.message))
            .finally(() => this.loading.next(false));
    }

    async deleteTask(task: Task, employee: Employee): Promise<void> {
        this.loading.next(true);
        await task
            .param('employee', employee.id)
            .delete()
            .catch((err) => this.notify.error(err.message))
            .finally(() => this.loading.next(false));
    }

    async sendReminder(task: Task): Promise<void> {
        this.loading.next(true);
        await task
            .sendReminder()
            .then(() => {
                this.notify.success('employees.tasksIndex.reminderSent');
            })
            .catch((err) => this.notify.error(err.message))
            .finally(() => this.loading.next(false));
    }

    /**
     * Loads the number of incomplete user tasks into the service
     * This is separated out from the other calls as this one is necessary for unread badges which is called on initial app load
     */
    private async loadIncompleteUserTasks(): Promise<void> {
        this.loading.next(true);
        return await Task.param('employee', this.auth.employee.id)
            .where('excludeTerminatedSubjects', true)
            .where('assigneeId', this.auth.employee.id)
            .where('isComplete', false)
            .get()
            .then(([, { pagination }]) => (this._totalIncompleteUserTasks = pagination.total))
            .catch((err) => this.notify.error(err.message))
            .finally(() => this.loading.next(false));
    }
}
