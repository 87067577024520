import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {
    private values: Map<string, any> = new Map<string, any>();
    private maxEntries = 20;

    get(key: string): any {
        const hasKey = this.values.has(key);
        let entry: any;
        if (hasKey) {
            // peek the entry, re-insert for LRU strategy
            entry = this.values.get(key);
            this.values.delete(key);
            this.values.set(key, entry);
        }

        return entry;
    }

    put(key: string, value: any) {
        if (this.values.size >= this.maxEntries) {
            // least-recently used cache eviction strategy
            const keyToDelete = this.values.keys().next().value;

            this.values.delete(keyToDelete);
        }

        this.values.set(key, value);
    }
}
