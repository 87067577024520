import { Injectable } from '@angular/core';
import { SocketEventTypes } from '@app/enums';
import { arrayWrap } from '@app/functions/array-wrap';
import { Employee } from '@app/models/employee/employee.model';
import { AuthService } from '@app/services/auth.service';
import { TokenService } from '@app/services/token.service';
import { environment } from '@env/environment';
import Echo from 'laravel-echo';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Used for listening to Pusher Events
 *
 * Provides convenient methods for listening to events on either
 * Employee or Company wide channels (private)
 */
@Injectable()
export class SocketService {
    private echo: Echo;

    private _channelNames: string[] = [];
    private _onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private auth: AuthService,
        private tokenService: TokenService
    ) {
        this.initEcho();
        this.subscribeToAuthEvents();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    /**
     * Returns an Observable that sends an event any time the
     * company channel has a message
     *
     * This channel can be listened on by multiple authenticated employees
     * of the same company
     */
    getCompanyChannel<T = any>(socketEventTypes: SocketEventTypes | SocketEventTypes[]): Observable<T> {
        return this.generateSubjectFromChannel('company.' + this.auth.company.id, socketEventTypes).asObservable();
    }

    /**
     * Returns an Observable that sends an event any time the
     * this specific employee channel has a message
     *
     * Messages in this channel are only for the current employee
     */
    getEmployeeChannel<T = any>(
        employee: Employee,
        socketEventTypes: SocketEventTypes | SocketEventTypes[]
    ): Observable<T> {
        return this.generateSubjectFromChannel('employee.' + employee.id, socketEventTypes).asObservable();
    }

    private generateSubjectFromChannel<T = any>(
        channelName: string,
        socketEventTypes: SocketEventTypes | SocketEventTypes[]
    ): Subject<T> {
        const subject = new Subject<any>();

        const echoChannel = this.echo.private(channelName);
        this._channelNames.push(channelName);

        arrayWrap(socketEventTypes).forEach((type: SocketEventTypes) =>
            echoChannel.listen(type, (payload: any) => {
                subject.next(payload);
            })
        );

        return subject;
    }

    private initEcho(): void {
        this.echo = new Echo({
            auth: {
                headers: {
                    Authorization: this.tokenService.token,
                },
            },
            authEndpoint: environment.api + '/broadcasting/auth',
            broadcaster: 'pusher',
            key: environment.pusher.key,
            cluster: environment.pusher.cluster,
            forceTLS: true,
        });
    }

    /**
     * Subscribe to auth events
     *
     * When the user logs in, refresh the Echo client so we get the correct auth toekn.
     * When the user logs out, tell Echo to stop sending us messages
     */
    private subscribeToAuthEvents(): void {
        this.auth.onLogin.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.initEcho());

        this.auth.onLogout
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(() => this._channelNames.forEach((channelName) => this.echo.leave(channelName)));
    }
}
