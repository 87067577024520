/**
 * Simple entity actions returns aggregated permissions
 * for frontend view and edit actions
 * combining search,view into 'view' and
 * create, update, delete into 'edit'
 */
export class SimpleEntityActions {
    constructor(
        private readonly entity: string,
        private actions: string[] = []
    ) {}

    some(...actions: string[]): string[] {
        const validActions = [];
        for (const action of actions) {
            if (this.actions.includes(action)) {
                validActions.push(action);
                continue;
            }
            if (['search', 'view', 'create', 'update', 'delete'].includes(action)) {
                validActions.push(action);
            }
        }
        return [...validActions.map((action) => action + '.' + this.entity)];
    }

    /**
     * View includes both Search and View
     */
    get view(): string[] {
        return [`search.${this.entity}`, `view.${this.entity}`];
    }

    /**
     * Edit includes Create, Update, and Delete
     */
    get edit(): string[] {
        return [`create.${this.entity}`, `update.${this.entity}`, `delete.${this.entity}`];
    }

    /**
     * All includes both Edit and View
     */
    get all(): string[] {
        return [...this.view, ...this.edit, ...this.actions.map((action) => action + '.' + this.entity)];
    }
}
