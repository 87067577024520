<div class="inline fields">
    <div class="field" [class.disabled]="disabled">
        <div class="ui radio checkbox">
            <input type="radio" [attr.checked]="toggle ? 'checked' : null" [attr.name]="name" [value]="true" />
            <label>{{ positiveLabel | appTranslate }}</label>
        </div>
    </div>
    <div class="field">
        <div class="ui radio checkbox" [class.disabled]="disabled">
            <input type="radio" [attr.checked]="!toggle ? 'checked' : null" [attr.name]="name" [value]="false" />
            <label>{{ negativeLabel | appTranslate }}</label>
        </div>
    </div>
</div>
