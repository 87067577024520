import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { addMonths, subMonths } from 'date-fns';

@Component({
    selector: 'app-calendar-nav',
    templateUrl: './calendar-nav.template.html',
    styleUrls: ['./calendar-nav.style.scss'],
})
export class CalendarNavComponent implements OnInit {
    date: Date = new Date();
    @Output() private selectDate: EventEmitter<any> = new EventEmitter<any>(true);
    private flatpickr;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        this.selectDate.emit(this.date);
        this.initializeDatePicker();
    }

    onClickToday(): void {
        this.date = new Date();
        this.selectDate.emit(this.date);
    }

    onMoveNextMonth(): void {
        this.date = addMonths(this.date, 1);
        this.selectDate.emit(this.date);
    }

    onMovePreviousMonth(): void {
        this.date = subMonths(this.date, 1);
        this.selectDate.emit(this.date);
    }

    onShowDatePicker(): void {
        this.flatpickr.setDate(this.date);
        this.flatpickr.open();
    }

    private initializeDatePicker(): void {
        this.flatpickr = $(this.element.nativeElement)
            .find('[type="date"]')
            .flatpickr({
                clickOpens: false,
                defaultDate: this.date,
                onChange: (dateArray) => {
                    this.date = dateArray[0];
                    this.selectDate.emit(this.date);
                },
                onClose: () => {},
            });
    }
}
