import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PayrollResources } from '../payroll.resources';

@Injectable()
export class VacationPayoutService {
    constructor(private http: HttpClient) {}

    async onSaveVacationPayoutOrRepayment(
        employeeId: number,
        paystubId: number,
        payoutAmount: string,
        clearVacationTimeBalance: boolean,
        scheduledVacationPayoutOrRepaymentId: number
    ): Promise<void> {
        const urlResource = this.determineUrlResource(scheduledVacationPayoutOrRepaymentId);

        const vacationPayoutPayload = {
            data: {
                type: 'schedule_vacation_payouts',
                attributes: {
                    amount: payoutAmount,
                    clearVacationTimeBalance: clearVacationTimeBalance,
                },
            },
        };

        if (!scheduledVacationPayoutOrRepaymentId) {
            await this.http
                .post(
                    urlResource.replace(':paystub', paystubId.toString()).replace(':employee', employeeId.toString()),
                    vacationPayoutPayload
                )
                .toPromise();
        } else {
            let payoutOrRepaymentIdReplacementParam = ':scheduledVacationPayout';
            await this.http
                .put(
                    urlResource
                        .replace(':paystub', paystubId.toString())
                        .replace(':employee', employeeId.toString())
                        .replace(payoutOrRepaymentIdReplacementParam, scheduledVacationPayoutOrRepaymentId.toString()),
                    vacationPayoutPayload
                )
                .toPromise();
        }
    }

    determineUrlResource(scheduledVacationPayoutOrRepaymentId: number): string {
        return scheduledVacationPayoutOrRepaymentId
            ? PayrollResources.UpdateTimeOffVacationPayout
            : PayrollResources.TimeOffVacationPayout;
    }
}
