import { ModelMixin } from '../core/base-generic.model';

export class WorkersCompensation extends ModelMixin<WorkersCompensation>() {
    protected static _resource = 'payroll/companies/:company/workersCompensations';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['name', 'rate', 'year', 'jurisdiction'];

    get id(): number {
        return this._attributes['id'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get rate(): number {
        return this._attributes['rate'];
    }

    set rate(val: number) {
        this._attributes['rate'] = val;
    }

    get year(): number {
        return this._attributes['year'];
    }

    set year(val: number) {
        this._attributes['year'] = val;
    }

    get jurisdiction(): string {
        return this._attributes['jurisdiction'];
    }

    set jurisdiction(val: string) {
        this._attributes['jurisdiction'] = val;
    }

    get employeesAssignedCount(): string {
        return this._attributes['employeesAssignedCount'];
    }
}
