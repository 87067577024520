import moment from 'moment';

export function stringToDate(value: unknown | string, format?: string): any {
    if (typeof value === 'string') {
        try {
            let a, d;

            if (value.indexOf(' ') > -1) {
                a = value.split(/[^0-9]/);
                d = new Date(+a[0], +a[1] - 1, +a[2], +a[3], +a[4], +a[5]);
            } else {
                a = value.split(/[^0-9]/);
                d = new Date(+a[0], +a[1] - 1, +a[2], 0, 0, 0);
            }

            if (format) {
                return moment(d).format(format);
            }

            if (+a[0] < 1900) {
                d.setUTCFullYear(+a[0]);
            }

            return d.toISOString();
        } catch (e) {
            return new Date().toISOString();
        }
    }

    return value;
}
