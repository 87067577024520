export const toDisplayFormat = (time: string): string => {
    if (!time || !time.trim()) {
        return '';
    }

    let [meridian] = time.match(/(am|pm)/gi) ?? [null];
    if (!meridian) {
        meridian = 'am';
    }

    const cleanedString = time.replace(/[A-z]/g, '');
    let [hoursString, minutesString] = cleanedString.split(/[:-\s]/g);

    if (!minutesString && hoursString.length > 2) {
        minutesString = hoursString.substr(2, 4);
        hoursString = hoursString.substr(0, 2);
    }

    let hoursValue = parseInt(hoursString);
    let minutesValue = parseInt(minutesString) || 0;

    if (hoursValue > 12) {
        meridian = 'pm';
        hoursValue %= 12;
    }

    if (minutesValue > 59) {
        minutesValue = 0;
    }

    // Default invalid times to midnight
    if (!hoursValue && !minutesValue) {
        return '12:00am';
    }

    const minutesWithLeadingZeroes = ('' + minutesValue).padStart(2, '0');

    return `${hoursValue}:${minutesWithLeadingZeroes}${meridian.toLowerCase()}`;
};
