import { Pipe, PipeTransform } from '@angular/core';
import { PronounsCollection } from '@app/interfaces/pronouns.interface';

@Pipe({ name: 'pronouns' })
export class PronounsPipe implements PipeTransform {
    transform(pronouns: PronounsCollection | null): string[] {
        if (!pronouns) {
            return;
        }

        return this.displayPronouns(pronouns);
    }

    displayPronouns(pronouns: PronounsCollection): string[] {
        return pronouns.map((pronoun) => {
            if (pronoun.value.length > 1) {
                return pronoun.value.join('/');
            } else {
                return pronoun.value[0];
            }
        });
    }
}
