<ng-container *ngIf="menu$ | async as menuItems">
    <!--
    an inline style is used here for gap so that the value can be used for calculations within the component.
-->
    <div id="tab-bar-container" *ngIf="menuItems.length" [ngStyle]="{ gap: MENU_ITEM_GAP + 'px' }" role="list">
        <ng-container *ngFor="let menuItem of menuItems; let index = index; let count = count">
            <!-- Contains the regular horizontal menu items-->
            <ng-container
                [ngTemplateOutlet]="horizontalMenuItem"
                [ngTemplateOutletContext]="{ menuItem, isHidden: isItemHidden(index) }"
            ></ng-container>
        </ng-container>
        <!-- The "more" button, is only visible if we've set a numItemsFitInScreenWidth value, meaning not all items fit.
    visibility is used so that the element still has a calculated width. This allows us to determine whether it will fit on screen or not-->
        <ng-container *ngIf="menuItems.slice(numItemsFitInScreenWidth ?? 0) as moreMenuItems">
            <div
                id="more"
                class="item"
                [ngStyle]="{ visibility: numItemsFitInScreenWidth ? 'visible' : 'hidden' }"
                [attr.role]="numItemsFitInScreenWidth ? 'listitem' : undefined"
                [attr.aria-hidden]="!numItemsFitInScreenWidth"
                [class.active]="moreMenuItems.some(isActiveLink)"
            >
                <button role="link" [matMenuTriggerFor]="moreMenu" [attr.tab-index]="numItemsFitInScreenWidth ? 0 : -1">
                    <h4 class="no-margin" role="group">
                        {{ 'employees.tabs.more' | appTranslate }}
                        <mat-icon svgIcon="chevronDown"></mat-icon>
                    </h4>
                </button>
            </div>
            <mat-menu #moreMenu xPosition="before">
                <ng-container *ngFor="let moreMenuItem of moreMenuItems; let moreIndex = index">
                    <a
                        mat-menu-item
                        *ngIf="!moreMenuItem.children"
                        class="tab-bar-item"
                        [routerLink]="moreMenuItem.link"
                        [queryParams]="moreMenuItem.queryParams || null"
                        [routerLinkActiveOptions]="
                            moreMenuItem.routerLinkExact === 'ignore-query-params'
                                ? IGNORE_QUERY_PARAMS
                                : { exact: !!moreMenuItem.routerLinkExact }
                        "
                        routerLinkActive="active"
                        >{{ moreMenuItem.key | appTranslate }}</a
                    >

                    <button
                        mat-menu-item
                        class="tab-bar-item"
                        [matMenuTriggerFor]="nestedMoreMenu"
                        *ngIf="moreMenuItem.children?.length"
                        [class.active]="moreMenuItem.isActiveLink"
                    >
                        <div>{{ moreMenuItem.key | appTranslate }}</div>
                    </button>
                    <mat-menu #nestedMoreMenu xPosition="before" direction="left">
                        <a
                            mat-menu-item
                            *ngFor="let child of moreMenuItem.children"
                            class="tab-bar-item"
                            [routerLink]="child.link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                        >
                            <div>{{ child.key | appTranslate }}</div>
                        </a>
                    </mat-menu>
                </ng-container>
            </mat-menu>
        </ng-container>
    </div>
</ng-container>

<ng-template #moreMenuItem let-menuItem="menuItem" let-isHidden="isHidden"> </ng-template>

<!-- REGULAR HORIZONTAL MENU ITEMS (not "more")
ng-template is used here to allow the isHidden calculation to only be run once and used throughout the template since many attributes rely on this property.
-->
<ng-template #horizontalMenuItem let-menuItem="menuItem" let-isHidden="isHidden">
    <div
        class="item"
        role="listitem"
        [class.offscreen]="isHidden"
        [attr.aria-hidden]="isHidden"
        [class.active]="menuItem.isActiveLink"
    >
        <a
            *ngIf="!menuItem.children"
            [attr.tab-index]="isHidden ? -1 : 0"
            [routerLink]="menuItem.link"
            [queryParams]="menuItem.queryParams || null"
            [routerLinkActiveOptions]="
                menuItem.routerLinkExact === 'ignore-query-params'
                    ? IGNORE_QUERY_PARAMS
                    : { exact: !!menuItem.routerLinkExact }
            "
            routerLinkActive="active"
            ><h4 role="group">{{ menuItem.key | appTranslate }}</h4></a
        >
        <button
            role="link"
            [matMenuTriggerFor]="nestedMenu"
            *ngIf="menuItem.children?.length"
            [attr.tab-index]="isHidden ? -1 : 0"
        >
            <h4 class="no-margin" role="group">
                {{ menuItem.key | appTranslate }}
                <mat-icon svgIcon="chevronDown"></mat-icon>
            </h4>
        </button>
    </div>
    <mat-menu #nestedMenu xPosition="after">
        <a
            mat-menu-item
            *ngFor="let child of menuItem.children"
            class="tab-bar-item"
            [routerLink]="child.link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
        >
            <div>{{ child.key | appTranslate }}</div>
        </a>
    </mat-menu>
</ng-template>
