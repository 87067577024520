import { Injectable } from '@angular/core';
import { ReactiveService } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { CompanyMFA } from '@app/models/company/settings/authentication/company-mfa.model';
import { CompanySSO } from '@app/models/company/settings/authentication/company-sso.model';
import { ModuleName } from '@app/modules/settings/enums/module-names.enum';
import { AuthService, NotifyService } from '@app/services';
import { CompanySetting } from '@models/settings/company-setting.model';
import { groupBy } from 'lodash-es';
import { FeatureService } from '../feature.service';

// Custom setting types can be added here. Currently most settings are boolean toggles that can be kept under booleanSettings
const DEFAULT_SETTINGS: AllSettings = {
    booleanSettings: [],
    companyMFA: undefined,
    companySSO: undefined,
};

type AllSettings = { booleanSettings: CompanySetting[]; companySSO?: CompanySSO; companyMFA?: CompanyMFA };

// Holds all non-boolean settings that were defined in AllSettings
type CustomSettings = Exclude<keyof AllSettings, 'booleanSettings'>;

// Checks if the consumer is attempting to access a custom setting, or a boolean toggle setting
const isCustomSetting = (name: string | CustomSettings): name is CustomSettings => {
    return Object.keys(DEFAULT_SETTINGS)
        .filter((key) => key != 'booleanSettings')
        .includes(name);
};

@Injectable()
export class SettingsService extends ReactiveService {
    // Organizes all settings by module name (utilized in limited areas within the app)
    private moduleSettings?: Record<ModuleName, CompanySetting[]>;
    private allSettings: AllSettings = { booleanSettings: [] };

    constructor(
        private auth: AuthService,
        private notify: NotifyService,
        featureService: FeatureService
    ) {
        super();
        this.loading.next(true);
        featureService.has(FeatureFlag.MFAEnabled).then((isMFAEnabled) => {
            const settingsAPICalls = [this.loadCompanySettings()];
            if (isMFAEnabled) {
                // Don't attempt to load MFA settings unless if flag is set
                settingsAPICalls.push(this.loadSSOSettings());
                settingsAPICalls.push(this.loadMFASettings());
            }
            Promise.allSettled(settingsAPICalls).then(() => this.loading.next(false));
        });
    }

    // This function is only used by boolean toggle settings
    getByModule(moduleName: ModuleName): CompanySetting[] | undefined {
        return this.moduleSettings?.[moduleName];
    }

    // This function is only used by boolean toggle settings
    getById(id: number): CompanySetting | undefined {
        return this.allSettings.booleanSettings.find((companySetting: CompanySetting) => companySetting.id === id);
    }

    // getByName has two possible implementations.
    // If you enter the name of a custom setting, we will return that data type. ie. getByName('companySSO') => CompanySSO
    getByName<T extends CustomSettings>(name: T): AllSettings[T];
    // If you enter a string that is NOT a custom setting, we will search through the boolean settings for it. ie. getByName('timeOffRequests.visibility') => CompanySetting
    getByName(name: string): CompanySetting | undefined;

    getByName(name: string | CustomSettings): AllSettings[CustomSettings] | CompanySetting | undefined {
        if (isCustomSetting(name)) {
            return this.allSettings[name];
        }
        return this.allSettings.booleanSettings.find(
            (companySetting: CompanySetting) => companySetting.setting.name === name
        );
    }

    private async loadCompanySettings(): Promise<void> {
        await CompanySetting.param('company', this.auth.company.id)
            .with('setting')
            .limit(0)
            .all()
            .then(([companySettings]) => {
                this.allSettings.booleanSettings = companySettings;
                this.moduleSettings = groupBy(
                    companySettings,
                    (companySetting) => companySetting.setting.module
                ) as Record<ModuleName, CompanySetting[]>;
                this.changes.next(null);
            })
            .catch(() => this.notify.error('Unable to load settings'));
    }

    private async loadSSOSettings(): Promise<void> {
        await CompanySSO.param('company', this.auth.company.id)
            .show()
            .then((ssoSetting) => (this.allSettings.companySSO = ssoSetting))
            .catch(() => this.notify.error('settings.sso.loadingFailedErrorMessage'));
    }

    private async loadMFASettings(): Promise<void> {
        await CompanyMFA.param('company', this.auth.company.id)
            .with('roles')
            .limit(0)
            .all()
            .then(([mfaSettings]) => {
                // The first time we load the MFA settings they will get created in our API if not already there
                this.allSettings.companyMFA = new CompanyMFA({ value: [] });
                mfaSettings.forEach((mfa) => {
                    if (mfa.value) {
                        this.allSettings.companyMFA?.roles.push(mfa.role);
                    }
                });
            })
            .catch(() => this.notify.error('settings.signIn.mfa.loadingFailedErrorMessage'));
    }
}
