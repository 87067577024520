import { Model } from '@models/core/base.model';

/**
 * EmployeeBenefitBenefitItem represents the BENEFIT (Company Contribution)
 * of an Employee's benefit.
 *
 * Ex: Sarah is given the Company Car benefit. The company must pay for the BENEFIT on this Employee Benefit.
 */
export class EmployeeBenefitBenefitItem extends Model {
    protected static _resource = 'payroll/employees/:employee/employeeBenefits/:employeeBenefit/benefitItems';
    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'paystubId',
        'name',
        'displayName',
        'employeeBenefitId',
        'amount',
        'cash',
        'taxable',
        'pensionable',
        'insurable',
    ];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get displayName(): string {
        return this._attributes['displayName'];
    }

    set displayName(val: string) {
        this._attributes['displayName'] = val;
    }

    get paystubId(): number {
        return this._attributes['paystubId'];
    }

    set paystubId(val: number) {
        this._attributes['paystubId'] = val;
    }

    get employeeBenefitId(): number {
        return this._attributes['employeeBenefitId'];
    }

    set employeeBenefitId(val: number) {
        this._attributes['employeeBenefitId'] = val;
    }

    get amount(): number {
        return this._attributes['amount'];
    }

    set amount(val: number) {
        this._attributes['amount'] = val;
    }

    get cash(): boolean {
        return this._attributes['cash'];
    }

    set cash(val: boolean) {
        this._attributes['cash'] = val;
    }

    get taxable(): boolean {
        return this._attributes['taxable'];
    }

    set taxable(val: boolean) {
        this._attributes['taxable'] = val;
    }

    get pensionable(): boolean {
        return this._attributes['pensionable'];
    }

    set pensionable(val: boolean) {
        this._attributes['pensionable'] = val;
    }

    get insurable(): boolean {
        return this._attributes['insurable'];
    }

    set insurable(val: boolean) {
        this._attributes['insurable'] = val;
    }
}
