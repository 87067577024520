import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileHelperService, NotifyService } from '@app/services';
import { File as FileModel } from '@models/common/file.model';
import * as Sentry from '@sentry/browser';

@Component({
    selector: 'app-audio-playback',
    templateUrl: './app-audio-playback.template.html',
    styleUrls: ['./app-audio-playback.style.scss'],
})
export class AudioPlaybackComponent {
    @Input() file?: FileModel;
    @ViewChild('audio') audio!: ElementRef<HTMLAudioElement>;
    @Output() audioPlayed: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(
        private fileService: FileHelperService,
        private notify: NotifyService
    ) {}

    async onPlay(event: Event): Promise<void> {
        // Stop event propagation from triggering additional events if located within a clickable container (the expansion-panel on mobile employee-cards)
        event.stopPropagation();
        this.initializeAudio();
        if (this.audio === null) {
            return;
        }
        try {
            await this.audio.nativeElement.play();
            this.audioPlayed.emit(true);
        } catch (e) {
            this.notify.error('Error playing file');
            Sentry.captureException({ name: 'Error playing audio file', message: e });
        }
    }

    private initializeAudio(): void {
        if (!this.file?.id) {
            return;
        }
        const audioFileUrl = this.fileService.serveWithToken(this.file.id);

        this.audio.nativeElement.src = audioFileUrl;
        this.audio.nativeElement.preload = 'metadata';
        this.audio.nativeElement.load();
    }
}
