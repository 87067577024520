import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MenuItem, MenuItemGroup } from '@app/interfaces';
import { AuthService } from '@app/services';
import { MenuService } from '@app/services/menu/menu.service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';
interface MenuItemWithDisplayOptions extends MenuItem {
    isActiveLink: boolean;
}
type DisplayMenuItemGroup = MenuItemWithDisplayOptions[];

@Component({
    selector: 'app-tab-bar',
    templateUrl: './tab-bar.template.html',
    host: { role: 'navigation' },
    styleUrls: ['./tab-bar.style.scss'],
})
export class TabBarComponent implements OnInit, OnDestroy {
    @Input() menuService!: MenuService;

    readonly IGNORE_QUERY_PARAMS = {
        matrixParams: 'exact' as const,
        paths: 'exact' as const,
        queryParams: 'ignored' as const,
        fragment: 'exact' as const,
    };

    readonly ID = {
        TAB_BAR_CONTAINER: 'tab-bar-container',
        MORE: 'more',
    };

    // The gap between each menu item in pixels
    readonly MENU_ITEM_GAP = 40;

    menu$?: Observable<DisplayMenuItemGroup>;

    // Contains the number of items that fit within the width of the tab-bar, minus space for the "more" button
    // When undefined, ALL items can be displayed on screen (therefore no need for "more" button)
    numItemsFitInScreenWidth?: number;

    // The horizontal width (in pixels) required to render the "more" button
    moreButtonWidth = 0;

    // Contains the HTMLElements for the menu items, not including the "more" button, and not including comments or other elements without any width
    private filteredMenuElements: HTMLElement[] = [];
    private _onWindowResize$ = new Subject<number>();
    private _onDestroy$ = new Subject();
    private _routeSnapshot: ActivatedRouteSnapshot;

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private element: ElementRef<HTMLElement>,
        private translateService: TranslateService
    ) {
        this._routeSnapshot = this.route.snapshot;

        // Resize events can be quite plentiful, therefore we want to debounce to ensure we aren't performing the calculation too frequently. Every 100ms still creates a smooth experience.
        this._onWindowResize$.pipe(takeUntil(this._onDestroy$), debounceTime(100)).subscribe((entireTabBarWidth) => {
            // Start with the "more" button width since we'll want to ensure there's room to display the button if necessary
            let childrenWidthSum = this.moreButtonWidth;

            // Using `every` here allows us to exit out of the loop early if we calculate that there's not enough room, by returning `false`
            const allItemsFit = this.filteredMenuElements.every((child, i) => {
                childrenWidthSum += child.offsetWidth;

                // If we are checking the very last of the menu items, we no longer consider the "more" button width
                // This is so that we aren't hiding a single item, only to show "more" when that item could have fit on its own
                if (i === this.filteredMenuElements.length - 1) {
                    childrenWidthSum -= this.moreButtonWidth;
                }

                // If the sum has now become greater than the container it's in, we mark the number of items that should still be visible and return false to exit the loop early
                if (childrenWidthSum > entireTabBarWidth) {
                    this.numItemsFitInScreenWidth = i;
                    return false;
                }
                childrenWidthSum += this.MENU_ITEM_GAP;

                // At this point, we've determined the sum is not yet greater than the container width, so we return true since the items so far will all fit
                return true;
            });

            if (allItemsFit) {
                // Signifies that we don't need to hide any items.
                this.numItemsFitInScreenWidth = undefined;
            }
        });
    }

    ngOnInit(): void {
        this.menu$ = combineLatest([
            this.menuService.menu$,
            this.translateService.onLangChange.asObservable().pipe(startWith(this.translateService.currentLang)), // Update menu items when language changes occur
        ]).pipe(
            takeUntil(this._onDestroy$),
            map(([menuItemGroup]) => menuItemGroup),
            /**
             * Since we change some properties on the objects within the array,
             * we don't want to affect upstream
             */
            map(cloneDeep),
            map(this.filterByCanAccessEmployee),
            map(this.replaceUrlParams),
            map(this.configureActiveLink),
            tap(() => {
                // setTimeout is required here due to the fact that the DOM is updated on the changeDetectionCycle AFTER the menu items have been added, we have to wait to properly calculate widths
                setTimeout(() => {
                    // Only continue if the menu has rendered items
                    if (this.tabBarContainer?.hasChildNodes() && this.menuItems.length) {
                        // Only care about items with rendered width
                        this.filteredMenuElements = this.menuItems.filter(
                            (el) => Boolean(el.scrollWidth) && el.id !== this.ID.MORE
                        );
                        const moreButton = this.element.nativeElement.querySelector(`#${this.ID.MORE}`);
                        if (moreButton) {
                            this.moreButtonWidth = moreButton.scrollWidth + this.MENU_ITEM_GAP; // Ensure enough space for the button and the spacing between it and the other elements
                        }
                        this.validateHorizontalWidth();
                    }
                });
            })
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    @HostListener('window:resize')
    validateHorizontalWidth(): void {
        this._onWindowResize$.next(this.element.nativeElement.offsetWidth);
    }

    isActiveLink(menuItem: MenuItemWithDisplayOptions): boolean {
        return menuItem.isActiveLink;
    }

    isItemHidden(itemNumber: number): boolean {
        return this.numItemsFitInScreenWidth ? itemNumber >= this.numItemsFitInScreenWidth : false;
    }

    private get tabBarContainer(): HTMLElement | null {
        return this.element.nativeElement.querySelector(`#${this.ID.TAB_BAR_CONTAINER}`);
    }

    // The HTML elements of all children within the tab-bar
    private get menuItems(): HTMLElement[] {
        return this.tabBarContainer?.childNodes
            ? Array.from(this.tabBarContainer.childNodes as NodeListOf<HTMLElement>)
            : [];
    }

    private filterByCanAccessEmployee = (items: MenuItemGroup): MenuItemGroup => {
        const filterByEmployeeAccess = (i: MenuItem): boolean => {
            if (!i.checkAccessToEmployee) {
                return true;
            }

            const employeeId = this._routeSnapshot.paramMap.get('employee');
            return employeeId ? this.auth.canAccessEmployee(parseInt(employeeId)) : false;
        };

        return items
            .map((item) => ({ ...item, children: item.children?.filter(filterByEmployeeAccess) }))
            .filter(filterByEmployeeAccess);
    };

    private replaceUrlParams = (items: MenuItemGroup): MenuItemGroup =>
        items
            .map((item) => (!item.children ? item : { ...item, children: this.replaceUrlParams(item.children) }))
            .map((item) => ({
                ...item,
                link: item.link.map((linkSegment) => {
                    if (!linkSegment.includes(':')) {
                        return linkSegment;
                    }

                    const key = linkSegment.replace(':', '');
                    const paramValue = this._routeSnapshot.paramMap.get(key);
                    if (paramValue !== null) {
                        linkSegment = linkSegment.replace(':' + key, paramValue);
                    }

                    return linkSegment;
                }),
            }));

    private configureActiveLink = (items: MenuItemGroup): DisplayMenuItemGroup => {
        return items.map((item) => {
            let isActiveLink = false;
            if (item.children) {
                const activeLinkChild = item.children.find((c) => c.link.join('/') === this.router.url);
                if (activeLinkChild) {
                    const parentKey = this.translateService.instant(item.key);
                    const childKey = this.translateService.instant(activeLinkChild.key);
                    item.key = `${parentKey} - ${childKey}`;
                    isActiveLink = true;
                }
            }

            return { ...item, isActiveLink };
        });
    };
}
