import { File } from '@models/common/file.model';
import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';

export class EmployeeNamePronunciation extends Model {
    protected static _resource = 'companyManagement/companies/:company/employees/:employee/employeeNamePronunciations';

    protected static _type = 'employeeNamePronunciations';

    protected static _version = 'v2';
    protected static _serializeAttributes = ['employeeId', 'fileId'];

    get employeeId(): number {
        return this._attributes['employeeId'];
    }
    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    get fileId(): number {
        return this._attributes['fileId'];
    }
    set fileId(val: number) {
        this._attributes['fileId'] = val;
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get file(): File {
        return this.hasOne(File, 'file');
    }

    set file(file: File) {
        this.setOne('file', file, 'fileId');
    }
}
