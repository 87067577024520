import { Injectable } from '@angular/core';
import { Paginator, SortDirection } from '@app/classes';
import { Column } from '@app/modules/table/classes/column.class';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService, NotifyService } from '@app/services';
import { DentalBenefitService } from '../../dental-benefit/dental-benefit.service';
import { DentalBenefitCodeCount, DentalBenefitTableRow } from '../../dental-benefit/types';
import columns from './columns';

/**
 * A simplified version of our existing Paginator.
 * This table does not require almost all of the functionality of the existing paginator, instead we can do most functionality locally.
 * This avoids us needing to define a model and can instead use a re-usable service to pull data.
 */
class DentalBenefitsPaginator extends Paginator<DentalBenefitTableRow> {
    private _empty = true;
    constructor(
        private dentalBenefitService: DentalBenefitService,
        private notifyService: NotifyService
    ) {
        super(undefined);
    }

    get empty(): boolean {
        return this._empty;
    }

    /**
     * Loads the data for the table. Instead of requiring a query, we can just use a service method
     */
    loadData(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.dentalBenefitService.getAssignedDentalBenefitCodeCounts().subscribe({
                next: (response) => {
                    this._currentValue = this.mapResponseData(response);
                    this._empty = false;
                    this._isLoading(false);
                    resolve();
                },
                error: (e) => {
                    this._empty = true;
                    this.notifyService.error('payroll.dental-benefits.table.error');
                    this._isLoading(false);
                    reject(e);
                },
            });
        });
    }

    /**
     * Since this table does not contain pages, we do not need to do the sorting on the back-end and can instead do a local sort for a faster user-experience
     */
    localSort(): void {
        const sortRow = this.sort.column as keyof DentalBenefitTableRow;
        const sortDirection = this.sort.order === SortDirection.ASC ? 1 : -1;
        this._currentValue.sort((a, b) => (a[sortRow] > b[sortRow] ? sortDirection : sortDirection * -1));
    }

    /**
     * Shapes the data from the back-end into the format expected for the table rows
     */
    private mapResponseData(response: DentalBenefitCodeCount): DentalBenefitTableRow[] {
        return Object.entries(response).map(([code, employeesAssignedCount]) => ({
            code,
            description: `payroll.dental-benefits.table.codeDescriptions.${code}`,
            employeesAssignedCount,
        }));
    }
}

@Injectable()
export class DentalBenefitsTable extends Table<DentalBenefitTableRow> {
    paginator!: DentalBenefitsPaginator;
    sortDirection = SortDirection.ASC;
    protected sortProperty = 'code';

    constructor(
        protected auth: AuthService,
        private dentalBenefitService: DentalBenefitService,
        private notifyService: NotifyService
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = columns.map((DentalBenefitsColumn) => new DentalBenefitsColumn());
    }

    setDataSource(): void {
        this.paginator = new DentalBenefitsPaginator(this.dentalBenefitService, this.notifyService);
    }

    sort(column: Column): void {
        // Super call ensures that we update the private variables responsible for the column UI icons that indicate sorting direction
        super.sort(column, false);
        this.paginator.localSort();
    }
}
