import { Model } from '@models/core/base.model';
import { JournalEntryReportItemTemplate } from '@models/payroll/journal-entry-reports-settings-line-item.model';

export class XeroAccountMapping extends Model {
    protected static _type = 'xeroAccountMapping';

    protected static _resource = 'payroll/companies/:company/xero/accountMappings';

    protected static _serializeAttributes = ['code', 'journalEntryReportItemTemplateId'];

    get journalEntryReportItemTemplateId(): number {
        return this._attributes['journalEntryReportItemTemplateId'];
    }
    set journalEntryReportItemTemplateId(val: number) {
        this._attributes['journalEntryReportItemTemplateId'] = val;
    }

    get journalEntryReportItemTemplate(): JournalEntryReportItemTemplate {
        return this.hasOne(JournalEntryReportItemTemplate, 'journalEntryReportItemTemplate');
    }
    set journalEntryReportItemTemplate(model: JournalEntryReportItemTemplate) {
        this.setOne('journalEntryReportItemTemplate', model);
    }

    get code(): string {
        return this._attributes['code'];
    }
    set code(val: string) {
        this._attributes['code'] = val;
    }
}
