<mat-form-field [hideRequiredMarker]="hideRequiredMarker">
    <mat-label [class.sr-only]="!labelVisible" *ngIf="label.length">{{ label }}</mat-label>
    <input
        matInput
        type="text"
        [required]="required"
        [formControl]="inputControl"
        [matDatepicker]="datePicker"
        [min]="minDate"
        [max]="maxDate"
        [placeholder]="placeholder || ('selectADate' | translate)"
        (input)="lastInput = $event.target.value"
        (dateChange)="onDatePickerChange($event?.value)"
        (focus)="openOnFocus && datePicker.open()"
        (click)="openOnFocus && datePicker.open()"
    />
    <mat-datepicker-toggle
        matSuffix
        [for]="datePicker"
        aria-label="{{ label }} {{ 'screen-reader-only.openCalendar' | translate }}"
    >
        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-hint *ngIf="hint || matFormField.hintLabel">{{ hint ?? matFormField.hintLabel | translate }}</mat-hint>
    <!-- Until all instances of mat-datepicker are run through the translation pipe matDatepickerMin and matDatepickerMax must be translated manually.
    Once all uses have been updated we can run the formErrors pipe through the translate pipe and return the translation keys directly-->
    <mat-error *ngIf="inputControl.invalid">
        <ng-container
            *ngIf="inputControl.errors?.matDatepickerMin || inputControl.errors?.matDatepickerMax; else genericError"
        >
            <span *ngIf="inputControl.errors?.matDatepickerMin">{{
                'dateValidation.dateMustBeAfter' | translate: { date: getDateLimit() | appDate: 'yyyy-MM-dd' }
            }}</span>
            <span *ngIf="inputControl.errors?.matDatepickerMax">{{
                (isToday(maxDate) ? 'dateValidation.dateCannotBeInTheFuture' : 'dateValidation.dateMustBeBefore')
                    | translate: { date: getDateLimit() | appDate: 'yyyy-MM-dd' }
            }}</span>
        </ng-container>
        <ng-template #genericError>
            <span *ngIf="inputControl.invalid">{{ inputControl.errors | formErrors }}</span>
        </ng-template>
    </mat-error>
</mat-form-field>
