import moment from 'moment';

interface FrequencyPeriod {
    start?: Date;
    end?: Date;
}

const getWeeklyFrequencyPeriods = (): FrequencyPeriod[] => {
    const lastPeriod = moment().subtract(1, 'weeks');

    return [
        {
            start: lastPeriod.startOf('week').toDate(),
            end: lastPeriod.endOf('week').toDate(),
        },
        {
            start: moment().startOf('week').toDate(),
            end: moment().endOf('week').toDate(),
        },
    ];
};

const getMonthlyFrequencyPeriods = (): FrequencyPeriod[] => {
    const lastPeriod = moment().subtract(1, 'months');
    return [
        {
            start: lastPeriod.startOf('month').toDate(),
            end: lastPeriod.endOf('month').toDate(),
        },
        {
            start: moment().startOf('month').toDate(),
            end: moment().endOf('month').toDate(),
        },
    ];
};

const getQuarterlyFrequencyPeriods = (): FrequencyPeriod[] => {
    const lastPeriod = moment().subtract(1, 'quarters');
    return [
        {
            start: lastPeriod.startOf('quarter').toDate(),
            end: lastPeriod.endOf('quarter').toDate(),
        },
        {
            start: moment().startOf('quarter').toDate(),
            end: moment().endOf('quarter').toDate(),
        },
    ];
};

const getSemiAnnualFrequencyPeriods = (): FrequencyPeriod[] => {
    return [
        {
            start: moment().subtract(2, 'quarters').startOf('quarter').toDate(),
            end: moment().subtract(1, 'quarters').endOf('quarter').toDate(),
        },
        {
            start: moment().startOf('quarter').toDate(),
            end: moment().add(1, 'quarters').endOf('quarter').toDate(),
        },
    ];
};

const getAnnualFrequencyPeriods = (): FrequencyPeriod[] => {
    const lastPeriod = moment().subtract(1, 'year');
    return [
        {
            start: lastPeriod.startOf('year').toDate(),
            end: lastPeriod.endOf('year').toDate(),
        },
        {
            start: moment().startOf('year').toDate(),
            end: moment().endOf('year').toDate(),
        },
    ];
};

export {
    FrequencyPeriod,
    getWeeklyFrequencyPeriods,
    getMonthlyFrequencyPeriods,
    getQuarterlyFrequencyPeriods,
    getSemiAnnualFrequencyPeriods,
    getAnnualFrequencyPeriods,
};
