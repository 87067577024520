export enum HiringAccessLevelsEnum {
    recruiter = 'applicant-tracker.recruiter',
    hiringManager = 'applicant-tracker.hiringManager',
    basic = 'applicant-tracker.basic',
}

export enum HiringAccessLevelIdsEnum {
    recruiter = null,
    hiringManager = 1,
    basic = 2,
    private = -1,
}

/*
    The reasoning behind having -1 for private is that currently in the mat-select for Visibility
    we are using accessLevelIds with a boolean value (isPrivate) the better recommendation would be
    to split this into a select and checkbox instead.

    The -1 allows us to use the isPrivate property in the current mat-select and in the future refactor it
    to be an access level if it seems appropriate in the access level hierarchy.

    TODO: Ticket for refactoring this https://gethumi.atlassian.net/browse/SQE-4781
*/

/*
    The recruiter access level is a prime candidate for a refactor as well since null does not play well with any of
    our current selects or in general in HTML. Null has a different semantic meaning and shouldn't be used in this
    case.

    TODO: https://gethumi.atlassian.net/browse/SQE-4782
*/
