import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelfOnboardingDocument } from '@models/payroll/self-onboarding-document.model';
import { SelfOnboardingDocumentType } from '@payroll/types/self-onboarding-document-type.type';
import { PayrollResources } from '@payroll/payroll.resources';
import { keyBy, map } from 'lodash-es';
import { SelfOnboardingDocumentUploader } from '../interfaces/self-onboarding/self-onboarding-document-uploader.interface';

@Injectable({
    providedIn: 'root',
})
export class SelfOnboardingDocumentsService {
    constructor(private http: HttpClient) {}

    async getDocuments<T extends SelfOnboardingDocumentType>(
        types: readonly T[]
    ): Promise<Record<T, SelfOnboardingDocument<T>>> {
        const [documents] = await SelfOnboardingDocument.with('documentUploader').get();

        return keyBy(
            map(
                types,
                (type) =>
                    documents.find((document: SelfOnboardingDocument) => document.type === type) ||
                    new SelfOnboardingDocument({ type, state: 'new' })
            ),
            'type'
        ) as Record<T, SelfOnboardingDocument<T>>;
    }

    async uploadFile<T extends SelfOnboardingDocumentType>(
        documentType: T,
        file: File,
        uploader?: SelfOnboardingDocumentUploader
    ): Promise<SelfOnboardingDocument<T>> {
        const url = PayrollResources.SelfOnboardingDocumentUpload;
        const form = new FormData();
        form.append('data[attributes][file]', file);
        form.append('data[attributes][display_name]', file.name);
        form.append('data[attributes][type]', documentType);
        if (uploader) {
            form.append('data[attributes][document_uploader_email_address]', uploader.documentUploaderEmailAddress);
            form.append('data[attributes][document_uploader_first_name]', uploader.documentUploaderFirstName);
            form.append('data[attributes][document_uploader_last_name]', uploader.documentUploaderLastName);
        }

        try {
            const response = await this.http.post(url, form).toPromise();
            return new SelfOnboardingDocument<T>(response);
        } catch (error) {
            throw error instanceof HttpErrorResponse ? error.message : error;
        }
    }
}
