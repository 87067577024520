import { Pipe, PipeTransform } from '@angular/core';
import { GendersCollection } from '@app/interfaces';
import { TitlecasePipe } from './titlecase.pipe';

@Pipe({ name: 'genders' })
export class GendersPipe implements PipeTransform {
    titleCasePipe = new TitlecasePipe();

    transform(genders: GendersCollection | null): string[] {
        if (!genders) {
            return;
        }
        return this.displayGenders(genders);
    }

    displayGenders(genders: GendersCollection): string[] {
        return genders?.map((gender) => this.titleCasePipe.transform(gender.value));
    }
}
