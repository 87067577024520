import { DentalBenefitCode } from '@app/modules/payroll/services/dental-benefit/types';
import { ModelMixin } from '@models/core/base-generic.model';

interface LegacyTaxCredit {
    amount: string;
    date: string;
    jurisdiction: string;
    name: string;
}

interface TaxInformation {
    additionalTaxDeducted: string;
    exemptions: {
        isEi: boolean;
        isCpp: boolean;
        isTax: boolean;
        isWcb: boolean;
    };
    taxCredits: LegacyTaxCredit[];
}

interface ApplicableWcbRate {
    id: number;
    name: string;
    rate: string;
    year: number;
    jurisdiction: string;
}

export class PayrollEmployee extends ModelMixin<PayrollEmployee>() {
    protected static _resource = 'payroll/employees';

    protected static _version = 'v2';

    get firstName(): string {
        return this._attributes['firstName'];
    }

    get lastName(): string {
        return this._attributes['lastName'];
    }

    get taxInformation(): TaxInformation {
        return this._attributes['taxInformation'];
    }

    get isTerminated(): boolean {
        return this._attributes['isTerminated'];
    }

    get hrEmployeeId(): number {
        return this._attributes['hrEmployeeId'];
    }

    get applicableWcbRate(): ApplicableWcbRate {
        return this._attributes['applicableWcbRate'];
    }

    get box45Value(): DentalBenefitCode | null {
        return this._attributes['box45Value'];
    }

    get isWcbExempt(): boolean {
        return this._attributes['isWcbExempt'];
    }
}
