<div *ngIf="isWaitingForResponse" class="ui active inverted dimmer">
    <div class="ui inline loader"></div>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form)">
    <ui-banner variant="bulb" class="margin-bottom-3">
        <div
            [innerHTML]="{ key: 'sidebar.featureRequest.description', params: { helpCenterUrl } } | appTranslate"
        ></div>
    </ui-banner>
    <div class="app-grid">
        <mat-form-field class="columns-12">
            <mat-label>{{ 'sidebar.featureRequest.problemOrRequest' | appTranslate }}</mat-label>
            <input matInput name="title" required #requestInput="ngModel" [(ngModel)]="title" maxlength="255" />
            <mat-error *ngIf="requestInput.invalid">{{ requestInput.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-12">
            <mat-label>{{ 'sidebar.featureRequest.content' | appTranslate }}</mat-label>
            <textarea
                [(ngModel)]="content"
                rows="9"
                name="content"
                required
                matInput
                #contentInput="ngModel"
            ></textarea>
            <mat-error *ngIf="contentInput.invalid">{{ contentInput.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-12">
            <mat-label appRemoveAriaOwns>{{ 'sidebar.featureRequest.tags' | appTranslate }}</mat-label>
            <mat-select matInput type="text" name="tags" [(ngModel)]="tags">
                <mat-option *ngFor="let productArea of PRODUCT_AREA_TAGS | keyvalue" [value]="productArea.value">
                    {{ 'sidebar.featureRequest.productAreaTags.' + productArea.key | appTranslate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>
