type SetterFn<T> = (x: T) => T;

export type GetCurrentPage = () => number;
export type SetCurrentPage = (page: number | SetterFn<number>) => number;

export type PagerDeps = {
    pages: number;
    initialPage?: number;
    basePage?: number;
    onPageChange?: (props: PagerProps) => void;
};
export type PagerProps = {
    pages: number;
    getCurrentPage: GetCurrentPage;
    setCurrentPage: SetCurrentPage;
    next(): number;
    prev(): number;
    first(): number;
    last(): number;
};

type CreatePager = (deps: PagerDeps) => PagerProps;

export const createPager: CreatePager = ({ pages, initialPage = 1, basePage = 1, onPageChange }) => {
    let currentPage: number = initialPage;

    const first = () => basePage;
    const last = () => pages;

    const getProps = (set: SetCurrentPage, get: GetCurrentPage): PagerProps => ({
        pages,
        getCurrentPage,
        setCurrentPage,
        first,
        last,
        next: () => {
            const result = get() + 1;

            if (result > pages) {
                return;
            }
            return set(result);
        },
        prev: () => {
            const result = get() - 1;

            if (result < basePage) {
                return;
            }

            return set(result);
        },
    });

    const getCurrentPage: GetCurrentPage = () => currentPage;
    const setCurrentPage: SetCurrentPage = (page) => {
        const result = typeof page === 'function' ? page(getCurrentPage()) : page;

        currentPage = result;

        if (onPageChange) {
            onPageChange(getProps(setCurrentPage, getCurrentPage));
        }

        return getCurrentPage();
    };

    return getProps(setCurrentPage, getCurrentPage);
};
