import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService, NotifyService } from '@app/services';
import { BulkSelectBaseDialogComponent } from '@app/components';
import { OffboardingReminder } from '@models/employee/offboarding-reminder.model';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-common-bulk-select-offboarding-reminder-dialog',
    templateUrl: './bulk-select-offboarding-reminders.template.html',
    styleUrls: ['../bulk-select-base-dialog/bulk-select-base-dialog.style.scss'],
})
export class BulkSelectOffboardingRemindersComponent extends BulkSelectBaseDialogComponent implements OnInit {
    @Input() label = 'Select';
    @Input() headerText = 'Select Offboarding Reminders';

    searchControl: FormControl<string | null> = new FormControl();

    items: OffboardingReminder[] = [];
    showItems: OffboardingReminder[] = [];
    selectItems: OffboardingReminder[] = [];

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        private notify: NotifyService
    ) {
        super(element, auth);
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(debounceTime(250)).subscribe((filterBy: string) => {
            if (!filterBy) {
                this.showItems = this.items;
                return;
            }

            this.showItems = this.items.filter((r: OffboardingReminder) => {
                const filter = filterBy.toLowerCase();
                const inName = r.name.toLowerCase().indexOf(filter) > -1;
                const inBody = r.body.toLowerCase().indexOf(filter) > -1;

                return inName || inBody;
            });
        });
    }

    selectAll(): void {
        OffboardingReminder.param('company', this.auth.company.id)
            .all()
            .then((reminders: OffboardingReminder[]) => {
                this.selectItems = reminders;
                this.updateModelSelection();
            })
            .catch(() => {
                this.isLoading = false;
                this.notify.error('Unable to contact server');
            });
    }

    protected loadItems(): void {
        OffboardingReminder.param('company', this.auth.company.id)
            .page(this.currentPage)
            .get()
            .then(([reminders, _]) => {
                this.totalResults = reminders.length;
                this.items = reminders;
                this.showItems = reminders;
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
                this.notify.error('Unable to contact server');
            });
    }
}
