import { TimeOffDayCalendarEvent } from '@models/time-off-v3/time-off-day-calendar-event.model';
import { parse } from 'date-fns';

export class TimeOffBlackoutPeriodEvent extends TimeOffDayCalendarEvent {
    get start(): Date {
        return parse(this.startAt);
    }

    get end(): Date {
        return parse(this.endAt);
    }
}
