import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { breakPoints } from '@app/styles/theme';

import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../platform';

@Component({
    selector: 'app-mobile-warning',
    templateUrl: './mobile-warning.template.html',
})
export class MobileWarningDialog implements AfterViewInit {
    @ViewChild('mobileConfirmationDialog') mobileConfirmationDialog!: ConfirmationDialogComponent;

    @Input() backRoute?: string;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private location: Location,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    async ngAfterViewInit(): Promise<void> {
        if (this.breakpointObserver.isMatched(`(max-width: ${breakPoints.md}px)`)) {
            const continueOnMobile = await this.mobileConfirmationDialog?.open();
            if (!continueOnMobile) {
                if (this.backRoute) {
                    this.router.navigate([this.backRoute], { relativeTo: this.route });
                } else {
                    this.location.back();
                }
            }
        }
    }
}
