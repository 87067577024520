import { Role } from '@app/models/account/role.model';
import { CreateBaseSetting } from '@app/models/settings/base-setting.model';
import { difference } from 'lodash-es';
import { Company } from '../../company.model';

// The entire model doesn't always come back from the API, so this is a partial representation of the model
export type CompanyMFAResponse = Pick<CompanyMFA, 'value' | 'roles'>;

export class CompanyMFA extends CreateBaseSetting<CompanyMFA, boolean>(false) {
    protected static _resource = 'companyManagement/companies/:company/companyMFA';

    protected static _serializeAttributes = [...super._serializeAttributes, 'role_ids'];

    protected static _version = 'v2';

    protected static _method = 'PUT';

    /**
     * Enable or disable roles based on the currently active roles.
     * The API takes in a list of roles that are to be enabled or disabled.
     *
     * @param company
     * @param roles
     * @returns
     */
    async setEnabledRoles(company: Company, roles: Role[]): Promise<CompanyMFAResponse> {
        // Only enable roles that are not already enabled
        const rolesToEnable = difference(roles, this.roles);
        if (rolesToEnable.length > 0) {
            this.roles = roles;
            this.setRequestData(rolesToEnable, true);
        } else {
            // if there are no roles to enable, check if there are any roles to disable
            // aka if there is anything in this.roles (currently active) that isn't in the "new" roles
            const rolesToDisable = difference(this.roles, roles);
            if (rolesToDisable.length > 0) {
                this.roles = roles;
                this.setRequestData(rolesToDisable, false);
            } else {
                // if there are no roles to enable or disable, return the current roles
                return this;
            }
        }
        return this.saveModel(company);
    }

    get roles(): Role[] {
        return this.hasMany(Role, 'roles');
    }
    set roles(val: Role[]) {
        this.setMany('roles', val);
    }

    get role(): Role {
        return this._attributes['role'];
    }

    get roleIds(): number[] {
        return this._attributes['role_ids'];
    }

    set roleIds(roleIds: number[]) {
        this._attributes['role_ids'] = roleIds;
    }

    private setRequestData(roles: Role[], value: boolean): void {
        this.roleIds = roles.map((role) => role.id);
        this.value = value;
    }

    private async saveModel(company: Company): Promise<CompanyMFAResponse> {
        return this.param('company', company.id)
            .save({ includeId: false })
            .then((companyMFA) => companyMFA.getAttributes());
    }
}
