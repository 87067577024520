import { Model } from '../core/base.model';

import { SimpleEntityActions } from '@app/classes';

export class Job extends Model {
    static permission = new SimpleEntityActions('companyJob');
    protected static _resource = 'companyManagement/companies/:company/jobs';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['title', 'description'];
    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    get title(): string {
        return this._attributes['title'];
    }
    set title(val: string) {
        this._attributes['title'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }
    set description(val: string) {
        this._attributes['description'] = val;
    }

    get employeeCount(): number {
        return this._attributes['employeeCount'];
    }

    get employees() {
        return this._attributes['employees'];
    }

    set employees(val) {
        this._attributes['employees'] = val;
    }
}
