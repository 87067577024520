import { ModelMixin as GenericModel } from './base-generic.model';
export enum RowChangeStatusTypes {
    'saving_in_progress',
    'saved',
    'error_while_saving',
}

/**
 * @deprecated This class does not have type safety since it extends any and restricts id to be a number type.
 * If you are creating a new model use [ModelMixin](./base-generic.model.ts), if you need an explicit class instead of a mixin you can use {@link ModelUnion}.
 * @use [ModelMixin](./base-generic.model.ts) or {@link ModelUnion} instead
 * @example
 * // CREATING A NEW MODEL
 * // ❌ Incorrect - use ModelMixin for type safety and attribute resolution
 * class Email extends Model
 * // ✅ Correct
 * class Email extends ModelMixin<Email>()
 *
 * // EXPLICIT TYPING
 * // ❌ Incorrect - Can't use Mixins in generics
 * class Table<T extends ModelMixin<any>()> {
 * // ❌ Incorrect - Model does not allow for string IDs, this class can not accept Models with numeric IDs
 * class Table<T extends Model> {
 * // ✅ Correct - The generic will allow for any existing and new models to be used
 * class Table<T extends ModelUnion> {
 */
export class Model extends GenericModel<any, number>() {}

/**
 * This class is only meant to be used where the [ModelMixin](./base-generic.model.ts) cannot (such as within generics). [ModelMixin](./base-generic.model.ts) should always be preferred.
 * @see {@link Model} deprecation message for better usage example.
 */
export class ModelUnion extends GenericModel<any, string | number>() {}
