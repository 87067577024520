import { ModuleName } from '@app/modules/settings/enums/module-names.enum';
import { Model } from '../core/base.model';

export class Setting extends Model {
    // protected static _type = 'settings';

    get module(): ModuleName {
        return this._attributes['module'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get default(): boolean {
        return this._attributes['default'];
    }
}
