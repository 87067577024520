export function arrayChunk<T>(array: T[], chunkSize: number): T[][] {
    const result = [];
    const copy = [...array];

    while (copy[0]) {
        result.push(copy.splice(0, chunkSize));
    }

    return result;
}
