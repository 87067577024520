import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Uploadable } from '@app/classes/uploadable';
import { MAX_FILE_SIZE_HUMAN_READABLE } from '@app/constants/file';
import { BulkUploadViewStatus } from '@app/modules/common/views/bulk-upload/bulk-upload-view';

/*
 * This component handles inserting files into the browser, pre-upload.
 *
 * It also accepts Uploadables and renders them using a template passed in if the file is valid.
 * If the file is not valid, it displays the invalidFileTemplate
 */
@Component({
    selector: 'app-bulk-file-uploader',
    templateUrl: './bulk-file-uploader.template.html',
    styleUrls: ['./bulk-file-uploader.style.scss'],
})
export class BulkFileUploaderComponent {
    /**
     * Uploadables created by the parent view that are shown using the template provided
     *
     * Having these here allows this file to do the iteration, handle group styling rules,
     * and show the same invalid template
     */
    @Input() uploadables: Uploadable[] = [];

    /**
     * A template that will be used to display each valid uploadable
     */
    @Input() uploadableTemplate: TemplateRef<unknown>;

    /**
     * When allowMoreFiles is true, the file uploader is shown.
     * When false, hidden.
     *
     * We do not allow more files if the limit is reached or files have already been uploaded
     */
    @Input() allowMoreFiles = true;

    /**
     * The status of the parent view
     */
    @Input() status: BulkUploadViewStatus;

    /**
     * The type of upload
     *
     * ex: "documents" or "certificates"
     */
    @Input() uploadDescriptor: string;

    /**
     * A single string of comma separated mime types or file types.
     * For example: '.pdf' or '.jpg,.bmp,.svg' or 'image/*,application/*'
     *
     * Drag and drop file validation will only occur if a file type list is provided
     */
    @Input() allowedTypes = '*';

    /**
     * The text to display as a maximum file size
     * Defaults to default max file size
     *
     * This does not enforce a file size. It's only for display
     */
    @Input() maxFileSizeLabel: string = MAX_FILE_SIZE_HUMAN_READABLE;

    /**
     * Emits every time new files are added.
     */
    @Output() filesAdded = new EventEmitter<FileList>();

    /**
     * Emits on upload button press
     */
    @Output() upload = new EventEmitter<null>();

    /**
     * Emits on upload more button press after completion
     */
    @Output() reset = new EventEmitter<null>();

    /**
     * Emits on click remove
     */
    @Output() remove = new EventEmitter<Uploadable>();

    /*
     * Is there currently a pointer holding files hovering above this component
     */
    isDraggedOver = false;

    /**
     * Determines if the upload has started
     * Useful for hiding components that should not appear once the upload has started
     */
    hasStartedUpload(): boolean {
        return this.status === 'uploading' || this.status === 'complete';
    }

    onDrag(event: DragEvent): void {
        this.isDraggedOver = true;
        event.preventDefault();
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();

        if (this.hasStartedUpload()) {
            return;
        }

        const { files } = event.dataTransfer;
        this.filesAdded.emit(files);
    }

    addFiles(files: FileList): void {
        this.filesAdded.emit(files);
    }
}
