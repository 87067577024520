<form #form="ngForm" autocomplete="off" class="notranslate" [class.loading]="isLoading">
    <mat-calendar #calendar [(selected)]="selectedDate" (selectedChange)="getBalanceOnDateSelect()"></mat-calendar>
    <div class="balance">
        <div class="balance-detail">
            <div>
                {{
                    {
                        key: 'components.calculate-balance.balanceOn',
                        params: { date: selectedDate | appDate: 'mediumDate' },
                    } | appTranslate
                }}
            </div>
            <h3 class="no-margin">
                {{ balance }} {{ this.timeOffType?.unitOfTimePluralTranslation | appTranslate | titlecase }}
            </h3>
        </div>
        <div>
            <div>
                {{
                    {
                        key: 'components.calculate-balance.unitScheduledAfterDate',
                        params: { unit: this.timeOffType?.unitOfTimePluralTranslation | appTranslate | titlecase },
                    } | appTranslate
                }}
            </div>
            <h3 class="no-margin">
                {{
                    {
                        key: 'components.calculate-balance.amountApprovedOfUnitApproved',
                        params: {
                            amountApproved: futureRequestsAmountApproved | number: decimalPlacesForPipe,
                            unit: this.timeOffType?.unitOfTimePluralTranslation | appTranslate | titlecase,
                        },
                    } | appTranslate
                }}
            </h3>
            <div *ngIf="!!futureRequestsAmountPending">
                ({{
                    {
                        key: 'components.calculate-balance.futureRequestOfUnitPending',
                        params: {
                            amountPending: futureRequestsAmountPending | number: decimalPlacesForPipe,
                            unit: this.timeOffType?.unitOfTimePluralTranslation | appTranslate | titlecase,
                        },
                    } | appTranslate
                }})
            </div>
        </div>
    </div>
</form>
