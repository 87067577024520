import { ModelMixin } from '../core/base-generic.model';
import { Module } from './module.model';

export class EmployeeModuleAssignment extends ModelMixin<EmployeeModuleAssignment>() {
    protected static _type = 'employeeModuleAssignments';

    protected static _datetimes = ['assignedAt', 'createdAt', 'updatedAt', 'deletedAt'];

    get assignedAt(): Date {
        return this._attributes['assignedAt'];
    }

    get module(): Module {
        return this.hasOne(Module, 'module');
    }
}
