import { Model } from '../core/base.model';

import { SimpleEntityActions } from '@app/classes';
import { Employee } from '@models/employee/employee.model';

export class Department extends Model {
    protected static _resource = 'companyManagement/companies/:company/departments';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['name', 'description', 'color', 'departmentCode'];
    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    static permission = new SimpleEntityActions('companyDepartment');

    get name(): string {
        return this._attributes['name'];
    }
    set name(val: string) {
        this._attributes['name'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }
    set description(val: string) {
        this._attributes['description'] = val;
    }

    get departmentCode(): string {
        return this._attributes['departmentCode'];
    }

    set departmentCode(val: string) {
        this._attributes['departmentCode'] = val;
    }

    /**
     * Color
     */
    get color(): string {
        return this._attributes['color'];
    }
    set color(val: string) {
        this._attributes['color'] = val;
    }

    /**
     * Employee Count
     */
    get employeeCount(): number {
        return this._attributes['employeeCount'];
    }

    get employees(): Employee[] {
        return this._attributes['employees'];
    }
    set employees(val: Employee[]) {
        this._attributes['employees'] = val;
    }
}
