<div
    (click)="toggleOverlay()"
    class="input-wrapper"
    [class.disabled]="disabled"
    [class.focused]="isOverlayOpen"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
>
    <div
        *ngIf="selectedColor"
        class="color-preview"
        [class.readonly]="readOnly"
        aria-hidden="true"
        [ngStyle]="{ backgroundColor: selectedColor.hex }"
    ></div>
    <span class="preview-label"> {{ selectedColor ? (selectedColor.label | translate) : placeholder }} </span>
    <mat-icon [svgIcon]="isOverlayOpen ? 'chevronUp' : 'chevronDown'"></mat-icon>
</div>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
    #overlay
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="true"
    [cdkConnectedOverlayOffsetX]="-8"
    [cdkConnectedOverlayOffsetY]="13"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'clear-backdrop'"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    (backdropClick)="toggleOverlay(true)"
    (overlayKeydown)="onOverlayKeydown($event)"
>
    <div class="wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <div class="color-list">
            <button
                *ngFor="let color of colors"
                (click)="selectColor(color)"
                [ngStyle]="{ backgroundColor: color.hex }"
            ></button>
        </div>
        <mat-form-field class="no-labels no-helpers color-input">
            <input type="text" placeholder="#000000" matInput [formControl]="hexInputControl" />
        </mat-form-field>
    </div>
</ng-template>
