import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TaskSubtask } from '@app/models/tasks/task-subtask.model';
import { Task } from '@app/models/tasks/task.model';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class TaskSubtasksService {
    constructor(private http: HttpClient) {}

    toggleCompletion(task: Task, subTask: TaskSubtask): Promise<TaskSubtask> {
        const url = this.getSubTaskToggleCompletionEndpoint(task.id, subTask.id);
        const payload = {};

        return this.http.post(url, payload).toPromise() as Promise<TaskSubtask>;
    }

    async onSaveSubTasks(task: Task, subtasks: TaskSubtask[], removedSubtasks: TaskSubtask[]): Promise<void> {
        subtasks = this.removeEmptySubTasks(subtasks);
        subtasks = this.setSubTasksOrderBy(subtasks);

        // create new sub tasks
        const newSubtasks = this.getNewSubTasks(subtasks);
        newSubtasks.forEach(async (newSubtask) => await newSubtask.param('task', task.id).save());

        // update sub tasks
        const updatedSubtasks = this.getUpdatedSubTasks(subtasks);
        updatedSubtasks.forEach(async (updatedSubtask) => await updatedSubtask.param('task', task.id).save());

        // delete sub tasks
        removedSubtasks.forEach(async (deletedSubtask) => await deletedSubtask.param('task', task.id).delete());
    }

    private getNewSubTasks(subtasks: TaskSubtask[]): TaskSubtask[] {
        return subtasks.filter((subtask) => !subtask.isPersisted);
    }

    private getUpdatedSubTasks(subtasks: TaskSubtask[]): TaskSubtask[] {
        return subtasks.filter((subtask) => subtask.isPersisted && subtask.isDirty());
    }

    // set order_by property of subtasks
    private setSubTasksOrderBy(subtasks: TaskSubtask[]): TaskSubtask[] {
        subtasks.forEach((subtask, index) => {
            subtask.orderBy = index + 1;
        });

        return subtasks;
    }

    // Remove empty sub tasks
    private removeEmptySubTasks(subtasks: TaskSubtask[]): TaskSubtask[] {
        return subtasks.filter((subtask) => subtask.description !== undefined && subtask.description !== null);
    }

    private getSubTaskToggleCompletionEndpoint(taskId: number, subtaskId: number): string {
        return environment.api + `/v2/tasks/${taskId}/subtasks/${subtaskId}/toggleCompletion`;
    }
}
