import { Translatable } from '@app/types/translatable.type';
import { TaskTemplateSubtask } from '@models/tasks/task-template-subtask.model';
import { Model } from '../core/base.model';
import { EmployeeFilter } from '../employee/employee-filter.model';
import { Employee } from '../employee/employee.model';

const dueTypeDay = 'day';

const dueRelationBefore = 'before';
const dueRelationAfter = 'after';
const dueDateTypeNone = 'none';
const dueDateTypeHiredDate = 'on hired date';
const dueDateTypeLastDay = 'on last day';
const dueDateTypeCustom = 'custom';
type dueDateType = 'none' | 'on hired date' | 'on last day' | 'custom';

const onboardingTaskType = 'onboarding';
const generalTaskType = 'general';

export class TaskTemplate extends Model {
    static taskPermission = 'task';

    static managePermission = 'createAndEditTaskTemplates';

    static assigeeTypeSubject = 'subject';
    static assigeeTypeManager = 'manager';
    static assigeeTypeSpecificEmployee = 'specific employee';
    protected static _resource = 'taskTemplates';
    protected static _version = 'v2';
    protected static _serializeAttributes = [
        'taskType',
        'assigneeId',
        'assigneeType',
        'name',
        'description',
        'isAttachmentRequired',
        'dueOffset',
        'dueType',
        'dueRelation',
        'employeeFilterId',
        'attach',
        'detach',
    ];

    get companyId(): number {
        return this._attributes['companyId'];
    }

    get assignee(): Employee {
        return this.hasOne(Employee, 'assignee');
    }

    set assignee(employee: Employee) {
        this.setOne('assignee', employee, 'assigneeId');
    }

    get assignees(): Employee[] {
        return this.hasMany(Employee, 'assignees');
    }

    get taskType(): string {
        return this._attributes['taskType'];
    }

    set taskType(val: string) {
        this._attributes['taskType'] = val;
    }

    get assigneeId(): number {
        return this._attributes['assigneeId'];
    }

    set assigneeId(val: number) {
        this._attributes['assigneeId'] = val;
    }

    get assigneeType(): string {
        return this._attributes['assigneeType'];
    }

    set assigneeType(val: string) {
        this._attributes['assigneeType'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get isAttachmentRequired(): boolean {
        return this._attributes['isAttachmentRequired'];
    }

    set isAttachmentRequired(val: boolean) {
        this._attributes['isAttachmentRequired'] = val;
    }

    get dueOffset(): number {
        return this._attributes['dueOffset'];
    }

    set dueOffset(val: number) {
        this._attributes['dueOffset'] = val;
    }

    get dueType(): string {
        return this._attributes['dueType'];
    }

    set dueType(val: string) {
        this._attributes['dueType'] = val;
    }

    get dueRelation(): string {
        return this._attributes['dueRelation'];
    }

    set dueRelation(val: string) {
        this._attributes['dueRelation'] = val;
    }

    get assignedTo(): string | Employee {
        return this.assigneeType ?? this.assignee;
    }

    set assignedTo(val: string | Employee) {
        if (val instanceof Employee) {
            this.assignee = val;
            this.assigneeType = null;
            return;
        }

        if (val !== TaskTemplate.assigeeTypeSubject) {
            this.isAttachmentRequired = false;
        }

        this.assignee = null;
        this.assigneeType = val;
    }

    get dueAt(): string {
        return this._attributes['dueAt'];
    }

    get dueDateType(): dueDateType {
        if (this._attributes['dueDateType']) {
            return this._attributes['dueDateType'];
        }

        if (this.dueOffset === null || this.dueOffset === undefined) {
            return dueDateTypeNone;
        }

        if (this.dueOffset === 0) {
            return this.taskType === onboardingTaskType ? dueDateTypeHiredDate : dueDateTypeLastDay;
        }

        return dueDateTypeCustom;
    }

    set dueDateType(val: dueDateType) {
        this._attributes['dueDateType'] = val;

        if (val === dueDateTypeNone) {
            this.dueOffset = null;
            this.dueType = null;
            this.dueRelation = null;
            return;
        }

        if (val === dueDateTypeHiredDate || val === dueDateTypeLastDay) {
            this.dueOffset = 0;
            this.dueType = dueTypeDay;
            this.dueRelation = dueRelationBefore;
            return;
        }

        if (val === dueDateTypeCustom) {
            this.dueType = dueTypeDay;
            this.dueRelation = dueRelationBefore;
            return;
        }
    }

    /**
     * Label for the "Required For" field based on task type and employee filters
     */
    get requiredForLabel(): string {
        if (this.taskType === generalTaskType) {
            return '-';
        }

        if (this.employeeFilterId) {
            return 'employees.tasks-template.groupOfEmployees';
        }

        if (this.taskType === onboardingTaskType) {
            return 'employees.tasks-template.allNewEmployees';
        }

        return 'employees.tasks-template.allTerminatedEmployees';
    }

    /**
     * Label for the "Tasks Completed" field based on total number of tasks / completed tasks
     */
    get tasksCountLabel(): Translatable {
        if (this._attributes['totalTasksCount'] === undefined) {
            return '-';
        }

        if (this._attributes['totalTasksCount'] === 0) {
            return 'employees.tasks-template.noTasksCreated';
        }

        return {
            key: 'employees.task-details.completedCountVsTotal',
            params: {
                completedTasksCount: this._attributes['completedTasksCount'],
                totalTasksCount: this._attributes['totalTasksCount'],
            },
        };
    }

    get employeeFilterId(): number {
        return this._attributes['employeeFilterId'];
    }

    set employeeFilterId(val: number) {
        this._attributes['employeeFilterId'] = val;
    }

    get employeeFilter(): EmployeeFilter {
        return this.hasOne(EmployeeFilter, 'employeeFilter');
    }

    set employeeFilter(employeeFilter: EmployeeFilter) {
        this.setOne('employeeFilter', employeeFilter, 'employeeFilterId');
    }

    get subtasks(): TaskTemplateSubtask[] {
        return this.hasMany(TaskTemplateSubtask, 'subtasks');
    }

    isCustomDueDateType(): boolean {
        return this.dueDateType === dueDateTypeCustom;
    }

    getDueRelations(): string[] {
        return [dueRelationBefore, dueRelationAfter];
    }

    getDueDateTypes(taskType: string): string[] {
        if (taskType === 'onboarding') {
            return [dueDateTypeNone, dueDateTypeHiredDate, dueDateTypeCustom];
        }
        return [dueDateTypeNone, dueDateTypeLastDay, dueDateTypeCustom];
    }

    sendReminders(): Promise<any> {
        return Model.performPost(this.urlGetRoute('remindIncompleteTasks'));
    }

    private urlGetRoute(newSlug = ''): string {
        return TaskTemplate._version + '/' + TaskTemplate._resource + '/' + this.id + '/' + newSlug;
    }
}
