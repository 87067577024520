<div class="ui basic button dropdown" *ngIf="filters && filterValues" [class.filtered]="activeFilterCount">
    {{ 'filters' | appTranslate }}
    <mat-icon [class.active]="activeFilterCount" svgIcon="filter"></mat-icon>
    <div class="menu">
        <ng-container *ngFor="let key of getFilterKeys()">
            <a *ngIf="filterValues[key] === 'boolean'" (click)="toggle($event, key)" class="item">
                <i class="fa fa-fw" [class.fa-check]="toggled(key)" [class.fa-minus]="!toggled(key)"></i>
                {{ filters[key] | appTranslate }}</a
            >

            <div *ngIf="isObject(filterValues[key])" class="ui right scrolling dropdown item icon">
                <i class="icon right dropdown"></i>
                <span class="text">{{ filters[key] | appTranslate }}</span>
                <div class="right menu" (scroll)="detectScrollEnd($event, key)">
                    <a
                        *ngFor="let valueKey of getFilterValueKeys(key)"
                        (click)="toggle($event, key, valueKey)"
                        class="item"
                    >
                        <i *ngIf="toggled(key, valueKey)" class="fa fa-fw fa-check"></i>
                        <i *ngIf="!toggled(key, valueKey)" class="fa fa-fw fa-minus"></i
                        ><span [class.capitalize]="capitalize">{{ filterValues[key][valueKey] }}</span></a
                    >
                </div>
            </div>
        </ng-container>

        <div class="divider"></div>
        <a (click)="clear()" class="item"> <i class="fa fa-fw fa-times"></i> {{ 'clearFilters' | translate }}</a>
    </div>
</div>
