<ui-dialog
    [open]="open"
    [isCloseable]="true"
    header="{{ 'components.select-benefit-class-dialog.title' | appTranslate }}"
    (primaryButtonPressed)="onSave(form)"
    primaryButtonLabel="{{ 'save' | appTranslate }}"
    secondaryButtonLabel="{{ 'cancel' | appTranslate }}"
    [secondaryButtonType]="'ghost'"
    (opening)="onOpen()"
    (closing)="onClose()"
    [form]="form"
    size="small"
>
    <form #form="ngForm" autocomplete="off">
        <div class="app-grid">
            <p>
                {{ 'components.select-benefit-class-dialog.description' | appTranslate }}
            </p>

            <mat-form-field class="columns-6">
                <mat-label appRemoveAriaOwns>{{
                    'components.select-benefit-class-dialog.selectClassLabel' | appTranslate
                }}</mat-label>
                <mat-select
                    name="benefitClass"
                    [(ngModel)]="selectedBenefitClass"
                    required
                    placeholder="{{ 'components.select-benefit-class-dialog.selectClassPlaceholder' | appTranslate }}"
                    #benefitClass="ngModel"
                >
                    <mat-option *ngFor="let benefitClass of benefitClasses" [value]="benefitClass">{{
                        benefitClass.title
                    }}</mat-option>
                </mat-select>
                <mat-error *ngIf="benefitClass.invalid">{{ benefitClass.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6">
                <mat-label appRemoveAriaOwns>{{
                    'components.select-benefit-class-dialog.selectDivisionLabel' | appTranslate
                }}</mat-label>
                <mat-select
                    name="division"
                    [(ngModel)]="selectedBenefitDivision"
                    required
                    placeholder="{{
                        'components.select-benefit-class-dialog.selectDivisionPlaceholder' | appTranslate
                    }}"
                    #division="ngModel"
                >
                    <mat-option *ngFor="let division of divisions" [value]="division">{{ division.title }}</mat-option>
                </mat-select>
                <mat-error *ngIf="division.invalid">{{ division.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <div class="app-grid">
            <div class="flex middle columns-6">
                <mat-form-field class="width-full">
                    <mat-label appRemoveAriaOwns>{{
                        'components.select-benefit-class-dialog.selectEligibilityPeriodLabel' | appTranslate
                    }}</mat-label>
                    <mat-select name="isWaitingPeriodWaved" [(ngModel)]="isWaitingPeriodWaved" required>
                        <mat-option [value]="false">{{
                            'components.select-benefit-class-dialog.doNotWaiveEligibilityPeriodOption' | appTranslate
                        }}</mat-option>
                        <mat-option [value]="true">{{
                            'components.select-benefit-class-dialog.waiveEligibilityPeriodOption' | appTranslate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ui-info
                    text="{{ 'components.select-benefit-class-dialog.waiveEligibilityPeriodInfo' | appTranslate }}"
                ></ui-info>
            </div>
        </div>
    </form>
</ui-dialog>
